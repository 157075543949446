/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { EditorState, RichUtils, convertFromRaw } from "draft-js";
import Editor from "draft-js-plugins-editor";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";
import RequestAction from "./RequestAction";
import EditLocation from "./EditLocation";

/*REDUX FUNCTION*/
import { getBlogDetail, deleteBlog } from "../../App/actions/blogAction";

/*PICTURE ASSET*/
import icon_blog_public from "../../Assets/svg/icon_blog_public.svg";
import icon_blog_private from "../../Assets/svg/icon_blog_private.svg";
import icon_blog_free from "../../Assets/svg/icon_blog_free.svg";
import icon_blog_paid from "../../Assets/svg/icon_blog_paid.svg";

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";
import { mediaBlockRenderer } from "./mediaBlockRenderer";
import isEmpty from "../../App/validation/is-empty";
import isEqual from "../../App/validation/is-equal";
import formatDate from "../../App/validation/format-date";

/*NON IMPORT*/

class BlogViewer extends Component {
  state = {
    modal_delete: false,
    modal_request_action: false,
    modal_edit_location: false,
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.blog.blog_list, prevProps.blog.blog_list)) {
      this.setState({ modal_delete: false });
    }
  }

  toggle_edit_location = () => {
    this.setState({ modal_edit_location: !this.state.modal_edit_location });
  };

  toggleRequestAction = () => {
    this.setState({ modal_request_action: !this.state.modal_request_action });
  };

  toggleDelete = () => {
    this.setState({ modal_delete: !this.state.modal_delete });
  };

  handleDelete = (id) => {
    this.props.deleteBlog(id);
  };

  focus = () => this.refs.editor.focus();

  onChangeEditor = () => {};

  handleKeyCommand = (command) => {
    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  };

  toggleBlockType = (blockType) => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  };

  toggleInlineStyle = (inlineStyle) => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    );
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const {
      _id,
      title,
      title_en,
      editorState,
      layers,
      projects,
      link,
      date_created,
    } = this.props.blog_item;
    const blog_item = this.props.blog_item;
    let location_content;
    if (blog_item?.address?.DESA) {
      const { PROVINSI, KAB_KOT, KECAMATAN, DESA } = blog_item?.address;
      location_content = (
        <main className="text_inferior">
          {DESA}, {KECAMATAN}, {KAB_KOT}, {PROVINSI}
        </main>
      );
    }
    const market_status = blog_item?.market_status?.status;
    const request_list = blog_item?.request_list ? blog_item?.request_list : [];
    const { loadingProcess, itemLoading } = this.props.blog;
    const { modal_delete, modal_request_action, modal_edit_location } =
      this.state;
    let array_status = [
      {
        name: dict["Private"][language],
        type: "private",
        desc_1: dict["Private documentation"][language],
        img: icon_blog_private,
      },
      {
        name: dict["Public"][language],
        type: "public",
        desc_1: "Public data",
        img: icon_blog_public,
      },
      {
        name: dict["Free with permission"][language],
        type: "free",
        desc_1: dict["Free with permission"][language],
        img: icon_blog_free,
      },
      {
        name: dict["Paid"][language],
        type: "paid",
        desc_1: dict["Data exchange"][language],
        img: icon_blog_paid,
      },
    ];
    array_status = array_status.filter((s) => s.type === market_status)?.[0];
    let status_badge = (
      <main style={{ marginTop: "10px" }}>
        <section className="container_blog_status_img">
          <img alt={array_status?.name} src={array_status?.img} width="100%" />
        </section>
        <section className="container_blog_paragraph">
          <h1 style={{ margin: "0" }}>{array_status?.name}</h1>
          <p>{array_status?.desc_1}</p>
        </section>
        <br />
        <button
          onClick={this.toggleRequestAction}
          className="button_very_small"
          id="blue"
          style={{ marginBottom: "100px" }}
        >
          Request:
          <div
            className="button_very_small"
            id="white"
            style={{ margin: "0 0 0 5px" }}
          >
            {request_list.length}
          </div>
        </button>
      </main>
    );
    let layerArray = [];
    if (!isEmpty(layers)) {
      const layers_filtered = layers.filter(
        (layer) => !!layer && !!layer.geo_layer
      );
      layerArray = layers_filtered.map((layer) => {
        const { geo_layer } = layer;
        return geo_layer;
      });
    }
    let projectArray = [];
    if (!isEmpty(projects)) {
      const projects_filtered = projects.filter(
        (project) => !!project && !!project.geo_project
      );
      projectArray = projects_filtered.map((project) => {
        const { geo_project } = project;
        return geo_project;
      });
    }
    const layersContent = (
      <div style={{ marginBottom: "10px" }}>
        <p className="text_medium">{dict["Layer attached"][language]}</p>
        {isEmpty(layerArray) ? (
          <div>
            <p className="text_inferior">
              {dict["No layer attached"][language]}
            </p>
          </div>
        ) : (
          <div className="carousell" style={{ marginBottom: "10px" }}>
            {layerArray.map((layer) => {
              let layerContent = null;
              let link, _id, name, isPublic;
              if (layer) {
                link = layer?.geo_project?.link;
                _id = layer?._id;
                name = layer?.name;
                isPublic = layer.isPublic;
                const button_open = isPublic ? (
                  <Link to={`/view/${link}`} className="button_very_small">
                    {dict["Open"][language]}
                  </Link>
                ) : (
                  <Link to={`/view/${link}`} className="button_very_small">
                    {dict["Not Public"][language]}
                  </Link>
                );
                layerContent = (
                  <div key={_id} className="container_layer_card">
                    <div className="container_layer_card_name">{name}</div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: "5px",
                      }}
                    >
                      {button_open}
                    </div>
                  </div>
                );
              }
              return layerContent;
            })}
          </div>
        )}
      </div>
    );
    const projectsContent = (
      <div style={{ marginBottom: "10px" }}>
        <p className="text_medium">{dict["Project attached"][language]}</p>
        {isEmpty(projectArray) ? (
          <div>
            <p className="text_inferior">
              {dict["No project attached"][language]}
            </p>
          </div>
        ) : (
          <div className="carousell" style={{ marginBottom: "10px" }}>
            {projectArray.map((project) => {
              const link = project?.link;
              const _id = project?._id;
              const name = project?.name;
              const isPublic = project?.isPublic;
              const button_open = isPublic ? (
                <Link to={`/view/${link}`} className="button_very_small">
                  {dict["Open"][language]}
                </Link>
              ) : (
                <div className="button_very_small">
                  {dict["Not Public"][language]}
                </div>
              );
              return (
                <div key={_id} className="container_layer_card">
                  <div className="container_layer_card_name">{name}</div>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "5px",
                    }}
                  >
                    {button_open}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
    const contentState = convertFromRaw(JSON.parse(editorState));
    const editorStateFinal = EditorState.createWithContent(contentState);
    let className = "RichEditor-editor";
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== "unstyled") {
        className += " RichEditor-hidePlaceholder";
      }
    }
    const itemContent = (
      <main>
        <section className="text_medium" style={{ marginTop: "10px" }}>
          {dict["Publication"][language]}
        </section>
        <section className="text_medium" style={{ marginBottom: "10px" }}>
          {formatDate(date_created)}
        </section>
        <section className="header_container">
          {language === "ina" ? title : title_en || title}
        </section>
        {location_content}
        <Link
          to={`/blog_editor/${link}`}
          className="button_very_small use_margin"
          id="blue"
        >
          {dict["Edit"][language]}
        </Link>
        <section
          onClick={this.toggle_edit_location}
          className="button_very_small use_margin"
          id="blue"
        >
          Edit lokasi
        </section>
        <br />
        <section
          onClick={this.toggleDelete}
          className="button_very_small use_margin"
          id="deleteBright"
        >
          {dict["Delete"][language]}
        </section>
        <br />
        {status_badge}
        {layersContent}
        {projectsContent}
        <section className="RichEditor-root " style={{ marginBottom: "500px" }}>
          <div className={className} onClick={this.focus}>
            <Editor
              blockRendererFn={mediaBlockRenderer}
              blockStyleFn={getBlockStyle}
              customStyleMap={styleMap}
              editorState={editorStateFinal}
              handleKeyCommand={this.handleKeyCommand.bind(this)}
              onChange={this.onChangeEditor}
              placeholder={`${dict["Publication for this data has not yet been written."][language]}`}
              ref="editor"
              spellCheck={false}
              readOnly={true}
            />
          </div>
        </section>
      </main>
    );
    const deleteButton =
      loadingProcess && itemLoading === "delete_blog" ? (
        <div className="button_standard" id="deleteBright">
          {dict["Deleting"][language]}
        </div>
      ) : (
        <div
          onClick={this.handleDelete.bind(this, _id)}
          className="button_standard"
          id="deleteBright"
        >
          {dict["Delete"][language]}
        </div>
      );
    const modal_delete_content = modal_delete && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_delete}
        onClose={this.toggleDelete}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          <p className="text_bold">
            {dict["Are you sure to delete this document?"][language]}
          </p>
          <p className="text_inferior" style={{ marginBottom: "50px" }}>
            {dict["This process can't be undone"][language]}
          </p>
          {deleteButton}
        </div>
      </Modal>
    );
    const modal_request_action_content = modal_request_action && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_request_action}
        onClose={this.toggleRequestAction}
      >
        <div className="box-body">
          <RequestAction />
        </div>
      </Modal>
    );
    const modal_edit_location_content = modal_edit_location && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_edit_location}
        onClose={this.toggle_edit_location}
      >
        <div className="box-body">
          <EditLocation toggle_edit_location={this.toggle_edit_location} />
        </div>
      </Modal>
    );
    return (
      <div>
        {itemContent}
        {modal_delete_content}
        {modal_request_action_content}
        {modal_edit_location_content}
      </div>
    );
  }
}

const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    case "code-block":
      return "RichEditor-code-block";
    case "center":
      return "RichEditor-center";
    default:
      return null;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  blog: state.blog,
});
export default connect(mapStateToProps, {
  getBlogDetail,
  deleteBlog,
})(BlogViewer);
