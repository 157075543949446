import styled from "styled-components";

const ModalFilter = styled.div`
  width: 400px;
  height: 300px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 0;
  z-index: 1;
  margin: -2.3rem auto auto auto;
  &.fade-in {
    opacity: 1;
    -webkit-transition: 0.1s;
    -moz-transition: 0.1s;
    -o-transition: 0.1s;
    transition: 0.1s;
  }
  &.fade-out {
    opacity: 0;
    -webkit-transition: 0.1s;
    -moz-transition: 0.1s;
    -o-transition: 0.1s;
    transition: 0.1s;
  }
  .background {
    background: #1f6fae2a;
    position: fixed;
    z-index: 1040;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    outline: 0;
  }
  .box-dialog {
    border-radius: 5px;
    z-index: 1050;
    width: 100%;
    height: 60vh;
    overflow: auto;
    background-color: ${(props) => {
      if (props.backgroundColor) {
        return props.backgroundColor;
      } else {
        return "#fff";
      }
    }};
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.071);
    .box-body {
      font-size: 14px;
      padding: 15px;
      overflow-y: auto;
      overflow-x: auto;
    }
  }
`;

export default ModalFilter;
