//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
//Personal Component
//Redux function
//Picture Asset
//General Function
import dict from "../../Data/dict.json";
import formatDate from "../../App/validation/format-date";

class FieldsViewer extends Component {
  state = {};
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { properties, fields } = this.props;
    return (
      <div>
        {fields.map((fl, idx) => {
          const { key, type } = fl;
          let value = properties?.[key];
          let content = value;
          switch (type) {
            case "text":
              content = <div className="text_medium use_margin">{value}</div>;
              break;
            case "textarea":
              content = (
                <div
                  style={{
                    whiteSpace: "pre-wrap",
                  }}
                  className="text_medium use_margin"
                >
                  {value}
                </div>
              );
              break;
            case "number":
              content = (
                <div className="text_medium use_margin">
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(value)}
                </div>
              );
              break;
            case "slider":
              content = (
                <div className="text_medium use_margin">
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(value)}
                </div>
              );
              break;
            case "currency":
              content = (
                <div className="text_medium use_margin">
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(value)}
                </div>
              );
              break;
            case "year":
              content = <div className="text_medium use_margin">{value}</div>;
              break;
            case "date":
              content = (
                <div className="text_medium use_margin">
                  {formatDate(value)}
                </div>
              );
              break;
            case "time":
              content = <div className="text_medium use_margin">{value}</div>;
              break;

            case "link":
              let link;
              if (value?.includes("http")) {
                link = value;
              } else {
                link = `https://${value}`;
              }
              content = (
                <a
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text_medium use_margin"
                >
                  {value}
                </a>
              );
              break;
            case "document":
              content = value ? (
                <a
                  href={value}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text_medium use_margin"
                  id="blue"
                >
                  {dict["Open file"][language]}
                </a>
              ) : (
                <div className="text_medium use_margin" id="blue">
                  {dict["No files"][language]}
                </div>
              );
              break;
            case "image":
              content = value ? (
                <main className="play_parent_image">
                  <section
                    className="play_container_image"
                    style={{
                      backgroundImage: `url(${value})`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  />
                </main>
              ) : (
                <div className="text_medium use_margin">No image</div>
              );
              break;
            default:
              content = <div className="text_medium use_margin">{value}</div>;
              break;
          }
          if (!value) {
            content = <p className="text_medium use_margin"></p>;
          }
          return <section key={idx}>{content}</section>;
        })}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(FieldsViewer);
