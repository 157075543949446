//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

//Personal Component
import SpinnerSimpleBlue from "../Components/common_spinner/SpinnerSimpleBlue";
import EditBank from "../Components/user/EditBank";
import Modal from "../Components/common_modal/Modal";

//Redux function
import { getUserPaymentList } from "../App/actions/paymentActions";
import { getCurrentBankData, openModal } from "../App/actions/authActions";

//Picture Asset

//General Function
import isEmpty from "../App/validation/is-empty";
import dict from "../Data/dict.json";

class IncomeList extends Component {
  state = { modal_edit_bank: false };
  componentDidMount() {
    this.props.getUserPaymentList();
    this.props.getCurrentBankData();
  }
  onOpenModal = (name) => {
    this.props.openModal(name);
  };
  toggleEditBank = () => {
    this.setState({ modal_edit_bank: !this.state.modal_edit_bank });
  };
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { modal_edit_bank } = this.state;
    const { payment_list, loadingList } = this.props.payment;
    const { current_bank_data } = this.props.auth;
    const payment_list_bill = payment_list.filter(
      (e) => e.payment_type === "admin_bill"
    );
    const bill_total = payment_list_bill
      .map((payment) => {
        const total_price = payment.blogs_snapshot.map((blog) => {
          return blog.market_status.price;
        });
        return total_price;
      })
      .flat();
    const total_price = bill_total.reduce((a, b) => a + b, 0);
    let itemContent;
    if (loadingList && isEmpty(itemContent)) {
      itemContent = (
        <SpinnerSimpleBlue
          width={200}
          unik="loading_item_list"
          marginTop="0px"
          center={true}
          border={0.1}
        />
      );
    } else {
      const content_array = [
        {
          bubble: (
            <div className="button_very_small" id="blue">
              {dict["Details"][language]}
            </div>
          ),
          name: payment_list_bill.length,
          footer: dict["Number of transactions"][language],
        },
        {
          bubble: (
            <div className="button_very_small" id="blue">
              {dict["Details"][language]}
            </div>
          ),
          name: `Rp ${new Intl.NumberFormat("id-ID", {
            style: "decimal",
          }).format(total_price)}`,
          footer: dict["Total income"][language],
        },
        {
          bubble: (
            <div
              onClick={this.toggleEditBank}
              className="button_very_small"
              id="blue"
            >
              {dict["Edit bank"][language]}
            </div>
          ),
          name: isEmpty(current_bank_data)
            ? dict["Not yet set"][language]
            : dict["Already set"][language],
          footer: dict["Bank data"][language],
        },
      ];
      const content_render = content_array.map((e, idx) => {
        return (
          <main
            key={idx}
            style={{
              backgroundColor: "#fff",
              color: "#556573ff",
              borderRadius: "10px",
              width: "160px",
              maxWidth: "90%",
              padding: "10px",
              display: "inline-block",
              marginRight: "10px",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            <section style={{ textAlign: "right" }}>{e.bubble}</section>
            <section className="text_bold">{e.name}</section>
            <section className="text_inferior">{e.footer}</section>
          </main>
        );
      });
      itemContent = (
        <main>
          <section>
            {dict["Your income history will be displayed here"][language]}
          </section>
          <section>{content_render}</section>
        </main>
      );
    }
    const modal_edit_bank_content = modal_edit_bank && (
      <Modal
        modalSize="medium"
        id="modal_device"
        isOpen={modal_edit_bank}
        onClose={this.toggleEditBank}
      >
        <div className="box-body">
          <EditBank />
        </div>
      </Modal>
    );
    return (
      <div id="main_container">
        <div className="header_medium" style={{ marginBottom: "20px" }}>
          {dict["Income History"][language]}
          <button
            className="button_info"
            id="blue"
            onClick={this.onOpenModal.bind(this, "Data Exchange")}
            style={{ marginLeft: "10px" }}
          >
            i
          </button>
        </div>
        {itemContent}
        {modal_edit_bank_content}
        <Helmet>
          <title>{dict["Income History"][language]}</title>
          <meta name="description" content={dict["Income History"][language]} />
        </Helmet>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  payment: state.payment,
});
export default connect(mapStateToProps, {
  getUserPaymentList,
  getCurrentBankData,
  openModal,
})(IncomeList);
