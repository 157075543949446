//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function
import dict from "../../Data/dict.json";
import { IconOpen } from "./icon";

//Picture Asset
import icon_download from "../../Assets/svg/icon_download.svg";
import icon_view_blue from "../../Assets/svg/icon_view_blue.svg";
import icon_view_grey from "../../Assets/svg/icon_view_grey.svg";

//General Function

class Disaster extends Component {
  state = {
    hide: false,
  };

  render() {
    const { hide } = this.state;
    const { sini_list, request_id } = this.props.layer;
    const language = localStorage?.language ? localStorage?.language : "ina";

    const bencana_banjir = sini_list?.filter(
      (e) => e.request_id === request_id
    )[0]?.bencana_banjir;

    const bencana_longsor = sini_list?.filter(
      (e) => e.request_id === request_id
    )[0]?.bencana_longsor;
    const bencana_tsunami = sini_list?.filter(
      (e) => e.request_id === request_id
    )[0]?.bencana_tsunami;

    const status_array = [
      "Sangat Rendah",
      "Cukup Rendah",
      "Sedang",
      "Cukup Tinggi",
      "Tinggi",
    ];

    const Data_Color = [
      {
        name: "Sangat Rendah",
        color: "#5E972C",
      },
      {
        name: "Cukup Rendah",
        color: "#AFCB40",
      },
      {
        name: "Sedang",
        color: "#FFFF54",
      },
      {
        name: "Cukup Tinggi",
        color: "#F6AE40",
      },
      {
        name: "Tinggi",
        color: "#EE5D2D",
      },
    ];

    const Color_Banjir = Data_Color.find(
      (d) => d.name === bencana_banjir?.features?.[0]?.properties?.Kelas
    );
    const Color_Longsor = Data_Color.find(
      (d) => d.name === bencana_longsor?.features?.[0]?.properties?.Kelas
    );
    const Color_Tsunami = Data_Color.find(
      (d) => d.name === bencana_tsunami?.features?.[0]?.properties?.KELAS
    );

    const render_banjir = (
      <section
        className="container_s"
        style={{
          padding: "10px 5px",
          minHeight: "50px",
          border: `2px solid ${
            Color_Banjir?.color ? Color_Banjir?.color : "#0ca5eb"
          }`,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <p className="text_inferior">
            {dict?.["Flood Vulnerability"]?.[language]}
          </p>
          <h3>
            {bencana_banjir?.features?.[0]?.properties?.Kelas
              ? dict?.[bencana_banjir?.features?.[0]?.properties?.Kelas]?.[
                  language
                ]
              : "-"}
          </h3>
        </div>
        <div className="text_bold" style={{ margin: "-1.5rem 0 10px 0" }}>
          {bencana_banjir?.features?.[0]?.properties?.Kelas && (
            <section>
              <div className="sini_disaster_button">
                <img
                  src={
                    this.props.layer.sini_mode.find(
                      (d) => d === "bencana_banjir"
                    )
                      ? icon_view_blue
                      : icon_view_grey
                  }
                  title={
                    this.props.layer.sini_mode.find(
                      (d) => d === "bencana_banjir"
                    )
                      ? dict?.["Hide"]?.[language]
                      : dict?.["View"]?.[language]
                  }
                  alt="Add "
                  width={25}
                  onClick={() => this.props.sini_mode("bencana_banjir")}
                  style={{ marginRight: "3px", cursor: "pointer" }}
                />
                <img
                  src={icon_download}
                  title="Download Data"
                  alt="Add "
                  width={25}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.props.handleDownloadSini(request_id, "bencana_banjir")
                  }
                />
              </div>
              <table className="table_full_fix text_inferior">
                <tbody>
                  <tr>
                    {status_array.map((s, idx) => {
                      return (
                        <td
                          key={idx}
                          className="text_center"
                          style={{ verticalAlign: "bottom" }}
                        >
                          <p
                            style={{
                              verticalAlign: "bottom",
                              fontSize: "10px",
                              margin: 0,
                              fontWeight: "500",
                            }}
                            className="button_pill_small"
                            id={
                              bencana_banjir?.features?.[0]?.properties
                                ?.Kelas === s
                                ? "grey"
                                : "gray_light"
                            }
                          >
                            {dict?.[s]?.[language]}
                          </p>
                          <p
                            style={{
                              marginTop: "-5px",
                              marginBottom: "0px",
                            }}
                          >
                            •
                          </p>
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
              <div className="gradient" />
            </section>
          )}
        </div>
      </section>
    );

    const render_longsor = (
      <section
        className="container_s"
        style={{
          padding: "10px 5px",
          minHeight: "50px",
          border: `2px solid ${
            Color_Longsor?.color ? Color_Longsor?.color : "#0ca5eb"
          }`,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <p className="text_inferior">
            {dict?.["Landslide Hazard"]?.[language]}
          </p>

          <h3>
            {bencana_longsor?.features?.[0]?.properties?.Kelas
              ? dict?.[bencana_longsor?.features?.[0]?.properties?.Kelas]?.[
                  language
                ]
              : "-"}
          </h3>
        </div>
        <div className="text_bold" style={{ margin: "-1.5rem 0 10px 0" }}>
          {bencana_longsor?.features?.[0]?.properties?.Kelas && (
            <section>
              <div className="sini_disaster_button">
                <img
                  src={
                    this.props.layer.sini_mode.find(
                      (d) => d === "bencana_longsor"
                    )
                      ? icon_view_blue
                      : icon_view_grey
                  }
                  title={
                    this.props.layer.sini_mode.find(
                      (d) => d === "bencana_longsor"
                    )
                      ? dict?.["Hide"]?.[language]
                      : dict?.["View"]?.[language]
                  }
                  alt="Add "
                  width={25}
                  onClick={() => this.props.sini_mode("bencana_longsor")}
                  style={{ marginRight: "3px", cursor: "pointer" }}
                />
                <img
                  src={icon_download}
                  title="Download Data"
                  style={{ cursor: "pointer" }}
                  alt="Add "
                  width={25}
                  onClick={() =>
                    this.props.handleDownloadSini(request_id, "bencana_longsor")
                  }
                />
              </div>
              <table className="table_full_fix text_inferior">
                <tbody>
                  <tr>
                    {status_array.map((s, idx) => {
                      return (
                        <td
                          key={idx}
                          className="text_center"
                          style={{ verticalAlign: "bottom" }}
                        >
                          <p
                            style={{
                              verticalAlign: "bottom",
                              fontSize: "10px",
                              margin: 0,
                              fontWeight: "500",
                            }}
                            className="button_pill_small"
                            id={
                              bencana_longsor?.features?.[0]?.properties
                                ?.Kelas === s
                                ? "grey"
                                : "gray_light"
                            }
                          >
                            {dict?.[s]?.[language]}
                          </p>
                          <p
                            style={{
                              marginTop: "-5px",
                              marginBottom: "0px",
                            }}
                          >
                            •
                          </p>
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
              <div className="gradient" />
            </section>
          )}
        </div>
      </section>
    );

    const render_tsunami = (
      <section
        className="container_s"
        style={{
          padding: "10px 5px",
          minHeight: "50px",
          border: `2px solid ${
            Color_Tsunami?.color ? Color_Tsunami?.color : "#0ca5eb"
          }`,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <p className="text_inferior">
            {dict?.["Tsunami Hazard"]?.[language]}
          </p>
          <h3>
            {bencana_tsunami?.features?.[0]?.properties?.KELAS
              ? dict?.[bencana_tsunami?.features?.[0]?.properties?.KELAS]?.[
                  language
                ]
              : "-"}
          </h3>
        </div>
        <div className="text_bold" style={{ margin: "-1.5rem 0 10px 0" }}>
          {bencana_tsunami?.features?.[0]?.properties?.KELAS && (
            <section>
              <div className="sini_disaster_button">
                <img
                  src={
                    this.props.layer.sini_mode.find(
                      (d) => d === "bencana_tsunami"
                    )
                      ? icon_view_blue
                      : icon_view_grey
                  }
                  title={
                    this.props.layer.sini_mode.find(
                      (d) => d === "bencana_tsunami"
                    )
                      ? dict?.["Hide"]?.[language]
                      : dict?.["View"]?.[language]
                  }
                  alt="Add "
                  width={25}
                  onClick={() => this.props.sini_mode("bencana_tsunami")}
                  style={{ marginRight: "3px", cursor: "pointer" }}
                />
                <img
                  src={icon_download}
                  title="Download Data"
                  style={{ cursor: "pointer" }}
                  alt="Add "
                  width={25}
                  onClick={() =>
                    this.props.handleDownloadSini(request_id, "bencana_tsunami")
                  }
                />
              </div>
              <table className="table_full_fix text_inferior">
                <tbody>
                  <tr>
                    {status_array.map((s, idx) => {
                      return (
                        <td
                          key={idx}
                          className="text_center"
                          style={{ verticalAlign: "bottom" }}
                        >
                          <p
                            style={{
                              verticalAlign: "bottom",
                              fontSize: "10px",
                              margin: 0,
                              fontWeight: "500",
                            }}
                            className="button_pill_small"
                            id={
                              bencana_tsunami?.features?.[0]?.properties
                                ?.KELAS === s
                                ? "grey"
                                : "gray_light"
                            }
                          >
                            {dict?.[s]?.[language]}
                          </p>
                          <p
                            style={{
                              marginTop: "-5px",
                              marginBottom: "0px",
                            }}
                          >
                            •
                          </p>
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
              <div className="gradient" />
            </section>
          )}
        </div>
      </section>
    );

    const content = (
      <section className="sini_card">
        <div className="sini_card_top">
          <div style={{ zIndex: "5" }}>
            <h2>{dict?.["Disaster"]?.[language]}</h2>
            <img
              src={
                this.props.layer.sini_mode.find(
                  (d) =>
                    d === "bencana_banjir" ||
                    d === "bencana_longsor" ||
                    d === "bencana_tsunami"
                )
                  ? icon_view_blue
                  : icon_view_grey
              }
              title={
                this.props.layer.sini_mode.find(
                  (d) =>
                    d === "bencana_banjir" ||
                    d === "bencana_longsor" ||
                    d === "bencana_tsunami"
                )
                  ? dict?.["Hide"]?.[language]
                  : dict?.["View"]?.[language]
              }
              className={hide ? "hide_icon" : "show_icon"}
              alt="Add "
              width={25}
              onClick={() =>
                this.props.sini_mode(
                  bencana_banjir
                    ? "bencana_banjir"
                    : bencana_longsor
                    ? "bencana_longsor"
                    : "bencana_tsunami"
                )
              }
              style={{ marginRight: "3px", cursor: "pointer" }}
            />
            <img
              src={icon_download}
              title="Download Data"
              className={hide ? "hide_icon" : "show_icon"}
              alt="Add "
              width={25}
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.props.handleDownloadSini(
                  request_id,
                  bencana_banjir
                    ? "bencana_banjir"
                    : bencana_longsor
                    ? "bencana_longsor"
                    : "bencana_tsunami"
                )
              }
            />
          </div>
          <IconOpen
            onClick={() => this.setState({ hide: !hide })}
            hide={hide}
          />
        </div>
        <main className={hide ? "sini_scroll" : "sini_scroll_hide"}>
          {render_banjir}
          <br />
          {render_longsor}
          <br />
          {render_tsunami}
        </main>
      </section>
    );

    const no_content = (
      <section className="sini_card">
        <div className="sini_card_top">
          <div style={{ zIndex: "5" }}>
            <h2>{dict?.["Disaster"]?.[language]}</h2>
            <p>
              {
                dict?.[
                  "There is no data at the point you selected, we will complete it soon."
                ]?.[language]
              }
            </p>
          </div>
        </div>
      </section>
    );

    return bencana_banjir?.features?.length === 0 &&
      bencana_longsor?.features?.length === 0 &&
      bencana_tsunami?.features?.length === 0
      ? no_content
      : content;
  }
}
const mapStateToProps = (state) => ({
  layer: state.layer,
  auth: state.auth,
});
export default connect(mapStateToProps, {})(Disaster);
