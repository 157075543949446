//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Personal Component
import Register from "../Components/auth/Register";
import NavigationLanding from "../Components/layout/NavigationLanding";
import LandingAuth from "../Components/layout/LandingAuth";

//Redux function
import { openModal, setLanguage } from "../App/actions/authActions";

//General Function
import dict from "../Data/dict.json";
import domain_list from "../Data/domain_list";

class LandingRegister extends Component {
  state = {
    width: window.innerWidth,
    loginStatus: true,
    specialist_code: "",
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    this.setState({ specialist_code: this?.props?.match?.params?.code });
    const isJoin = this?.props?.location?.pathname?.includes("join");
    this.setState({ loginStatus: !isJoin });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }
  onSetLanguage = (language) => {
    this.props.setLanguage(language);
  };
  onOpenModal = (name) => {
    this.props.openModal(name);
  };
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth }, () => {});
  };
  toggleLogin = () => {
    this.setState({ loginStatus: !this.state.loginStatus });
  };
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const domain = this.props.auth.domain;
    // const { mode_balai } = this.props.auth;
    const { width } = this.state;

    const itemContent =
      width < 600 ? (
        <div className="box_content">
          <h1> {dict["Register"][language]}</h1>
          <Register kode={this?.props?.match?.params?.kode} />
          <div className="box_content_bottom">
            <p className="button_account">
              {dict["Already have an account?"][language]}
            </p>
            <Link to="/login" className="button_register">
              {dict["Login instead"][language]}
            </Link>
          </div>
        </div>
      ) : (
        <div className="box_content">
          <h1> {dict["Register"][language]}</h1>
          <Register kode={this?.props?.match?.params?.kode} />
          <div className="box_content_bottom">
            <p className="button_account">
              {dict["Already have an account?"][language]}
            </p>
            <Link to="/login" className="button_register">
              {dict["Login instead"][language]}
            </Link>
          </div>
        </div>
      );
    return (
      <LandingAuth
        nav={<NavigationLanding />}
        title={domain_list?.[domain]?.title}
        subtitle={domain_list?.[domain]?.sub_title}
        content={itemContent}
      />
      //     <div className="landingKeren">
      //       <NavigationLanding />
      //       {itemContent}
      //       <div id="main_container"></div>
      //       <style>{`
      //     body{
      //       padding-top: 0px;
      //       overflow-y: auto;
      //     }
      //     .navbarDefault{ visibility: hidden;}
      //     #top_nav{ visibility: hidden;}
      //     #sidebar_home{ visibility: hidden;}
      //  `}</style>
      //     </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { openModal, setLanguage })(
  LandingRegister
);
