// library
import React, { Component } from "react";

/*GENERAL*/
import dict from "../../Data/dict.json";

export default class ModalLayerCustomApi extends Component {
  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    const {
      //   modal_layer_name,
      //   isOpen,
      //   onClose,
      //   modal_api_input,
      //   toggle_modal_api_input,
      layer_name,
      handle_layer_name,
      description,
      handle_description,
      handle_back,
      handle_save,
      handle_folder,
      folders,
      folder,
    } = this.props;

    return (
      <main>
        <input
          value={layer_name}
          onChange={handle_layer_name}
          type="text"
          placeholder={dict["Layer name"][language]}
        />
        <select value={folder} onChange={handle_folder}>
          <option key="outside-folders" value="">
            {dict["Outside folders"][language]}
          </option>
          {folders?.map((item) => (
            <option key={item?._id} value={item?._id}>
              {item?.name}
            </option>
          ))}
        </select>
        <textarea
          className="h_500"
          value={description}
          onChange={handle_description}
          type="text"
          placeholder={dict["Description"][language]}
        />
        <div className="flex justify_between">
          <button
            onClick={() => handle_back()}
            className="bg_blue text_white button_inactive padding_5 rounded_5 font_18"
          >
            Back
          </button>
          <button
            onClick={() => handle_save()}
            className="bg_blue text_white button_inactive padding_5 rounded_5 font_18"
          >
            Save
          </button>
        </div>
      </main>
    );
  }
}
