//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

//Personal Component
import SpinnerWhite from "../common_spinner/SpinnerWhite";
import CheckBox from "../common_input/CheckBox";
import TextFieldGroupTight from "../common_input/TextFieldGroupTight";

//Redux function
import { editProposeFormAfterPayment } from "../../App/actions/layerActions";

//Picture Asset

//General Function
import dict from "../../Data/dict.json";
import Kabupaten from "../../Data/Kabupaten.json";
import Provinsi from "../../Data/Provinsi.json";

const cities_array = Kabupaten.sort();
const provinces_array = Provinsi.sort();
const ENTER_KEY = 13;

class EditBillingForm extends Component {
  state = {
    errors: {},
    isDateRestrict: false,
    date_start: moment(Date.now()).add(7, "day").format("yyyy-MM-DD"),
    date_end: moment(Date.now()).add(14, "day").format("yyyy-MM-DD"),
    minResponden: 1,
    maxResponden: 2,
    isProvince: false,
    isCity: false,
    provinces: [],
    cities: [],
    province: "",
    city: "",
    geo_layer_id: "",
  };

  componentDidMount() {
    const { layer_id, geo_layer_list } = this.props.layer;
    const geo_layer = geo_layer_list.find(
      (item) => item.geo_layer._id === layer_id
    )?.geo_layer;

    const formPayment = geo_layer.formPayment;
    const {
      isDateRestrict,
      date_start,
      date_end,
      minResponden,
      maxResponden,
      isProvince,
      isCity,
      provinces,
      cities,
    } = formPayment;

    this.setState({
      geo_layer_id: geo_layer._id,
      isDateRestrict,
      date_start: moment(date_start).format("yyyy-MM-DD"),
      date_end: moment(date_end).format("yyyy-MM-DD"),
      minResponden,
      maxResponden,
      isProvince,
      isCity,
      provinces,
      cities,
    });
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      if (e.target.name === "province") {
        const provinces = this.state.provinces;
        const province = e.target.value;
        provinces.push(province);
        const provinces_filter_double = provinces.filter(
          (value, index) => provinces.indexOf(value) === index
        );
        this.setState({ provinces: provinces_filter_double, province: "" });
      }
      if (e.target.name === "city") {
        const cities = this.state.cities;
        const city = e.target.value;
        cities.push(city);
        const cities_filter_double = cities.filter(
          (value, index) => cities.indexOf(value) === index
        );
        this.setState({ cities: cities_filter_double, city: "" });
      }
    }
  }

  onInput = (e) => {
    if (e.target.name === "province") {
      const val = document.getElementById("provinces").value;
      const opts = document.getElementById("provinces_list").childNodes;
      for (let i = 0; i < opts.length; i++) {
        if (opts[i].value === val) {
          const provinces = this.state.provinces;
          const province = e.target.value;
          provinces.push(province);
          const provinces_filter_double = provinces.filter(
            (value, index) => provinces.indexOf(value) === index
          );
          this.setState({ provinces: provinces_filter_double }, () => {
            this.setState({ province: "" });
          });
        }
      }
    }
    if (e.target.name === "city") {
      const val = document.getElementById("cities").value;
      const opts = document.getElementById("cities_list").childNodes;
      for (let i = 0; i < opts.length; i++) {
        if (opts[i].value === val) {
          const cities = this.state.cities;
          const city = e.target.value;
          cities.push(city);
          const cities_filter_double = cities.filter(
            (value, index) => cities.indexOf(value) === index
          );
          this.setState({ cities: cities_filter_double }, () => {
            this.setState({ city: "" });
          });
        }
      }
    }
  };

  onDeleteProvince = (province) => {
    const provinces = this.state.provinces;
    const index = provinces.indexOf(province);
    provinces.splice(index, 1);
    this.setState({ provinces });
  };

  onDeleteCity = (city) => {
    const cities = this.state.cities;
    const index = cities.indexOf(city);
    cities.splice(index, 1);
    this.setState({ cities });
  };

  onChangeToggle = (e) => {
    this.setState({ [e.target.name]: !this.state[e.target.name] });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    //State
    const {
      geo_layer_id,
      isDateRestrict,
      date_start,
      date_end,
      minResponden,
      maxResponden,
      isProvince,
      isCity,
      provinces,
      cities,
    } = this.state;
    const body = {
      geo_layer_id,
      isDateRestrict,
      date_start,
      date_end,
      minResponden,
      maxResponden,
      isProvince,
      isCity,
      provinces,
      cities,
    };
    this.props.editProposeFormAfterPayment(body);
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const {
      isDateRestrict,
      date_start,
      date_end,
      minResponden,
      maxResponden,
      isProvince,
      isCity,
      province,
      city,
      provinces,
      cities,
      errors,
    } = this.state;

    //global props
    const { loadingProcess, itemLoading } = this.props.layer;

    //content
    const buttonContent =
      loadingProcess && itemLoading === "edit_propose_form_after_payment" ? (
        <div className="button_block_tight">
          <SpinnerWhite
            width={30}
            unik="loading_item_list"
            marginTop="0px"
            center={true}
            border={0.1}
          />
        </div>
      ) : (
        <button className="button_block" type="submit">
          {dict["Save"][language]}
        </button>
      );
    const itemContent = (
      <div>
        <form noValidate autoComplete="off" onSubmit={this.onSubmit}>
          <div className="text_bold" style={{ marginBottom: "13px" }}>
            {dict["Edit Survey Preference"][language]}
          </div>
          <div className="two_container_equal">
            <div className="container_grey">
              {dict["Area preferences"][language]}
              <div>
                <CheckBox
                  text="isProvince"
                  title="Province"
                  value={isProvince}
                  handle={this.onChangeToggle.bind(this)}
                />
                {isProvince ? (
                  <div>
                    <input
                      type="text"
                      placeholder={dict["Select provinces"][language]}
                      autoComplete="off"
                      name="province"
                      value={province}
                      onKeyDown={this.handleKeyDown.bind(this)}
                      onChange={this.onChange.bind(this)}
                      onInput={this.onInput.bind(this)}
                      list="provinces_list"
                      id="provinces"
                    />
                    <datalist id="provinces_list">
                      {provinces_array.map((province_element, idx) => {
                        return <option value={province_element} key={idx} />;
                      })}
                    </datalist>
                  </div>
                ) : (
                  <input disabled />
                )}
                {provinces.map((element, idx) => {
                  return (
                    <div key={idx} className="badge_bold">
                      {element}{" "}
                      <div
                        onClick={this.onDeleteProvince.bind(this, element)}
                        className="button_pill_tight"
                      >
                        x
                      </div>
                    </div>
                  );
                })}
                <CheckBox
                  text="isCity"
                  title="City"
                  value={isCity}
                  handle={this.onChangeToggle.bind(this)}
                />
                {isCity ? (
                  <div>
                    <input
                      type="text"
                      placeholder={dict["Select cities"][language]}
                      autoComplete="off"
                      name="city"
                      value={city}
                      onKeyDown={this.handleKeyDown.bind(this)}
                      onChange={this.onChange.bind(this)}
                      onInput={this.onInput.bind(this)}
                      list="cities_list"
                      id="cities"
                    />
                    <datalist id="cities_list">
                      {cities_array.map((city_element, idx) => {
                        return <option value={city_element} key={idx} />;
                      })}
                    </datalist>
                  </div>
                ) : (
                  <input disabled />
                )}
                {cities.map((element, idx) => {
                  return (
                    <div key={idx} className="badge_bold">
                      {element}
                      <div
                        onClick={this.onDeleteCity.bind(this, element)}
                        className="button_pill_tight"
                      >
                        x
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <div className="container_grey" style={{ marginTop: "10px" }}>
                {dict["Time range"][language]}
                <CheckBox
                  text="isDateRestrict"
                  title={dict["Limit the survey time range"][language]}
                  value={isDateRestrict}
                  handle={this.onChangeToggle.bind(this)}
                />
                {isDateRestrict ? (
                  <div className="two_container_equal">
                    <TextFieldGroupTight
                      type="date"
                      placeholder={dict["Start date"][language]}
                      name="date_start"
                      id="date_start"
                      value={date_start}
                      onChange={this.onChange}
                      error={errors.date_start}
                    />
                    <TextFieldGroupTight
                      type="date"
                      placeholder={dict["End date"][language]}
                      name="date_end"
                      id="date_end"
                      value={date_end}
                      onChange={this.onChange}
                      error={errors.date_end}
                    />
                  </div>
                ) : (
                  <div className="two_container_equal">
                    <TextFieldGroupTight
                      type="date"
                      placeholder={dict["Start date"][language]}
                      name="date_start"
                      id="date_start"
                      value={date_start}
                      onChange={this.onChange}
                      error={errors.date_start}
                      disabled={true}
                    />
                    <TextFieldGroupTight
                      type="date"
                      placeholder={dict["End date"][language]}
                      name="date_end"
                      id="date_end"
                      value={date_end}
                      onChange={this.onChange}
                      error={errors.date_end}
                      disabled={true}
                    />
                  </div>
                )}
              </div>
              <div className="container_grey" style={{ marginTop: "10px" }}>
                <TextFieldGroupTight
                  type="number"
                  placeholder={
                    dict["Minimum respondents for one surveyor"][language]
                  }
                  name="minResponden"
                  id="minResponden"
                  value={minResponden}
                  onChange={this.onChange}
                  error={errors.minResponden}
                />
                Surveyor preference
                <TextFieldGroupTight
                  type="number"
                  placeholder={
                    dict["Maximum respondents for one surveyor"][language]
                  }
                  name="maxResponden"
                  id="maxResponden"
                  value={maxResponden}
                  onChange={this.onChange}
                  error={errors.maxResponden}
                />
              </div>
              <div style={{ marginTop: "30px" }}>{buttonContent}</div>
            </div>
          </div>
        </form>
      </div>
    );
    return <div style={{ minHeight: "75vh" }}>{itemContent}</div>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  payment: state.payment,
  layer: state.layer,
});

export default connect(mapStateToProps, { editProposeFormAfterPayment })(
  EditBillingForm
);
