/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { bbox } from "@turf/turf";

/*PERSONAL COMPONENT*/
import RenderPopupEditor from "../layer/RenderPopupEditor";

/*REDUX FUNCTION*/
import { set_value_properties } from "../../App/actions/propertiesActions";
import { status_action } from "../../App/actions/mapActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import getStatusMember from "../../App/validation/get-status-member";
import { gometry_to_area_or_length } from "../../App/validation/geometry_to_area_or_length";

/*NON IMPORT*/

class FlyTo extends Component {
  state = {};

  toggle_share = () => {
    const feature = this.props.feature;
    this.props.set_value_properties({
      key: "feature_will_be_shared_key",
      value: feature.key,
    });
    const { modal_share } = this.props.properties;
    this.props.set_value_properties({
      key: "modal_share",
      value: !modal_share,
    });
  };

  on_fly_feature = (params) => {
    const feature = this.props.feature;

    this.props.set_value_properties({
      key: "popupInfo",
      value: null,
    });

    //STEP 1 masukkan ke dalam props feature_key_array untuk styling menonjolkan feature yang di select
    this.props.set_value_properties({
      key: "feature_key_selected",
      value: feature.key,
    });

    //STEP 2 mendapatkan data geometry dari feature untuk fly to
    const { geo_layer_list, layer_id } = this.props.layer;

    const layer = geo_layer_list.find(
      (d) => d.geo_layer._id === layer_id
    ).geo_layer;

    const type_2 = layer?.type_2;
    const fields = layer.fields;
    const genangan_cm = feature?.properties?.Ketinggian_Genangan_cm;
    const geometry = feature.geometry;

    const map = this?.props?.map?.map;
    if (map) {
      let [minLng, minLat, maxLng, maxLat] = bbox(feature);
      const padding = { top: 150, bottom: 300, left: 400, right: 400 };
      map?.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding,
          maxZoom: 19,
        }
      );
      let dataPopUP = feature?.properties || {};
      dataPopUP["_geometry"] = gometry_to_area_or_length(geometry);
      const long = (maxLng + minLng) / 2;
      const lat = (maxLat + minLat) / 2;
      let popupInfo = (
        <RenderPopupEditor
          lat={lat}
          long={long}
          fields={fields}
          dataPopUP={dataPopUP}
          type_2={type_2}
          feature_key={dataPopUP.key}
          genangan_cm={genangan_cm}
          geo_layer={layer}
          is_editable={true}
        />
      );
      this.props.set_value_properties({
        key: "popupInfo",
        value: popupInfo,
      });
    }
    this.props.status_action();
    this.props.set_value_properties({
      key: "modal_pop_up_layer",
      value: true,
    });
  };

  render() {
    //local storage

    //local state

    //global props
    const { idx, indexOfFirstTodo } = this.props;
    const { isAuthenticated } = this.props.auth;
    const { user } = this.props.auth;
    const { geo_project } = this.props.project;

    //content
    let is_author = false;

    if (isAuthenticated) {
      let userCurrentStatus;

      const groups = geo_project?.groups || [];
      if (geo_project._id) {
        userCurrentStatus = getStatusMember(groups, user?._id);
      }
      is_author =
        !!userCurrentStatus || !!(geo_project?.user?._id === user?._id);
    }

    return (
      <main>
        <div className="h_full text_left">
          {idx + 1 + indexOfFirstTodo}
          <br />
          <button
            className="button_small margin_right margin_bottom"
            style={{
              whiteSpace: "nowrap",
            }}
            onClick={this.on_fly_feature}
          >
            Fly to
          </button>
          {is_author && (
            <button
              className="button_small"
              onClick={this.toggle_share}
              feature={this.props.feature}
            >
              Share
            </button>
          )}
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  auth: state.auth,
  project: state.project,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_value_properties,
  status_action,
})(FlyTo);
