//Library modul
import MapGL, { Marker } from "@urbica/react-map-gl";
import React, { Component } from "react";
import Geocoder from "react-map-gl-geocoder";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";

//Personal component

//Redux function

//Picture Asset

//General Function
const style = {
  padding: "2.5px",
  color: "#fff",
  cursor: "pointer",
  background: "#1978c8",
  borderRadius: "50%",
  borderStyle: "solid",
  borderColor: "#fff",
  borderWidth: 2.5,
  width: 10,
  height: 10,
};

export default class Map extends Component {
  geocoderContainerRef = React.createRef();
  _map = React.createRef();
  state = {
    mapIsLoaded: true,
    isLocationChangeable: true,
    latitude: -1.3056265244967449,
    longitude: 121.95513009599199,
    viewport: {
      latitude: -1.3056265244967449,
      longitude: 121.95513009599199,
      zoom: 3.5,
    },
    mapStyle:
      "https://api.maptiler.com/maps/streets/style.json?key=CVYOeJckfxqURN5wjKEB",
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.viewport.latitude === -1.3056265244967449 &&
      prevState.viewport.longitude === 121.95513009599199 &&
      this.props?.lat &&
      this.props?.lng
    ) {
      this.setState({
        viewport: {
          ...this.state.viewport,
          latitude: this.props.lat,
          longitude: this.props.lng,
        },
      });
    }
  }

  onDragEnd = (lngLat) => {
    this.props.handleChange({
      target: {
        value: lngLat.lat,
        name: "lat",
      },
    });
    this.props.handleChange({
      target: {
        value: lngLat.lng,
        name: "long",
      },
    });

    this.props.on_get_geocode({ lat: lngLat.lat, long: lngLat.lng });
  };

  _onMapLoaded() {
    this.setState({ mapIsLoaded: true });
  }

  render() {
    const { mapIsLoaded, isLocationChangeable } = this.state;
    return (
      <div style={{ width: "100%", position: "absolute" }}>
        <div
          ref={this.geocoderContainerRef}
          style={{
            position: "absolute",
            top: 10,
            left: 10,
            zIndex: 2,
            width: "90%",
          }}
        />
        <MapGL
          style={{
            width: "100%",
            height: "350px",
            visibility: mapIsLoaded ? "visible" : "hidden",
          }}
          mapStyle={this.state.mapStyle}
          accessToken={
            "pk.eyJ1IjoibWFwaWQiLCJhIjoiY2pqNWtnaW10MGNnMjNrcWg5MHQwY21nNiJ9.voWwMqU73TCDDua3mGCb8g"
          }
          latitude={this.state.viewport.latitude}
          longitude={this.state.viewport.longitude}
          zoom={this.state.viewport.zoom}
          onViewportChange={(viewport) => this.setState({ viewport })}
          attributionControl={false}
          ref={this._map}
          {...this.props}
          onLoad={() => {
            this._onMapLoaded();
          }}
        >
          {isLocationChangeable && (
            <Geocoder
              containerRef={this.geocoderContainerRef}
              mapRef={this._map}
              onViewportChange={(viewport) => {
                this.setState({
                  viewport,
                });
                this.onDragEnd({
                  lat: viewport.latitude,
                  lng: viewport.longitude,
                });
              }}
              mapboxApiAccessToken={
                "pk.eyJ1IjoibWFwaWQiLCJhIjoiY2pqNWtnaW10MGNnMjNrcWg5MHQwY21nNiJ9.voWwMqU73TCDDua3mGCb8g"
              }
              placeholder={"Search location"}
            />
          )}
          <Marker
            longitude={this.props?.lng ? this.props.lng : this.state.longitude}
            latitude={this.props?.lat ? this.props.lat : this.state.latitude}
            onDragEnd={this.onDragEnd}
            draggable={isLocationChangeable}
            anchor={"center"}
            children={<div style={style}></div>}
            offset={[0, 0]}
          />
        </MapGL>
      </div>
    );
  }
}
