import axios from "axios";
import uuid from "../validation/uuid";

import { geoServerBaseUrl } from "./baseUrl";
import { get_quota_access } from "./authActions";

const SERVER_URL = geoServerBaseUrl;
const KEY_MAPBOX = `pk.eyJ1IjoibWFwaWQiLCJhIjoiY2pqNWtnaW10MGNnMjNrcWg5MHQwY21nNiJ9.voWwMqU73TCDDua3mGCb8g`;

const endpoint_sini =
  "https://j7ulnco2bj.execute-api.ap-southeast-3.amazonaws.com/prod";

/*GET METHODE*/

//generate_city_object
export const generate_city_object = (params) => async (dispatch) => {
  try {
    const { lat, long } = params;
    const res = await axios.get(
      SERVER_URL + `/sini/generate_city_object?long=${long}&lat=${lat}`
    );
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "city_object",
        value: res.data,
      },
    });
  } catch (error) {}
};

//get_insight
export const get_insight = (params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };

    //deklarasi parameter & header untuk ke backend
    const {
      provinsi,
      kota,
      kecamatan,
      kelurahan,
      poi_tipe_2_selected_string,
      request_id,
    } = params;

    dispatch({
      type: "set_loading_sini",
      payload: "get_insight",
    });
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "progress_poi_total",
        value: 1,
      },
    });
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "progress_poi_current",
        value: 0,
      },
    });

    dispatch({
      type: "set_value_sini",
      payload: {
        key: "features_poi",
        value: [],
      },
    });

    dispatch({
      type: "set_value_properties",
      payload: {
        key: "save_layer_progress",
        value: 0,
      },
    });

    //mengambil data poligon demografi
    const res = await axios.get(
      SERVER_URL +
        `/sini/get_demografi?provinsi=${provinsi}&kota=${kota}&kecamatan=${kecamatan}&kelurahan=${kelurahan}&request_id=${request_id}`,
      config
    );
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "features_demografi",
        value: res.data,
      },
    });
    dispatch({
      type: "state_update",
    });

    //mengambil data titik POI
    const res_count = await axios.get(
      SERVER_URL +
        `/sini/get_count_poi?provinsi=${provinsi}&kota=${kota}&kecamatan=${kecamatan}&kelurahan=${kelurahan}&poi_tipe_2_selected_string=${poi_tipe_2_selected_string}&request_id=${request_id}`,
      config
    );
    const count_poi = res_count.data;

    if (count_poi === 0) {
      dispatch({
        type: "clear_loading_sini",
      });
      dispatch({
        type: "fly_update",
      });
    }

    const total = count_poi;
    const limit = 100;
    const iteration = Math.ceil(total / limit);
    let array_loop = [];
    for (let i = 0; i < iteration; i++) {
      array_loop.push({
        start: i * limit,
        end: i * limit + limit,
      });
    }
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "progress_poi_total",
        value: array_loop[array_loop.length - 1].end,
      },
    });

    const delay_c = 0; //delay sinkronus o detik
    const delay = () => new Promise((res) => setTimeout(res, delay_c));
    const parent_function = () => {
      return array_loop.reduce(
        (last_promise, object) =>
          last_promise.then((result_sum) =>
            child_function(object).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (object) => {
      return delay().then(async () => {
        try {
          const { start, end } = object;
          const res = await axios.get(
            SERVER_URL +
              `/sini/get_poi?provinsi=${provinsi}&kota=${kota}&kecamatan=${kecamatan}&kelurahan=${kelurahan}&poi_tipe_2_selected_string=${poi_tipe_2_selected_string}&start=${start}&end=${end}&request_id=${request_id}`,
            config
          );
          const features = res.data || [];
          dispatch({
            type: "push_value_sini",
            payload: {
              key: "features_poi",
              value: features,
            },
          });
          dispatch({
            type: "state_update",
          });
          dispatch({
            type: "set_value_sini",
            payload: {
              key: "progress_poi_current",
              value: end,
            },
          });
        } catch (error) {}
      });
    };
    parent_function().then(() => {
      dispatch(get_quota_access());
      dispatch({
        type: "clear_loading_sini",
      });
      dispatch({
        type: "state_update",
      });
      dispatch({
        type: "fly_update",
      });
    });

    return res_count;
  } catch (error) {
    dispatch({
      type: "clear_loading_sini",
    });
  }
};

//push_sini_list
export const push_sini_list = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("push_sini_list"));
    const { lat, long } = params;
    const res = await axios.get(
      endpoint_sini +
        `/get?km_rad=${1}&lat=${lat}&long=${long}&token=${
          localStorage.jwtTokenMapid
        }`
    );
    const body = { ...params, ...res.data, isochrone: [] };
    dispatch({
      type: "push_sini_list",
      payload: body,
    });
    const res_blog = await axios.get(
      SERVER_URL + `/blog/near?long=${long}&lat=${lat}&min=0&max=185000`
    );
    const blog_body = {
      request_id: params.request_id,
      blog_list: res_blog.data,
    };
    dispatch({
      type: "sini_blog",
      payload: blog_body,
    });
    dispatch({
      type: "sini_action",
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//push_change_radius_sini
export const push_change_radius_sini = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("push_change_radius_sini"));
    const { lat, long, radius, request_id } = params;
    const res = await axios.get(
      endpoint_sini +
        `/get?km_rad=${radius}&lat=${lat}&long=${long}&token=${localStorage.jwtTokenMapid}`
    );
    const body = { ...params, ...res.data, isochrone: [], request_id };
    dispatch({
      type: "push_sini_list",
      payload: body,
    });
    const res_blog = await axios.get(
      SERVER_URL + `/blog/near?long=${long}&lat=${lat}&min=0&max=185000`
    );
    const blog_body = {
      request_id: params.request_id,
      blog_list: res_blog.data,
    };
    dispatch({
      type: "sini_blog",
      payload: blog_body,
    });
    dispatch({
      type: "sini_action",
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

// Get Sini Iscorone
export const push_isochrone = (params) => async (dispatch) => {
  const { lat, long, routing_profile, countour_type, interval, color } = params;

  let number;

  let countour_mode = countour_type;
  if (countour_mode === "Time") {
    countour_mode = `contours_minutes`;
  } else {
    countour_mode = `contours_meters`;
    number = interval + "000";
  }

  let value_interval = number === undefined ? interval : number;

  let colors = color.replace(/#/g, "");
  let denoise = `1`;
  let generalize = `0`;
  try {
    const res = await axios.get(
      `https://api.mapbox.com/isochrone/v1/mapbox/${routing_profile}/${long}%2C${lat}?${countour_mode}=${value_interval}&contours_colors=${colors}&polygons=true&denoise=${denoise}&generalize=${generalize}&access_token=${KEY_MAPBOX}`
    );
    const data = {
      ...params,
      key: uuid(),
      geometry: res.data,
      view: false,
      // color: randomColor(),
    };
    dispatch({
      type: "push_isochrone",
      payload: data,
    });
    dispatch({
      type: "sini_action",
    });
  } catch (error) {}
};

/*POST METHODE*/

/*NON API*/

//set_value_sini
export const set_value_sini = (body) => (dispatch) => {
  dispatch({
    type: "set_value_sini",
    payload: body,
  });

  dispatch({
    type: "state_update",
  });
};

// delete isochrone
export const delete_isochrone = (body) => async (dispatch) => {
  try {
    dispatch({
      type: "delete_isochrone",
      payload: body,
    });
    dispatch({
      type: "sini_action",
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//view isochrone
export const view_isochrone = (body) => async (dispatch) => {
  try {
    dispatch({
      type: "view_isochrone",
      payload: body,
    });
    dispatch({
      type: "sini_action",
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//sini_view
export const set_poi_select = (body) => async (dispatch) => {
  try {
    dispatch({
      type: "set_poi_select",
      payload: body,
    });
    dispatch({
      type: "sini_action",
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

// set_sini_all_active
export const set_sini_all_active = (body) => async (dispatch) => {
  try {
    dispatch({
      type: "set_sini_all_active",
      payload: body,
    });
    dispatch({
      type: "sini_action",
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//clear_sini_list
export const clear_sini_list = () => (dispatch) => {
  dispatch({
    type: "clear_sini_list",
  });
  dispatch({
    type: "sini_action",
  });
};

//push_field_bulk
/*
body={
  geo_layer_id,
  fields: []
}
*/
export const push_field_bulk = (body) => (dispatch) => {
  dispatch({
    type: "push_field_bulk",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

//replace_features
/*
body={
  geo_layer_id,
  features: []
}
*/
export const replace_features = (body) => (dispatch) => {
  dispatch({
    type: "replace_features",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

//clearLoading
export const clearLoading = () => {
  return {
    type: "CLEAR_LOADING_LAYER",
  };
};

export const setLoading = (itemLoading) => {
  return {
    type: "SET_LOADING_PROCESS_LAYER",
    payload: itemLoading,
  };
};

// remove sini
export const remove_sini = (params) => (dispatch) => {
  dispatch({
    type: "remove_sini",
    payload: params,
  });
  dispatch({
    type: "sini_action",
  });
};

// add view sini data
export const set_sini_mode = (params) => (dispatch) => {
  dispatch({
    type: "set_sini_mode",
    payload: params,
  });
  dispatch({
    type: "sini_action",
  });
};

//clear_sini_mode
//hide semua tampilan sini
export const clear_sini_mode = () => (dispatch) => {
  dispatch({
    type: "clear_sini_mode",
  });
  dispatch({
    type: "sini_action",
  });
};

export const view_isochrone_id = (params) => (dispatch) => {
  dispatch({
    type: "view_isochrone_id",
    payload: params,
  });
  dispatch({
    type: "sini_action",
  });
};

// non_view_isochrone_id
export const non_view_isochrone_id = (params) => (dispatch) => {
  dispatch({
    type: "non_view_isochrone_id",
    payload: params,
  });
  dispatch({
    type: "sini_action",
  });
};

// remove view sini data
export const del_sini_mode = (params) => (dispatch) => {
  dispatch({
    type: "del_sini_mode",
    payload: params,
  });
  dispatch({
    type: "sini_action",
  });
};

// color_category_poi_sini
export const set_color_category_poi_sini = (body) => async (dispatch) => {
  dispatch({
    type: "color_category_poi_sini",
    payload: body,
  });
  dispatch({
    type: "sini_action",
  });
};

//Set request_id
export const setRequestId = (request_id) => async (dispatch) => {
  dispatch({
    type: "SET_REQUEST_ID",
    payload: request_id,
  });
  dispatch({
    type: "sini_action",
  });
};
