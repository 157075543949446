import React from "react";
import { Source, Layer } from "@urbica/react-map-gl";

const Display = ({
  title,
  data,
  paint,
  type,
  viewStatus,
  layerOnClick,
  icon_image,
  paintLine,
}) => {
  let layout = { visibility: viewStatus ? "visible" : "none" };
  if (type === "symbol") {
    layout = { ...layout, "icon-image": icon_image, "icon-size": 1.5 };
  }
  return (
    <>
      <Source key={title} id={title} type="geojson" data={data} />
      <Layer
        id={title}
        type={type}
        source={title}
        paint={type === "symbol" ? {} : paint}
        layout={layout}
        onClick={layerOnClick}
      />
      {type === "fill" && (
        <Layer
          id={`line_polygon_${title}`}
          type="line"
          source={title}
          paint={paintLine}
          layout={layout}
          // onClick={layerOnClick}
        />
      )}
    </>
  );
};

function CircleColor(getColor, type, outline, array_width_selected) {
  let paint = {
    "circle-color":
      type === "pda"
        ? [
            "match",
            ["get", "status"],
            "siaga1",
            "#ff0000",
            "siaga2",
            "#ffff00",
            "siaga3",
            "#0000ff",
            "normal",
            "#008000",
            "Offline",
            "#000000",
            "delay",
            "#000000",
            "tanpa",
            "#000000",
            "#000000",
          ]
        : getColor,
    "circle-radius":
      type === "pch" || type === "pda" ? 5 : ["get", "circle_radius"],
    "circle-stroke-width":
      type === "pch" || type === "pda"
        ? 2
        : array_width_selected
        ? array_width_selected
        : ["get", "circle_stroke_width"],
    "circle-stroke-color":
      type === "pch" || type === "pda"
        ? "#fff"
        : outline
        ? outline
        : ["get", "circle_stroke_color"],
  };
  return paint;
}

function LineColor(getColor, array_width_selected) {
  let paint = {
    "line-color": getColor,
    "line-width": array_width_selected
      ? array_width_selected
      : ["get", "line_width"],
    "line-opacity": 1,
    "line-gap-width": ["get", "line_gap_width"],
  };
  return paint;
}

function FillColor(getColor, outline, array_width_selected) {
  let paint = {
    "fill-color": getColor,
    "fill-opacity": array_width_selected
      ? array_width_selected
      : ["get", "opacity"],
    "fill-outline-color": outline ? outline : "rgba(0,0,0,0.3)",
  };

  return paint;
}

// function FuncIfType(name, typeOne, typeTwo) {
//   return (name =
//     typeTwo === "pch"
//       ? "symbol"
//       : typeOne === "Point" || typeOne === "MultiPoint"
//       ? "circle"
//       : typeOne === "LineString" || typeOne === "MultiLineString"
//       ? "line"
//       : typeOne === "Polygon" || typeOne === "MultiPolygon"
//       ? "fill"
//       : "circle");
// }

export { Display, CircleColor, LineColor, FillColor };

// Error baru
// import React from "react";
// import { Source, Layer } from "@urbica/react-map-gl";

// const Paragraf = (title, value, idx) => {
//   return (
//     <div className="valueNewPopup" key={idx}>
//       <p>{title}</p>
//       <h3>{value}</h3>
//     </div>
//   );
// };

// const Display = ({
//   title,
//   data,
//   paint,
//   type,
//   viewStatus,
//   layerOnClick,
//   icon_image,
// }) => {
//   let layout = { visibility: viewStatus ? "visible" : "none" };
//   if (type === "symbol") {
//     layout = { ...layout, "icon-image": icon_image, "icon-size": 1.5 };
//   }
//   return (
//     <>
//       <Source key={title} id={title} type="geojson" data={data} />
//       <Layer
//         id={title}
//         type={type}
//         source={title}
//         paint={type === "symbol" ? {} : paint}
//         layout={layout}
//         onClick={layerOnClick}
//       />
//     </>
//   );
// };

// function CircleColor(
//   getColor,
//   type,
//   outline,
//   array_width_selected,
//   circle_radius,
//   circle_stroke_width,
//   circle_stroke_color
// ) {
//   let paint = {
//     "circle-color":
//       type === "pda"
//         ? [
//             "match",
//             ["get", "status"],
//             "siaga1",
//             "#ff0000",
//             "siaga2",
//             "#ffff00",
//             "siaga3",
//             "#0000ff",
//             "normal",
//             "#008000",
//             "Offline",
//             "#000000",
//             "delay",
//             "#000000",
//             "tanpa",
//             "#000000",
//             "#000000",
//           ]
//         : getColor,
//     "circle-radius": circle_radius,
//     "circle-stroke-width":
//       type === "pch" || type === "pda"
//         ? 2
//         : array_width_selected
//         ? array_width_selected
//         : circle_stroke_width,
//     "circle-stroke-color":
//       type === "pch" || type === "pda"
//         ? "#fff"
//         : outline
//         ? outline
//         : circle_stroke_color,
//   };
//   return paint;
// }

// function LineColor(getColor, array_width_selected, line_width, opacity) {
//   let paint = {
//     "line-color": getColor,
//     "line-width": array_width_selected ? array_width_selected : line_width,
//     "line-opacity": opacity,
//     "line-gap-width": ["get", "line_gap_width"],
//   };
//   return paint;
// }

// function FillColor(
//   getColor,
//   outline,
//   array_width_selected,
//   opacity,
//   outline_color
// ) {
//   let paint = {
//     "fill-color": getColor,
//     "fill-opacity": array_width_selected ? array_width_selected : opacity,
//     "fill-outline-color": outline ? outline : outline_color,
//   };

//   return paint;
// }

// function FuncIfType(name, typeOne, typeTwo) {
//   return (name =
//     typeTwo === "pch"
//       ? "symbol"
//       : typeOne === "Point" || typeOne === "MultiPoint"
//       ? "circle"
//       : typeOne === "LineString" || typeOne === "MultiLineString"
//       ? "line"
//       : typeOne === "Polygon" || typeOne === "MultiPolygon"
//       ? "fill"
//       : "circle");
// }

// export { Paragraf, Display, CircleColor, LineColor, FillColor, FuncIfType };

// paint = {
//   "circle-color": ["get", "color"],
//   "circle-radius": circle_radius
//     ? circle_radius
//     : ["get", "circle_radius"],
//   "circle-stroke-width": circle_stroke_width
//     ? circle_stroke_width
//     : ["get", "circle_stroke_width"],
//   "circle-stroke-color": circle_stroke_color
//     ? circle_stroke_color
//     : ["get", "circle_stroke_color"],
// };

// paint = {
//   "line-color": ["get", "color"],
//   "line-width": array_width_selected
//     ? array_width_selected
//     : line_width
//     ? line_width
//     : ["get", "line_width"],
//   "line-opacity": ["get", "opacity"],
//   "line-gap-width": ["get", "line_gap_width"],
// };

// paint = {
//   "fill-color": ["get", "color"],
//   "fill-opacity": array_opacity_selected
//     ? array_opacity_selected
//     : ["get", "opacity"],
//   "fill-outline-color": outline
//     ? outline
//     : outline_color
//     ? outline_color
//     : ["get", "outline_color"],
// };
