import axios from "axios";
import uuid from "../validation/uuid";
import along from "@turf/along";

export const set_value_toolbox = (body) => (dispatch) => {
  dispatch({
    type: "set_value_toolbox",
    payload: body,
  });
  dispatch({
    type: "status_toolbox",
  });
};

export const set_loading_toolbox = (loading_item) => (dispatch) => {
  dispatch({
    type: "set_loading_toolbox",
    payload: loading_item,
  });
};

export const clear_loading_toolbox = () => (dispatch) => {
  dispatch({
    type: "clear_loading_toolbox",
  });
};

export const change_radius = (body) => (dispatch) => {
  dispatch({
    type: "change_radius",
    payload: body,
  });
  dispatch({
    type: "status_toolbox",
  });
};

/**
 *
 * @param {*} body = {
 * geo_layer_id,
 * features_filtered
 * }
 * @returns
 */
export const set_geojson_filtered = (body) => (dispatch) => {
  dispatch({
    type: "set_geojson_filtered",
    payload: body,
  });
  dispatch({
    type: "status_action",
  });
};

export const generate_elevation = (feature) => async (dispatch) => {
  try {
    dispatch(set_loading_toolbox("generate_elevation"));
    /*
    `https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2/tilequery/${ft.geometry.coordinates[0]},${ft.geometry.coordinates[1]}.json?layers=contour&limit=1&access_token=pk.eyJ1IjoibWFwaWQiLCJhIjoiY2pqNWtnaW10MGNnMjNrcWg5MHQwY21nNiJ9.voWwMqU73TCDDua3mGCb8g`

    */
    const length_km = feature?.properties?.length_km;

    const unit = 10;
    const delta = length_km / unit;
    //generate array along
    let array_along = [];
    let features_elevation_point = [];

    //chart
    let data = [];
    let labels = [];
    for (let index = 0; index <= unit; index++) {
      const value = parseFloat((delta * index).toFixed(3));
      array_along.push(value);
    }

    const delay_constant = 0;
    const delay_promise = () =>
      new Promise((res) => setTimeout(res, delay_constant));
    const parent_function = () => {
      return array_along.reduce(
        (last_promise, item, index) =>
          last_promise.then((result_sum) =>
            child_function(item, index).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (item, index) => {
      return delay_promise().then(() => {
        const core_function = async () => {
          try {
            const options = { units: "kilometers" };
            let point = along(feature, item, options);
            const res = await axios.get(
              `https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2/tilequery/${point.geometry.coordinates[0]},${point.geometry.coordinates[1]}.json?layers=contour&limit=1&access_token=pk.eyJ1IjoibWFwaWQiLCJhIjoiY2pqNWtnaW10MGNnMjNrcWg5MHQwY21nNiJ9.voWwMqU73TCDDua3mGCb8g`
            );
            const elevation = res?.data?.features?.[0]?.properties?.ele;
            data.push(elevation);
            labels.push(index);
            point.properties.elevation = elevation;
            point.properties.uuid = uuid();
            point.properties.parent_uuid = feature?.properties?.uuid;
            features_elevation_point.push(point);
          } catch (error) {}
        };
        return core_function();
      });
    };
    parent_function().then(() => {
      dispatch({
        type: "push_value_toolbox",
        payload: {
          key: "features_elevation_point",
          value: features_elevation_point,
        },
      });
      dispatch({
        type: "push_value_toolbox",
        payload: {
          key: "elevation_chart_array",
          value: [
            {
              parent_uuid: feature?.properties?.uuid,
              data,
              labels,
            },
          ],
        },
      });
      dispatch({
        type: "status_toolbox",
      });
      dispatch(clear_loading_toolbox());
    });
  } catch (error) {}
};
