/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";
import PaymentTable from "../payment/PaymentTable";
// import BuyQuota from "./BuyQuota";

/*REDUX FUNCTION*/
import { set_modal_quota_access } from "../../App/actions/authActions";

/*PICTURE ASSET*/

/*GENERAL*/

class ErrorFree extends Component {
  state = {
    modal_pricing: false,
    modal_quota_price: false,
  };
  toggle_pricing = () => {
    this.setState({ modal_pricing: !this.state.modal_pricing });
  };

  toggle_modal_quota_price = () => {
    this.setState({ modal_quota_price: !this.state.modal_quota_price });
  };

  render() {
    //local storage

    //local state
    const { modal_pricing } = this.state;

    //global props
    const { modal_quota_access } = this.props.auth;
    const { admin_information_list } = this.props.properties;

    //content

    const insight_bispro = admin_information_list.find(
      (item) => item.key === "insight_bispro"
    )?.value_string;

    // monthly_sub_quota = tampil keduanya
    // monthly_sub = tampil cuman lisensi

    const modal_pricing_content = modal_pricing && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_pricing}
        onClose={this.toggle_pricing}
      >
        <div className="box-body">
          <PaymentTable />
        </div>
      </Modal>
    );

    return (
      <main>
        {modal_pricing_content}
        <h1 className="text_header">Beli akses untuk menggunakan fitur ini</h1>
        <br />
        <br />
        <button className="button" id="blue" onClick={this.toggle_pricing}>
          Pilih lisensi
        </button>
        {insight_bispro !== "monthly_sub" && (
          <button
            className="button"
            id="blue"
            onClick={() =>
              this.props.set_modal_quota_access(!modal_quota_access)
            }
            style={{ marginLeft: "10px" }}
          >
            Beli kuota akses
          </button>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_modal_quota_access,
})(ErrorFree);
