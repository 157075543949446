/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import BuyNow from "./BuyNow";
import Modal from "../common_modal/Modal";

/*REDUX FUNCTION*/
import { get_price_2023 } from "../../App/actions/paymentActions";

/*PICTURE ASSET*/
import checklist_grey from "../../Assets/svg/checklist_grey.svg";
import checklist_gold from "../../Assets/svg/checklist_gold.svg";

/*GENERAL*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class PaymentTable extends Component {
  state = {
    modal_buy_now: false,
    license_object: {},
  };

  componentDidMount() {
    this.props.get_price_2023();
  }

  toggleBuyNow = (license_object) => {
    this.setState({ modal_buy_now: !this.state.modal_buy_now }, () => {
      if (license_object) {
        this.setState({ license_object });
      }
    });
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const { modal_buy_now, license_object } = this.state;

    //global props
    const { price_list } = this.props.payment;

    //content

    const prices_render = price_list.map((e, idx) => {
      const { key, prices, color, is_hide_price } = e;

      const name = e?.name;

      const features = e?.features?.[language] || [];
      const feature_list = features.split("\n");
      const base_price = prices?.[0]?.price;
      const discount =
        (Number(prices?.[0]?.discount_percent) / 100) * base_price;
      const discounted_price = base_price - discount;

      return (
        <main className="container_pricing" key={idx} id="outline_blue">
          <p className="header_medium" style={{ color }}>
            {name?.[language]}
          </p>
          <br />
          <br />

          {!!discount && (
            <p
              className="text_pricing"
              style={{ textDecoration: "line-through" }}
            >
              {key !== "license_4"
                ? `Rp ${new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(base_price)}`
                : "-"}
            </p>
          )}

          {!is_hide_price && (
            <section>
              <p className="text_header">
                Rp
                {new Intl.NumberFormat("id-ID", {
                  style: "decimal",
                }).format(discounted_price)}
              </p>
              <p className="text_inferior">
                {key !== "license_4" ? `/${dict["month"][language]}` : "-"}
              </p>
              {e?.discount_text?.[language] && (
                <p className="badge_pill">{e?.discount_text?.[language]}</p>
              )}
            </section>
          )}

          <br />
          <br />
          <div className="container_pricing_child">
            {feature_list.map((feature, idx) => {
              return (
                <div
                  className="two_container_list"
                  style={{ marginBottom: "10px" }}
                  key={idx}
                >
                  <img
                    src={key === "license_4" ? checklist_gold : checklist_grey}
                    width="18px"
                    alt="checklist_grey"
                  />
                  <div className="text_feature_pricing">{feature}</div>
                </div>
              );
            })}
          </div>
          {/* <button
            onClick={this.toggleBuyNow.bind(this, e)}
            className="button_block"
            style={{
              left: "20px",
              width: "100%",
              backgroundColor: color,
              boxSizing: "border-box",
            }}
          >
            {dict["Buy"][language]}
          </button> */}
          <a
            className="button_block"
            style={{
              left: "20px",
              width: "100%",
              backgroundColor: color,
              boxSizing: "border-box",
            }}
            href="https://wa.me/6287829400138"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact us
          </a>
        </main>
      );
    });

    const modal_buy_now_content = modal_buy_now && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_buy_now}
        onClose={this.toggleBuyNow}
      >
        <div className="box-body">
          <BuyNow license_object={license_object} />
        </div>
      </Modal>
    );

    return (
      <main>
        <h1 className="text_header margin_bottom">
          {dict["GEO MAPID License"][language]}
        </h1>
        <br />
        <section>{prices_render}</section>

        {modal_buy_now_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  payment: state.payment,
});

export default connect(mapStateToProps, { get_price_2023 })(PaymentTable);
