//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function
import { get_quota_price_list } from "../../App/actions/propertiesActions";
import {
  buy_coin,
  getUserPaymentDetail,
  clear_payment_object,
  dummy_confirm_coin_will_be_delete_soon,
} from "../../App/actions/paymentActions";
import { get_quota_access } from "../../App/actions/authActions";

//Picture Asset
import checklist from "../../Assets/svg/checklist.svg";

//General Function

let interval_id = {};

class QoutaPriceList extends Component {
  state = {
    view: "qouta price list",
    quota: 0,
    dummy_clicked: false,
  };

  componentDidMount() {
    this.props.get_quota_price_list();
    this.props.clear_payment_object();
  }

  componentDidUpdate(prevProps) {
    if (
      this?.props?.payment?.payment?._id !== prevProps?.payment?.payment?._id &&
      this?.props?.payment?.payment?._id
    ) {
      console.log("update start");
      interval_id[this?.props?.payment?.payment?._id] = setInterval(
        this.on_refresh_payment,
        7_000
      );
      const midtransToken = this?.props?.payment?.payment?.midtrans?.token;
      window.snap.pay(midtransToken, {
        onSuccess: function (result) {
          /* You may add your own implementation here */
          // alert("payment success!");
          // history.push("/user_profile");
        },
        onPending: function (result) {
          /* You may add your own implementation here */
          // alert("wating your payment!");
          // history.push("/purchase_list");
        },
        onError: function (result) {
          /* You may add your own implementation here */
          // alert("payment failed!");
          // history.push("/purchase_list");
        },
        onClose: function () {
          /* You may add your own implementation here */
          // history.push("/purchase_list");
        },
      });
    } else if (
      this?.props?.payment?.payment?.status !==
        prevProps?.payment?.payment?.status &&
      this?.props?.payment?.payment?._id
    ) {
      console.log("update_finish");
      clearInterval(interval_id[this.props.payment.payment?._id]);
      window.snap.hide();
    }
  }

  on_refresh_payment = () => {
    const { payment } = this.props.payment;
    if (payment._id) {
      console.log("refresh");
      this.props.getUserPaymentDetail(payment._id);
      this.props.get_quota_access();
    }
  };

  on_dummy_confirm_coin_will_be_delete_soon = () => {
    const body = {
      payment_id: this?.props?.payment?.payment?._id,
    };
    this.props.dummy_confirm_coin_will_be_delete_soon(body);
    this.setState({ dummy_clicked: true });
  };

  select_qouta(quota) {
    this.setState({ quota: quota });
  }

  on_buy_coin = () => {
    let { quota } = this.state;
    if (quota === 0) {
      quota = this?.props?.properties?.quota_price_list?.[0]?.quota;
    }
    const body = {
      quota: quota,
    };
    this.props.buy_coin(body);
  };

  componentWillUnmount() {
    clearInterval(interval_id[this.props.payment.payment?._id]);
    this.props.clear_payment_object();
  }

  render() {
    //local storage

    //local state
    let { quota } = this.state;

    //global props
    const { quota_price_list } = this.props.properties;
    const { payment } = this.props.payment;
    // const { quota_access } = this.props.auth;

    // console.log("payment=", payment);
    // console.log("quota_access=", quota_access);

    if (quota === 0) {
      quota = this?.props?.properties?.quota_price_list?.[0]?.quota;
    }

    //content
    let content;

    if (!payment._id) {
      content = (
        <>
          <h1 className="text_header">Beli kuota akses</h1>
          <section className="row_qouta_price">
            {quota_price_list.map((item, index) => {
              const rupiah_per_akses = item.price / item.quota;
              return (
                <figure
                  key={index}
                  className="card_qouta_price"
                  style={{
                    backgroundColor:
                      quota === item.quota ? "#fff" : "rgb(225, 228, 255)",
                  }}
                  onClick={this.select_qouta.bind(this, item.quota)}
                >
                  <figcaption>
                    <p>{item.quota} akses</p>
                    <p>
                      Rp.
                      {new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(item?.price)}
                    </p>
                  </figcaption>
                  <figcaption>
                    <h4>
                      {new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(rupiah_per_akses)}{" "}
                      rupiah per akses
                    </h4>
                  </figcaption>
                </figure>
              );
            })}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button className="button" id="blue" onClick={this.on_buy_coin}>
                Beli akses sekarang
              </button>
            </div>
          </section>
        </>
      );
    } else if (payment?.status === "success") {
      content = (
        <section className="text_center">
          <p className="text_header">
            Pembayaran telah berhasil, saldo akses telah terisi, Anda bisa
            menutup ini
          </p>
          <br />
          <br />
          <img alt="success" src={checklist} style={{ width: "50%" }} />
        </section>
      );
    } else {
      content = (
        <>
          {/* <p>Hanya test nanti teks ini akan dihapus</p>

          <section
            className="container_light"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {`
              this.props.payment = {
                _id: ${payment?._id},
                status: ${payment?.status},
                payment_methode: ${payment?.payment_methode},
                price: ${payment?.price},
                amount: ${payment?.amount},
                date_in: ${payment?.date_in},
                date_user_action: ${payment?.date_user_action},
              }

              this.props.auth.user.quota_access = ${quota_access}
            `}
          </section>
          <br />
          <br />
          {!dummy_clicked ? (
            <button
              className="button"
              onClick={this.on_dummy_confirm_coin_will_be_delete_soon}
            >
              Konfirmasi midtrans dummy
            </button>
          ) : (
            <button className="button" id="grey">
              Tunggu, sedang refresh per 7 detik
            </button>
          )} */}
        </>
      );
    }

    return <main className="max_height">{content}</main>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  properties: state.properties,
  payment: state.payment,
});

export default connect(mapStateToProps, {
  get_quota_price_list,
  buy_coin,
  getUserPaymentDetail,
  clear_payment_object,
  dummy_confirm_coin_will_be_delete_soon,
  get_quota_access,
})(QoutaPriceList);
