const initial_state = {
  loading_item: "",
  loading_status: false,
  features_demografi: [],
  features_poi: [],
  state_update: 0,
  fly_update: 0,
  progress_poi_total: 1,
  progress_poi_current: 0,

  features_genangan: [],
  features_genangan_feature_key: [],
  feature_key_loading_genangan: "",
  feature_key_fly_genangan: "",
};

export default function dataReducer(state = initial_state, action) {
  switch (action.type) {
    case "state_update":
      return {
        ...state,
        state_update: state.state_update + 1,
      };
    case "fly_update":
      return {
        ...state,
        fly_update: state.fly_update + 1,
      };
    case "set_loading_sini":
      return {
        ...state,
        loading_item: action.payload,
        loading_status: true,
      };
    case "clear_loading_sini":
      return {
        ...state,
        loading_item: "",
        loading_status: false,
      };
    case "set_value_sini":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case "push_value_sini":
      return {
        ...state,
        [action.payload.key]: [
          ...state[action.payload.key],
          ...action.payload.value,
        ],
      };
    case "pull_value_sini_string":
      return {
        ...state,
        ...pull_value_sini_string([action.payload.key], action.payload.value),
      };
    case "pull_value_sini_object":
      return {
        ...state,
        ...pull_value_sini_object([action.payload.key], action.payload.value),
      };
    default:
      return state;
  }
}

const pull_value_sini_string = (list, value) => {};

const pull_value_sini_object = (list, value) => {};
