//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

//Personal Component
import FormListRenderPaid from "../Components/form/FormListRenderPaid";

//Redux function
import { createLayer, getListPaidForm } from "../App/actions/layerNewActions";
import { getProjectListAll } from "../App/actions/projectActions";

//Picture Asset

//General Function
import isEmpty from "../App/validation/is-empty";
import dict from "../Data/dict.json";

class FormListDashboard extends Component {
  componentDidMount() {
    this.props.getListPaidForm();
  }

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { paid_form_list, loadingProcess, itemLoading } = this.props.layer;

    const buttonsContent = (
      <main style={{ marginBottom: "10px" }}>
        <button className="button_pill">
          <Link to="/form">{dict?.["Regular Form"]?.[language]}</Link>
        </button>
      </main>
    );

    let item_content;
    if (
      loadingProcess &&
      itemLoading === "get_layer_point" &&
      isEmpty(paid_form_list)
    ) {
      item_content = (
        <div className="button_standard">
          {dict["Loading your data"][language]}
        </div>
      );
    } else {
      item_content = (
        <main style={{ textAlign: "center" }}>
          <section className="header_medium" style={{ marginBottom: "20px" }}>
            {dict?.["Paid Survey"]?.[language]}
          </section>
          {buttonsContent}
          <FormListRenderPaid />
        </main>
      );
    }

    return (
      <main id="main_container" style={{ marginBottom: "20px" }}>
        {item_content}
        <Helmet>
          <title>FORM</title>
          <meta name="description" content="FORM" />
        </Helmet>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  project: state.project,
});

export default connect(mapStateToProps, {
  getListPaidForm,
  getProjectListAll,
  createLayer,
})(FormListDashboard);
