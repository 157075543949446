import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";
import unique_list_layer_by_id from "../validation/unique_list_layer_by_id";

const SERVER_URL = geoServerBaseUrl;

/*POST METHODE*/

/**
 * get_pinned_project_list
 * @endpoint : /projects/pin
 * @methode : GET
 */
export const get_pinned_project_list = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    const res = await axios.get(SERVER_URL + `/projects/pin`, config);

    if (res?.status === 200) {
      dispatch({
        type: "push_many_values_project",
        payload: {
          key: "geo_project_pinned_list",
          value: res?.data,
        },
      });
    }
  } catch (error) {}
};

/**
 * pin_project
 * @endpoint : /projects/pin_project
 * @body : geo_project_id
 * @method : POST
 */
export const pin_project = (body, project) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    const res = await axios.post(
      SERVER_URL + `/projects/pin_project`,
      body,
      config
    );

    if (res?.status === 200) {
      dispatch({
        type: "push_value_project",
        payload: {
          key: "geo_project_pinned_list",
          value: project,
        },
      });

      dispatch({
        type: "pull_value_project_object",
        payload: {
          key: "geo_project_list",
          value: body?.geo_project_id,
          id: "_id",
        },
      });
    }
  } catch (error) {}
};

/**
 * unpin_project
 * @endpoint : /projects/unpin_project
 * @body : geo_project_id
 * @method : POST
 */
export const unpin_project = (body, project) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    const res = await axios.post(
      SERVER_URL + `/projects/unpin_project`,
      body,
      config
    );

    if (res?.status === 200) {
      dispatch({
        type: "push_value_project",
        payload: {
          key: "geo_project_list",
          value: project,
        },
      });

      dispatch({
        type: "pull_value_project_object",
        payload: {
          key: "geo_project_pinned_list",
          value: body?.geo_project_id,
          id: "_id",
        },
      });
    }
  } catch (error) {}
};

//clone_project
export const clone_project = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("clone_project"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    const res = await axios.post(
      SERVER_URL + "/projects/clone_project",
      body,
      config
    );
    dispatch({
      type: "CREATE_PROJECT",
      payload: res.data,
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//Edit Project General
export const editProject = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("edit_project"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    const res = await axios.post(
      SERVER_URL + "/layers_new/edit_project",
      body,
      config
    );

    const new_project = res?.data || {};

    const key = body.key;
    dispatch({
      type: "edit_value_project",
      payload: {
        key: key,
        value: new_project,
        id_key: "_id",
        id_value: new_project?._id,
      },
    });

    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//Push Folder to project
export const pushFolder = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    dispatch(setLoadingProcess());
    const res = await axios.post(
      SERVER_URL + "/projects/push_folder",
      body,
      config
    );
    dispatch({
      type: "EDIT_PROJECT",
      payload: res.data,
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//Edit Folder
export const editFolder = (item) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    dispatch(setLoadingProcess());
    const res = await axios.post(
      SERVER_URL + "/projects/edit_folder",
      item,
      config
    );

    dispatch({
      type: "EDIT_PROJECT",
      payload: res.data,
    });

    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//Delete Folder
export const deleteFolder = (item) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    dispatch(setLoadingProcess());
    const res = await axios.post(
      SERVER_URL + "/projects/delete_folder",
      item,
      config
    );

    dispatch({
      type: "EDIT_PROJECT",
      payload: res.data,
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//Push Members to Project (isGroups = true) DONE
export const pushMemberThen = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    dispatch(setLoadingProcess());
    const res = await axios.post(
      SERVER_URL + "/projects/push_member_then",
      body,
      config
    );
    const new_project = res?.data || {};
    const key = body.key;
    dispatch({
      type: "edit_value_project",
      payload: {
        key: key,
        value: new_project,
        id_key: "_id",
        id_value: body.new_project._id,
      },
    });

    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//Push Group to Project
export const pushGroup = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    dispatch(setLoadingProcess());
    const res = await axios.post(
      SERVER_URL + "/projects/push_group",
      body,
      config
    );

    const new_project = res?.data || {};
    const key = body?.key;
    dispatch({
      type: "edit_value_project",
      payload: {
        key: key,
        value: new_project,
        id_key: "_id",
        id_value: new_project?._id,
      },
    });

    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//Edit Member role
export const editMember = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    dispatch(setLoadingProcess());
    const res = await axios.post(
      SERVER_URL + "/projects/edit_member",
      body,
      config
    );

    const new_project = res?.data || {};
    const key = body.key;
    dispatch({
      type: "edit_value_project",
      payload: {
        key: key,
        value: new_project,
        id_key: "_id",
        id_value: new_project?._id,
      },
    });

    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//Delete Member from group inside Project DONE
export const deleteMember = (item) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    dispatch(setLoadingProcess());

    const res = await axios.post(
      SERVER_URL + "/projects/delete_member",
      item,
      config
    );

    dispatch({
      type: "EDIT_PROJECT",
      payload: res.data,
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//Delete Group inside Project
export const deleteGroup = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    dispatch(setLoadingProcess());
    const res = await axios.post(
      SERVER_URL + "/projects/delete_group",
      body,
      config
    );

    const new_project = res?.data || {};
    const key = body.key;
    dispatch({
      type: "edit_value_project",
      payload: {
        key: key,
        value: new_project,
        id_key: "_id",
        id_value: new_project?._id,
      },
    });

    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//Delete Project
export const deleteProject = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    dispatch(setLoadingProcess());
    await axios.delete(
      SERVER_URL + `/projects/delete_project/${body?.geo_project_id}`,
      config
    );

    dispatch({
      type: "pull_value_project_object",
      payload: {
        key: body?.key,
        value: body?.geo_project_id,
        id: "_id",
      },
    });

    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

/*GET METHODE*/

//Get Project List By Owner Id & By Group Members Id [NEW]
const defaultParams = { limit: 0, skip: 0, append: false };

export const getProjectList =
  (params = defaultParams) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          accesstoken: localStorage.jwtTokenMapid,
        },
      };
      dispatch(setLoadingList());
      dispatch({ type: "SET_LOAD_MORE_LOADING", payload: true });
      const limit = params.limit ? params.limit : 0;
      const skip = params.skip ? params.skip : 0;

      const res = await axios.get(
        SERVER_URL + `/projects/get_list_new?limit=${limit}&skip=${skip}`,
        config
      );

      dispatch(clearLoading());
      if (params.append) {
        let lastProject = params.lastProject;
        let newListProject = [...lastProject, ...res.data];
        dispatch({
          type: "GET_PROJECT_LIST",
          payload: newListProject,
        });
        if (res.data.length === 0) {
          dispatch({
            type: "SET_LOAD_MORE_BTN",
            payload: false,
          });
        }
      } else {
        dispatch({
          type: "GET_PROJECT_LIST",
          payload: res.data,
        });
        dispatch({
          type: "SET_LOAD_MORE_BTN",
          payload: true,
        });
      }
      dispatch({ type: "SET_LOAD_MORE_LOADING", payload: false });
    } catch (error) {
      dispatch(clearLoading());
      dispatch({
        type: "GET_PROJECT_LIST",
        payload: [],
      });
    }
  };

//Get All Project List Simpled (_id & name)
export const getProjectListAll = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    const res = await axios.get(
      SERVER_URL + `/projects/get_list_new/all`,
      config
    );
    dispatch(clearLoading());
    dispatch({
      type: "GET_PROJECT_LIST_ALL",
      payload: res.data,
    });
  } catch (error) {
    dispatch(clearLoading());
    dispatch({
      type: "GET_PROJECT_LIST_ALL",
      payload: [],
    });
  }
};

export const get_project_all_only_name = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    const res = await axios.get(
      SERVER_URL + `/projects/get_project_all_only_name`,
      config
    );
    dispatch(clearLoading());
    dispatch({
      type: "get_project_all_only_name",
      payload: res.data,
    });
  } catch (error) {
    dispatch(clearLoading());
    dispatch({
      type: "get_project_all_only_name",
      payload: [],
    });
  }
};

//Get Project List By Domain
export const getProjectListPublic = (domain) => async (dispatch) => {
  try {
    dispatch(setLoading("get_list_public_by_domain"));
    const res = await axios.get(
      SERVER_URL + `/projects/get_list_public_by_domain/${domain}`
    );
    dispatch({
      type: "GET_PROJECT_LIST_PUBLIC",
      payload: res.data,
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//Get Project List Public [MAPID]
export const getProjectListPublicMapid = () => async (dispatch) => {
  try {
    dispatch(setLoadingList());
    const res = await axios.get(SERVER_URL + `/projects/get_list_public`);
    dispatch(clearLoading());
    dispatch({
      type: "GET_PROJECT_LIST_PUBLIC",
      payload: res.data,
    });
  } catch (error) {
    dispatch(clearLoading());
    dispatch({
      type: "GET_PROJECT_LIST_PUBLIC",
      payload: [],
    });
  }
};

//Get Project List Public [CILIWUNG]
export const getProjectListPublicCiliwung = () => async (dispatch) => {
  try {
    dispatch(setLoadingList());
    const res = await axios.get(SERVER_URL + `/projects/get_list_ciliwung`);
    dispatch(clearLoading());
    dispatch({
      type: "GET_PROJECT_LIST_PUBLIC",
      payload: res.data,
    });
  } catch (error) {
    dispatch(clearLoading());
    dispatch({
      type: "GET_PROJECT_LIST_PUBLIC",
      payload: [],
    });
  }
};

//Get Project List Public [BRANTAS]
export const getProjectListPublicBrantas = () => async (dispatch) => {
  try {
    dispatch(setLoadingList());
    const res = await axios.get(SERVER_URL + `/projects/get_list_brantas`);
    dispatch(clearLoading());
    dispatch({
      type: "GET_PROJECT_LIST_PUBLIC",
      payload: res.data,
    });
  } catch (error) {
    dispatch(clearLoading());
    dispatch({
      type: "GET_PROJECT_LIST_PUBLIC",
      payload: [],
    });
  }
};

//Get Project Detail By Link, use token or public in same API [NEW NEW]
export const getProjectDetailByLink = (link) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    dispatch(setLoadingDetail());
    const res = await axios.get(
      SERVER_URL + `/projects/get_detail_by_link_new/${link}?limit=1&skip=0`,
      config
    );
    dispatch({
      type: "GET_PROJECT_DETAIL",
      payload: res.data,
    });
    const layers = res?.data?.layers ? res?.data?.layers : [];

    dispatch({
      type: "GET_LAYER_LIST",
      payload: layers,
    });
    dispatch({
      type: "SET_ARRAY_INFO_ANALYZER",
      payload: layers,
    });
    dispatch(clearLoading());

    // kode pagination loop layers
    let layer_paginations = layers;
    let loop = true;
    let skip = 1,
      limit = 50;
    dispatch(setLoadingProcess("Loading Layers"));
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "get_layer_list_status",
        value: "loading",
      },
    });
    while (loop) {
      const res = await axios.get(
        SERVER_URL +
          `/projects/get_detail_by_link_new/${link}?limit=${limit}&skip=${skip}`,
        config
      );
      const layers = res?.data?.layers ? res?.data?.layers : [];
      if (layers.length === 0) {
        loop = false;
      } else {
        layer_paginations = [...layer_paginations, ...layers];
        layer_paginations = unique_list_layer_by_id(layer_paginations);
        skip += limit;
        dispatch({
          type: "GET_LAYER_LIST",
          payload: layer_paginations,
        });
        dispatch({
          type: "SET_ARRAY_INFO_ANALYZER",
          payload: layer_paginations,
        });
      }
    }
    dispatch({ type: "CLEAR_LOADING_LAYER" });
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "get_layer_list_status",
        value: "done",
      },
    });
  } catch (error) {
    dispatch(clearLoading());
    dispatch({
      type: "GET_PROJECT_DETAIL",
      payload: {},
    });
  }
};

//get_history_project
export const get_history_project = () => async (dispatch) => {
  try {
    dispatch(setLoadingDetail());

    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    const res = await axios.get(
      SERVER_URL + `/projects/get_history_project`,
      config
    );
    dispatch({
      type: "GET_PROJECT_DETAIL",
      payload: res.data,
    });
    const layers = res?.data?.layers ? res?.data?.layers : [];
    dispatch({
      type: "GET_LAYER_LIST",
      payload: layers,
    });
    dispatch({
      type: "SET_ARRAY_INFO_ANALYZER",
      payload: layers,
    });

    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
    dispatch({
      type: "GET_PROJECT_DETAIL",
      payload: {},
    });
  }
};

/*NON API*/

export const clearProject = () => {
  return {
    type: "CLEAR_PROJECT",
  };
};

export const clearProjectList = () => {
  return {
    type: "CLEAR_PROJECT_LIST",
  };
};

export const setLoading = (itemLoading) => {
  return {
    type: "SET_LOADING_PROJECT",
    payload: itemLoading,
  };
};

export const setLoadingProcess = (itemLoading) => {
  return {
    type: "SET_LOADING_PROCESS_LAYER",
    payload: itemLoading,
  };
};

export const setLoadingDetail = () => {
  return {
    type: "SET_LOADING_DETAIL_PROJECT",
  };
};

export const setLoadingList = () => {
  return {
    type: "SET_LOADING_LIST_PROJECT",
  };
};

export const clearLoading = () => {
  return {
    type: "CLEAR_LOADING_PROJECT",
  };
};

export const setProject = (project) => {
  return {
    type: "GET_PROJECT_DETAIL",
    payload: project,
  };
};

export const setOpenAllFolder = () => {
  return {
    type: "SET_OPEN_ALL_FOLDER",
  };
};

export const setCloseAllFolder = () => {
  return {
    type: "SET_CLOSE_ALL_FOLDER",
  };
};

export const setCloseFolder = (folder_id) => {
  return {
    type: "SET_CLOSE_FOLDER",
    payload: folder_id,
  };
};

export const setHeightFolders = (height_array) => {
  return {
    type: "SET_HEIGHT_FOLDERS",
    payload: height_array,
  };
};

export const set_value_project = (body) => {
  return {
    type: "set_value_project",
    payload: body,
  };
};

export const push_value_project = (body) => {
  return {
    type: "push_value_project",
    payload: body,
  };
};
