import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";

const SERVER_URL = geoServerBaseUrl;


export const get_all_exist_projects = () => async (dispatch) => {
    try {
        const config = {
            headers: {
                accesstoken: localStorage.jwtTokenMapid,
            },
        };

        const limit = 15
        let skip = 0

        dispatch(setLoadingProcess("get_projects_from_trash"));

        let result = []
        let total_data = 0;
        do {
            const res = await axios.get(SERVER_URL + `/projects/get_list_new?limit=${limit}&skip=${skip}`, config);
            if (res?.status === 200) {
                total_data = res?.data?.length;
                result = [...result, ...res?.data]
                skip += limit;
            }
        } while (total_data !== 0)

        dispatch(clearLoading());
        return result

    } catch (e) {
        dispatch(clearLoading());
        return []
    }
};

export const delete_project_to_recovery = (body) => async (dispatch) => {
    try {
        const config = {
            headers: {
                accesstoken: localStorage.jwtTokenMapid,
            },
        };

        const { project_id } = body

        dispatch(setLoadingProcess("delete_project_to_recovery"));

        await axios.delete(SERVER_URL + `/projects/delete_project/${project_id}`, config);

        dispatch(clearLoading());

    } catch (e) {
        dispatch(clearLoading());
    }
};

export const get_projects_from_recovery = (body) => async (dispatch) => {
    try {
        const config = {
            headers: {
                accesstoken: localStorage.jwtTokenMapid,
            },
        };

        const { limit = 15, skip = 0, attribute = "trash.updated_at", sort_value = -1 } = body

        dispatch(setLoadingProcess("get_projects_from_trash"));

        const result = await axios.get(SERVER_URL + `/projects/get_list_trash?limit=${limit}&skip=${skip}&sort_field=${attribute}&sort_value=${sort_value}`, config);

        dispatch(clearLoading());

        if (result?.status === 200) {
            return result?.data
        }


    } catch (e) {
        dispatch(clearLoading());
        return []
    }
};

export const restore_project = (body) => async (dispatch) => {
    try {
        const config = {
            headers: {
                accesstoken: localStorage.jwtTokenMapid,
            },
        };

        dispatch(setLoadingProcess("restore_project"));

        const res = await axios.post(SERVER_URL + `/projects/restore_trash`, body, config);

        dispatch(clearLoading());

        if (res?.status === 200) {
            dispatch({
                type: "CLEAR_PROJECT_LIST",
            })
            return res?.data;
        }

    } catch (e) {
        dispatch(clearLoading());
    }
};


export const delete_project_permanent = (body) => async (dispatch) => {
    try {
        const config = {
            headers: {
                accesstoken: localStorage.jwtTokenMapid,
            },
        };

        dispatch(setLoadingProcess("delete_project_permanent"));

        const res = await axios.post(SERVER_URL + `/projects/delete_permanent`, body, config);

        dispatch(clearLoading());

        if (res?.status === 200) {
            return res?.data;
        }

    } catch (e) {
        dispatch(clearLoading());
    }
};


export const setLoadingProcess = (itemLoading) => {
    return {
        type: "SET_LOADING_PROCESS_LAYER",
        payload: itemLoading,
    };
};

export const clearLoading = () => {
    return {
        type: "CLEAR_LOADING_LAYER",
    };
};