const geoServer = "https://geoserver.mapid.io";
const geoServerLocal = "http://localhost:4000";
const geoServerIp = `http://${window.location.host}:4000`;

const iotServer = "https://api.mapid.io";
const iotServerLocal = "http://localhost:4001";
const iotServerIp = `http://${window.location.host}:4001`;

const uploadServer = "https://geoserver.mapid.io";
const uploadLocal = "http://localhost:4000";

const server_befa = "https://serverbefaupload.mapid.io";
const server_befa_upload = "https://serverbefaupload.mapid.io";

const mode = "online"; // online | offline | ip

const domain = window.location.hostname;

if (domain === "befa.mapid.io" || domain === "befa-alpha.mapid.io") {
  module.exports = {
    geoServerBaseUrl: server_befa,
    iotServerBaseUrl: server_befa,
    uploadServerUrl: server_befa_upload,
  };
} else {
  if (mode === "offline") {
    module.exports = {
      geoServerBaseUrl: geoServerLocal,
      iotServerBaseUrl: iotServerLocal,
      uploadServerUrl: uploadLocal,
    };
  } else if (mode === "ip") {
    module.exports = {
      geoServerBaseUrl: geoServerIp,
      iotServerBaseUrl: iotServerIp,
    };
  } else {
    module.exports = {
      geoServerBaseUrl: geoServer,
      iotServerBaseUrl: iotServer,
      uploadServerUrl: uploadServer,
    };
  }
}
