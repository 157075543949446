/*LIBRARY MODULE*/
import { FormatRupiah } from "@arismun/format-rupiah";
import { Popup } from "@urbica/react-map-gl";
import { useDispatch } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { get_genangan } from "../../App/actions/algoritma_genangan";

/*PICTURE ASSET*/

/*GENERAL*/
import { dot_every_3_digits } from "../../App/validation/format_number";
import { convertUnit } from "../../App/validation/geometry_to_area_or_length";
import format_date_formal from "../../App/validation/format_date_formal";

/*NON IMPORT*/

const PopupValue = (params) => {
  const { data, dataPopUP } = params;
  const { value, unit } = convertUnit(dataPopUP["_geometry"]?.value, data.unit);

  return (
    <div className="valueNewPopup">
      {
        <>
          <p>{data?.name}</p>
          {data?.type === "text" ? (
            <h3>{dataPopUP[data?.key]}</h3>
          ) : data?.type === "textarea" ? (
            <h3 className="popup_textarea">{dataPopUP[data?.key]}</h3>
          ) : data?.type === "number" ? (
            <h3>{dataPopUP[data?.key]}</h3>
          ) : data?.type === "phone_number" ? (
            <h3>+{dataPopUP[data?.key]}</h3>
          ) : data?.type === "currency" ? (
            <h3>
              <FormatRupiah value={dataPopUP[data?.key]} />
            </h3>
          ) : data?.type === "year" ? (
            <h3>{dataPopUP[data?.key]}</h3>
          ) : data?.type === "date" ? (
            <h3>{format_date_formal(dataPopUP[data?.key])}</h3>
          ) : data?.type === "time" ? (
            <h3>{dataPopUP[data?.key]}</h3>
          ) : data?.type === "selection" ? (
            <h3>{dataPopUP[data?.key]}</h3>
          ) : data?.type === "radio_button" ? (
            <h3>{dataPopUP[data?.key]}</h3>
          ) : data?.type === "checklist" ? (
            <h3>{dataPopUP[data?.key]?.split(" ")}</h3>
          ) : data?.type === "image" ? (
            <img
              src={dataPopUP[data.key]}
              alt={data?.name}
              className="photo_popup"
            />
          ) : data?.type === "document" ? (
            <a
              href={dataPopUP[data?.key]}
              className="button_very_small"
              id="blue"
            >
              Open File
            </a>
          ) : data?.type === "link" ? (
            <a
              href={`${dataPopUP[data?.key]}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {dataPopUP[data?.key]}
            </a>
          ) : data?.type === "auto_address" ? (
            <h3>{dataPopUP[data?.key]}</h3>
          ) : data?.type === "auto_address_poi" ? (
            <h3>{dataPopUP[data?.key]}</h3>
          ) : data?.type === "table" ? (
            <h3>{dataPopUP[data?.key]}</h3>
          ) : data?.type === "calculate_dimension" ? (
            <h3>{`${dot_every_3_digits(value)} ${unit}`}</h3>
          ) : (
            <h3>{dataPopUP[data?.key]}</h3>
          )}
        </>
      }
    </div>
  );
};

const RenderPopup = ({
  long,
  lat,
  value,
  type_2,
  feature_key,
  genangan_cm,
}) => {
  const dispatch = useDispatch();

  const on_get_genangan = () => {
    const params = {
      feature_key,
      latitude: lat,
      longitude: long,
      genangan_cm,
    };
    dispatch(get_genangan(params));
  };

  return (
    <Popup
      tipSize={7}
      anchor="bottom"
      longitude={long}
      latitude={lat}
      closeButton={true}
      closeOnClick={false}
    >
      <div className="close_button_popup" />
      <div className="newPopup">
        {type_2 === "banjir" && (
          <>
            <button className="button margin_bottom" onClick={on_get_genangan}>
              Luas genangan banjir
            </button>
            <button className="button margin_bottom">Share</button>
          </>
        )}

        {value}
      </div>
    </Popup>
  );
};

export { PopupValue, RenderPopup };
