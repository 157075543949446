//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Personal Component
import Modal from "../common_modal/Modal";

//Redux function
import { getProjectList } from "../../App/actions/projectActions";
import {
  importLayer,
  getLayerProjectBlogNew,
  migrateBlog,
} from "../../App/actions/blogAction";

//Picture Asset
import official_account from "../../Assets/svg/official_account.svg";
import loading_icon from "../../Assets/svg/loading.svg";

//General Function
import dict from "../../Data/dict.json";

class ImportBlog extends Component {
  state = {
    layer_id_list: [],
    project_id: "",
    project_link: "",
    modal_success: false,
  };
  async componentDidMount() {
    await this.props.getProjectList();
    const { blog, layer_blog_list } = this.props.blog;
    const { projects, link } = blog;
    const geo_project_list = projects.map((e) => {
      return e.geo_project;
    });
    const geo_project_list_old = geo_project_list.slice();
    // const geo_project_list_old = geo_project_list.filter(
    //   (e) => e.migration_status !== "migrated"
    // );
    if (geo_project_list_old.length > 0) {
      this.props.migrateBlog(link);
    } else if (layer_blog_list.length === 0) {
      this.props.getLayerProjectBlogNew(link);
    }
  }
  toggleSuccess = () => {
    this.setState({ modal_success: !this.state.modal_success });
  };
  onClickLayer = (layer_id) => {
    const { layer_id_list } = this.state;
    layer_id_list.push(layer_id);
    this.setState({ layer_id_list });
  };
  onRemoveLayer = (layer_id) => {
    const { layer_id_list } = this.state;
    const layer_id_list_filtered = layer_id_list.filter(
      (layer_el) => layer_el !== layer_id
    );
    this.setState({ layer_id_list: layer_id_list_filtered });
  };
  onClickProject = (project_id, project_link) => {
    this.setState({ project_id, project_link });
  };
  handleSubmitImport = () => {
    this.setState({ modal_success: true });
    const { blog } = this.props.blog;
    const { layer_id_list, project_id } = this.state;
    const body = {
      blog_id: blog._id,
      layer_id_list,
      project_id,
    };
    this.props.importLayer(body);
  };
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { layer_id_list, project_id, project_link, modal_success } =
      this.state;
    const { blog, layer_blog_list, itemLoading, loadingProcess } =
      this.props.blog;
    const { geo_project_list } = this.props.project;
    const layers = blog.layers;
    const layers_ori = layers.map((layer) => layer.geo_layer);
    let layers_total = [...layers_ori];
    let layers_total_new = [...layers_ori];
    if (layer_blog_list !== "") {
      layers_total = layers_ori.concat(layer_blog_list);
      layers_total_new = layers_total.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t._id === thing._id)
      );
    }
    const loading_content = layer_blog_list === "" && (
      <div style={{ display: "block" }}>
        <hr />
        <button className="flex_container">
          <section className="flex_child">
            <section
              className="container_blog_status_img_like"
              style={{
                margin: "auto",
                backgroundColor: "#ecf6fe",
                height: "17px",
                width: "17px",
              }}
            />
          </section>
          <section className="flex_child">
            {dict["Getting layers from projects"][language]}...
          </section>
        </button>
      </div>
    );
    const list_content = layers_total_new.map(({ _id, name }) => {
      let content = (
        <main
          key={_id}
          style={{ marginBottom: "5px" }}
          onClick={this.onClickLayer.bind(this, _id)}
        >
          <button className="flex_container">
            <section className="flex_child">
              <section
                className="container_blog_status_img_like"
                style={{
                  margin: "auto",
                  backgroundColor: "#ecf6fe",
                  height: "17px",
                  width: "17px",
                }}
              />
            </section>
            <section className="flex_child">{name}</section>
          </button>
        </main>
      );
      if (layer_id_list.includes(_id)) {
        content = (
          <main
            key={_id}
            style={{ marginBottom: "5px" }}
            onClick={this.onRemoveLayer.bind(this, _id)}
          >
            <button className="flex_container">
              <section className="flex_child">
                <section
                  className="container_blog_status_img_like"
                  style={{
                    margin: "auto",
                    backgroundColor: "#ecf6fe",
                    height: "17px",
                    width: "17px",
                  }}
                >
                  <img alt="like" src={official_account} width="100%" />
                </section>
              </section>
              <section className="flex_child">{name}</section>
            </button>
          </main>
        );
      }
      return content;
    });
    const project_list_content = geo_project_list.map(({ _id, name, link }) => {
      let content = (
        <main
          key={_id}
          style={{ marginBottom: "5px" }}
          onClick={this.onClickProject.bind(this, _id, link)}
        >
          <button className="flex_container">
            <section className="flex_child">
              <section
                className="container_blog_status_img_like"
                style={{
                  margin: "auto",
                  backgroundColor: "#ecf6fe",
                  height: "17px",
                  width: "17px",
                }}
              />
            </section>
            <section className="flex_child">{name}</section>
          </button>
        </main>
      );
      if (project_id === _id) {
        content = (
          <main key={_id} style={{ marginBottom: "5px" }}>
            <button className="flex_container">
              <section className="flex_child">
                <section
                  className="container_blog_status_img_like"
                  style={{
                    margin: "auto",
                    backgroundColor: "#ecf6fe",
                    height: "17px",
                    width: "17px",
                  }}
                >
                  <img alt="like" src={official_account} width="100%" />
                </section>
              </section>
              <section className="flex_child">{name}</section>
            </button>
          </main>
        );
      }
      return content;
    });
    const from_content = (
      <div
        style={{
          width: "50%",
          minWidth: "200px",
          display: "inline-block",
          verticalAlign: "top",
        }}
      >
        <h3 style={{ textAlign: "center" }}>{dict["From"][language]}</h3>
        <div
          style={{
            height: "55vh",
            overflowY: "auto",
            overflowX: "hidden",
            padding: "10px",
            marginBottom: "20px",
          }}
        >
          {list_content}
          {loading_content}
        </div>
      </div>
    );
    const to_content = (
      <div
        style={{
          width: "50%",
          minWidth: "200px",
          display: "inline-block",
          verticalAlign: "top",
        }}
      >
        <h3 style={{ textAlign: "center" }}>{dict["To"][language]}</h3>
        <div
          style={{
            height: "55vh",
            overflowY: "auto",
            overflowX: "hidden",
            padding: "10px",
            marginBottom: "20px",
          }}
        >
          {project_list_content}
        </div>
      </div>
    );
    let import_button = (
      <div className="button_block" id="blue_disable">
        {dict["Import"][language]}
      </div>
    );
    if (layer_id_list.length > 0 && project_id !== "") {
      import_button = (
        <div
          onClick={this.handleSubmitImport}
          className="button_block"
          id="blue"
        >
          {dict["Import"][language]}
        </div>
      );
    } else if (loadingProcess && itemLoading === "import_layer") {
      import_button = (
        <div className="button_block" id="blue">
          {dict["Importing"][language]}...
        </div>
      );
    }
    let import_status = (
      <main>
        <img src={official_account} width="100px" alt="official account" />
        <p className="text_bold" style={{ marginBottom: "20px" }}>
          {dict["Layers has been successfully imported"][language]}
        </p>
        <Link
          to={`/editor/${project_link}`}
          className="button_standard"
          target="_blank"
        >
          {dict["Check the project"][language]}
        </Link>
      </main>
    );
    if (loadingProcess && itemLoading === "import_layer") {
      import_status = (
        <main>
          <img src={loading_icon} width="100px" alt="official account" />
          <p className="text_bold" style={{ marginBottom: "20px" }}>
            {dict["Importing layer"][language]}
          </p>
        </main>
      );
    }
    const modal_success_content = modal_success && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_success}
        onClose={this.toggleSuccess}
      >
        <div
          className="box-body"
          style={{ textAlign: "center", minHeight: "300px" }}
        >
          {import_status}
        </div>
      </Modal>
    );
    return (
      <main>
        <div className="text_bold" style={{ textAlign: "center" }}>
          {dict["Import Layers From Blog"][language]}
        </div>
        <div style={{ width: "100%" }}>
          {from_content}
          {to_content}
        </div>
        {import_button}
        {modal_success_content}
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  blog: state.blog,
  project: state.project,
});
export default connect(mapStateToProps, {
  getProjectList,
  importLayer,
  getLayerProjectBlogNew,
  migrateBlog,
})(ImportBlog);
