//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Personal Component
import IconPlay from "../Components/common_icon/IconPlay";

//Redux function
import {
  get_list_public,
  get_tag_list,
  get_list_public_by_tag,
} from "../App/actions/playActions";

//Picture Asset

//General Function

class PlayListPublic extends Component {
  state = {};

  componentDidMount() {
    this.props.get_list_public();
    this.props.get_tag_list();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location?.search !== prevProps.location?.search) {
      const str = this.props.location.search;
      const params = Object.fromEntries(new URLSearchParams(str));
      let tag = `${params.tag}`;
      this.props.get_list_public_by_tag(tag);
    }
  }

  render() {
    let { play_list_public, tag_list } = this.props.play;
    const tag_list_content = (
      <main className="carousell">
        {tag_list.map((t, idx) => {
          let { text } = t;
          text = text.substring(1);
          return (
            <Link
              to={`/play_list?tag=${text}`}
              key={idx}
              style={{
                backgroundColor: "#000",
                color: "#fff",
                margin: "5px 0px 5px 13px",
                padding: "5px 10px 5px 10px",
                borderRadius: "5px",
              }}
            >
              {text}
            </Link>
          );
        })}
      </main>
    );
    const play_list_content = (
      <main>
        {play_list_public.map((p, idx) => {
          const { title, caption, link, user, views } = p;
          const url_compressed = user?.profile_picture?.url_compressed;
          const full_name = user?.full_name;
          const playButton = (
            <Link to={`/play/${link}`} className="icon_edit_on_container">
              <IconPlay color="#52606e" />
            </Link>
          );
          return (
            <main className="container_blog_card" id="white" key={idx}>
              <main className="container_square">
                <section
                  className="container_first_half_blog"
                  style={{ width: "100%" }}
                >
                  <div className="two_container_small_right">
                    <section>
                      <h1 className="title_project">{title}</h1>
                      <div className="sub_title_project">{caption}</div>
                    </section>
                    <section>
                      {playButton}
                      <p className="text_inferior text_center use_margin">
                        {views} views
                      </p>
                    </section>
                  </div>
                </section>
                <section className="container_card_footer">
                  <div
                    style={{
                      fontSize: "13px",
                      color: "#52606e",
                      marginBottom: "10px",
                      textAlign: "left",
                    }}
                  >
                    <div
                      className="pp_img_left"
                      style={{
                        backgroundImage: `url(${url_compressed})`,
                      }}
                    />
                    {full_name}
                  </div>
                </section>
              </main>
            </main>
          );
        })}
      </main>
    );
    return (
      <div style={{ textAlign: "center" }} id="main_container">
        <section className="text_bold use_margin">Trending hashtag</section>
        {tag_list_content}
        {play_list_content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ play: state.play });

export default connect(mapStateToProps, {
  get_list_public,
  get_tag_list,
  get_list_public_by_tag,
})(PlayListPublic);
