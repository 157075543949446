/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datasheet/lib/react-datasheet.css";
import { bbox } from "@turf/turf";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";
import EditField from "./EditField";
import PushField from "./PushField";
import ParentTable from "../viewer_table/ParentTable";
import NumberAndAction from "../custom_cell/NumberAndAction";
import CustomToolbarContainer from "./CustomToolbarContainer";
import NumberAndActionHeader from "../custom_cell/NumberAndActionHeader";
import ModalValidasi from "../editor_manage_survey/ModalValidasi";
import ModalReject from "../editor_manage_survey/ModalReject";
import TableDataPembanding from "./TableDataPembanding";
import ColumnSetupDownloadLaporanPdf from "../editor_manage_survey/ColumnSetupDownloadLaporanPdf";
import ModalAssignSurveyor from "../editor_manage_survey/ModalAssignSurveyor";
import Table from "../viewer_table/Table";
import DownloadLaporanPdf from "../custom_cell/DownloadLaporanPdf";
import RenderPopupEditor from "../layer/RenderPopupEditor";

/*REDUX FUNCTION*/
import {
  pushFeature,
  pushField,
  editProperties,
  editPropertiesV2,
  setFlyFeatures,
  deleteFeature,
  deleteFieldForm,
  deleteField,
  setTabelStatus,
} from "../../App/actions/layerActions";
import { openModal } from "../../App/actions/authActions";
import {
  set_value,
  set_feature_key_array,
  set_layer_id,
} from "../../App/actions/dataActions";
import {
  getAllFeatures,
  getAllFields,
  getLayerDetails,
} from "../../App/reduxHelper/layer";
import { set_value_properties } from "../../App/actions/propertiesActions";
import { status_action } from "../../App/actions/mapActions";

/*PICTURE ASSET*/
import pic_static from "../../Assets/svg/profile_pic_comment.svg";

/*GENERAL*/
import {
  convertFieldsToHeaders,
  convertPropertiesToDataList,
  packThePropertiesDataToUseApiEdit,
} from "../../App/validation/convert_data";
import dict from "../../Data/dict.json";
import is_empty from "../../App/validation/is-empty";
import { calculateWithoutConvert } from "../../App/validation/convert_data";
import { StyledDataGrid } from "../../Style/StyledDataGrid";
import { useGridApiRef } from "@mui/x-data-grid";
import { gometry_to_area_or_length } from "../../App/validation/geometry_to_area_or_length";

/*NON IMPORT*/
const geo_layer_status_that_editable = ["original", "digitasi", "upload"];

class EditorProperties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalPushField: false,
      modalDeleteFeature: false,
      modalDeleteField: false,
      modalNotification: false,
      modalAssign: false,
      modalValidasi: false,
      modalReject: false,
      // modal_table: false,
      EditField: false,
      rowSelected: -1,
      layer_id: "",
      headers: [],
      data_list: [],
      feature_key: "",
      set_key_feature: false,
      old_cell: {},
      fields: [],
      // fields_for_hide_purpose: [],
      field: {},
      modal_data: "",
      modal_data_pembanding: false,
      current_page: { page: 0, pageSize: 0 },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // after
    const layer_id_after = this.props.layer.layer_id;
    const status_action_after = this.props.map.status_action;
    const tableStatus_after = this.props.layer.tableStatus;

    // before
    const layer_id_before = prevProps.layer.layer_id;
    const status_action_before = prevProps.map.status_action;
    const tableStatus_before = prevProps.layer.tableStatus;

    if (
      layer_id_after !== layer_id_before ||
      status_action_after !== status_action_before ||
      tableStatus_after !== tableStatus_before
    ) {
      this.on_render();
    }

    const geo_layer = this.props.layer.geo_layer;
    const prev_layer = prevProps.layer.geo_layer;
    if (geo_layer?.geo_layer?._id !== prev_layer?.geo_layer?._id) {
      this.setState({ modalDeleteFeature: false });
    }
  }

  on_fly_feature = (params) => {
    this.props.set_value_properties({
      key: "popupInfo",
      value: null,
    });

    const feature_key_selected = params.row.key;

    //STEP 1 masukkan ke dalam props feature_key_array untuk styling menonjolkan feature yang di select
    this.props.set_value_properties({
      key: "feature_key_selected",
      value: feature_key_selected,
    });

    //STEP 2 mendapatkan data geometry dari feature untuk fly to
    const { geo_layer_list, layer_id } = this.props.layer;

    const layer = geo_layer_list.find(
      (d) => d.geo_layer._id === layer_id
    ).geo_layer;

    const type_2 = layer?.type_2;
    const geojson = layer.geojson;
    const fields = layer.fields;
    const features = geojson?.features || [];
    const feature = features.find((d) => d.key === feature_key_selected);
    const genangan_cm = feature?.properties?.Ketinggian_Genangan_cm;
    const geometry = feature.geometry;

    const map = this?.props?.map?.map;
    if (map) {
      let [minLng, minLat, maxLng, maxLat] = bbox(feature);
      const padding = { top: 150, bottom: 300, left: 400, right: 400 };
      map?.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding,
          maxZoom: 19,
        }
      );
      let dataPopUP = feature?.properties || {};
      dataPopUP["_geometry"] = gometry_to_area_or_length(geometry);
      const long = (maxLng + minLng) / 2;
      const lat = (maxLat + minLat) / 2;
      let popupInfo = (
        <RenderPopupEditor
          lat={lat}
          long={long}
          fields={fields}
          dataPopUP={dataPopUP}
          type_2={type_2}
          feature_key={dataPopUP.key}
          genangan_cm={genangan_cm}
          geo_layer={layer}
          is_editable={true}
        />
      );
      this.props.set_value_properties({
        key: "popupInfo",
        value: popupInfo,
      });
    }
    this.props.status_action();
    this.props.set_value_properties({
      key: "modal_pop_up_layer",
      value: true,
    });
  };

  toggle_data_pembanding = (
    feature_key_survey,
    feature_key_pembanding_list
  ) => {
    this.setState({ modal_data_pembanding: !this.state.modal_data_pembanding });
    this.props.set_value("feature_key_survey", feature_key_survey);
    this.props.set_value(
      "feature_key_pembanding_list",
      feature_key_pembanding_list
    );
  };

  togglePushField = () => {
    this.setState({ modalPushField: !this.state.modalPushField });
  };

  toggle_table = (field_parent, feature_parent, child) => {
    this.setState({ modal_table: !this.state.modal_table });

    if (field_parent) {
      this.props.set_value("field_parent", field_parent);
      this.props.set_value("feature_parent", feature_parent);
      this.props.set_value("child", child);
    } else {
      //bagaimana cara balikin ke parent fieldnya lagi
      this.props.set_value("child", null);
      const child = this.props.child;

      if (child) {
        const { layer_id, geo_layer_list, feature_parent } = this.props.layer;

        const layer = geo_layer_list.find(
          (l) => l.geo_layer._id === layer_id
        )?.geo_layer;

        const fields = layer?.fields || [];

        let child_array = feature_parent?.child_array || [];

        const item = child_array.find(
          (c) => c.child_uuid === child.parent_uuid_temp
        );
        const parent_uuid = item?.parent_uuid;

        const field_parent_new = fields.find((f) => f.uuid === parent_uuid);

        this.props.set_value("field_parent", field_parent_new);
        this.props.set_value("child", undefined);
      }
    }
  };

  toggleDeleteField = (params) => {
    const field = this.state.fields.find(
      (field) => field?.key === params?.field
    );
    this.setState({
      modalDeleteField: !this.state.modalDeleteField,
      field: field || {},
    });
  };

  toggleEditField = (params) => {
    const field = this.state.fields.find(
      (field) => field?.key === params?.field
    );
    this.setState({
      modalEditField: !this.state.modalEditField,
      field: field || {},
    });
  };

  features_key_active = async (params, value) => {
    // select highlight
    const key = params.row.key;
    const body = { key, value };
    this.setState({
      set_key_feature: false,
    });
    await this.props.set_feature_key_array(body);
  };

  features_key_non_active = (params, value) => {
    const key = params.row.key;

    //STEP 1 masukkan ke dalam props feature_key_array untuk styling menonjolkan feature yang di select
    const body = { key, value };
    this.setState({
      set_key_feature: true,
    });
    this.props.set_feature_key_array(body);

    //STEP 2 mendapatkan data geometry dari feature untuk fly to
    const { geo_layer_list, layer_id } = this.props.layer;
    const layer = geo_layer_list.find(
      (d) => d.geo_layer._id === layer_id
    ).geo_layer;
    const geojson = layer.geojson;
    const features = geojson?.features || [];
    let feature = features.find((d) => d.key === key);
    const map = this?.props?.map?.map;
    if (map) {
      let [minLng, minLat, maxLng, maxLat] = bbox(feature);
      const padding = { top: 150, bottom: 300, left: 400, right: 400 };
      map?.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding,
          maxZoom: 16,
        }
      );
    }
  };

  componentDidMount() {
    this.on_render();
  }

  on_render = () => {
    const { layer, child } = this.props;
    const { layer_id } = layer;
    const layer_details = getLayerDetails(layer);
    const geo_layer = layer_details?.geo_layer;
    const fields_original = getAllFields(geo_layer);
    const sortedFeatures = getAllFeatures(geo_layer);

    let fields = fields_original.filter(
      (f) =>
        !f.isStyle &&
        // && !f.isHide //isHide dihilangkan request dari Galih Pratiwi 4 Juli 2023
        f.key !== "circle_color" &&
        !f.parent_uuid
    );

    const show_only_highlight_field = geo_layer?.show_only_highlight_field;

    if (show_only_highlight_field) {
      fields = fields.filter((item) => item.isHighlight);
    }

    const { geo_layer_list } = this.props.layer;

    const id_pembanding = geo_layer?.layer_data_list?.[0]?._id;
    const layer_pembanding = geo_layer_list.find(
      (item) => item?.geo_layer?._id === id_pembanding
    )?.geo_layer;

    if (geo_layer_status_that_editable.includes(geo_layer?.status)) {
      this.setState({
        layer_id: layer_id,
        headers: convertFieldsToHeaders(
          {
            fields,
            layer_id,
            child,
            sortedFeatures,
            geo_layer,
            is_from_first_gen_table: true,
            is_editable_field: true,
          },
          {
            handleEditCellChange: this.handleEditCellChange,
            toggleEditField: this.toggleEditField,
            toggleDeleteField: this.toggleDeleteField,
            toggle_table: this.toggle_table,
            editProperties:
              geo_layer?.architecture === "v2"
                ? this.props.editPropertiesV2
                : this.props.editProperties,
          },
          {
            deleteColumn: this.deleteColumn,
            newColumn: this.newColumn,
            column_surveyor: this.column_surveyor,
            column_status: this.column_status,
            column_pembanding: this.column_pembanding,
            column_value_pembanding: this.column_value_pembanding,
            column_download_laporan_pdf: this.column_download_laporan_pdf,
          }
        ),
        data_list: convertPropertiesToDataList({
          features: sortedFeatures,
          fields: fields_original,
          layer_pembanding,
          source: "on_render editor properties",
          capex_value: this.props.properties.capex_value,
        }),
        fields: fields,
      });
    }
  };

  handleDeleteField = () => {
    const body = {
      geo_layer_id: this.state.layer_id,
      field_key: this.state.field.key,
    };
    this.props.deleteField(body);
    this.setState({
      modalDeleteField: false,
    });
  };

  handleDeleteFeature = () => {
    let { layer_id } = this.props.layer;
    const { feature_key } = this.state;
    const content = {
      feature_key: feature_key,
      geo_layer_id: layer_id,
    };
    this.props.deleteFeature(content);
    this.setState({
      modalDeleteFeature: false,
    });
  };

  toggleDeleteFeature = (row) => {
    const feature_key = row ? row.key : "";
    this.setState({
      modalDeleteFeature: !this.state.modalDeleteFeature,
      feature_key,
    });
  };

  toggleAssign = (e) => {
    this.setState({
      modalAssign: !this.state.modalAssign,
      modal_data: e,
    });
  };

  toggleValidasi = (e) => {
    this.setState({
      modalValidasi: !this.state.modalValidasi,
      modal_data: e,
    });
  };

  toggleReject = (e) => {
    this.setState({
      modalReject: !this.state.modalReject,
      modal_data: e,
    });
  };

  toggle_close_table = () => {
    const { layer_id } = this.state;
    this.props.set_layer_id(layer_id);
    this.props.setTabelStatus(false);
  };

  deleteColumn = {
    field: "no",
    headerName: "No.",
    width: 80,
    sortable: false,
    renderHeader: (params) => {
      return (
        <NumberAndActionHeader
          params={params}
          onOpenModalTutorial={this.props.onOpenModalTutorial}
        />
      );
    },
    renderCell: (params) => {
      const view_tabel =
        this.props.properties.feature_key_selected === params.row.key;
      return (
        <NumberAndAction
          toggleAssign={this.toggleAssign}
          toggleDeleteFeature={this.toggleDeleteFeature}
          onClick={this.on_fly_feature.bind(this, params)}
          backgroundColor={view_tabel ? "#caeef6" : "transparent"}
          params={params}
        />
      );
    },
  };

  newColumn = {
    field: "add_column",
    headerName: "Add Column",
    width: 150,
    sortable: false,
    editable: false,
    renderHeader: (params) => {
      return (
        <button
          className="button bg_white text_black hover_bigger"
          onClick={() => {
            this.togglePushField();
          }}
        >
          New Column
        </button>
      );
    },
  };

  column_status = {
    field: "column_status",
    headerName: "Validasi",
    width: 190,
    sortable: false,
    editable: false,
    renderHeader: (params) => {
      return <strong>Surveyor</strong>;
    },
    renderCell: (params) => {
      return (
        <div className="row_surveyor">
          <div className="row_surveyor_img">
            {params?.row?.formStatus?.status === "upload" ||
            params?.row?.formStatus?.status === "digitasi" ? (
              <img src={pic_static} alt="profile" />
            ) : (
              <img
                src={
                  params?.row?.formStatus?.status
                    ? params?.row?.user?.profile_picture?.url_compressed
                      ? params?.row?.user?.profile_picture?.url_compressed
                      : pic_static
                    : pic_static
                }
                alt="profile"
              />
            )}
          </div>
          <div className="row_suveyor_action">
            <p>
              {params?.row?.formStatus?.status === "upload" ||
              params?.row?.formStatus?.status === "digitasi"
                ? "-"
                : params?.row?.user?.full_name ||
                  params?.row?.user?.email ||
                  params?.row?.user?.name}
            </p>
            <div className="row_suveyor_button">
              <button
                className="button_small"
                id="blue"
                onClick={() => this.toggleValidasi(params?.row)}
              >
                Accept
              </button>
              <button
                className="button_small"
                id="red"
                onClick={() => this.toggleReject(params?.row)}
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      );
    },
  };

  column_surveyor = {
    field: "column_surveyor",
    headerName: "Surveyor",
    width: 220,
    sortable: false,
    editable: false,
    renderHeader: (params) => {
      return <strong>Status</strong>;
    },
    renderCell: (params) => {
      const status = params?.row?.formStatus?.status;

      return (
        <div
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <button
            className="button_small"
            style={{
              borderRadius: "25px",
              width: "100%",
              fontWeight: "500",
            }}
            id={
              status === "upload" ||
              status === "digitasi" ||
              status === "pending"
                ? "blue_light"
                : status === "assign" || status === "on_rev"
                ? "blue_muda_light"
                : status === "submit_rev"
                ? "yellow_light"
                : status === "accepted"
                ? "green_light"
                : "blue"
            }
            onClick={() => this.toggleAssign(params.row)}
          >
            {status === "upload" ||
            status === "digitasi" ||
            status === "pending"
              ? "Assign"
              : status === "assign" || status === "on_rev"
              ? "On Process"
              : status === "submit_rev"
              ? "On Review"
              : status === "accepted"
              ? "Completed"
              : "-"}
          </button>
          {status === "submit_rev" ? <div className="small_circle" /> : null}
        </div>
      );
    },
  };

  column_pembanding = {
    field: "column_pembanding",
    headerName: "Data Pembanding",
    width: 150,
    sortable: false,
    editable: false,
    renderHeader: (params) => {
      return <strong>Data Pembanding</strong>;
    },
    renderCell: (params) => {
      return (
        <div>
          <button
            className="button_small"
            id="blue"
            onClick={this.toggle_data_pembanding.bind(
              this,
              params?.row?.key,
              params?.row?.data_pembanding_list
            )}
          >
            {params?.row?.data_pembanding_list?.length} data
          </button>
        </div>
      );
    },
  };

  column_value_pembanding = {
    field: "column_value_pembanding",
    headerName: "Nilai pembanding",
    width: 150,
    sortable: false,
    editable: false,
    renderHeader: (params) => {
      return <strong>Nilai pembanding</strong>;
    },
    renderCell: (params) => {
      const data_pembanding_list = params?.row?.data_pembanding_list;

      const { layer_id, geo_layer_list } = this.props.layer;

      //content
      const layer_object = geo_layer_list.find(
        (item) => item?.geo_layer?._id === layer_id
      );

      const id_pembanding = layer_object?.geo_layer?.layer_data_list?.[0]?._id;
      const layer_pembanding = geo_layer_list.find(
        (item) => item?.geo_layer?._id === id_pembanding
      )?.geo_layer;
      let fields = layer_pembanding?.fields || [];
      fields = fields.filter((item) => !item?.isStyle);

      let features = layer_pembanding?.geojson?.features || [];
      features = features.filter((item) =>
        data_pembanding_list.includes(item?.key)
      );

      //rev
      const rev_calculation_field_key =
        layer_pembanding?.rev_calculation_field_key;

      //percent
      const percent_value_field_key = layer_pembanding?.percent_value_field_key;
      const field_percent = fields.find(
        (item) => item.key === percent_value_field_key
      );

      let sum;

      if (field_percent?.key) {
        const array_of_number = features.map((item) =>
          Number(item.properties[percent_value_field_key])
        );
        const init = 0;
        sum = array_of_number.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          init
        );
      }

      // mengisi feature kosong dgn hasil kalkulasi menggunakan formula.
      const source = "EditorProperties";
      features = calculateWithoutConvert({
        features,
        fields,
        source,
      });

      features = features.map((item) => {
        const nilai_pembobotan =
          item?.properties?.[percent_value_field_key] / sum;
        const nilai_indikasi =
          item?.properties?.[rev_calculation_field_key] * nilai_pembobotan;
        item.properties.nilai_pembobotan = nilai_pembobotan;
        item.properties.nilai_indikasi = nilai_indikasi;
        return item;
      });

      let total_indikasi = 0;

      if (field_percent?.key) {
        const array_of_number = features.map((item) =>
          Number(item?.properties?.nilai_indikasi)
        );
        const init = 0;
        total_indikasi = array_of_number.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          init
        );
      }

      return (
        <div>
          {total_indikasi ? (
            <p>
              Rp.
              {new Intl.NumberFormat("id-ID", {
                style: "decimal",
              }).format(total_indikasi.toFixed(0))}
            </p>
          ) : (
            <p>Belum load</p>
          )}
        </div>
      );
    },
  };

  column_download_laporan_pdf = {
    field: "column_download_laporan_pdf",
    headerName: "Laporan PDF",
    width: 190,
    sortable: false,
    editable: false,
    renderHeader: (params) => {
      return (
        <strong>
          <ColumnSetupDownloadLaporanPdf />
        </strong>
      );
    },
    renderCell: (params) => {
      return <DownloadLaporanPdf row={params} />;
    },
  };

  handleEditCellChange(editProperties, layer_id, params) {
    const body = packThePropertiesDataToUseApiEdit(layer_id, params);
    editProperties(body);
  }

  onCellEditCommit(event) {
    if (event?.target?.value) {
      const value = !isNaN(event.target?.value)
        ? +event.target?.value
        : event.target?.value;

      const old_value = !isNaN(this.state.old_cell.value)
        ? +this.state.old_cell.value
        : this.state.old_cell.value;

      if (!is_empty(this.state.old_cell) && old_value !== value) {
        let newParams = this.state.old_cell;
        newParams.value = value;
        newParams.row.value = value;

        const { layer_id, geo_layer_list } = this.props.layer;
        const layer = geo_layer_list.find((l) => l.geo_layer._id === layer_id);
        if (layer?.geo_layer?.architecture === "v2") {
          this.handleEditCellChange(
            this.props.editPropertiesV2,
            this.state.layer_id,
            newParams
          );
        } else {
          this.handleEditCellChange(
            this.props.editProperties,
            this.state.layer_id,
            newParams
          );
        }

        this.setState({ old_cell: {} });
      }
    }
  }

  onCellKeyDown = (params, event) => {
    if (event?.key === "Enter" && params?.colDef.isImage) {
      document.getElementById(`${params.row.key}-${params.field}`).click();
    }
    // else if (event?.key === " " && params?.colDef.isImage && params?.value) {
    //   this.handlePreviewImage(params);
    //   this.toggleImagePreview();
    // }
    else if (event?.key === " " && params?.colDef.isImage === false) {
      event.stopPropagation();
    } else if (event?.key === "Enter" && params?.colDef.isDoc) {
      document.getElementById(`${params.row.key}-${params.field}`).click();
    } else if (event?.key === "Enter") {
      this.onCellEditCommit(event);
    }
  };

  onCellEditStart(params) {
    this.setState({ old_cell: params });
  }

  render() {
    const { layer } = this.props;
    const layer_details = getLayerDetails(layer);

    const language = localStorage?.language || "ina";
    const { headers, current_page } = this.state;

    const close_table_button = (
      <main className="floating_close_button_table">
        <div className="margin_5 ">
          <div
            className="center_perfect h_full pointer noselect hover_bigger"
            onClick={() => this.toggle_close_table()}
          >
            X
          </div>
        </div>
      </main>
    );

    const modal_data_pembanding_content = this.state.modal_data_pembanding && (
      <Modal
        modalSize="large"
        id="modal_data_pembanding"
        isOpen={this.state.modal_data_pembanding}
        onClose={this.toggle_data_pembanding}
      >
        <div className="box-body">
          <TableDataPembanding />
        </div>
      </Modal>
    );

    const modalAssign = this.state.modalAssign && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={this.state.modalAssign}
        onClose={this.toggleAssign}
      >
        <ModalAssignSurveyor
          toggleAssign={this.toggleAssign}
          modal_data={this.state.modal_data}
        />
      </Modal>
    );

    const modalValidasi = this.state.modalValidasi && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={this.state.modalValidasi}
        onClose={this.toggleValidasi}
      >
        <ModalValidasi
          toggleValidasi={this.toggleValidasi}
          modal_data={this.state.modal_data}
        />
      </Modal>
    );

    const modalReject = this.state.modalReject && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={this.state.modalReject}
        onClose={this.toggleReject}
      >
        <ModalReject
          toggleReject={this.toggleReject}
          modal_data={this.state.modal_data}
        />
      </Modal>
    );

    const modalEditField = this.state.modalEditField && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={this.state.modalEditField}
        onClose={this.toggleEditField}
      >
        <div className="box-body">
          <EditField headers={headers} field={this.state.field} />
        </div>
      </Modal>
    );

    const modalPushFieldContent = this.state.modalPushField && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={this.state.modalPushField}
        onClose={this.togglePushField}
      >
        <div className="box-body">
          <PushField
            headers={headers}
            from="table"
            layer_id={this.state.layer_id}
          />
        </div>
      </Modal>
    );

    const modalDeleteFeatureContent = this.state.modalDeleteFeature && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={this.state.modalDeleteFeature}
        onClose={(params) => this.toggleDeleteFeature(params)}
      >
        <main style={{ textAlign: "center", padding: 20 }}>
          <section className="text_medium">
            {dict["Are you sure to delete this feature?"][language]}
            <br />
            {dict["This process can't be undone."][language]}
          </section>
          <button
            className="buttonSimple2"
            id="deleteBright"
            style={{ marginTop: "1rem" }}
            onClick={this.handleDeleteFeature}
          >
            {dict["Delete"][language]}
          </button>
        </main>
      </Modal>
    );

    const modalDeleteField = this.state.modalDeleteField && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={this.state.modalDeleteField}
        onClose={(params) => this.toggleDeleteField(params)}
      >
        <main style={{ textAlign: "center", padding: 20 }}>
          <section className="text_medium">
            {dict["Are you sure to delete?"][language]}
          </section>
          <button
            className="buttonSimple2"
            id="deleteBright"
            style={{ marginTop: "1rem" }}
            onClick={this.handleDeleteField}
          >
            {dict["Delete"][language]}
          </button>
        </main>
      </Modal>
    );

    const modal_table_content = this.state.modal_table && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={this.state.modal_table}
        onClose={this.toggle_table}
      >
        <div className="box-body">
          <ParentTable />
        </div>
      </Modal>
    );

    let table_properties_content;
    let table_style = {};
    if (
      geo_layer_status_that_editable.includes(layer_details?.status) &&
      !layer_details?.geo_layer?.is_custom_endpoint
    ) {
      table_style = {
        height: "100%",
        width: "100%",
        display: "flex",
      };

      table_properties_content = (
        <section
          style={{
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 5,
            paddingRight: 5,
            width: "100%",
          }}
        >
          <StyledDataGrid
            rows={this.state.data_list}
            apiRef={this.props.apiDataGridRef}
            columns={this.state.headers}
            onCellEditStart={(params) => this.onCellEditStart(params)}
            slotProps={{
              cell: {
                onBlur: (event) => {
                  this.onCellEditCommit(event);
                },
              },
              footer: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
                apiDataGridRef: this.props.apiDataGridRef,
                current_page: current_page,
              },
            }}
            onCellKeyDown={(params, event) => {
              this.onCellKeyDown(params, event);
            }}
            getRowHeight={() => 45}
            onPaginationModelChange={(params) =>
              this.setState({ current_page: params })
            }
            slots={{
              footer: CustomToolbarContainer,
            }}
            rowsPerPageOptions={[
              25, 50, 100, 500, 1000, 5000, 10000, 50000, 100000,
            ]}
            disableColumnMenu
          />
        </section>
      );
    } else {
      table_style = {
        overflowX: "auto",
        width: "100%",
        height: "100%",
      };
      table_properties_content = (
        <section>
          <Table />
        </section>
      );
    }

    return (
      <div style={{ ...table_style, zIndex: 3 }}>
        {close_table_button}
        {table_properties_content}
        {modalPushFieldContent}
        {modalDeleteFeatureContent}
        {modalEditField}
        {modalDeleteField}
        {modalAssign}
        {modalValidasi}
        {modalReject}
        {modal_table_content}
        {modal_data_pembanding_content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  auth: state.auth,
  map: state.map,
  properties: state.properties,
});

const Editor = connect(mapStateToProps, {
  pushFeature,
  pushField,
  editProperties,
  editPropertiesV2,
  setFlyFeatures,
  openModal,
  deleteFeature,
  deleteFieldForm,
  deleteField,
  set_value,
  set_feature_key_array,
  setTabelStatus,
  set_layer_id,
  set_value_properties,
  status_action,
})(EditorProperties);

function ParentEditorProperties(props) {
  const apiDataGridRef = useGridApiRef();

  return <Editor apiDataGridRef={apiDataGridRef} {...props} />;
}

export default ParentEditorProperties;
