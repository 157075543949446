/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import randomcolor from "randomcolor";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";
import CheckBox from "../common_input/CheckBox";
import SelectDataPembanding from "../editor_search_import/SelectDataPembanding";
import DataPembanding from "./DeleteDataPembanding";
import DeleteDataFormOverlay from "./DeleteDataFormOverlay";
import SelectDataForm from "../editor_search_import/SelectDataForm";
import SpatialJoin from "../editor_map/SpatialJoin";
import EditRadiusPembanding from "./EditRadiusPembanding";
import MANAGE_CHART from "./MANAGE_CHART";
// import ApiInput from "../editor_api/ApiInput";

/*REDUX FUNCTION*/
import {
  setField,
  doSentimentAnalysis,
  setLayerWOgeojson,
} from "../../App/actions/layerActions";
import {
  editLayerGeneral,
  editLayerGeneralAttached,
  getDetailLayerById,
} from "../../App/actions/layerNewActions";
import { openModal, initApi } from "../../App/actions/authActions";
import { set_value } from "../../App/actions/dataActions";

/*PICTURE ASSET*/
import { icon_map } from "../../Assets";

/*GENERAL FUNCTION & DATA*/
import isEmpty from "../../App/validation/is-empty";
import isEqual from "../../App/validation/is-equal";
import uuid from "../../App/validation/uuid";
import dict from "../../Data/dict.json";
import domain_list from "../../Data/domain_list";
import { select_field_from_default_props } from "../../App/validation/generateGeoJson";

/*NON IMPORT*/
const Rainbow = require("rainbowvis.js");

class EditLayer extends Component {
  state = {
    geo_layer: {},
    fields: 0,
    sentiment_column: "",
    geojson: {
      features: [],
    },
    selected_column: "",
    selected_color: "",
    typing: false,
    typingTimeout: 0,
    newColor: "",
    listIcon: [],
    range_group: [],

    modal_clone_field: false,
    modal_layer_public: false,
    modal_form_public: false,
    modal_select_comparison_data: false,
    modal_select_data_form: false,
    modal_spatial_join: false,
    modal_edit_radius_pembanding: false,
    modal_manage_chart: false,
  };

  rainbow = new Rainbow();

  static getDerivedStateFromProps(nextProps, prevState) {
    let compareArr = (arr1, arr2) => {
      const _arr1 = arr1.concat().sort();
      const _arr2 = arr2.concat().sort();
      return isEqual(_arr1, _arr2);
    };

    if (
      nextProps?.layer?.geo_layer_wo_geojson?.geo_layer?._id !==
        prevState?.geo_layer?.geo_layer?._id &&
      nextProps?.layer?.geo_layer_wo_geojson?.geo_layer?._id
    ) {
      const type = nextProps.layer.geo_layer_wo_geojson?.geo_layer?.type;
      const fields = select_field_from_default_props(type);
      let geo_layer = { ...nextProps.layer.geo_layer_wo_geojson };
      let properties = !isEmpty(
        nextProps.layer.geo_layer_wo_geojson?.geo_layer?.properties
      )
        ? nextProps.layer.geo_layer_wo_geojson?.geo_layer?.properties
        : fields;
      if (
        !properties.find((p) => p.key === "is_using_label") &&
        (type === "Polygon" || type === "MultiPolygon")
      ) {
        properties.push(fields.find((p) => p.key === "is_using_label"));
        properties.push(fields.find((p) => p.key === "text_field"));
      }
      geo_layer.geo_layer.properties = properties;
      const hasProperty = geo_layer.geo_layer.hasOwnProperty("globalStyleMode");
      let geojson = {};
      let dataColumn = [];
      //Untuk rancangan by value
      if (!hasProperty) {
        const fieldList = geo_layer.geo_layer.fields.filter((f) => !f.isHide);
        geo_layer.geo_layer.globalStyleMode = "solid";
        geo_layer.geo_layer.valueStyleProps = {
          id: uuid(),
          color: [],
          selected_column: fieldList.length < 1 ? "" : fieldList[0].key,
          range: [],
          class_number: 5,
          selected_color: "darkred,yellow,darkgreen",
          classify_mode: "number",
          isNumber: true,
        };
        geo_layer.geo_layer.valueStyleList = [
          geo_layer.geo_layer.valueStyleProps,
        ];
      }

      //Untuk rancangan grouping

      if (nextProps?.layer?.geo_layer?.geo_layer?.hasOwnProperty("geojson")) {
        let layer_inside = geo_layer.geo_layer;
        geojson = nextProps.layer.geo_layer.geo_layer.geojson;
        if (true) {
          const selected_column =
            layer_inside?.valueStyleProps?.selected_column;
          const classify_mode = layer_inside?.valueStyleProps?.classify_mode;
          const isNumber = classify_mode === "number";
          let class_number = layer_inside?.valueStyleProps?.class_number;
          if (!isNumber && geojson.hasOwnProperty("features")) {
            const arrayUniq = geojson?.features
              .map((d) => d.properties[selected_column])
              .sort()
              .filter((val, id, array) => {
                return array.indexOf(val) === id;
              });

            /**Ini harusnya ditaro di comopnent update */
            dataColumn =
              layer_inside?.valueStyleProps?.range &&
              layer_inside?.valueStyleProps?.range?.length ===
                arrayUniq?.length &&
              compareArr(layer_inside?.valueStyleProps?.range, arrayUniq)
                ? layer_inside?.valueStyleProps?.range
                : arrayUniq;
            class_number = dataColumn.length;
          }
          let new_range = [...dataColumn];
          let new_props = {
            ...layer_inside.valueStyleProps,
            range: new_range,
            class_number,
          };
          let new_geo_layer = { ...layer_inside, valueStyleProps: new_props };
          geo_layer = {
            ...geo_layer,
            geo_layer: new_geo_layer,
          };
        }
      }

      return {
        geo_layer: geo_layer,
        geojson,
        range_group: hasProperty
          ? geo_layer.geo_layer?.valueStyleProps?.range
          : dataColumn,
      };
    } else if (
      (!isEmpty(nextProps?.layer?.geo_layer?.geo_layer?.geojson) &&
        nextProps?.layer?.geo_layer?.geo_layer?._id !==
          prevState?.geo_layer?.geo_layer?._id) ||
      (nextProps?.layer?.geo_layer?.geo_layer?._id ===
        prevState?.geo_layer?.geo_layer?._id &&
        !isEmpty(nextProps?.layer?.geo_layer?.geo_layer?.geojson) &&
        !isEqual(
          nextProps?.layer?.geo_layer?.geo_layer?.geojson,
          prevState.geojson
        ))
    ) {
      let { geo_layer } = nextProps?.layer;
      let layer_inside = geo_layer.geo_layer;
      /** start styling config */
      const selected_column = layer_inside?.valueStyleProps?.selected_column;
      const classify_mode = layer_inside?.valueStyleProps?.classify_mode;
      const isNumber = classify_mode === "number";
      let dataColumn = [];
      let geojson = nextProps?.layer?.geo_layer?.geo_layer?.geojson || {};
      let class_number = layer_inside?.valueStyleProps?.class_number;
      if (!isNumber && geojson?.hasOwnProperty("features")) {
        const arrayUniq = geojson?.features
          .map((d) => d.properties[selected_column])
          .sort()
          .filter((val, id, array) => {
            return array.indexOf(val) === id;
          });

        /**Ini harusnya ditaro di comopnent update */
        dataColumn =
          layer_inside?.valueStyleProps?.range &&
          layer_inside?.valueStyleProps?.range?.length === arrayUniq?.length &&
          compareArr(layer_inside?.valueStyleProps?.range, arrayUniq)
            ? layer_inside?.valueStyleProps?.range
            : arrayUniq;
        class_number = dataColumn.length;
      }
      let new_range = [...dataColumn];
      let new_props = {
        ...layer_inside.valueStyleProps,
        range: new_range,
        class_number,
      };
      let new_geo_layer = { ...layer_inside, valueStyleProps: new_props };
      let new_geo_geo_layer = {
        ...nextProps.layer.geo_layer,
        geo_layer: new_geo_layer,
      };
      return {
        geojson,
        geo_layer: new_geo_geo_layer,
        range_group: dataColumn,
      };
    } else return null;
  }

  componentDidMount() {
    this.handleChangeColorProps = this.handleChangeColorProps.bind(this);
    this.getLegendColor = this.getLegendColor.bind(this);
    const { layer } = this.props;
    let { geo_layer_wo_geojson } = layer;
    let geo_layer = geo_layer_wo_geojson?.geo_layer;
    if (geo_layer?.isGlobalStyle && !geo_layer?.geojson) {
      const body = {
        geo_layer_id: geo_layer?._id,
        geo_project_id: geo_layer?.geo_project,
      };
      this.props.getDetailLayerById(body);
    }
    let listIcon = Object.keys(icon_map).map((key) => {
      let firstLetter = key.replaceAll("_", " ").charAt(0).toUpperCase();
      let name = firstLetter + key.replaceAll("_", " ").slice(1);
      return {
        name,
        icon: key,
      };
    });
    this.setState({ listIcon });
  }

  toggle_edit_chart = () => {
    this.setState({ modal_manage_chart: !this.state.modal_manage_chart });
  };

  toggle_edit_radius_pembanding = () => {
    this.setState({
      modal_edit_radius_pembanding: !this.state.modal_edit_radius_pembanding,
    });
  };

  toggle_spatial_join = () => {
    this.setState({ modal_spatial_join: !this.state.modal_spatial_join });
  };

  toggle_link_edit = () => {
    this.props.set_value("modal_link_edit", !this.props.layer.modal_link_edit);
  };

  toggle_select_comparison_data = () => {
    this.setState({
      modal_select_comparison_data: !this.state.modal_select_comparison_data,
    });
  };

  toggle_select_data_form = () => {
    this.setState({
      modal_select_data_form: !this.state.modal_select_data_form,
    });
  };

  toggle_clone_field = () => {
    this.setState({ modal_clone_field: !this.state.modal_clone_field });
  };

  initApi = () => {
    const body = {};
    this.props.initApi(body);
  };

  onOpenModal = (name) => {
    this.props.openModal(name);
  };

  setLayerWOgeojson = (geo_layer) => {
    const content = {
      geo_layer: geo_layer,
    };
    this.props.setLayerWOgeojson(content);
  };

  //Local change
  handleChange = (e) => {
    e.preventDefault();
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name === "properties") {
      const id = target.id;
      let geo_layer = this.state.geo_layer;
      let properties = this.state.geo_layer.geo_layer.properties;
      properties[id].defaultValue = value;
      geo_layer.geo_layer.properties = properties;
      this.setState({
        geo_layer,
      });
    } else if (name === "fields") {
      this.setState({
        [name]: value,
      });
    } else if (name === "folder") {
      let geo_layer = this.state.geo_layer;
      geo_layer.folder = value;
      this.setState({
        geo_layer,
      });
    } else {
      let geo_layer = this.state.geo_layer.geo_layer;
      geo_layer[name] = value;
      geo_layer.geo_layer = geo_layer;
      this.setState({
        geo_layer,
      });
    }
  };

  handleToggle = (e) => {
    const target = e.target;
    const name = target.name;
    let geo_layer = this.state.geo_layer;
    if (name === "isGlobalStyle" && !geo_layer?.geo_layer?.geojson) {
      const body = {
        geo_layer_id: geo_layer.geo_layer._id,
        geo_project_id: geo_layer.geo_layer.geo_project,
      };
      this.props.getDetailLayerById(body);
    }
    if (name === "properties") {
      const id = target.id;
      const value = target.checked;
      let geo_layer = this.state.geo_layer;
      let properties = this.state.geo_layer.geo_layer.properties;
      properties[id].defaultValue = value;
      geo_layer.geo_layer.properties = properties;
    } else {
      if (name === "isNumber") {
        geo_layer.geo_layer.valueStyleProps[name] =
          !geo_layer.geo_layer.valueStyleProps[name];
      } else {
        geo_layer.geo_layer[name] = !geo_layer.geo_layer[name];
      }
    }
    if (name === "isPublic") {
      this.setState({ modal_layer_public: true });
    }
    if (name === "isFormPublic") {
      this.setState({ modal_form_public: true });
    }
    this.setState({
      geo_layer,
    });
  };

  handleChangeSentiment = (e) => {
    e.preventDefault();
    this.setState({ sentiment_column: e.target.value });
  };

  handleChangeValueProps = (e) => {
    try {
      e.preventDefault();
      const geo_layer = this.state.geo_layer.geo_layer;
      const gl = this.state.geo_layer;
      const geojson = this.state.geojson;
      const target = e.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const name = target.name;

      let newProps = Object.assign({}, geo_layer.valueStyleProps);
      newProps[name] = value;
      let isNumber = newProps.classify_mode === "number";

      if (
        name === "selected_column" ||
        name === "selected_color" ||
        name === "classify_mode" ||
        name === "class_number"
      ) {
        let class_number = isNumber
          ? newProps.class_number
          : geo_layer.valueStyleProps.class_number;
        let dataColumn = [];
        if (!isNumber) {
          dataColumn = geojson?.features
            .map((d) => d.properties[newProps["selected_column"]])
            .sort()
            .filter((val, id, array) => {
              return array.indexOf(val) === id;
            });

          class_number = dataColumn.length;
        }
        newProps.class_number = class_number;
        let firstCheck = true;
        do {
          if (!firstCheck && isNumber) newProps.class_number--;
          newProps["range"] = this.getRangeItem({
            classNumber: newProps.class_number,
            geojson,
            selected_column: newProps.selected_column,
            isNumber: isNumber,
          });
          firstCheck = false;
          if (!isNumber || (isNumber && newProps["range"].length < 1)) break;
        } while (
          isNumber &&
          newProps.class_number >= 2 &&
          newProps["range"][newProps["range"].length - 1].min >
            newProps["range"][newProps["range"].length - 1].max
        );
        newProps["color"] = this.getLegendColor({
          color: newProps.selected_color,
          classNumber: newProps.class_number,
          forceChange: true,
        });
        this.setState({
          range_group: newProps["range"],
        });
      } else if (name === "range" && !isNumber) {
        const idx = target.id.split("-").pop();
        let new_range = [];
        new_range = [...geo_layer.valueStyleProps.range];
        new_range[idx] = value;
        let range_group = this.state.range_group;
        let swapIdx = range_group.findIndex((val) => {
          return value === val;
        });
        new_range[swapIdx] = range_group[idx];
        newProps.range = [...new_range];
        this.setState({
          range_group: new_range,
        });
      }
      const new_geo_layer = {
        ...geo_layer,
        valueStyleProps: { ...newProps },
      };
      const new_geo_geo_layer = {
        ...gl,
        geo_layer: { ...new_geo_layer },
      };
      this.setState({
        geo_layer: new_geo_geo_layer,
      });
    } catch (e) {}
  };

  handleChangeColorProps = (e) => {
    // const { geo_layer } = this.state;
    // let newProps = geo_layer.valueStyleProps.color;
    // newProps.color[0] = convert.rgb.hex(e.target.value);
  };

  getLegendColor = ({ color, classNumber, forceChange }) => {
    const { valueStyleProps } = this.state.geo_layer;
    let legendColor = new Rainbow();
    let legendItem = [];
    let i = 0;
    if (isEmpty(color)) return legendItem;
    if (color !== "random") {
      let arrStr = color.split(",");
      legendColor.setSpectrum(arrStr[0], arrStr[1], arrStr[2]);
      legendColor.setNumberRange(0, classNumber <= 1 ? 1 : classNumber - 1);
      for (i = 0; i < classNumber; i++) {
        legendItem.push("#" + legendColor.colourAt(i));
      }
    } else if (color === "random" && forceChange) {
      // let arrStr = ["#FF0000", "#FF0000", "#FF0000", "#FF0000", "#0000FF"];
      for (i = 0; i < classNumber; i++) {
        legendItem.push(randomcolor());
      }
    } else {
      return valueStyleProps?.color;
    }
    return legendItem;
  };

  round = (value, decimals) => {
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
  };

  legend = (props) => {
    const { legendItem, classNumber } = props;
    let i = 0;
    let ele = [];
    let min = props.min;
    let max = props.max;
    let temp = min;
    let diff = max - min;
    let increment = this.round(diff / classNumber, 2);
    let valIsNan = isNaN(min) || isNaN(max);
    //Mengembalikan elemen non range
    if (!props.isNumber) {
      const dataCol = props.dataCol;
      if (dataCol.length < 1) return null;
      ele = dataCol.map((dt, idx) => {
        return (
          <div key={`legend-color-${idx}`} className={"two-container-input"}>
            {/*             <div
              style={{
                borderColor: "white",
                padding: 2,
                textDecoration: "none",
              }}
            >
              {dt}
            </div> */}
            <select
              name="range"
              id={`range-${idx}`}
              onChange={this.handleChangeValueProps.bind(this)}
              value={dataCol[idx]}
            >
              {props.range_group.map((dt, index) => {
                return (
                  <option key={index} value={dt}>
                    {dt}
                  </option>
                );
              })}
            </select>
            <div>
              <input type="color" value={legendItem[idx]} disabled={true} />
            </div>
          </div>
        );
      });
      return (
        <div
          id="legend-layout"
          style={{
            marginBottom: 5,
            height: "auto",
            backgroundColor: "rgba(0,0,0,0)",
          }}
        >
          {ele}
        </div>
      );
    }
    if (valIsNan) {
      return <div id="legend-layout"></div>;
    }
    if (min === max) {
      //Cuma satu elemen yg direneder
      ele.push();
    } else if (props?.enableDecimal) {
      //Render dengan desimal
      for (i = 0; i < classNumber; i++) {
        ele.push(
          <div
            style={{ borderColor: "white", padding: 2, textDecoration: "none" }}
          >
            {i === 0 ? min : this.round(temp + 0.01, 2)} -{" "}
            {i === classNumber - 1
              ? max
              : temp + increment <= max - 0.01
              ? this.round(temp + increment, 2)
              : max}
          </div>
        );
        temp = temp + increment;
      }
    } else {
      //Render pembulatan
      increment = this.round(diff / classNumber, 0);
      for (i = 0; i < classNumber; i++) {
        ele.push(
          <div className={"two-container-input"} key={`legend-color-${i}`}>
            <div
              style={{
                borderColor: "white",
                padding: 2,
                textDecoration: "none",
              }}
            >
              {i === 0 ? min : this.round(temp + 1, 0)} -{" "}
              {i === 0 && increment === 2
                ? this.round(temp + 1, 0)
                : temp + increment <= max - 1 && i !== classNumber - 1
                ? this.round(temp + increment, 0)
                : max}
            </div>
            <div>
              <input type="color" value={legendItem[i]} disabled={true} />
            </div>
          </div>
        );
        temp = temp + increment;
        if (i === 0 && increment === 2) {
          temp -= 1;
        }
      }
    }
    return (
      <div
        id="legend-layout"
        style={{
          marginBottom: 5,
          height: "auto",
          backgroundColor: "rgba(0,0,0,0)",
        }}
      >
        {" "}
        {ele}{" "}
      </div>
    );
  };

  getRangeItem = (props) => {
    if (!props.isNumber) {
      let dataCol = props.geojson?.features
        .map((d) => d.properties[props.selected_column])
        .sort()
        .filter((val, id, array) => {
          return array.indexOf(val) === id;
        });

      return dataCol;
    }
    let max = Math.max.apply(
      Math,
      props.geojson?.features.map(function (o) {
        return o.properties[props.selected_column];
      })
    );
    let min = Math.min.apply(
      Math,
      props.geojson?.features.map(function (o) {
        return o.properties[props.selected_column];
      })
    );
    const classNumber = props.classNumber;
    let i = 0;
    let ele = [];
    let temp = min;
    let increment = this.round((max - min) / classNumber, 2);
    let valIsNan = isNaN(min) || isNaN(max);
    if (valIsNan) {
      return [];
    }
    if (min === max) {
      //Cuma satu elemen yg direneder
      return [
        {
          min: min,
          max: max,
        },
      ];
    } else if (props.enableDecimal) {
      //Render dengan desimal
      for (i = 0; i < classNumber; i++) {
        ele.push({
          min: i === 0 ? min : this.round(temp + 0.01, 2),
          max:
            i === classNumber - 1
              ? max
              : temp + increment <= max - 0.01
              ? this.round(temp + increment, 2)
              : max,
        });
        temp = temp + increment;
      }
    } else {
      //Render pembulatan
      increment = this.round((max - min) / classNumber, 0);
      for (i = 0; i < classNumber; i++) {
        ele.push({
          min: i === 0 ? min : this.round(temp + 1, 0),
          max:
            i === 0 && increment === 2
              ? this.round(temp + 1, 0)
              : temp + increment <= max - 1 && i !== classNumber - 1
              ? this.round(temp + increment, 0)
              : max,
        });
        temp = temp + increment;
      }
      if (i === 0 && increment === 2) {
        temp -= 1;
      }
    }
    return ele;
  };

  handleSubmitField = (e) => {
    e.preventDefault();
    let { geo_layer, fields } = this.state;

    geo_layer = geo_layer.geo_layer;
    const { geo_layer_list } = this.props.layer;

    const field = geo_layer_list[fields].geo_layer.fields;
    const new_field = field.map((f) => {
      let new_field = { ...f, uuid: uuid() };
      return new_field;
    });
    const { _id } = geo_layer;
    const body = {
      geo_layer_id: _id,
      field: new_field,
    };
    this.props.setField(body);
    this.props.toggleEdit();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let { geo_layer } = this.state;
    geo_layer = geo_layer.geo_layer;
    const {
      _id,
      name,
      description,
      is_form_login_req,
      isPublic,
      show_only_highlight_field,
      isGlobalStyle,
      isFormPublic,
      isLocationChangeable,
      isExpand,
      properties,
      /*Tambahan by value */
      picture_url,
      valueStyleProps,
      globalStyleMode,
      valueStyleList,
      isCustomFormMessage,
      customFormMessageHeader,
      customFormMessageDesc,
      is_survey,
    } = geo_layer;
    let { layer_id, geo_layer_list } = this.props.layer;

    const Layer_valueStyleProps = geo_layer_list.find(
      (e) => e?.geo_layer?._id === layer_id
    )?.geo_layer?.valueStyleProps;

    let new_valueStyleProps =
      valueStyleProps?.range?.length === 0
        ? Layer_valueStyleProps
        : valueStyleProps;

    let folder = this.state.geo_layer.folder;

    if (folder === undefined) {
      folder = this.props.layer.geo_layer_wo_geojson.folder;
    }

    const body = {
      geo_layer_id: _id, //1
      folder, //2
      name, //3
      description, //4
      is_form_login_req: is_form_login_req ? is_form_login_req : false, //5
      isPublic, //6
      show_only_highlight_field, //7
      isGlobalStyle, //8
      isFormPublic, //9
      isLocationChangeable, //10
      isExpand, //11
      properties, //12
      picture_url, //13
      valueStyleProps: new_valueStyleProps, //14
      globalStyleMode, //15
      valueStyleList, //16
      isCustomFormMessage, //17
      customFormMessageHeader, //18
      customFormMessageDesc, //19
      is_survey, //20
    };
    this.props.editLayerGeneral(body);
  };

  handleSubmitAttached = (e) => {
    e.preventDefault();
    let { geo_layer } = this.state;
    geo_layer = geo_layer.geo_layer;
    const { geo_project } = this.props.project;
    const { _id } = geo_layer;
    const folder = this.state.geo_layer.folder;
    const body = {
      geo_project_id: geo_project._id,
      geo_layer_id: _id,
      folder,
    };
    this.props.editLayerGeneralAttached(body);
  };

  compareArr = (arr1, arr2) => {
    const _arr1 = arr1.concat().sort();
    const _arr2 = arr2.concat().sort();
    return _arr1 === _arr2;
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const {
      geojson,
      listIcon,
      range_group,
      modal_select_data_form,
      geo_layer,
      fields,
      modal_clone_field,
      modal_select_comparison_data,
      modal_spatial_join,
      modal_edit_radius_pembanding,
      modal_manage_chart,
    } = this.state;

    //global props
    const { domain, user } = this.props.auth;
    const { geo_project } = this.props.project;
    const { geo_layer_list, loadingProcess, itemLoading } = this.props.layer;
    const geo_layer_id = this.props.layer?.layer_id;

    //content
    const api_keys = user?.api_keys ? user.api_keys : [];
    const domain_temp = domain_list?.[domain]?.form_link;
    const number_type = ["number", "currency", "range"];

    const layer_data_list =
      this.props.layer?.geo_layer?.geo_layer?.layer_data_list || [];
    const layer_form_list =
      this.props.layer?.geo_layer?.geo_layer?.layer_form_list || [];

    const { folders } = geo_project;
    let folders_state = folders.slice();
    folders_state.unshift({ name: "Outside folders", _id: "" });

    let layer_inside = geo_layer.geo_layer;
    const properties = layer_inside.properties ? layer_inside.properties : [];
    const status = geo_layer.status;

    // const is_custom_api =
    //   layer_inside?.architecture === "api" && layer_inside?.is_custom_endpoint;

    let final_content;
    let button_save_content;
    let button_save_overwrite_fields;
    let button_edit_attached;

    /** start styling config */
    const selected_column = layer_inside?.valueStyleProps?.selected_column;
    const selected_color = layer_inside?.valueStyleProps?.selected_color;
    const color = layer_inside?.valueStyleProps?.color;
    const classify_mode = layer_inside?.valueStyleProps?.classify_mode;
    let variableRange = layer_inside?.valueStyleProps?.range;
    const isNumber = classify_mode === "number";
    let class_number = layer_inside?.valueStyleProps?.class_number;
    let type = layer_inside.fields.find((x) => x.key === selected_column);
    let dataColumn = [];
    if (!isNumber && geojson.hasOwnProperty("features")) {
      const arrayUniq = geojson?.features
        .map((d) => d.properties[selected_column])
        .sort()
        .filter((val, id, array) => {
          return array.indexOf(val) === id;
        });

      /**Ini harusnya ditaro di comopnent update */

      dataColumn =
        layer_inside?.valueStyleProps?.range &&
        layer_inside?.valueStyleProps?.range?.length === arrayUniq?.length &&
        this.compareArr(layer_inside?.valueStyleProps?.range, arrayUniq)
          ? layer_inside?.valueStyleProps?.range
          : arrayUniq;

      class_number = dataColumn.length;
    }
    const legendItem = color;
    /** end styling config */

    if (loadingProcess && itemLoading === "edit_general") {
      button_save_content = (
        <div className="button">{dict["Saving"][language]}...</div>
      );
      button_save_overwrite_fields = (
        <div className="button">{dict["Saving"][language]}...</div>
      );
    } else {
      button_save_content = (
        <button type="submit" className="button">
          {dict["Save"][language]}
        </button>
      );
      button_save_overwrite_fields = (
        <button type="submit" className="button">
          {dict["Overwrite attribute"][language]}
        </button>
      );
    }

    if (loadingProcess && itemLoading === "edit_general_attached") {
      button_edit_attached = <div className="button">Saving...</div>;
    } else {
      button_edit_attached = (
        <button type="submit" className="button">
          {dict["Save"][language]}
        </button>
      );
    }

    let open_api_content = (
      <main className="container_flat" style={{ marginBottom: "15px" }}>
        <p className="text_bold">OPEN API</p>
        <p className="text_inferior">
          {dict["You don't have an API key yet"][language]}
        </p>
        <button className="button" onClick={this.initApi}>
          {dict["Create a new API key"][language]}
        </button>
      </main>
    );
    if (api_keys.length > 0) {
      open_api_content = (
        <main className="container_flat" style={{ marginBottom: "15px" }}>
          <section className="text_bold">OPEN API</section>
          <textarea
            disabled
            value={`https://geoserver.mapid.io/layers_new/get_layer?api_key=${api_keys[0]?.key}&layer_id=${layer_inside?._id}&project_id=${geo_project?._id}`}
            rows="5"
          />
        </main>
      );
    }

    if (status === "attached") {
      //untuk kasus layer yang dilampirkan, hanya bisa mengedit folder di dalam project yang sedang dibuka
      final_content = (
        <main>
          <form onSubmit={this.handleSubmitAttached}>
            <section
              className="container_flat"
              style={{ marginBottom: "15px" }}
            >
              <p className="text_bold">General</p>
              <p className="text_inferior">_id: {geo_layer?.geo_layer?._id}</p>
              <p className="text_inferior">
                link: {geo_layer?.geo_layer?.link}
              </p>
            </section>
            <section
              className="container_flat"
              style={{ marginBottom: "15px" }}
            >
              <section className="text_bold">
                {dict["Edit Folder"][language]}
                <button
                  className="button_info"
                  id="blue"
                  onClick={this.onOpenModal.bind(this, "General Layer Setting")}
                  style={{ marginLeft: "10px" }}
                >
                  i
                </button>
              </section>
              <section
                className="text_inferior"
                style={{ marginBottom: "10px" }}
              >
                {
                  dict[
                    "In the case of attached layer, you only can edit folder in this project"
                  ][language]
                }
              </section>
              <select
                id="folder"
                name="folder"
                value={geo_layer.folder}
                onChange={this.handleChange}
              >
                {folders_state.map(({ _id, name }, idx) => {
                  return (
                    <option key={idx} value={_id}>
                      {name}
                    </option>
                  );
                })}
              </select>
            </section>
            <section
              style={{
                textAlign: "center",
              }}
            >
              <div>{button_edit_attached}</div>
            </section>
          </form>
        </main>
      );
    } else {
      //untuk kasus layer asli yang ada di dalam project, tersedia semua kolom pengeditan
      final_content = (
        <main>
          <p className="text_header">Edit Layer</p>
          <form onSubmit={this.handleSubmit.bind(this)}>
            <main
              style={{
                maxHeight: "calc(( 100vh - 255px ))",
                overflowY: "scroll",
              }}
            >
              <section
                className="container_flat"
                style={{ marginBottom: "15px" }}
              >
                <section className="text_bold">
                  {dict["General"][language]}
                  <button
                    className="button_info"
                    id="blue"
                    onClick={this.onOpenModal.bind(
                      this,
                      "General Layer Setting"
                    )}
                    style={{ marginLeft: "10px" }}
                  >
                    i
                  </button>
                </section>
                <section
                  className="text_inferior"
                  style={{ marginBottom: "10px" }}
                >
                  {
                    dict[
                      "Rename layer, edit description, group position and toggle publication status of the layer. If you set this layer as public, your layer will be shown on our community dashboard."
                    ][language]
                  }
                </section>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={layer_inside.name}
                  onChange={this.handleChange}
                />
                <textarea
                  type="text"
                  name="description"
                  id="description"
                  value={
                    layer_inside?.description ? layer_inside.description : ""
                  }
                  onChange={this.handleChange}
                  rows="4"
                />
                <select
                  id="folder"
                  name="folder"
                  value={geo_layer.folder}
                  onChange={this.handleChange}
                >
                  {folders_state.map(({ _id, name }, idx) => {
                    return (
                      <option key={idx} value={_id}>
                        {name}
                      </option>
                    );
                  })}
                </select>
                <CheckBox
                  text="isPublic"
                  title="Public Data"
                  value={layer_inside.isPublic}
                  handle={this.handleToggle.bind(this)}
                />
                <CheckBox
                  text="show_only_highlight_field"
                  title="Hanya tampilkan pertanyaan yang di highlight saja"
                  value={layer_inside.show_only_highlight_field}
                  handle={this.handleToggle.bind(this)}
                />
                <br />
                <div className="button" onClick={this.toggle_edit_chart}>
                  Buat Grafik
                </div>
              </section>

              <section
                className="container_flat"
                style={{ marginBottom: "15px" }}
              >
                <div className="text_bold">
                  {dict["Form config"][language]}
                  <button
                    className="button_info"
                    id="blue"
                    onClick={this.onOpenModal.bind(this, "Membuat Form")}
                    style={{ marginLeft: "10px" }}
                  >
                    i
                  </button>
                </div>
                <div className="text_inferior" style={{ marginBottom: "10px" }}>
                  {
                    dict[
                      "GEO MAPID is integrated with FORM MAPID, edit form configuration here. You can test the link by click on it, if you set this layer as public form you can just test it without logging in, if not you need to login."
                    ][language]
                  }
                </div>
                <CheckBox
                  text="isLocationChangeable"
                  title={dict["Changeable Location (in FORM MAPID)"][language]}
                  value={layer_inside.isLocationChangeable}
                  handle={this.handleToggle.bind(this)}
                />
                <CheckBox
                  text="is_form_login_req"
                  title="Required to login"
                  value={layer_inside.is_form_login_req}
                  handle={this.handleToggle.bind(this)}
                />
                <CheckBox
                  text="isFormPublic"
                  title={dict["Public Form"][language]}
                  value={layer_inside.isFormPublic}
                  handle={this.handleToggle.bind(this)}
                />

                <CheckBox
                  text="is_survey"
                  title={dict["Survey manager"][language]}
                  value={layer_inside.is_survey}
                  handle={this.handleToggle.bind(this)}
                />
                <div style={{ marginTop: "5px" }}>
                  Link:{" "}
                  <a
                    href={`${domain_temp}${layer_inside?.link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#2196f3" }}
                  >
                    {`${domain_temp}${layer_inside?.link}`}
                  </a>
                </div>

                <div
                  style={{ display: "flex", marginTop: "10px", gap: "10px" }}
                >
                  <Link
                    // onClick={this.setLayerWOgeojson.bind(this, geo_layer_clone)}
                    to={`/form_editor/${layer_inside.link}`}
                    // target="_blank"
                    className="button"
                    style={{
                      backgroundColor: "#2196f3",
                      color: "#ffffff",
                      display: "inline",
                    }}
                  >
                    {dict["Open form editor"][language]}
                  </Link>
                  <button
                    onClick={this.toggle_link_edit}
                    style={{ backgroundColor: "#2196f3", display: "inline" }}
                    className="button"
                  >
                    Link Edit
                  </button>
                </div>

                <hr />
                <section>
                  <label className="text_bold w_full">Data Pembanding</label>
                  <p>
                    Pilih data pembanding untuk alat bantu surveyor memilih data
                    pembanding terdekat dengan lokasi survei
                  </p>

                  <div
                    onClick={this.toggle_select_comparison_data}
                    style={{ backgroundColor: "#2196f3" }}
                    className="button"
                  >
                    {dict["Select comparison data"][language]}
                  </div>

                  <p>{dict["Selected comparison data:"][language]}</p>
                  <div className="overflow_x flex gap_10 w_full">
                    {layer_data_list.length > 0 ? (
                      layer_data_list.map((row, idx) => {
                        return (
                          <DataPembanding
                            key={idx}
                            name={row.name}
                            geo_layer_id={geo_layer_id}
                          />
                        );
                      })
                    ) : (
                      <p>Belum ada</p>
                    )}
                  </div>
                  <p>
                    Radius:{" "}
                    {layer_inside?.radius_pembanding
                      ? `${layer_inside?.radius_pembanding} meter`
                      : "Belum atur radius"}
                  </p>
                  <div
                    className="button"
                    onClick={this.toggle_edit_radius_pembanding}
                  >
                    Edit radius pembanding
                  </div>
                </section>

                <hr />
                <section>
                  <label className="text_bold w_full">
                    Data Overlay FORM MAPID
                  </label>
                  <p>Pilih data yang akan di-overlay-kan di peta FORM MAPID</p>

                  <div
                    onClick={this.toggle_select_data_form}
                    style={{ backgroundColor: "#2196f3" }}
                    className="button"
                  >
                    Pilih
                  </div>

                  <p>Data overlay yang sudah dipilih</p>
                  <div className="overflow_x flex gap_10 w_full">
                    {layer_form_list.length > 0 ? (
                      layer_form_list.map((row, idx) => {
                        return (
                          <DeleteDataFormOverlay
                            key={idx}
                            name={row.name}
                            geo_layer_id={geo_layer_id}
                          />
                        );
                      })
                    ) : (
                      <p>Belum ada</p>
                    )}
                  </div>
                </section>
              </section>

              <section
                className="container_flat"
                style={{ marginBottom: "15px" }}
              >
                <div className="text_bold">
                  {dict["Advance"][language]}
                  <button
                    className="button_info"
                    id="blue"
                    onClick={this.onOpenModal.bind(this, "Layer Style")}
                    style={{ marginLeft: "10px" }}
                  >
                    i
                  </button>
                </div>
                <p className="text_inferior" style={{ marginBottom: "10px" }}>
                  {
                    dict[
                      "This configuration is to set the layer style homogeneously. For each layer type (Point, Line, Polygon) it has a different style configuration."
                    ][language]
                  }
                </p>
                <CheckBox
                  text="isExpand"
                  title={dict["Force to expand"][language]}
                  value={
                    layer_inside.isExpand !== undefined
                      ? layer_inside.isExpand
                      : false
                  }
                  handle={this.handleToggle.bind(this)}
                />
                <CheckBox
                  text="isGlobalStyle"
                  title={dict["Layer style"][language]}
                  value={layer_inside.isGlobalStyle}
                  handle={this.handleToggle.bind(this)}
                />
                {layer_inside.isGlobalStyle && (
                  <div>
                    <div
                      className="two-container-input"
                      style={{ height: "40px", fontWeight: 600 }}
                    >
                      <div style={{ textAlign: "left" }}>
                        {dict["Layer type"][language]}
                      </div>
                      <div style={{ textAlign: "left" }}>
                        {layer_inside.type}
                      </div>
                    </div>

                    <div className="two-container-input">
                      <div
                        style={{
                          textAlign: "left",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {dict["Style mode"][language]}
                      </div>
                      <div>
                        <select
                          name="globalStyleMode"
                          value={layer_inside.globalStyleMode}
                          onChange={this.handleChange.bind(this)}
                        >
                          <option value="by_value">
                            {dict["By value"][language]}
                          </option>
                          <option value="solid">
                            {dict["Solid"][language]}
                          </option>
                        </select>
                      </div>
                    </div>
                    {layer_inside.globalStyleMode === "by_value" && (
                      <div>
                        {layer_inside.fields.filter((f) => !f.isHide).length <
                        1 ? (
                          <div
                            style={{
                              textAlign: "center",
                              padding: 5,
                              marginTop: 5,
                              marginBottom: 10,
                            }}
                            className="text_bold"
                          >
                            {dict["You don't have a data column"][language]}
                          </div>
                        ) : (
                          <div>
                            <div className="two-container-input">
                              <div>{dict["Classification mode"][language]}</div>
                              <div>
                                <select
                                  name={"classify_mode"}
                                  value={classify_mode}
                                  onChange={this.handleChangeValueProps.bind(
                                    this
                                  )}
                                >
                                  <option value={"number"}>
                                    {dict["Classify by number"][language]}
                                  </option>
                                  <option value={"text"}>
                                    {dict["Classify by group"][language]}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="two-container-input">
                              <div
                                style={{
                                  textAlign: "left",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {dict["Style mode"][language]}
                              </div>
                              <div>
                                <select
                                  name={"selected_color"}
                                  value={selected_color}
                                  onChange={this.handleChangeValueProps.bind(
                                    this
                                  )}
                                >
                                  <option value="darkred,yellow,darkgreen">
                                    {dict["Red to Green"][language]}
                                  </option>
                                  <option value="darkgreen,yellow,darkred">
                                    {dict["Green to Red"][language]}
                                  </option>
                                  <option value="lightblue,blue,darkblue">
                                    {dict["Light Blue to dark blue"][language]}
                                  </option>
                                  <option value="lightgreen,green,darkgreen">
                                    {
                                      dict["Light green to dark green"][
                                        language
                                      ]
                                    }
                                  </option>
                                  <option value="lightcoral,red,maroon">
                                    {dict["Light red to dark red"][language]}
                                  </option>
                                  <option value="random">
                                    {dict["Random color"][language]}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="two-container-input">
                              <div
                                style={{
                                  textAlign: "left",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {dict["Column"][language]}{" "}
                              </div>
                              <div>
                                <select
                                  name={"selected_column"}
                                  value={selected_column}
                                  onChange={this.handleChangeValueProps.bind(
                                    this
                                  )}
                                >
                                  <option value={""}>
                                    ==={dict["Select a Column"][language]}===
                                  </option>
                                  {layer_inside.fields
                                    .filter((f) => !f.isHide)
                                    .map(({ key, name }, idx) => (
                                      <option
                                        key={`col-style-${idx}`}
                                        value={key}
                                      >
                                        {name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                            {isNumber && (
                              <div>
                                <div className={"two-container-input"}>
                                  <div
                                    style={{
                                      textAlign: "left",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {dict["number_of_classes"][language]} (
                                    {class_number})
                                  </div>
                                  <div>
                                    {/* <input
                                      name="class_number"
                                      id={"class_number"}
                                      type={"range"}
                                      min={2}
                                      // max={max - min <= 15 ? max - min : 15}
                                      max={15}
                                      step={1}
                                      value={class_number}
                                      onChange={this.handleChangeValueProps.bind(
                                        this
                                      )}
                                      /> */}
                                    <input
                                      name="class_number"
                                      id={"class_number"}
                                      type={"number"}
                                      value={class_number}
                                      onChange={this.handleChangeValueProps.bind(
                                        this
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            <div>
                              {geojson?.features &&
                                (isNumber &&
                                number_type.includes(type?.type) ? (
                                  <div className={"two-container-input"}>
                                    <div>{dict["Style"][language]}</div>
                                    <div>
                                      {this.legend({
                                        max: Math.max.apply(
                                          Math,
                                          geojson?.features.map(function (o) {
                                            return o
                                              .properties[selected_column];
                                          })
                                        ),
                                        min: Math.min.apply(
                                          Math,
                                          geojson?.features.map(function (o) {
                                            return o
                                              .properties[selected_column];
                                          })
                                        ),
                                        legendItem: legendItem,
                                        classNumber: class_number,
                                        isNumber,
                                        selected_color,
                                      })}
                                    </div>
                                  </div>
                                ) : (
                                  !isEmpty(selected_column) && (
                                    <div className={"two-container-input"}>
                                      <div>{dict["Style"][language]}</div>
                                      <div>
                                        {this.legend({
                                          max: Math.max.apply(
                                            Math,
                                            geojson?.features.map(function (o) {
                                              return o
                                                .properties[selected_column];
                                            })
                                          ),
                                          min: Math.min.apply(
                                            Math,
                                            geojson?.features.map(function (o) {
                                              return o
                                                .properties[selected_column];
                                            })
                                          ),
                                          legendItem: legendItem,
                                          classNumber: class_number,
                                          dataCol: variableRange,
                                          range_group,
                                          isNumber,
                                          selected_color,
                                        })}
                                      </div>
                                    </div>
                                  )
                                ))}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {properties
                      .filter(
                        (x) =>
                          x.key !== "line_gap_width" &&
                          x.key !== "line_dash_length" &&
                          x.key !== "line_dash_gap-length"
                      )
                      .map(({ key, type, min, max, step, name }, idx) => {
                        const colName =
                          type === "range"
                            ? `${key} (${layer_inside?.properties[idx].defaultValue})`
                            : name;
                        return (
                          <div className="two-container-input" key={idx}>
                            <div
                              style={{
                                textAlign: "left",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {colName}
                            </div>
                            {key === "icon_image" ? (
                              <select
                                id={idx}
                                name="properties"
                                value={
                                  layer_inside?.properties[idx].defaultValue
                                }
                                onChange={this.handleChange.bind(this)}
                              >
                                {listIcon.map(({ name, icon }) => {
                                  return (
                                    <option key={icon} value={icon}>
                                      {name}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : key === "text_field" ? (
                              <select
                                id={idx}
                                name="properties"
                                value={
                                  layer_inside?.properties[idx].defaultValue
                                }
                                onChange={this.handleChange.bind(this)}
                              >
                                <option value={"point"}>
                                  ==={dict["Select a Column"][language]}===
                                </option>
                                {layer_inside?.fields
                                  .filter((fl) => !fl.isHide)
                                  .map(({ key, name }) => {
                                    return (
                                      <option key={key} value={key}>
                                        {name}
                                      </option>
                                    );
                                  })}
                              </select>
                            ) : type === "checkbox" ? (
                              <CheckBox
                                name="properties"
                                title=""
                                id={idx}
                                type="checkbox"
                                text={idx}
                                value={
                                  layer_inside?.properties[idx].defaultValue
                                }
                                handle={this.handleToggle.bind(this)}
                              />
                            ) : (
                              <input
                                name="properties"
                                id={idx}
                                type={type}
                                min={min}
                                max={max}
                                step={step}
                                value={
                                  layer_inside?.properties[idx].defaultValue
                                }
                                onChange={this.handleChange}
                              />
                            )}
                          </div>
                        );
                      })}
                  </div>
                )}
                <div
                  onClick={this.toggle_clone_field}
                  className="button"
                  style={{
                    backgroundColor: "#2196f3",
                    color: "#ffffff",
                    marginTop: "10px",
                  }}
                >
                  {dict["Clone attribute"][language]}
                </div>
              </section>

              <section
                className="container_flat"
                style={{ marginBottom: "15px" }}
              >
                <div className="text_bold">
                  Penggabungan spasial (geospatial join attribute)
                </div>
                <p className="text_inferior margin_bottom">
                  Penggabungan spasial adalah fitur untuk menggabungkan dua data
                  lokasi berdasarkan hubungan spasial antara geometri keduanya.
                </p>
                <div className="button" onClick={this.toggle_spatial_join}>
                  Pilih data layer
                </div>
              </section>
              {open_api_content}

              {/* {is_custom_api && (
                <section className="container_flat">
                  <ApiInput />
                </section>
              )} */}
            </main>
            <section
              style={{
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              {button_save_content}
            </section>
          </form>
        </main>
      );
    }

    const modal_manage_chart_content = modal_manage_chart && (
      <Modal
        modalSize="medium"
        id="modal_manage_chart"
        isOpen={modal_manage_chart}
        onClose={this.toggle_edit_chart}
      >
        <div className="box-body">
          <MANAGE_CHART />
        </div>
      </Modal>
    );

    const modal_select_comparison_data_content =
      modal_select_comparison_data && (
        <Modal
          modalSize="medium"
          id="modal_select_comparison_data"
          isOpen={modal_select_comparison_data}
          onClose={this.toggle_select_comparison_data}
        >
          <div className="box-body">
            <SelectDataPembanding
              toggle_select_comparison_data={this.toggle_select_comparison_data}
            />
          </div>
        </Modal>
      );

    const modal_select_data_form_content = modal_select_data_form && (
      <Modal
        modalSize="medium"
        id="modal_select_comparison_data"
        isOpen={modal_select_data_form}
        onClose={this.toggle_select_data_form}
      >
        <div className="box-body">
          <SelectDataForm
            toggle_select_data_form={this.toggle_select_data_form}
          />
        </div>
      </Modal>
    );

    const modal_clone_field_content = modal_clone_field && (
      <Modal
        modalSize="small"
        id="modal_clone_field"
        isOpen={modal_clone_field}
        onClose={this.toggle_clone_field}
      >
        <div className="box-body">
          <form onSubmit={this.handleSubmitField.bind(this)}>
            <div className="text_bold">
              {dict["Clone attributes from other layer"][language]}
            </div>
            <div className="text_inferior" style={{ marginBottom: "10px" }}>
              {
                dict[
                  "This action will overwrite the attribute list from other layer to this layer, do this if you want to clone attribute. Be careful this action can't be undone."
                ][language]
              }
            </div>
            <select
              id="fields"
              name="fields"
              value={fields}
              onChange={this.handleChange.bind(this)}
            >
              {geo_layer_list.map((layer, idx) => {
                return (
                  <option key={idx} value={idx}>
                    {layer?.geo_layer?.name}
                  </option>
                );
              })}
            </select>
            <div style={{ textAlign: "center" }}>
              {button_save_overwrite_fields}
            </div>
          </form>
        </div>
      </Modal>
    );

    const modal_spatial_join_content = modal_spatial_join && (
      <Modal
        modalSize="medium"
        id="modal_spatial_join"
        isOpen={modal_spatial_join}
        onClose={this.toggle_spatial_join}
      >
        <div className="box-body">
          <SpatialJoin />
        </div>
      </Modal>
    );

    const modal_edit_radius_pembanding_content =
      modal_edit_radius_pembanding && (
        <Modal
          modalSize="small"
          id="modal_edit_radius_pembanding"
          isOpen={modal_edit_radius_pembanding}
          onClose={this.toggle_edit_radius_pembanding}
        >
          <div className="box-body">
            <EditRadiusPembanding
              toggle_edit_radius_pembanding={this.toggle_edit_radius_pembanding}
            />
          </div>
        </Modal>
      );

    return (
      <>
        {modal_manage_chart_content}
        {modal_select_comparison_data_content}
        {modal_clone_field_content}
        {modal_select_data_form_content}
        {modal_spatial_join_content}
        {modal_edit_radius_pembanding_content}
        {final_content}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  project: state.project,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setField,
  doSentimentAnalysis,
  setLayerWOgeojson,
  openModal,
  editLayerGeneral,
  editLayerGeneralAttached,
  getDetailLayerById,
  initApi,
  set_value,
})(EditLayer);
