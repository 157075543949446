import React, { Component } from "react";
import { connect } from "react-redux";
import { pushFeature, pushFeatureV2 } from "../../App/actions/layerActions";
import randomcolor from "randomcolor";
import uuid from "../../App/validation/uuid";
import dict from "../../Data/dict.json";
import Modal from "../reuseable/Modal";

class CreateRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeLayer: "",
      modal_lat_long: false,
      latitude: -4.409208952216346,
      longitude: 116.96618000886156,
    };
  }

  set_latitutde = (e) => {
    this.setState({
      latitude: e.target.value,
    });
  };

  set_longitude = (e) => {
    this.setState({
      longitude: e.target.value,
    });
  };

  toggle_modal_lat_long = () => {
    const { modal_lat_long } = this.state;
    this.setState({
      modal_lat_long: !modal_lat_long,
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { layer_id, geo_layer_list } = nextProps.layer;
    const layer = geo_layer_list.find((l) => l.geo_layer._id === layer_id);

    const typeLayer = layer?.geo_layer?.type;

    return {
      typeLayer,
    };
  }

  handleType = () => {};

  handleAddRow = async () => {
    const { typeLayer, latitude, longitude } = this.state;
    let prop = {};
    let coordinates = {};
    if (typeLayer === "Point") {
      prop = {
        icon_image: "museum",
        text_field: "icon_image",
        circle_color: randomcolor(),
        circle_radius: 5,
        circle_stroke_width: 1,
        circle_stroke_color: "#fff",
      };

      coordinates = {
        coordinates: [Number(longitude), Number(latitude)],
        type: "Point",
      };
    } else if (typeLayer === "Polygon" || typeLayer === "MultiPolygon") {
      prop = {
        color: randomcolor(),
        opacity: 0.5,
        outline_color: "#fff",
      };

      coordinates = {
        coordinates: [
          [
            [114, -4],
            [114, -5],
            [116, -5],
            [116, -4],
            [114, -4],
          ],
        ],
        type: "Polygon",
      };
    } else if (typeLayer === "MultiLineString" || typeLayer === "LineString") {
      prop = {
        color: randomcolor(),
        width: 2,
        line_width: 1.25,
        opacity: 0.5,
        line_gap_width: 0,
      };
      coordinates = {
        coordinates: [
          [107.95988015062431, -4.088269057402783],
          [126.95661571104824, -5.882793830394718],
        ],
        type: "LineString",
      };
    }

    const key = uuid();

    let feature = {
      key,
      type: "Feature",
      properties: prop,
      formStatus: {
        status: "digitasi",
        message: "",
        revision_list: [],
      },
      geometry: coordinates,
      isHide: false,
    };
    const body = {
      geo_layer_id: this.props.layer.layer_id,
      feature,
    };
    const { layer_id, geo_layer_list } = this.props.layer;
    const layer = geo_layer_list.find((l) => l.geo_layer._id === layer_id);
    if (layer?.geo_layer?.architecture === "v2") {
      await this.props.pushFeatureV2(body);
    } else {
      await this.props.pushFeature(body);
    }

    const limit = this.props.current_page.pageSize;
    const rows_length =
      this.props?.apiDataGridRef?.current?.getAllRowIds()?.length || 0;
    const last_page = Math.ceil(rows_length / limit) - 1;

    this.props.apiDataGridRef.current.setPage(last_page);

    const rowIndex = rows_length - 1;
    const colIndex = this.props.apiDataGridRef.current.getColumnIndex(
      "no",
      false
    );
    const indexes = { rowIndex, colIndex };
    this.props.apiDataGridRef.current.scrollToIndexes(indexes);
  };

  handleAddRowClicked = () => {
    const { typeLayer } = this.state;
    if (typeLayer === "Point") {
      this.toggle_modal_lat_long();
    } else {
      this.handleAddRow();
    }
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { typeLayer, modal_lat_long, latitude, longitude } = this.state;

    const modal_lat_long_content = typeLayer === "Point" && modal_lat_long && (
      <Modal
        title="Longitude and Latitude"
        modalSize="small"
        id="modal_latitude_longitude"
        isOpen={modal_lat_long}
        onClose={this.toggle_modal_lat_long}
      >
        <section>
          <label>Longitude</label>
          <input
            type="number"
            placeholder="Longitude, ex: 125.2342"
            value={longitude}
            onChange={this.set_longitude}
          />
        </section>
        <section>
          <label>Latitude</label>
          <input
            type="number"
            placeholder="Latitude, ex: 83.2342"
            value={latitude}
            onChange={this.set_latitutde}
          />
        </section>
        <section className="center_perfect">
          <button
            onClick={this.handleAddRow}
            className="button_inactive bg_blue text_white rounded_5 padding_5"
          >
            Submit
          </button>
        </section>
      </Modal>
    );

    return (
      <>
        <button
          onClick={this.handleAddRowClicked}
          style={{
            color: "#0166cb",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            textTransform: "uppercase",
          }}
        >
          <span
            style={{
              fontWeight: "500",
              fontSize: "25px",
              marginTop: "-5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            +
          </span>
          {dict?.["Add Row"]?.[language]}
        </button>
        {modal_lat_long_content}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  pushFeature,
  pushFeatureV2,
})(CreateRow);
