//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function

//Picture Asset
import * as Assets from "../Assets";

//General Function
const youtube_link =
  "https://www.youtube.com/playlist?list=PLExQBc2gAI8uopeN3Xaj9V9b1QLPp8wPj";

class Tutorial extends Component {
  render() {
    return (
      <main className="main_container" id="main_container">
        <h1>Tutorial</h1>
        <img
          src={Assets.icon.icon_404}
          alt="Viewer"
          style={{ width: "200px", maxWidth: "70%", marginBottom: "20px" }}
        />
        <br />
        <a
          style={{ marginTop: "20px" }}
          href={youtube_link}
          target="_blank"
          rel="noopener noreferrer"
          className="button_standard"
        >
          MAPID TUTORIAL PLAYLIST
        </a>
      </main>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(Tutorial);
