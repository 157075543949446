/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Menu from "@mui/material/Menu";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_project } from "../../App/actions/projectActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";
import sort_array from "../../App/validation/sort_array";

/*NON IMPORT*/

class PROJECT_CONTROL_PANEL extends Component {
  state = {
    modal_menu: false,
  };

  on_change_localstorage = (name, value) => {
    this.setState({ modal_menu: false });
    localStorage.setItem(name, value);
    const is_sort_from_small = this.props.project.is_sort_from_small;
    this.props.set_value_project({ key: name, value: value });
    if (name === "mode_sort") {
      let {
        geo_project_list,
        geo_project_list_child,
        folder_project_list,
        folder_project_child_list,
        folder_object_selected_list,
      } = this.props.project;
      /*
      folder
      
      name - name
      date_created - date_created
      date_modified - date

      project

      name - name
      date_created - date_created
      date_modified - date_modified
      */
      let key_project = value;
      let key_folder = value;
      if (value === "date_modified") {
        key_project = "date";
      }
      if (folder_object_selected_list.length === 0) {
        geo_project_list = sort_array(
          geo_project_list,
          key_project,
          is_sort_from_small
        );
        folder_project_list = sort_array(
          folder_project_list,
          key_folder,
          is_sort_from_small
        );
        this.props.set_value_project({
          key: "geo_project_list",
          value: geo_project_list,
        });
        this.props.set_value_project({
          key: "folder_project_list",
          value: folder_project_list,
        });
      } else {
        geo_project_list_child = sort_array(
          geo_project_list_child,
          key_project,
          is_sort_from_small
        );
        folder_project_child_list = sort_array(
          folder_project_child_list,
          key_folder,
          is_sort_from_small
        );
        this.props.set_value_project({
          key: "geo_project_list_child",
          value: geo_project_list_child,
        });
        this.props.set_value_project({
          key: "folder_project_child_list",
          value: folder_project_child_list,
        });
      }
    }
  };

  toggle_localstorage = (name) => {
    const value = this.props.project[name];
    const new_value = !value;
    localStorage.setItem(name, new_value);
    this.props.set_value_project({ key: name, value: new_value });
    const mode_sort = !!localStorage.getItem("mode_sort")
      ? localStorage.getItem("mode_sort")
      : "date_created";
    this.on_change_localstorage("mode_sort", mode_sort);
  };

  toggle_menu = (e) => {
    this.setState({ modal_menu: !this.state.modal_menu });
    if (e) {
      this.setState({
        anchor: e.currentTarget,
      });
    }
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";
    const mode_view = !!localStorage.getItem("mode_view")
      ? localStorage.getItem("mode_view")
      : "box"; //box || list
    const mode_sort = !!localStorage.getItem("mode_sort")
      ? localStorage.getItem("mode_sort")
      : "date_modified"; //name || date_created || date_modified

    //local state
    const { modal_menu, anchor } = this.state;

    //global props
    const is_sort_from_small = this.props.project.is_sort_from_small;

    //content

    const mode_sort_list = [
      {
        key: "name",
        name: dict?.["Name"]?.[language],
      },
      {
        key: "date_created",
        name: dict?.["Date created"]?.[language],
      },
      {
        key: "date_modified",
        name: dict?.["Date modified"]?.[language],
      },
    ];

    const mode_sort_name = mode_sort_list.find(
      (item) => item.key === mode_sort
    )?.name;

    const modal_menu_content = modal_menu && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_menu}
        onClose={this.toggle_menu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        style={{ borderRadius: "15px" }}
      >
        <div style={{ padding: "7px 15px 0px 15px" }}>
          {mode_sort_list.map((item, idx) => {
            return (
              <div key={idx}>
                <button
                  onClick={this.on_change_localstorage.bind(
                    this,
                    "mode_sort",
                    item.key
                  )}
                  className={`button_small margin_bottom ${
                    mode_sort === item.key
                      ? "background_black"
                      : "background_grey"
                  }`}
                >
                  {item?.name}
                </button>
                <br />
              </div>
            );
          })}
        </div>
      </Menu>
    );

    return (
      <main>
        {modal_menu_content}
        <table className="full_width">
          <tbody>
            <tr>
              <td className="text_left" style={{ verticalAlign: "top" }}>
                <main className="margin_bottom">
                  <button
                    onClick={this.props.toggle_create_project}
                    className="button_pill background_white margin_bottom margin_right"
                  >
                    <span className="badge_circle background_green">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={9.5}
                        height={9.5}
                        viewBox="0 0 2.514 2.514"
                        className="center_child"
                      >
                        <path
                          d="M1.257 2.315V.198M.198 1.257h2.117"
                          style={{
                            fill: "none",
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeWidth: 0.39688,
                            stroke: "#fff",
                          }}
                        />
                      </svg>
                    </span>{" "}
                    {dict?.["New Project"]?.[language]}
                  </button>

                  <button
                    onClick={this.props.toggle_create_folder}
                    className="button_pill background_white margin_bottom margin_right"
                  >
                    <span className="badge_circle background_green">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={9.5}
                        height={9.5}
                        viewBox="0 0 2.514 2.514"
                        className="center_child"
                      >
                        <path
                          d="M1.257 2.315V.198M.198 1.257h2.117"
                          style={{
                            fill: "none",
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeWidth: 0.39688,
                            stroke: "#fff",
                          }}
                        />
                      </svg>
                    </span>{" "}
                    {dict?.["New Folder"]?.[language]}
                  </button>

                  <Link
                    to="/blog_list"
                    className="button_pill background_white margin_bottom margin_right"
                  >
                    <span className="badge_circle background_green">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={9.5}
                        height={9.5}
                        viewBox="0 0 2.514 2.514"
                        className="center_child"
                      >
                        <path
                          d="M1.257 2.315V.198M.198 1.257h2.117"
                          style={{
                            fill: "none",
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                            strokeWidth: 0.39688,
                            stroke: "#fff",
                          }}
                        />
                      </svg>
                    </span>{" "}
                    {dict?.["Publication"]?.[language]}
                  </Link>
                </main>
              </td>
              <td className="text_right">
                <button
                  className={`button margin_bottom margin_right ${
                    mode_view === "box"
                      ? "background_black"
                      : "background_white"
                  }`}
                  onClick={this.on_change_localstorage.bind(
                    this,
                    "mode_view",
                    "box"
                  )}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={15}
                    height={15}
                    viewBox="0 0 5.292 5.292"
                  >
                    <g
                      transform="translate(-426.14 -81.632)"
                      className={
                        mode_view === "box"
                          ? "background_black"
                          : "background_white"
                      }
                    >
                      <rect
                        width={2.075}
                        height={2.075}
                        x={429.09}
                        y={81.897}
                        rx={0.3}
                        ry={0.661}
                        style={{
                          fill: "none",
                          paintOrder: "stroke markers fill",
                          strokeDashoffset: 11.411,
                          strokeWidth: 0.52917,
                        }}
                      />
                      <rect
                        width={2.075}
                        height={2.075}
                        x={429.09}
                        y={84.584}
                        rx={0.3}
                        ry={0.661}
                        style={{
                          fill: "none",
                          paintOrder: "stroke markers fill",
                          strokeDashoffset: 11.411,
                          strokeWidth: 0.52917,
                        }}
                      />
                      <rect
                        width={2.075}
                        height={2.075}
                        x={426.4}
                        y={84.584}
                        rx={0.3}
                        ry={0.661}
                        style={{
                          fill: "none",
                          paintOrder: "stroke markers fill",
                          strokeDashoffset: 11.411,
                          strokeWidth: 0.52917,
                        }}
                      />
                      <rect
                        width={2.075}
                        height={2.075}
                        x={426.4}
                        y={81.897}
                        rx={0.3}
                        ry={0.661}
                        style={{
                          fill: "none",
                          paintOrder: "stroke markers fill",
                          strokeDashoffset: 11.411,
                          strokeWidth: 0.52917,
                        }}
                      />
                    </g>
                  </svg>
                </button>
                <button
                  className={`button margin_bottom margin_right ${
                    mode_view === "list"
                      ? "background_black"
                      : "background_white"
                  }`}
                  onClick={this.on_change_localstorage.bind(
                    this,
                    "mode_view",
                    "list"
                  )}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={15}
                    height={15}
                    viewBox="0 0 5.292 5.292"
                  >
                    <path
                      className={
                        mode_view === "list"
                          ? "background_black"
                          : "background_white"
                      }
                      d="M436.07 83.754h3.885M436.07 85.368h3.885M436.07 86.981h3.885"
                      style={{
                        fill: "none",
                        strokeLinecap: "round",
                        strokeLinejoin: "round",
                        strokeWidth: 0.52917,
                      }}
                      transform="translate(-435.37 -82.722)"
                    />
                  </svg>
                </button>

                <br />

                <div
                  className="button_pill background_white margin_bottom margin_right"
                  // style={{ width: "160px" }}
                >
                  <table className="full_width">
                    <tbody>
                      <tr>
                        <td className="text_left">
                          <span
                            className="badge_circle background_grey margin_right"
                            onClick={this.toggle_localstorage.bind(
                              this,
                              "is_sort_from_small"
                            )}
                          >
                            {!is_sort_from_small ? (
                              <svg
                                className="center_child"
                                xmlns="http://www.w3.org/2000/svg"
                                width={7.5}
                                height={9.5}
                                viewBox="0 0 1.984 2.514"
                              >
                                <path
                                  d="M.992 2.315V.198M.198 1.528l.794.787.794-.787"
                                  style={{
                                    fill: "none",
                                    strokeLinecap: "round",
                                    strokeLinejoin: "round",
                                    strokeWidth: 0.39688,
                                    stroke: "#000",
                                  }}
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={7.5}
                                height={9.499}
                                viewBox="0 0 1.984 2.514"
                              >
                                <path
                                  d="M.992.198v2.117m.794-1.33L.992.198.198.985"
                                  style={{
                                    fill: "none",
                                    paintOrder: "stroke markers fill",
                                    strokeDashoffset: 11.411,
                                    strokeLinecap: "round",
                                    strokeLinejoin: "round",
                                    strokeWidth: 0.39688,
                                    stroke: "#000",
                                  }}
                                />
                              </svg>
                            )}
                          </span>
                        </td>
                        <td className="text_right">
                          <div
                            onClick={this.toggle_menu}
                            className="inline_center"
                          >
                            {mode_sort_name}{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={10}
                              height={10}
                              viewBox="0 0 3.925 3.925"
                              className="margin_left"
                            >
                              <path
                                d="m443.56 84.139 1.323 1.323 1.323-1.323z"
                                style={{
                                  fillRule: "evenodd",
                                  fill: "#000",
                                  paintOrder: "stroke markers fill",
                                }}
                                transform="translate(-442.92 -82.838)"
                              />
                            </svg>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  project: state.project,
});

export default connect(mapStateToProps, { set_value_project })(
  PROJECT_CONTROL_PANEL
);
