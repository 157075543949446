/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";
import ProgressBar from "../common_spinner/ProgressBar";

/*REDUX FUNCTION*/
import { set_value_properties } from "../../App/actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class STATUS_GET_GENANGAN extends Component {
  state = {};

  on_close_modal = () => {
    this.props.set_value_properties({
      key: "modal_pop_up_genangan",
      value: false,
    });
  };

  render() {
    //local storage

    //local state

    //global props
    const {
      modal_pop_up_genangan,
      success_status,
      error_status,
      error_message,
      loading_status,
      loading_item,
      tipe_genangan,
      total_progress_genangan,
      current_progress_genangan,
    } = this.props.properties;

    //content

    let content;
    if (loading_status && loading_item === "get_genangan") {
      content = (
        <>
          <p className="text_medium">Menjalankan algoritma genangan...</p>
          <br />
          <br />
          <br />
          <ProgressBar
            current_number={current_progress_genangan}
            total_number={total_progress_genangan}
            name="Algoritma genangan"
          />
        </>
      );
    } else if (success_status) {
      content = (
        <>
          <p className="text_small">Status:</p>
          <p className="text_medium">Berhasil memuat genangan</p>
          <br />
          <p className="text_small">Sumber data:</p>
          <p className="text_medium">{tipe_genangan}</p>
        </>
      );
    } else if (error_status) {
      content = (
        <>
          <p className="text_small">Status:</p>
          <p className="text_medium">Gagal menjalankan algoritma genangan</p>
          <br />
          <p className="text_small">Pesan error:</p>
          <p className="container_light">{error_message}</p>
        </>
      );
    }

    const modal_content = modal_pop_up_genangan && (
      <Modal
        modalSize="small"
        isOpen={modal_pop_up_genangan}
        onClose={this.on_close_modal}
      >
        <div className="box-body" id="box-body">
          {content}
        </div>
      </Modal>
    );

    return <>{modal_content}</>;
  }
}

const mapStateToProps = (state) => ({
  properties: state.properties,
});

export default connect(mapStateToProps, { set_value_properties })(
  STATUS_GET_GENANGAN
);
