//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import ChartTemplate from "../viewer_chart/ChartTemplate";
import dict from "../../Data/dict.json";

import icon_download from "../../Assets/svg/icon_download.svg";
import icon_view_blue from "../../Assets/svg/icon_view_blue.svg";
import icon_view_grey from "../../Assets/svg/icon_view_grey.svg";
import { IconOpen } from "./icon";

class DemografiSini extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hide: false,
    };
  }
  render() {
    const { hide } = this.state;
    const { request_id, sini_list } = this.props.layer;
    const language = localStorage?.language ? localStorage?.language : "ina";

    const demografi = sini_list?.filter((e) => e.request_id === request_id)[0]
      .demografi;

    const features = demografi?.features;
    const feature = features?.[0];
    let properties = feature?.properties;

    if (demografi?.features?.length > 0) {
      const {
        PROVINSI,
        KECAMATAN,

        /*Agama*/
        ISLAM,
        KRISTEN,
        KATHOLIK,
        HINDU,
        BUDHA,
        KONGHUCU,
        KPERCAYAAN,
        /*Jenis kelamin*/

        /*Status Perkawinan*/

        /*Status Perpindahan*/
        // JML_PINDAH,
        // JML_DATANG,
        // JML_LAHIR,
        // MENINGGAL,
        /*Umur Pendidikan*/

        /*Pendidikan*/

        /*Pekerjaan*/
        /*Usia*/
        /*Kelahiran 2015-2020*/

        /*Kelahiran Sebelum 2015-2020*/

        /*Pertambahan 2015-2020*/
      } = properties;

      const KAB_KOT =
        properties?.["KABUPATEN ATAU KOTA"] || properties?.KAB_KOT;
      const DESA = properties?.["DESA ATAU KELURAHAN"] || properties?.DESA;

      const U0 = properties?.["USIA 0-4 TAHUN"] || properties?.U0;
      const U5 = properties?.["USIA 5-9 TAHUN"] || properties?.U5;
      const U10 = properties?.["USIA 10-14 TAHUN"] || properties?.U10;
      const U15 = properties?.["USIA 15-19 TAHUN"] || properties?.U15;
      const U20 = properties?.["USIA 20-24 TAHUN"] || properties?.U20;
      const U25 = properties?.["USIA 25-29 TAHUN"] || properties?.U25;
      const U30 = properties?.["USIA 30-34 TAHUN"] || properties?.U30;
      const U35 = properties?.["USIA 35-39 TAHUN"] || properties?.U35;
      const U40 = properties?.["USIA 40-44 TAHUN"] || properties?.U40;
      const U45 = properties?.["USIA 45-49 TAHUN"] || properties?.U45;
      const U50 = properties?.["USIA 50-54 TAHUN"] || properties?.U50;
      const U55 = properties?.["USIA 55-59 TAHUN"] || properties?.U55;
      const U60 = properties?.["USIA 60-64 TAHUN"] || properties?.U60;
      const U65 = properties?.["USIA 65-69 TAHUN"] || properties?.U65;
      const U70 = properties?.["USIA 70-74 TAHUN"] || properties?.U70;
      const U75 = properties?.["USIA 75 TAHUN KE ATAS"] || properties?.U75;

      const PEND_3_5 =
        properties?.["USIA SEKOLAH 3-4 TAHUN"] || properties?.PEND_3_5;
      const PEND_5 = properties?.["USIA SEKOLAH 5 TAHUN"] || properties?.PEND_5;
      const PEND_6_11 =
        properties?.["USIA SEKOLAH 6-11 TAHUN"] || properties?.PEND_6_11;
      const PEND_12_14 =
        properties?.["USIA SEKOLAH 12-14 TAHUN"] || properties?.PEND_12_14;
      const PEND_15_17 =
        properties?.["USIA SEKOLAH 15-17 TAHUN"] || properties?.PEND_15_17;
      const Pend_18_22 =
        properties?.["USIA SEKOLAH 18-22 TAHUN"] || properties?.Pend_18_22;

      const BLM_SKLH =
        properties?.["TIDAK/BELUM SEKOLAH"] || properties?.BLM_SKLH;
      const TDK_SD = properties?.["BELUM TAMAT SD"] || properties?.TDK_SD;
      const TAMAT_SD = properties?.["TAMAT SD"] || properties?.TAMAT_SD;
      const SMP = properties?.["SLTP"] || properties?.SMP;
      const SMA = properties?.["SLTA"] || properties?.SMA;
      const D1_D2 = properties?.["D1 DAN D2"] || properties?.D1_D2;
      const D3 = properties?.["D3"] || properties?.D3;
      const D4_S1 = properties?.["S1"] || properties?.D4_S1;
      const S2 = properties?.["S2"] || properties?.S2;
      const S3 = properties?.["S2"] || properties?.S3;

      const POPULASI = properties?.["JUMLAH PENDUDUK"] || properties?.POPULASI;
      const JML_KK = properties?.["JUMLAH KK"] || properties?.JML_KK;
      // const LUAS_KM2 = properties?.["LUAS WILAYAH (KM2)"]  || properties?.LUAS_KM2;
      const KPDTN_PEN =
        properties?.["KEPADATAN PENDUDUK"] || properties?.KPDTN_PEN;
      const PRPND_PEN =
        properties?.["PERPINDAHAN PENDUDUK"] || properties?.PRPND_PEN;
      const PRBHN_DATA =
        properties?.["PERUBAHAN DATA"] || properties?.PRBHN_DATA;
      const WAJIB_KTP = properties?.["WAJIB KTP"] || properties?.WAJIB_KTP;

      const TDK_BKRJ =
        properties?.["BELUM/TIDAK BEKERJA"] || properties?.TDK_BKRJ;
      const PNS = properties?.["APARATUR PEJABAT NEGARA"] || properties?.PNS;
      const TNG_PNGJR =
        properties?.["TENAGA PENGAJAR"] || properties?.TNG_PNGJR;
      const WIRASWASTA = properties?.["WIRASWASTA"] || properties?.WIRASWASTA;
      const TANI_TRNK =
        properties?.["PERTANIAN DAN PETERNAKAN"] || properties?.TANI_TRNK;
      const NELAYAN = properties?.["NELAYAN"] || properties?.NELAYAN;
      const PMK_AGM =
        properties?.["AGAMA DAN KEPERCAYAAN"] || properties?.PMK_AGM;
      const SISWA_MHS =
        properties?.["PELAJAR DAN MAHASISWA"] || properties?.SISWA_MHS;
      const TNG_KSHTN =
        properties?.["TENAGA KESEHATAN"] || properties?.TNG_KSHTN;
      const PENSIUNAN = properties?.["PENSIUNAN"] || properties?.PENSIUNAN;
      const LAINNYA = properties?.["PEKERJAAN LAINNYA"] || properties?.LAINNYA;

      const BLM_KAWIN = properties?.["BELUM KAWIN"] || properties?.BLM_KAWIN;
      const KAWIN = properties?.["KAWIN"] || properties?.KAWIN;
      const CERAI_HDP = properties?.["CERAI HIDUP"] || properties?.CERAI_HDP;
      const CERAI_MT = properties?.["CERAI MATI"] || properties?.CERAI_MT;

      const PRIA = properties?.["LAKI-LAKI"] || properties?.PRIA;
      const WANITA = properties?.["PEREMPUAN"] || properties?.WANITA;

      const SBLM_2018 =
        properties?.["LAHIR SEBELUM TAHUN 2018"] || properties?.SBLM_2018;
      const SBLM_2019 =
        properties?.["LAHIR SEBELUM TAHUN 2019"] || properties?.SBLM_2019;
      const SBLM_2020 =
        properties?.["LAHIR SEBELUM TAHUN 2020"] || properties?.SBLM_2020;

      // const PRTHMBN16 =
      //   properties?.["PERTUMBUHAN PENDUDUK TAHUN 2016 (%)"] ||
      //   properties?.PRTHMBN16;
      // const PRTHMBN17 =
      //   properties?.["PERTUMBUHAN PENDUDUK TAHUN 2017 (%)"] ||
      //   properties?.PRTHMBN17;
      // const PRTMBHN18 =
      //   properties?.["PERTUMBUHAN PENDUDUK TAHUN 2018 (%)"] ||
      //   properties?.PRTMBHN18;

      // const gol_dar_a = properties?.["GOLONGAN DARAH A"]  || properties?.gol_dar_a;
      // const gol_dar_b = properties?.["GOLONGAN DARAH B"] || properties?.gol_dar_b;
      // const gol_dar_ab = properties?.["GOLONGAN DARAH AB"] || properties?.gol_dar_ab;
      // const gol_dar_o = properties?.["GOLONGAN DARAH O"] || properties?.gol_dar_o;

      // const gol_dar_a_plus = properties?.["GOLONGAN DARAH A+"] || properties?.gol_dar_a_plus;
      // const gol_dar_a_minus = properties?.["GOLONGAN DARAH A-"] || properties?.gol_dar_a_minus;
      // const gol_dar_b_plus = properties?.["GOLONGAN DARAH B+"] || properties?.gol_dar_b_plus;
      // const gol_dar_b_minus = properties?.["GOLONGAN DARAH B-"] || properties?.gol_dar_b_minus;
      // const gol_dar_ab_plus = properties?.["GOLONGAN DARAH AB+"] || properties?.gol_dar_ab_plus;
      // const gol_dar_ab_minus = properties?.["GOLONGAN DARAH AB-"] || properties?.gol_dar_ab_minus;
      // const gol_dar_o_plus = properties?.["GOLONGAN DARAH O+"] || properties?.gol_dar_o_plus;
      // const gol_dar_o_minus = properties?.["GOLONGAN DARAH O-"] || properties?.gol_dar_o_minus;
      // const gol_dar_undefined = properties?.["GOLONGAN DARAH TIDAK DIKETAHUI"] || properties?.gol_dar_undefined;

      // const tes = properties?.["tes"];

      properties = {
        default_data: [
          {
            ina: "Alamat",
            eng: "Address",
            value: (
              <div>
                {DESA}, <br />
                {KECAMATAN}, <br />
                {KAB_KOT}, <br />
                {PROVINSI}
              </div>
            ),
            desc_ina: "test",
            desc_eng: "test",
          },
          {
            ina: "Populasi (orang)",
            eng: "Population (people)",
            value: POPULASI,
            desc_ina:
              "Populasi merupakan jumlah penduduk yang tinggal di suatu wilayah administratif berdasarkan Desa/Kelurahan, Kecamatan, Kota/Kabupaten atau Provinisi",
            desc_eng:
              "Population is the number of people living in an administrative area based on Village, Region, City or Province",
          },
          {
            ina: "Jumlah Kepala Keluarga (orang)",
            eng: "Number of Household Heads (people)",
            value: JML_KK,
            desc_ina:
              "Jumlah Kepala Keluarga merupakan jumlah penduduk yang tinggal di suatu daerah dibagi berdasarkan jumlah kepala keluarga",
            desc_eng:
              "The Number of Household Heads is the number of people living in an area based on the number of heads of families",
          },
          {
            ina: "Kepadatan Penduduk (orang/km²)",
            eng: "People Density (people/km²)",
            value: KPDTN_PEN,
            desc_ina:
              "Kepadatan penduduk adalah banyaknya penduduk per satuan luas ",
            desc_eng:
              "Population Density is the number of people per unit area",
          },
          {
            ina: "Perpindahan Penduduk (orang)",
            eng: "Migration",
            value: PRPND_PEN,
            desc_ina:
              "Perpindahan Penduduk merupakan aktivitas migrasi dari satu tempat ke tempat lainnya dengan melewati batas administratif atau batas politik/negara dengan tujuan untuk menetap di tempat baru tersebut",
            desc_eng:
              "Population Movements is a migration activity from one place to another by crossing administrative or political/state boundaries with the aim of settling in the new place",
          },
          {
            ina: "Perubahan Data Jumlah Penduduk (orang)",
            eng: "Total Change in Population Data (people)",
            value: PRBHN_DATA,
            desc_ina:
              "Perubahan Data Jumlah Penduduk adalah perubahan atau pertumbuhan jumlah penduduk dari waktu ke waktu yang diakibatkan oleh adanya peristiwa kelahiran, kematian atau perpindahan penduduk",
            desc_eng:
              "Changes in Population Data are changes or population growth from time to time caused by births, deaths or population movements",
          },
          {
            ina: "Jumlah Usia Wajib KTP (orang)",
            eng: "Number of Residents at the Required Age of KTP (people)",
            value: WAJIB_KTP,
            desc_ina:
              "Jumlah Usia Wajib KTP merupakan jumlah penduduk di atas 17 tahun atau penduduk yang sudah menikah dan diwajibkan untuk memiiki KTP",
            desc_eng:
              "The Number of Mandatory ID cards is the number of residents over 17 years old or people who are married and are required to have an ID card",
          },
        ],
        pie_chart: [
          {
            title: "Status Perkawinan",
            title_eng: "Marital Status",
            labels: ["Belum kawin", "Telah kawin", "Cerai hidup", "Cerai mati"],
            labels_eng: ["Single", "Married", "Divorced", "Widowed"],
            data: [BLM_KAWIN, KAWIN, CERAI_HDP, CERAI_MT],
            backgroundColor: [
              "#bde38fff",
              "#fc9685ff",
              "#79d9f3ff",
              "#f5b7dcff",
              "#cad0d7ff",
              "#fdcd56ff",
              "#4a88daff",
              "#535b68ff",
              "#31a98dff",
              "#dda579ff",
              "#9579daff",
              "#d73a49ff",
              "#81a140ff",
              "#f9f353ff",
              "#0ca4acff",
              "#6b59f8ff",
            ],
          },
          {
            title: "Agama",
            title_eng: "Religion",
            labels: [
              "Islam",
              "Kristen",
              "Katholik",
              "Hindu",
              "Budha",
              "Konghucu",
              "Kepercayaan",
            ],
            labels_eng: [
              "Islam",
              "Christian",
              "Catholic",
              "Hindu",
              "Buddhist",
              "Confucian",
              "Religion of belief",
            ],
            data: [
              ISLAM,
              KRISTEN,
              KATHOLIK,
              HINDU,
              BUDHA,
              KONGHUCU,
              KPERCAYAAN,
            ],
            backgroundColor: [
              "#bde38fff",
              "#fc9685ff",
              "#79d9f3ff",
              "#f5b7dcff",
              "#cad0d7ff",
              "#fdcd56ff",
              "#4a88daff",
              "#535b68ff",
              "#31a98dff",
              "#dda579ff",
              "#9579daff",
              "#d73a49ff",
              "#81a140ff",
              "#f9f353ff",
              "#0ca4acff",
              "#6b59f8ff",
            ],
          },

          // {
          //   title: "Perpindahan",
          //   title_eng: "Migration",
          //   labels: ["Pindah", "Datang", "Lahir", "Meninggal"],
          //   labels_eng: [
          //     "Residents out",
          //     "Residents enter",
          //     "Number of Births",
          //     "Number of Deaths",
          //   ],
          //   data: [JML_PINDAH, JML_DATANG, JML_LAHIR, MENINGGAL],
          //   backgroundColor: [
          //     "#bde38fff",
          //     "#fc9685ff",
          //     "#79d9f3ff",
          //     "#f5b7dcff",
          //     "#cad0d7ff",
          //     "#fdcd56ff",
          //     "#4a88daff",
          //     "#535b68ff",
          //     "#31a98dff",
          //     "#dda579ff",
          //     "#9579daff",
          //     "#d73a49ff",
          //     "#81a140ff",
          //     "#f9f353ff",
          //     "#0ca4acff",
          //     "#6b59f8ff",
          //   ],
          // },
          {
            title: "Pekerjaan",
            title_eng: "Job",
            labels: [
              "Tidak Bekerja",
              "PNS",
              "Tenaga Pengajar",
              "Wiraswasta",
              "Tani/Ternak",
              "Nelayan",
              "Pemuka Agama",
              "Pelajar",
              "Tenaga Kesehatan",
              "Pensiunan",
              "Lainnya",
            ],
            labels_eng: [
              "No Employment",
              "Civil Servant",
              "Teachers",
              "Entrepreneurs",
              "Farmers",
              "Fishermans",
              "Religious Leaders",
              "Students",
              "Health Workers",
              "Retirees",
              "Other",
            ],
            data: [
              TDK_BKRJ,
              PNS,
              TNG_PNGJR,
              WIRASWASTA,
              TANI_TRNK,
              NELAYAN,
              PMK_AGM,
              SISWA_MHS,
              TNG_KSHTN,
              PENSIUNAN,
              LAINNYA,
            ],
            backgroundColor: [
              "#bde38fff",
              "#fc9685ff",
              "#79d9f3ff",
              "#f5b7dcff",
              "#cad0d7ff",
              "#fdcd56ff",
              "#4a88daff",
              "#535b68ff",
              "#31a98dff",
              "#dda579ff",
              "#9579daff",
              "#d73a49ff",
              "#81a140ff",
              "#f9f353ff",
              "#0ca4acff",
              "#6b59f8ff",
            ],
          },
          {
            title: "Pendidikan",
            title_eng: "Education",
            labels: [
              "Belum Sekolah",
              "Tidak SD",
              "Tamat SD",
              "SMP",
              "SMA",
              "D1-D2",
              "D3",
              "D4/S1",
              "S2",
              "S3",
            ],
            labels_eng: [
              "Preschoolers",
              "Do not school",
              "Elementary",
              "Junior High",
              "Senior High",
              "Diploma (D1-D2)",
              "Associate (D3)",
              "Undergraduate (D4/S1)",
              "Master",
              "Doctor",
            ],
            data: [
              BLM_SKLH,
              TDK_SD,
              TAMAT_SD,
              SMP,
              SMA,
              D1_D2,
              D3,
              D4_S1,
              S2,
              S3,
            ],
            backgroundColor: [
              "#bde38fff",
              "#fc9685ff",
              "#79d9f3ff",
              "#f5b7dcff",
              "#cad0d7ff",
              "#fdcd56ff",
              "#4a88daff",
              "#535b68ff",
              "#31a98dff",
              "#dda579ff",
              "#9579daff",
              "#d73a49ff",
              "#81a140ff",
              "#f9f353ff",
              "#0ca4acff",
              "#6b59f8ff",
            ],
          },
          {
            title: "Umur Pendidikan",
            title_eng: "Educational Age",
            labels: ["3-5", "5", "6-11", "12-14", "15-17", "18-22"],
            labels_eng: ["3-5", "5", "6-11", "12-14", "15-17", "18-22"],
            data: [
              PEND_3_5,
              PEND_5,
              PEND_6_11,
              PEND_12_14,
              PEND_15_17,
              Pend_18_22,
            ],
            backgroundColor: [
              "#bde38fff",
              "#fc9685ff",
              "#79d9f3ff",
              "#f5b7dcff",
              "#cad0d7ff",
              "#fdcd56ff",
              "#4a88daff",
              "#535b68ff",
              "#31a98dff",
              "#dda579ff",
              "#9579daff",
              "#d73a49ff",
              "#81a140ff",
              "#f9f353ff",
              "#0ca4acff",
              "#6b59f8ff",
            ],
          },

          {
            title: "Jenis Kelamin",
            title_eng: "Gender",
            labels: ["Pria", "Wanita"],
            labels_eng: ["Man", "Woman"],
            data: [PRIA, WANITA],
            backgroundColor: [
              "#bde38fff",
              "#fc9685ff",
              "#79d9f3ff",
              "#f5b7dcff",
              "#cad0d7ff",
              "#fdcd56ff",
              "#4a88daff",
              "#535b68ff",
              "#31a98dff",
              "#dda579ff",
              "#9579daff",
              "#d73a49ff",
              "#81a140ff",
              "#f9f353ff",
              "#0ca4acff",
              "#6b59f8ff",
            ],
          },

          // {
          //   title: "Golongan darah",
          //   title_eng: "Blood type",
          //   labels: [
          //     "A",
          //     "B",
          //     "AB",
          //     "O",

          //     // "A+",
          //     // "A-",
          //     // "B+",
          //     // "B-",
          //     // "AB+",
          //     // "AB-",
          //     // "O+",
          //     // "O-",
          //     // "Tidak diketahui",
          //   ],
          //   labels_eng: [
          //     "A",
          //     "B",
          //     "AB",
          //     "O",

          //     // "A+",
          //     // "A-",
          //     // "B+",
          //     // "B-",
          //     // "AB+",
          //     // "AB-",
          //     // "O+",
          //     // "O-",
          //     // "Not known",
          //   ],
          //   data: [
          //     gol_dar_a,
          //     gol_dar_b,
          //     gol_dar_ab,
          //     gol_dar_o,
          //     // gol_dar_a_plus,
          //     // gol_dar_a_minus,
          //     // gol_dar_b_plus,
          //     // gol_dar_b_minus,
          //     // gol_dar_ab_plus,
          //     // gol_dar_ab_minus,
          //     // gol_dar_o_plus,
          //     // gol_dar_o_minus,
          //     // gol_dar_undefined,
          //   ],
          //   backgroundColor: [
          //     "#bde38fff",
          //     "#fc9685ff",
          //     "#79d9f3ff",
          //     "#f5b7dcff",
          //     "#cad0d7ff",
          //     "#fdcd56ff",
          //     "#4a88daff",
          //     "#535b68ff",
          //     "#31a98dff",
          //     "#dda579ff",
          //     "#9579daff",
          //     "#d73a49ff",
          //     "#81a140ff",
          //     "#f9f353ff",
          //     "#0ca4acff",
          //     "#6b59f8ff",
          //   ],
          // },
        ],
        horizontal_bar_chart: [
          {
            title: "Persebaran Usia",
            title_eng: "Age Distribution",
            labels: [
              "<5",
              "5-10",
              "10-15",
              "15-20",
              "20-25",
              "25-30",
              "30-35",
              "35-40",
              "40-45",
              "45-50",
              "50-55",
              "55-60",
              "60-65",
              "65-70",
              "70-75",
              ">75",
            ],
            labels_eng: [
              "<5",
              "5-10",
              "10-15",
              "15-20",
              "20-25",
              "25-30",
              "30-35",
              "35-40",
              "40-45",
              "45-50",
              "50-55",
              "55-60",
              "60-65",
              "65-70",
              "70-75",
              ">75",
            ],
            data: [
              U0,
              U5,
              U10,
              U15,
              U20,
              U25,
              U30,
              U35,
              U40,
              U45,
              U50,
              U55,
              U60,
              U65,
              U70,
              U75,
            ],
            backgroundColor: [
              "#bde38fff",
              "#fc9685ff",
              "#79d9f3ff",
              "#f5b7dcff",
              "#cad0d7ff",
              "#fdcd56ff",
              "#4a88daff",
              "#535b68ff",
              "#31a98dff",
              "#dda579ff",
              "#9579daff",
              "#d73a49ff",
              "#81a140ff",
              "#f9f353ff",
              "#0ca4acff",
              "#6b59f8ff",
            ],
          },
        ],
        bar_chart: [
          // {
          //   title: "Angka Kelahiran (Delta)",
          //   title_eng: "Birthrate (Delta)",
          //   labels: ["2018", "2019", "2020"],
          //   labels_eng: ["2018", "2019", "2020"],
          //   data: [LAHIR_2018, LAHIR_2019, LAHIR_2020],
          //   backgroundColor: [
          //     "#bde38fff",
          //     "#fc9685ff",
          //     "#79d9f3ff",
          //     "#f5b7dcff",
          //     "#cad0d7ff",
          //     "#fdcd56ff",
          //     "#4a88daff",
          //     "#535b68ff",
          //     "#31a98dff",
          //     "#dda579ff",
          //     "#9579daff",
          //     "#d73a49ff",
          //     "#81a140ff",
          //     "#f9f353ff",
          //     "#0ca4acff",
          //     "#6b59f8ff",
          //   ],
          // },
          {
            title: "Populasi (Total)",
            title_eng: "Population (Total)",
            labels: ["2018", "2019", "2020", "2022"],
            labels_eng: ["2018", "2019", "2020", "2022"],
            data: [SBLM_2018, SBLM_2019, SBLM_2020, POPULASI],
            backgroundColor: [
              "#bde38fff",
              "#fc9685ff",
              "#79d9f3ff",
              "#f5b7dcff",
              "#cad0d7ff",
              "#fdcd56ff",
              "#4a88daff",
              "#535b68ff",
              "#31a98dff",
              "#dda579ff",
              "#9579daff",
              "#d73a49ff",
              "#81a140ff",
              "#f9f353ff",
              "#0ca4acff",
              "#6b59f8ff",
            ],
          },
          // {
          //   title: "Angka Pertambahan Penduduk",
          //   title_eng: "Population Growth Rate",
          //   labels: ["2016", "2017", "2018"],
          //   labels_eng: ["2016", "2017", "2018"],
          //   data: [PRTHMBN16, PRTHMBN17, PRTMBHN18],
          //   backgroundColor: [
          //     "#bde38fff",
          //     "#fc9685ff",
          //     "#79d9f3ff",
          //     "#f5b7dcff",
          //     "#cad0d7ff",
          //     "#fdcd56ff",
          //     "#4a88daff",
          //     "#535b68ff",
          //     "#31a98dff",
          //     "#dda579ff",
          //     "#9579daff",
          //     "#d73a49ff",
          //     "#81a140ff",
          //     "#f9f353ff",
          //     "#0ca4acff",
          //     "#6b59f8ff",
          //   ],
          // },
        ],
      };

      const { default_data, pie_chart, horizontal_bar_chart, bar_chart } =
        properties;
      const pie_chart_clone = pie_chart.slice();
      const pie_data = pie_chart_clone.map((e) => {
        const { title, title_eng, labels, labels_eng, data, backgroundColor } =
          e;
        let title_final = language === "ina" ? title : title_eng;
        let data_clone = data.slice();
        const labels_final = language === "ina" ? labels : labels_eng;
        const data_length = data.length;
        const backgroundColor_clone = backgroundColor.slice(0, data_length);
        return {
          labels: labels_final,
          datasets: [
            {
              label: title_final,
              data: data_clone,
              backgroundColor: backgroundColor_clone,
              hoverOffset: 4,
            },
          ],
        };
      });
      const pie_content = (
        <main className="sini_pie_chart">
          {pie_data.map((pie, idx) => {
            return (
              <main
                key={idx}
                style={{
                  width: "150px",
                  textAlign: "center",
                  verticalAlign: "top",
                  marginTop: "20px",
                }}
              >
                <section
                  style={{
                    width: "80px",
                    margin: "auto",
                  }}
                >
                  <ChartTemplate
                    type="pie"
                    width={100}
                    height={100}
                    options={{
                      animation: false,
                      maintainAspectRatio: true,
                      responsive: true,
                      legend: {
                        display: false,
                      },
                      title: {
                        display: false,
                      },
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                    data={pie}
                  />
                </section>
                <section
                  className="text_bold margin_bottom"
                  style={{
                    minHeight: "40px",
                  }}
                >
                  {pie.datasets[0].label}
                </section>
                <section style={{ textAlign: "left", marginTop: "10px" }}>
                  {pie.datasets[0].backgroundColor.map((color, idx_color) => {
                    return (
                      <main
                        key={idx_color}
                        className="two_container_list"
                        style={{ marginTop: "5px" }}
                      >
                        <section
                          style={{
                            display: "inline-block",
                            width: "20px",
                            height: "20px",
                            backgroundColor: color,
                          }}
                        />
                        <section
                          className="text_inferior"
                          style={{
                            display: "inline-block",
                          }}
                        >
                          {pie.labels[idx_color]} (
                          <b style={{ color: "#000" }}>
                            {pie.datasets[0].data[idx_color]}
                          </b>
                          )
                        </section>
                      </main>
                    );
                  })}
                </section>
              </main>
            );
          })}
        </main>
      );
      const horizontal_bar_chart_clone = horizontal_bar_chart.slice();
      const horizontal_bar_data = horizontal_bar_chart_clone.map((e) => {
        const { title, title_eng, labels, labels_eng, data, backgroundColor } =
          e;
        let title_final = language === "ina" ? title : title_eng;
        const labels_clone = labels.slice();
        const labels_eng_clone = labels_eng.slice();
        let labels_final = language === "ina" ? labels_clone : labels_eng_clone;
        const data_clone = data.slice();
        const data_length = data.length;
        const backgroundColor_clone = backgroundColor.slice(0, data_length);
        return {
          labels: e.labels,
          datasets: [
            {
              label: title_final,
              labels: labels_final,
              data: data_clone,
              backgroundColor: backgroundColor_clone,
              hoverOffset: 4,
            },
          ],
        };
      });
      const horizontal_bar_content = (
        <main>
          {horizontal_bar_data.map((data_element, idx) => {
            return (
              <main key={idx} style={{ textAlign: "center" }}>
                <section className="text_inferior">
                  {data_element.datasets[0].label}
                </section>
                <section>
                  <ChartTemplate
                    type="bar"
                    width={310}
                    height={500}
                    options={{
                      animation: false,
                      maintainAspectRatio: false,
                      responsive: false,
                      legend: {
                        display: false,
                      },
                      indexAxis: "y",
                      title: {
                        display: false,
                      },
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                    data={data_element}
                  />
                </section>
              </main>
            );
          })}
        </main>
      );
      const bar_chart_clone = bar_chart.slice();
      const bar_chart_data = bar_chart_clone.map((e) => {
        const {
          title,
          title_eng,
          labels,
          labels_eng,
          data,
          // backgroundColor,
        } = e;
        let title_final = language === "ina" ? title : title_eng;
        const labels_clone = labels.slice();
        const labels_eng_clone = labels_eng.slice();
        let labels_final = language === "ina" ? labels_clone : labels_eng_clone;
        const data_clone = data.slice();
        // const data_length = data.length;
        // const backgroundColor_clone = backgroundColor.slice(0, data_length);
        return {
          labels: e.labels,
          datasets: [
            {
              label: title_final,
              labels: labels_final,
              data: data_clone,
              backgroundColor: "#31a98dff",
              hoverOffset: 4,
            },
          ],
        };
      });
      const bar_chart_content = (
        <main>
          {bar_chart_data.map((data_element, idx) => {
            return (
              <main
                key={idx}
                style={{
                  textAlign: "center",
                  marginBottom: "30px",

                  width: "310px",
                  display: "inline-block",

                  verticalAlign: "top",
                  marginTop: "20px",
                }}
              >
                <section className="text_inferior">
                  {data_element.datasets[0].label}
                </section>
                <section>
                  <ChartTemplate
                    type="bar"
                    width={80}
                    height={60}
                    options={{
                      animation: false,
                      maintainAspectRatio: true,
                      responsive: true,
                      legend: {
                        display: false,
                      },
                      title: {
                        display: false,
                      },
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                    data={data_element}
                  />
                </section>
              </main>
            );
          })}
        </main>
      );

      const content = (
        <section className="sini_card">
          <div className="sini_card_top">
            <div style={{ zIndex: "5" }}>
              <h2>{dict?.["Demographics"]?.[language]}</h2>
              <img
                src={
                  this.props.layer.sini_mode.find((d) => d === "demografi")
                    ? icon_view_blue
                    : icon_view_grey
                }
                style={{ marginRight: "5px", cursor: "pointer" }}
                alt="Add "
                width={25}
                onClick={() => this.props.sini_mode("demografi")}
                title={
                  this.props.layer.sini_mode.find((d) => d === "demografi")
                    ? dict?.["Hide"]?.[language]
                    : dict?.["View"]?.[language]
                }
              />
              <img
                src={icon_download}
                alt="Add "
                width={25}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  this.props.handleDownloadSini(request_id, "demografi")
                }
                title="Download Data"
              />
            </div>
            <IconOpen
              onClick={() => this.setState({ hide: !hide })}
              hide={hide}
            />
          </div>
          <main className={hide ? "sini_scroll" : "sini_scroll_hide"}>
            <section>
              {default_data.map((e, idx) => {
                const { value } = e;
                return (
                  <main key={idx}>
                    <div className="text_inferior">{e[language]}</div>
                    <div className="text_bold" style={{ marginBottom: "10px" }}>
                      {isNaN(value)
                        ? value
                        : Intl.NumberFormat("id-ID", {
                            style: "decimal",
                          }).format(value)}
                    </div>
                  </main>
                );
              })}
            </section>
            <section style={{ marginTop: "20px" }}>{pie_content}</section>
            <section style={{ marginTop: "20px" }}>
              {horizontal_bar_content}
            </section>
            <section style={{ marginTop: "20px" }}>{bar_chart_content}</section>
            <section className="text_inferior">
              Sumber data: Data Kependudukan Kementerian Dalam Negeri - Dukcapil
              <br />
              Update terbaru 30 Juni 2022
            </section>
          </main>
        </section>
      );

      return content;
    } else {
      const kelurahan = sini_list?.filter(
        (e) => e.request_id === request_id
      )?.[0]?.kelurahan;

      const DESA = kelurahan?.features?.[0]?.properties?.DESA;
      const KECAMATAN = kelurahan?.features?.[0]?.properties?.KECAMATAN;
      const KABKOT = kelurahan?.features?.[0]?.properties?.KABKOT;
      const PROVINSI = kelurahan?.features?.[0]?.properties?.PROVINSI;

      let no_content = (
        <section className="sini_card">
          <div className="sini_card_top">
            <div style={{ zIndex: "5" }}>
              <h2>{dict?.["Demographics"]?.[language]}</h2>
              <p>
                {
                  dict?.[
                    "There is no data at the point you selected, we will complete it soon."
                  ]?.[language]
                }
              </p>
              <br />
              <p className="text_inferior">Address</p>
              <p className="text_bold">
                {DESA}, {KECAMATAN}, {KABKOT}, {PROVINSI}
              </p>
            </div>
          </div>
        </section>
      );

      return no_content;
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
});

export default connect(mapStateToProps, {})(DemografiSini);
