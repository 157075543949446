//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

//Personal Component
import SpinnerSimpleBlue from "../common_spinner/SpinnerSimpleBlue";
//Redux function
import { pushUploadedPicture } from "../../App/actions/authActions";
//Picture Asset
import icon_plus from "../../Assets/svg/icon_plus.svg";
//General Function
import uuid from "../../App/validation/uuid";
import dict from "../../Data/dict.json";
class UploadPicture extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fileInput = React.createRef();
  }
  handleSubmit(e) {
    e.preventDefault();
    const { name } = this.props.auth.user;
    const file = this.fileInput.current.files[0];
    const date_now = moment(Date.now()).format("X");
    const objParams = {
      file: file,
      file_name: "doc_" + date_now + "_" + uuid(),
      dirName: "foto_doc/" + name,
      file_name_origin: file.name,
    };
    let finalObject;
    finalObject = {
      objParams,
    };
    this.props.pushUploadedPicture(finalObject);
  }
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    let itemContent;
    const { itemLoading, loadingProcess } = this.props.auth;
    if (loadingProcess && itemLoading === "uploaded_picture") {
      itemContent = (
        <div className="upload_container vertical_center">
          <div
            className="vertical_horizontal_center_child"
            style={{ fontSize: "20px" }}
          >
            <SpinnerSimpleBlue
              width={40}
              unik="loading_item_list"
              marginTop="0px"
              center={true}
              border={0.1}
            />
          </div>
        </div>
      );
    } else {
      itemContent = (
        <label
          className="fileContainer upload_container vertical_center"
          style={{
            backgroundImage: `url(${icon_plus})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div
            className="vertical_horizontal_center_child"
            style={{ cursor: "pointer", fontSize: "20px", width: "100%" }}
          >
            <input
              style={{ cursor: "pointer" }}
              type="file"
              accept="image/*"
              ref={this.fileInput}
              onChange={this.handleSubmit}
            />
          </div>
        </label>
      );
    }

    return (
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        {itemContent}
        <div>{dict["Upload image"][language]}</div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {
  pushUploadedPicture,
})(UploadPicture);
