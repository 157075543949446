import React, { Component } from "react";
import ParentTableEditor from "../editor_table/ParentTableEditor";
import Modal from "../common_modal/Modal";
import { connect } from "react-redux";
import { set_value } from "../../App/actions/dataActions";
import {
  getChildrenFeaturesByColumnParentUuidAndRowParentUuid,
  getFeatureByKey,
  getGeoLayerById,
} from "../../App/reduxHelper/layer";

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_table: false,
    };
  }

  toggle_table_close = () => {
    const { is_from_first_gen_table } = this.props;
    if (is_from_first_gen_table) {
      this.props.set_value("selected_parent_table_row_key", "");
    }
    this.setState({ modal_table: false });
  };

  toggle_table = () => {
    const { is_from_first_gen_table, row, column } = this.props;

    this.setState({ modal_table: !this.state.modal_table });

    if (is_from_first_gen_table) {
      this.props.set_value("selected_parent_table_row_key", row?.key);
    }

    const child_features = this.get_children_features(row, column);

    this.props.set_value("field_parent", column);
    this.props.set_value("child_fields_shown", child_features);
  };

  get_children_features = (row, column) => {
    const { is_from_first_gen_table, layer, layer_id } = this.props;

    const { selected_parent_table_row_key } = layer;

    const geo_layer = getGeoLayerById({ layer, layer_id });
    const feature = getFeatureByKey({
      geo_layer,
      feature_key: selected_parent_table_row_key || row?.key,
    });

    let child_features = getChildrenFeaturesByColumnParentUuidAndRowParentUuid({
      feature,
      column_parent_uuid: column?.uuid,
      row_parent_uuid: is_from_first_gen_table ? row?.child_uuid : row?.key,
    });

    return child_features;
  };

  modal_table = () => {
    const { layer_id, row, column, is_from_first_gen_table } = this.props;
    const { selected_parent_table_row_key } = this.props.layer;

    const modal_table_content = this.state.modal_table && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={this.state.modal_table}
        onClose={this.toggle_table_close}
      >
        <div className="box-body">
          <ParentTableEditor
            layer_id={layer_id}
            row={row}
            column={column}
            selected_parent_table_row_key={selected_parent_table_row_key}
            is_from_first_gen_table={is_from_first_gen_table}
          />
        </div>
      </Modal>
    );

    return modal_table_content;
  };

  render() {
    return (
      <section>
        <button className="button" onClick={() => this.toggle_table()}>
          Buka tabel
        </button>
        {this.modal_table()}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
});

export default connect(mapStateToProps, {
  set_value,
})(Table);
