//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Personal Component
import Map from "../Components/play/Map";
import FieldsViewer from "../Components/play/FieldsViewer";
import Modal from "../Components/common_modal/Modal";

//Redux function
import { get_play_public, get_play_private } from "../App/actions/playActions";

//Picture Asset
// import icon_play from "../../img/icon_play.png";

import icon_home from "../Assets/img/icon_home.png";
//General Function

class PlayViewer extends Component {
  state = {
    screen: "start",
    /*
    start
    play
    finish
    */
    mode: "mobile",
    /*
    mobile
    desktop
    mobile_on_desktop
    */
    index: 0,
    feature: {},
    modal_start: false,
    modal_finish: false,
    mode_url: "public",
  };

  componentDidMount() {
    const str = this?.props?.location?.search;
    const params = Object.fromEntries(new URLSearchParams(str));
    let mode = params?.mode;
    if (mode === "private") {
      this.props.get_play_private(this?.props?.match?.params?.link);
      this.setState({ mode_url: "private" });
    } else {
      this.props.get_play_public(this?.props?.match?.params?.link);
      this.setState({ mode_url: "public" });
    }
    setTimeout(
      function () {
        this.toggle_start();
      }.bind(this),
      500
    );
  }

  toggle_start = () => {
    this.setState({ modal_start: !this.state.modal_start });
  };
  toggle_finish = () => {
    this.setState({ modal_finish: !this.state.modal_finish });
  };
  on_play = () => {
    const { mode_url } = this.state;
    this.setState({ modal_start: false, modal_finish: false });
    let { play_public, play_private } = this.props.play;
    if (mode_url === "private") {
      play_public = play_private;
    }
    let features = play_public?.slide_list?.[0]?.layer?.geojson?.features
      ? play_public?.slide_list?.[0]?.layer?.geojson?.features.slice()
      : [];
    features.push("end");
    const delay_second = play_public?.delay;
    const delay = () =>
      new Promise((res) => setTimeout(res, delay_second * 1000));
    const func = (e, idx) => {
      if (idx === 0) {
        this.setState({ screen: "play", index: idx, feature: e });
      } else if (idx > 0 && idx === features.length - 1) {
        // this.setState({ screen: "finish", modal_finish: true });
        this.setState({ screen: "finish" });
      } else {
        this.setState({ index: idx, feature: e });
      }
    };
    const parent_function = () => {
      return features.reduce(
        (last_prom, e, idx) =>
          last_prom.then((result_sum) =>
            child_function(e, idx).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (e, idx) => {
      if (idx === 0) {
        return func(e, idx);
      } else {
        return delay().then(() => {
          return func(e, idx);
        });
      }
    };
    parent_function();
  };
  render() {
    const width = window.innerWidth;
    const {
      screen,
      feature,
      index,
      mode,
      modal_start,
      modal_finish,
      mode_url,
    } = this.state;
    let { play_public, play_private } = this.props.play;
    if (mode_url === "private") {
      play_public = play_private;
    }
    const url_compressed = play_public?.user?.profile_picture?.url_compressed;
    const full_name = play_public?.user?.full_name;
    const title = play_public?.title;
    const caption = play_public?.caption;
    const features = !!play_public?.slide_list?.[0]?.layer?.geojson?.features
      ? play_public?.slide_list?.[0]?.layer?.geojson?.features
      : [];
    let fields = !!play_public?.slide_list?.[0]?.layer?.fields
      ? play_public?.slide_list?.[0]?.layer?.fields
      : [];
    fields = fields.filter((f) => !f.isHide);
    let text_container;
    const total_index = features.length;
    const percentage = parseInt(((index + 1) / total_index) * 100);
    const percentage_content = (
      <main
        style={{
          height: "10px",
          backgroundColor: "#dedede",
          borderRadius: "2rem",
          padding: "3px",
          margin: "auto",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            height: "100%",
            width: `${percentage}%`,
            backgroundColor: "#7cc735ff",
            borderRadius: "2rem",
          }}
        />
      </main>
    );
    // //generate default field (smart mode)
    // let image_field, title_field, desc_field_1, desc_field_2;
    // if (fields.length > 0) {
    //   //gambar
    //   image_field = fields.filter((f) => f.type === "image")?.[0]?.key;
    //   //teks pertama
    //   title_field = fields.filter((f) => f.type === "text")?.[0]?.key;
    //   desc_field_1 = fields.filter(
    //     (f) =>
    //       (f.type === "text" || f.type === "textarea") &
    //       (f?.key !== title_field)
    //   )?.[0]?.key;
    //   desc_field_2 = fields.filter(
    //     (f) =>
    //       (f.type === "text" || f.type === "textarea") &
    //         (f?.key !== title_field) && f?.key !== desc_field_1
    //   )?.[0]?.key;
    // }
    let button_play;
    if (features.length > 0) {
      button_play = (
        <main>
          {/* <button onClick={this.on_play}>
            <img
              alt="Play MAPID"
              src={icon_play}
              style={{ width: "80px", maxWidth: "80vw", marginBottom: "20px" }}
            />
          </button>
          <br /> */}
          <button onClick={this.on_play} className="button_standard use_margin">
            Play
          </button>
        </main>
      );
    }
    let screen_content;
    if (screen === "start") {
      screen_content = (
        <main className="text_center">
          <div
            className="pp_img"
            style={{
              backgroundImage: `url(${url_compressed})`,
            }}
          />
          <p className="text_medium use_margin">{full_name}</p>
          <h1 className="text_bold play_title">{title}</h1>
          <div>
            {button_play}
            <p>Klik Play untuk memulai</p>
          </div>
          <p className="text_medium use_margin">{caption}</p>
        </main>
      );
    } else if (screen === "play") {
      // const other_field = fields.filter(
      //   (f) =>
      //     ![image_field, title_field, desc_field_1, desc_field_2].includes(
      //       f.key
      //     )
      // );
      screen_content = (
        <main className="text_center">
          <p
            className="badge_pill"
            style={{ margin: "auto", marginBottom: "10px" }}
          >
            {index + 1}
          </p>
          {percentage_content}
          {/* {image_field && (
            <main className="play_parent_image">
              <section
                className="play_container_image"
                style={{
                  backgroundImage: `url(${feature?.properties?.[image_field]})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              />
            </main>
          )} */}
          {/* {title_field && (
            <h1 className="text_bold play_title">
              {feature?.properties?.[title_field]}
            </h1>
          )}
          {desc_field_1 && (
            <p className="text_medium use_margin">
              {feature?.properties?.[desc_field_1]}
            </p>
          )}
          {desc_field_2 && (
            <p className="text_medium use_margin">
              {feature?.properties?.[desc_field_2]}
            </p>
          )} */}
          <FieldsViewer fields={fields} properties={feature?.properties} />
        </main>
      );
    } else if (screen === "finish") {
      screen_content = (
        <main className="text_center">
          <Link to="/dashboard" className="button_square_image">
            <img alt="home" src={icon_home} style={{ width: "100%" }} />
          </Link>
          <section style={{ marginBottom: "100px" }}>
            <h1 className="text_bold use_margin">Buat peta kamu sendiri!</h1>
            <Link to="/dashboard?modal=play" className="button_standard">
              Buat sekarang
            </Link>
          </section>
          <section>
            <h1 className="text_bold play_title">{title}</h1>
            <div>
              {button_play}
              <p>Klik Play untuk memulai lagi dari awal</p>
            </div>
          </section>
          <p className="text_medium use_margin">{caption}</p>
        </main>
      );
    }
    if (width <= 1150) {
      text_container = <main className="play_text">{screen_content}</main>;
    } else {
      text_container = (
        <main className="play_text_desktop">
          <h1 className="text_bold play_title">{title}</h1>
          {screen_content}
        </main>
      );
    }
    const modal_start_content = modal_start && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_start}
        onClose={this.toggle_start}
      >
        <div className="box-body">{screen_content}</div>
      </Modal>
    );
    const modal_finish_content = modal_finish && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_finish}
        onClose={this.toggle_finish}
      >
        <div className="box-body">{screen_content}</div>
      </Modal>
    );
    return (
      <main>
        <Map
          feature={feature}
          index={index}
          screen={screen}
          mode={mode}
          play_final={play_public}
        />
        {modal_finish_content}
        {modal_start_content}
        {text_container}
        <style>
          {`
          body{
            padding-top: 0px;
            }
          #navbarDefault{ visibility: hidden;}
          .top_nav{visibility: hidden;}
          .sidebar_home{ visibility: hidden;}
          .nav_bottom{ visibility: hidden;}`}
        </style>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({ play: state.play });

export default connect(mapStateToProps, { get_play_public, get_play_private })(
  PlayViewer
);
