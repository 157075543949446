import axios from "axios";
import imageCompression from "browser-image-compression";
import { geoServerBaseUrl } from "./baseUrl";
import history from "./history";

const SERVER_URL = geoServerBaseUrl;

/*NON-API*/
export const set_loading = (itemLoading) => {
  return {
    type: "set_loading",
    payload: itemLoading,
  };
};

export const clear_loading = () => {
  return {
    type: "clear_loading",
  };
};

/*POST*/
//1. Create empty
export const create_empty = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("create_empty"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    const res = await axios.post(
      SERVER_URL + "/plays/create_empty",
      body,
      config
    );
    dispatch(clear_loading());
    dispatch({
      type: "create_empty",
      payload: res.data,
    });
    history.push(`/play_editor/${res?.data?._id}`);
  } catch (e) {
    dispatch(clear_loading());
  }
};

//2. Push feature
export const push_feature = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("push_feature"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    await axios.post(SERVER_URL + "/layers/push_feature", body, config);
    dispatch({
      type: "push_feature_play",
      payload: body,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//3. Delete feature
export const delete_feature = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("delete_feature"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    await axios.post(SERVER_URL + "/layers/delete_feature", body, config);
    dispatch({
      type: "delete_feature_play",
      payload: body,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//4. Upload image to S3
export const upload_image = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("upload_image"));
    //Original file
    const { objParams, field_key } = body;
    const file = objParams.file;
    //Compressed file big
    const options_compression_big = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 1500,
      useWebWorker: true,
    };
    const config_header_upload = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
        "Content-Type": "multipart/form-data",
      },
    };
    const file_compressed = await imageCompression(
      file,
      options_compression_big
    );
    const file_name = objParams.file_name;
    // form data upload gambar
    const formData = new FormData();
    formData.append("original_file_name", `${file_name}`);
    formData.append("file", file_compressed);
    const result_upload = await axios.post(
      SERVER_URL + "/upload",
      formData,
      config_header_upload
    );
    const url = result_upload.data.data.Location;
    const payload = {
      field_key,
      url,
    };
    dispatch({
      type: "upload_image",
      payload,
    });
    dispatch(clear_loading());
  } catch (error) {
    dispatch(clear_loading());
  }
};

//5. Upload file to S3
export const upload_file = (body) => async (dispatch) => {
  try {
    const config_header_upload = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
        "Content-Type": "multipart/form-data",
      },
    };
    dispatch(set_loading("upload_image"));
    const { objParams, field_key } = body;
    const file = objParams.file;
    const file_name = objParams.file_name;
    //Compressed file big
    const options_compression = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 1500,
      useWebWorker: true,
    };
    const file_compres = await imageCompression(file, options_compression);
    // form data upload gambar
    const formData = new FormData();
    formData.append("original_file_name", `${file_name}`);
    formData.append("file", file_compres);
    const result_upload = await axios.post(
      SERVER_URL + "/upload",
      formData,
      config_header_upload
    );
    const url = result_upload.data.data.location;
    const payload = {
      field_key,
      url,
    };
    dispatch({
      type: "upload_image",
      payload,
    });
    dispatch(clear_loading());
  } catch (error) {
    dispatch(clear_loading());
  }
};

//6. Edit all object proporties in one feature
export const edit_all_properties = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("edit_all_properties"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    await axios.post(SERVER_URL + "/layers/edit_all_properties", body, config);
    dispatch(clear_loading());
    dispatch({
      type: "edit_all_properties",
      payload: body,
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

//7. Edit Layer geojson geometry
export const edit_geometry = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("edit_geometry"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    await axios.post(SERVER_URL + "/layers/edit_geometry", body, config);
    dispatch(clear_loading());
    dispatch({
      type: "edit_geometry",
      payload: body,
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

//8. push_field
export const push_field = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("push_field"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    await axios.post(SERVER_URL + "/layers/push_field", body, config);
    dispatch(clear_loading());
    dispatch({
      type: "push_field_play",
      payload: body,
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

//9. push_field
export const delete_field = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("delete_field"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    await axios.post(SERVER_URL + "/layers/delete_field", body, config);
    dispatch(clear_loading());
    dispatch({
      type: "delete_field_play",
      payload: body,
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

//10. push_field
export const edit_field = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("edit_field"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    await axios.post(SERVER_URL + "/layers/edit_field", body, config);
    dispatch(clear_loading());
    dispatch({
      type: "edit_field_play",
      payload: body,
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

//11. edit_all_fields
export const edit_all_fields = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("edit_all_fields"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    await axios.post(SERVER_URL + "/layers/edit_all_fields", body, config);
    dispatch(clear_loading());
    dispatch({
      type: "edit_all_fields_play",
      payload: body,
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

//2. Cek & save link
export const check_link = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("check_link"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    await axios.post(SERVER_URL + "/plays/check_link", body, config);
    dispatch(clear_loading());
    dispatch({
      type: "check_link",
      payload: body,
    });
  } catch (e) {
    dispatch({
      type: "play_error",
      payload: e?.response?.data,
    });
    dispatch({
      type: "check_link_error",
      payload: e?.response?.data?.link_before,
    });
    dispatch(clear_loading());
  }
};

//3. Edit play
export const edit_play = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("edit_play"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    await axios.post(SERVER_URL + "/plays/edit_play", body, config);
    dispatch({
      type: "edit_play",
      payload: body,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch({
      type: "play_error",
      payload: e?.response?.data,
    });
    dispatch(clear_loading());
  }
};

//4. Publish play
export const publish_play = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("publish_play"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    await axios.post(SERVER_URL + "/plays/publish_play", body, config);
    dispatch({
      type: "publish_play",
      payload: body,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch({
      type: "play_error",
      payload: e?.response?.data,
    });
    dispatch(clear_loading());
  }
};

//5. Delete play
export const delete_play = (body) => async (dispatch) => {
  try {
    dispatch(set_loading("delete_play"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    await axios.post(SERVER_URL + "/plays/delete_play", body, config);
    dispatch({
      type: "delete_play",
      payload: body,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch({
      type: "play_error",
      payload: e?.response?.data,
    });
    dispatch(clear_loading());
  }
};

/*GET*/
//1. Get list private
export const get_list_private = () => async (dispatch) => {
  try {
    dispatch(set_loading("get_list_private"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    const res = await axios.get(SERVER_URL + `/plays/get_list_private`, config);
    dispatch({
      type: "get_list_private_play",
      payload: res.data,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//2. Get list public
export const get_list_public = () => async (dispatch) => {
  try {
    dispatch(set_loading("get_list_public"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    const res = await axios.get(SERVER_URL + `/plays/get_list_public`, config);
    dispatch({
      type: "get_list_public_play",
      payload: res.data,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//3. Get list public by tag
export const get_list_public_by_tag = (tag) => async (dispatch) => {
  try {
    dispatch(set_loading("get_list_public_by_tag"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    const res = await axios.get(
      SERVER_URL + `/plays/get_list_public_by_tag?tag=${tag}`,
      config
    );
    dispatch({
      type: "get_list_public_play",
      payload: res.data,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//4. Get detail private populate layer & project
export const get_play_private = (play_id) => async (dispatch) => {
  try {
    dispatch(set_loading("get_play_private"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    const res = await axios.get(
      SERVER_URL + `/plays/get_play_private?play_id=${play_id}`,
      config
    );
    dispatch({
      type: "get_play_private",
      payload: res.data,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//5. Get detail public populate layer & project
export const get_play_public = (link) => async (dispatch) => {
  try {
    dispatch(set_loading("get_play_public"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    const res = await axios.get(
      SERVER_URL + `/plays/get_play_public?link=${link}`,
      config
    );
    dispatch({
      type: "get_play_public",
      payload: res.data,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//6. Get tag list
export const get_tag_list = () => async (dispatch) => {
  try {
    dispatch(set_loading("get_tag_list"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    const res = await axios.get(SERVER_URL + `/plays/get_tag_list`, config);
    dispatch({
      type: "get_tag_list",
      payload: res.data,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};
