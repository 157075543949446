import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";

const SERVER_URL = geoServerBaseUrl;

/*NON-API*/

export const set_loading = (itemLoading) => {
  return {
    type: "set_loading",
    payload: itemLoading,
  };
};

export const clear_loading = () => {
  return {
    type: "clear_loading",
  };
};

/*POST METHODE*/

export const set_data_premium_bulk = (body) => async (dispatch) => {
  //set_data_premium_bulk
  /*
const body = {
  geo_project_id,
  layer_id_array,
  price
}
*/
  try {
    dispatch(set_loading("set_data_premium_bulk"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };

    await axios.post(
      SERVER_URL + "/moneys/set_data_premium_bulk",
      body,
      config
    );

    dispatch({
      type: "set_data_premium_bulk",
      payload: body,
    });

    dispatch({
      type: "set_project_premium",
      payload: body,
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const undo_data_premium_bulk = (body) => async (dispatch) => {
  //undo_data_premium_bulk
  /*
const body = {
  geo_project_id,
  layer_id_array
}
*/
  try {
    dispatch(set_loading("undo_data_premium_bulk"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };

    await axios.post(
      SERVER_URL + "/moneys/undo_data_premium_bulk",
      body,
      config
    );

    dispatch({
      type: "undo_data_premium_bulk",
      payload: body,
    });

    dispatch({
      type: "undo_project_premium",
      payload: body,
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

/*GET METHODE*/

export const get_data_premium_count = () => async (dispatch) => {
  //get_data_premium_count
  try {
    dispatch(set_loading("get_data_premium_count"));

    const res = await axios.get(SERVER_URL + "/moneys/get_data_premium_count");

    dispatch({
      type: "get_data_premium_count",
      payload: res.data,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

//search_data_premium
export const search_data_premium = (search_params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    dispatch(set_loading("search_layer"));
    const res = await axios.get(
      SERVER_URL + `/moneys/search_data_premium/${search_params}`,
      config
    );

    dispatch(clear_loading());
    dispatch({
      type: "SEARCH_LAYER",
      payload: res.data,
    });
  } catch (error) {
    dispatch(clear_loading());
  }
};

//get_data_premium_by_category_id
export const get_data_premium_by_category_id =
  (category_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          accesstoken: localStorage.jwtTokenMapid,
        },
      };
      dispatch(set_loading("search_layer"));
      const res = await axios.get(
        SERVER_URL + `/moneys/get_data_premium_by_category_id/${category_id}`,
        config
      );

      dispatch(clear_loading());
      dispatch({
        type: "SEARCH_LAYER",
        payload: res.data,
      });
    } catch (error) {
      dispatch(clear_loading());
    }
  };
