//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function
import {
  create_payment,
  checkSpecialist,
  getSpecialistCode,
  clearPayment,
  resetSpecialistCode,
} from "../../App/actions/paymentActions";
import Modal from "../common_modal/Modal";
import PaymentInstruction from "../../Pages/PaymentInstruction";

//Picture Asset

//General Function
import dict from "../../Data/dict.json";

class BuyNow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      month: 1,
      modal_payment: false,
      payment_id: "",
    };
  }

  on_set_month = (month) => {
    this.setState({ month });
  };

  toggle_modal_payment = () => {
    const { modal_payment } = this.state;
    this.setState({
      modal_payment: !modal_payment,
    });
  };

  on_create_payment = async () => {
    const { month } = this.state;
    const license_object = this.props.license_object;
    const body = {
      payment_type: license_object?.key,
      month,
      payment_methode: "midtrans",
    };

    let payment_id = "";
    const current_url = window.location.href;
    if (current_url.includes("user_profile")) {
      payment_id = await this.props.create_payment(body, "user_profile");
    } else {
      payment_id = await this.props.create_payment(body);
    }

    if (payment_id) {
      this.setState(
        {
          payment_id,
        },
        () => {
          this.toggle_modal_payment();
        }
      );
    }
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const { month, modal_payment, payment_id } = this.state;

    //global props
    const license_object = this.props.license_object;
    // const current_url = window.location.href;

    //content

    let modal_payment_content = // !current_url.includes("user_profile") &&
      modal_payment && (
        <Modal
          modalSize="small"
          id="modal"
          isOpen={modal_payment}
          onClose={this.toggle_modal_payment}
        >
          <main className="box-body">
            <PaymentInstruction payment_id={payment_id || ""} />
          </main>
        </Modal>
      );

    let content;

    if (license_object?._id) {
      const prices = license_object?.prices || [];
      const price_object = prices.find((item) => item.month === month);

      let price = Number(price_object?.price);
      const discount = (Number(price_object?.discount_percent) / 100) * price;
      price = price - discount;

      content = (
        <main>
          <h1 className="text_header">{license_object?.name?.[language]}</h1>
          <br />
          {prices.map((item, idx) => {
            return (
              <main
                key={idx}
                className="container_flat container_small"
                onClick={this.on_set_month.bind(this, item.month)}
                id={month === item?.month ? "blue" : "almost_white"}
              >
                <p className="text_inferior">
                  Bundle: {item?.month}{" "}
                  {dict[item?.month === 1 ? "month" : "months"][language]}
                </p>
                <p className="text_inferior">
                  Rp
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(item?.price)}
                </p>
                <br />
                {!!item.discount_percent && (
                  <p className="text_medium">
                    Diskon <b>{item?.discount_percent}%</b>
                  </p>
                )}
              </main>
            );
          })}
          <br />
          <br />
          <hr />
          <br />
          <p className="text_header">
            Rp
            {new Intl.NumberFormat("id-ID", {
              style: "decimal",
            }).format(price)}
          </p>
          <p className="text_inferior">{dict["Exclude VAT"][language]} 11%</p>
          <br />
          <button
            className="button_big"
            id="blue"
            onClick={this.on_create_payment}
          >
            {dict["Pay"][language]}
          </button>
        </main>
      );
    }

    return (
      <main>
        {content}
        {modal_payment_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  payment: state.payment,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  create_payment,
  checkSpecialist,
  getSpecialistCode,
  clearPayment,
  resetSpecialistCode,
})(BuyNow);
