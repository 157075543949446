/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { deleteLayer } from "../../App/actions/layerNewActions";

/*PICTURE ASSET*/

/*GENERAL*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class DeleteLayer extends Component {
  state = {
    geo_layer: {},
  };

  handleSubmit = () => {
    const { geo_layer_wo_geojson } = this.props.layer;
    const { geo_project } = this.props.project;
    this.props.toggle_delete_layer();
    const body = {
      geo_project_id: geo_project._id,
      geo_layer_id: geo_layer_wo_geojson.geo_layer._id,
    };
    this.props.deleteLayer(body);
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { geo_layer_wo_geojson } = this.props.layer;
    const { loadingProcess } = this.props.layer;
    let itemContent;
    let buttonContent;
    if (loadingProcess) {
      buttonContent = (
        <div
          className="button_standard"
          id="deleteBright"
          style={{ marginTop: "1rem" }}
        >
          {dict["Process deletion"][language]}...
        </div>
      );
    } else {
      buttonContent = (
        <button
          className="button_standard"
          id="deleteBright"
          style={{ marginTop: "1rem" }}
          onClick={this.handleSubmit}
        >
          {dict["Process deletion"][language]}
        </button>
      );
    }
    itemContent = (
      <div style={{ textAlign: "center" }}>
        <div className="text_medium">
          {dict["Are you sure to delete this layer?"][language]}
        </div>
        <br />
        <p>
          {
            dict[
              "Layer(s) will be kept in the Recovery place for 30 days before being permanently deleted automatically"
            ][language]
          }
        </p>
        <br />
        <div className="text_bold">{geo_layer_wo_geojson?.geo_layer?.name}</div>
        {buttonContent}
      </div>
    );
    return <div>{itemContent}</div>;
  }
}
const mapStateToProps = (state) => ({
  layer: state.layer,
  auth: state.auth,
  project: state.project,
});
export default connect(mapStateToProps, { deleteLayer })(DeleteLayer);
