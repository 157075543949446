//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
//Personal Component
//Redux function
import { getUserFromToken } from "../../App/actions/authActions";
//Picture Asset
//General Function
class ListPicturesBanner extends Component {
  componentDidMount() {
    const jwtTokenMapid = localStorage.jwtTokenMapid;
    this.props.getUserFromToken(jwtTokenMapid);
  }
  onClickImage = picture_url => {
    this.props.selectPicture(picture_url);
  };
  render() {
    const { user } = this.props.auth;
    const uploaded_pictures = user?.uploaded_pictures
      ? user?.uploaded_pictures
      : [];
    const itemContent = (
      <div>
        {uploaded_pictures.map((picture, idx) => {
          const { url, name } = picture;
          return (
            <div
              key={idx}
              onClick={this.props.handleClickImage.bind(this, url)}
              style={{
                display: "inline-block",
                textAlign: "center",
                marginBottom: "10px",
                width: "90px",
                height: "82px",
                overflow: "hidden",
                marginRight: "5px",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  width: "90px",
                  height: "65px",
                  borderRadius: "5px",
                  backgroundImage: `url(${url})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              />
              <div className="text_inferior">{name}</div>
            </div>
          );
        })}
      </div>
    );
    return (
      <main
        style={{
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        {itemContent}
      </main>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { getUserFromToken })(
  ListPicturesBanner
);
