import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import dict from "../../Data/dict.json";
import { IconOpen } from "./icon";

class Publication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hide: false,
    };
  }

  render() {
    const { hide } = this.state;
    const { sini_list, request_id } = this.props.layer;
    const language = localStorage?.language ? localStorage?.language : "ina";

    let blog_content;
    const blog_list = sini_list?.filter((e) => e.request_id === request_id)[0]
      .blog_list
      ? sini_list?.filter((e) => e.request_id === request_id)[0].blog_list
      : [];

    blog_content = (
      <main className="sini_publikasi">
        {blog_list?.map((blog, idx) => {
          const { title, link, authors } = blog;
          const picture_url = blog?.picture_url ? blog?.picture_url : "";
          const full_name = authors[0]?.user?.full_name;
          const url_img = String(picture_url).replace(/\s/g, "%20");
          let text_status = "Public";
          return (
            <figure key={idx} className="card_sini">
              <figcaption
                className="card_sini_left"
                style={{
                  backgroundImage: `url(${url_img})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                {/* <img src={url_img} alt={url_img} /> */}
                <span>{text_status}</span>
              </figcaption>
              <figcaption className="card_sini_right">
                <div>
                  <h1>{title}</h1>
                </div>
                <div>
                  <p className="creator_blog_sini">{full_name}</p>
                  <Link
                    className="button_blog_sini"
                    to={`/blog_read/${link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {dict?.["Open"]?.[language]}
                  </Link>
                </div>
              </figcaption>
            </figure>
          );
        })}
      </main>
    );

    const content = (
      <section className="sini_card">
        <div className="sini_card_top">
          <div style={{ zIndex: "5" }}>
            <h2>{dict?.["Publication"]?.[language]}</h2>
          </div>
          <IconOpen
            onClick={() => this.setState({ hide: !hide })}
            hide={hide}
          />
        </div>
        <main className={hide ? "sini_scroll" : "sini_scroll_hide"}>
          {blog_content}
        </main>
      </section>
    );

    const no_content = (
      <section className="sini_card">
        <div className="sini_card_top">
          <div style={{ zIndex: "5" }}>
            <h2>{dict?.["Publication"]?.[language]}</h2>
            <p>
              {
                dict?.[
                  "There is no data at the point you selected, we will complete it soon."
                ]?.[language]
              }
            </p>
          </div>
        </div>
      </section>
    );

    return blog_list?.length === 0 ? no_content : content;
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Publication);
