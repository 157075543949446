//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
//Personal Component
//Redux function
import { actionRequestFreeImport } from "../../App/actions/blogAction";
//Picture Asset
//General Function
import dict from "../../Data/dict.json";
class RequestAction extends Component {
  state = {
    request_id_array: [],
  };
  handleAction = (request_id, status, buyer_id) => {
    const { blog_personal } = this.props.blog;
    const { request_id_array } = this.state;
    request_id_array.push(request_id);
    this.setState({ request_id_array }, () => {
      const body = {
        blog_id: blog_personal._id,
        request_id,
        status,
        seller_message: "",
        buyer_id,
      };
      this.props.actionRequestFreeImport(body);
    });
  };
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { request_id_array } = this.state;
    const { blog_personal, loadingProcess, itemLoading } = this.props.blog;
    const request_list = blog_personal?.request_list
      ? blog_personal?.request_list
      : [];
    return (
      <div>
        {request_list.map((request, idx) => {
          const profile_picture = request?.user?.profile_picture?.url_compressed
            ? request?.user?.profile_picture?.url_compressed
            : request?.user?.profile_picture?.url;
          const full_name = request?.user?.full_name
            ? request?.user?.full_name
            : request?.user?.name;
          const { _id, user, status } = request;
          const user_id = user._id;
          let button_content = (
            <section>
              <button
                onClick={this.handleAction.bind(
                  this,
                  _id,
                  "free_accepted",
                  user_id
                )}
                className="button_very_small"
                id="blue"
              >
                {dict["Accept"][language]}
              </button>
              <button
                onClick={this.handleAction.bind(
                  this,
                  _id,
                  "free_rejected",
                  user_id
                )}
                className="button_very_small"
                id="grey"
              >
                {dict["Reject"][language]}
              </button>
            </section>
          );
          if (status === "free_accepted") {
            button_content = (
              <div className="button_very_small" id="blue">
                {dict["Accepted"][language]}
              </div>
            );
          } else if (
            status === "free_pending" &&
            loadingProcess &&
            itemLoading === "action_request_free_import" &&
            request_id_array.includes(_id)
          ) {
            button_content = (
              <div className="button_very_small" id="blue">
                {dict["Processing"][language]}
              </div>
            );
          } else if (status === "free_rejected") {
            button_content = (
              <div className="button_very_small" id="grey">
                {dict["Rejected"][language]}
              </div>
            );
          }
          return (
            <main
              key={idx}
              style={{ marginBottom: "40px", width: "calc(( 100% - 20px ))" }}
            >
              <section>
                <button className="flex_container">
                  <div className="flex_child">
                    <div
                      style={{
                        margin: "auto",
                        backgroundImage: `url(${profile_picture})`,
                        backgroundColor: "#ecf6fe",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        height: "50px",
                        width: "50px",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                  <section className="flex_child">{full_name}</section>
                </button>
                <section
                  className="two_container_small_right"
                  style={{ marginTop: "5px" }}
                >
                  <div
                    style={{ whiteSpace: "pre-wrap" }}
                    className="container_standard"
                    id="almost_white"
                  >
                    {request.free_message}
                  </div>
                  <div>{button_content}</div>
                </section>
              </section>
            </main>
          );
        })}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  blog: state.blog,
});
export default connect(mapStateToProps, {
  actionRequestFreeImport,
})(RequestAction);
