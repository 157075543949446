//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  EditorState,
  RichUtils,
  convertToRaw,
  AtomicBlockUtils,
  convertFromRaw,
} from "draft-js";
import Editor from "draft-js-plugins-editor";
import { Helmet } from "react-helmet";

//Personal Component
import UploadPicture from "../Components/blog/UploadPicture";
import ListPictures from "../Components/blog/ListPictures";
import ListPicturesBanner from "../Components/blog/ListPicturesBanner";
import AttachLayer from "../Components/blog/AttachLayer";
import AttachProject from "../Components/blog/AttachProject";
import StatusBlog from "../Components/blog/StatusBlog";
import SpinnerSimpleBlue from "../Components/common_spinner/SpinnerSimpleBlue";
import Modal from "../Components/common_modal/Modal";
import FAQ from "../Components/blog/FAQ";

//Redux function
import {
  getBlogDetailPersonal,
  editBlogGeneral,
  editBlogEditor,
  clearBlog,
  removeLayer,
  removeProject,
  get_categories,
} from "../App/actions/blogAction";
import { openModal } from "../App/actions/authActions";

//General Function
import dict from "../Data/dict.json";
import domain_list from "../Data/domain_list";
import isEmpty from "../App/validation/is-empty";
import isEqual from "../App/validation/is-equal";
import { mediaBlockRenderer } from "../Components/blog/mediaBlockRenderer";

const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

class RichEditorNew extends Component {
  state = {
    id: "",
    title: "",
    title_en: "",
    sub_title: "",
    picture_url: "",
    picture_alt: "",
    editorState: EditorState.createEmpty(),
    link_text: "Link name",
    link_url: "https://mapid.io",
    openStatus: false,
    sidebarStatus: false,
    modeSideBar: "",
    layerArray: [],
    projectArray: [],
    isPublish: false,
    modalSelectPicture: false,
    modalStatus: false,
    selected_category: "",
    modalWarning: false,
    warningMessage: "",
    topics: [],
    inputTopic: "",
  };

  componentDidMount() {
    this.timer = null;
    const { blog_link } = this.props.match.params;
    this.props.getBlogDetailPersonal(blog_link);
    document.getElementById("container_document").style.width = "100%";
    document.getElementById("container_document").style.marginLeft = "0px";
    this.props.get_categories();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.blog.blog_personal?._id !== prevState.id) {
      const id = nextProps.blog.blog_personal?._id;
      const title = nextProps.blog.blog_personal?.title || "";
      const title_en = nextProps.blog.blog_personal?.title_en || "";
      const sub_title = nextProps.blog.blog_personal?.sub_title || "";
      const picture_url = nextProps.blog.blog_personal?.picture_url || "";
      const picture_alt = nextProps.blog.blog_personal?.picture_alt || "";
      const topics = nextProps.blog.blog_personal?.topics || [];
      const selected_category =
        nextProps.blog.blog_personal?.category_id?.[0] || "";
      if (title) {
        const editorState = nextProps.blog.blog_personal.editorState;
        const layers = nextProps.blog.blog_personal.layers;
        const projects = nextProps.blog.blog_personal.projects;
        const isPublish = nextProps.blog.blog_personal.isPublish;
        const contentState = convertFromRaw(JSON.parse(editorState));
        const editorStateFinal = EditorState.createWithContent(contentState);
        let layerArray = [];
        if (!isEmpty(layers)) {
          const layers_filtered = layers.filter(
            (layer) => !!layer && !!layer.geo_layer
          );
          layerArray = layers_filtered.map((layer) => {
            const { geo_layer } = layer;
            return geo_layer;
          });
        }
        let projectArray = [];
        if (!isEmpty(projects)) {
          const projects_filtered = projects.filter(
            (project) => !!project && !!project.geo_project
          );
          projectArray = projects_filtered.map((project) => {
            const { geo_project } = project;
            return geo_project;
          });
        }
        return {
          id: id,
          title,
          title_en: title_en || dict["Untitled Document"]["eng"],
          sub_title,
          picture_url,
          picture_alt,
          editorState: editorStateFinal,
          layerArray,
          projectArray,
          isPublish,
          selected_category,
          topics,
        };
      } else return null;
    } else return null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.blog.pictureIndex !== prevProps.blog.pictureIndex) {
      this.onAddImage(this.props.blog.pictureUrlSelected);
    }
    if (
      !isEmpty(this.props.blog.blog_personal?.projects) &&
      !isEqual(this.props.blog.blog_personal?.projects, this.state.projectArray)
    ) {
      this.setState({
        projectArray: this.props.blog.blog_personal?.projects,
      });
    }
    if (
      !isEmpty(this.props.blog.blog_personal?.layers) &&
      !isEqual(this.props.blog.blog_personal?.layers, this.state.layerArray)
    ) {
      this.setState({
        layerArray: this.props.blog.blog_personal?.layers,
      });
    }
  }

  componentWillUnmount() {
    this.props.clearBlog();
  }

  toggleWarning = (value, message) => {
    this.setState({
      modalWarning: value || !this.state.modalWarning,
      warningMessage: message || "",
    });
  };

  onRemoveLayer = (layer) => {
    const { blog_personal } = this.props.blog;
    const body = {
      blog_id: blog_personal._id,
      layer: layer,
    };
    this.props.removeLayer(body);
  };

  onRemoveProject = (project) => {
    const { blog_personal } = this.props.blog;
    const body = {
      blog_id: blog_personal._id,
      project: project,
    };
    this.props.removeProject(body);
  };

  onOpenModal = (name) => {
    this.props.openModal(name);
  };

  toggleStatus = () => {
    this.setState({ modalStatus: !this.state.modalStatus });
  };

  toggleSelectPicture = () => {
    this.setState({ modalSelectPicture: !this.state.modalSelectPicture });
  };

  handleCategory = (e) => {
    const { title, title_en } = this.state;
    this.setState(
      {
        selected_category: e.target.value,
      },
      () => {
        if (title && title_en) {
          this.handleSubmitGeneral();
        }
      }
    );
  };

  handleDeleteLayer = (layer_id) => {
    const { layerArray } = this.state;
    const layerArrayNew = layerArray.filter((layer) => layer._id !== layer_id);
    clearTimeout(this.timer);
    this.setState({ layerArray: layerArrayNew });
    this.timer = setTimeout(this.handleSubmitGeneral.bind(this), WAIT_INTERVAL);
  };

  handleDeleteProject = (project_id) => {
    const { projectArray } = this.state;
    const projectArrayNew = projectArray.filter(
      (project) => project._id !== project_id
    );
    clearTimeout(this.timer);
    this.setState({ projectArray: projectArrayNew });
    this.timer = setTimeout(this.handleSubmitGeneral.bind(this), WAIT_INTERVAL);
  };

  toggleSideBar = (title) => {
    const { openStatus, modeSideBar } = this.state;
    this.setState(
      {
        openStatus: !openStatus,
        modeSideBar: title,
      },
      () => {
        if (!(openStatus && modeSideBar === title)) {
          this.setState({ sidebarStatus: true });
          document.getElementById("main_blog_content").style.width =
            "calc(( 100% - 400px ))";
          document.getElementById("main_blog_content").style.marginLeft =
            "400px";
        } else {
          this.setState({ sidebarStatus: false });
          document.getElementById("main_blog_content").style.width = "100%";
          document.getElementById("main_blog_content").style.marginLeft = "0px";
        }
      }
    );
  };

  handleChange(e) {
    const { title, title_en, selected_category } = this.state;
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    clearTimeout(this.timer);
    this.setState({
      [name]: value,
    });

    if (selected_category && title && title_en) {
      this.timer = setTimeout(
        this.handleSubmitGeneral.bind(this),
        WAIT_INTERVAL
      );
    }
    // this.timer = setTimeout(this.handleSubmitGeneral.bind(this), WAIT_INTERVAL);
  }

  handleToggle = () => {
    clearTimeout(this.timer);
    this.setState({ isPublish: !this.state.isPublish });
    this.timer = setTimeout(this.handleSubmitGeneral.bind(this), WAIT_INTERVAL);
  };

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.handleSubmitGeneral.bind(this);
    }
  }

  handleChangeLink = (e) => {
    e.preventDefault();
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleSubmitGeneral = () => {
    const { blog_personal } = this.props.blog;
    const blog_id = blog_personal?._id;
    if (!!blog_id) {
      const {
        title,
        // title_en,
        sub_title,
        picture_url,
        picture_alt,
        layerArray,
        projectArray,
        selected_category,
        topics,
      } = this.state;
      let title_en = title;
      const layers = layerArray.map((layer) => {
        return {
          geo_layer: layer._id,
          link: layer.link ? layer.link : layer._id,
          date: Date.now(),
        };
      });
      const projects = projectArray.map((project) => {
        return {
          geo_project: project._id,
          link: project.link ? project.link : project._id,
          date: Date.now(),
        };
      });

      // if (!selected_category) {
      //   this.toggleWarning(
      //     true,
      //     "Please select the category and then save it first!"
      //   );
      // } else if (!title || !title_en) {
      //   this.toggleWarning(true, "Please input the title!");
      // } else {
      const body = {
        blog_id,
        title,
        title_en,
        sub_title,
        picture_url,
        picture_alt,
        layers,
        projects,
        layers_original: layerArray,
        projects_original: projectArray,
        category_id: [selected_category],
        topics,
      };
      this.props.editBlogGeneral(body);
      // }
    }
  };

  handleSubmitEditor = () => {
    const { blog_personal } = this.props.blog;
    const blog_id = blog_personal?._id;
    if (!!blog_id) {
      const { editorState } = this.state;
      const raw = convertToRaw(editorState.getCurrentContent());
      const body = {
        blog_id,
        editorState: JSON.stringify(raw),
      };
      this.props.editBlogEditor(body);
    }
  };

  handleClickImage = (picture_url) => {
    this.setState({ picture_url }, () => {
      this.handleSubmitGeneral();
    });
  };

  handleAltImage = (e) => {
    this.setState({ picture_alt: e.target.value });
  };

  handleSubmitAll = () => {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { title, title_en, selected_category } = this.state;
    if (selected_category && title && title_en) {
      this.handleSubmitGeneral();
      this.handleSubmitEditor();
    } else {
      this.toggleWarning(
        true,
        dict["Title [INA], Title [ENG], and Category must be filled"][language]
      );
    }
  };

  //Rich editor stuff
  focus = () => this.refs.editor.focus();

  onChangeEditor = (editorState) => {
    const { selected_category, title, title_en } = this.state;
    clearTimeout(this.timer);
    this.setState({ editorState });
    if (selected_category && title && title_en) {
      this.timer = setTimeout(
        this.handleSubmitEditor.bind(this),
        WAIT_INTERVAL
      );
    }
  };

  handleKeyCommand = (command) => {
    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChangeEditor(newState);
      return true;
    }
    return false;
  };

  toggleBlockType = (blockType) => {
    this.onChangeEditor(
      RichUtils.toggleBlockType(this.state.editorState, blockType)
    );
  };

  toggleInlineStyle = (inlineStyle) => {
    this.onChangeEditor(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    );
  };

  //Add image to document
  onAddImage = (picNew) => {
    const editorState = this.state.editorState;
    const urlValue = picNew;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "image",
      "IMMUTABLE",
      { src: urlValue }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(
      editorState,
      { currentContent: contentStateWithEntity },
      "create-entity"
    );
    clearTimeout(this.timer);
    this.setState(
      {
        editorState: AtomicBlockUtils.insertAtomicBlock(
          newEditorState,
          entityKey,
          " "
        ),
      },
      () => {
        setTimeout(() => this.focus(), 0);
      }
    );
    this.timer = setTimeout(this.handleSubmitEditor.bind(this), WAIT_INTERVAL);
  };

  onAddLink = () => {
    const { editorState, link_text, link_url } = this.state;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "link",
      "IMMUTABLE",
      { link_text, link_url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(
      editorState,
      { currentContent: contentStateWithEntity },
      "create-entity"
    );
    clearTimeout(this.timer);
    this.setState(
      {
        editorState: AtomicBlockUtils.insertAtomicBlock(
          newEditorState,
          entityKey,
          " "
        ),
      },
      () => {
        setTimeout(() => this.focus(), 0);
      }
    );
    this.timer = setTimeout(this.handleSubmitEditor.bind(this), WAIT_INTERVAL);
  };

  handleAddTopic = (event) => {
    const { value } = event.target;
    const { keyCode, key } = event;
    if ((value !== "" && keyCode === 13, key === "Enter")) {
      this.setState({
        topics: [...this.state.topics, value.toLowerCase()],
        inputTopic: "",
      });
    }
  };

  handleDeleteTopic = (index) => {
    const updatedArray = [...this.state.topics];
    updatedArray.splice(index, 1);
    this.setState({ topics: updatedArray });
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const {
      editorState,
      title,
      title_en,
      link_text,
      link_url,
      sidebarStatus,
      modeSideBar,
      modalSelectPicture,
      modalStatus,
      selected_category,
      modalWarning,
      warningMessage,
    } = this.state;
    const { loadingProcess, itemLoading, categories } = this.props.blog;
    const blog_personal = this.props?.blog?.blog_personal;
    const layers = blog_personal?.layers ? blog_personal.layers : [];
    const projects = blog_personal?.projects ? blog_personal.projects : [];
    const { user } = this.props.auth;
    let className = "RichEditor-editor";
    const contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== "unstyled") {
        className += " RichEditor-hidePlaceholder";
      }
    }
    const modeSideBarArray = [
      {
        title: dict["Add Link"][language],
        component: (
          <div>
            <input
              className="form-control"
              type="text"
              name="link_text"
              id="link_text"
              value={link_text}
              onChange={this.handleChangeLink}
            />
            <input
              className="form-control"
              type="text"
              name="link_url"
              id="link_url"
              value={link_url}
              onChange={this.handleChangeLink}
            />
            <div style={{ textAlign: "center" }}>
              <div
                className="button_standard"
                onClick={this.onAddLink.bind(this)}
              >
                {dict["Insert Link"][language]}
              </div>
            </div>
          </div>
        ),
      },
      {
        title: dict["Add Image"][language],
        component: (
          <div>
            <UploadPicture />
            <ListPictures />
          </div>
        ),
      },
      {
        title: dict["Attach Layer"][language],
        component: (
          <div>
            <AttachLayer />
          </div>
        ),
      },
      {
        title: dict["Attach Project"][language],
        component: (
          <div>
            <AttachProject />
          </div>
        ),
      },
      {
        title: dict["faq"][language],
        component: (
          <div><FAQ /></div>
        )
      }
    ];
    let current_component;
    if (!!modeSideBar) {
      current_component = modeSideBarArray.filter(
        (mode) => mode.title === modeSideBar
      )[0].component;
    }
    const array_loading = ["edit_blog_general", "edit_blog_editor"];
    const buttonContent =
      loadingProcess && array_loading.includes(itemLoading) ? (
        <div className="w_50 bg_blue text_white h_50 w_80 rounded_5 center_perfect bold">
          {dict["Saving"][language]}
        </div>
      ) : (
        <div
          onClick={this.handleSubmitAll}
          className="w_50 bg_blue text_white h_50 w_80 rounded_5 center_perfect bold hover_pointer"
        >
          {dict["Save"][language]}
        </div>
      );
    const { domain } = this.props.auth;

    const toolContent = (
      <div className="tools_bar noselect">
        <div className="two_container_button_left">
          <div style={{ textAlign: "center" }}>
            <Link to="/blog_list">
              <img src={domain_list?.[domain]?.logo} width="100px" alt="Home" />
            </Link>
            <button
              className="button_info"
              id="blue"
              onClick={this.onOpenModal.bind(this, "Membuat Publikasi")}
            >
              i
            </button>
            <div style={{ marginTop: "15px" }}>
              <button
                onClick={this.toggleStatus}
                className="button_block"
                id="blue"
              >
                {dict["Status"][language]}
              </button>
            </div>
          </div>
          <div>
            <div className="two_container_button_right">
              <div className="">
                <div>
                  <label htmlFor="title" className="w_full">
                    {`${dict["Title"][language]}`}
                  </label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    className="input_title_blog"
                    value={title}
                    onChange={this.handleChange.bind(this)}
                    onKeyDown={this.handleKeyDown.bind(this)}
                    style={{
                      height: "49px",
                    }}
                  />
                </div>
                {/* <div>
                  <label htmlFor="title_en" className="w_full">
                    {`${dict["Title"][language]} [ENG]`}
                  </label>
                  <input
                    type="text"
                    name="title_en"
                    id="title_en"
                    className="input_title_blog"
                    value={title_en}
                    onChange={this.handleChange.bind(this)}
                    onKeyDown={this.handleKeyDown.bind(this)}
                    style={{
                      height: "49px",
                    }}
                  />
                </div> */}
              </div>
              <div className="center_perfect">{buttonContent}</div>
            </div>
            <div>
              <BlockStyleControls
                editorState={editorState}
                onToggle={this.toggleBlockType}
              />
              <InlineStyleControls
                editorState={editorState}
                onToggle={this.toggleInlineStyle}
              />
              {modeSideBarArray.map(({ title }) => {
                const id =
                  sidebarStatus && modeSideBar === title
                    ? "active"
                    : "non-active";
                return (
                  <div
                    onClick={this.toggleSideBar.bind(this, title)}
                    key={title}
                    id={id}
                    className="button_tool"
                  >
                    {title}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
    const categoryContent = (
      <section className="marginTop_10 marginBottom_10 w_auto">
        <label>{dict["Category"][language]}</label>
        <select
          value={selected_category}
          onChange={this.handleCategory}
          selected
          style={{ paddingLeft: "0.5rem" }}
        >
          <option key="" value="" disabled>
            Select
          </option>
          {categories.map((category) => (
            <option key={category._id} value={category._id}>
              {category?.[language === "eng" ? "eng" : "idn"]}
            </option>
          ))}
        </select>
      </section>
    );

    const keywordContent = (
      <section className="marginTop_10 marginBottom_10 w_auto">
        <span>{dict["Topics"][language]}</span>
        <div
          style={{
            border: "solid 1px #aaa9a9",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
            paddingLeft: "0.5rem",
            gap: "0.3rem",
            flexWrap: "wrap",
            paddingTop: "0.3rem",
          }}
        >
          {this.state.topics.map((topic, idx) => (
            <button
              key={idx}
              style={{
                backgroundColor: "white",
                padding: "4px 8px",
                borderRadius: "4px",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
              onClick={() => this.handleDeleteTopic(idx)}
            >
              {topic} <span>x</span>
            </button>
          ))}
          <input
            type="text"
            placeholder={dict["add a topic"][language]}
            onKeyUp={this.handleAddTopic}
            onChange={(e) => this.setState({ inputTopic: e.target.value })}
            value={this.state.inputTopic}
            style={{
              border: "none",
              background: "transparent",
              marginBottom: "0",
              display: "inline-block",
              width: "6rem",
            }}
          />
        </div>
      </section>
    );

    const previewContent = (
      <div className="container_blog" id="white">
        <main className="container_square">
          <section
            onClick={this.toggleSelectPicture}
            className="container_image_blog"
            style={{
              backgroundImage: `url(${blog_personal?.picture_url})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          />
          <section className="container_second_half_blog">
            <h1 className="title_blog">{blog_personal?.title}</h1>
            <p className="sub_title_blog">
              {user?.full_name ? user?.full_name : "No full name"}
            </p>
          </section>
        </main>
      </div>
    );

    const modalSelectPictureContent = modalSelectPicture && (
      <Modal
        modalSize="medium"
        id="modal banner"
        isOpen={modalSelectPicture}
        onClose={this.toggleSelectPicture}
      >
        <div
          className="box-body"
          style={{ marginTop: "30px", marginBottom: "30px" }}
        >
          <h1>{dict["Pilih Gambar Thumbnail"][language]}</h1>
          <ListPicturesBanner
            handleClickImage={this.handleClickImage.bind(this)}
          />
          <h1>{dict["Deskripsi Gambar"][language]}</h1>
          <div style={{ marginTop: "0.3rem" }}>
            <div>{dict["Deskripsi Gambar Label"][language]}</div>
            <div
              style={{
                border: "solid 1px #bdb497",
                padding: "0.3rem",
                borderRadius: "0.5rem",
                marginBottom: "1rem",
                marginTop: "0.3rem",
                backgroundColor: "#fff3cd",
              }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: dict["Deskripsi Gambar Contoh"][language],
                }}
              ></span>
            </div>
          </div>
          <input
            type="text"
            placeholder={dict["Masukan Deskripsi Disini"][language]}
            onChange={this.handleAltImage}
            value={this.state.picture_alt}
          />

          <div
            onClick={() => {
              this.setState({ modalSelectPicture: false });
              this.handleSubmitGeneral();
            }}
            style={{ margin: "1rem auto 0rem auto" }}
            className="w_50 bg_blue text_white h_50 w_80 rounded_5 center_perfect bold hover_pointer"
          >
            {dict["Save"][language]}
          </div>
        </div>
      </Modal>
    );

    const modalStatusContent = modalStatus && (
      <Modal
        modalSize="large"
        id="modal status"
        isOpen={modalStatus}
        onClose={this.toggleStatus}
        backgroundColor="#f1f1f1"
      >
        <div className="box-body">
          <StatusBlog />
        </div>
      </Modal>
    );

    const modalWarningContent = modalWarning && (
      <Modal
        modalSize="small"
        id="modal status"
        isOpen={modalWarning}
        onClose={this.toggleWarning}
        backgroundColor="#f1f1f1"
      >
        <div className="box-body">{warningMessage}</div>
      </Modal>
    );

    const layersContent = (
      <div style={{ marginBottom: "30px" }}>
        <p className="text_medium">{dict["Layer attached"][language]}</p>
        {isEmpty(layers) ? (
          <div>
            <p className="text_inferior">
              {dict["No layer attached"][language]}
            </p>
          </div>
        ) : (
          <div className="carousell" style={{ marginBottom: "10px" }}>
            {layers.map((e) => {
              const layer = e.geo_layer;
              let layerContent = null;
              let link, _id, name, isPublic;
              if (layer) {
                link = layer?.geo_project?.link;
                _id = layer._id;
                name = layer.name;
                isPublic = layer.isPublic;
                const button_open = (
                  <div>
                    {isPublic ? (
                      <Link
                        to={`/view/${link}`}
                        target="_blank"
                        className="button_very_small"
                      >
                        {dict["Open"][language]}
                      </Link>
                    ) : (
                      <Link
                        to={`/view/${link}`}
                        target="_blank"
                        className="button_very_small"
                      >
                        {dict["Not Public"][language]}
                      </Link>
                    )}
                    <button
                      className="button_very_small"
                      id="red"
                      style={{ marginLeft: "10px" }}
                      onClick={this.onRemoveLayer.bind(this, layer)}
                    >
                      {dict["Delete"][language]}
                    </button>
                  </div>
                );
                layerContent = (
                  <div
                    key={_id}
                    className="container_grey"
                    style={{
                      minWidth: "130px",
                      width: "130px",
                      height: "60px",
                      marginRight: "10px",
                      backgroundColor: "#0ca5ebff",
                      color: "#ffffff",
                      position: "relative",
                    }}
                  >
                    <div className="card_layer_content">{name}</div>
                    <div
                      style={{
                        position: "absolute",
                        bottom: "5px",
                      }}
                    >
                      {button_open}
                    </div>
                  </div>
                );
              }
              return layerContent;
            })}
          </div>
        )}
      </div>
    );
    const projectsContent = (
      <div style={{ marginBottom: "30px" }}>
        <p className="text_medium">{dict["Project attached"][language]}</p>
        {isEmpty(projects) ? (
          <div>
            <p className="text_inferior">
              {dict["No project attached"][language]}
            </p>
          </div>
        ) : (
          <div className="carousell" style={{ marginBottom: "10px" }}>
            {projects.map((e) => {
              const project = e.geo_project;
              const link = project?.link;
              const _id = project?._id;
              const name = project?.name;
              const isPublic = project?.isPublic;
              const button_open = (
                <div>
                  {isPublic ? (
                    <Link to={`/view/${link}`} className="button_very_small">
                      {dict["Open"][language]}
                    </Link>
                  ) : (
                    <Link to={`/view/${link}`} className="button_very_small">
                      {dict["Not Public"][language]}
                    </Link>
                  )}
                  <button
                    className="button_very_small"
                    id="red"
                    style={{ marginLeft: "10px" }}
                    onClick={this.onRemoveProject.bind(this, project)}
                  >
                    {dict["Delete"][language]}
                  </button>
                </div>
              );
              return (
                <div
                  key={_id}
                  className="container_grey"
                  style={{
                    minWidth: "130px",
                    width: "130px",
                    height: "60px",
                    marginRight: "10px",
                    backgroundColor: "#0ca5ebff",
                    color: "#ffffff",
                    position: "relative",
                  }}
                >
                  <div style={{ textAlign: "right", width: "100%" }}></div>
                  <div className="card_layer_content">{name}</div>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "5px",
                    }}
                  >
                    {button_open}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );

    const tocRaw = convertToRaw(editorState.getCurrentContent());
    const typeHeader = [
      "header-one",
      "header-two",
      "header-three",
      "header-four",
      "header-five",
      "header-six",
    ];
    const { blocks } = tocRaw;
    let listHeader = [];
    for (let index = 0; index < blocks.length; index++) {
      const element = blocks[index];
      if (typeHeader.includes(element.type)) {
        listHeader.push(element);
      }
    }
    const previewSidebarContent = (
      <section className="container_layer_and_project">
        <div>
          {previewContent}
          <div className="center_perfect">
            <div className="marginTop_10">
              <div className="overflowX_auto w_350">{layersContent}</div>
              <div className="overflowX_auto w_350">{projectsContent}</div>
            </div>
          </div>
        </div>
        <div>
          <h3>{dict["Daftar Isi"][language]}</h3>
          <hr />
          <ul style={{ listStyle: "none", fontWeight: "bold" }}>
            {listHeader.map((header, idx) => {
              switch (header.type) {
                case "header-one":
                  return (
                    <li
                      key={idx}
                      style={{ marginBottom: "0.5rem", marginLeft: "0rem" }}
                    >
                      <a href={`#${header.text}`}>{header.text}</a>
                    </li>
                  );
                case "header-two":
                  return (
                    <li
                      key={idx}
                      style={{ marginBottom: "0.5rem", marginLeft: "0.5rem" }}
                    >
                      <a href={`#${header.text}`}>{header.text}</a>
                    </li>
                  );
                case "header-three":
                  return (
                    <li
                      key={idx}
                      style={{ marginBottom: "0.5rem", marginLeft: "1rem" }}
                    >
                      <a href={`#${header.text}`}>{header.text}</a>
                    </li>
                  );
                case "header-four":
                  return (
                    <li
                      key={idx}
                      style={{ marginBottom: "0.5rem", marginLeft: "1.5rem" }}
                    >
                      <a href={`#${header.text}`}>{header.text}</a>
                    </li>
                  );
                case "header-five":
                  return (
                    <li
                      key={idx}
                      style={{ marginBottom: "0.5rem", marginLeft: "2rem" }}
                    >
                      <a href={`#${header.text}`}>{header.text}</a>
                    </li>
                  );
                case "header-six":
                  return (
                    <li
                      key={idx}
                      style={{ marginBottom: "0.5rem", marginLeft: "2.5rem" }}
                    >
                      <a href={`#${header.text}`}>{header.text}</a>
                    </li>
                  );

                default:
                  return (
                    <li key={idx} style={{ marginLeft: "0rem" }}>
                      <a href={`#${header.text}`}>{header.text}</a>
                    </li>
                  );
              }
            })}
          </ul>
        </div>
      </section>
    );

    const editorInputContent = (
      <main
        id="container_document"
        style={{
          paddingTop: "10px",
          transition: "all 0.2s ease-in-out",
        }}
        className="container_blog_editor"
      >
        <section className="container_document_new center_perfect">
          <div className="w_full">
            {categoryContent}
            {keywordContent}
            <div
              className="RichEditor-root rounded_5 w_auto"
              style={{
                backgroundColor: "#ffffff",
                padding: "20px",
                minHeight: "620px",
              }}
              onClick={(e) => {
                if (selected_category && title && title_en) {
                  this.focus(e);
                } else {
                  this.toggleWarning(
                    true,
                    dict[
                      "Title [INA], Title [ENG], and Category must be filled"
                    ][language]
                  );
                }
              }}
            >
              <div className={className}>
                <Editor
                  readOnly={
                    selected_category && title && title_en ? false : true
                  }
                  blockRendererFn={mediaBlockRenderer}
                  blockStyleFn={getBlockStyle}
                  customStyleMap={styleMap}
                  editorState={editorState}
                  handleKeyCommand={this.handleKeyCommand.bind(this)}
                  onChange={this.onChangeEditor}
                  placeholder={dict["Click here to start writing"][language]}
                  ref="editor"
                  spellCheck={false}
                />
              </div>
            </div>
          </div>
          {modalWarningContent}
        </section>
      </main>
    );
    let itemContent = (
      <div id="container_document">
        <SpinnerSimpleBlue
          // width={200}
          unik="loading_item_list"
          marginTop="30vh"
          center={true}
          border={0.1}
        />
      </div>
    );
    if (!isEmpty(blog_personal)) {
      itemContent = (
        <div>
          {toolContent}
          <div id="main_blog_content" className="two_container_blog_editor">
            {previewSidebarContent}
            {editorInputContent}
          </div>
          {sidebarStatus && (
            <div className="container_document_import">{current_component}</div>
          )}
        </div>
      );
    }
    return (
      <div
        style={{
          // backgroundColor: "rgb(223, 231, 235)",
          minHeight: "100vh",
          // paddingBottom: "100px",
        }}
      >
        {itemContent}
        {modalSelectPictureContent}
        {modalStatusContent}
        <Helmet>
          <title>{`${title ? title : "GEO DOCS"}`}</title>
          <meta name="description" content={`${title ? title : "GEO DOCS"}`} />
        </Helmet>
        <style>
          {`
      body{
        padding-top: 0px;
        overflow-y: auto;
      }
      .navbarDefault{ visibility: hidden;}
      #top_nav{ visibility: hidden;}
      #sidebar_home{ visibility: hidden;}
      .nav_bottom{visibility: hidden;}      
      `}
        </style>
      </div>
    );
  }
}
const styleMap = {
  CODE: {
    backgroundColor: "red",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
  },
};
function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    case "code-block":
      return "RichEditor-code-block";
    case "center":
      return "RichEditor-center";
    default:
      return null;
  }
}
class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }
  render() {
    let className = "RichEditor-styleButton";
    if (this.props.active) {
      className += " RichEditor-activeButton";
    }
    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}
const BLOCK_TYPES = [
  { label: "H1", style: "header-one" },
  { label: "H2", style: "header-two" },
  { label: "H3", style: "header-three" },
  { label: "H4", style: "header-four" },
  { label: "H5", style: "header-five" },
  { label: "H6", style: "header-six" },
  { label: "Blockquote", style: "blockquote" },
  { label: "UL", style: "unordered-list-item" },
  { label: "OL", style: "ordered-list-item" },
  { label: "Code Block", style: "code-block" },
  { label: "Center", style: "center" },
];
const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <div className="RichEditor-controls" style={{ display: "inline-block" }}>
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};
const INLINE_STYLES = [
  { label: "Bold", style: "BOLD" },
  { label: "Italic", style: "ITALIC" },
  { label: "Underline", style: "UNDERLINE" },
  { label: "Monospace", style: "CODE" },
];
const InlineStyleControls = (props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls" style={{ display: "inline-block" }}>
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  blog: state.blog,
  categories: state.categories,
});

export default connect(mapStateToProps, {
  get_categories,
  getBlogDetailPersonal,
  editBlogGeneral,
  editBlogEditor,
  clearBlog,
  openModal,
  removeLayer,
  removeProject,
})(RichEditorNew);
