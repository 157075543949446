/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
// import CellReturnValue from "../viewer_table/CellReturnValue";

/*REDUX FUNCTION*/
import {
  edit_final_calculation_field_key,
  edit_rev_calculation_field_key,
  edit_percent_value_field_key,
} from "../../App/actions/propertiesActions";
import {
  editProperties,
  editPropertiesV2,
} from "../../App/actions/layerActions";
/*PICTURE ASSET*/

/*GENERAL*/
import {
  // calculateWithoutConvert,
  convertFieldsToHeaders,
  convertPropertiesToDataList,
  packThePropertiesDataToUseApiEdit,
} from "../../App/validation/convert_data";
import { getGeoLayerById, getLayerDetails } from "../../App/reduxHelper/layer";
import { StyledDataGrid } from "../../Style/StyledDataGrid";
import is_empty from "../../App/validation/is-empty";

class TableDataPembanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // modal
      modal_choose_final: false,
      modal_choose_rev: false,
      modal_choose_percent: false,
      modal_table: false,

      // tabel data pembanding
      headers: [],
      data_list: [],

      // default
      features: [],

      // lainnya
      final: {
        min: 0,
        max: 1,
        result: 50,
        conclusion: "",
        color: "",
      },
      rev: {
        min: 0,
        max: 1,
        result: 50,
        conclusion: "",
        color: "",
      },
      sum: 0,
      total_indikasi: 0,
      final_calculation_field_key: "",
      field_final: {},
      rev_calculation_field_key: "",
      field_rev: {},
      percent_value_field_key: "",
      field_percent: {},
      final_fields: [],
    };
  }

  componentDidMount = () => {
    this.on_render();
  };

  componentDidUpdate = (prevProps) => {
    const status_action_after = this.props.map.status_action;
    const status_action_before = prevProps.map.status_action;
    if (status_action_after !== status_action_before) {
      this.on_render();
    }
  };

  on_render = () => {
    const deleteColumn = {
      field: "no",
      headerName: "No.",
      width: 80,
      sortable: false,
      renderHeader: (params) => {
        return <>No.</>;
      },
      renderCell: (params) => {
        return <>{params?.id + 1}.</>;
      },
    };

    //local storage

    //local state

    //global props
    const { feature_key_pembanding_list } = this.props.map;

    //content

    const layer_object = getLayerDetails(this.props.layer);

    const id_pembanding = layer_object?.geo_layer?.layer_data_list?.[0]?._id;
    const layer_pembanding = getGeoLayerById({
      layer: this.props.layer,
      layer_id: id_pembanding,
    });

    let fields = layer_pembanding?.fields || [];
    fields = fields.filter(
      (item) => !item?.isStyle && !item?.isHide && !item?.parent_uuid
    );
    const fields_highlight = fields.filter((item) => item.isHighlight);
    let final_fields = fields;
    if (fields_highlight.length > 0) {
      final_fields = fields_highlight;
    }

    let features = layer_pembanding?.geojson?.features || [];
    features = features.filter((item) =>
      feature_key_pembanding_list.includes(item?.key)
    );

    const {
      final_calculation_field_key,
      field_final,
      rev_calculation_field_key,
      field_rev,
      percent_value_field_key,
      field_percent,
      sum,
    } = this.setup({ layer_pembanding, fields, features });

    const layer_appraisal = layer_object.geo_layer;
    const source = "TableDataPembanding";
    // mengisi feature kosong dgn hasil kalkulasi menggunakan formula.
    const feature_key_survey = this.props.map.feature_key_survey;
    let feature_appraisal;
    if (feature_key_survey) {
      const appraisal_features = layer_appraisal?.geojson?.features || [];
      feature_appraisal = appraisal_features.find(
        (item) => item.key === feature_key_survey
      );
    }

    const headers = convertFieldsToHeaders(
      {
        fields,
        layer_id: id_pembanding,
        sortedFeatures: features,
        geo_layer: layer_pembanding,
        is_from_first_gen_table: true,
        is_editable_field: false,
      },
      {
        handleEditCellChange: this.handleEditCellChange,
        toggle_table: this.toggle_table,
        editProperties: this.props.editProperties,
      },
      {
        deleteColumn: deleteColumn,
      }
    );

    features = convertPropertiesToDataList({
      features,
      fields,
      source,
      feature_appraisal,
    });

    features = features.map((item) => {
      const nilai_pembobotan = item?.[percent_value_field_key] / sum;
      const nilai_indikasi =
        item?.[rev_calculation_field_key] * nilai_pembobotan;
      item.nilai_pembobotan = nilai_pembobotan;
      item.nilai_indikasi = nilai_indikasi;
      return item;
    });

    let total_indikasi = 0;

    if (field_percent?.key) {
      const array_of_number = features.map((item) =>
        Number(item?.nilai_indikasi)
      );
      const init = 0;
      total_indikasi = array_of_number.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        init
      );
    }

    this.setState({
      headers,
      data_list: features,
      final_calculation_field_key,
      field_final,
      rev_calculation_field_key,
      field_rev,
      percent_value_field_key,
      field_percent,
      final_fields,
      total_indikasi,
    });
  };

  setup = ({ layer_pembanding, fields, features }) => {
    //final
    const final_calculation_field_key =
      layer_pembanding?.final_calculation_field_key;
    const field_final = fields.find(
      (item) => item.key === final_calculation_field_key
    );

    //rev
    const rev_calculation_field_key =
      layer_pembanding?.rev_calculation_field_key;
    const field_rev = fields.find(
      (item) => item.key === rev_calculation_field_key
    );

    //percent
    const percent_value_field_key = layer_pembanding?.percent_value_field_key;
    const field_percent = fields.find(
      (item) => item.key === percent_value_field_key
    );

    const generate_number = (key) => {
      let array_of_number = [],
        min = 0,
        max = 1,
        result = 50,
        conclusion = "",
        color = "";
      if (key) {
        //convert value menjadi array of number;
        array_of_number = features.map((item) => item.properties[key]);
        min = Math.min(...array_of_number);
        max = Math.max(...array_of_number);
        result = ((1 - min / max) * 100).toFixed(1);
        if (result === 0) {
          conclusion = "Tidak bisa diteruskan/informasi tidak sama";
          color = "red";
        } else if (result > 0 && result <= 15) {
          conclusion = "Informasi data/bisa diteruskan";
          color = "green";
        } else {
          conclusion = "Informasi data tidak bagus/ganti";
          color = "red";
        }
      }

      return { min, max, conclusion, color, result };
    };

    if (field_final?.key) {
      const { min, max, conclusion, color, result } = generate_number(
        field_final.key
      );
      this.setState({
        final: {
          min,
          max,
          conclusion,
          color,
          result,
        },
      });
    }

    if (field_rev?.key) {
      const { min, max, conclusion, color, result } = generate_number(
        field_rev.key
      );
      this.setState({
        rev: {
          min,
          max,
          conclusion,
          color,
          result,
        },
      });
    }

    let sum = 0;
    if (field_percent?.key) {
      const array_of_number = features.map((item) =>
        Number(item.properties[percent_value_field_key])
      );
      const init = 0;
      sum = array_of_number.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        init
      );
      this.setState({
        sum,
      });
    }

    return {
      final_calculation_field_key,
      field_final,
      rev_calculation_field_key,
      field_rev,
      percent_value_field_key,
      field_percent,
      sum,
    };
  };

  handleEditCellChange(editProperties, layer_id, params) {
    const body = packThePropertiesDataToUseApiEdit(layer_id, params);
    editProperties(body);
  }

  onCellEditCommit(params) {
    if (
      !is_empty(this.state.old_cell) &&
      this.state.old_cell.value !== params.value
    ) {
      let newParams = this.state.old_cell;
      newParams.value = params.value;
      newParams.row.value = params.value;

      const layer_object = getLayerDetails(this.props.layer);
      const id_pembanding = layer_object?.geo_layer?.layer_data_list?.[0]?._id;
      const { geo_layer_list } = this.props.layer;
      const layer = geo_layer_list.find(
        (l) => l.geo_layer._id === id_pembanding
      );
      if (layer?.geo_layer?.architecture === "v2") {
        this.handleEditCellChange(
          this.props.editPropertiesV2,
          id_pembanding,
          newParams
        );
      } else {
        this.handleEditCellChange(
          this.props.editProperties,
          id_pembanding,
          newParams
        );
      }

      this.setState({ old_cell: {} });
    }
  }

  onCellEditStart(params) {
    this.setState({ old_cell: params });
  }

  toggle_table = (field_parent, feature_parent, child) => {
    this.setState({ modal_table: !this.state.modal_table });

    if (field_parent) {
      this.props.set_value("field_parent", field_parent);
      this.props.set_value("feature_parent", feature_parent);
      this.props.set_value("child", child);
    } else {
      //bagaimana cara balikin ke parent fieldnya lagi
      this.props.set_value("child", null);
      const child = this.props.child;

      if (child) {
        const { layer_id, geo_layer_list, feature_parent } = this.props.layer;

        const layer = geo_layer_list.find(
          (l) => l.geo_layer._id === layer_id
        )?.geo_layer;

        const fields = layer?.fields || [];

        let child_array = feature_parent?.child_array || [];

        const item = child_array.find(
          (c) => c.child_uuid === child.parent_uuid_temp
        );
        const parent_uuid = item?.parent_uuid;

        const field_parent_new = fields.find((f) => f.uuid === parent_uuid);

        this.props.set_value("field_parent", field_parent_new);
        this.props.set_value("child", undefined);
      }
    }
  };

  toggle_choose_percent = () => {
    this.setState({ modal_choose_percent: !this.state.modal_choose_percent });
  };

  toggle_choose_final = () => {
    this.setState({ modal_choose_final: !this.state.modal_choose_final });
  };

  toggle_choose_rev = () => {
    this.setState({ modal_choose_rev: !this.state.modal_choose_rev });
  };

  on_edit_percent_value_field_key = (field_key) => {
    this.setState({ modal_choose_percent: false });

    const { layer_id, geo_layer_list } = this.props.layer;
    const layer_object = geo_layer_list.find(
      (item) => item?.geo_layer?._id === layer_id
    );
    const id_pembanding = layer_object?.geo_layer?.layer_data_list?.[0]?._id;

    const body = {
      geo_layer_id: id_pembanding,
      field_key,
    };
    this.props.edit_percent_value_field_key(body);
  };

  on_edit_final_calculation_field_key = (field_key) => {
    this.setState({ modal_choose_final: false });

    const { layer_id, geo_layer_list } = this.props.layer;
    const layer_object = geo_layer_list.find(
      (item) => item?.geo_layer?._id === layer_id
    );
    const id_pembanding = layer_object?.geo_layer?.layer_data_list?.[0]?._id;

    const body = {
      geo_layer_id: id_pembanding,
      field_key,
    };
    this.props.edit_final_calculation_field_key(body);
  };

  on_edit_rev_calculation_field_key = (field_key) => {
    this.setState({ modal_choose_rev: false });

    const { layer_id, geo_layer_list } = this.props.layer;
    const layer_object = geo_layer_list.find(
      (item) => item?.geo_layer?._id === layer_id
    );
    const id_pembanding = layer_object?.geo_layer?.layer_data_list?.[0]?._id;

    const body = {
      geo_layer_id: id_pembanding,
      field_key,
    };
    this.props.edit_rev_calculation_field_key(body);
  };

  render() {
    // //local storage

    // //local state
    const {
      modal_choose_final,
      modal_choose_rev,
      modal_choose_percent,
      final,
      rev,
      // sum,
      total_indikasi,
      final_calculation_field_key,
      field_final,
      rev_calculation_field_key,
      field_rev,
      percent_value_field_key,
      field_percent,
      // final_fields,
      headers,
      data_list,
      // features,
    } = this.state;

    const table_data_pembanding_content = (
      <section className="container_grey">
        <h1 className="table_header margin_bottom">Data pembanding</h1>
        {data_list?.length > 0 ? (
          <section
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 5,
              paddingRight: 5,
              width: "100%",
              height: "300px",
            }}
          >
            <StyledDataGrid
              rows={data_list}
              columns={headers}
              getRowHeight={() => 45}
              disableColumnMenu
              onCellEditStart={(params) => this.onCellEditStart(params)}
              onCellEditCommit={(params) => this.onCellEditCommit(params)}
            />
          </section>
        ) : (
          <p className="text_medium">Belum submit data pembanding</p>
        )}
      </section>
    );

    return (
      <main style={{ minHeight: "80vh" }}>
        {table_data_pembanding_content}

        <br />

        <section className="container_grey">
          <h1 className="table_header margin_bottom">Validasi range</h1>
          <>
            <p className="text_inferior">
              Pilih kolom hasil perhitungan terakhir
            </p>
            <button
              className="button margin_bottom"
              id="green"
              onClick={this.toggle_choose_final}
            >
              Pilih
            </button>
          </>
          <p className="text_inferior">
            Field terpilih:{" "}
            <span className="badge_pill">{field_final?.name}</span>
          </p>

          {modal_choose_final && (
            <>
              {headers.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    className="margin_bottom"
                    onClick={this.on_edit_final_calculation_field_key.bind(
                      this,
                      item.field
                    )}
                  >
                    <button
                      id={
                        final_calculation_field_key === item.field
                          ? "checklist_active"
                          : "checklist_not"
                      }
                      className="margin_right"
                    />
                    <p className="text_inferior inline">{item?.headerName}</p>
                  </div>
                );
              })}
            </>
          )}
          <br />

          {!field_final?.key ? (
            <>
              <p className="text_inferior">
                Belum pilih kolom hasil perhitungan terakhir, silakan pilih
                salah satu kolom sebagai hasil perhitungan terakhir.
              </p>
            </>
          ) : (
            <>
              <p>Result:</p>
              <table className="table">
                <tbody>
                  <tr>
                    <td>min</td>
                    <td>
                      {new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(final?.min)}
                    </td>
                  </tr>
                  <tr>
                    <td>max</td>
                    <td>
                      {new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(final?.max)}
                    </td>
                  </tr>
                  <tr>
                    <td>Formula range</td>
                    <td>
                      <p>(1-(Nilai minimum/Nilai maksimum))*100%</p>
                      <br />
                      <p>
                        {
                          "Range 0% --> Tidak bisa diteruskan/informasi tidak sama"
                        }
                      </p>
                      <p>{"Range ≤15% --> Informasi data/bisa diteruskan"}</p>
                      <p>{"Range >15% --> Informasi data tidak bagus/ganti"}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>Hasil</td>
                    <td>{final?.result} %</td>
                  </tr>
                  <tr>
                    <td>Kesimpulan</td>
                    <td>
                      <div className="badge_pill" id={final?.color}>
                        {final?.conclusion}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </section>

        <br />

        <section className="container_grey">
          <h1 className="table_header margin_bottom">
            Validasi range setelah pengurangan
          </h1>
          <>
            <p className="text_inferior">
              Pilih kolom hasil perhitungan terakhir setelah pengurangan
            </p>
            <button
              className="button margin_bottom"
              id="green"
              onClick={this.toggle_choose_rev}
            >
              Pilih
            </button>
          </>

          <p className="text_inferior">
            Field terpilih:{" "}
            <span className="badge_pill">{field_rev?.name}</span>
          </p>

          {modal_choose_rev && (
            <>
              {headers.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    className="margin_bottom"
                    onClick={this.on_edit_rev_calculation_field_key.bind(
                      this,
                      item.field
                    )}
                  >
                    <button
                      id={
                        rev_calculation_field_key === item.field
                          ? "checklist_active"
                          : "checklist_not"
                      }
                      className="margin_right"
                    />
                    <p className="text_inferior inline">{item?.headerName}</p>
                  </div>
                );
              })}
            </>
          )}
          <br />

          {!field_rev?.key ? (
            <>
              <p className="text_inferior">
                Belum pilih kolom hasil perhitungan terakhir{" "}
                <b>setelah pengurangan</b>, silakan pilih salah satu kolom
                sebagai hasil perhitungan terakhir.
              </p>
            </>
          ) : (
            <>
              <p>Result:</p>
              <table className="table">
                <tbody>
                  <tr>
                    <td>min</td>
                    <td>
                      {new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(rev?.min)}
                    </td>
                  </tr>
                  <tr>
                    <td>max</td>
                    <td>
                      {new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(rev?.max)}
                    </td>
                  </tr>
                  <tr>
                    <td>Formula range</td>
                    <td>
                      <p>(1-(Nilai minimum/Nilai maksimum))*100%</p>
                      <br />
                      <p>
                        {
                          "Range 0% --> Tidak bisa diteruskan/informasi tidak sama"
                        }
                      </p>
                      <p>{"Range ≤15% --> Informasi data/bisa diteruskan"}</p>
                      <p>{"Range >15% --> Informasi data tidak bagus/ganti"}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>Hasil</td>
                    <td>{rev?.result} %</td>
                  </tr>
                  <tr>
                    <td>Kesimpulan</td>
                    <td>
                      <div className="badge_pill" id={rev?.color}>
                        {rev?.conclusion}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </section>

        <br />

        <section className="container_grey">
          <h1 className="table_header margin_bottom">
            Total Indikasi nilai tanah agunan
          </h1>
          <>
            <p className="text_inferior">Pilih kolom persen sisa</p>
            <button
              className="button margin_bottom"
              id="green"
              onClick={this.toggle_choose_percent}
            >
              Pilih
            </button>
          </>

          <p className="text_inferior">
            Field terpilih:
            <span className="badge_pill">{field_percent?.name}</span>
          </p>

          {modal_choose_percent && (
            <>
              {headers.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    className="margin_bottom"
                    onClick={this.on_edit_percent_value_field_key.bind(
                      this,
                      item.field
                    )}
                  >
                    <button
                      id={
                        percent_value_field_key === item.field
                          ? "checklist_active"
                          : "checklist_not"
                      }
                      className="margin_right"
                    />
                    <p className="text_inferior inline">{item?.headerName}</p>
                  </div>
                );
              })}
            </>
          )}
          <br />

          {!field_rev?.key ? (
            <>
              <p className="text_inferior">Belum pilih kolom persen dipakai.</p>
            </>
          ) : (
            <>
              <p className="text_inferior">Total Indikasi nilai tanah agunan</p>

              <table className="table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Nilai pembobotan</th>
                    <th>Nilai indikasi tanah setelah pembobotan</th>
                  </tr>
                </thead>
                <tbody>
                  {data_list.map((item, idx) => {
                    const nilai_pembobotan = item?.nilai_pembobotan;
                    const nilai_indikasi = item?.nilai_indikasi;

                    return (
                      <tr key={idx}>
                        <td>Data pembanding {idx + 1}</td>
                        <td>{(nilai_pembobotan * 100).toFixed(1)}%</td>
                        <td>
                          Rp.
                          {new Intl.NumberFormat("id-ID", {
                            style: "decimal",
                          }).format(nilai_indikasi.toFixed(0))}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan={2}>Total Indikasi nilai tanah agunan</td>
                    <td className="text_header">
                      {" "}
                      Rp.
                      {new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(total_indikasi.toFixed(0))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  map: state.map,
  project: state.project,
});

export default connect(mapStateToProps, {
  edit_final_calculation_field_key,
  edit_rev_calculation_field_key,
  edit_percent_value_field_key,
  editProperties,
  editPropertiesV2,
})(TableDataPembanding);
