/*LIBRARY MODULE*/
import { useEffect, useState } from "react";
import { FormatRupiah } from "@arismun/format-rupiah";
import { Popup } from "@urbica/react-map-gl";
import { useDispatch } from "react-redux";
import Pagination from "@mui/material/Pagination";
import { Link } from "react-router-dom";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
} from "@mui/material";

/*PERSONAL COMPONENT*/
import UploadImage from "../custom_cell/UploadImage";
import UploadFile from "../custom_cell/UploadFile";
import Modal from "../reuseable/Modal";

/*REDUX FUNCTION*/
import { get_genangan } from "../../App/actions/algoritma_genangan";
import {
  editProperties,
  editPropertiesV2,
} from "../../App/actions/layerActions";
import { set_value_properties } from "../../App/actions/propertiesActions";

/*PICTURE ASSET*/
import icon_full_screen_active from "../../Assets/svg/icon_full_screen_active.svg";
import icon_type_date from "../../Assets/svg/icon_type_date.svg";
import icon_download from "../../Assets/svg/icon_import.svg";

/*GENERAL*/
import { dot_every_3_digits } from "../../App/validation/format_number";
import { convertUnit } from "../../App/validation/geometry_to_area_or_length";
import format_date_formal from "../../App/validation/format_date_formal";
import dict from "../../Data/dict.json";
import { is_valid_url } from "../../App/validation/is_valid_url";
import { getFeatureByKey } from "../../App/reduxHelper/layer";
import { Table } from "../custom_cell";
import { Dropdown } from "../reuseable";
import is_not_empty from "../../App/validation/is_not_empty";

const WAIT_INTERVAL = 1000;

/**
 * Keterangan:
 *
 * PopupValue ini merupakan Component popup yang terdiri Title dan value, komponen ini TIDAK ADA fitur editor. Component ini akan muncul ketika component RenderPopupEditor berjalan.
 */
const PopupValue = (params) => {
  const language = useState("ina")[0];
  const { data, dataPopUP } = params;
  const { value, unit } = convertUnit(dataPopUP["_geometry"]?.value, data.unit);

  let final_render_value = dataPopUP[data?.key];
  if (!is_not_empty(final_render_value)) {
    final_render_value = "-";
  }

  return (
    <div className="valueNewPopup">
      {
        <>
          <p>{data?.name}</p>
          <div className="border_grey rounded_5 padding_10">
            {data?.type === "text" ? (
              <h3>{final_render_value}</h3>
            ) : data?.type === "textarea" ? (
              <h3 className="popup_textarea">{final_render_value}</h3>
            ) : data?.type === "number" ? (
              <h3>{final_render_value}</h3>
            ) : data?.type === "phone_number" ? (
              <h3>+{final_render_value}</h3>
            ) : data?.type === "currency" ? (
              <h3>
                <FormatRupiah value={final_render_value} />
              </h3>
            ) : data?.type === "year" ? (
              <h3>{final_render_value}</h3>
            ) : data?.type === "date" ? (
              <h3>{format_date_formal(final_render_value)}</h3>
            ) : data?.type === "time" ? (
              <h3>{final_render_value}</h3>
            ) : data?.type === "selection" ? (
              <h3>{final_render_value}</h3>
            ) : data?.type === "radio_button" ? (
              <h3>{final_render_value}</h3>
            ) : data?.type === "checklist" ? (
              <h3>{final_render_value?.split(" ")}</h3>
            ) : data?.type === "image" ? (
              <img
                src={dataPopUP[data.key]}
                alt={data?.name}
                className="photo_popup"
              />
            ) : data?.type === "document" ? (
              <a
                href={
                  final_render_value.includes("https://")
                    ? final_render_value
                    : `https://${final_render_value}`
                }
                className={`noselect rounded_5 padding_5 ${
                  final_render_value ? "bg_blue pointer" : "bg_grey"
                }`}
                style={{
                  color: final_render_value ? "white" : "black",
                }}
              >
                {final_render_value
                  ? dict["Download"][language]
                  : dict["No document"][language]}
              </a>
            ) : data?.type === "link" ? (
              <a
                href={
                  final_render_value.includes("https://")
                    ? final_render_value
                    : `https://${final_render_value}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {final_render_value}
              </a>
            ) : data?.type === "auto_address" ? (
              <h3>{final_render_value}</h3>
            ) : data?.type === "auto_address_poi" ? (
              <h3>{final_render_value}</h3>
            ) : data?.type === "table" ? (
              <h3>{final_render_value}</h3>
            ) : data?.type === "calculate_dimension" ? (
              <h3>{`${dot_every_3_digits(value)} ${unit}`}</h3>
            ) : (
              <h3>{final_render_value}</h3>
            )}
          </div>
        </>
      }
    </div>
  );
};

const render_like_text = ["text", "phone_number"];
const render_like_number = ["number", "currency", "year"];
const render_uneditable = [
  "kelurahan",
  "kecamatan",
  "kota",
  "provinsi",
  "auto_address",
  "auto_address_poi",
  "counting_poi",
  "counting_custom",
  "simple_referring",
  "2d_referring",
  "math_operators",
  "conditional_statement",
  "auto_generate_user_name",
];

/**
 * Keterangan:
 *
 * PopupFormEdit ini merupakan Component popup yang terdiri Title dan value, namun untuk kasus tertentu, value dapat diubah melalui komponen ini. Component ini akan muncul ketika component RenderPopupEditor berjalan.
 */
const PopupFormEdit = ({
  data,
  dataPopUP,
  is_mode_edit,
  on_change_callback,
  index,
  page,
  geo_layer,
  geo_layer_id,
}) => {
  const [timer, setTimer] = useState(null);
  const [textfield, set_textfield] = useState(dataPopUP[data?.key]);
  const language = useState(localStorage?.language || "ina")[0];

  const handle_checkbox =
    ({ value, field_key }) =>
    () => {
      const splitted_list = textfield?.split("\n") || [];

      const currentIndex = splitted_list?.indexOf(value);
      const newChecked = structuredClone(splitted_list);

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      set_textfield(newChecked.join("\n"));
      on_change_callback(field_key, newChecked.join("\n"));
    };

  const handle_stright_forward = (value, field_key) => {
    set_textfield(value);
    on_change_callback(field_key, value);
  };

  const handle_on_change_timer = (field_key, value) => {
    set_textfield(value);

    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      on_change_callback(field_key, value);
    }, WAIT_INTERVAL);

    setTimer(newTimer);
  };

  let content = <></>;
  if (render_like_text?.includes(data?.type)) {
    content = (
      <input
        className={`w_full ${is_mode_edit ? "bg_white" : "bg_lightGrey"}`}
        key={data?.key}
        type="text"
        value={textfield}
        onChange={(e) => {
          handle_on_change_timer(data?.key, e.target.value);
        }}
        disabled={!is_mode_edit}
      />
    );
  } else if (render_like_number?.includes(data?.type)) {
    content = (
      <input
        className={`w_full ${is_mode_edit ? "bg_white" : "bg_lightGrey"}`}
        key={data?.key}
        type="number"
        value={textfield}
        onChange={(e) => handle_on_change_timer(data?.key, e.target.value)}
        disabled={!is_mode_edit}
      />
    );
  } else if (data?.type === "textarea") {
    content = (
      <textarea
        className={`w_full ${
          is_mode_edit ? "bg_white" : "bg_lightGrey"
        } min_h_200 max_h_500`}
        key={data?.key}
        type="text"
        value={textfield}
        onChange={(e) => handle_on_change_timer(data?.key, e.target.value)}
        disabled={!is_mode_edit}
      />
    );
  } else if (data?.type === "range") {
    content = (
      <div>
        <label className="flex justify_end">{textfield || 0}</label>
        <input
          className="w_full"
          key={data?.key}
          type="range"
          value={textfield}
          onChange={(e) => handle_on_change_timer(data?.key, e.target.value)}
          disabled={!is_mode_edit}
          min={data?.min || 0}
          max={data?.max || 100}
          step={data?.step || 1}
        />
      </div>
    );
  } else if (data?.type === "date") {
    const converted_date = textfield ? format_date_formal(textfield) : "";
    content = (
      <main className="center_perfect w_full">
        <div
          className={`w_full h_full flex justify_between border_grey rounded_5 padding_5 ${
            is_mode_edit ? "bg_white" : "bg_lightGrey"
          }`}
        >
          <span title="DD-MM-YYYY">{converted_date}</span>
          {is_mode_edit && (
            <button className="datepicker-native-button bg_grey rounded_5 pointer button_inactive">
              <img alt="date" src={icon_type_date} className="w_15 h_15" />
              <input
                // id={`date-${params?.field}`}
                className="datepicker-native-button-input"
                value={textfield || ""}
                type="date"
                onChange={(e) =>
                  handle_stright_forward(e.target.value, data?.key)
                }
                disabled={!is_mode_edit}
              />
            </button>
          )}
        </div>
      </main>
    );
  } else if (data?.type === "time") {
    content = (
      <input
        className={is_mode_edit ? "bg_white" : "bg_lightGrey"}
        id="time_input"
        type="time"
        value={textfield || ""}
        onChange={(e) => handle_stright_forward(e.target.value, data?.key)}
        disabled={!is_mode_edit}
      />
    );
  } else if (data?.type === "checklist") {
    content = (
      <List style={{ paddingTop: 0, paddingBottom: 0, margin: 0 }}>
        {data?.array_templates?.map((item) => (
          <ListItem
            key={item?._id}
            value={item?._id}
            style={{ paddingTop: 0, paddingBottom: 0, margin: 0, height: 30 }}
            secondaryAction={
              // <>
              //   {is_mode_edit && (
              <Checkbox
                disabled={!is_mode_edit}
                onChange={handle_checkbox({
                  value: item?.name,
                  field_key: data?.key,
                })}
                checked={textfield?.includes(item?.name) || false}
              />
              //   )}
              // </>
            }
          >
            {item?.name}
          </ListItem>
        ))}
      </List>
    );
  } else if (data?.type === "radio_button") {
    content = (
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
          onChange={(e, value) => handle_stright_forward(value, data?.key)}
          value={textfield || ""}
        >
          <div className="flex flex_col">
            {data?.array_templates?.map((item) => (
              <FormControlLabel
                disabled={!is_mode_edit}
                key={item?._id}
                value={item?.name}
                control={
                  <Radio
                    style={{
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                  />
                }
                label={
                  <p className="text_black font_12 text_grey">{item?.name}</p>
                }
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  margin: 0,
                  wordBreak: "break-word",
                }}
              />
            ))}
            {!data?.array_templates?.some(
              (template) => template?.name === textfield
            ) &&
              is_not_empty(textfield) && (
                <FormControlLabel
                  disabled={!is_mode_edit}
                  value={textfield}
                  control={
                    <Radio
                      style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                    />
                  }
                  label={
                    <p className="text_black font_12 text_grey">{textfield}</p>
                  }
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    margin: 0,
                    wordBreak: "break-word",
                  }}
                />
              )}
          </div>
        </RadioGroup>
      </FormControl>
    );
  } else if (data?.type === "selection") {
    const merged_option = data?.array_templates?.map((item) => (
      <option key={item?.name} value={item?.name}>
        {<p className="text_black font_12">{item?.name}</p>}
      </option>
    ));

    if (
      !data?.array_templates?.some(
        (template) => template?.name === textfield
      ) &&
      is_not_empty(textfield)
    ) {
      merged_option.push(
        <option key={textfield} value={textfield}>
          {textfield}
        </option>
      );
    }

    if (is_mode_edit) {
      content = (
        <Dropdown
          placeholder={dict["Select"][language]}
          filter_on
          is_enable_empty
          value={textfield}
          onChange={(value) => {
            handle_stright_forward(value, data?.key);
          }}
        >
          {merged_option}
        </Dropdown>
      );
    } else {
      content = <div>{textfield ? textfield : "-"}</div>;
    }
  } else if (data?.type === "image") {
    content = (
      <div>
        <img
          src={textfield}
          style={{
            width: "100%",
            objectFit: "cover",
          }}
          alt=""
        />
        {is_mode_edit && (
          <div className="flex justify_end">
            <UploadImage
              imageUrl={textfield}
              geo_layer_id={geo_layer_id}
              feature_key={dataPopUP?.key}
              properties_key={data?.key}
              set_textfield={handle_stright_forward}
            />
          </div>
        )}
      </div>
    );
  } else if (data?.type === "document") {
    const fileNameOnServer = textfield?.split("/")?.at(-1);
    const arrayName = fileNameOnServer?.split("_%25");
    const extension = textfield?.split(".").at(-1);
    const originalName =
      arrayName?.at(0)?.split("_")?.at(1) === "doc"
        ? arrayName?.at(1)?.replaceAll("%20", " ") +
          (extension ? `.${extension}` : "")
        : "";
    content = (
      <div>
        {textfield ? (
          <div title={originalName}>
            <p>{originalName}</p>
          </div>
        ) : (
          <div
            style={{
              fontSize: "10px",
              width: 80,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 5,
            }}
          >
            {dict["No document"][language]}
          </div>
        )}

        <div className="flex justify_between">
          <div className="flex justify_end">
            {is_mode_edit && (
              <UploadFile
                imageUrl={textfield}
                geo_layer_id={geo_layer_id}
                feature_key={dataPopUP?.key}
                properties_key={data?.key}
                set_textfield={handle_stright_forward}
              />
            )}
          </div>
          <Link
            className="hover_darkGreen hover_bigger pointer"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "10px",
              width: 15,
              height: 15,
              backgroundColor: "#e6eef3",
              padding: "5px",
              border: "1px solid #9ca8bb",
              borderRadius: "99px",
            }}
            target="_blank"
            to={textfield}
          >
            <img
              alt="download"
              src={icon_download}
              className="h_15 w_15 rounded"
            />
          </Link>
        </div>
      </div>
    );
  } else if (data?.type === "link") {
    let href = textfield;
    if (is_valid_url(textfield)) {
      if (textfield?.includes("https://") || textfield?.includes("http://")) {
        // do nothing
      } else {
        href = `https://${textfield}`;
      }
    }
    content = is_mode_edit ? (
      <input
        className={`w_full`}
        key={data?.key}
        type="text"
        value={textfield}
        onChange={(e) => {
          handle_on_change_timer(data?.key, e.target.value);
        }}
        disabled={!is_mode_edit}
      />
    ) : (
      <a className="url" rel="noopener noreferrer" target="_blank" href={href}>
        {textfield || "-"}
      </a>
    );
  } else if (render_uneditable.includes(data?.type)) {
    content = <div>{textfield ? textfield : "-"}</div>;
  } else if (data?.type === "calculate_dimension") {
    const { unit } = convertUnit(textfield["_geometry"]?.value, data.unit);
    content = <div>{`${textfield} ${unit}`}</div>;
  } else if (data?.type === "table") {
    const feature = getFeatureByKey({
      geo_layer,
      feature_key: dataPopUP?.["key"],
    });
    content = (
      <Table
        layer_id={geo_layer_id}
        row={feature}
        column={data}
        is_from_first_gen_table={true}
      />
    );
  } else {
    content = <div>{textfield ? textfield : "-"}</div>;
  }

  return (
    <div className="flex w_full bold_border_grey rounded_5 padding_10">
      <p>{index + 1}.</p>
      <div className="w_full">
        <section className="flex justify_between">
          <p className="bold font_16 text_blue">{data?.name}</p>
          <p className="font_16 text_orange underline">
            {data?.type
              ?.split("_")
              .map((word) => word?.[0].toUpperCase() + word?.slice(1))
              .join(" ")}
          </p>
        </section>
        {content}
      </div>
    </div>
  );
};

/**
 * Keterangan
 *
 * RenderPopupEditor merupakan suatu component popup yang akan muncul ketika suatu geometry ditekan/diklik. Component ini memiliki 2 children. PopupFormEdit (bisa diedit) dan PopupValue (tidak bisa diedit). PopupFormEdit akan dirender hanya ketika mode fullscreen saja sehingga secara default akan merender PopupValue.
 */
const RenderPopupEditor = ({
  long,
  lat,
  fields,
  dataPopUP,
  type_2,
  feature_key,
  genangan_cm,
  geo_layer,
  is_editable,
}) => {
  const dispatch = useDispatch();
  const [modal_fullscreen, set_modal_fullscreen] = useState(false);
  const [is_mode_edit, set_is_mode_edit] = useState(false);
  const [data_popup, set_data_popup] = useState(dataPopUP);
  const [rendered_input_field, set_rendered_input_field] = useState([]);
  const [page, set_page] = useState(1);
  const [field_key_that_changed, set_field_key_that_changed] = useState("");

  useEffect(() => {
    let fieldsMap = render_list_attributes() || [];
    set_rendered_input_field(fieldsMap);
    // eslint-disable-next-line
  }, [data_popup, Object.keys(data_popup).length]);

  useEffect(() => {
    set_data_popup(dataPopUP);
    // eslint-disable-next-line
  }, [feature_key]);

  useEffect(() => {
    // merender semua data dari layer yang dipilih
    let fieldsMap = render_list_attributes() || [];
    set_rendered_input_field(fieldsMap);
    // eslint-disable-next-line
  }, [
    data_popup,
    modal_fullscreen,
    is_mode_edit,
    data_popup?.[field_key_that_changed],
  ]);

  const toggle_modal_fullscreen = () => {
    set_modal_fullscreen(!modal_fullscreen);
  };

  const on_change_page = (event, value) => {
    set_page(value);
  };

  const toggle_mode_edit = () => {
    set_is_mode_edit(!is_mode_edit);
  };

  const on_change_callback = (field_key, value) => {
    let new_data = structuredClone(data_popup);
    new_data[field_key] = value;

    submit(geo_layer?._id, dataPopUP?.key, field_key, value);

    set_data_popup({ ...new_data });
    set_field_key_that_changed(field_key);
  };

  const submit = (
    geo_layer_id,
    feature_key,
    properties_key,
    properties_value
  ) => {
    const body = {
      geo_layer_id,
      feature_key,
      properties_key,
      properties_value,
    };

    if (geo_layer?.architecture === "v2") {
      dispatch(editPropertiesV2(body));
    } else if (geo_layer?.architecture === "api") {
      // do nothing
    } else {
      dispatch(editProperties(body));
    }
  };

  const close_pop_up = () => {
    dispatch(
      set_value_properties({
        key: "modal_pop_up_layer",
        value: false,
      })
    );
    dispatch(
      set_value_properties({
        key: "popupInfo",
        value: null,
      })
    );
  };

  const render_list_attributes = () => {
    let fieldsMap = [];
    if (modal_fullscreen) {
      let index = 0;
      let subFieldMap = [];
      let section_counter = 0;
      for (const data of fields) {
        if (data.isStyle !== true && data?.type !== "section") {
          const element = (
            <div key={index} className="marginTop_5 marginBottom_5">
              <PopupFormEdit
                index={index}
                data={data}
                dataPopUP={data_popup}
                is_mode_edit={is_mode_edit}
                on_change_callback={on_change_callback}
                page={section_counter}
                geo_layer_id={geo_layer?._id}
                geo_layer={data?.type === "table" ? geo_layer : undefined}
              />
            </div>
          );
          index++;
          subFieldMap.push(element);
        } else if (data?.type === "section") {
          if (subFieldMap?.length > 0) {
            fieldsMap.push(subFieldMap);
          }
          const element = (
            <div
              key={`section-${index}`}
              className="marginTop_5 marginBottom_5"
            >
              <label className="font_30 bold">{`Section: ${data?.name}`}</label>
            </div>
          );

          subFieldMap = [element];
          section_counter++;
        }
      }

      if (subFieldMap?.length > 0) {
        fieldsMap.push(subFieldMap);
      }
    } else {
      let index = 0;
      fields.forEach((data) => {
        const element = (
          <div key={index}>
            {data.isStyle !== true && (
              <PopupValue
                data={data}
                dataPopUP={data_popup}
                feature_key={feature_key}
              />
            )}
          </div>
        );
        index++;
        fieldsMap.push(element);
      });
    }

    return fieldsMap;
  };

  const on_get_genangan = () => {
    const params = {
      feature_key,
      latitude: lat,
      longitude: long,
      genangan_cm,
    };
    dispatch(get_genangan(params));
  };

  const toggle_share = () => {
    dispatch(
      set_value_properties({
        key: "feature_will_be_shared_key",
        value: feature_key,
      })
    );
    dispatch(
      set_value_properties({
        key: "modal_share",
        value: true,
      })
    );
  };

  const modal_fullscreen_content = modal_fullscreen && (
    <Modal
      title={"Details"}
      modalSize="medium"
      id="modal_api_input"
      isOpen={modal_fullscreen}
      onClose={toggle_modal_fullscreen}
      content={
        <main className="marginRight_10 min_h_500 center_perfect">
          <div className="modal_content flex flex_col gap_10">
            {type_2 === "banjir" && (
              <>
                <button
                  className="button margin_bottom"
                  onClick={on_get_genangan}
                >
                  Luas genangan banjir
                </button>
                <button className="button margin_bottom" onClick={toggle_share}>
                  Share
                </button>
              </>
            )}
            {rendered_input_field?.length > 0 &&
              rendered_input_field?.[page - 1]}
          </div>
        </main>
      }
      footer={
        <footer className="flex justify_between padding_5 marginLeft_20 marginRight_20 border_top_grey w_full">
          <Pagination
            count={rendered_input_field?.length || 0}
            page={page}
            onChange={on_change_page}
            showFirstButton
            showLastButton
          />

          {is_editable && (
            <button
              onClick={toggle_mode_edit}
              className="bg_blue text_white button_inactive rounded_5 padding_5"
            >
              Edit : {is_mode_edit ? "ON" : "OFF"}
            </button>
          )}
        </footer>
      }
    ></Modal>
  );

  return (
    <>
      <Popup
        tipSize={7}
        anchor="bottom"
        longitude={long}
        latitude={lat}
        closeButton={false}
        closeOnClick={false}
      >
        <section className="flex justify_between">
          <button onClick={toggle_modal_fullscreen} className="pointer">
            <img
              alt="Fullscreen"
              src={icon_full_screen_active}
              className="h_20 w_20"
            />
          </button>

          <button
            onClick={close_pop_up}
            className="button_small background_red"
          >
            X
          </button>
        </section>

        <div className="w_full">
          <div className="newPopup">
            {type_2 === "banjir" && (
              <>
                <button
                  className="button margin_bottom"
                  onClick={on_get_genangan}
                >
                  Luas genangan banjir
                </button>
                <button className="button margin_bottom" onClick={toggle_share}>
                  Share
                </button>
              </>
            )}
            {rendered_input_field}
          </div>
        </div>
      </Popup>
      {modal_fullscreen_content}
    </>
  );
};

export default RenderPopupEditor;
