/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bbox } from "@turf/turf";

/*PERSONAL COMPONENT*/
import ModalFilter from "../common_modal/ModalFilter";
import Modal from "../common_modal/Modal";
import Login from "../auth/Login";
import Register from "../auth/Register";
import CreateProject from "../project_dashboard/CreateProject";

/*REDUX FUNCTION*/
import {
  reset_searchInside,
  searchInside,
  setLayer,
  setTabelStatus,
  setEditGeometry,
  setRealtimeStatus,
  set_value_layer,
} from "../../App/actions/layerActions";
import {
  view_layer,
  get_location_list,
  set_layer_id,
} from "../../App/actions/dataActions";
import {
  getDetailLayerById,
  attachLayer,
  createProjectNew,
} from "../../App/actions/layerNewActions";
import { getProjectList } from "../../App/actions/projectActions";
import {
  clone_layer,
  set_value_properties,
} from "../../App/actions/propertiesActions";
import { set_value_toolbox } from "../../App/actions/toolboxActions";

/*PICTURE ASSET*/
import icon_edit_properties from "../../Assets/svg/icon_edit_properties.svg";
import icon_edit_properties_white from "../../Assets/svg/icon_edit_properties_white.svg";
import icon_view from "../../Assets/svg/icon_view.svg";
import icon_view_white from "../../Assets/svg/icon_view_white.svg";
import icon_import from "../../Assets/svg/icon_import.svg";
import icon_search from "../../Assets/svg/icon_search_new.svg";

/*GENERAL*/
import dict from "../../Data/dict.json";
import isEmpty from "../../App/validation/is-empty";
import getStatusMember from "../../App/validation/get-status-member";
import uuid from "../../App/validation/uuid";

/*NON IMPORT*/
const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

class SearchBarNew extends Component {
  state = {
    search_params: "",
    layer_filtered: [],
    modal_search: false,
    geo_layer_id: "",
    geo_layer_name: "",
    layer_mode: false,
    search_params_layer: "",
    search_params_project: "",
    modal_choose_project: false,
    modal_import: false,
    login_status: true,
    create_project_status: false,
    project_id: "",
    project_link: "",
    project_filtered: [],
    import_progress: false,
    search_mode: "data",
    location_list: [],
    status_typing: false,
    input: true,
  };

  on_clone_layer = () => {
    this.setState({ import_progress: true });
    const { project_id } = this.state;
    const { geo_layer_list, layer_id } = this.props.layer;
    const { user } = this.props.auth;
    const geo_layer = geo_layer_list.find(
      (item) => item?.geo_layer?._id === layer_id
    )?.geo_layer;

    const geo_layer_id = geo_layer?._id;
    const new_name = geo_layer?.name;
    const geo_project_id = project_id;
    const user_id = user?._id;

    if (geo_layer_id && new_name && geo_project_id && user_id) {
      const body = {
        geo_layer_id,
        new_name,
        geo_project_id,
        user_id,
      };
      this.props.clone_layer(body);
    }
  };

  flyTo = async (feature) => {
    const geojson_filtered = { type: "FeatureCollection", features: [feature] };

    const { map } = this.props?.map;
    let [minLng, minLat, maxLng, maxLat] = bbox(geojson_filtered);
    if (map) {
      const padding = { top: 100, bottom: 100, left: 500, right: 500 };

      map?.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding,
          maxZoom: 14,
        }
      );

      this.props.set_value_layer({ key: "statistic_mode", value: "toolbox" });
      this.props.set_value_properties({
        key: "sidebar_right_status",
        value: true,
      });

      //toobox radius

      let { default_radius_km, default_radius_m, features_radius } =
        this.props.toolbox;
      let uuid_name = "uuid_radius";
      const name = `R-${features_radius.length + 1}`;
      feature.properties.uuid = uuid();
      feature.properties.name = name;
      //feature.properties.poi_name = poi_name;
      feature.properties.radius_km = default_radius_km;
      feature.properties.radius_m = default_radius_m;
      features_radius.push(feature);
      this.props.set_value_toolbox({
        key: "features_radius",
        value: features_radius,
      });

      this.props.set_value_toolbox({
        key: uuid_name,
        value: feature.properties.uuid,
      });
      this.props.set_value_toolbox({
        key: "mode_toolbox",
        value: "features_radius",
      });
      //
    }
  };

  on_get_location_list = async () => {
    const search_params = this.state.search_params;
    await this.props.get_location_list(search_params);
    this.setState({ location_list: this.props.layer.location_list });
  };

  toggleSearchMode = (e, whichButton) => {
    if (whichButton === "location") {
      this.setState({ search_mode: "location" });
    } else {
      this.setState({ search_mode: "data" });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.auth.isRegistered !== prevProps.auth.isRegistered) {
      this.setState({ login_status: true });
    }
    if (this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {
      const { isAuthenticated } = this.props.auth;
      if (isAuthenticated) {
        this.props.getProjectList();
      }
    }
    if (
      this.props.project.geo_project_list.length !==
        prevProps.project.geo_project_list.length &&
      this.props.project.geo_project_list.length > 0
    ) {
      this.setState({
        create_project_status: false,
        project_id: this.props.project.geo_project_list[0]._id,
        project_link: this.props.project.geo_project_list[0].link,
      });
    }
  }

  handleChangeProject = (e) => {
    e.preventDefault();
    const target = e.target;
    const value = target.value;
    const name = target.name;
    clearTimeout(this.timer);
    this.setState({
      [name]: value,
    });
    this.timer = setTimeout(this.triggerProject(), WAIT_INTERVAL);
  };

  handleKeyDownProject(e) {
    if (e.keyCode === ENTER_KEY) {
      this.triggerProject();
    }
  }

  triggerProject = () => {
    const { search_params_project } = this.state;
    const { geo_project_list } = this.props.project;
    const project_filtered = geo_project_list.filter(
      (e) =>
        e.name.toLowerCase().includes(search_params_project.toLowerCase()) ||
        e.description
          .toLowerCase()
          .includes(search_params_project.toLowerCase())
    );
    this.setState({ project_filtered });
  };

  handlePushProject = () => {
    this.setState({ import_progress: true });
    const { project_id } = this.state;
    const { layer_id } = this.props.layer;
    const geo_layer = {
      _id: layer_id,
    };
    const body = {
      geo_project_id: project_id,
      geo_layer,
    };
    this.props.attachLayer(body);
  };

  disableImportProgress = () => {
    this.setState({ import_progress: false });
  };

  onClickProject = (project_id, project_link) => {
    this.setState({ project_id, project_link });
  };

  toggleCreateProject = () => {
    this.setState({ create_project_status: !this.state.create_project_status });
  };

  toggle_import = (geo_layer_id) => {
    const { isAuthenticated } = this.props.auth;
    if (isAuthenticated) {
      this.props.getProjectList();
    }
    this.props.set_layer_id(geo_layer_id);
    this.setState({ import_progress: false });
    this.setState({ modal_import: !this.state.modal_import });
  };

  toggleLoginRegister = () => {
    this.setState({ login_status: !this.state.login_status });
  };

  activateLayer = (geo_layer_id, geo_layer_name) => {
    this.setState({ layer_mode: true, geo_layer_id, geo_layer_name });
  };

  deactivatedLayer = () => {
    this.setState({
      layer_mode: false,
      input: true,
      modal_search: false,
      search_params_layer: "",
    });
  };

  handleChangeLayer = (e) => {
    e.preventDefault();
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
  handleKeyDownLayer(e) {
    if (e.keyCode === ENTER_KEY) {
      this.triggerLayer();
    }
  }

  triggerLayer = () => {
    const { geo_layer_id, search_params_layer } = this.state;
    this.props.searchInside(geo_layer_id, search_params_layer);
  };

  resetSearch = () => {
    const { geo_layer_id } = this.state;
    this.props.reset_searchInside(geo_layer_id);
  };

  handleChange = (e) => {
    e.preventDefault();
    if (this.state.status_typing === false) {
      this.setState({ status_typing: true });
    }
    const target = e.target;
    const value = target.value;
    const name = target.name;

    clearTimeout(this.timer);
    this.setState({
      [name]: value,
      isSearching: true,
    });
    this.timer = setTimeout(this.triggerChange.bind(this), WAIT_INTERVAL);
  };

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.triggerChange.bind(this);
    }
  }

  handleOnMouseDown(e) {
    e.preventDefault();
  }

  triggerChange = () => {
    const { search_params } = this.state;
    const { geo_layer_list } = this.props.layer;
    this.setState({ status_typing: false });
    if (this.state.search_mode === "data") {
      const layer_filtered = geo_layer_list.filter(
        (l) =>
          l?.geo_layer?.name
            .toLowerCase()
            .includes(search_params.toLowerCase()) ||
          l?.geo_layer?.description
            .toLowerCase()
            .includes(search_params.toLowerCase())
      );
      this.setState({ layer_filtered });
    } else if (this.state.search_mode === "location") {
      this.on_get_location_list();
    }
  };

  toggleModal = () => {
    this.setState({ modal_search: true });
    document.getElementById("filter_layer").focus();
  };

  closeSearch = () => {
    this.setState({
      modal_search: false,
      location_list: [],
      search_params: "",
    });
  };

  toggleSearch = () => {
    this.setState({ modal_search: !this.state.modal_search }, () => {
      if (!this.state.modal_search) {
        document.getElementById("filter_layer").blur();
      }
    });
  };

  toggleEditProperty = (geo_layer_item) => {
    const { tableStatus, layer_id } = this.props.layer;
    const { geo_project } = this.props.project;
    //Jika layer yang diklik bukanlah layer yang sedang aktif--> aktifkan layer
    if (layer_id !== geo_layer_item._id) {
      //Jika layer belum memiliki geojson, ambil geojson via API
      if (
        geo_layer_item?.geojson?.features?.length === 0 ||
        !geo_layer_item?.geojson?.features
      ) {
        this.props.setTabelStatus(true);
        //kemungkinan 1: belum ada geojson, ambil geojson dulu, kemudian aktifkan view
        if (geo_layer_item?.type_2 === "pch") {
          this.props.get_pch(geo_layer_item);
        } else if (geo_layer_item?.type_2 === "pda") {
          this.props.get_pda(geo_layer_item);
        } else {
          const body = {
            geo_layer_id: geo_layer_item._id,
            geo_project_id: geo_project._id,
            tableStatus: true,
          };
          this.props.getDetailLayerById(body);
        }
      } else {
        //Jika layer sudah memiliki geojson --> aktifkan layer tanpa mengambil geojson lagi
        this.props.set_layer_id(geo_layer_item._id);
        this.props.setTabelStatus(true);
      }
    } else {
      //Jika layer yang diklik adalah layer yang sedang aktif
      //Jika layer awalnya kosong, coba cek ke API lagi, barangkali sekarang ada isinya
      if (
        geo_layer_item?.geojson?.features?.length === 0 ||
        !geo_layer_item?.geojson?.features
      ) {
        this.props.setTabelStatus(!tableStatus);
        if (geo_layer_item?.type_2 === "pch") {
          this.props.get_pch(geo_layer_item);
        } else if (geo_layer_item?.type_2 === "pda") {
          this.props.get_pda(geo_layer_item);
        } else {
          const body = {
            geo_layer_id: geo_layer_item._id,
            geo_project_id: geo_project._id,
            tableStatus: !tableStatus,
          };
          this.props.getDetailLayerById(body);
        }
      } else {
        this.props.set_layer_id(geo_layer_item._id);
        this.props.setTabelStatus(!tableStatus);
      }
    }
  };

  onViewLayer = (geo_layer_item, value) => {
    //proses DB
    let { geo_layer } = this.props.layer;
    geo_layer = geo_layer?.geo_layer;
    if (
      geo_layer?._id !== geo_layer_item?.geo_layer?._id &&
      isEmpty(geo_layer_item?.geo_layer?.geojson)
    ) {
      const { geo_project } = this.props.project;
      const body = {
        geo_layer_id: geo_layer_item.geo_layer._id,
        geo_project_id: geo_project._id,
      };
      this.props.getDetailLayerById(body);
    } else {
      const body = {
        geo_layer_id: geo_layer_item.geo_layer._id,
        value,
      };
      this.props.view_layer(body);
    }
    //update state
    const { search_params } = this.state;
    const { geo_layer_list } = this.props.layer;
    const new_geo_layer_list = geo_layer_list.slice(); //clone state
    const geo_layer_id = geo_layer_item.geo_layer._id;
    const new_geo_layer = new_geo_layer_list.filter(
      (l) => l.geo_layer._id === geo_layer_id
    )[0];
    const viewStatus = !new_geo_layer.geo_layer.viewStatus; //toggle viewStatus
    new_geo_layer.geo_layer.viewStatus = viewStatus;
    const index = new_geo_layer_list.findIndex(
      (l) => l.geo_layer._id === geo_layer_id
    ); //get index of the geo_layer in geo_layer_list
    new_geo_layer_list.splice(index, 1, new_geo_layer); //replace item
    const layer_filtered = new_geo_layer_list.filter(
      (l) =>
        l.geo_layer.name.toLowerCase().includes(search_params.toLowerCase()) ||
        l.geo_layer.description
          .toLowerCase()
          .includes(search_params.toLowerCase())
    );
    this.setState({ layer_filtered });
  };

  toggleRealtime = () => {
    let { layer_id } = this.props.layer;
    const { geo_project } = this.props.project;
    const body = {
      geo_layer_id: layer_id,
      geo_project_id: geo_project._id,
    };
    this.props.getDetailLayerById(body);
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const {
      search_params,
      modal_search,
      layer_filtered,
      layer_mode,
      search_params_layer,
      modal_import,
      login_status,
      project_id,
      project_link,
      create_project_status,
      import_progress,
      search_params_project,
      project_filtered,
      search_mode,
      location_list,
      input,
    } = this.state;

    //global props
    const { tableStatus, geo_layer_list, layer_id, loadingProcess } =
      this.props.layer;
    const { isRegistered, isAuthenticated } = this.props.auth;
    const { geo_project_list } = this.props.project;
    const { loading_item, loading_status, shared_link_check } =
      this.props.properties;

    //content
    const role = shared_link_check?.role;

    //get status user
    let is_author = false;

    if (isAuthenticated) {
      let userCurrentStatus;
      const { user } = this.props.auth;
      const { geo_project } = this.props.project;
      const groups = geo_project?.groups || [];
      if (!isEmpty(geo_project)) {
        userCurrentStatus = getStatusMember(groups, user?._id);
      }
      is_author =
        !!userCurrentStatus || !!(geo_project?.user?._id === user?._id);
    }

    const geo_layer = geo_layer_list.find(
      (item) => item?.geo_layer?._id === layer_id
    )?.geo_layer;

    let project_final = project_filtered;
    if (!search_params_project) {
      project_final = geo_project_list;
    }
    let layer_final = layer_filtered;
    if (!search_params) {
      layer_final = geo_layer_list;
    }
    let list_result = (
      <div>
        <section className="text_medium">
          {dict["Result"][language]}:{" "}
          <b>
            {this.state.search_mode === "data"
              ? layer_final.length
              : this.state.location_list?.length}{" "}
          </b>
        </section>
        <section
          className="text_inferior"
          style={{ paddingBottom: "10px", borderBottom: "7px solid #efefef" }}
        >
          {
            dict[
              "Filter layers by typing keywords that match name & description"
            ][language]
          }
        </section>

        {this.state.search_mode === "data" &&
          layer_final.map((layer) => {
            const geo_layer = layer?.geo_layer ? layer.geo_layer : {};
            const { _id, name, description, viewStatus } = geo_layer;
            return (
              <main key={_id} className="result_item">
                <section className="text_bold">{name}</section>
                <section
                  className="text_inferior"
                  style={{ maxHeight: "4em", overflow: "hidden" }}
                >
                  {description}
                </section>
                <section>
                  <button
                    onClick={this.toggleEditProperty.bind(this, geo_layer)}
                    className="buttonCenter margin_right"
                    style={{
                      background: `${
                        _id === layer_id && tableStatus
                          ? "#606f8dff"
                          : "#e5eefdff"
                      }`,
                    }}
                    title={dict["View Table"][language]}
                  >
                    <img
                      src={
                        _id === layer_id && tableStatus
                          ? icon_edit_properties_white
                          : icon_edit_properties
                      }
                      alt="edit properties"
                      height="18px"
                      style={{ margin: "auto" }}
                    />
                  </button>

                  {viewStatus ? (
                    <button
                      onClick={this.onViewLayer.bind(this, layer, false)}
                      className="buttonCenter margin_right"
                      style={{
                        background: "#606f8dff",
                      }}
                      title={dict["View Layer"][language]}
                    >
                      <img
                        src={icon_view_white}
                        alt="edit properties"
                        height="18px"
                        style={{ margin: "auto" }}
                      />
                    </button>
                  ) : (
                    <button
                      onClick={this.onViewLayer.bind(this, layer, true)}
                      className="buttonCenter margin_right"
                      style={{
                        background: "#e5eefdff",
                      }}
                      title={dict["View Layer"][language]}
                    >
                      <img
                        src={icon_view}
                        alt="edit properties"
                        height="18px"
                        style={{ margin: "auto" }}
                      />
                    </button>
                  )}

                  {(is_author ||
                    role === "viewer_download" ||
                    role === "editor") && (
                    <button
                      onClick={this.activateLayer.bind(this, _id, name)}
                      className="buttonCenter margin_right"
                      style={{
                        background: "#e5eefdff",
                      }}
                      title={dict["Search"][language]}
                    >
                      <img
                        src={icon_search}
                        alt="edit properties"
                        height="18px"
                        style={{ margin: "auto" }}
                      />
                    </button>
                  )}

                  {(is_author ||
                    role === "viewer_download" ||
                    role === "editor") && (
                    <button
                      onClick={this.toggle_import.bind(
                        this,
                        layer?.geo_layer?._id
                      )}
                      className="buttonCenter margin_right"
                      style={{
                        background: "#e5eefdff",
                      }}
                      title={dict["Import"][language]}
                    >
                      <img
                        src={icon_import}
                        alt="edit properties"
                        height="18px"
                        style={{ margin: "auto" }}
                      />
                    </button>
                  )}
                </section>
              </main>
            );
          })}

        {this.state.search_mode === "location" &&
          this.state.location_list.map((item) => {
            const { id, place_name } = item;
            return (
              <main
                key={id}
                onClick={() => {
                  this.flyTo(item);
                  this.closeSearch();
                }}
                className="result_item"
              >
                <section className="text_bold">{place_name}</section>
              </main>
            );
          })}
      </div>
    );

    if (!search_params && search_mode === "location") {
      list_result = <div></div>;
    } else if (
      (search_params &&
        !this.state.status_typing &&
        search_mode === "data" &&
        layer_filtered.length === 0) ||
      (search_mode === "location" && location_list.length === 0)
    ) {
      list_result = <div>{dict["not found"][language]}</div>;
    }

    const search_result_content = modal_search && (
      <ModalFilter
        id="modal"
        isOpen={modal_search}
        onClose={(e) => {
          this.toggleSearch(e);
          this.setState({ location_list: [], search_params: "" });
        }}
      >
        <div>
          <section
            style={{
              width: "140px",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <button
              id={
                this.state.search_mode === "data"
                  ? "outline_blue"
                  : "outline_white"
              }
              className="button_pill_tight margin_right"
              onClick={(event) => {
                this.toggleSearchMode(event, "data");
              }}
            >
              Data
            </button>
            <button
              id={
                this.state.search_mode === "location"
                  ? "outline_blue"
                  : "outline_white"
              }
              className="button_pill_tight"
              onClick={(event) => {
                this.toggleSearchMode(event, "location");
              }}
            >
              Location
            </button>
          </section>
          {list_result}
        </div>
      </ModalFilter>
    );

    let modal_import_item = (
      <main>
        <section className="inline_block margin_bottom margin_right container_light">
          <div className="button_pill_normal margin_bottom" id="blue_light">
            {dict["From"][language]}
          </div>
          <p className="text_bold">Data layer yang akan diimport:</p>

          <div className="badge_circle margin_right" id="checklist_active" />
          <p className="text_medium inline">{geo_layer?.name}</p>
        </section>

        <section
          className="inline_block container_light margin_bottom"
          style={{ overflowY: "hidden" }}
        >
          <div className="button_pill_normal margin_bottom" id="blue_light">
            {dict["To"][language]}
          </div>
          <p className="text_bold">
            Pilih lokasi proyek penyimpanan data layer:
          </p>
          <input
            id="search_params_project"
            name="search_params_project"
            type="text"
            value={search_params_project}
            onChange={this.handleChangeProject}
            onKeyDown={this.handleKeyDownProject}
            placeholder={
              dict["Filter list of project by name or description"][language]
            }
            autoComplete="off"
            style={{
              marginTop: "5px",
            }}
          />

          <div
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              height: "calc(( 85vh - 390px ))",
              minHeight: "100px",
            }}
          >
            {project_final.map(({ _id, name, link }, idx) => {
              const item_id = project_id === _id ? "" : _id;
              const item_link = project_id === _id ? "" : link;
              return (
                <main
                  key={idx}
                  onClick={this.onClickProject.bind(this, item_id, item_link)}
                  className="margin_bottom"
                >
                  <button className="two_container_auto">
                    <div
                      className="badge_circle margin_right"
                      id={
                        project_id === _id
                          ? "checklist_active"
                          : "checklist_not"
                      }
                    />
                    <p className="text_medium text_left">{name}</p>
                  </button>
                </main>
              );
            })}
          </div>

          <p className="text_bold margin_top">Atau buat proyek baru:</p>
          <button
            onClick={this.toggleCreateProject}
            className="button margin_bottom"
          >
            Buat proyek baru
          </button>
        </section>
        <br />

        <main style={{ display: "grid" }}>
          {project_id ? (
            <section style={{ margin: "auto" }}>
              <div className="inline_block container_light margin_right margin_bottom">
                <button
                  onClick={this.on_clone_layer}
                  className="button margin_bottom"
                >
                  Impor layer
                </button>
                <p className="text_inferior text_left">
                  {dict?.["import_definition"]?.[language]}
                </p>
              </div>

              <div className="inline_block container_light margin_bottom">
                <button
                  onClick={this.handlePushProject}
                  className="button margin_bottom"
                >
                  Lampirkan layer
                </button>
                <p className="text_inferior text_left">
                  {dict?.["attach_definition"]?.[language]}
                </p>
              </div>
            </section>
          ) : (
            <div className="button" id="red" style={{ margin: "auto" }}>
              {
                dict["Please select the storage destination project first"][
                  language
                ]
              }
            </div>
          )}
        </main>
      </main>
    );

    if (!isAuthenticated) {
      modal_import_item = (
        <main>
          {login_status || isRegistered ? <Login isNav={true} /> : <Register />}
          {login_status || isRegistered ? (
            <div style={{ display: "block", marginTop: "30px" }}>
              <div className="button_account">
                {dict["Don't have an account?"][language]}
              </div>
              <div
                className="button_register"
                onClick={this.toggleLoginRegister}
              >
                {dict["Register here"][language]}
              </div>
            </div>
          ) : (
            <div style={{ display: "block", marginTop: "30px" }}>
              <div className="button_account">
                {dict["Aready have an account?"][language]}
              </div>
              <div
                className="button_register"
                onClick={this.toggleLoginRegister}
              >
                {dict["Login instead"][language]}
              </div>
            </div>
          )}
        </main>
      );
    } else if (create_project_status) {
      modal_import_item = (
        <main>
          <button
            onClick={this.toggleCreateProject}
            className="button_very_small"
            id="blue"
          >
            ← {dict["Back"][language]}
          </button>
          <br />
          <CreateProject toggleCreateProject={this.toggleCreateProject} />
        </main>
      );
    } else if (import_progress) {
      modal_import_item = (
        <main>
          <h1 className="text_header margin_bottom">Proses impor</h1>

          {loading_status && loading_item === "clone_layer" ? (
            <div className="container_light margin_bottom">
              Mengimpor layer ke proyek Anda...
            </div>
          ) : (
            <div>
              <p className="container_light margin_bottom">Impor selesai</p>
              <Link
                to={`/editor/${project_link}`}
                className="button"
                target="_blank"
                rel="noopener noreferrer"
              >
                Edit Data Sekarang
              </Link>
            </div>
          )}
        </main>
      );
    }

    const modal_import_content = modal_import && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={modal_import}
        onClose={this.toggle_import}
      >
        <div className="box-body">
          <main
            style={{ minHeight: "80vh", overflowX: "hidden", display: "grid" }}
          >
            <div style={{ marginLeft: "auto", marginRight: "auto" }}>
              {modal_import_item}
            </div>
          </main>
        </div>
      </Modal>
    );

    const data_search = geo_layer_list.find(
      (d) => d.geo_layer?._id === geo_layer?._id
    );

    const data_length =
      data_search?.geo_layer?.geojson_filtered?.features?.length;

    return (
      <div style={{ display: `${loadingProcess ? "none" : ""}` }}>
        {!layer_mode ? (
          <main
            className={
              input
                ? "search_center_content_hide"
                : "search_center_content_show"
            }
          >
            <div
              onClick={() =>
                this.setState({ input: false, modal_search: true })
              }
              className="search_center_button"
            >
              <img src={icon_search} alt="Search" width={17} />
              <input
                className="search_center_input"
                id="filter_layer"
                type="text"
                name="search_params"
                value={search_params}
                onChange={this.handleChange.bind(this)}
                onKeyDown={this.handleKeyDown.bind(this)}
                onFocus={this.toggleModal}
                placeholder={
                  input
                    ? dict["Search"][language]
                    : dict["Filter list of data by name or description"][
                        language
                      ]
                }
                autoComplete="off"
              />
            </div>
            <button
              className="search_center_close"
              onClick={() =>
                this.setState({
                  input: !input,
                  modal_search: false,
                  search_params_layer: "",
                })
              }
            >
              X
            </button>
            {search_result_content}
          </main>
        ) : (
          <main>
            <section className="search_center_content_show">
              <input
                className="search_center_input"
                id="filter_layer"
                type="text"
                name="search_params_layer"
                value={search_params_layer}
                onChange={this.handleChangeLayer.bind(this)}
                onKeyDown={this.handleKeyDownLayer.bind(this)}
                placeholder={`${dict["Search data in layer"][language]} : ${geo_layer?.name}`}
                autoComplete="off"
              />
              <div
                className="search_center_close"
                onClick={this.deactivatedLayer}
              >
                X
              </div>
            </section>
            <div className="search_center_content_bottom">
              <div>
                <p>{dict["Layer name"][language]}</p>
                <h2 className="text_overhide">{geo_layer?.name}</h2>
              </div>
              {data_length !== undefined && data_length >= 0 ? (
                <div>
                  {data_length ? <p>{data_length} data</p> : <p>No data</p>}
                  <button
                    className="button"
                    id="red"
                    onClick={() => this.toggleRealtime()}
                  >
                    Reset
                  </button>
                </div>
              ) : null}
            </div>
          </main>
        )}
        {modal_import_content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  project: state.project,
  map: state.map,
  properties: state.properties,
  toolbox: state.toolbox,
});

export default connect(mapStateToProps, {
  reset_searchInside,
  searchInside,
  set_layer_id,
  setLayer,
  setTabelStatus,
  setEditGeometry,
  setRealtimeStatus,
  getDetailLayerById,
  attachLayer,
  getProjectList,
  createProjectNew,
  view_layer,
  get_location_list,
  clone_layer,
  set_value_layer,
  set_value_properties,
  set_value_toolbox,
})(SearchBarNew);
