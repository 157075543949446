//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function
import { upload_resi_paid_form } from "../../App/actions/paymentActions";

//Picture Asset

//General Function
import dict from "../../Data/dict.json";
import uuid from "../../App/validation/uuid";

class UploadBankTansfer extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fileInput = React.createRef();
  }

  handleSubmit(e) {
    e.preventDefault();

    const { layer_id } = this.props.layer;
    const { payment_methode, amounts_addition } = this.props;

    const file = this.fileInput.current.files[0];
    const body_file = {
      file: file,
      file_name: "resi_form_" + uuid(),
      dirname: "resi_form",
    };

    const body_database = {
      geo_layer_id: layer_id,
      payment_methode,
      amounts_addition,
    };

    const body = {
      body_file,
      body_database,
    };

    this.props.upload_resi_paid_form(body);
  }

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    return (
      <label className="fileContainer">
        <div className="button_big" id="green">
          {dict["Upload payment receipt"][language]}
        </div>
        <input
          style={{ cursor: "pointer" }}
          type="file"
          accept="image/*"
          ref={this.fileInput}
          onChange={this.handleSubmit}
        />
      </label>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
});

export default connect(mapStateToProps, {
  upload_resi_paid_form,
})(UploadBankTansfer);
