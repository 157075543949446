import styled from "styled-components";

const ModalNotification = styled.div`
  position: fixed;
  right: 0px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 0;
  z-index: 2000;
  width: 420px;
  max-width: 80vw;
  margin: auto;
  &.fade-in {
    bottom: 0px;
    right: 0px;
    opacity: 1;
    -webkit-transition: 0.1s;
    -moz-transition: 0.1s;
    -o-transition: 0.1s;
    transition: left 0.1s;
  }
  &.fade-out {
    bottom: 0px;
    right: -100px;
    opacity: 0;
    -webkit-transition: 0.1s;
    -moz-transition: 0.1s;
    -o-transition: 0.1s;
    transition: 0.1s;
  }
  .background {
    position: fixed;
    z-index: 1040;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    outline: 0;
  }
  .box-dialog {
    border-radius: 10px;
    z-index: 1050;
    width: 100%;
    background-color: ${props => {
      if (props.backgroundColor) {
        return props.backgroundColor;
      } else {
        return "#fff";
      }
    }};
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.071);
    .box-body {
      font-size: 14px;
      padding: 15px;
      max-height: calc((100vh - 270px));
      overflow-y: auto;
      overflow-x: auto;
    }
  }
`;

export default ModalNotification;
