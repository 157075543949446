import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
// import PaperComponent from "./PapperComponent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { DialogActions, DialogContent } from "@mui/material";

export default function DraggableDialog(props) {
  const {
    title,
    modalSize,
    id = "modal",
    isOpen,
    onClose,
    children,
    fullScreen,
    content,
    footer,
  } = props;
  const theme = useTheme();
  const media_query_fullscreen = useMediaQuery(theme.breakpoints.down("md"));
  const [size, setSize] = useState("sm");

  useEffect(() => {
    if (modalSize === "large") {
      setSize("xl");
    } else if (modalSize === "normal" || modalSize === "medium") {
      setSize("md");
    }
  }, [modalSize]);

  return (
    <Dialog
      id={id}
      fullScreen={fullScreen || media_query_fullscreen}
      fullWidth
      maxWidth={size}
      open={isOpen}
      onClose={onClose}
      aria-labelledby="draggable-dialog-title"
    >
      <header className="flex justify_between">
        <DialogTitle
        //  style={{ cursor: "move" }}
        //  id="draggable-dialog-title"
        >
          {title}
        </DialogTitle>
        <div
          className="margin_10"
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <button
            onClick={() => onClose()}
            aria-label="close"
            className="button h_35 w_35 font_20"
            id="delete"
          >
            x
          </button>
        </div>
      </header>
      {children && (
        <main className="marginBottom_20 marginLeft_20 marginRight_20">
          <section>{children}</section>
        </main>
      )}
      {content && <DialogContent>{content}</DialogContent>}
      {footer && <DialogActions>{footer}</DialogActions>}
    </Dialog>
  );
}
