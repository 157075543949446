/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer, Marker } from "@urbica/react-map-gl";
import circle from "@turf/circle";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class TOOLBOX_LAYER extends Component {
  state = {
    render_content: null,
  };

  componentDidMount() {
    this.on_render_content();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.toolbox.status_toolbox !== prevProps.toolbox.status_toolbox
    ) {
      this.on_render_content();
    }
  }

  on_render_content = () => {
    //local storage

    //local state

    //global props
    const {
      features_area,
      features_radius,
      features_elevation,
      features_elevation_point,
      features_distance,
      features_distance_label,

      features_distance_center,
      features_elevation_center,
      features_area_center,

      uuid_distance,
      uuid_area,
      uuid_elevation,
      uuid_radius,
    } = this.props.toolbox;

    //content
    const geojson_distance = {
      type: "FeatureCollection",
      features: features_distance,
    };
    const geojson_distance_label = {
      type: "FeatureCollection",
      features: features_distance_label,
    };
    const geojson_area = {
      type: "FeatureCollection",
      features: features_area,
    };
    const geojson_elevation = {
      type: "FeatureCollection",
      features: features_elevation,
    };
    const geojson_radius = {
      type: "FeatureCollection",
      features: features_radius,
    };
    const geojson_elevation_point = {
      type: "FeatureCollection",
      features: features_elevation_point,
    };

    const features_radius_polygon = features_radius.map((feature_point) => {
      const polygon = circle(
        feature_point,
        feature_point.properties.radius_km,
        {
          steps: 100,
          units: "kilometers",
          properties: feature_point.properties,
        }
      );
      return polygon;
    });

    const geojson_radius_polygon = {
      type: "FeatureCollection",
      features: features_radius_polygon,
    };

    const render_content = (
      <>
        <main className="features_area">
          <section>
            <Source
              key="geojson_area"
              id="geojson_area"
              data={geojson_area}
              type="geojson"
            />
            <Layer
              id="geojson_area"
              source="geojson_area"
              type="fill"
              paint={{
                "fill-color": "#00ff00",
                "fill-opacity": 0.2,
                "fill-outline-color": "#019371",
              }}
              layout={{
                visibility: "visible",
              }}
              before=""
            />
          </section>
          <section>
            <Source
              key="geojson_area_line"
              id="geojson_area_line"
              data={geojson_area}
              type="geojson"
            />
            <Layer
              id="geojson_area_line"
              source="geojson_area_line"
              type="line"
              paint={{
                "line-color": "#019371",
                "line-width": 2,
                "line-opacity": 1,
              }}
              layout={{
                visibility: "visible",
              }}
              before=""
            />
          </section>
          <section>
            {features_area_center.map((item, idx) => {
              return (
                <Marker
                  key={idx}
                  longitude={item.geometry.coordinates[0]}
                  latitude={item.geometry.coordinates[1]}
                  draggable={false}
                >
                  <div
                    className={`badge_pill_small ${
                      item.properties.uuid === uuid_area
                        ? "background_green"
                        : "background_blue"
                    }`}
                  >
                    A-{idx + 1}
                  </div>
                </Marker>
              );
            })}
          </section>
        </main>

        <main className="features_radius">
          <section>
            <Source
              key="geojson_radius_polygon"
              id="geojson_radius_polygon"
              data={geojson_radius_polygon}
              type="geojson"
            />
            <Layer
              id="geojson_radius_polygon"
              source="geojson_radius_polygon"
              type="fill"
              paint={{
                "fill-color": "#3d3f92",
                "fill-opacity": 0.2,
                "fill-outline-color": "#019371",
              }}
              layout={{
                visibility: "visible",
              }}
              before=""
            />
          </section>
          <section>
            <Source
              key="geojson_radius_line"
              id="geojson_radius_line"
              data={geojson_radius_polygon}
              type="geojson"
            />
            <Layer
              id="geojson_radius_line"
              source="geojson_radius_line"
              type="line"
              paint={{
                "line-color": "#0ca5eb",
                "line-width": 2,
                "line-opacity": 1,
              }}
              layout={{
                visibility: "visible",
              }}
              before=""
            />
          </section>
          <section>
            <Source
              key="geojson_radius"
              id="geojson_radius"
              data={geojson_radius}
              type="geojson"
            />
            <Layer
              id="geojson_radius"
              source="geojson_radius"
              type="circle"
              paint={{
                "circle-color": "#606f8d",
                "circle-radius": 3,
                "circle-stroke-width": 1,
                "circle-stroke-color": "#ffffff",
              }}
              layout={{
                visibility: "visible",
              }}
              before=""
            />
          </section>
          <section>
            {features_radius.map((item, idx) => {
              return (
                <Marker
                  key={idx}
                  longitude={item.geometry.coordinates[0]}
                  latitude={item.geometry.coordinates[1]}
                  draggable={false}
                >
                  <div
                    className={`badge_pill_small ${
                      item.properties.uuid === uuid_radius
                        ? "background_green"
                        : "background_blue"
                    }`}
                  >
                    R-{idx + 1}
                  </div>
                </Marker>
              );
            })}
          </section>
        </main>

        <main className="features_elevation">
          <section>
            <Source
              key="geojson_elevation"
              id="geojson_elevation"
              data={geojson_elevation}
              type="geojson"
            />
            <Layer
              id="geojson_elevation"
              source="geojson_elevation"
              type="line"
              paint={{
                "line-color": "#104585",
                "line-width": 2,
                "line-opacity": 1,
              }}
              layout={{
                visibility: "visible",
              }}
              before=""
            />
          </section>
          <section>
            <Source
              key="geojson_elevation_point"
              id="geojson_elevation_point"
              data={geojson_elevation_point}
              type="geojson"
            />
            <Layer
              id="geojson_elevation_point"
              source="geojson_elevation_point"
              type="circle"
              paint={{
                "circle-color": "#0ca5eb",
                "circle-radius": 3,
                "circle-stroke-width": 1,
                "circle-stroke-color": "#ffffff",
              }}
              layout={{
                visibility: "visible",
              }}
              before=""
            />
          </section>
          <section>
            {features_elevation_center.map((item, idx) => {
              return (
                <Marker
                  key={idx}
                  longitude={item.geometry.coordinates[0]}
                  latitude={item.geometry.coordinates[1]}
                  draggable={false}
                >
                  <div
                    className={`badge_pill_small ${
                      item.properties.uuid === uuid_elevation
                        ? "background_green"
                        : "background_blue"
                    }`}
                  >
                    E-{idx + 1}
                  </div>
                </Marker>
              );
            })}
          </section>
        </main>

        <main className="features_distance">
          <section>
            <Source
              key="geojson_distance"
              id="geojson_distance"
              data={geojson_distance}
              type="geojson"
            />
            <Layer
              id="geojson_distance"
              source="geojson_distance"
              type="line"
              paint={{
                "line-color": "#104585",
                "line-width": 2,
                "line-opacity": 1,
              }}
              layout={{
                visibility: "visible",
              }}
              before=""
            />
          </section>
          <section>
            <Source
              key="geojson_distance_label_background"
              id="geojson_distance_label_background"
              data={geojson_distance_label}
              type="geojson"
            />
            <Layer
              id="geojson_distance_label_background"
              source="geojson_distance_label_background"
              type="circle"
              paint={{
                "circle-color": "#ffffff",
                "circle-radius": 7,
                "circle-stroke-width": 1,
                "circle-stroke-color": "#000000",
              }}
              layout={{
                visibility: "visible",
              }}
              before=""
            />
          </section>
          <section>
            <Source
              key="geojson_distance_label"
              id="geojson_distance_label"
              data={geojson_distance_label}
              type="geojson"
            />
            <Layer
              id="geojson_distance_label"
              source="geojson_distance_label"
              type="symbol"
              layout={{
                "text-field": ["get", "name"],
                "text-size": 10,
                "icon-image": "circle",
                "icon-anchor": "center",
                visibility: "visible",
              }}
              before=""
            />
          </section>
          <section>
            {features_distance_center.map((item, idx) => {
              return (
                <Marker
                  key={idx}
                  longitude={item.geometry.coordinates[0]}
                  latitude={item.geometry.coordinates[1]}
                  draggable={false}
                >
                  <div
                    className={`badge_pill_small ${
                      item.properties.uuid === uuid_distance
                        ? "background_green"
                        : "background_blue"
                    }`}
                  >
                    D-{idx + 1}
                  </div>
                </Marker>
              );
            })}
          </section>
        </main>
      </>
    );

    this.setState({ render_content });
  };

  render() {
    return <div>{this.state.render_content}</div>;
  }
}

const mapStateToProps = (state) => ({
  toolbox: state.toolbox,
});

export default connect(mapStateToProps, {})(TOOLBOX_LAYER);
