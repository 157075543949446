//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
//Personal Component
//Redux function
import { setLayerWOgeojson } from "../../App/actions/layerActions";
//Picture Asset
//General Function
import formatDate from "../../App/validation/format-date";
import dict from "../../Data/dict.json";
class FormListRenderPaid extends Component {
  setLayerWOgeojson = (geo_layer) => {
    const content = {
      geo_layer: geo_layer,
    };
    this.props.setLayerWOgeojson(content);
  };
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { paid_form_list } = this.props.layer;
    const itemContent = (
      <main
        style={{
          display: "inline-block",
          verticalAlign: "top",
          textAlign: "center",
        }}
      >
        {paid_form_list.map((geo_layer, idx) => {
          const { _id, name, date, date_created, geo_project, link } =
            geo_layer;
          const geo_project_name = geo_project?._id
            ? geo_project.name
            : dict["project has been deleted"][language];
          return (
            <main
              key={idx}
              className="container_blog_card"
              id="white"
              style={{
                position: "relative",
                backgroundColor: "#fff",
                color: "#556573ff",
                height: "200px",
              }}
            >
              <main
                className="bold_normal"
                style={{ maxHeight: "104px", overflow: "hidden" }}
              >
                {name}
              </main>
              <section
                style={{
                  fontSize: "11px",
                  maxWidth: "100%",
                  maxHeight: "30px",
                  overflow: "hidden",
                }}
              >
                {dict["Project"][language]}: <b>{geo_project_name}</b>
              </section>
              <main
                style={{
                  fontSize: "11px",
                  position: "absolute",
                  bottom: "15px",
                  width: "calc(( 100% - 30px ))",
                }}
              >
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td>
                        {dict["Created at"][language]}:{" "}
                        <b>{formatDate(date_created)}</b>
                        <br />
                        {dict["Modified at"][language]}:{" "}
                        <b>{formatDate(date)}</b>
                      </td>
                      <td>
                        <Link
                          onClick={this.setLayerWOgeojson.bind(this, geo_layer)}
                          to={`/form_editor/${_id}`}
                          className="button_standard_tight"
                        >
                          {dict["Edit"][language]}
                        </Link>
                      </td>
                      <td>
                        <Link
                          onClick={this.setLayerWOgeojson.bind(this, geo_layer)}
                          to={`/view/data/${link}`}
                          className="button_standard_tight"
                        >
                          {dict["Result data"][language]}
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </main>
            </main>
          );
        })}
      </main>
    );
    return <div>{itemContent}</div>;
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
});
export default connect(mapStateToProps, { setLayerWOgeojson })(
  FormListRenderPaid
);
