//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

//Personal Component
import SpinnerWhite from "../common_spinner/SpinnerWhite";
import CheckBox from "../common_input/CheckBox";
import TextFieldGroupTight from "../common_input/TextFieldGroupTight";
import Modal from "../common_modal/Modal";

//Redux function
import { editProposeFormBeforePayment } from "../../App/actions/layerActions";

//Picture Asset
//General Function
import Kabupaten from "../../Data/Kabupaten.json";
import Provinsi from "../../Data/Provinsi.json";
import dict from "../../Data/dict.json";

const cities_array = Kabupaten.sort();
const provinces_array = Provinsi.sort();
const ENTER_KEY = 13;
const persen_admin = 30; //biaya admin 30 persen
const minimum_price = 8_000; //minimum biaya perdata

class EditBillingForm extends Component {
  state = {
    errors: {},
    per_insentif: minimum_price,
    quota: 100,
    isDateRestrict: false,
    date_start: moment(Date.now()).add(7, "day").format("yyyy-MM-DD"),
    date_end: moment(Date.now()).add(14, "day").format("yyyy-MM-DD"),
    minResponden: 1,
    maxResponden: 2,
    isProvince: false,
    isCity: false,
    provinces: [],
    cities: [],
    province: "",
    city: "",
    geo_layer_id: "",
    warning: false,
    warning_errors: [],
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    const { layer_id, geo_layer_list } = nextProps.layer;

    let geo_layer = geo_layer_list.find(
      (e) => e.geo_layer._id === layer_id
    )?.geo_layer;

    if (layer_id !== prevState.geo_layer_id) {
      const formPayment = geo_layer.formPayment;
      const {
        per_insentif,
        quota,
        isDateRestrict,
        date_start,
        date_end,
        minResponden,
        maxResponden,
        isProvince,
        isCity,
        provinces,
        cities,
      } = formPayment;
      return {
        geo_layer_id: layer_id,
        per_insentif,
        quota,
        isDateRestrict,
        date_start: moment(date_start).format("yyyy-MM-DD"),
        date_end: moment(date_end).format("yyyy-MM-DD"),
        minResponden,
        maxResponden,
        isProvince,
        isCity,
        provinces,
        cities,
      };
    } else return null;
  }
  toggleWarning = () => {
    this.setState({ warning: !this.state.warning });
  };
  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      if (e.target.name === "province") {
        const provinces = this.state.provinces;
        const province = e.target.value;
        provinces.push(province);
        const provinces_filter_double = provinces.filter(
          (value, index) => provinces.indexOf(value) === index
        );
        this.setState({ provinces: provinces_filter_double, province: "" });
      }
      if (e.target.name === "city") {
        const cities = this.state.cities;
        const city = e.target.value;
        cities.push(city);
        const cities_filter_double = cities.filter(
          (value, index) => cities.indexOf(value) === index
        );
        this.setState({ cities: cities_filter_double, city: "" });
      }
    }
  }
  onInput = (e) => {
    if (e.target.name === "province") {
      const val = document.getElementById("provinces").value;
      const opts = document.getElementById("provinces_list").childNodes;
      for (let i = 0; i < opts.length; i++) {
        if (opts[i].value === val) {
          const provinces = this.state.provinces;
          const province = e.target.value;
          provinces.push(province);
          const provinces_filter_double = provinces.filter(
            (value, index) => provinces.indexOf(value) === index
          );
          this.setState({ provinces: provinces_filter_double }, () => {
            this.setState({ province: "" });
          });
        }
      }
    }
    if (e.target.name === "city") {
      const val = document.getElementById("cities").value;
      const opts = document.getElementById("cities_list").childNodes;
      for (let i = 0; i < opts.length; i++) {
        if (opts[i].value === val) {
          const cities = this.state.cities;
          const city = e.target.value;
          cities.push(city);
          const cities_filter_double = cities.filter(
            (value, index) => cities.indexOf(value) === index
          );
          this.setState({ cities: cities_filter_double }, () => {
            this.setState({ city: "" });
          });
        }
      }
    }
  };
  onDeleteProvince = (province) => {
    const provinces = this.state.provinces;
    const index = provinces.indexOf(province);
    provinces.splice(index, 1);
    this.setState({ provinces });
  };
  onDeleteCity = (city) => {
    const cities = this.state.cities;
    const index = cities.indexOf(city);
    cities.splice(index, 1);
    this.setState({ cities });
  };
  onChangeToggle = (e) => {
    this.setState({ [e.target.name]: !this.state[e.target.name] });
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = (e) => {
    e.preventDefault();
    //State
    const {
      geo_layer_id,
      per_insentif,
      quota,
      isDateRestrict,
      date_start,
      date_end,
      minResponden,
      maxResponden,
      isProvince,
      isCity,
      provinces,
      cities,
    } = this.state;
    const body = {
      geo_layer_id,
      per_insentif,
      quota,
      isDateRestrict,
      date_start,
      date_end,
      minResponden,
      maxResponden,
      isProvince,
      isCity,
      provinces,
      cities,
    };
    this.props.editProposeFormBeforePayment(body);
  };
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const {
      per_insentif,
      quota,
      isDateRestrict,
      date_start,
      date_end,
      minResponden,
      maxResponden,
      isProvince,
      isCity,
      province,
      city,
      provinces,
      cities,
      errors,
      warning,
      warning_errors,
    } = this.state;
    const price_pure = quota * per_insentif;
    const administration_price = parseInt((persen_admin / 100) * price_pure);
    const price = price_pure + administration_price;
    const decimal_price_pure = new Intl.NumberFormat("id-ID", {
      style: "decimal",
    }).format(price_pure);
    const decimal_administration_price = new Intl.NumberFormat("id-ID", {
      style: "decimal",
    }).format(administration_price);
    const decimal_price = new Intl.NumberFormat("id-ID", {
      style: "decimal",
    }).format(price);
    const decimal_amount = new Intl.NumberFormat("id-ID", {
      style: "decimal",
    }).format(quota);
    const decimal_insentif = new Intl.NumberFormat("id-ID", {
      style: "decimal",
    }).format(per_insentif);
    const { loadingProcess, itemLoading } = this.props.layer;
    let buttonContent;
    if (loadingProcess && itemLoading === "edit_propose_form_before_payment") {
      buttonContent = (
        <div className="button_block_tight">
          <SpinnerWhite
            width={30}
            unik="loading_item_list"
            marginTop="0px"
            center={true}
            border={0.1}
          />
        </div>
      );
    } else if (
      per_insentif < minimum_price ||
      minResponden <= 0 ||
      maxResponden <= 0 ||
      minResponden > maxResponden ||
      quota <= 0
    ) {
      if (per_insentif < minimum_price) {
        const ww = warning_errors.filter((e) => e.name === "insentif");
        if (ww.length === 0) {
          warning_errors.push({
            name: "insentif",
            text: `${dict["Incentives cannot be less than"][language]} Rp ${minimum_price}`,
          });
        }
      }
      if (minResponden < 1) {
        const ww = warning_errors.filter((e) => e.name === "min");
        if (ww.length === 0) {
          warning_errors.push({
            name: "min",
            text: dict["Minimum responden cannot be less than 1"][language],
          });
        }
      }
      if (maxResponden < 1) {
        const ww = warning_errors.filter((e) => e.name === "max");
        if (ww.length === 0) {
          warning_errors.push({
            name: "max",
            text: dict["Maximum responden cannot be less than 1"][language],
          });
        }
      }
      if (minResponden > maxResponden) {
        const ww = warning_errors.filter((e) => e.name === "minmax");
        if (ww.length === 0) {
          warning_errors.push({
            name: "minmax",
            text: dict[
              "Maximum responden cannot be less than minimum responden"
            ][language],
          });
        }
      }
      if (quota < 1) {
        const ww = warning_errors.filter((e) => e.name === "quota");
        if (ww.length === 0) {
          warning_errors.push({
            name: "quota",
            text: dict["Quota data cannot be less than 1"][language],
          });
        }
      }
      buttonContent = (
        <div onClick={this.toggleWarning} className="button_block" id="grey">
          {dict["Apply Now"][language]}
        </div>
      );
    } else {
      buttonContent = (
        <button className="button_block" type="submit">
          {dict["Apply Now"][language]}
        </button>
      );
    }
    const itemContent = (
      <div>
        <form noValidate autoComplete="off" onSubmit={this.onSubmit}>
          <div className="text_bold" style={{ marginBottom: "13px" }}>
            {dict["Edit Survey Preference"][language]}
          </div>
          <div className="two_container_equal">
            <div>
              <div className="text_medium">{dict["Preferences"][language]}</div>
              <div className="container_grey">
                {dict["Area preferences"][language]}
                <div>
                  <CheckBox
                    text="isProvince"
                    title={dict["Province"][language]}
                    value={isProvince}
                    handle={this.onChangeToggle.bind(this)}
                  />
                  {isProvince ? (
                    <div>
                      <input
                        type="text"
                        placeholder={dict["Select provinces"][language]}
                        autoComplete="off"
                        name="province"
                        value={province}
                        onKeyDown={this.handleKeyDown.bind(this)}
                        onChange={this.onChange.bind(this)}
                        onInput={this.onInput.bind(this)}
                        list="provinces_list"
                        id="provinces"
                      />
                      <datalist id="provinces_list">
                        {provinces_array.map((province_element, idx) => {
                          return <option value={province_element} key={idx} />;
                        })}
                      </datalist>
                    </div>
                  ) : (
                    <input disabled />
                  )}
                  {provinces.map((element, idx) => {
                    return (
                      <div key={idx} className="badge_bold">
                        {element}{" "}
                        <div
                          onClick={this.onDeleteProvince.bind(this, element)}
                          className="button_pill_tight"
                        >
                          x
                        </div>
                      </div>
                    );
                  })}
                  <CheckBox
                    text="isCity"
                    title={dict["City"][language]}
                    value={isCity}
                    handle={this.onChangeToggle.bind(this)}
                  />
                  {isCity ? (
                    <div>
                      <input
                        type="text"
                        placeholder={dict["Select cities"][language]}
                        autoComplete="off"
                        name="city"
                        value={city}
                        onKeyDown={this.handleKeyDown.bind(this)}
                        onChange={this.onChange.bind(this)}
                        onInput={this.onInput.bind(this)}
                        list="cities_list"
                        id="cities"
                      />
                      <datalist id="cities_list">
                        {cities_array.map((city_element, idx) => {
                          return <option value={city_element} key={idx} />;
                        })}
                      </datalist>
                    </div>
                  ) : (
                    <input disabled />
                  )}
                  {cities.map((element, idx) => {
                    return (
                      <div key={idx} className="badge_bold">
                        {element}
                        <div
                          onClick={this.onDeleteCity.bind(this, element)}
                          className="button_pill_tight"
                        >
                          x
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="container_grey" style={{ marginTop: "10px" }}>
                {dict["Time range"][language]}
                <CheckBox
                  text="isDateRestrict"
                  title={dict["Limit the survey time range"][language]}
                  value={isDateRestrict}
                  handle={this.onChangeToggle.bind(this)}
                />
                {isDateRestrict ? (
                  <div className="two_container_equal">
                    <TextFieldGroupTight
                      type="date"
                      placeholder={dict["Start date"][language]}
                      name="date_start"
                      id="date_start"
                      value={date_start}
                      onChange={this.onChange}
                      error={errors.date_start}
                    />
                    <TextFieldGroupTight
                      type="date"
                      placeholder={dict["End date"][language]}
                      name="date_end"
                      id="date_end"
                      value={date_end}
                      onChange={this.onChange}
                      error={errors.date_end}
                    />
                  </div>
                ) : (
                  <div className="two_container_equal">
                    <TextFieldGroupTight
                      type="date"
                      placeholder={dict["Start date"][language]}
                      name="date_start"
                      id="date_start"
                      value={date_start}
                      onChange={this.onChange}
                      error={errors.date_start}
                      disabled={true}
                    />
                    <TextFieldGroupTight
                      type="date"
                      placeholder={dict["End date"][language]}
                      name="date_end"
                      id="date_end"
                      value={date_end}
                      onChange={this.onChange}
                      error={errors.date_end}
                      disabled={true}
                    />
                  </div>
                )}
              </div>
              <div className="container_grey" style={{ marginTop: "10px" }}>
                {dict["Surveyor preference"][language]}
                <TextFieldGroupTight
                  type="number"
                  placeholder={
                    dict["Minimum respondents for one surveyor"][language]
                  }
                  name="minResponden"
                  id="minResponden"
                  value={minResponden}
                  onChange={this.onChange}
                  error={errors.minResponden}
                />
                <TextFieldGroupTight
                  type="number"
                  placeholder={
                    dict["Maximum respondents for one surveyor"][language]
                  }
                  name="maxResponden"
                  id="maxResponden"
                  value={maxResponden}
                  onChange={this.onChange}
                  error={errors.maxResponden}
                />
              </div>
            </div>
            <div>
              <div className="text_medium">{dict["Budgeting"][language]}</div>
              <div className="container_light">
                <TextFieldGroupTight
                  type="number"
                  placeholder={dict["Survey quota"][language]}
                  name="quota"
                  id="quota"
                  value={quota}
                  onChange={this.onChange}
                  error={errors.quota}
                />
                <TextFieldGroupTight
                  type="number"
                  placeholder={dict["Incentives per one data"][language]}
                  name="per_insentif"
                  id="per_insentif"
                  value={per_insentif}
                  onChange={this.onChange}
                  error={errors.per_insentif}
                />
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td>{dict["Survey cost"][language]}</td>
                      <td style={{ textAlign: "right" }}>
                        Rp {decimal_insentif} x {decimal_amount}:
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {" "}
                        Rp <b>{decimal_price_pure}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>{dict["Administrative costs"][language]}</td>
                      <td style={{ textAlign: "right" }}>{persen_admin}%:</td>
                      <td style={{ textAlign: "right" }}>
                        Rp <b>{decimal_administration_price}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr />
                <div className="two_container_auto">
                  <div>{dict["Total"][language]}</div>
                  <div style={{ textAlign: "right" }}>
                    Rp <b>{decimal_price}</b>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "30px" }}>{buttonContent}</div>
            </div>
          </div>
        </form>
      </div>
    );
    const modal_warning = warning && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={warning}
        onClose={this.toggleWarning}
      >
        <div className="box-body">
          <ul>
            {warning_errors.map((e, idx) => {
              return (
                <li key={idx} className="text_medium">
                  {e.text}
                </li>
              );
            })}
          </ul>
        </div>
      </Modal>
    );
    return (
      <div style={{ minHeight: "75vh" }}>
        {itemContent}
        {modal_warning}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  payment: state.payment,
  layer: state.layer,
});
export default connect(mapStateToProps, { editProposeFormBeforePayment })(
  EditBillingForm
);
