//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { readString } from "react-papaparse";

//Personal Component
import SpinnerSimpleBlue from "../common_spinner/SpinnerSimpleBlue";
import ModalComponent from "../common_modal/Modal";
import SearchLayerJoin from "./SearchLayerInJoin";

//Redux function
import {
  get_features_by_layer_id_without_reducer,
  importLayer,
} from "../../App/actions/layerNewActions";

//Picture Asset

//General Function
import isEmpty from "../../App/validation/is-empty";
import uuid from "../../App/validation/uuid";
import dict from "../../Data/dict.json";
import {
  generateFields,
  select_field_from_default_props,
} from "../../App/validation/generateGeoJson";
import { color_setup } from "../../App/validation/geojson_properties";
const lastIndex = 5;
const confirmIndex = 4;
/**
 * text_bold : Title
 * text_inferior : Description title
 */

class JoinTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      header: [],
      csvData: [],
      modalStatus: false,
      indexModal: 0,
      colSelected: "",
      colImportSelected: "",
      dataSelected: "",
      layerName: "",
      isSuccess: false,
      successRow: 0,
      isLoading: false,
      notFoundFeatures: [],
      notFoundCSV: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.openWizard = this.openWizard.bind(this);
    this.closeWizard = this.closeWizard.bind(this);
    this.increaseIndex = this.increaseIndex.bind(this);
    this.decreaseIndex = this.decreaseIndex.bind(this);
    this.generateNewLayer = this.generateNewLayer.bind(this);
    this.setSelectedData = this.setSelectedData.bind(this);
    this.fileInput = React.createRef();
  }

  componentDidMount() {
    const { _id } = this.props.auth.user;
    this.setState({ user_id: _id });
  }

  openWizard() {
    this.setState({ modalStatus: !this.state.modalStatus });
  }
  closeWizard() {
    this.setState({
      header: [],
      csvData: [],
      modalStatus: false,
      indexModal: 0,
      colSelected: "",
      colImportSelected: "",
      dataSelected: "",
      layerName: "",
      isSuccess: false,
      successRow: 0,
      isLoading: false,
      notFoundFeatures: [],
      notFoundCSV: [],
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const file = this.fileInput.current.files[0];
    const fr = new FileReader();
    fr.addEventListener("load", (file_event) => {
      const res = fr.result;
      const parseRes = readString(res);
      this.setState({ header: parseRes.data[0], csvData: parseRes.data });
    });
    fr.readAsText(file);
  }

  generateNewLayer = async () => {
    const {
      colSelected,
      dataSelected,
      user_id,
      csvData,
      header,
      layerName,
      colImportSelected,
    } = this.state;
    const { geo_project } = this.props.project;

    const params_body = {
      project_id: dataSelected?.geo_project._id,
      geo_layer_id: dataSelected?._id,
      architecture: dataSelected?.architecture,
    };

    const features = await this.props.get_features_by_layer_id_without_reducer(
      params_body
    );

    let colIndex = header.findIndex((itm) => itm === colSelected);
    let type = dataSelected?.type;
    csvData.shift();
    let notFoundFeatures = [];
    let notFoundCSV = [];

    let csvItem = csvData.map((dt) => String(dt[colIndex]).toLowerCase());

    let featuresItem = features.map((ft) =>
      String(ft.properties[colImportSelected]).toLowerCase()
    );

    features.forEach((ft) => {
      let item = ft.properties[colImportSelected];
      let searchRes = csvItem.includes(String(item).toLowerCase());
      if (!searchRes) {
        notFoundFeatures.push(ft.properties[colImportSelected]);
      }
    });

    csvData.forEach((dt) => {
      let searchRes = featuresItem.includes(String(dt[colIndex]).toLowerCase());
      if (!searchRes) {
        notFoundCSV.push(dt[colIndex]);
      }
    });

    let newFeatures = csvData
      .filter((dt) => {
        const itm = features.find(
          (ft) =>
            String(ft.properties[colImportSelected]).toLowerCase() ===
            String(dt[colIndex]).toLowerCase()
        );
        return !isEmpty(itm) ? true : false;
      })
      .map((dt) => {
        let originFeature = features.find(
          (ft) =>
            String(ft.properties[colImportSelected]).toLowerCase() ===
            String(dt[colIndex]).toLowerCase()
        );

        let newProps = {};
        header.forEach((h, idx) => {
          let generatedKey = h.replace(/ /g, "_");
          newProps[generatedKey] = dt[idx];
        });
        if (!isEmpty(originFeature)) {
          originFeature.properties = newProps;
        }

        return originFeature;
      })
      .filter((x) => x);

    let newGeojson = {
      type: "FeatureCollection",
      features: newFeatures,
    };

    this.setState({
      isSuccess: newFeatures.length > 1,
      successRow: newFeatures.length,
      notFoundFeatures,
      notFoundCSV,
    });

    if (newFeatures.length < 1) {
      this.increaseIndex();
      this.setState({ isLoading: false });
      return;
    }

    //Generate Fields
    let fields_ori = generateFields(header);

    const fields_default = select_field_from_default_props(type);

    let new_fields = fields_default.concat(fields_ori);

    // mencari field
    const cari_index = (field) =>
      new_fields.findIndex((field_element) => field_element.key === field.key);
    new_fields = new_fields.filter(
      (field, index) => cari_index(field) === index
    );

    //GENERATE GEOJSON
    let generatedGeojson = {};
    const generatedFeatures = newGeojson.features.map((feature, idx) => {
      const geometry_type = feature?.geometry?.type
        ? feature?.geometry?.type
        : "Polygon";

      const prop_default = color_setup({
        feature,
        geometry_type,
        idx,
      });

      const prop_ori = feature.properties;
      const properties = Object.assign(prop_default, prop_ori);
      return {
        key: uuid(),
        type: feature.type,
        properties,
        geometry: feature.geometry
          ? feature.geometry
          : { type: "Polygon", coordinates: [] },
        isHide: false,
      };
    });
    generatedGeojson.features = generatedFeatures;
    generatedGeojson.type = "FeatureCollection";

    const body = {
      //basic
      name: layerName,
      description: layerName,
      user_id,
      geo_project_id: geo_project.link,
      folder: "",
      geojson: generatedGeojson,
      type,
      fields: new_fields,
      properties: fields_default,
    };
    this.props.importLayer(body);
    this.setState({ isLoading: false });
    this.increaseIndex();
  };

  setSelectedData = (layer) => {
    this.setState({ dataSelected: layer });
  };
  increaseIndex = () => {
    this.setState({ indexModal: this.state.indexModal + 1 });
  };

  decreaseIndex = () => {
    this.setState({ indexModal: this.state.indexModal - 1 });
    this.setState({});
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const {
      modalStatus,
      header,
      csvData,
      indexModal,
      colSelected,
      dataSelected,
      layerName,
      isSuccess,
      successRow,
      colImportSelected,
      isLoading,
      /* eslint-disable */
      notFoundFeatures,
      notFoundCSV,
    } = this.state;
    const page0 = (
      <div>
        <div className="text_bold">{dict["Select File"][language]}</div>
        <div className="text_inferior" style={{ marginBottom: "10px" }}>
          {
            dict[
              "Please give your new layer a name, and select a .csv file to joined with public data on MAPID."
            ][language]
          }
        </div>
        <div>
          <input
            type="text"
            value={layerName}
            placeholder={dict["Layer name"][language]}
            onChange={(e) => {
              this.setState({ layerName: e.target.value });
            }}
          />
        </div>
        <div>
          <input
            type="file"
            accept=".csv"
            ref={this.fileInput}
            onChange={this.handleSubmit}
          />
        </div>
      </div>
    );
    const page1 = (
      <div>
        <div className="text_bold">{dict["Select column"][language]}</div>
        <div className="text_inferior" style={{ marginBottom: "10px" }}>
          {
            dict[
              "Please select a column from your .csv file to set it as a parameter."
            ][language]
          }
        </div>

        <div>
          {header.map((col, idx) => (
            <div key={`col-param-${idx}`}>
              <input
                type="radio"
                name="param"
                id={`col-param-${idx}`}
                checked={colSelected === col}
                className="normalInput"
                value={col}
                onChange={(e) => {
                  this.setState({ colSelected: e.target.value });
                }}
              />
              <label htmlFor={`col-param-${idx}`}>{col}</label>
            </div>
          ))}
        </div>
      </div>
    );

    //Pilih lyaer yang akan dijoin
    const page2 = (
      <div>
        <div className="box-body">
          <SearchLayerJoin selectData={this.setSelectedData} />
        </div>
      </div>
    );

    // Konfirmasi kolom yang dipilih dari data yang di Import
    const page3 = (
      <div>
        <div className="text_bold">{dict["Select column"][language]}</div>
        <div className="text_inferior" style={{ marginBottom: "10px" }}>
          {
            dict[
              "Please select a column from selected public data to set it as a parameter."
            ][language]
          }
        </div>
        {dataSelected &&
          dataSelected?.fields
            ?.filter((x) => !x.isStyle)
            .map(({ key, name }, idx) => (
              <div key={`col-param-selected-${idx}`}>
                <div>
                  <input
                    type="radio"
                    name="param"
                    id={`col-param-${idx}`}
                    checked={colImportSelected === key}
                    className="normalInput"
                    value={key}
                    onChange={(e) => {
                      this.setState({ colImportSelected: e.target.value });
                    }}
                  />
                  <label htmlFor={`col-param-${idx}`}>{name}</label>
                </div>
              </div>
            ))}
      </div>
    );

    const page4 = (
      <div>
        <div className="text_bold">{dict["Confirmation"][language]}</div>
        <div className="text_inferior" style={{ marginBottom: "10px" }}>
          {dict["Please check again your selected data and column."][language]}
        </div>
        <div>
          <table>
            <tbody>
              <tr>
                <td>{dict["Selected column"][language]}</td>
                <td>:</td>
                <td>{colSelected}</td>
              </tr>
              <tr>
                <td>{dict["Selected data"][language]}</td>
                <td>:</td>
                <td>{dataSelected.name}</td>
              </tr>
              <tr>
                <td>{dict["Selected public data column"][language]}</td>
                <td>:</td>
                <td>{colImportSelected}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div>
          <b>
            {
              dict["Are you sure want to generate data based that parameter?"][
                language
              ]
            }
          </b>
        </div>
      </div>
    );

    const page5 = isSuccess ? (
      <div>
        <div className="text_bold">{dict["Success"][language]}</div>
        <div>
          {dict["Layer is created."][language]}
          <br />
          {successRow} {dict["from"][language]} {csvData.length}{" "}
          {dict["row is generated."][language]}
          <br />
        </div>
        {notFoundCSV && (
          <div>
            {dict["Not generated features from CSV :"][language]}
            <div>
              <ul>
                {notFoundCSV.map((f, idx) => (
                  <li key={`not-found-${idx}`}>{f}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    ) : (
      <div>
        <div className="text_bold">{dict["Failed"][language]}</div>

        <div>
          {dict["0 row generated."][language]}
          <br />
          {
            dict[
              "Please check your file,your selected column or selected data from MAPID"
            ][language]
          }
        </div>
      </div>
    );

    return (
      <div>
        <button className={"buttonSimple2"} onClick={this.openWizard}>
          {" "}
          {dict["Open wizard"][language]}
        </button>
        {modalStatus && (
          <ModalComponent
            modalSize="medium"
            id="modal"
            isOpen={modalStatus}
            onClose={this.closeWizard}
          >
            <div className="box-body">
              {isLoading && (
                <div>
                  <div className="text_bold">{dict["Loading"][language]}</div>
                  <div
                    className="text_inferior"
                    style={{ marginBottom: "10px" }}
                  >
                    {
                      dict[
                        "Please wait, your layer is now creating by system."
                      ][language]
                    }
                  </div>
                  <SpinnerSimpleBlue
                    width={100}
                    unik="loading_create_layer"
                    border={0.1}
                    center={true}
                  />
                </div>
              )}
              {indexModal === 0 && !isLoading && page0}
              {indexModal === 1 && !isLoading && page1}
              {indexModal === 2 && !isLoading && page2}
              {indexModal === 3 && !isLoading && page3}
              {indexModal === 4 && !isLoading && page4}
              {indexModal === 5 && !isLoading && page5}
            </div>
            <div
              style={{
                textAlign: "right",
                padding: 20,
                visibility: !isLoading ? "visible" : "hidden",
              }}
            >
              {indexModal === lastIndex && isSuccess ? (
                <div>
                  <button
                    className={"buttonSimple2"}
                    onClick={this.closeWizard}
                  >
                    {" "}
                    {dict["Finish"][language]}
                  </button>
                </div>
              ) : indexModal === lastIndex ? (
                <div>
                  <button
                    className={"buttonSimple2"}
                    onClick={(e) => this.decreaseIndex()}
                  >
                    {" "}
                    {dict["Back"][language]}
                  </button>
                  <button
                    className={"buttonSimple2"}
                    onClick={this.closeWizard}
                  >
                    {dict["Cancel"][language]}
                  </button>
                </div>
              ) : indexModal === confirmIndex ? (
                <div>
                  <button
                    className={"buttonSimple2"}
                    onClick={(e) => this.decreaseIndex()}
                  >
                    {" "}
                    {dict["Back"][language]}
                  </button>
                  <button
                    className={"buttonSimple2"}
                    onClick={(e) => {
                      this.setState({ isLoading: true });
                      this.generateNewLayer();
                    }}
                  >
                    {dict["Yes"][language]}
                  </button>
                </div>
              ) : (
                <div>
                  <button
                    disabled={indexModal === 0}
                    className={
                      indexModal === 0
                        ? "button_standard_disabled"
                        : "buttonSimple2"
                    }
                    onClick={(e) => this.decreaseIndex()}
                  >
                    {" "}
                    {dict["Back"][language]}
                  </button>
                  <button
                    disabled={
                      indexModal === lastIndex ||
                      (indexModal === 0 &&
                        isEmpty(header) &&
                        isEmpty(csvData)) ||
                      layerName === "" ||
                      (indexModal === 2 && dataSelected === "")
                    }
                    className={
                      indexModal === lastIndex ||
                      (indexModal === 0 &&
                        isEmpty(header) &&
                        isEmpty(csvData)) ||
                      layerName === "" ||
                      (indexModal === 2 && dataSelected === "")
                        ? "button_standard_disabled"
                        : "buttonSimple2"
                    }
                    onClick={(e) => {
                      this.increaseIndex();
                    }}
                  >
                    {dict["Next"][language]}
                  </button>
                </div>
              )}
            </div>
          </ModalComponent>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  importLayer,
  get_features_by_layer_id_without_reducer,
})(JoinTable);
