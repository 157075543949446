/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { bbox } from "@turf/turf";
import { Source, Layer } from "@urbica/react-map-gl";

/*PERSONAL COMPONENT*/
import { Display } from "./StyleGenerator";

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/
import generate_color_map from "../../App/validation/generate_color_map";
import generate_color_interpolate from "../../App/validation/generate_color_interpolate";
import generate_width_map from "../../App/validation/generate_width_map";
import generate_opacity_map from "../../App/validation/generate_opacity_map";

/*NON IMPORT*/
let valueColor = "#1b659d";

class LayerBasic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: [],
    };
  }

  componentDidMount() {
    this.renderLayer(this.props.layer.layer_id_active);
  }

  componentDidUpdate(prevProps) {
    const geometryStatus = this.props.layer.geometryStatus;
    let layer_id_active_after = this?.props?.layer?.layer_id_active;
    const layer_id_after = this.props.layer?.layer_id;
    const geo_layer_list_after = this?.props?.layer?.geo_layer_list;
    const status_action_after = this.props.map.status_action;
    const status_action_before = prevProps.map.status_action;
    const fly_action_after = this.props.map.fly_action;
    const fly_action_before = prevProps.map.fly_action;
    const layer_after = geo_layer_list_after.find(
      (l) => l.geo_layer?._id === layer_id_after
    );
    const features_after = layer_after?.geo_layer?.geojson_filtered?.features
      ? layer_after?.geo_layer?.geojson_filtered?.features
      : [];

    // Mode Dari Map
    // const mode_before = this.props.mode;
    // const mode_after = "simple_select";

    if (
      status_action_after !== status_action_before ||
      fly_action_after !== fly_action_before
    ) {
      //STEP 1 render layer
      this.renderLayer(layer_id_active_after);
    }

    //STEP 2 fly to jika diperlukan
    //fly to ke layer yang baru saja diaktifkan
    let { geo_layer_list } = this.props.layer;
    const layer = geo_layer_list.find(
      (e) => e?.geo_layer?._id === layer_id_after
    );
    const { map } = this.props?.map;
    const geojson_filtered = layer?.geo_layer?.geojson_filtered
      ? layer?.geo_layer?.geojson_filtered
      : { type: "FeatureCollection", features: [] };
    const viewStatus = layer_id_active_after?.includes(layer_id_after);

    if (
      geojson_filtered?.features?.length > 0 &&
      map &&
      viewStatus &&
      features_after.length > 0 &&
      !geometryStatus &&
      fly_action_after !== fly_action_before
    ) {
      this.fly_layer();
    }
  }

  fly_layer = () => {
    const layer_id_after = this.props.layer?.layer_id;
    const { geo_layer_list } = this.props.layer;
    const layer = geo_layer_list.find(
      (e) => e.geo_layer?._id === layer_id_after
    );
    const geojson_filtered = layer?.geo_layer?.geojson_filtered || {
      type: "FeatureCollection",
      features: [],
    };
    if (
      layer_id_after &&
      geo_layer_list.length > 0 &&
      layer?.geo_layer?._id &&
      geojson_filtered?.features?.length > 0
    ) {
      const map = this?.props?.map?.map;
      if (map) {
        let [minLng, minLat, maxLng, maxLat] = bbox(geojson_filtered);
        const padding = { top: 150, bottom: 300, left: 400, right: 400 };
        map?.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          {
            padding,
            maxZoom: 16,
          }
        );
      }
    }
  };

  renderLayer(dataMap) {
    let { geo_layer_list, layer_id_heatmap, layer_apply_color } =
      this.props.layer;
    const { feature_key_selected } = this.props.properties;

    geo_layer_list = geo_layer_list.map((data) => {
      //convert tipe geojson (point, line, poligon) ke tipe visual peta (circle, line, fill, symbol)
      data.type_new =
        data.geo_layer?.type_2 === "pch"
          ? "symbol"
          : data.geo_layer?.type === "Point" ||
            data.geo_layer?.type === "MultiPoint"
          ? "circle"
          : data.geo_layer?.type === "LineString" ||
            data.geo_layer?.type === "MultiLineString"
          ? "line"
          : data.geo_layer?.type === "Polygon" ||
            data.geo_layer?.type === "MultiPolygon"
          ? "fill"
          : "circle";

      //handle jika geojson kosong atau undefined, masukan geojson kosong dengan format yang benar
      if (
        !data?.geo_layer?.geojson_filtered?.features?.length &&
        data?.geo_layer
      ) {
        data.geo_layer.geojson_new = {
          type: "FeatureCollection",
          features: [],
        };
      } else {
        let features_filtered = data?.geo_layer?.geojson_filtered?.features
          ? data?.geo_layer?.geojson_filtered?.features
          : [];

        if (data.geo_layer?.type === "Point") {
          features_filtered = features_filtered.filter((f) => {
            return (
              f.geometry?.coordinates?.[1] <= 90 &&
              f.geometry?.coordinates?.[1] >= -90 &&
              f.geometry?.coordinates?.[0] <= 180 &&
              f.geometry?.coordinates?.[0] >= -180
            );
          });
        }
        features_filtered = features_filtered.map((f) => {
          f.properties.key = f?.key;
          return f;
        });

        //handle untuk titik masukan long lat ke dalam properties supaya bisa diakses lewat pop up
        if (
          data?.geo_layer?.type === "Point" ||
          data?.geo_layer?.type === "IoT"
        ) {
          data.geo_layer.geojson_filtered.features = features_filtered.map(
            (f) => {
              f.properties.long = f.geometry?.coordinates?.[0];
              f.properties.lat = f.geometry?.coordinates?.[1];
              return f;
            }
          );
        } else {
          data.geo_layer.geojson_filtered.features = features_filtered;
        }

        data.geo_layer.geojson_new = {
          type: "FeatureCollection",
          features: features_filtered,
        };
      }

      //atur warna default untuk masing-masing tipe layer (poin, line, poligon)
      let paint = {};
      let paintLine = {};
      // paint

      // selected_column mengambil nilai dari warna yang mau difilter
      let selected_column = data?.geo_layer?.valueStyleProps?.selected_column;

      // classify_mode jenis text yang dipakai buat warna
      let classify_mode = data?.geo_layer?.valueStyleProps?.classify_mode;

      // mode_color menandakan warna solid atau bukan
      let mode_color = data.geo_layer?.globalStyleMode;

      // global_style buat menandakan layer custom
      let global_style = data?.geo_layer?.isGlobalStyle;

      // color mengabil warna yang akan ditampilkan
      let color = data?.geo_layer?.valueStyleProps?.color
        ? data?.geo_layer?.valueStyleProps?.color
        : [];

      let range = data?.geo_layer?.valueStyleProps?.range
        ? data?.geo_layer?.valueStyleProps?.range
        : [];

      let rangeFilter = range?.map((d) => d?.max); // kalo ga diginiin ada 2 array {min, max}

      // Generate array select array color
      let array_name = [];
      if (feature_key_selected) {
        array_name = [
          {
            key: feature_key_selected,
            color: "rgb(125, 194, 61)",
            width: 5,
            opacity: 1,
          },
        ];
      }

      let array_key = array_name.map((d) => d?.key);

      //color
      let array_color = array_name.map((d) => d?.color);
      let array_color_selected = generate_color_map(
        array_key,
        array_color,
        "key"
      );

      //width
      const array_width = array_name.map((d) => d?.width);
      let array_width_selected = generate_width_map(
        array_key,
        array_width,
        "key"
      );

      // opacity
      const array_opacity = array_name.map((d) => d?.opacity);
      let array_opacity_selected = generate_opacity_map(
        array_key,
        array_opacity,
        "key"
      );

      if (!feature_key_selected) {
        array_width_selected = null;
        array_opacity_selected = null;
      }

      const type = data.geo_layer.type;

      let properties_raw = data?.geo_layer?.properties;

      const is_error = !!properties_raw?.[0]?.["LineString"]; //cara deteksi error karena upload bug

      if (is_error) {
        if (type === "Point" || type === "MultiPoint") {
          properties_raw = properties_raw?.[0]?.["Point"];
        } else if (type === "LineString" || type === "MultiLineString") {
          properties_raw = properties_raw?.[0]?.["LineString"];
        } else if (type === "Polygon" || type === "MultiPolygon") {
          properties_raw = properties_raw?.[0]?.["Polygon"];
        }
      }

      // STYLE POINT
      // circle_radius
      let circle_radius = Number(
        properties_raw?.find((d) => d?.key === "circle_radius")?.defaultValue
      );

      // circle_stroke_width
      let circle_stroke_width = Number(
        properties_raw?.find((d) => d?.key === "circle_stroke_width")
          ?.defaultValue
      );

      // circle_stroke_color
      let circle_stroke_color = properties_raw?.find(
        (d) => d?.key === "circle_stroke_color"
      )?.defaultValue;

      // STYLE LINE
      // line_width
      let line_width = Number(
        properties_raw?.find((d) => d?.key === "line_width")?.defaultValue
      );

      // STYLE POLYGON
      // outline_width
      let outline_width = Number(
        properties_raw?.find((d) => d?.key === "outline_width")?.defaultValue
      );

      // outline_color
      let outline_color = properties_raw?.find(
        (d) => d?.key === "outline_color"
      )?.defaultValue;

      // jika warna line undefined or NaN
      let polygon_line_color =
        outline_color === undefined ? "#dedede" : outline_color;

      let polygon_line_width =
        isNaN(outline_width) || outline_width === undefined
          ? "#dedede"
          : outline_width;

      // ALL
      // opacity
      let opacity = Number(
        properties_raw?.find((d) => d?.key === "opacity")?.defaultValue
      );

      let outline;
      let stroke;
      if (feature_key_selected) {
        outline = array_color_selected;
        stroke = array_width_selected;
      } else {
        if (
          data.geo_layer?.type === "Point" ||
          data.geo_layer?.type === "MultiPoint"
        ) {
          stroke = circle_stroke_width;
          outline = circle_stroke_color;
        } else if (
          data.geo_layer?.type === "LineString" ||
          data.geo_layer?.type === "MultiLineString"
        ) {
          outline = 1;
          // stroke = polygon_line_color;
        } else if (
          data.geo_layer?.type === "Polygon" ||
          data.geo_layer?.type === "MultiPolygon"
        ) {
          outline = outline_color;
        }
      }

      // STYLING Value Color
      // let apply_color_id = layer_apply_color.map((item) => item.layer_id);
      let apply_color_id = layer_apply_color.find(
        (item) => item.layer_id === data.geo_layer?._id
      );

      /*
        Case-case styling

        CASE 1 : dari style bawaan
        CASE 2 : dari value style solid 
        CASE 3 : dari value style text || classify by group
        CASE 4 : dari apply color
        CASE 5 : dari value style number || classify by number

      */

      let Case = "";
      if (apply_color_id) {
        Case = 4;
        // CASE 4
        let apply_value = apply_color_id?.config?.array_color?.labels || [];
        apply_value = apply_value.map((item) => String(item));
        let apply_color =
          apply_color_id?.config?.array_color?.datasets?.[0]?.backgroundColor;
        const array_apply_color = generate_color_map(
          apply_value,
          apply_color,
          apply_color_id?.config?.field_key
        );
        //pakai codingan baru apply_color_status
        if (
          data.geo_layer?.type === "Point" ||
          data.geo_layer?.type === "MultiPoint"
        ) {
          paint = {
            "circle-color": array_apply_color,
            "circle-radius": circle_radius ? circle_radius : 7,
            "circle-stroke-width": stroke ? stroke : 2,
            "circle-stroke-color": outline ? outline : "#fff",
          };
        } else if (
          data.geo_layer?.type === "LineString" ||
          data.geo_layer?.type === "MultiLineString"
        ) {
          paint = {
            "line-color": array_apply_color,
            "line-width": array_width_selected ? array_width_selected : 3,
            "line-opacity": opacity ? opacity : 0.5,
          };
        } else if (
          data.geo_layer?.type === "Polygon" ||
          data.geo_layer?.type === "MultiPolygon"
        ) {
          paint = {
            "fill-color": array_apply_color,
            "fill-opacity": array_opacity_selected
              ? array_opacity_selected
              : 0.3,
            "fill-outline-color": outline_color ? outline_color : "#fff",
          };
        }
      } else {
        // Styling Standart
        //styling dengan konfigurasi yang disimpan di database
        if (global_style === false) {
          // CASE 1
          Case = 1;
          if (
            data.geo_layer?.type === "Point" ||
            data.geo_layer?.type === "MultiPoint"
          ) {
            paint = {
              "circle-color": ["get", "circle_color"],
              "circle-radius": circle_radius
                ? circle_radius
                : ["get", "circle_radius"],
              "circle-stroke-width": stroke
                ? stroke
                : ["get", "circle_stroke_width"],
              "circle-stroke-color": outline
                ? outline
                : ["get", "circle_stroke_color"],
            };
          } else if (
            data.geo_layer?.type === "LineString" ||
            data.geo_layer?.type === "MultiLineString"
          ) {
            paint = {
              "line-color": ["get", "color"],
              "line-width": array_width_selected
                ? array_width_selected
                : line_width
                ? line_width
                : ["get", "line_width"],
              "line-opacity": opacity ? opacity : ["get", "opacity"],
              "line-gap-width": ["get", "line_gap_width"],
            };
          } else if (
            data.geo_layer?.type === "Polygon" ||
            data.geo_layer?.type === "MultiPolygon"
          ) {
            paint = {
              "fill-color": ["get", "color"],
              "fill-opacity": array_opacity_selected
                ? array_opacity_selected
                : opacity
                ? opacity
                : ["get", "opacity"],
              // "fill-outline-color": outline
              //   ? outline
              //   : ["get", "outline_color"],
            };
            paintLine = {
              "line-color": outline ? outline : "rgba(0,0,0,0.4)",
              "line-width": 1,
              "line-opacity": 1,
              // "line-color": polygon_line_color,
              // "line-width": polygon_line_width,
              // "line-gap-width": 1,
            };
          }
        } else if (global_style === true) {
          if (mode_color === "solid") {
            // CASE 2
            Case = 2;
            if (
              data.geo_layer?.type === "Point" ||
              data.geo_layer?.type === "MultiPoint"
            ) {
              paint = {
                "circle-color": properties_raw[2]?.defaultValue,
                "circle-radius": circle_radius
                  ? circle_radius
                  : ["get", "circle_radius"],
                "circle-stroke-width": stroke
                  ? stroke
                  : ["get", "circle_stroke_width"],
                "circle-stroke-color": outline
                  ? outline
                  : ["get", "circle_stroke_color"],
              };
              // paint = CircleColor(
              //   properties_raw[2]?.defaultValue,
              //   "normal",
              //   outline,
              //   array_width_selected,
              //   circle_radius,
              //   circle_stroke_width,
              //   circle_stroke_color
              // );
            } else if (
              data.geo_layer?.type === "LineString" ||
              data.geo_layer?.type === "MultiLineString"
            ) {
              paint = {
                "line-color": properties_raw[0]?.defaultValue,
                "line-width": array_width_selected
                  ? array_width_selected
                  : line_width
                  ? line_width
                  : ["get", "line_width"],
                "line-opacity": opacity ? opacity : ["get", "opacity"],
                "line-gap-width": ["get", "line_gap_width"],
              };
            } else if (
              data.geo_layer?.type === "Polygon" ||
              data.geo_layer?.type === "MultiPolygon"
            ) {
              paint = {
                "fill-color": properties_raw[0]?.defaultValue,
                "fill-opacity": array_opacity_selected
                  ? array_opacity_selected
                  : opacity
                  ? opacity
                  : ["get", "opacity"],
                // "fill-outline-color": outline
                //   ? outline
                //   : outline_color
                //   ? outline_color
                //   : ["get", "outline_color"],
              };
              paintLine = {
                "line-color": outline ? outline : polygon_line_color,
                "line-width": polygon_line_width,
                // "line-opacity": 1,
                // "line-gap-width": 1,
              };
            }
          } else if (mode_color === "by_value") {
            if (classify_mode === "text") {
              // CASE 3
              Case = 3;
              // mode classify mode match by text
              valueColor = generate_color_map(range, color, selected_column);
              if (
                data.geo_layer?.type === "Point" ||
                data.geo_layer?.type === "MultiPoint"
              ) {
                paint = {
                  "circle-color": valueColor,
                  "circle-radius": circle_radius
                    ? circle_radius
                    : ["get", "circle_radius"],
                  "circle-stroke-width": stroke
                    ? stroke
                    : ["get", "circle_stroke_width"],
                  "circle-stroke-color": outline
                    ? outline
                    : ["get", "circle_stroke_color"],
                };
              } else if (
                data.geo_layer?.type === "LineString" ||
                data.geo_layer?.type === "MultiLineString"
              ) {
                paint = {
                  "line-color": valueColor,
                  "line-width": array_width_selected
                    ? array_width_selected
                    : line_width
                    ? line_width
                    : ["get", "line_width"],
                  "line-opacity": opacity ? opacity : ["get", "opacity"],
                  "line-gap-width": ["get", "line_gap_width"],
                };
              } else if (
                data.geo_layer?.type === "Polygon" ||
                data.geo_layer?.type === "MultiPolygon"
              ) {
                paint = {
                  "fill-color": valueColor,
                  "fill-opacity": array_opacity_selected
                    ? array_opacity_selected
                    : opacity
                    ? opacity
                    : ["get", "opacity"],
                  // "fill-outline-color": outline
                  //   ? outline
                  //   : outline_color
                  //   ? outline_color
                  //   : ["get", "outline_color"],
                };
                paintLine = {
                  "line-color": outline ? outline : polygon_line_color,
                  "line-width": polygon_line_width,
                  "line-opacity": 1,
                  // "line-gap-width": 1,
                };
              }
            } else if (classify_mode === "number") {
              // CASE 5
              Case = 5;
              // mode classify mode step by number
              valueColor = generate_color_interpolate(
                rangeFilter,
                color,
                selected_column
              );
              if (
                data.geo_layer?.type === "Point" ||
                data.geo_layer?.type === "MultiPoint"
              ) {
                paint = {
                  "circle-color": valueColor,
                  "circle-radius": circle_radius
                    ? circle_radius
                    : ["get", "circle_radius"],
                  "circle-stroke-width": stroke
                    ? stroke
                    : ["get", "circle_stroke_width"],
                  "circle-stroke-color": outline
                    ? outline
                    : ["get", "circle_stroke_color"],
                };
              } else if (
                data.geo_layer?.type === "LineString" ||
                data.geo_layer?.type === "MultiLineString"
              ) {
                paint = {
                  "line-color": valueColor,
                  "line-width": array_width_selected
                    ? array_width_selected
                    : line_width
                    ? line_width
                    : ["get", "line_width"],
                  "line-opacity": opacity ? opacity : ["get", "opacity"],
                  "line-gap-width": ["get", "line_gap_width"],
                };
              } else if (
                data.geo_layer?.type === "Polygon" ||
                data.geo_layer?.type === "MultiPolygon"
              ) {
                paint = {
                  "fill-color": valueColor,
                  "fill-opacity": array_opacity_selected
                    ? array_opacity_selected
                    : opacity
                    ? opacity
                    : ["get", "opacity"],
                  // "fill-outline-color": outline
                  //   ? outline
                  //   : outline_color
                  //   ? outline_color
                  //   : ["get", "outline_color"],
                };
                paintLine = {
                  "line-color": outline ? outline : polygon_line_color,
                  "line-width": polygon_line_width,
                  "line-opacity": 1,
                  // "line-gap-width": 1,
                };
              }
            }
          }
        }
      }

      // Khusus
      //styling khusus untuk pos duga air
      if (data.geo_layer.type_2 === "pda") {
        // paint = CircleColor("#000000", "pda");
        paint = {
          "circle-color": [
            "match",
            ["get", "status"],
            "siaga1",
            "#ff0000",
            "siaga2",
            "#ffff00",
            "siaga3",
            "#0000ff",
            "normal",
            "#008000",
            "Offline",
            "#000000",
            "delay",
            "#000000",
            "tanpa",
            "#000000",
            "#000000",
          ],
          "circle-radius": 5,
          "circle-stroke-width": 2,
          "circle-stroke-color": "#fff",
        };
      }

      //styling khusus untuk pos curah hujan
      if (data?.geo_layer?.type_2 === "pch") {
        // paint = CircleColor("#000000", "pch");
        paint = {
          "circle-color": "#000000",
          "circle-radius": 5,
          "circle-stroke-width": 2,
          "circle-stroke-color": "#fff",
        };
        const icon_image = ["get", "status"];
        data.icon_image = icon_image;
      }

      data.paintLine = paintLine;
      data.paint = paint;
      data.Case = Case;
      return data;
    });

    const render = geo_layer_list.map((data, index) => {
      // Menampilkan Label
      const is_use_label = data?.geo_layer?.properties?.find(
        (d) => d?.key === "is_using_label"
      )?.defaultValue;
      const is_name_label = data?.geo_layer?.properties?.find(
        (d) => d?.key === "text_field"
      )?.defaultValue;

      // Menampilkan Icon
      const is_use_icon = data?.geo_layer?.properties?.find(
        (d) => d?.key === "is_using_icon"
      )?.defaultValue;
      const icon_image = data?.geo_layer?.properties?.find(
        (d) => d?.key === "icon_image"
      )?.defaultValue;

      let global_style = data?.geo_layer?.isGlobalStyle;

      const display_render = (
        <>
          {!data.geo_layer?.isExpand && data?.type_new === "circle" ? (
            <section>
              <Source
                key={data.geo_layer?._id}
                id={data.geo_layer?._id}
                type="geojson"
                data={data.geo_layer.geojson_new}
                cluster={true}
                generateId={true}
                clusterMaxZoom={14}
                clusterRadius={50}
              />
              <Layer
                // Semua layer akan merender dengan menggunakan ini
                id={`Layer_${data.geo_layer?._id}_${index + 1}`}
                type={data?.type_new}
                source={data?.geo_layer?._id}
                onClick={this.props.layerOnClick.bind(this)}
                paint={data?.paint}
                filter={["!", ["has", "point_count"]]}
              />
              <Layer
                // Jika mode cluster diaktifkan maka maka akan merender dengan menggunakan ini
                id={data?.geo_layer?._id}
                type="circle"
                source={data?.geo_layer?._id}
                filter={["has", "point_count"]}
                paint={{
                  "circle-color": [
                    "step",
                    ["get", "point_count"],
                    "#37A0F7",
                    100,
                    "#1E5E96",
                    750,
                    "#7EB532",
                  ],
                  "circle-radius": [
                    "step",
                    ["get", "point_count"],
                    20,
                    100,
                    30,
                    750,
                    40,
                  ],
                }}
              />
              <Layer
                // Jika text pada cluster diaktifkan maka maka akan merender dengan menggunakan ini
                id={`Label_Cluster_${data?.geo_layer?._id}_${index + 1}`}
                type="symbol"
                source={data?.geo_layer?._id}
                filter={["has", "point_count"]}
                layout={{
                  visibility: dataMap.includes(data?.geo_layer?._id)
                    ? "visible"
                    : "none",
                  "text-field": "{point_count_abbreviated}",
                  "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                  "text-size": 13,
                }}
                paint={{
                  "text-color": "#fff",
                }}
              />
            </section>
          ) : (
            <Display
              // Semua layer akan merender dengan menggunakan ini
              key={index}
              title={data?.geo_layer?._id}
              viewStatus={dataMap.includes(data?.geo_layer?._id)}
              type={data?.type_new}
              icon_image={data?.icon_image}
              data={data?.geo_layer?.geojson_new}
              paint={data?.paint}
              layerOnClick={this.props.layerOnClick.bind(this)}
              paintLine={data?.paintLine}
            />
          )}

          {/* Kalo label di edit layer diaktifkan aman merender menggunakan layer ini */}
          {global_style === false ||
            (is_use_label && (
              <div>
                <Source
                  key={`${data?.geo_layer?._id}_label_${index + 1}`}
                  id={`${data?.geo_layer?._id}_label_${index + 1}`}
                  type="geojson"
                  data={data?.geo_layer.geojson_new}
                />
                <Layer
                  id={`${data?.geo_layer?._id}_label_${index + 1}`}
                  type="symbol"
                  source={`${data?.geo_layer?._id}_label_${index + 1}`}
                  layout={{
                    "text-field": ["get", `${is_name_label}`], // mengasih nama labelnya
                    "text-variable-anchor": ["bottom"],
                    "text-radial-offset": is_use_icon ? 1.5 : 0.8, // menentukan tata letak tulisan
                    "text-allow-overlap": true,
                    "text-justify": "auto",

                    visibility: dataMap.includes(data?.geo_layer?._id)
                      ? "visible"
                      : "none",
                  }}
                  paint={{
                    "text-halo-color": "#fff",
                    "text-halo-width": 30,
                    "text-halo-blur": 0,
                    "text-color": "#2064A1",
                  }}
                />
              </div>
            ))}

          {/* Kalo icon di edit layer diaktifkan aman merender menggunakan layer ini */}
          {global_style === false ||
            (is_use_icon && (
              <div>
                <Source
                  key={`${data?.geo_layer?._id}_icon_${index + 1}`}
                  id={`${data?.geo_layer?._id}_icon_${index + 1}`}
                  type="geojson"
                  data={data?.geo_layer.geojson_new}
                />
                <Layer
                  id={`${data?.geo_layer?._id}_icon_${index + 1}`}
                  type="symbol"
                  source={`${data?.geo_layer?._id}_icon_${index + 1}`}
                  layout={{
                    "icon-image": icon_image,
                    "icon-size": 1.2,
                    visibility: dataMap.includes(data?.geo_layer?._id)
                      ? "visible"
                      : "none",
                  }}
                />
              </div>
            ))}
        </>
      );

      return (
        <section key={index} style={{ cursor: "pointer" }}>
          {/* Kalo headmap aktif merender dengan ini */}
          {layer_id_heatmap.includes(data?.geo_layer?._id) ? (
            <section>
              <Source
                key={data?.geo_layer?._id}
                id={data?.geo_layer?._id}
                type="geojson"
                data={data?.geo_layer?.geojson_new}
              />
              <Layer
                id={data?.geo_layer?._id}
                type="heatmap"
                source={data?.geo_layer?._id}
                paint={{
                  "heatmap-weight": [
                    "interpolate",
                    ["linear"],
                    ["get", "mag"],
                    0,
                    0,
                    6,
                    1,
                  ],
                  "heatmap-radius": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    0,
                    2,
                    9,
                    20,
                  ],
                }}
              />
            </section>
          ) : (
            // Selain headmap merendern yang yang ini
            display_render
          )}
        </section>
      );
    });
    this.setState({ render });
  }

  render() {
    const { render } = this.state;
    return render;
  }
}
const mapStateToProps = (state) => ({
  layer: state.layer,
  map: state.map,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(LayerBasic);
