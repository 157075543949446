//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import TextFieldGroup from "../common_input/TextFieldGroup";
import { SelectField } from "../common_input/TextFieldGroup";

//Redux function
import { registerUser } from "../../App/actions/authActions";

//Picture Asset

//General Function
import dict from "../../Data/dict.json";
import sortArray from "../../App/validation/sort-array";

class Register extends Component {
  constructor() {
    super();
    this.state = {
      full_name: "",
      name: "",
      email: "",
      password: "",
      password2: "",
      errors: {},
      industry: "",
      show_password: false,
    };
  }

  toggleShowPassword = () => {
    this.setState({ show_password: !this.state.show_password });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.errors) {
      return {
        errors: nextProps.errors,
      };
    } else {
      return null;
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { full_name, name, email, password, password2, industry } =
      this.state;
    let body = {
      full_name,
      name: name.toLowerCase().replace(/\s/g, ""),
      email,
      password,
      password2,
      industry,
      city: "Kota Bandung",
    };
    if (this.props.kode) {
      body = {
        full_name,
        name: name.toLowerCase().replace(/\s/g, ""),
        email,
        password,
        password2,
        industry,
        specialist_code: this.props.kode,
        city: "Kota Bandung",
      };
    }

    this.props.registerUser(body);
  };

  onChange = (e) => {
    if (e.target.name === "name") {
      this.setState({ [e.target.name]: e.target.value.replace(/\s/g, "") });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";

    const industry_list = [
      {
        value: "Information Technology and Services",
        title: dict["Information Technology and Services"][language],
      },
      {
        value: "Government",
        title: dict["Government"][language],
      },
      {
        value: "Real Estate and Architecture",
        title: dict["Real Estate and Architecture"][language],
      },
      {
        value: "Mining and Metals",
        title: dict["Mining and Metals"][language],
      },
      {
        value: "Oil and Energy",
        title: dict["Oil and Energy"][language],
      },
      {
        value: "Research and Education",
        title: dict["Research and Education"][language],
      },
      {
        value: "Nonprofit Organization Management",
        title: dict["Nonprofit Organization Management"][language],
      },
      {
        value: "Internet and Telecommunications",
        title: dict["Internet and Telecommunications"][language],
      },
      {
        value: "Farming and Agriculture",
        title: dict["Farming and Agriculture"][language],
      },
      {
        value: "Banking and Financial Services",
        title: dict["Banking and Financial Services"][language],
      },
      {
        value: "Consumer Goods",
        title: dict["Consumer Goods"][language],
      },
      {
        value: "Logistics and Supply Chain",
        title: dict["Logistics and Supply Chain"][language],
      },
      {
        value: "Retail and Fashion",
        title: dict["Retail and Fashion"][language],
      },
      {
        value: "Manufacture",
        title: dict["Manufacture"][language],
      },
      {
        value: "Transportation and Mobility",
        title: dict["Transportation and Mobility"][language],
      },
      {
        value: "Food and Beverages",
        title: dict["Food and Beverages"][language],
      },
      {
        value: "Hospital and Health Care",
        title: dict["Hospital and Health Care"][language],
      },
      {
        value: "Hospitality, Entertainment, and Tourism",
        title: dict["Hospitality, Entertainment, and Tourism"][language],
      },
      {
        value: "Media and Public Relations",
        title: dict["Media and Public Relations"][language],
      },
      {
        value: "Fishery and Marine",
        title: dict["Fishery and Marine"][language],
      },
    ];

    const industry_list_sorted = sortArray(industry_list, "title", true);
    const {
      errors,
      full_name,
      name,
      email,
      password,
      password2,
      show_password,
      industry,
    } = this.state;

    const { loading } = this.props.auth;

    let iconButton;
    const kode = this.props.kode;
    let kode_content;
    if (kode) {
      kode_content = (
        <section style={{ marginBottom: "10px" }}>
          Kode specialist: <b>{kode}</b>
        </section>
      );
    }
    if (loading) {
      iconButton = (
        <button
          className="button"
          type="submit"
          style={{
            padding: "5px 15px",
            backgroundColor: "#1265ae",
            marginTop: "1rem",
          }}
        >
          {dict["Registering..."][language]}
        </button>
      );
    } else {
      iconButton = (
        <button
          className="button"
          type="submit"
          style={{
            padding: "5px 15px",
            backgroundColor: "#1265ae",
            marginTop: "1rem",
          }}
        >
          {dict["Register"][language]}
        </button>
      );
    }

    return (
      <div>
        {kode_content}
        <form noValidate autoComplete="off" onSubmit={this.onSubmit}>
          <TextFieldGroup
            Title={dict["FullName"][language]}
            // placeholder="Nama lengkap, contoh: Vino Arystio"
            placeholder={`${dict["example fullname"][language]}`}
            name="full_name"
            value={full_name}
            onChange={this.onChange}
            error={errors.full_name}
          />
          <TextFieldGroup
            // placeholder={dict["User name"][language]}
            placeholder={dict["example username"][language]}
            Title={`${dict["Username"][language]}`}
            // placeholder="Username, contoh: vino_ary"
            name="name"
            value={name.toLowerCase()}
            onChange={this.onChange}
            error={errors.name}
          />
          <TextFieldGroup
            Title={dict["Email"][language]}
            // placeholder="Email, contoh: vino@gmail.com"
            placeholder={`${dict["example email"][language]}`}
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={this.onChange}
            error={errors.email}
          />
          <TextFieldGroup
            Title={dict["Password"][language]}
            type={show_password ? "text" : "password"}
            isPassword={true}
            placeholder={`${dict["Password minimal 8 character"][language]} `}
            name="password"
            id="password"
            value={password}
            onChange={this.onChange}
            error={errors.password}
            toggleShowPassword={this.toggleShowPassword}
          />
          <TextFieldGroup
            type={show_password ? "text" : "password"}
            isPassword={true}
            placeholder={dict["Confirm password"][language]}
            name="password2"
            id="password2"
            value={password2}
            onChange={this.onChange}
            error={errors.password2}
            toggleShowPassword={this.toggleShowPassword}
          />
          <SelectField
            list_array={industry_list_sorted}
            name="industry"
            id="industry"
            value={industry}
            onChange={this.onChange}
            error={errors.industry}
            placeholder={dict["industry"][language]}
          />
          {iconButton}
        </form>
        <style>{`
         #bottom_bar{ display:none;}
      `}</style>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerUser })(Register);
