//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import dict from "../../Data/dict.json";
import isEmpty from "../../App/validation/is-empty";
import { IconOpen } from "./icon";

//Redux function

//Picture Asset
import icon_download from "../../Assets/svg/icon_download.svg";
import icon_view_blue from "../../Assets/svg/icon_view_blue.svg";
import icon_view_grey from "../../Assets/svg/icon_view_grey.svg";

//General Function

class Disaster extends Component {
  state = {
    hide: false,
  };

  render() {
    const { hide } = this.state;
    const { sini_list, request_id } = this.props.layer;
    const language = localStorage?.language ? localStorage?.language : "ina";

    const Data_Kelas = [
      {
        name: 1,
        color: "#429488",
      },
      {
        name: 2,
        color: "#67AD5B",
      },
      {
        name: 3,
        color: "#97C15C",
      },
      {
        name: 4,
        color: "#D0DC59",
      },
      {
        name: 5,
        color: "#FCEC60",
      },
      {
        name: 6,
        color: "#F6C344",
      },
      {
        name: 7,
        color: "#F19D38",
      },
      {
        name: 8,
        color: "#FF3E00",
      },
      {
        name: 9,
        color: "#C5714C",
      },
      {
        name: 10,
        color: "#74574A",
      },
    ];

    const Data_Objek = [
      {
        name: "SAWAH",
        ina: "SAWAH",
        eng: "SAWAH",
        color: "#AF1300",
      },
      {
        name: "KEBUN/TEGALAN",
        ina: "KEBUN/TEGALAN",
        eng: "KEBUN/TEGALAN",
        color: "#FF1B00",
      },
      {
        name: "JASA/INDUSTRI",
        ina: "JASA/INDUSTRI",
        eng: "JASA/INDUSTRI",
        color: "#8B00B1",
      },
      {
        name: "PERMUKIMAN",
        ina: "PERMUKIMAN",
        eng: "PERMUKIMAN",
        color: "#C800FF",
      },
      {
        name: "TANAH TERBUKA",
        ina: "TANAH TERBUKA",
        eng: "TANAH TERBUKA",
        color: "#3700AB",
      },
      {
        name: "PERAIRAN DARAT",
        ina: "PERAIRAN DARAT",
        eng: "PERAIRAN DARAT",
        color: "#5100FF",
      },
      {
        name: "HUTAN",
        ina: "HUTAN",
        eng: "HUTAN",
        color: "#0015AC",
      },
      {
        name: "TRANSPORTASI/INSTALASI",
        ina: "TRANSPORTASI/INSTALASI",
        eng: "TRANSPORTASI/INSTALASI",
        color: "#001FFF",
      },
      {
        name: "PERTAMBANGAN",
        ina: "PERTAMBANGAN",
        eng: "PERTAMBANGAN",
        color: "#2200AC",
      },
      {
        name: "KAWASAN TERBANGUN",
        ina: "KAWASAN TERBANGUN",
        eng: "KAWASAN TERBANGUN",
        color: "#0075FF",
      },
      {
        name: "JARINGAN JALAN",
        ina: "JARINGAN JALAN",
        eng: "JARINGAN JALAN",
        color: "#0060AC",
      },
      {
        name: "DANAU",
        ina: "DANAU",
        eng: "DANAU",
        color: "#008EFF",
      },
      {
        name: "WADUK",
        ina: "WADUK",
        eng: "WADUK",
        color: "#008CAD",
      },
      {
        name: "RAWA",
        ina: "RAWA",
        eng: "RAWA",
        color: "#00CEFF",
      },
      {
        name: "SUNGAI",
        ina: "SUNGAI",
        eng: "SUNGAI",
        color: "#00AD94",
      },
      {
        name: "LADANG",
        ina: "LADANG",
        eng: "LADANG",
        color: "#00FFDA",
      },
      {
        name: "SABANA",
        ina: "SABANA",
        eng: "SABANA",
        color: "#1AAE00",
      },
      {
        name: "SEMAK BELUKAR",
        ina: "SEMAK BELUKAR",
        eng: "SEMAK BELUKAR",
        color: "#25FF00",
      },
      {
        name: "JARINGAN JALAN KERETA API",
        ina: "JARINGAN JALAN KERETA API",
        eng: "JARINGAN JALAN KERETA API",
        color: "#AC7B00",
      },
      {
        name: "LAHAN TIDAK TERBANGUN",
        ina: "LAHAN TIDAK TERBANGUN",
        eng: "LAHAN TIDAK TERBANGUN",
        color: "#FFB600",
      },
      {
        name: "BANDAR UDARA DOMESTIK/INTL",
        ina: "BANDAR UDARA DOMESTIK/INTL",
        eng: "BANDAR UDARA DOMESTIK/INTL",
        color: "#AF6000",
      },
      {
        name: "PELABUHAN LAUT",
        ina: "PELABUHAN LAUT",
        eng: "PELABUHAN LAUT",
        color: "#FF8B00",
      },
      {
        name: "TEMPAT PENUMBUNAN SAMPAH/DEPOSIT",
        ina: "TEMPAT PENUMBUNAN SAMPAH/DEPOSIT",
        eng: "TEMPAT PENUMBUNAN SAMPAH/DEPOSIT",
        color: "#AE2A00",
      },
      {
        name: "TAMBAK",
        ina: "TAMBAK",
        eng: "TAMBAK",
        color: "#FF3E00",
      },
      {
        name: "PADANG RUMPUT",
        ina: "PADANG RUMPUT",
        eng: "PADANG RUMPUT",
        color: "#AB3500",
      },
      {
        name: "BELUM ADA DATA",
        ina: "BELUM ADA DATA",
        eng: "BELUM ADA DATA",
        color: "#F04A00",
      },
    ];

    let znt_content;
    let landuse_content;
    const znt = sini_list?.filter((e) => e.request_id === request_id)[0].znt;
    const landuse = sini_list?.filter((e) => e.request_id === request_id)[0]
      .landuse;

    if (!isEmpty(znt)) {
      if (!isEmpty(znt.features)) {
        const feature = znt.features[0];
        const properties = feature.properties;
        const RENDAH_RP = properties.RENDAH_RP;
        const TINGGI_RP = properties.TINGGI_RP;
        const KELASNILAI = properties.KELASNILAI;
        const KABKOT = properties.KABKOT;
        const NOMORZONA = properties.NOMORZONA;
        const TAHUN = properties.TAHUN;
        const Color = Data_Kelas.find((d) => d.name === KELASNILAI);

        znt_content = (
          <main
            className="card_znt"
            style={hide ? { border: `2px solid ${Color.color}` } : null}
          >
            <div className="card_znt_right">
              <h1>{dict?.["Land Value Zone"]?.[language]}</h1>

              <div className="card_znt_content">
                <p>{dict?.["City District"]?.[language]}</p>
                <h2>{KABKOT}</h2>
              </div>
              <div className="card_znt_content">
                <p>{dict?.["Zone Number"]?.[language]}</p>
                <h2>{NOMORZONA}</h2>
              </div>
              <div className="card_znt_content">
                <p>{dict?.["Year"]?.[language]}</p>
                <h2>{TAHUN}</h2>
              </div>
              <div className="card_znt_content">
                <p>{dict?.["Land Price Range"]?.[language]}</p>
                <h2>
                  Rp.{" "}
                  {Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(RENDAH_RP)}{" "}
                  - Rp.{" "}
                  {Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(TINGGI_RP)}
                </h2>
              </div>
            </div>
            <div
              className="card_znt_left"
              style={{ backgroundColor: Color.color }}
            >
              <p>{dict?.["Class"]?.[language]}</p>
              <h2>{KELASNILAI}</h2>
            </div>
            <div className="card_znt_button">
              <img
                src={
                  this.props.layer.sini_mode.find((d) => d === "znt")
                    ? icon_view_blue
                    : icon_view_grey
                }
                title={
                  this.props.layer.sini_mode.find((d) => d === "landuse")
                    ? dict?.["Hide"]?.[language]
                    : dict?.["View"]?.[language]
                }
                alt="Add "
                width={25}
                style={{ cursor: "pointer" }}
                onClick={() => this.props.sini_mode("znt")}
              />
              <img
                src={icon_download}
                title="Download Data"
                alt="Add "
                width={25}
                style={{ marginLeft: "-5px", cursor: "pointer" }}
                onClick={() => this.props.handleDownloadSini(request_id, "znt")}
              />
            </div>
            {/* <div
              className="information_class"
              style={{
                backgroundColor: `${Color.color}`,
              }}
            >
              <h1>Class Information</h1>
              <div className="information_class_row">
                {Data_Kelas.map((d) => (
                  <p
                    className="card_mini_information"
                    style={{
                      backgroundColor: `${d.color}`,
                    }}
                  >
                    {d.name}
                  </p>
                ))}
              </div>
            </div> */}
          </main>
        );
      } else {
        znt_content = (
          <main>
            <p className="text_inferior">
              {dict?.["Land Price Range"]?.[language]}
            </p>
            <h2 className="text_bold" style={{ marginBottom: "10px" }}>
              {dict?.["There is no land price here"]?.[language]}
            </h2>
          </main>
        );
      }
    }

    if (!isEmpty(landuse)) {
      if (!isEmpty(landuse.features)) {
        const feature = landuse.features[0];
        const properties = feature.properties;

        let GUNATANAH;
        GUNATANAH = dict?.[properties?.GUNATANAH]?.[language]
          ? dict?.[properties?.GUNATANAH]?.[language]
          : properties?.GUNATANAH;

        let NAMAOBJ = properties?.NAMAOBJ;
        let Provinsi = properties?.Provinsi;
        let Kabkot = properties?.Kabkot;
        let Luas_Ha = properties?.Luas_Ha;
        const Color = Data_Objek?.find((d) => d.name === GUNATANAH);

        landuse_content = (
          <main
            className="card_znt"
            style={
              hide
                ? {
                    border: `2px solid ${Color ? Color.color : "#613CB0"}`,
                    // border: `2px solid ${Color.color}`,
                    marginTop: "10px",
                    position: "relative",
                  }
                : { marginTop: "10px", position: "relative" }
            }
          >
            <div className="card_znt_right">
              <p
                style={{
                  backgroundColor: Color ? Color.color : "#613CB0",
                  // backgroundColor: Color.color,
                  width: "100%",
                  color: "#fff",
                  position: "absolute",
                  padding: "5px",
                  top: "0",
                  right: "0",
                  textAlign: "center",
                }}
              >
                {GUNATANAH}
              </p>
              <div className="card_znt_content" style={{ marginTop: "1.5rem" }}>
                <h1>{dict?.["Land Use"]?.[language]}</h1>
                <h2>{NAMAOBJ}</h2>
              </div>
              <div className="card_znt_content">
                <p>{dict?.["Province"]?.[language]}</p>
                <h2>{Provinsi}</h2>
              </div>
              <div className="card_znt_content">
                <p>{dict?.["City District"]?.[language]}</p>
                <h2>{Kabkot}</h2>
              </div>
              <div className="card_znt_content">
                <p>{dict?.["Area Ha"]?.[language]}</p>
                <h2>{Luas_Ha}</h2>
              </div>
            </div>
            {/* <div
              className="card_znt_left"
              style={{ backgroundColor: Color ? Color.color : "#613CB0" }}
            >
              <p>{NAMAOBJ}</p>
            </div> */}
            <div className="card_znt_button" style={{ top: "2.7rem" }}>
              <img
                src={
                  this.props.layer.sini_mode.find((d) => d === "landuse")
                    ? icon_view_blue
                    : icon_view_grey
                }
                title={
                  this.props.layer.sini_mode.find((d) => d === "landuse")
                    ? dict?.["Hide"]?.[language]
                    : dict?.["View"]?.[language]
                }
                alt="Add "
                width={25}
                style={{ cursor: "pointer" }}
                onClick={() => this.props.sini_mode("landuse")}
              />
              <img
                src={icon_download}
                title="Download Data"
                alt="Add "
                width={25}
                style={{ marginLeft: "-5px", cursor: "pointer" }}
                onClick={() =>
                  this.props.handleDownloadSini(request_id, "landuse")
                }
              />
            </div>
          </main>
        );
      } else {
        landuse_content = (
          <main>
            <div className="text_inferior">
              {dict?.["Land Use"]?.[language]}
            </div>
            <div className="text_bold" style={{ marginBottom: "10px" }}>
              {dict?.["There is no land use data here"]?.[language]}
            </div>
          </main>
        );
      }
    }

    const content = (
      <section className="sini_card">
        <div className="sini_card_top">
          <div style={{ zIndex: "5" }}>
            <h2>{dict?.["Land Zoning"]?.[language]}</h2>
            <img
              src={
                this.props.layer.sini_mode.find((d) => d === "znt")
                  ? icon_view_blue
                  : icon_view_grey
              }
              title={
                this.props.layer.sini_mode.find((d) => d === "znt")
                  ? dict?.["Hide"]?.[language]
                  : dict?.["View"]?.[language]
              }
              style={{ marginRight: "5px", cursor: "pointer" }}
              className={hide ? "hide_icon" : "show_icon"}
              alt="Add "
              width={25}
              onClick={() => this.props.sini_mode("znt")}
            />
            <img
              src={icon_download}
              title="Download Data"
              className={hide ? "hide_icon" : "show_icon"}
              style={{ cursor: "pointer" }}
              alt="Add "
              width={25}
              onClick={() => this.props.handleDownloadSini(request_id, "znt")}
            />
          </div>
          <IconOpen
            onClick={() => this.setState({ hide: !hide })}
            hide={hide}
          />
        </div>
        <main className={hide ? "sini_scroll" : "sini_scroll_hide"}>
          <div>{znt_content}</div>
          {landuse_content}
        </main>
      </section>
    );

    const no_content = (
      <section className="sini_card">
        <div className="sini_card_top">
          <div style={{ zIndex: "5" }}>
            <h2>{dict?.["Land Zoning"]?.[language]}</h2>
            <p>
              {
                dict?.[
                  "There is no data at the point you selected, we will complete it soon."
                ]?.[language]
              }
            </p>
          </div>
        </div>
      </section>
    );

    return znt?.features?.length === 0 && landuse?.features?.length === 0
      ? no_content
      : content;
  }
}
const mapStateToProps = (state) => ({
  layer: state.layer,
  auth: state.auth,
});
export default connect(mapStateToProps, {})(Disaster);
