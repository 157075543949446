//Library modul
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
//Personal Component
//Redux function
import {
  getBankData,
  addBank,
  getCurrentBankData,
  setCurrentBankData,
} from "../../App/actions/authActions";
//Picture Asset
//General Function
import dict from "../../Data/dict.json";
import isEqual from "../../App/validation/is-equal";
import icon_check from "../../Assets/svg/icon_check.svg";
import Kabupaten from "../../Data/Bank_list.json";
const e_money = ["GO-PAY", "OVO"];
const cities_array_pre = Kabupaten.sort();
const cities_array = e_money.concat(cities_array_pre);
class EditBank extends Component {
  state = {
    modalAddBank: false,
    banks_filtered: cities_array,
    type_bank: "GO-PAY",
    no_bank: "",
    name_bank: "",
    modalListBank: true,
    mode_bank: "list",
    modal_revision: false,
    modal_edit: false,
    feature: {},
  };
  componentDidMount() {
    this.props.getBankData();
    this.props.getCurrentBankData();
  }
  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.auth.bank_data, prevProps.auth.bank_data)) {
      this.setState({ mode_bank: "list" });
    }
  }
  toggleEdit = feature => {
    this.setState({ modal_edit: !this.state.modal_edit });
    if (!!feature?.key) {
      this.setState({ feature });
    }
  };
  closeAllModal = () => {
    this.setState({ modal_edit: false, modal_revision: false });
  };
  submitSetBank = bank_data => {
    this.props.setCurrentBankData(bank_data);
  };
  setModeBank = mode_bank => {
    this.setState({ mode_bank });
  };
  activateModalListBank = () => {
    this.setState({ modalListBank: true });
  };
  inActivateModalListBank = () => {
    this.setState({ modalListBank: false });
  };
  toggleAddBank = () => {
    this.setState({ modalAddBank: !this.state.modalAddBank });
  };
  toggleRevision = layer => {
    this.setState({ modal_revision: !this.state.modal_revision });
    if (!!layer?._id) {
      this.props.setLayerWOgeojson({
        geo_layer: layer,
      });
    }
  };
  onClickLayer = layer => {
    this.props.setLayerWOgeojson({
      geo_layer: layer,
    });
  };
  onClickBank = type_bank => {
    this.setState({ type_bank });
  };
  handleFixBank = () => {
    const { banks_filtered, type_bank } = this.state;
    if (banks_filtered.length === 0) {
      this.setState({ banks_filtered: cities_array });
    } else if (
      !banks_filtered.includes(type_bank) &&
      !cities_array.includes(type_bank)
    ) {
      this.setState({
        type_bank: banks_filtered[0] ? banks_filtered[0] : "",
      });
    }
  };
  handleChange = e => {
    e.preventDefault();
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (["type_bank"].includes(name)) {
      this.activateModalListBank();
      if (!value) {
        this.setState({
          [name]: value,
          banks_filtered: cities_array,
        });
      } else {
        const banks_filtered = cities_array.filter(type_bank =>
          type_bank.toLowerCase().includes(value.toLowerCase())
        );
        this.setState({
          [name]: value,
          banks_filtered,
        });
      }
    } else {
      this.setState({
        [name]: value,
      });
    }
  };
  handleSubmitBank = e => {
    e.preventDefault();
    const { banks_filtered, no_bank, name_bank, type_bank } = this.state;
    let type_bank_final;
    if (!!no_bank && !!name_bank && !!type_bank) {
      if (!banks_filtered.includes(type_bank)) {
        type_bank_final = banks_filtered[0];
      } else {
        type_bank_final = type_bank;
      }
      const body = {
        no_bank,
        name_bank,
        type_bank: type_bank_final,
      };
      this.props.addBank(body);
    }
  };
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const {
      modalListBank,
      type_bank,
      banks_filtered,
      no_bank,
      name_bank,
      mode_bank,
    } = this.state;
    const { bank_data, current_bank_data } = this.props.auth;
    const loading_user = this.props.auth.loading;
    const itemLoading_user = this.props.auth.itemLoading;
    let button_content;
    button_content = (
      <button type="submit" className="button_block">
        {dict["Add bank"][language]}
      </button>
    );
    if (loading_user && itemLoading_user === "addBank") {
      button_content = (
        <div className="button_block">{dict["Adding"][language]}..</div>
      );
    } else if (!type_bank || !name_bank || !no_bank) {
      button_content = (
        <div className="button_block" id="grey">
          {dict["Add bank"][language]}
        </div>
      );
    }
    const add_bank_content = (
      <form onSubmit={this.handleSubmitBank}>
        {bank_data.length > 0 && (
          <div
            onClick={this.setModeBank.bind(this, "list")}
            className="button_circle"
            id="blue_disable"
            style={{ cursor: "pointer" }}
          >
            <div className="button_circle_inside">x</div>
          </div>
        )}
        <div
          className="container_select_input"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="text_small">{dict["Bank"][language]}</div>
          <input
            name="type_bank"
            id="type_bank"
            type="text"
            value={type_bank}
            onChange={this.handleChange}
            onBlur={this.handleFixBank}
            onFocus={this.activateModalListBank}
          />
          {modalListBank && (
            <div
              style={{
                height: "23vh",
                overflowY: "auto",
                marginTop: "20px",
              }}
            >
              {banks_filtered.map((bank_element, idx) => {
                return (
                  <div
                    onClick={this.onClickBank.bind(this, bank_element)}
                    key={idx}
                    className="text_medium container_select_content noselect"
                  >
                    {bank_element}
                    {bank_element === type_bank && (
                      <img
                        src={icon_check}
                        width="25px"
                        alt="check"
                        style={{ marginLeft: "10px" }}
                      />
                    )}
                    {!banks_filtered.includes(type_bank) && idx === 0 && (
                      <img
                        src={icon_check}
                        width="25px"
                        alt="check"
                        style={{ marginLeft: "10px" }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div
          className="container_select_input"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="text_small" style={{ marginTop: "10px" }}>
            {e_money.includes(type_bank)
              ? dict["Mobile phone number"][language]
              : dict["Account number"][language]}
          </div>
          <input
            type="number"
            placeholder={dict["Account number"][language]}
            name="no_bank"
            id="no_bank"
            value={no_bank}
            onChange={this.handleChange}
            onFocus={this.inActivateModalListBank}
          />
          <div className="text_small" style={{ marginTop: "10px" }}>
            {dict["On behalf of the owner"][language]}
          </div>
          <input
            type="text"
            placeholder={dict["On behalf of the owner"][language]}
            name="name_bank"
            id="name_bank"
            value={name_bank}
            onChange={this.handleChange}
            onFocus={this.inActivateModalListBank}
          />
        </div>
        {button_content}
      </form>
    );
    const select_bank_content = (
      <div>
        {bank_data.map((bank_data, idx) => {
          const logic =
            current_bank_data.type_bank === bank_data.type_bank &&
            current_bank_data.no_bank === bank_data.no_bank;
          return (
            <div
              onClick={this.submitSetBank.bind(this, bank_data)}
              key={idx}
              className="text_medium container_select_content noselect"
            >
              {bank_data.type_bank.toUpperCase()}
              {"  "}
              {bank_data.name_bank.toUpperCase()}
              {logic && (
                <img
                  src={icon_check}
                  width="25px"
                  alt="check"
                  style={{ marginLeft: "10px" }}
                />
              )}
            </div>
          );
        })}
        <div
          onClick={this.setModeBank.bind(this, "add")}
          className="button_block"
          style={{ marginTop: "30px" }}
        >
          {dict["Add another bank"][language]}
        </div>
      </div>
    );
    let bank_content = select_bank_content;
    if (bank_data.length === 0 || mode_bank === "add") {
      bank_content = add_bank_content;
    }

    return <main>{bank_content}</main>;
  }
}
EditBank.propTypes = {
  getBankData: PropTypes.func.isRequired,
  addBank: PropTypes.func.isRequired,
  getCurrentBankData: PropTypes.func.isRequired,
  setCurrentBankData: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
  form: state.form,
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, {
  getBankData,
  addBank,
  getCurrentBankData,
  setCurrentBankData,
})(EditBank);
