//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import Modal from "../common_modal/Modal";
import UploadBankTransfer from "../form_paid/UploadBankTransfer";
import TextFieldGroup from "../common_input/TextFieldGroup";

//Redux function
import {
  create_payment_form_new,
  update_bank,
} from "../../App/actions/paymentActions";

//Picture Asset
import qris from "../../Assets/img/qris.png";
import qris_info from "../../Assets/img/qris_info.png";

//General Function
import Bank_list from "../../Data/Bank_list";
import dict from "../../Data/dict.json";

const persen_admin = 30; //biaya admin 30 persen

class PaymentForm extends Component {
  state = {
    errors: {},
    type_bank: "",
    no_bank: "",
    name_bank: "",
    rekeningCopied: false,
    amountCopied: false,
    payment_methode: "qris", // qris | bank_transfer
    amounts_addition: 0,
    modal_bank: false,

    mode_amount: "quota", //quota | registered
  };

  toggle_bank = () => {
    this.setState({ modal_bank: !this.state.modal_bank });
  };

  handleCopyToClipBoard = (text, type) => {
    navigator.clipboard.writeText(text);
    this.setState({
      [type]: true,
    });
    setTimeout(
      function () {
        this.setState({ [type]: false });
      }.bind(this),
      1200
    );
  };

  on_change = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  on_submit = (e) => {
    e.preventDefault();
    let { layer_id } = this?.props?.layer;
    const { payment_methode, amounts_addition, mode_amount } = this.state;
    const body = {
      geo_layer_id: layer_id,
      amounts_addition,
      payment_methode,
      mode_amount,
    };

    this.props.create_payment_form_new(body);
  };

  on_update_bank = (e) => {
    e.preventDefault();
    let { layer_id, geo_layer_list } = this.props.layer;
    const { type_bank, no_bank, name_bank } = this.state;

    let geo_layer = geo_layer_list.find(
      (e) => e.geo_layer._id === layer_id
    )?.geo_layer;

    const payment_id = geo_layer?.formPayment?.payment?._id;

    const body = {
      payment_id,
      geo_layer_id: layer_id,
      type_bank,
      no_bank,
      name_bank,
    };
    this.props.update_bank(body);
    this.setState({ modal_bank: false });
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    // const before_status = [
    //   "not_propose",
    //   "propose",
    //   "rejected",
    //   "recruit",
    //   "close_recruit",
    // ];
    const after_status = [
      "wait_purchase",
      "user_purchase",
      "success_payment",
      "validation",
      "post_payment",
      "finish",
    ];

    const {
      errors,
      type_bank,
      no_bank,
      name_bank,
      amountCopied,
      payment_methode,
      amounts_addition,
      modal_bank,
      mode_amount,
    } = this.state;

    let { loadingProcess, itemLoading, layer_id, geo_layer_list } =
      this.props.layer;

    let geo_layer = geo_layer_list.find(
      (e) => e.geo_layer._id === layer_id
    )?.geo_layer;

    const payment_methode_db = geo_layer?.formPayment?.payment?.payment_methode;
    const picture_url = geo_layer?.formPayment?.payment?.picture_url;
    const no_bank_db = geo_layer?.formPayment?.payment?.bank_data?.no_bank;

    const status = geo_layer?.formStatus?.status;

    const quota = geo_layer?.formPayment?.quota;

    const amount = geo_layer?.formPayment?.payment?.amount
      ? geo_layer?.formPayment?.payment?.amount
      : 0;
    // const amount_dec = new Intl.NumberFormat("id-ID", {
    //   style: "decimal",
    // }).format(amount);
    const surveyors = geo_layer?.formPayment?.surveyors
      ? geo_layer?.formPayment?.surveyors
      : [];
    const per_insentif = geo_layer?.formPayment?.per_insentif;
    const amounts = surveyors.map((surveyor) => surveyor?.amount);
    const amounts_sum = amounts.reduce((a, b) => a + b, 0);

    let final_sum = 0;

    if (mode_amount === "quota") {
      final_sum = quota;
    } else {
      final_sum = amounts_sum;
    }

    //Pricing stuff
    const price_pure = final_sum * per_insentif;
    const administration_price = parseInt((persen_admin / 100) * price_pure);
    const price = price_pure + administration_price;
    const decimal_price_pure = new Intl.NumberFormat("id-ID", {
      style: "decimal",
    }).format(price_pure);
    const decimal_administration_price = new Intl.NumberFormat("id-ID", {
      style: "decimal",
    }).format(administration_price);
    const decimal_price = new Intl.NumberFormat("id-ID", {
      style: "decimal",
    }).format(price);
    const decimal_amount = new Intl.NumberFormat("id-ID", {
      style: "decimal",
    }).format(amounts_sum);
    const decimal_insentif = new Intl.NumberFormat("id-ID", {
      style: "decimal",
    }).format(per_insentif);

    // let button_content;
    // if (loadingProcess && itemLoading === "create_payment_form_new") {
    //   button_content = (
    //     <div className="button_block">
    //       <SpinnerWhite
    //         width={30}
    //         unik="loading_item_list"
    //         marginTop="0px"
    //         center={true}
    //         border={0.1}
    //       />
    //     </div>
    //   );
    // } else if (!name_bank || !no_bank || !type_bank) {
    //   button_content = (
    //     <div className="button_block" id="grey">
    //       {dict["Next"][language]}
    //     </div>
    //   );
    // } else {
    //   button_content = (
    //     <button className="button_block" type="submit">
    //       {dict["Next"][language]}
    //     </button>
    //   );
    // }

    const modal_bank_content = modal_bank && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_bank}
        onClose={this.toggle_bank}
      >
        <div className="box-body text_center">
          <form onSubmit={this.on_update_bank}>
            <TextFieldGroup
              type="text"
              placeholder={dict["Your Bank"][language]}
              name="type_bank"
              id="type_bank"
              value={type_bank}
              onChange={this.on_change}
              error={errors.type_bank}
              list="list_bank"
            />
            <datalist id="list_bank">
              {Bank_list.map((bank_element, idx) => {
                return <option value={bank_element} key={idx} />;
              })}
            </datalist>
            <br />
            <TextFieldGroup
              type="number"
              placeholder={dict["Account Number"][language]}
              name="no_bank"
              id="no_bank"
              value={no_bank}
              onChange={this.on_change}
              error={errors.no_bank}
            />
            <br />
            <TextFieldGroup
              type="text"
              placeholder={dict["Account Name"][language]}
              name="name_bank"
              id="name_bank"
              value={name_bank}
              onChange={this.on_change}
              error={errors.name_bank}
            />
            <br />
            <button className="button" id="blue" type="submit">
              Simpan
            </button>
          </form>
        </div>
      </Modal>
    );

    return (
      <main className="text_center" style={{ minHeight: "100vh" }}>
        {modal_bank_content}
        <section
          className="container_grey half text_left"
          style={{ minHeight: "300px" }}
        >
          <p className="text_header">{dict["Payment Detail"][language]}</p>
          <table style={{ width: "100%" }} className="table text_bold">
            <tbody>
              {/* <tr>
                <td>{dict["Number of potential surveyors"][language]}</td>
                <td style={{ textAlign: "right" }}>{surveyors.length}</td>
              </tr> */}
              <tr>
                <td>Jumlah data dari surveyor yang mendaftar</td>
                <td style={{ textAlign: "right" }}>{amounts_sum}</td>
              </tr>
              <tr>
                <td>Jumlah data yang Anda atur di awal</td>
                <td style={{ textAlign: "right" }}>{quota}</td>
              </tr>
              {/* <tr>
                <td colSpan={2}>
                  <p>Opsi:</p>
                  <p>1. Bayar sesuai dengan yang mendaftar saja</p>
                  <p>
                    2. Bayar sesuai target data di awal (jika pada akhirnya data
                    yang masuk lebih sedikit dari uang yang didepositkan, uang
                    sisa akan dikembalikan)
                  </p>
                  <p>3. Tambah sekian jumlah data dari target sebelumnya</p>
                </td>
              </tr> */}
            </tbody>
          </table>
          <br />
          <p>
            Pilih jumlah data yang mau dibayar (dari kuota awal atau surveyor
            yang sudah mendaftar)
          </p>
          <select
            name="mode_amount"
            onChange={this.on_change}
            value={mode_amount}
          >
            <option value="quota">Kuota awal</option>
            <option value="registered">Yang sudah mendaftar saja</option>
          </select>
          <table style={{ width: "100%" }} className="table">
            <tbody>
              <tr>
                <td>{dict["Survey cost"][language]}</td>
                <td style={{ textAlign: "right" }}>
                  Rp {decimal_insentif} x {decimal_amount}:
                </td>
                <td style={{ textAlign: "right" }}>
                  Rp <b>{decimal_price_pure}</b>
                </td>
              </tr>
              <tr>
                <td>{dict["Service fee"][language]}</td>
                <td style={{ textAlign: "right" }}>{persen_admin}%:</td>
                <td style={{ textAlign: "right" }}>
                  Rp <b>{decimal_administration_price}</b>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <section className="two_container_auto">
            <p>{dict["Total"][language]}</p>
            <section>
              <p style={{ textAlign: "right" }} className="text_big">
                Rp.{decimal_price}
              </p>
              {after_status.includes(status) && (
                <div style={{ textAlign: "right" }}>
                  {amountCopied ? (
                    <div className="button">{dict["Copied"][language]}</div>
                  ) : (
                    <div
                      onClick={this.handleCopyToClipBoard.bind(
                        this,
                        amount,
                        "amountCopied"
                      )}
                      className="button"
                    >
                      {dict["Copy"][language]}
                    </div>
                  )}
                </div>
              )}
            </section>
          </section>
        </section>

        <section
          className="container_grey half text_left"
          style={{ minHeight: "300px" }}
        >
          <p className="text_header">{dict["Choose how to pay"][language]}</p>
          <select
            name="payment_methode"
            value={payment_methode}
            onChange={this.on_change}
          >
            <option value="qris">QRIS</option>
            <option value="bank_transfer">Bank transfer</option>
          </select>
          {payment_methode === "qris" ? (
            <main>
              <section className="text_center">
                <img
                  src={qris_info}
                  alt="qris_info"
                  style={{ width: "150px", maxWidth: "100%" }}
                  className="margin_bottom"
                />
              </section>

              <section className="text_center">
                <img
                  src={qris}
                  alt="qris"
                  style={{ width: "150px", maxWidth: "100%" }}
                />
              </section>

              <section className="text_center">
                <p className="text_inferior">MAPID (ID2022174374084)</p>
              </section>
            </main>
          ) : (
            <main id="white" className="box">
              <p className="text_bold">Bank Transfer:</p>
              <br />
              <br />
              <p className="text_header">130 008 877 8785</p>
              <p className="text_bold">Bank Mandiri</p>
              <p className="text_bold">PT Multi Areal Planing Indonesia</p>
            </main>
          )}
        </section>

        <section
          className="container_grey half text_left"
          style={{ minHeight: "300px" }}
        >
          <p className="text_header">
            {dict["Confirm your payment"][language]}
          </p>
          <p className="text_inferior">
            Jika anda telah melakukan pembayaran dengan metode pembayaran yang
            anda pilih, konfirmasikan dengan langkah di bawah:
          </p>
          {loadingProcess && itemLoading === "create_payment_form_new" ? (
            <button className="button_big" id="grey">
              ...
            </button>
          ) : status === "user_purchase" ? (
            <section className="margin_top">
              <p className="text_bold text_center">
                Anda telah melakukan konfirmasi pembayaran, sistem keuangan kami
                akan melakukan pengecekan.
              </p>
            </section>
          ) : status === "success_payment" ? (
            <section className="margin_top">
              <p className="text_bold text_center">
                Deposit sudah dikonfirmasi oleh sistem
              </p>
            </section>
          ) : payment_methode === "qris" ? (
            <button
              className="button_big text_center"
              id="green"
              onClick={this.on_submit}
            >
              {dict["Confirm"][language]}
            </button>
          ) : (
            <section>
              <UploadBankTransfer
                payment_methode={payment_methode}
                amounts_addition={amounts_addition}
              />
            </section>
          )}
          <br />
          <table className="table" style={{ width: "100%" }}>
            <tbody>
              {payment_methode_db && (
                <tr>
                  <td>
                    <p className="text_medium">Metode pembayaran:</p>
                  </td>
                  <td>
                    <b className="button" id="grey">
                      {payment_methode_db?.toUpperCase()}
                    </b>
                  </td>
                </tr>
              )}
              {picture_url && (
                <tr>
                  <td>
                    <p className="text_medium">Resi pembayaran:</p>
                    <br />
                    <br />
                    {!no_bank_db ? (
                      <section>
                        <button
                          className="button"
                          id="blue"
                          onClick={this.toggle_bank}
                        >
                          Lengkapi data bank
                        </button>
                        <p className="text_inferior">
                          Kelengkapan data bank bisa membantu sistem dalam
                          verifikasi pembayaran Anda.
                        </p>
                      </section>
                    ) : (
                      <section>Bank data sudah diisi</section>
                    )}
                  </td>
                  <td>
                    <img
                      alt="Resi"
                      src={picture_url}
                      style={{ width: "200px", maxWidth: "100%" }}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
});

export default connect(mapStateToProps, {
  create_payment_form_new,
  update_bank,
})(PaymentForm);
