const initial_state = {
  loading_status: false,
  loading_item: "",
  admin_information_list: [],
  quota_price_list: [],
  error_object: {},
  sidebar_right_status: false,
  folder_kai_timeseries: {},
  folder_list_active: [],
  poi_tipe_1_selected: [],
  poi_tipe_2_selected: [],
  poi_all_selected: false,
  save_layer_progress: 0,

  //fitur share layer
  feature_will_be_share: {},
  feature_will_be_shared_key: "",

  //list get administrasi by API
  list_all_provinsi: [],
  list_all_kota: [],
  list_all_kecamatan: [],
  list_all_kelurahan: [],

  //list administrasi by search
  list_kota_by_search: [],
  list_kecamatan_by_search: [],
  list_kelurahan_by_search: [],
  capex_value: 0,
  mode_grid: "size_grid", //size_grid || count_grid
  shared_link_list: [],
  shared_link_check: {},
  user_search_object: {},
  group_search_list: [],

  //state insight
  demographic_parent: "UMUM",
  demographic_child_idx_list: [0],
  chart_js_aggregate_train: {},
  feature_key_selected: "",

  //pop_up layer
  layer_id_popup: "",
  popupInfo: null,

  //state-state untuk algoritma genangan
  success_status: false,
  error_status: false,
  error_message: "",
  tipe_genangan: "",
  total_progress_genangan: 7,
  current_progress_genangan: 0,

  //kumpulan state modal
  modal_buy_license: false,
  modal_share: false,
  modal_pop_up_genangan: false,
  modal_pop_up_layer: false,
};

export default function propertiesReducer(state = initial_state, action) {
  switch (action.type) {
    case "set_loading":
      return {
        ...state,
        loading_item: action.payload,
        loading_status: true,
      };

    case "clear_loading":
      return {
        ...state,
        loading_item: "",
        loading_status: false,
      };

    case "set_value_properties":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case "push_value_properties":
      return {
        ...state,
        [action.payload.key]: [
          ...state[action.payload.key],
          action.payload.value,
        ],
      };

    case "pull_value_properties_string":
      return {
        ...state,
        ...pull_value_properties_string(
          action.payload.key,
          state[action.payload.key],
          action.payload.value
        ),
      };

    case "pull_value_properties_object":
      return {
        ...state,
        ...pull_value_properties_object(
          action.payload.key,
          state[action.payload.key],
          action.payload.value,
          action.payload.id
        ),
      };
    default:
      return state;
  }
}

const pull_value_properties_string = (key, list, value) => {
  list = list.filter((item) => item !== value);
  return { [key]: list };
};

const pull_value_properties_object = (key, list, value, id) => {
  list = list.filter((item) => item[id] !== value);
  return { [key]: list };
};
