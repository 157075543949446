/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import Draw from "@urbica/react-map-gl-draw";
import calculate_length from "@turf/length";
import calculate_area from "@turf/area";
import centroid from "@turf/centroid";
import along from "@turf/along";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import {
  set_value_toolbox,
  generate_elevation,
} from "../../App/actions/toolboxActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import { drawStyle } from "../layer/DrawStyle";
import uuid from "../../App/validation/uuid";

/*NON IMPORT*/

class TOOLBOX_DRAWING extends Component {
  state = {};

  on_draw_create = (event) => {
    const { mode_toolbox } = this.props.toolbox;
    const geojson = event;
    let feature = geojson?.features?.[0];
    feature.properties.uuid = uuid();
    let uuid_name;
    if (mode_toolbox === "features_distance") {
      let {
        features_distance,
        features_distance_label,
        features_distance_center,
      } = this.props.toolbox;
      uuid_name = "uuid_distance";
      const length_km = calculate_length(feature, {
        units: "kilometers",
      }).toFixed(2);
      const length_m = length_km * 1000;
      const name = `D-${features_distance.length + 1}`;
      feature.properties.name = name;
      feature.properties.length_km = length_km;
      feature.properties.length_m = length_m;
      features_distance.push(feature);
      const center_length = length_km / 2;
      let point = along(feature, center_length, { units: "kilometers" });
      point.properties.uuid = feature.properties.uuid;
      point.properties.name = name;
      const coordinates_a = feature.geometry.coordinates[0];
      const coordinates_b =
        feature.geometry.coordinates[feature.geometry.coordinates.length - 1];
      const features_label = [
        {
          type: "Feature",
          properties: {
            name: "A",
            parent_uuid: feature.properties.uuid,
          },
          geometry: {
            type: "Point",
            coordinates: coordinates_a,
          },
        },
        {
          type: "Feature",
          properties: {
            name: "B",
            parent_uuid: feature.properties.uuid,
          },
          geometry: {
            type: "Point",
            coordinates: coordinates_b,
          },
        },
      ];
      features_distance_label = [...features_distance_label, ...features_label];
      features_distance_center.push(point);
      this.props.set_value_toolbox({
        key: "features_distance",
        value: features_distance,
      });
      this.props.set_value_toolbox({
        key: "features_distance_label",
        value: features_distance_label,
      });
      this.props.set_value_toolbox({
        key: "features_distance_center",
        value: features_distance_center,
      });
    } else if (mode_toolbox === "features_area") {
      let { features_area, features_area_center } = this.props.toolbox;
      uuid_name = "uuid_area";

      const area_meter_square = calculate_area(feature).toFixed(0);
      const area_hectare = parseFloat((area_meter_square / 10_000).toFixed(2));
      const name = `A-${features_area.length + 1}`;

      feature.properties.area_meter_square = area_meter_square;
      feature.properties.area_hectare = area_hectare;
      feature.properties.name = name;
      features_area.push(feature);

      const geojson = {
        type: "FeatureCollection",
        features: [feature],
      };

      let point = centroid(geojson);
      point.properties.uuid = feature.properties.uuid;
      point.properties.name = name;

      features_area_center.push(point);

      this.props.set_value_toolbox({
        key: "features_area",
        value: features_area,
      });
      this.props.set_value_toolbox({
        key: "features_area_center",
        value: features_area_center,
      });
    } else if (mode_toolbox === "features_elevation") {
      let { features_elevation, features_elevation_center } =
        this.props.toolbox;
      uuid_name = "uuid_elevation";
      const length_km = calculate_length(feature, {
        units: "kilometers",
      }).toFixed(2);
      const length_m = length_km * 1000;
      const name = `E-${features_elevation.length + 1}`;
      feature.properties.name = name;
      feature.properties.length_km = length_km;
      feature.properties.length_m = length_m;
      const center_length = length_km / 2;
      let point = along(feature, center_length, { units: "kilometers" });
      point.properties.uuid = feature.properties.uuid;
      point.properties.name = name;
      features_elevation.push(feature);
      features_elevation_center.push(point);
      this.props.set_value_toolbox({
        key: "features_elevation_center",
        value: features_elevation_center,
      });
      this.props.set_value_toolbox({
        key: "features_elevation",
        value: features_elevation,
      });
      this.props.generate_elevation(feature);
    } else if (mode_toolbox === "features_radius") {
      let { default_radius_km, default_radius_m, features_radius } =
        this.props.toolbox;
      uuid_name = "uuid_radius";
      const name = `R-${features_radius.length + 1}`;
      feature.properties.name = name;
      //feature.properties.poi_name = poi_name;
      feature.properties.radius_km = default_radius_km;
      feature.properties.radius_m = default_radius_m;
      features_radius.push(feature);
      this.props.set_value_toolbox({
        key: "features_radius",
        value: features_radius,
      });
    }
    this.props.set_value_toolbox({
      key: "mode_draw",
      value: "simple_select",
    });
    this.props.set_value_toolbox({
      key: uuid_name,
      value: feature.properties.uuid,
    });
  };

  on_draw_update = (event) => {
    // console.log("on_draw_update=", event);
  };

  on_change = (event) => {
    // console.log("on_change=", event);
  };

  on_draw_mode_change = (event) => {
    this.props.set_value_toolbox({
      key: "mode_draw",
      value: "simple_select",
    });
  };

  render() {
    //local storage

    //local state

    //global props
    const { geojson_draw, mode_draw, draw_toolbox_status } = this.props.toolbox;

    //content

    return (
      <main>
        {draw_toolbox_status && (
          <Draw
            data={geojson_draw}
            mode={mode_draw}
            onDrawCreate={this.on_draw_create}
            onDrawUpdate={this.on_draw_update}
            onChange={this.on_change}
            onDrawModeChange={this.on_draw_mode_change}
            combineFeaturesControl={false}
            uncombineFeaturesControl={false}
            pointControl={false}
            lineStringControl={false}
            polygonControl={false}
            trashControl={false}
            styles={drawStyle}
          />
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  toolbox: state.toolbox,
});

export default connect(mapStateToProps, {
  set_value_toolbox,
  generate_elevation,
})(TOOLBOX_DRAWING);
