import axios from "axios";
import polygon_smooth from "@turf/polygon-smooth";
import along from "@turf/along";
import calculate_length from "@turf/length";
import calculate_area from "@turf/area";
import convex from "@turf/convex";

/*GET METHODE*/

//get_genangan
export const get_genangan = (params) => async (dispatch) => {
  try {
    console.log("params=", params);
    let properties = {};
    const { longitude, latitude, genangan_cm, feature_key } = params;
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "success_status",
        value: false,
      },
    });
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "error_status",
        value: false,
      },
    });
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "error_message",
        value: "",
      },
    });
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "modal_pop_up_genangan",
        value: true,
      },
    });
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "popupInfo",
        value: null,
      },
    });
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "modal_pop_up_layer",
        value: false,
      },
    });
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "loading_status",
        value: true,
      },
    });
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "loading_item",
        value: "get_genangan",
      },
    });
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "feature_key_loading_genangan",
        value: feature_key,
      },
    });
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "feature_key_fly_genangan",
        value: feature_key,
      },
    });
    const res_tipe = await axios.get(
      `https://edrg5otbpe.execute-api.ap-southeast-3.amazonaws.com/prod/get?long=${longitude}&lat=${latitude}&genangan_cm=${genangan_cm}&feature_key=${feature_key}`
    );
    const tipe = res_tipe.data;
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "tipe_genangan",
        value: tipe,
      },
    });
    let res;
    let data;
    if (tipe === "lidar") {
      res = await axios.get(
        `https://eoxlwv7if2.execute-api.ap-southeast-3.amazonaws.com/prod/get?long=${longitude}&lat=${latitude}&genangan_cm=${genangan_cm}&feature_key=${feature_key}`
      );
      const geojson_polygon_smooth = polygon_smooth(res.data, {
        iterations: 2,
      });
      data = geojson_polygon_smooth.features[0];
      const area_meter_square = parseFloat(calculate_area(data).toFixed(0));
      const area_hectare = parseFloat((area_meter_square / 10_000).toFixed(4));
      data.properties = { ...properties, area_meter_square, area_hectare };
      dispatch({
        type: "push_value_sini",
        payload: {
          key: "features_genangan",
          value: [data],
        },
      });
      dispatch({
        type: "push_value_sini",
        payload: {
          key: "features_genangan_feature_key",
          value: [feature_key],
        },
      });
      dispatch({
        type: "state_update",
      });
      dispatch({
        type: "set_value_properties",
        payload: {
          key: "success_status",
          value: true,
        },
      });
      dispatch({
        type: "set_value_properties",
        payload: {
          key: "loading_status",
          value: false,
        },
      });
    } else {
      //START

      res = await axios.get(
        `https://ehz301d2sg.execute-api.ap-southeast-3.amazonaws.com/prod/get?long=${longitude}&lat=${latitude}&genangan_cm=${genangan_cm}&feature_key=${feature_key}`
      );
      dispatch({
        type: "set_value_properties",
        payload: {
          key: "current_progress_genangan",
          value: 1,
        },
      });
      data = res.data;
      properties = data.properties;
      let features_final = [data];

      /*
      ide recursive

      fungsi dasar:

      input:  feature_poligon_genangan_input
      proses: looping sample titik di perimeter
      output: feature_poligon_genangan_output (lebih luas jika ada perambatan)
      break: setelah index loop sampai pada jumlah iterasi yang ditentukan
      */

      const feature_line = {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: res?.data?.geometry?.coordinates?.[0],
        },
      };
      const length_km = parseFloat(
        calculate_length(data, {
          units: "kilometers",
        })
      );

      console.log("length_km=", length_km);

      const unit = 6; //jumlah sample di sekitar parameter
      const delta = length_km / unit;
      let array_along = [];
      for (let index = 0; index < unit; index++) {
        const value = parseFloat(delta * index);
        array_along.push(value);
      }

      const delay_constant = 0;
      const delay_promise = () =>
        new Promise((res) => setTimeout(res, delay_constant));
      const parent_function = () => {
        return array_along.reduce(
          (last_promise, item, index) =>
            last_promise.then((result_sum) =>
              child_function(item, index).then((result_current) => [
                ...result_sum,
                result_current,
              ])
            ),
          Promise.resolve([])
        );
      };

      const child_function = async (item, index) => {
        return delay_promise().then(() => {
          const core_function = async () => {
            try {
              const options = { units: "kilometers" };
              let point = along(feature_line, item, options);
              const longitude_local = point.geometry.coordinates[0];
              const latitude_local = point.geometry.coordinates[1];
              res = await axios.get(
                `https://ehz301d2sg.execute-api.ap-southeast-3.amazonaws.com/prod/get?long=${longitude_local}&lat=${latitude_local}&genangan_cm=${genangan_cm}&feature_key=${feature_key}`
              );
              const feature_local = res.data;
              features_final.push(feature_local);
              dispatch({
                type: "set_value_properties",
                payload: {
                  key: "current_progress_genangan",
                  value: index + 2,
                },
              });
            } catch (error) {}
          };
          return core_function();
        });
      };

      parent_function().then(() => {
        const geojson_final = {
          type: "FeatureCollection",
          features: features_final,
        };
        const feature_convex = convex(geojson_final, {
          concavity: 1,
        });
        const geojson_polygon_smooth = polygon_smooth(feature_convex, {
          iterations: 2,
        });
        data = geojson_polygon_smooth.features[0];
        const area_meter_square = parseFloat(calculate_area(data).toFixed(0));
        const area_hectare = parseFloat(
          (area_meter_square / 10_000).toFixed(4)
        );
        data.properties = { ...properties, area_meter_square, area_hectare };
        dispatch({
          type: "push_value_sini",
          payload: {
            key: "features_genangan",
            value: [data],
          },
        });
        dispatch({
          type: "push_value_sini",
          payload: {
            key: "features_genangan_feature_key",
            value: [feature_key],
          },
        });
        dispatch({
          type: "state_update",
        });
        dispatch({
          type: "set_value_properties",
          payload: {
            key: "success_status",
            value: true,
          },
        });
        dispatch({
          type: "set_value_properties",
          payload: {
            key: "loading_status",
            value: false,
          },
        });
      });

      //FINISH
    }
  } catch (error) {
    let error_message;
    if (error.response) {
      /*
      The request was made and the server responded with a status code that falls out of the range of 2xx

      error.response.data
      error.response.status
      error.response.headers
      */
      error_message = error?.response?.data?.message;
    } else if (error.request) {
      // The request was made but no response was received
      error_message = error.request;
    } else {
      // Something happened in setting up the request that triggered an Error
      error_message = error.message;
    }
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "loading_status",
        value: false,
      },
    });
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "error_status",
        value: true,
      },
    });
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "error_message",
        value: error_message,
      },
    });
    dispatch({
      type: "set_value_properties",
      payload: {
        key: "modal_pop_up_genangan",
        value: true,
      },
    });
  }
};
