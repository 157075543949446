import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";
import { getDetailLayerById } from "./layerNewActions";
import get_aggregate_train from "../validation/get_aggregate_train";

const SERVER_URL = geoServerBaseUrl;

/*POST METHODE*/

export const push_resume_kai = (body) => async (dispatch) => {
  //push_resume_kai
  /*
  body = {
    geo_project_id,
    folder_id,
    geo_layer_id,
    year,
    timeseries_object = {
      timeseries_mode: "kai",
      field_name_array_month: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MEI",
        "JUN",
        "JUL",
        "AGS",
        "SEP",
        "OKT",
        "NOV",
        "DES",
      ],
      field_name_relasi: "RELASI",
      field_name_jumlah_kereta: "Jumlah KA per 30 menit",
      field_name_jumlah_pnp: "Jumlah pnp 30 menit",
      field_name_grand_total: "Grand Total",
      field_name_max_per_kereta: "Max per Kereta",
      field_name_jam_berangkat: "JAM BERANGKAT",
      field_name_jam_datang: "JAM DATANG",
    }
  }
  */

  try {
    dispatch(set_loading("push_resume_kai"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    await axios.post(
      SERVER_URL + "/chart_editors/push_resume_kai",
      body,
      config
    );
    dispatch({
      type: "push_resume_kai",
      payload: body,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const reset_resume_kai = (body) => async (dispatch) => {
  //reset_resume_kai
  /*
  body = {
    geo_project_id,
    folder_id,
  }
  */

  try {
    dispatch(set_loading("reset_resume_kai"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    await axios.post(
      SERVER_URL + "/chart_editors/reset_resume_kai",
      body,
      config
    );
    dispatch({
      type: "reset_resume_kai",
      payload: body,
    });
    dispatch({
      type: "status_action",
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const push_chart_list = (body) => async (dispatch) => {
  //push_chart_list
  /*
   const geo_layer_id = req.body.geo_layer_id;
   let chart_object = req.body.chart_object;
   chart_object = {
      ina                      : String, //judul bahasa indonesia
      eng                      : String, //judul bahasa inggris
      x_axis_field_key         : String,
      y_axis_field_key         : String,
      chart_type               : String, //line || bar || pie
      x_element_custom_order   : Array,
      color_default            : String,
      color_hover              : String,
      is_npv_kai               : Boolean,
      wacc_field_key           : String
  }
  */
  try {
    dispatch(set_loading("push_chart_list"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    const res = await axios.post(
      SERVER_URL + "/chart_editors/push_chart_list",
      body,
      config
    );
    dispatch({
      type: "push_chart_list",
      payload: res.data,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const edit_chart_list = (body) => async (dispatch) => {
  //edit_chart_list
  /*
   const geo_layer_id = req.body.geo_layer_id;
   let chart_object = req.body.chart_object;
   chart_object = {
      ina                      : String, //judul bahasa indonesia
      eng                      : String, //judul bahasa inggris
      x_axis_field_key         : String,
      y_axis_field_key         : String,
      chart_type               : String, //line || bar || pie
      x_element_custom_order   : Array,
      color_default            : String,
      color_hover              : String,
      is_npv_kai               : Boolean,
      wacc_field_key           : String
  }
  */
  try {
    dispatch(set_loading("edit_chart_list"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    await axios.put(
      SERVER_URL + "/chart_editors/edit_chart_list",
      body,
      config
    );
    dispatch({
      type: "edit_chart_list",
      payload: body,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const clone_layer = (body) => async (dispatch) => {
  //clone_layer
  /*
  const body = {
  "geo_layer_id": "649fb6e7fda52321502ed33d",
  "geo_project_id": "63dcf592a54b9cc8ee2f308e",
  "user_id": "5cb88367ce451995f7ed86f2",
  "new_name": "MACHRUS TEST APRAISAL"
}
*/
  try {
    dispatch(set_loading("clone_layer"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };

    await axios.post(SERVER_URL + "/properties/clone_layer", body, config);

    dispatch({
      type: "clone_layer",
      payload: body,
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const edit_radius_pembanding = (body) => async (dispatch) => {
  //edit_radius_pembanding
  /*
  const body = {
    geo_layer_id,
    radius_pembanding
  }
  */
  try {
    dispatch(set_loading("edit_radius_pembanding"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };

    await axios.post(
      SERVER_URL + "/properties/edit_radius_pembanding",
      body,
      config
    );

    dispatch({
      type: "edit_radius_pembanding",
      payload: body,
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const edit_percent_value_field_key = (body) => async (dispatch) => {
  //edit_percent_value_field_key
  /*
  const body = {
    geo_layer_id,
    field_key
  }
  */
  try {
    dispatch(set_loading("edit_percent_value_field_key"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    await axios.post(
      SERVER_URL + "/properties/edit_percent_value_field_key",
      body,
      config
    );
    dispatch({
      type: "edit_percent_value_field_key",
      payload: body,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const edit_rev_calculation_field_key = (body) => async (dispatch) => {
  //edit_rev_calculation_field_key
  /*
  const body = {
    geo_layer_id,
    field_key
  }
  */
  try {
    dispatch(set_loading("edit_rev_calculation_field_key"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };

    await axios.post(
      SERVER_URL + "/properties/edit_rev_calculation_field_key",
      body,
      config
    );

    dispatch({
      type: "edit_rev_calculation_field_key",
      payload: body,
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const edit_final_calculation_field_key = (body) => async (dispatch) => {
  //edit_final_calculation_field_key
  /*
  const body = {
    geo_layer_id,
    field_key
  }
  */
  try {
    dispatch(set_loading("edit_final_calculation_field_key"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };

    await axios.post(
      SERVER_URL + "/properties/edit_final_calculation_field_key",
      body,
      config
    );

    dispatch({
      type: "edit_final_calculation_field_key",
      payload: body,
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const edit_laporan_pdf = (body) => async (dispatch) => {
  //edit_laporan_pdf
  try {
    dispatch(set_loading("edit_laporan_pdf"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };

    await axios.post(SERVER_URL + "/properties/edit_laporan_pdf", body, config);

    dispatch({
      type: "edit_laporan_pdf",
      payload: body,
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

/*GET METHODE*/

export const get_detail_many_layer = (params) => async (dispatch) => {
  //get_detail_many_layer
  try {
    const { layer_list, geo_project_id, folder_id } = params;

    dispatch(set_loading("get_detail_many_layer"));
    const delay_c = 0;
    const delay = () => new Promise((res) => setTimeout(res, delay_c));
    const parent_function = () => {
      return layer_list.reduce(
        (last_promise, object) =>
          last_promise.then((result_sum) =>
            child_function(object).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };

    const child_function = async (geo_layer_id) => {
      return delay().then(() => {
        const core_function = async () => {
          try {
            const params = {
              is_ignore_set_layer_id: true,
              geo_layer_id,
              geo_project_id,
              folder_id,
            };
            await dispatch(getDetailLayerById(params));
            dispatch(clear_loading());
          } catch (error) {
            dispatch(clear_loading());
          }
        };
        return core_function();
      });
    };

    parent_function().then(() => {
      dispatch({
        type: "status_action",
      });
      dispatch(clear_loading());
    });
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const get_admin_information_list = () => async (dispatch) => {
  //get_admin_information_list
  try {
    dispatch(set_loading("get_admin_information_list"));

    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };

    const res = await axios.get(
      SERVER_URL + "/admin_informations/get_admin_information_list",
      config
    );

    dispatch({
      type: "set_value_properties",
      payload: {
        key: "admin_information_list",
        value: res.data,
      },
    });

    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const get_quota_price_list = () => async (dispatch) => {
  //get_quota_price_list
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };

    const res = await axios.get(
      SERVER_URL + "/admin_informations/get_quota_price_list",
      config
    );

    dispatch({
      type: "set_value_properties",
      payload: {
        key: "quota_price_list",
        value: res.data,
      },
    });
  } catch (error) {
    dispatch(clear_loading());
  }
};

/*DELETE METHODE*/

export const delete_resume_kai = (body) => async (dispatch) => {
  //delete_resume_kai
  /*
  body = {
    geo_project_id,
    folder_id,
    geo_layer_id,
    year,
  }
  */

  try {
    dispatch(set_loading("push_resume_kai"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    await axios.delete(
      SERVER_URL + "/chart_editors/delete_resume_kai",
      body,
      config
    );
    dispatch({
      type: "delete_resume_kai",
      payload: body,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

export const delete_chart_list = (params) => async (dispatch) => {
  //delete_chart_list
  /*
  params = {
    geo_project_id,
    chart_id
  }
  */
  try {
    dispatch(set_loading("delete_chart_list"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    await axios.delete(
      SERVER_URL +
        `/chart_editors/delete_chart_list?geo_layer_id=${params.geo_layer_id}&chart_id=${params.chart_id}`,
      config
    );
    dispatch({
      type: "delete_chart_list",
      payload: params,
    });
    dispatch(clear_loading());
  } catch (e) {
    dispatch(clear_loading());
  }
};

/*NON-API*/

export const clear_layer_id = () => {
  return {
    type: "clear_layer_id",
  };
};

export const set_loading = (itemLoading) => {
  return {
    type: "set_loading",
    payload: itemLoading,
  };
};

export const clear_loading = () => {
  return {
    type: "clear_loading",
  };
};

export const set_value_properties = (body) => {
  return {
    type: "set_value_properties",
    payload: body,
  };
};

export const push_value_properties = (body) => {
  return {
    type: "push_value_properties",
    payload: body,
  };
};

export const pull_value_properties_string = (body) => {
  return {
    type: "pull_value_properties_string",
    payload: body,
  };
};

export const pull_value_properties_object = (body) => {
  return {
    type: "pull_value_properties_object",
    payload: body,
  };
};

export const aggregate_train = (body) => {
  /*
  const body = {
        geo_layer,
        start,
        end,
        field_key,
      };

  */
  const chart_js_aggregate_train = get_aggregate_train(body);
  return {
    type: "set_value_properties",
    payload: {
      key: "chart_js_aggregate_train",
      value: chart_js_aggregate_train,
    },
  };
};
