//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  Slide,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  InputAdornment,
  InputLabel,
  FormControl,
  Input,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RefreshIcon from "@material-ui/icons/Refresh";
import TodayIcon from "@material-ui/icons/Today";

//Personal Component

//Redux function
import { getAllDatasetv3 } from "../../App/actions/iotActions";

//Picture Asset

//General Function
//ini iot spbu
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class IotTableSpbu extends Component {
  state = {
    tabs: "table",

    sensor: [],
    iotData: [],
    iotDataTimeUnix: [
      1625626800000, 1625630400000, 1625634000000, 1625637600000, 1625641200000,
      1625644800000, 1625648400000, 1625652000000, 1625655600000, 1625659200000,
      1625662800000, 1625666400000,
    ],

    filterId: `${this?.props?.geo_layer?.geo_layer?.filtered_features?.[0]?.key}`,
    startDate: `${this.formatDate(new Date())}`,
    finishDate: `${this.formatDate(new Date())}`,

    value: [],
    inputValue: "",
    dataset: [],
    lineGraphDatasets: [
      {
        label: `Motor`,
        borderColor: "rgba(54, 162, 235, 1)",
        data: [1, 2, 3, 4, 5, 6, 7, 7, 7, 7, 8, 8],
        fill: false,
        borderWidth: 2,
        radius: 3,
        hoverRadius: 16,
        hoverBorderWidth: 3,
      },
      {
        label: `Mobil`,
        borderColor: "red",
        data: [0, 0, 1, 2, 4, 6, 8, 9, 9, 10, 11, 14],
        fill: false,
        borderWidth: 2,
        radius: 3,
        hoverRadius: 16,
        hoverBorderWidth: 3,
      },
    ],

    modalSettings: false,
    rupiahPengisianUlang: 5000,
    rupiahPengisianBaru: 10000,

    JumlahPengisian: [],
  };

  changeTime(params) {
    const a = new Date(params);
    const jam = a.getHours() < 10 ? `0${a.getHours()}` : a.getHours();
    const menit = a.getMinutes() < 10 ? `0${a.getMinutes()}` : a.getMinutes();
    const detik = a.getSeconds() < 10 ? `0${a.getSeconds()}` : a.getSeconds();
    return `${jam}:${menit}:${detik}`;
  }

  changeDate(params) {
    const a = new Date(params);
    const bulan = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mei",
      "Jun",
      "Jul",
      "Agu",
      "Sep",
      "Okt",
      "Nov",
      "Des",
    ];
    const tanggal = a.getDate() < 0 ? `0${a.getDate()}` : a.getDate();
    return `${tanggal} ${bulan[a.getMonth()]} ${a.getFullYear()}`;
  }

  async getIoTData() {
    this.setState({ sensor: [], iotData: [] });
    const d = await getAllDatasetv3({
      id: this.state.filterId,
      startDate: this.state.startDate,
      finishDate: this.state.finishDate,
    });

    const sensor = d.log_data_v3;
    const lastData = d.last_data;
    if (sensor.length > 0) {
      let d = [],
        arrLastData = [];
      sensor.forEach((element) => {
        d.push({
          _id: element._id,
          name_sensor: element.name_sensor,
          unit: element.unit,
          var: element.var,
          show: true,
        });
        if (lastData[`${element.var}`].values.length > 0) {
          arrLastData.push(lastData[`${element.var}`].values);
        }
      });
      this.setState({ sensor: d, JumlahPengisian: arrLastData });
    } else if (sensor.length === 0) {
      this.setState({
        sensor: [{ name_sensor: "Belum ada Data", unit: "-" }],
      });
    }

    const ioTdata = d.IoTData;
    if (ioTdata.length > 0) {
      // let arr1 = [];
      // ioTdata.forEach((element) => {
      //   this.setState((prevState) => ({
      //     iotDataTimeUnix: [...prevState.iotDataTimeUnix, element.created],
      //   }));
      //   const values = element.values;
      //   let arr2 = [];
      //   d.log_data_v3.forEach((e) => {
      //     let match = values.find((r) => r._id === e._id);
      //     arr2.push(
      //       match ? (match.context === "" ? match.data : match.context) : "-"
      //     );
      //   });
      //   arr1.push(arr2);
      // });
      // const transpose = arr1[0].map((_, colIndex) =>
      //   arr1.map((row) => row[colIndex])
      // );
      // this.setState({ iotData: transpose });
      // this.setState({
      //   siaga1: Array.from(Array(ioTdata.length), (_, x) => 350),
      //   siaga2: Array.from(Array(ioTdata.length), (_, x) => 270),
      //   siaga3: Array.from(Array(ioTdata.length), (_, x) => 200),
      // });
    } else if (ioTdata.length === 0) {
      this.setState({ iotData: [] });
    }
  }

  componentDidMount() {
    let userSPBU = ["5dc3939ce5e8052f7526f944", "60c97865b4992810e9aaa89f"];
    if (userSPBU.includes(this.props.user._id)) {
      let dataset = [];
      this?.props?.geo_layer?.geo_layer?.filtered_features.forEach((d) => {
        dataset.push({
          key: d.key,
          dataset: d.properties.name,
        });
      });
      this.setState({ dataset, value: dataset[0] });
      this.getIoTData();
    }
  }

  componentDidUpdate(prevProps) {
    let userSPBU = ["5dc3939ce5e8052f7526f944", "60c97865b4992810e9aaa89f"];
    if (userSPBU.includes(this.props.user._id)) {
      if (
        prevProps?.geo_layer?.geo_layer?._id !==
        this?.props?.geo_layer?.geo_layer?._id
      ) {
        this.getIoTData();
      }
    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  handleChangeOptions = (event) => {
    const index = event.target.selectedIndex;
    const idDataset = event.target.childNodes[index].id;

    this.setState({ filterId: idDataset });
  };

  handleStarDate = (e) => {
    this.setState({ startDate: e.target.value });
  };
  handleFinishDate = (e) => {
    this.setState({ finishDate: e.target.value });
  };

  handleSubmit = () => {
    this.getIoTData();
    const idx = this.state.dataset.findIndex(
      (x) => x.dataset === `${this.state.inputValue}`
    );
    this.props.propsFly(idx);
  };

  rupiahFormater = (number) => {
    return new Intl.NumberFormat(["ban", "id"]).format(number);
  };

  style = {
    center: { display: "flex", justifycontent: "center", alignItems: "center" },
    font: { color: "black" },
    input: {
      date: {
        borderTop: "none",
        borderRight: "none",
        borderLeft: "none",
        borderRadius: 0,
      },
    },
    btn: {
      outline: {
        color: "#757575",
        padding: "0.35rem",
        marginLeft: "0.1rem",
        marginRight: "0.1rem",
        cursor: "pointer",
      },
      active: {
        border: "solid 1.8px #2196f3",
        backgroundColor: "#2196f3",
        color: "#fff",
        padding: "0.35rem",
        marginLeft: "0.1rem",
        marginRight: "0.1rem",
        borderRadius: "0.3rem",
        cursor: "pointer",
      },
    },
  };

  render() {
    const tableRender = (
      <Grid
        container
        justify="center"
        style={{ marginBottom: 25, paddingBottom: 20 }}
      >
        <Grid item sm={12} md={12} lg={12} xl={10}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {this.state.sensor.map((e, idx) => (
                    <TableCell key={idx} align="center">
                      {e.name_sensor} ({e.unit})
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow hover={true}>
                  <TableCell>Jumlah Pengisian</TableCell>
                  <TableCell align="center">
                    {this.state.JumlahPengisian[0]}
                  </TableCell>
                  <TableCell align="center">
                    {this.state.JumlahPengisian[1]}
                  </TableCell>
                  <TableCell align="center">
                    {this.state.JumlahPengisian[2]}
                  </TableCell>
                  <TableCell align="center">
                    {this.state.JumlahPengisian[3]}
                  </TableCell>
                  <TableCell align="center">
                    {this.state.JumlahPengisian[4]}
                  </TableCell>
                </TableRow>
                <TableRow hover={true}>
                  <TableCell>Jumlah Penghasilan</TableCell>
                  <TableCell align="center">
                    Rp.
                    {this.rupiahFormater(
                      this.state.rupiahPengisianUlang *
                        this.state.JumlahPengisian[0]
                    )}
                    ,-
                  </TableCell>
                  <TableCell align="center">
                    Rp.
                    {this.rupiahFormater(
                      this.state.rupiahPengisianUlang *
                        this.state.JumlahPengisian[1]
                    )}
                    ,-
                  </TableCell>
                  <TableCell align="center">
                    Rp.
                    {this.rupiahFormater(
                      this.state.rupiahPengisianBaru *
                        this.state.JumlahPengisian[2]
                    )}
                    ,-
                  </TableCell>
                  <TableCell align="center">
                    Rp.
                    {this.rupiahFormater(
                      this.state.rupiahPengisianBaru *
                        this.state.JumlahPengisian[3]
                    )}
                    ,-
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );

    const graphRenderTab = (
      <Grid container justify="center">
        <Grid item>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox checked={true} color="primary" />}
              label="Motor - Isi Ulang"
              style={{ width: "max-content" }}
            />
            <FormControlLabel
              control={<Checkbox checked={true} color="primary" />}
              label="Mobil - Isi Ulang"
              style={{ width: "max-content" }}
            />
            <FormControlLabel
              control={<Checkbox checked={false} color="primary" />}
              label="Motor - Isi Baru"
              style={{ width: "max-content" }}
            />
            <FormControlLabel
              control={<Checkbox checked={false} color="primary" />}
              label="Mobil - Isi Baru"
              style={{ width: "max-content" }}
            />
            <FormControlLabel
              control={<Checkbox checked={false} color="primary" />}
              label="Batal"
              style={{ width: "max-content" }}
            />
          </FormGroup>
        </Grid>
      </Grid>
    );
    const graphRender = (
      <Grid
        container
        style={{ marginBottom: 15, paddingBottom: 10, overflow: "auto" }}
        wrap="nowrap"
        justifycontent="center"
        justify="center"
      >
        <Grid item style={{ padding: 15 }} lg={12} xl={10}>
          {/* <LineChart
            data={{
              labels: this.state.iotDataTimeUnix,
              datasets: this.state.lineGraphDatasets,
              // datasets: [
              //   {
              //     label: `Motor`,
              //     borderColor: "rgba(54, 162, 235, 1)",
              //     data: this.state.siaga1,
              //     fill: false,
              //     borderWidth: 2,
              //     radius: 3,
              //     hoverRadius: 16,
              //     hoverBorderWidth: 3,
              //   },
              //   {
              //     label: `Siaga 1`,
              //     borderColor: "red",
              //     data: this.state.siaga1,
              //     fill: false,
              //     borderWidth: 2,
              //     radius: 0,
              //     hoverRadius: 0,
              //     hoverBorderWidth: 0,
              //   },
              //   {
              //     label: `Siaga 2`,
              //     borderColor: "yellow",
              //     data: this.state.siaga2,
              //     fill: false,
              //     borderWidth: 2,
              //     radius: 0,
              //     hoverRadius: 0,
              //     hoverBorderWidth: 0,
              //   },
              //   {
              //     label: `Siaga 3`,
              //     borderColor: "blue",
              //     data: this.state.siaga3,
              //     fill: false,
              //     borderWidth: 2,
              //     radius: 0,
              //     hoverRadius: 0,
              //     hoverBorderWidth: 0,
              //   },
              // ],
            }}
            options={options}
            width={600}
            height={150}
          /> */}
        </Grid>
      </Grid>
    );

    const defaultRender = (
      <Grid
        container
        spacing={1}
        style={{
          margin: 5,
          marginBottom: 0,
        }}
        justify="center"
      >
        <Grid item style={this.style.center}>
          <Typography style={this.style.font}>
            Select {this?.props?.geo_layer?.geo_layer?.name}
          </Typography>
        </Grid>
        <Grid item style={this.style.center}>
          <Autocomplete
            style={{ width: 200 }}
            options={this.state.dataset}
            getOptionLabel={(option) => (option.dataset ? option.dataset : "")}
            renderInput={(params) => <TextField {...params} />}
            value={this.state.value}
            onChange={(event, newValue) => {
              newValue &&
                this.setState({ value: newValue, filterId: newValue.key });
            }}
            inputValue={this.state.inputValue}
            onInputChange={(event, newInputValue) => {
              this.setState({ inputValue: newInputValue });
            }}
          />
        </Grid>
        <Grid item style={this.style.center}>
          &bull;
        </Grid>
        <Grid item style={this.style.center}>
          <Tooltip title="Select Start Date">
            <TodayIcon />
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Select Start Date">
            <input
              type="date"
              style={this.style.input.date}
              onChange={this.handleStarDate}
              value={this.state.startDate}
            />
          </Tooltip>
        </Grid>
        <Grid item style={this.style.center}>
          <Tooltip title="Select End Date">
            <TodayIcon />
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Select End Date">
            <input
              type="date"
              style={this.style.input.date}
              onChange={this.handleFinishDate}
              value={this.state.finishDate}
            />
          </Tooltip>
        </Grid>
        <Grid item style={this.style.center}>
          <button className="buttonSimple2" onClick={this.handleSubmit}>
            Submit
          </button>
        </Grid>
        <Grid item style={this.style.center}>
          &bull;
        </Grid>
        <Grid item style={this.style.center}>
          <Tooltip title="Refresh Data">
            <IconButton>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Settings">
            <IconButton onClick={() => this.setState({ modalSettings: true })}>
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item style={this.style.center}>
          &bull;
        </Grid>
        <Grid item style={this.style.center}>
          {/* <Tooltip title="Detail Data">
            <btn style={this.style.btn.outline}>d</btn>
          </Tooltip> */}
          <Tooltip title="Hourly Data">
            <button style={this.style.btn.active}>H</button>
          </Tooltip>
          {/* <Tooltip title="Day Data">
            <btn style={this.style.btn.outline}>D</btn>
          </Tooltip> */}
        </Grid>
      </Grid>
    );

    const modalSettings = (
      <Dialog
        open={this.state.modalSettings}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.setState({ modalSettings: false })}
        fullWidth={true}
        width="xs"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Settings"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <FormControl fullWidth>
              <InputLabel htmlFor="priceRefill" style={{ width: "100%" }}>
                Harga Isi Ulang
              </InputLabel>
              <Input
                id="priceRefill"
                onChange={(e) =>
                  this.setState({
                    rupiahPengisianUlang: parseInt(e.target.value),
                  })
                }
                value={this.state.rupiahPengisianUlang}
                startAdornment={
                  <InputAdornment position="start">Rp.</InputAdornment>
                }
              />
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel htmlFor="priceNew" style={{ width: "100%" }}>
                Harga Isi Ulang
              </InputLabel>
              <Input
                id="priceNew"
                onChange={(e) =>
                  this.setState({
                    rupiahPengisianBaru: parseInt(e.target.value),
                  })
                }
                value={this.state.rupiahPengisianBaru}
                startAdornment={
                  <InputAdornment position="start">Rp.</InputAdornment>
                }
              />
            </FormControl>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );

    let userSPBU = ["5dc3939ce5e8052f7526f944", "60c97865b4992810e9aaa89f"];
    if (userSPBU.includes(this.props.user._id)) {
      return (
        <div style={{ backgroundColor: "#fff" }}>
          {defaultRender}
          <hr style={{ border: "0.1px solid #c4c4c4", marginBottom: "2rem" }} />
          {tableRender}
          {graphRenderTab}
          {graphRender}
          {modalSettings}
        </div>
      );
    } else {
      return (
        <div className="row">
          <div
            className="col text-center"
            style={{ textAlign: "center", marginTop: 5 }}
          >
            "Please wait, system in maintenance"
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  geo_layer: state?.layer?.geo_layer?.geo_layer,
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(IotTableSpbu);
