/*LIBRARY MODULE*/
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";
import ModalNotification from "../common_modal/ModalNotification";
import ModalSearch from "../common_modal/ModalSearch";
import NotificationList from "./NotificationList";
import Login from "../auth/Login";
import Register from "../auth/Register";
import ModalEmailConfirm from "../common_modal/ModalEmailConfirm";
import EmailConfirm from "./EmailConfirm";
import VersionLog from "./VersionLog";
import QoutaPriceList from "../payment/QoutaPriceList";
import PaymentTable from "../payment/PaymentTable";

/*REDUX FUNCTION*/
import {
  refreshSearch,
  searchLayersOnly,
  searchProjects,
  searchGroups,
} from "../../App/actions/layerActions";
import {
  setLanguage,
  set_value_user,
  set_modal_quota_access,
} from "../../App/actions/authActions";
import { set_value_properties } from "../../App/actions/propertiesActions";

/*PICTURE ASSET*/
import icon_notification from "../../Assets/svg/icon_notification.svg";
import icon_map_editor from "../../Assets/svg/icon_map_editor.svg";
import icon_analyze_lite from "../../Assets/svg/icon_analyze_lite.svg";
import icon_form from "../../Assets/svg/icon_form_menu.svg";
import icon_group from "../../Assets/svg/icon_group.svg";
import icon_home from "../../Assets/svg/icon_home.svg";
import icon_profile from "../../Assets/svg/icon_profile.svg";
import icon_public_data from "../../Assets/svg/icon_public_data.svg";
import icon_tutorial from "../../Assets/svg/icon_tutorial.svg";
import icon_simple_map from "../../Assets/svg/icon_simple_map.svg";
import icon_coin from "../../Assets/svg/icon_coin.svg";
import icon_trash from "../../Assets/svg/icon_delete_grey.svg";

/*GENERAL*/
import dict from "../../Data/dict.json";
import formatDate from "../../App/validation/format-date";
import domain_list from "../../Data/domain_list";

const WAIT_INTERVAL = 1500;
const ENTER_KEY = 13;

class Navigation extends Component {
  state = {
    width: window.innerWidth,
    search_params: "",
    mode_search: "layer",
    project_selected: {},
    layer_selected: {},
    modal_project: false,
    modal_notification: false,
    modal_email: false,
    modal_search: false,
    loginModal: false,
    show: 3,
    mode_auth: "login",
    modal_version: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.auth.isAuthenticated) {
      return {
        loginModal: false,
      };
    } else return null;
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    this.timer = null;
    this.setState({
      modal_email: true,
    });
  }

  componentDidUpdate(prevProps) {
    const { pathname } = this.props.auth;
    const pathname_prev = prevProps.auth.pathname;
    if (pathname !== pathname_prev) {
      this.setState({
        modal_project: false,
        modal_notification: false,
        modal_search: false,
        loginModal: false,
      });
    }

    const isAuthenticated_after = this.props.auth.isAuthenticated;
    const isAuthenticated_before = prevProps.auth.isAuthenticated;

    if (isAuthenticated_after !== isAuthenticated_before) {
      this.props.set_value_user("modal_login", false);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  toggle_buy_license = () => {
    const { modal_buy_license } = this.props.properties;
    this.props.set_value_properties({
      key: "modal_buy_license",
      value: !modal_buy_license,
    });
  };

  toggle_version = () => {
    this.setState({ modal_version: !this.state.modal_version });
  };

  set_mode_auth = (mode_auth) => {
    this.setState({ mode_auth });
  };

  toggle_login = () => {
    const { modal_login } = this.props.auth;
    this.props.set_value_user("modal_login", !modal_login);
  };

  onSetLanguage = (language) => {
    this.props.setLanguage(language);
  };

  toggleProject = (project_selected, layer_selected) => {
    if (project_selected?.link) {
      this.setState({ project_selected });
    }
    if (layer_selected?.link) {
      this.setState({ layer_selected });
    }
    this.setState({ modal_project: !this.state.modal_project });
  };

  onChangeMode = (mode_search) => {
    clearTimeout(this.timer);
    this.setState({ mode_search });
  };

  toggleSearch = () => {
    this.props.refreshSearch();
    this.setState({ modal_search: !this.state.modal_search });
  };

  toggle_modal_quota_price = () => {
    this.props.set_modal_quota_access(false);
  };

  activateSearch = () => {
    this.setState({ modal_search: true });
  };

  closeSearch = () => {
    this.props.refreshSearch();
    this.setState({ modal_search: false, modal_project: false });
  };

  toggleNotification = () => {
    this.setState({ modal_notification: !this.state.modal_notification });
  };

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  toggleLogin = () => {
    this.setState({
      loginModal: !this.state.loginModal,
    });
  };

  toggleEmailConfirm = () => {
    this.setState({
      modal_email: !this.state.modal_email,
    });
  };

  handleDontShow = () => {
    const { show } = this.state;
    localStorage.setItem("dontShow", show);
  };

  handleChange(e) {
    this.setState({ modal_search: true });
    const target = e.target;
    const value = target.value;
    const name = target.name;
    clearTimeout(this.timer);
    this.setState({
      [name]: value,
    });
    this.timer = setTimeout(this.triggerChange.bind(this), WAIT_INTERVAL);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.triggerChange.bind(this);
    }
  }

  triggerChange() {
    const { search_params, mode_search } = this.state;
    if (search_params) {
      switch (mode_search) {
        case "layer":
          this.props.searchLayersOnly(search_params);
          break;
        case "project":
          this.props.searchProjects(search_params);
          break;
        case "group":
          this.props.searchGroups(search_params);
          break;
        default:
          this.props.searchLayersOnly(search_params);
          break;
      }
    }
  }

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const {
      search_params,
      modal_notification,
      modal_search,
      mode_search,
      modal_project,
      project_selected,
      layer_selected,
      width,
      modal_email,
      mode_auth,
      modal_version,
    } = this.state;

    //global props
    let {
      domain,
      isAuthenticated,
      pathname,
      version,
      user,
      modal_login,
      quota_access,
      modal_quota_access,
    } = this.props.auth;
    const { admin_information_list, modal_buy_license } = this.props.properties;
    const { search_result_list, loadingProcess, itemLoading } =
      this.props.layer;
    const { geo_layer_list, geo_project_list, group_list, is_fresh } =
      search_result_list;

    //content
    const premium_status = domain_list?.[domain]?.status;
    const show_insight = domain_list?.[domain]?.show_insight;

    let array_menu = [
      {
        title: "Insight",
        icon: icon_simple_map,
        link: "/insight",
        links: ["/insight"],
      },
      {
        title: dict["Dashboard"][language],
        icon: icon_home,
        link: "/dashboard",
        links: ["/dashboard"],
      },
      {
        title: dict["Profile"][language],
        icon: icon_profile,
        link: "/user_profile",
        links: ["/user_profile", "/purchase_list"],
      },
      {
        title: dict["Form"][language],
        icon: icon_form,
        link: "/form",
        links: ["/form", "/paid_form_list", "/paid_form"],
      },
      {
        title: dict["Group"][language],
        icon: icon_group,
        link: "/user_group",
        links: ["/user_group"],
      },
      {
        title: dict["Public Data"][language],
        icon: icon_public_data,
        link: "/",
        links: [
          "/",
          "/publication_free",
          "/publication_paid",
          "/publication_project",
        ],
      },
      {
        title: dict["Tutorial"][language],
        icon: icon_tutorial,
        link: "/tutorial",
        links: ["/tutorial"],
      },
    ];

    let key = "";

    if (domain === "geo.mapid.io") {
      key = "show_insight_prod";
    } else if (domain === "geo-beta.mapid.io") {
      key = "show_insight_beta";
    } else {
      key = "show_insight_alpha";
    }

    const show_insight_status =
      admin_information_list.find((item) => item.key === key)?.value_string ||
      "show";

    if (show_insight_status !== "show") {
      array_menu = array_menu.filter((item) => item.link !== "/insight");
    }

    let array_menu_public = [
      {
        title: dict["Login"][language],
        icon: icon_profile,
        link: "/login",
        links: ["/login"],
      },
      {
        title: dict["Tutorial"][language],
        icon: icon_tutorial,
        link: "/tutorial",
        links: ["/tutorial"],
      },
      {
        title: dict["Public Data"][language],
        icon: icon_public_data,
        link: "/",
        links: [
          "/",
          "/publication_free",
          "/publication_paid",
          "/publication_project",
        ],
      },
    ];

    if (premium_status === "premium") {
      array_menu = [
        {
          title: dict["Dashboard"][language],
          icon: icon_home,
          link: "/dashboard",
          links: ["/dashboard"],
        },
        {
          title: dict["Profile"][language],
          icon: icon_profile,
          link: "/user_profile",
          links: ["/user_profile"],
        },
        {
          title: dict["Form"][language],
          icon: icon_form,
          link: "/form",
          links: ["/form", "/paid_form"],
        },
        {
          title: dict["Group"][language],
          icon: icon_group,
          link: "/user_group",
          links: ["/user_group"],
        },
      ];
      array_menu_public = [
        {
          title: dict["Login"][language],
          icon: icon_profile,
          link: "/login",
          links: ["/login"],
        },
      ];
    }

    if (show_insight === "show") {
      array_menu = [
        {
          title: "Insight",
          icon: icon_simple_map,
          link: "/insight",
          links: ["/insight"],
        },
        {
          title: dict["Dashboard"][language],
          icon: icon_home,
          link: "/dashboard",
          links: ["/dashboard"],
        },
        {
          title: dict["Profile"][language],
          icon: icon_profile,
          link: "/user_profile",
          links: ["/user_profile", "/purchase_list"],
        },
        {
          title: dict["Form"][language],
          icon: icon_form,
          link: "/form",
          links: ["/form", "/paid_form_list", "/paid_form"],
        },
        {
          title: dict["Group"][language],
          icon: icon_group,
          link: "/user_group",
          links: ["/user_group"],
        },
        {
          title: dict["Public Data"][language],
          icon: icon_public_data,
          link: "/",
          links: [
            "/",
            "/publication_free",
            "/publication_paid",
            "/publication_project",
          ],
        },
      ];

      array_menu_public = [
        {
          title: dict["Login"][language],
          icon: icon_profile,
          link: "/login",
          links: ["/login"],
        },
        {
          title: dict["Tutorial"][language],
          icon: icon_tutorial,
          link: "/tutorial",
          links: ["/tutorial"],
        },
        {
          title: dict["Public Data"][language],
          icon: icon_public_data,
          link: "/",
          links: [
            "/",
            "/publication_free",
            "/publication_paid",
            "/publication_project",
          ],
        },
      ];
    }

    let array_menu_final = isAuthenticated ? array_menu : array_menu_public;
    const mode_array = [
      {
        name: dict["Layer"][language],
        key: "layer",
      },
      {
        name: dict["Project"][language],
        key: "project",
      },
      {
        name: dict["Group"][language],
        key: "group",
      },
    ];
    let list_result;

    if (
      loadingProcess &&
      ["search_layer", "search_projects", "search_groups"].includes(itemLoading)
    ) {
      list_result = <div>{dict["Searching"][language]}...</div>;
    } else if (mode_search === "layer") {
      if (geo_layer_list.length === 0 && !is_fresh) {
        list_result = (
          <div>
            {
              dict["There are no layers with the keyword you are looking for"][
                language
              ]
            }
          </div>
        );
      } else if (is_fresh) {
        list_result = (
          <div>
            {
              dict[
                "Start looking for layers with the keywords you want to search for"
              ][language]
            }
          </div>
        );
      } else {
        list_result = (
          <div>
            {geo_layer_list.map((layer) => {
              const { _id, name, type, geo_project, user, date } = layer;
              const geo_project_name = geo_project?.name
                ? geo_project?.name
                : dict["Project already delete"][language];
              const user_name = user.full_name ? user.full_name : user.name;
              return (
                <main key={_id} className="result_item">
                  <section className="result_name">{name}</section>
                  <section
                    onClick={this.toggleProject.bind(this, geo_project, layer)}
                    className="button_normal"
                  >
                    {dict["Open"][language]} {dict["Project"][language]}:{" "}
                    <b>{geo_project_name}</b>
                  </section>
                  <br />
                  <section className="badge_normal">
                    {dict["Type"][language]}: <b>{type}</b>
                  </section>
                  <section className="badge_normal">
                    {dict["Owner"][language]}: <b>{user_name}</b>
                  </section>
                  <section className="badge_normal">
                    {dict["Update at"][language]}: <b>{formatDate(date)}</b>
                  </section>
                </main>
              );
            })}
          </div>
        );
      }
    } else if (mode_search === "project") {
      if (geo_project_list.length === 0 && !is_fresh) {
        list_result = (
          <div>
            {
              dict[
                "There are no projects with the keywords you are looking for"
              ][language]
            }
          </div>
        );
      } else if (is_fresh) {
        list_result = (
          <div>
            {
              dict[
                "Start searching for projects with the keywords you want to search"
              ][language]
            }
          </div>
        );
      } else {
        list_result = (
          <main>
            {geo_project_list.map((project) => {
              const { _id, name, user, date } = project;
              const user_name = user.full_name ? user.full_name : user.name;
              return (
                <main key={_id} className="result_item">
                  <section onClick={this.toggleProject.bind(this, project)}>
                    <div className="result_name text_medium">{name}</div>
                  </section>
                  <section
                    onClick={this.toggleProject.bind(this, project)}
                    className="button_normal"
                  >
                    {dict["Open"][language]} {dict["Project"][language]}
                  </section>
                  <br />
                  <section className="badge_normal">
                    {dict["Owner"][language]}: <b>{user_name}</b>
                  </section>
                  <section className="badge_normal">
                    {dict["Update at"][language]}: <b>{formatDate(date)}</b>
                  </section>
                </main>
              );
            })}
          </main>
        );
      }
    } else {
      if (group_list.length === 0 && !is_fresh) {
        list_result = (
          <div>
            {
              dict["There are no groups with the keyword you are looking for"][
                language
              ]
            }
          </div>
        );
      } else if (is_fresh) {
        list_result = (
          <div>
            {
              dict[
                "Start searching for groups with the keywords you want to search"
              ][language]
            }
          </div>
        );
      } else {
        list_result = (
          <div>
            {group_list.map(({ _id, name, members, date }) => {
              return (
                <main key={_id} className="result_item">
                  <Link
                    to={`/group/${_id}`}
                    className="result_name"
                    onClick={this.closeSearch}
                  >
                    {name}
                  </Link>
                  <br />
                  <Link
                    to={`/group/${_id}`}
                    onClick={this.closeSearch}
                    className="button_normal"
                  >
                    {dict["Open"][language]} {dict["Group"][language]}
                  </Link>
                  <br />
                  <section className="badge_normal">
                    {dict["Members"][language]}: <b>{members.length}</b>
                  </section>
                  <section className="badge_normal">
                    {dict["Create at"][language]}: <b>{formatDate(date)}</b>
                  </section>
                </main>
              );
            })}
          </div>
        );
      }
    }

    const modal_quota_price_list = modal_quota_access && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_quota_access}
        onClose={this.toggle_modal_quota_price}
      >
        <div className="box-body">
          <QoutaPriceList />
        </div>
      </Modal>
    );

    const search_result_content = modal_search && (
      <ModalSearch
        modalSize="small"
        id="modal"
        isOpen={modal_search}
        onClose={this.toggleSearch}
      >
        <div className="box-body">
          <div className="search_bar">
            <input
              type="text"
              placeholder={
                dict["Search your layer, project or group"][language]
              }
              autoComplete="off"
              name="search_params"
              value={search_params}
              onChange={this.handleChange.bind(this)}
              onKeyDown={this.handleKeyDown.bind(this)}
              onFocus={this.activateSearch}
              style={{
                overflow: "hidden",
                boxSizing: "border-box",
                border: "none",
                backgroundColor: "transparent",
                fontSize: "15px",
                color: "#575757",
                textAlign: "left",
                zIndex: "100",
              }}
            />
          </div>
          <section style={{ marginBottom: "10px" }}>
            {mode_array.map((e) => {
              let css_id = "outline_white";
              if (mode_search === e.key) {
                css_id = "outline_blue";
              }
              return (
                <div
                  key={e.key}
                  onMouseDown={this.onChangeMode.bind(this, e.key)}
                  onClick={this.onChangeMode.bind(this, e.key)}
                  className="button_very_small"
                  id={css_id}
                  style={{ marginRight: "5px" }}
                >
                  {e.name}
                </div>
              );
            })}
          </section>
          {list_result}
        </div>
      </ModalSearch>
    );

    const modal_notification_content = modal_notification && (
      <ModalNotification
        modalSize="small"
        id="modal"
        isOpen={modal_notification}
        onClose={this.toggleNotification}
      >
        <div className="box-body">
          <NotificationList />
        </div>
      </ModalNotification>
    );

    const array_option = [
      {
        name: "Map Editor",
        icon: icon_map_editor,
        link:
          mode_search === "layer"
            ? `/editor/${project_selected.link}?layer_id=${layer_selected._id}`
            : `/editor/${project_selected.link}`,
      },

      {
        name: "Map Viewer",
        icon: icon_analyze_lite,
        link:
          mode_search === "layer"
            ? `/view/${project_selected.link}?layer_id=${layer_selected._id}`
            : `/view/${project_selected.link}`,
      },
      {
        name: "Form Editor",
        icon: icon_form,
        link: `/form_editor/${layer_selected.link}`,
      },
    ];

    const modal_project_content = modal_project && (
      <ModalSearch
        id="modal"
        isOpen={modal_project}
        onClose={this.toggleProject}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          {array_option.map((e, idx) => {
            let item = null;
            if (idx !== 3 || (idx === 3 && mode_search === "layer")) {
              item = (
                <Link key={e.name} to={e.link} onClick={this.closeSearch}>
                  <div
                    style={{
                      textAlign: "center",
                      display: "inline-block",
                      width: "77px",
                      margin: "20px",
                      verticalAlign: "top",
                    }}
                  >
                    <img
                      src={e.icon}
                      height="45px"
                      alt={e.name}
                      style={{ marginBottom: "10px" }}
                    />
                    <div className="text_bold" style={{ fontSize: "14px" }}>
                      {e.name}
                    </div>
                  </div>
                </Link>
              );
            }
            return item;
          })}
        </div>
      </ModalSearch>
    );

    const language_list = [
      {
        name: "INA",
        value: "ina",
        image: "https://mapid.co.id/img/icon/indo-flag.png",
      },
      {
        name: "ENG",
        value: "eng",
        image: "https://mapid.co.id/img/icon/uk-flag.png",
      },
    ];

    const language_content = (
      <main style={{ marginTop: "10px" }}>
        {language_list.map((e) => {
          let css_id = "outline_white";
          if (e.value === language) {
            css_id = "outline_blue";
          }
          return (
            <div
              onClick={this.onSetLanguage.bind(this, e.value)}
              key={e.value}
              className="button_pill_tight"
              id={css_id}
              style={{ marginRight: "5px", marginBottom: "5px" }}
            >
              <img
                style={{
                  width: "20px",
                  height: "20px",
                }}
                src={e.image}
                alt={e.name}
              />
            </div>
          );
        })}
      </main>
    );

    let nav_content = (
      <main>
        <div id="navbarDefault" className="navbarDefault">
          <div className="top_nav" id="top_nav">
            <div className="top_nav_container">
              <Link to="/dashboard">
                <div className="left">
                  <img
                    src={domain_list?.[domain]?.logo}
                    height="35px"
                    alt={domain_list?.[domain]?.title}
                  />
                </div>
              </Link>
              {show_insight_status === "show" &&
                premium_status !== "premium" &&
                isAuthenticated && (
                  <div
                    className="kuota_akses"
                    onClick={() =>
                      this.props.set_modal_quota_access(!modal_quota_access)
                    }
                  >
                    <h1>{quota_access}</h1>
                    <img src={icon_coin} alt="coin" />
                  </div>
                )}

              {isAuthenticated && (
                <div className="search_bar">
                  <input
                    type="text"
                    placeholder={
                      dict["Search your layer, project or group"][language]
                    }
                    autoComplete="off"
                    name="search_params"
                    value={search_params}
                    onChange={this.handleChange.bind(this)}
                    onKeyDown={this.handleKeyDown.bind(this)}
                    onFocus={this.activateSearch}
                    style={{
                      overflow: "hidden",
                      boxSizing: "border-box",
                      border: "none",
                      backgroundColor: "transparent",
                      fontSize: "15px",
                      color: "#575757",
                      textAlign: "left",
                    }}
                  />
                </div>
              )}
              {isAuthenticated && (
                <div
                  className="right"
                  style={{ cursor: "pointer" }}
                  onClick={this.toggleNotification}
                >
                  <img src={icon_notification} height="25px" alt="GEO MAPID" />
                </div>
              )}
            </div>
          </div>
          <div
            className="sidebar_home sidebar_dashboard_layout"
            id="sidebar_home"
          >
            <div>
              {array_menu_final.map(({ title, icon, link, links }) => {
                const id_content = links.includes(pathname)
                  ? "active"
                  : "not_active";
                return (
                  <Link
                    to={link}
                    className="sidebar_button vertical_center w_200"
                    id={id_content}
                    key={title}
                  >
                    <div className="vertical_center_child">
                      <div style={{ marginLeft: "20px" }}>
                        <img src={icon} alt={title} width="35px" />
                      </div>
                      <div style={{ marginLeft: "20px", width: "250px" }}>
                        {title}
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
            <div
              style={{ marginLeft: "20px", marginTop: "30px", fontSize: "13" }}
            >
              {domain_list?.[domain]?.short_title}
              <br />V {version}{" "}
              <span
                className="button_pill_tight"
                id="green"
                onClick={this.toggle_version}
              >
                Changelogs
              </span>
              <div>{language_content}</div>
            </div>

            {isAuthenticated && (
              <div className="flex align_end">
                <div>
                  <div className="paddingLeft_15 w_175">
                    <hr />
                  </div>
                  <Link
                    to={"/recovery"}
                    className="sidebar_button vertical_center w_200"
                    id={pathname === "/recovery" ? "active" : "not_active"}
                    key={"Recovery"}
                  >
                    <div className="vertical_center_child">
                      <div style={{ marginLeft: "20px" }}>
                        <div className="bg_lightGrey rounded_full center_perfect w_35 h_35">
                          <img
                            src={icon_trash}
                            style={{
                              filter: "invert(1)",
                            }}
                            alt={dict["Recovery"][language]}
                            width="20px"
                          />
                        </div>
                      </div>
                      <div style={{ marginLeft: "20px", width: "250px" }}>
                        {dict["Recovery"][language]}
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    );

    if (width <= 900) {
      array_menu_final = array_menu_final.filter((a) => a.link !== "/tutorial");
      nav_content = (
        <main className="nav_bottom">
          {array_menu_final.map(({ title, icon, link, links }) => {
            const id_content = links.includes(pathname)
              ? "active"
              : "not_active";
            return (
              <Link
                to={link}
                className="nav_item_bottom"
                key={title}
                id={id_content}
              >
                <img src={icon} alt={title} width="35px" />
                {title}
              </Link>
            );
          })}
          {isAuthenticated && (
            <Link
              to={"/recovery"}
              className="nav_item_bottom"
              key={"Recovery"}
              id={pathname === "/recovery" ? "active" : "not_active"}
            >
              <div className="bg_lightGrey rounded_full center_perfect w_35 h_35">
                <img
                  src={icon_trash}
                  style={{
                    filter: "invert(1)",
                  }}
                  alt={dict["Recovery"][language]}
                  width="20px"
                />
              </div>
              {dict["Recovery"][language]}
            </Link>
          )}

          <div
            className="button_small"
            style={{ position: "fixed", bottom: "0px", left: "10px" }}
          >
            Version {version}
          </div>
        </main>
      );
    }

    const modal_email_confirm = modal_email && (
      <>
        {user?.is_email_confirm === "not_propose" ? (
          <ModalEmailConfirm
            modalSize="medium"
            id="modal"
            isOpen={modal_email}
            onClose={this.toggleEmailConfirm}
            auth={this.props.auth}
          >
            <EmailConfirm onClose={this.toggleEmailConfirm} />
          </ModalEmailConfirm>
        ) : null}
      </>
    );

    const modal_login_content = modal_login && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_login}
        onClose={this.toggle_login}
      >
        <div className="box-body">
          {mode_auth === "login" ? (
            <Login isNav={true} />
          ) : (
            <Register isNav={true} />
          )}
          {mode_auth === "login" ? (
            <section style={{ display: "block", marginTop: "30px" }}>
              <p className="button_account">
                {dict["Don't have an account?"][language]}
              </p>
              <p
                className="button_register"
                onClick={this.set_mode_auth.bind(this, "register")}
              >
                {dict["Register here"][language]}
              </p>
            </section>
          ) : (
            <section style={{ display: "block", marginTop: "30px" }}>
              <p className="button_account">
                {dict?.["Already have an account?"]?.[language]}
              </p>
              <p
                className="button_register"
                onClick={this.set_mode_auth.bind(this, "login")}
              >
                {dict["Login instead"][language]}
              </p>
            </section>
          )}
          {language_content}
        </div>
      </Modal>
    );

    const modal_version_content = modal_version && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_version}
        onClose={this.toggle_version}
      >
        <div className="box-body">
          <VersionLog />
        </div>
      </Modal>
    );

    const modal_buy_license_content = modal_buy_license && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_buy_license}
        onClose={this.toggle_buy_license}
      >
        <div className="box-body">
          <PaymentTable />
        </div>
      </Modal>
    );

    return (
      <div>
        {modal_buy_license_content}
        {modal_version_content}
        {modal_login_content}
        {modal_notification_content}
        {modal_project_content}
        {modal_email_confirm}

        {search_result_content}
        {modal_quota_price_list}
        {nav_content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  searchLayersOnly,
  searchProjects,
  searchGroups,
  setLanguage,
  refreshSearch,
  set_value_user,
  set_modal_quota_access,
  set_value_properties,
})(Navigation);
