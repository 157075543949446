//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Personal Component

//Redux function

//Picture Asset

//General Function
import domain_list from "../../Data/domain_list";

class HomeButton extends Component {
  render() {
    const domain = this.props.auth.domain;

    return (
      <div
        className="noselect"
        style={{
          zIndex: "4",
          position: "fixed",
          top: "5px",
          left: "20px",
        }}
      >
        <Link to="/dashboard">
          <img src={domain_list?.[domain]?.logo} height="30px" alt="Home" />
        </Link>
        <style>
          {`
        body{
          padding-top: 0px;
          overflow-y: auto;
        }
        .navbarDefault{ visibility: hidden;}
        #top_nav{ visibility: hidden;}
        #sidebar_home{ visibility: hidden;}
        `}
        </style>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(HomeButton);
