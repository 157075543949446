//Library Modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer } from "@urbica/react-map-gl";

//Personal Component

//Redux Function
import {
  // getSini,
  setRequestId,
} from "../../App/actions/siniActions";

//Picture Asset

//General Function
import generate_color_map from "../../App/validation/generate_color_map";
import generate_array_color from "../../App/validation/generate_array_color";
import generate_color_interpolate from "../../App/validation/generate_color_interpolate";

class LayerSini extends Component {
  constructor(props) {
    super(props);
    this._map = React.createRef();
    this._draw = React.createRef();
    this.state = {
      // SINI
      id_active: "",
      render_layer: null,
    };
  }
  componentDidMount() {
    this.layerRender();
  }

  componentDidUpdate(prevProps) {
    // AFTER
    const sini_action_after = this.props.map.sini_action;
    const request_id_after = this.props.layer.request_id;

    // BEFORE
    const sini_action_before = prevProps.map.sini_action;
    const request_id_before = prevProps.layer.request_id;

    if (
      sini_action_after !== sini_action_before ||
      request_id_after !== request_id_before
    ) {
      this.layerRender();
    }
  }

  onSetId = (data) => {
    const map = this.props.map.map;
    this.props.setRequestId(data.request_id);
    this.setState({ id_active: data.request_id });
    map.flyTo({
      center: [data.long, data.lat],
      zoom: 12.5,
      speed: 1,
    });
  };

  layerRender() {
    const { sini_list, sini_mode, poi_select } = this.props.layer;
    // const pathname = window.location.pathname;

    const render_layer = sini_list?.map((data, index) => {
      // Coloring poi
      let poi_data = [];
      poi_select?.forEach((poi) => {
        data?.poi?.features?.map((d) =>
          d.properties.TIPE_1 === poi ? poi_data.push(d) : null
        );
      });

      const data_poi_filter = {
        type: "FeatureCollection",
        features: poi_data,
      };

      const category_poi = [
        "ATM DAN BANK",
        "PEMERINTAHAN",
        "FASILITAS KESEHATAN",
        "FASILITAS OLAHRAGA",
        "FASILITAS PENDIDIKAN",
        "FASILITAS TRANSPORTASI",
        "PARIWISATA DAN HIBURAN",
        "PARIWISATA DAN TEMPAT HIBURAN",
        "FASILITAS UMUM",
        "RETAIL",
        "TEMPAT IBADAH",
        "TEMPAT MAKAN/MINUM",
        "UMKM",
        "HOTEL DAN TEMPAT MENGINAP",
      ];

      const color_poi = [
        "#B54335",
        "#D63864",
        "#9031AA",
        "#613CB0",
        "#4350AF",
        "#4994EC",
        "#4BA6EE",
        "#4BA6EE",
        "#54B9D1",
        "#429488",
        "#67AD5B",
        "#97C15C",
        "#D0DC59",
        "#FCEC60",
        "#000000",
      ];

      const valueColor = generate_color_map(category_poi, color_poi, "TIPE_1");

      const render_poi = sini_mode.find((d) => d === "poi") === "poi" && (
        <DisplaySini
          title={`${data.request_id}_poi`}
          type="circle"
          data={
            // data_poi_filter.features.length === 0 ? data?.poi : data_poi_filter
            this.props.layer.poi_select === 0 &&
            data_poi_filter.features.length === 0
              ? data?.poi
              : data_poi_filter
          }
          color={{
            "circle-color": valueColor,
            "circle-radius": 5,
            "circle-stroke-width": 1,
            "circle-stroke-color": "#fff",
          }}
          before="5"
          layerOnClick={this.props.layerOnClick.bind(this)}
        />
      );

      // Coloring demografi
      let array_demografi = data?.demografi?.features?.map(
        // (d) => d?.properties?.DESA
        (d) => d?.properties?.[`DESA ATAU KELURAHAN`]
      );

      array_demografi = [...new Set(array_demografi)];

      const color_demografi = generate_array_color(array_demografi);
      const array_color_demografi = generate_color_map(
        array_demografi,
        color_demografi,
        // "DESA"
        "DESA ATAU KELURAHAN"
      );

      const render_demografi = sini_mode.find((d) => d === "demografi") ===
        "demografi" && (
        <>
          <DisplaySini
            title={`polygon_${data.request_id}demografi_${index + 1}`}
            type="fill"
            data={data?.demografi}
            color={{
              "fill-color": array_color_demografi,
              "fill-opacity": 0.5,
            }}
            layerOnClick={this.props.layerOnClick.bind(this)}
          />
          <DisplaySini
            title={`line_${data.request_id}demografi_${index + 1}`}
            type="line"
            data={data?.demografi}
            color={{
              "line-color": "#000000",
              "line-width": 0.5,
            }}
          />
          {/* {pathname === "/insight" ? (
            <div>
              <Source
                key={`Label_${data.request_id}demografi_${index + 1}`}
                id={`Label_${data.request_id}demografi_${index + 1}`}
                type="geojson"
                data={data?.demografi}
              />
              <Layer
                id={`Label_${data.request_id}demografi_${index + 1}`}
                type="symbol"
                source={`Label_${data.request_id}demografi_${index + 1}`}
                layout={{
                  // "text-field": "Test", // mengasih nama labelnya
                  "text-field": ["get", "DESA ATAU KELURAHAN"],
                  "text-variable-anchor": ["bottom"],
                  "text-radial-offset": 1, // menentukan tata letak tulisan
                  "text-allow-overlap": true,
                  "text-justify": "auto",
                  // visibility: "visible",
                }}
                paint={{
                  "text-halo-color": "#fff",
                  "text-halo-width": 10,
                  "text-halo-blur": 0,
                  "text-color": "#2064A1",
                }}
                before="1"
              />
            </div>
          ) : null} */}
        </>
      );

      // Coloring landuse
      const array_landuse = [
        "SAWAH",
        "KEBUN/TEGALAN",
        "JASA/INDUSTRI",
        "PERMUKIMAN",
        "TANAH TERBUKA",
        "PERAIRAN DARAT",
        "HUTAN",
        "TRANSPORTASI/INSTALASI",
        "PERTAMBANGAN",
        "KAWASAN TERBANGUN",
        "JARINGAN JALAN",
        "DANAU",
        "WADUK",
        "RAWA",
        "SUNGAI",
        "LADANG",
        "SABANA",
        "SEMAK BELUKAR",
        "JARINGAN JALAN KERETA API",
        "LAHAN TIDAK TERBANGUN",
        "BANDAR UDARA DOMESTIK/INTL",
        "PELABUHAN LAUT",
        "TEMPAT PENUMBUNAN SAMPAH/DEPOSIT",
        "TAMBAK",
        "PADANG RUMPUT",
        "BELUM ADA DATA",
      ];
      const color_landuse = [
        "#AF1300",
        "#FF1B00",
        "#8B00B1",
        "#C800FF",
        "#3700AB",
        "#5100FF",
        "#0015AC",
        "#001FFF",
        "#2200AC",
        "#0075FF",
        "#0060AC",
        "#008EFF",
        "#008CAD",
        "#00CEFF",
        "#00AD94",
        "#00FFDA",
        "#1AAE00",
        "#25FF00",
        "#AC7B00",
        "#FFB600",
        "#AF6000",
        "#FF8B00",
        "#AE2A00",
        "#FF3E00",
        "#AB3500",
        "#F04A00",
      ];
      const array_color_landuse = generate_color_map(
        array_landuse,
        color_landuse,
        "GUNATANAH"
      );

      const render_landuse = sini_mode.find((d) => d === "landuse") ===
        "landuse" && (
        <>
          <DisplaySini
            title={`polygon_${data.request_id}_landuse`}
            type="fill"
            data={data?.landuse}
            color={{
              "fill-color": array_color_landuse,
              "fill-opacity": 0.5,
            }}
            layerOnClick={this.props.layerOnClick.bind(this)}
          />
          <DisplaySini
            title={`line_${data.request_id}_landuse`}
            type="line"
            data={data?.landuse}
            color={{
              "line-color": "#000000",
              "line-width": 0.3,
            }}
          />
        </>
      );

      // Coloring kebencanaan
      let array_disaster = [
        "Sangat Rendah",
        "Cukup Rendah",
        "Sedang",
        "Cukup Tinggi",
        "Tinggi",
      ];
      const color_disaster = [
        "#5E972C",
        "#AFCB40",
        "#FFFF54",
        "#F6AE40",
        "#EE5D2D",
      ];
      const array_color_disaster = generate_color_map(
        array_disaster,
        color_disaster,
        "Kelas"
      );

      const array_color_tsunami = generate_color_map(
        array_disaster,
        color_disaster,
        "KELAS"
      );

      const render_bencana_banjir = sini_mode.find(
        (d) => d === "bencana_banjir"
      ) === "bencana_banjir" && (
        <>
          <DisplaySini
            title={`polygon_${data.request_id}_bencana_banjir`}
            type="fill"
            data={data?.bencana_banjir}
            color={{
              "fill-color": array_color_disaster,
              "fill-opacity": 0.5,
            }}
            layerOnClick={this.props.layerOnClick.bind(this)}
          />
          <DisplaySini
            title={`line_${data.request_id}_bencana_banjir`}
            type="line"
            data={data?.bencana_banjir}
            color={{
              "line-color": "#000000",
              "line-width": 0.3,
            }}
          />
        </>
      );

      const render_bencana_longsor = sini_mode.find(
        (d) => d === "bencana_longsor"
      ) === "bencana_longsor" && (
        <>
          <DisplaySini
            title={`polygon_${data.request_id}_bencana_longsor`}
            type="fill"
            data={data?.bencana_longsor}
            color={{
              "fill-color": array_color_disaster,
              "fill-opacity": 0.5,
            }}
            layerOnClick={this.props.layerOnClick.bind(this)}
          />
          <DisplaySini
            title={`line_${data.request_id}_bencana_longsor`}
            type="line"
            data={data?.bencana_longsor}
            color={{
              "line-color": "#000000",
              "line-width": 0.3,
            }}
          />
        </>
      );

      const render_bencana_tsunami = sini_mode.find(
        (d) => d === "bencana_tsunami"
      ) === "bencana_tsunami" && (
        <>
          <DisplaySini
            title={`polygon_${data.request_id}_bencana_tsunami`}
            type="fill"
            data={data?.bencana_tsunami}
            color={{
              "fill-color": array_color_tsunami,
              "fill-opacity": 0.5,
            }}
            layerOnClick={this.props.layerOnClick.bind(this)}
          />
          <DisplaySini
            title={`line_${data.request_id}_bencana_tsunami`}
            type="line"
            data={data?.bencana_tsunami}
            color={{
              "line-color": "#000000",
              "line-width": 0.3,
            }}
          />
        </>
      );

      // Coloring znt
      const array_znt = [1, 5, 10]; // Cek untuk nilai maximal menunggu novan
      const color_znt = ["#5E972C", "#FFFF54", "#EE5D2D"];
      const array_color_znt = generate_color_interpolate(
        array_znt,
        color_znt,
        "KELASNILAI"
      );

      const render_znt = sini_mode.find((d) => d === "znt") === "znt" && (
        <>
          <DisplaySini
            title={`polygon_${data.request_id}_znt`}
            type="fill"
            data={data?.znt}
            color={{
              "fill-color": array_color_znt,
              "fill-opacity": 0.7,
            }}
            layerOnClick={this.props.layerOnClick.bind(this)}
          />
          <DisplaySini
            title={`line_${data.request_id}_znt`}
            type="line"
            data={data?.znt}
            color={{
              "line-color": "#000000",
              "line-width": 0.3,
            }}
          />
        </>
      );

      // isochrone
      const render_Isochrone =
        sini_mode.find((d) => d === "isochrone") === "isochrone" &&
        data?.isochrone.map(
          (isochrone, index) =>
            isochrone.view && (
              <div key={index + 1}>
                <DisplaySini
                  title={`polgon_${isochrone.key}_isochrone`}
                  type="fill"
                  data={isochrone?.geometry}
                  color={{
                    "fill-color": ["get", "color"],
                    "fill-opacity": 0.5,
                  }}
                  layerOnClick={this.props.layerOnClick.bind(this)}
                />
                <DisplaySini
                  title={`line_${isochrone.key}_isochrone`}
                  type="line"
                  data={isochrone?.geometry}
                  color={{
                    "line-color": "#000000",
                    "line-width": 0.3,
                  }}
                />
              </div>
            )
        );

      // const render_isochrone = sini_mode.find((d) => === "isochrone") === "isochrone" && data.isochrone.fi

      const render = (
        <section key={index}>
          {render_demografi}
          {render_poi}
          {render_znt}
          {render_landuse}
          {render_bencana_banjir}
          {render_bencana_longsor}
          {render_bencana_tsunami}
          {render_Isochrone}
        </section>
      );

      return render;
    });
    this.setState({ render_layer: render_layer });
  }

  render() {
    return this.state.render_layer;
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  map: state.map,
});

export default connect(mapStateToProps, {
  setRequestId,
  // getSini,
})(LayerSini);

export const DisplaySini = ({
  title,
  data,
  color,
  type,
  layerOnClick,
  before,
}) => {
  let layout = { visibility: true ? "visible" : "none" };
  return (
    <section>
      <Source
        key={title}
        id={title}
        type="geojson"
        data={data}
        clusterMaxZoom={14} // Max zoom to cluster points on
        clusterRadius={1000}
      />
      <Layer
        id={title}
        before={before ? before : "2"}
        type={type}
        source={title}
        paint={color}
        layout={layout}
        filter={["!", ["has", "point_count"]]}
        onClick={layerOnClick}
      />
    </section>
  );
};
