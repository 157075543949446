import React, { Component } from "react";
import { connect } from "react-redux";

//General Function
import dict from "../../Data/dict.json";

//Redux function
import {
  editBlogGeneral,
  postEditFAQ
} from "../../App/actions/blogAction";

class FAQ extends Component {
  state = {
    faqJson: {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [],
    },
    question: "",
    answer: "",
  };

  componentDidMount() { 
    if(this.props.blog.blog_personal.faq){
      this.setState({
        faqJson: this.props.blog.blog_personal.faq
      })
    }
  }

  handleSave() {
    const newFAQ = {
      "@type": "Question",
      name: this.state.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: this.state.answer,
      },
    };
    const entity = this.state.faqJson.mainEntity;
    entity.push(newFAQ);
    const faq = { ...this.state.faqJson, mainEntity: entity };
    this.setState({
      faqJson: faq,
      question: "",
      answer: "",
    });

    const {_id} = this.props.blog.blog_personal;
    this.props.postEditFAQ({
      blog_id: _id,
      faq: faq
    })
  }

  handleDelete(params) {
    const entity = this.state.faqJson.mainEntity;
    entity.splice(params, 1);
    const faq = { ...this.state.faqJson, mainEntity: entity };
    this.setState({
      faqJson: faq,
    });

    const {_id} = this.props.blog.blog_personal;
    this.props.postEditFAQ({
      blog_id: _id,
      faq: faq
    })
  }

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { faqJson } = this.state;
    return (
      <div>
        <div className="text_bold">
          {dict["Frequently Asked Questions"][language]}
        </div>
        <div
          className=""
          style={{ marginBottom: "4px", marginTop: "10px", fontSize: 16 }}
        >
          {dict["Question_faq"][language]}?
        </div>
        <textarea
          rows={"2"}
          style={{ resize: "vertical" }}
          onChange={(e) => this.setState({ question: e.target.value })}
          value={this.state.question}
        ></textarea>
        <div
          className=""
          style={{ marginBottom: "4px", marginTop: "10px", fontSize: 16 }}
        >
          {dict["Answer_faq"][language]}
        </div>
        <textarea
          rows={"4"}
          style={{ resize: "vertical" }}
          onChange={(e) => this.setState({ answer: e.target.value })}
          value={this.state.answer}
        ></textarea>

        <div
          onClick={() => {
            this.handleSave();
          }}
          style={{ margin: "1rem auto 0rem auto" }}
          className="w_50 bg_blue text_white h_50 w_80 rounded_5 center_perfect bold hover_pointer"
        >
          {dict["Save"][language]}
        </div>

        {faqJson.mainEntity.map((entity, idx) => (
          <div key={idx}>
            <hr />
            <p>
              <b>{dict["Question_faq"][language]}:</b> {entity["name"]}
            </p>
            <p>
              <b>{dict["Answer_faq"][language]}:</b> {entity["acceptedAnswer"]["text"]}
            </p>
            <button
              onClick={() => this.handleDelete(idx)}
              className="button_very_small use_margin"
              id="deleteBright"
            >
              {dict["Delete"][language]}
            </button>
          </div>
        ))}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  blog: state.blog,
});
export default connect(mapStateToProps, {
  editBlogGeneral,
  postEditFAQ
})(FAQ);
