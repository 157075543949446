//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Personal Component
import Modal from "../common_modal/Modal";
import EditBillingFormBeforePayment from "./EditBillingFormBeforePayment";
import EditBillingFormAfterPayment from "./EditBillingFormAfterPayment";
import PaymentForm from "../form_paid/PaymentForm";

//Redux function
import { form_validation } from "../../App/actions/layerActions";
import {
  push_revision_list_all,
  push_revision_list,
} from "../../App/actions/layerNewActions";
import { createFormDoc, getBlogDetailForm } from "../../App/actions/blogAction";

//Picture Asset
import icon_edit_text from "../../Assets/svg/icon_edit_text.svg";
import icon_recruitment from "../../Assets/img/icon_recruitment.png";

//General Function
import dict from "../../Data/dict.json";
// import is_equal_array from "../../App/validation/is_equal_array";

const before_payment = ["not_propose", "propose", "rejected", "recruit"];
const after_payment = [
  "close_recruit",
  "wait_purchase",
  "user_purchase",
  "success_payment",
];
const recruit_status = ["not_propose", "propose", "rejected", "recruit"];
const payment_status = ["close_recruit", "wait_purchase", "user_purchase"];
const survey_status = [
  "success_payment",
  "validation",
  "post_payment",
  "finish",
];

class PaidFormDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalEditPreference: false,
      modalSubmitPayment: false,
      table_mode: "data", //surveyors | data
      feature_key: "",
      message: "",
      modal_form_reject: false,
      modalFullscreen: false,
      modal_full: false,
      modal_rev_all: false,
      fields_state: [],
      fields: [],
      geo_layer_id: null,
      modal_rev_message: false,
      modal_rev_message_satu: false,
      modal_success: false,
      field: {},
      feature_state: {},

      language: "ina",
      features: [],
      features_slice: [],

      top_content: null,
      is_full_data: false,

      // full_content_slice: null,

      // data_list_slice: null,
      // surveyor_list_slice: null,
      // data_list_full: null,
      // surveyor_list_full: null,

      modal_warn_full_data: false,
    };
  }

  componentDidMount() {
    this.render_view();
  }

  componentDidUpdate(prevProps) {
    //AFTER
    const layer_id_after = this.props.layer.layer_id;
    // const geo_layer_list_after = this.props.layer.geo_layer_list;
    const language_after = this.props.auth.language;
    const status_action_after = this.props.map.status_action;

    //BEFORE
    const layer_id_before = prevProps.layer.layer_id;
    // const geo_layer_list_before = prevProps.layer.geo_layer_list;
    const language_before = prevProps.auth.language;
    const status_action_before = prevProps.map.status_action;

    if (
      layer_id_after !== layer_id_before ||
      language_after !== language_before ||
      status_action_after !== status_action_before
    ) {
      this.render_view();
    }
  }

  render_view = () => {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { geo_layer_list, layer_id } = this.props.layer;
    const { domain } = this.props.auth;

    let domain_temp = "https://form.mapid.io/";
    if (domain === "bbwsciliwungcisadane.mapid.io") {
      domain_temp = "https://cilicis.mapid.io/";
    } else if (domain === "bbwsbrantas.mapid.io") {
      domain_temp = "https://brantas.mapid.io/";
    }

    let geo_layer = geo_layer_list.find(
      (e) => e.geo_layer._id === layer_id
    )?.geo_layer;

    let features = geo_layer?.geojson?.features
      ? geo_layer?.geojson?.features
      : [];

    const status = geo_layer?.formStatus?.status;
    const quota = geo_layer?.formPayment?.quota
      ? geo_layer?.formPayment?.quota
      : 10;

    const features_slice = features.slice(0, 100);
    // features = features.slice(0, 10);

    // const fields = geo_layer?.fields ? geo_layer?.fields : [];

    const features_accepted = features.filter((feature) =>
      ["success", "paid_off"].includes(feature.formStatus.status)
    );
    const percentage_success = parseInt(
      (features_accepted.length / quota) * 100
    );

    const surveyors = geo_layer?.formPayment?.surveyors
      ? geo_layer?.formPayment?.surveyors
      : [];
    const amounts = surveyors.map((surveyor) => surveyor.amount);
    const amounts_sum = amounts.reduce((a, b) => a + b, 0);

    const percentage = parseInt((amounts_sum / quota) * 100);
    const percentage_survey = parseInt((features.length / quota) * 100);

    const top_content = (
      <main>
        <section className="text_header" style={{ marginBottom: "20px" }}>
          {geo_layer?.name ? geo_layer?.name : "Loading..."}{" "}
          <Link
            to={`/form_editor/${
              geo_layer?.link ? geo_layer?.link : geo_layer?._id
            }`}
          >
            <img
              style={{ cursor: "pointer" }}
              src={icon_edit_text}
              alt="Edit general"
              width="15px"
              height="auto"
            />
          </Link>
        </section>
        <section
          style={{
            width: "800px",
            maxWidth: "100%",
          }}
        >
          <div className="container_full three-container">
            {[
              {
                name: dict["Recruitment"][language],
                array: recruit_status,
              },
              {
                name: dict["Payment"][language],
                array: payment_status,
              },
              {
                name: dict["Survey & Validation"][language],
                array: survey_status,
              },
            ].map((step, idx) => {
              const id_style = step.array.includes(status)
                ? "blue"
                : "blue_disable";
              const text_style = step.array.includes(status)
                ? "bold"
                : "normal";
              return (
                <div
                  key={idx}
                  style={{
                    display: "grid",
                  }}
                >
                  <div
                    id={id_style}
                    className="button"
                    style={{
                      margin: "auto",
                    }}
                  >
                    {idx + 1}
                  </div>
                  <div
                    className="text_medium"
                    style={{
                      margin: "auto",
                      marginTop: "0px",
                      fontWeight: text_style,
                    }}
                  >
                    {step.name}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="two_container_equal" style={{ marginTop: "10px" }}>
            <div
              className="container_full"
              style={{
                width: "calc(( 100% - 40px ))",
                height: "auto",
                padding: "20px",
                textAlign: "center",
              }}
            >
              <div
                className="button_block_tight"
                id="blue"
                style={{ marginBottom: "80px" }}
              >
                {payment_status.includes(status)
                  ? dict["Payment Process"][language]
                  : survey_status.includes(status) && percentage_success === 100
                  ? dict["Survey Completed"][language]
                  : survey_status.includes(status)
                  ? dict["Survey Process"][language]
                  : dict["Recruitment Process"][language]}
              </div>
              {payment_status.includes(status) ? (
                <div>
                  <div
                    style={{
                      height: "10px",
                      backgroundColor: "#dedede",
                      borderRadius: "2rem",
                      padding: "3px",
                      margin: "auto",
                      width: "80%",
                    }}
                  >
                    <div
                      style={{
                        height: "100%",
                        width: `${percentage > 100 ? 100 : percentage}%`,
                        backgroundColor: "#7cc735ff",
                        borderRadius: "2rem",
                      }}
                    />
                  </div>
                  <div
                    className="text_medium"
                    style={{ marginTop: "5px", marginBottom: "80px" }}
                  >
                    {percentage} %
                    <br />
                    {dict["Potential responders"][language]} ({amounts_sum}{" "}
                    {dict["of"][language]} {quota})
                  </div>
                </div>
              ) : survey_status.includes(status) ? (
                <div>
                  <div
                    style={{
                      height: "10px",
                      backgroundColor: "#dedede",
                      borderRadius: "2rem",
                      padding: "3px",
                      margin: "auto",
                      width: "80%",
                    }}
                  >
                    <div
                      style={{
                        height: "100%",
                        width: `${
                          percentage_survey > 100 ? 100 : percentage_survey
                        }%`,
                        backgroundColor: "#7cc735ff",
                        borderRadius: "2rem",
                      }}
                    />
                  </div>
                  <div
                    className="text_medium"
                    style={{ marginTop: "5px", marginBottom: "40px" }}
                  >
                    {percentage_survey} %
                    <br />
                    {dict["Data submited"][language]} ({features.length}{" "}
                    {dict["of"][language]} {quota})
                  </div>
                  <div
                    style={{
                      height: "10px",
                      backgroundColor: "#dedede",
                      borderRadius: "2rem",
                      padding: "3px",
                      margin: "auto",
                      width: "80%",
                    }}
                  >
                    <div
                      style={{
                        height: "100%",
                        width: `${
                          percentage_success > 100 ? 100 : percentage_success
                        }%`,
                        backgroundColor: "#2196f3",
                        borderRadius: "2rem",
                      }}
                    />
                  </div>
                  <div
                    className="text_medium"
                    style={{ marginTop: "5px", marginBottom: "80px" }}
                  >
                    {percentage_success} %
                    <br />
                    {dict["Data accepted"][language]} (
                    {features_accepted.length} {dict["of"][language]} {quota})
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    style={{
                      height: "10px",
                      backgroundColor: "#dedede",
                      borderRadius: "2rem",
                      padding: "3px",
                      margin: "auto",
                      width: "80%",
                    }}
                  >
                    <div
                      style={{
                        height: "100%",
                        width: `${percentage > 100 ? 100 : percentage}%`,
                        backgroundColor: "#7cc735ff",
                        borderRadius: "2rem",
                      }}
                    />
                  </div>
                  <div
                    className="text_medium"
                    style={{ marginTop: "5px", marginBottom: "80px" }}
                  >
                    {percentage} %
                    <br />
                    {dict["Potential responders"][language]} ({amounts_sum}{" "}
                    {dict["of"][language]} {quota})
                  </div>
                </div>
              )}
              <div style={{ textAlign: "right" }}>
                <div
                  onClick={this.handleCreateDoc}
                  className="button"
                  id="dark_blue"
                  style={{ marginRight: "3px", marginBottom: "3px" }}
                >
                  {dict["Edit Instructions"][language]}
                </div>
                <Link
                  to={`/form_editor/${geo_layer?.link}`}
                  className="button"
                  id="dark_blue"
                  style={{ marginRight: "3px", marginBottom: "3px" }}
                >
                  {dict["Edit Form"][language]}
                </Link>
                {!!(
                  before_payment.includes(status) ||
                  after_payment.includes(status)
                ) && (
                  <div
                    onClick={this.toggleEditPreference}
                    className="button"
                    id="dark_blue"
                    style={{ marginRight: "3px", marginBottom: "3px" }}
                  >
                    {dict["Edit Preferences"][language]}
                  </div>
                )}
                <a
                  href={`${domain_temp}${geo_layer?.link}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button"
                  id="dark_blue"
                >
                  {dict["Link"][language]}
                </a>
              </div>
            </div>

            <div
              className="container_full"
              style={{
                width: "calc(( 100% - 40px ))",
                height: "auto",
                padding: "20px",
                textAlign: "center",
              }}
            >
              <img alt="FORM MAPID" src={icon_recruitment} width="50%" />
              <div className="text_bold">{dict["Recruitment"][language]}</div>
              <div className="text_inferior">
                {
                  dict[
                    "Surveyors who meet the requirements will be recruited by receiving your survey link or from a notification from us."
                  ][language]
                }
              </div>
              <div style={{ textAlign: "center" }}>
                <button
                  className="button margin_top margin_bottom"
                  onClick={this.toggleSubmitPayment}
                >
                  {dict["Deposit surveyors payment now"][language]}
                </button>
              </div>
            </div>
          </div>
        </section>
        <section>
          <button
            className="button margin_top margin_bottom"
            id="red"
            onClick={this.toggle_rev_all}
          >
            Kirim pesan revisi untuk semua isian
          </button>
        </section>
        <section>
          {survey_status.includes(status) && (
            <main
              style={{
                zIndex: "8",
                position: "fixed",
                right: "10px",
                bottom: "10px",
                backgroundColor: "#fff",
                borderRadius: "10px",
                padding: "10px",
                width: "200px",
                boxShadow: "0 0 6px #9d9d9d53",
                textAlign: "center",
              }}
            >
              <section
                style={{
                  height: "10px",
                  backgroundColor: "#dedede",
                  borderRadius: "2rem",
                  padding: "3px",
                  margin: "auto",
                  width: "80%",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: `${
                      percentage_success > 100 ? 100 : percentage_success
                    }%`,
                    backgroundColor: "#2196f3",
                    borderRadius: "2rem",
                  }}
                />
              </section>
              <section className="text_inferior" style={{ marginTop: "5px" }}>
                {percentage_success} %
                <br />
                {percentage_success < 100
                  ? `${dict["Data accepted"][language]} (${features_accepted.length} ${dict["of"][language]} ${quota})`
                  : `${dict["The survey has been successful, you can no longer provide incentives, because the money you paid has all been distributed."][language]}`}
              </section>
            </main>
          )}
        </section>
      </main>
    );

    this.setState({
      language,
      geo_layer_id: geo_layer?._id,
      fields_state: geo_layer?.fields ? geo_layer?.fields : [],

      status,
      percentage_success,
      fields: geo_layer?.fields ? geo_layer?.fields : [],
      features,
      features_slice,

      surveyors,

      //react tag components
      top_content,
      // data_list_slice,
      // surveyor_list_slice,
      // full_content_slice,
    });
  };

  toggle_success = (feature_state) => {
    this.setState({ modal_success: !this.state.modal_success });
    if (feature_state) {
      this.setState({ feature_state });
    }
  };

  toggle_rev_message_satu = () => {
    this.setState({
      modal_rev_message_satu: !this.state.modal_rev_message_satu,
    });
  };

  toggle_full_data = () => {
    if (!this.state.is_full_data) {
      this.setState(
        {
          modal_warn_full_data: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              is_full_data: true,
              modal_warn_full_data: false,
            });
          }, 3000);
        }
      );
    } else {
      this.setState({ is_full_data: false });
    }
  };

  toggle_warn_full_data = () => {
    this.setState({ modal_warn_full_data: !this.state.modal_warn_full_data });
  };

  on_save_rev_satu = () => {
    const { layer_id, geo_layer_list } = this.props.layer;
    const { fields_state, feature_state } = this.state;

    let geo_layer = geo_layer_list.find(
      (e) => e.geo_layer._id === layer_id
    )?.geo_layer;

    let features = geo_layer?.geojson?.features
      ? geo_layer?.geojson?.features
      : [];

    const feature_index = features.findIndex(
      (f) => f.key === feature_state.key
    );

    const revision_list = fields_state
      .filter((f) => f.rev)
      .map((f) => {
        return { field_uuid: f.uuid, message: f.message ? f.message : "" };
      });

    const body = {
      geo_layer_id: layer_id,
      feature_index,
      revision_list,
    };

    this.props.push_revision_list(body);

    this.setState({ modal_form_reject: false });
  };

  on_save_rev = () => {
    const { layer_id, geo_layer_list } = this.props.layer;
    const { message, field } = this.state;
    const revision_list = [
      {
        field_uuid: field.uuid,
        message,
      },
    ];
    let geo_layer = geo_layer_list.find(
      (e) => e.geo_layer._id === layer_id
    )?.geo_layer;
    let features = geo_layer?.geojson?.features
      ? geo_layer?.geojson?.features
      : [];
    const body = {
      geo_layer_id: layer_id,
      length: features?.length,
      revision_list,
    };
    this.props.push_revision_list_all(body);
  };

  toggle_rev_message = (uuid) => {
    this.setState({ modal_rev_message: !this.state.modal_rev_message });
    if (uuid) {
      this.setState({
        field: this.state.fields_state.find((f) => f.uuid === uuid),
      });
    }
  };

  toggle_rev_field = (uuid) => {
    let { fields } = this.state;
    const index = fields.findIndex((f) => f.uuid === uuid);
    fields[index].rev = !fields[index].rev;
    this.setState({ fields });
  };

  on_change_inside = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const id = e.target.id;
    const value = e.target.value;
    if (name === "message_rev") {
      let { fields_state } = this.state;
      fields_state[id].message = value;
      this.setState({ fields_state });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  toggle_rev_all = () => {
    this.setState({ modal_rev_all: !this.state.modal_rev_all });
  };

  toggle_full = () => {
    this.setState({ modal_full: !this.state.modal_full });
  };

  // toggleFullscreen = () => {
  //   this.setState({ modalFullscreen: !this.state.modalFullscreen });
  // };

  on_form_validation = () => {
    const { feature_state } = this.state;
    let { layer_id } = this.props.layer;

    const body = {
      geo_layer_id: layer_id,
      feature_key: feature_state.key,
      formStatus: {
        status: "success",
        message: "",
        date: Date.now(),
      },
    };

    this.props.form_validation(body);

    this.setState({ modal_success: false });
  };

  toggle_form_reject = (feature, idx) => {
    this.setState({ modal_form_reject: !this.state.modal_form_reject }, () => {
      if (feature) {
        //STEP 1 mengambil nilai initial array field_state (masih kosong)
        let { fields_state } = this.state;

        //STEP 2 ambil isi message dari baris feature yg diklik
        const revision_list = feature?.formStatus?.revision_list || [];
        const revision_list_uuid = revision_list.map((r) => r.field_uuid);

        //STEP 3 mengisi fields_state dengan array revisi jika ada isinya
        fields_state = fields_state.map((f) => {
          //STEP 4 nilai awal untuk setiap field adalah false dan messagenya kosong
          let rev = false;
          let message = "";
          if (revision_list_uuid.includes(f.uuid)) {
            //STEP 5 jika ada isinya maka rev di set menjadi true dan messagenya diambil dar revision_list
            rev = true;
            message = revision_list.find(
              (r) => r.field_uuid === f.uuid
            )?.message;
          }
          f.rev = rev;
          f.message = message;
          return f;
        });
        this.setState({ feature_state: feature, fields_state });
      }
    });
  };

  onChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  onClickMenu = (menu) => {
    this.setState({ menu });
  };

  onClickTable = (table_mode) => {
    this.setState({ table_mode });
  };

  toggleSubmitPayment = () => {
    this.setState({ modalSubmitPayment: !this.state.modalSubmitPayment });
  };

  toggleEditPreference = () => {
    this.setState({ modalEditPreference: !this.state.modalEditPreference });
  };

  handleCreateDoc = () => {
    const geo_layer_id = this.props.layer?.geo_layer?._id;
    const geo_layer_name = this.props.layer?.geo_layer?.name;
    const body = {
      geo_layer_id,
      title: geo_layer_name,
      layers: {
        geo_layer: geo_layer_id,
        date: Date.now(),
      },
    };
    this.props.createFormDoc(body);
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";

    const {
      top_content,
      modalEditPreference,
      status,
      modalSubmitPayment,
      modal_form_reject,
      fields_state,
      percentage_success,
      fields,
      features,
      features_slice,
      feature_key,
      modal_rev_message,
      message,
      modal_rev_all,
      modal_full,
      table_mode,

      // surveyor_list_slice,

      // data_list_slice,

      surveyors,
      is_full_data,

      modal_warn_full_data,
      modal_success,
    } = this.state;

    const features_final = is_full_data ? features : features_slice;

    const { loadingProcess, itemLoading } = this.props.layer;

    const modal_success_content = modal_success && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_success}
        onClose={this.toggle_success}
      >
        <div className="box-body text_center">
          <p className="text_header">
            Apakah Anda yakin untuk memvalidasikan isian ini?
          </p>
          <p className="text_medium">
            Dana survei akan diteruskan ke surveyor ini.
          </p>
          <br />
          <button
            className="button"
            id="green"
            onClick={this.on_form_validation}
          >
            Konfirmasi
          </button>
        </div>
      </Modal>
    );

    const modal_warn_full_data_content = modal_warn_full_data && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_warn_full_data}
        onClose={this.toggle_warn_full_data}
      >
        <div className="box-body">
          <main
            style={{
              height: "300px",
              textAlign: "center",
              paddingTop: "100px",
            }}
          >
            <p className="text_bold">Loading...</p>
            <br />
            <p className="text_bold">
              Menampilkan semua data mungkin akan membutuhkan waktu lebih lama
              dan akan memperlambat platform
            </p>
            <br />
          </main>
        </div>
      </Modal>
    );

    const modalEditPreferenceContent = modalEditPreference && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modalEditPreference}
        onClose={this.toggleEditPreference}
      >
        <div className="box-body">
          {before_payment.includes(status) ? (
            <EditBillingFormBeforePayment />
          ) : after_payment.includes(status) ? (
            <EditBillingFormAfterPayment />
          ) : (
            <div></div>
          )}
        </div>
      </Modal>
    );

    const modalSubmitPaymentContent = modalSubmitPayment && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={modalSubmitPayment}
        onClose={this.toggleSubmitPayment}
      >
        <div className="box-body">
          <PaymentForm />
        </div>
      </Modal>
    );

    const modal_form_reject_content = modal_form_reject && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_form_reject}
        onClose={this.toggle_form_reject}
      >
        <div>
          <main>
            <section
              style={{
                position: "sticky",
                top: "0px",
                textAlign: "center",
                backgroundColor: "#ffffff",
                zIndex: "1000",
              }}
            >
              <h1 className="text_bold">
                Pilih pertanyaan & pesan revisi, hanya pertanyaan yang pilih
                saja yang akan muncul di form survei revisi (revisi satu isian)
              </h1>
              <br />
            </section>
            <div className="modal_reject_row">
              {fields_state.map((f, idx) => {
                return (
                  <div className="kotak_revisi_paid" key={idx}>
                    <h2>{idx + 1}</h2>
                    <div style={{ width: "70%" }}>
                      <h3>{f.name}</h3>
                      <p>
                        {f.rev
                          ? `Akan direvisi, klik sekali lagi untuk undo.Tambah keterangan revisi untuk kolom/pertanyaan ini pada kolom di sebelah kiri.`
                          : "Klik untuk tandai pertanyaan untuk direvisi."}
                      </p>
                      <button
                        className="button_small"
                        id={f.rev ? "red" : "green"}
                        onClick={this.toggle_rev_field.bind(this, f.uuid)}
                      >
                        {f.rev ? "batal" : "revisi"}
                      </button>
                    </div>
                    {f.rev ? (
                      <textarea
                        className="input_new"
                        rows={4}
                        name="message_rev"
                        id={idx}
                        value={f.message}
                        onChange={this.on_change_inside}
                        type="text"
                      />
                    ) : null}
                  </div>
                );
              })}
            </div>
            <section
              style={{
                position: "sticky",
                bottom: "0px",
                textAlign: "center",
                backgroundColor: "#ffffff",
              }}
            >
              {loadingProcess && itemLoading === "push_revision_list" ? (
                <button className="button margin_top" id="blue_disable">
                  Memproses...
                </button>
              ) : (
                <button
                  className="button margin_top"
                  id="red"
                  onClick={this.on_save_rev_satu}
                >
                  Ajukan revisi ke surveyor
                </button>
              )}
              <br />
              {loadingProcess && itemLoading === "push_revision_list" ? (
                <button className="button margin_top" id="blue_disable">
                  Memproses...
                </button>
              ) : (
                <button
                  className="button margin_top"
                  id="blue"
                  onClick={this.on_save_rev_satu}
                >
                  Batalkan revisi (jadikan pending kembali)
                </button>
              )}
            </section>
          </main>
        </div>
      </Modal>
    );

    const modal_rev_message_content = modal_rev_message && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_rev_message}
        onClose={this.toggle_rev_message}
      >
        <div className="box-body">
          <p className="text_inferior">
            Tuliskan petunjuk revisi untuk pertanyaan ini
          </p>
          <textarea
            name="message"
            id="message"
            value={message}
            type="text"
            rows="10"
            onChange={this.onChange}
          />
          <section
            style={{
              position: "sticky",
              bottom: "0px",
              textAlign: "center",
              backgroundColor: "#ffffff",
            }}
          >
            {loadingProcess && itemLoading === "push_revision_list_all" ? (
              <button className="button" id="blue_disable">
                Menyimpan...
              </button>
            ) : (
              <button className="button" onClick={this.on_save_rev}>
                Simpan
              </button>
            )}
          </section>
        </div>
      </Modal>
    );

    const modal_rev_all_content = modal_rev_all && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_rev_all}
        onClose={this.toggle_rev_all}
      >
        <div className="box-body">
          <main style={{ minHeight: "100vh" }}>
            <h1 className="text_bold">
              Pilih pertanyaan & pesan revisi, hanya pertanyaan yang pilih saja
              yang akan muncul di form survei revisi (revisi semua isian)
            </h1>
            <table className="table">
              <tbody>
                {fields_state.map((f, idx) => {
                  return (
                    <tr key={idx}>
                      <td> {idx + 1}</td>
                      <td>{f.name}</td>
                      <td>
                        <button
                          className="button"
                          onClick={this.toggle_rev_message.bind(this, f.uuid)}
                        >
                          Isi petunjuk revisi
                        </button>
                      </td>
                      <td>
                        <button
                          className="button_small"
                          id={f.rev ? "red" : "blue_disable"}
                          onClick={this.toggle_rev_field.bind(this, f.uuid)}
                        >
                          -
                        </button>
                        <p className="text_inferior">
                          {f.rev
                            ? "Akan direvisi, klik sekali lagi untuk undo"
                            : "Klik untuk tandai pertanyaan untuk direvisi"}
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <section
              style={{
                position: "sticky",
                bottom: "0px",
                textAlign: "center",
                backgroundColor: "#ffffff",
              }}
            >
              <button className="button">Simpan</button>
            </section>
          </main>
        </div>
      </Modal>
    );

    const modal_full_content = modal_full && (
      <div
        style={{
          zIndex: "9",
          width: "100vw",
          height: "100vh",
          backgroundColor: "#fff",
          position: "fixed",
          top: "0px",
          left: "0px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            textAlign: "center",
          }}
        >
          <div
            style={{
              width: "300px",
              height: "70px",
              textAlign: "center",
              margin: "auto",
              backgroundColor: "#fff",
              borderRadius: "20px",
              padding: "5px",
              boxShadow: "0 0 6px #9d9d9d53",
              marginBottom: "10px",
            }}
          >
            <div className="text_bold" style={{ marginTop: "5px" }}>
              <div onClick={this.toggle_full} className="button_standard_tight">
                {dict["Close"][language]}
              </div>
            </div>
            <div
              style={{
                height: "10px",
                backgroundColor: "#dedede",
                borderRadius: "2rem",
                padding: "3px",
                margin: "auto",
                width: "80%",
              }}
            >
              <div
                style={{
                  height: "100%",
                  width: `${
                    percentage_success > 100 ? 100 : percentage_success
                  }%`,
                  backgroundColor: "#2196f3",
                  borderRadius: "2rem",
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ overflow: "scroll" }}>
          <div
            style={{
              maxHeight: "calc(( 100vh - 110px  ))",
            }}
          >
            <table className="table" style={{ minWidth: "100%" }}>
              <thead>
                <tr style={{ textAlign: "left" }}>
                  <th style={{ textAlign: "left" }}>No</th>
                  {fields.map((field, idx) => {
                    return (
                      <th key={idx} style={{ minWidth: "55px" }}>
                        {field.name}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              {/* onClick={this.on_form_validation.bind(
                          this,
                          feature.key
                        )} */}
              <tbody>
                {features_final.map((feature, idx) => {
                  let action_content = (
                    <section>
                      <button
                        onClick={this.toggle_success.bind(this, feature)}
                        className="button_standard_tight"
                        id="blue"
                        style={{ marginRight: "10px" }}
                      >
                        {dict["Accept"][language]}
                      </button>
                      <button
                        onClick={this.toggle_form_reject.bind(this, feature)}
                        className="button_standard_tight"
                        id="red"
                      >
                        {dict["Revision"][language]}
                      </button>
                    </section>
                  );
                  if (
                    loadingProcess &&
                    itemLoading === "form_validation" &&
                    feature_key === feature.key
                  ) {
                    action_content = (
                      <div className="button_standard_tight" id="blue">
                        {dict["Processing"][language]}...
                      </div>
                    );
                  } else if (
                    ["success", "paid_off"].includes(
                      feature?.formStatus?.status
                    )
                  ) {
                    action_content = (
                      <div className="button_standard_tight" id="green">
                        {dict["Valid"][language]}
                      </div>
                    );
                  } else if (feature?.formStatus?.status === "rejected") {
                    action_content = (
                      <div className="button_standard_tight" id="grey">
                        {dict["Under revision"][language]}
                      </div>
                    );
                  }
                  return (
                    <tr key={idx}>
                      <th>
                        {idx + 1}
                        <br />
                        {action_content}
                      </th>
                      {fields.map((field, idx) => {
                        let content;
                        switch (field.type) {
                          case "text":
                            content = (
                              <div>{feature.properties[field.key]}</div>
                            );
                            break;
                          case "textarea":
                            content = (
                              <div
                                className="badge_normal"
                                style={{
                                  fontSize: "13px",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {feature.properties[field.key]}
                              </div>
                            );
                            break;
                          case "number":
                            content = (
                              <div style={{ textAlign: "right" }}>
                                {new Intl.NumberFormat("id-ID", {
                                  style: "decimal",
                                }).format(feature.properties[field.key])}
                              </div>
                            );
                            break;
                          case "slider":
                            content = (
                              <div style={{ textAlign: "right" }}>
                                {new Intl.NumberFormat("id-ID", {
                                  style: "decimal",
                                }).format(feature.properties[field.key])}
                              </div>
                            );
                            break;
                          case "currency":
                            content = (
                              <div style={{ textAlign: "right" }}>
                                {new Intl.NumberFormat("id-ID", {
                                  style: "decimal",
                                }).format(feature.properties[field.key])}
                              </div>
                            );
                            break;
                          case "year":
                            content = (
                              <div style={{ textAlign: "right" }}>
                                {feature.properties[field.key]}
                              </div>
                            );
                            break;
                          case "date":
                            content = (
                              <div style={{ textAlign: "right" }}>
                                {feature.properties[field.key]}
                              </div>
                            );
                            break;
                          case "time":
                            content = (
                              <div style={{ textAlign: "right" }}>
                                {feature.properties[field.key]}
                              </div>
                            );
                            break;
                          case "image":
                            content = feature.properties[field.key] ? (
                              <img
                                alt="GEO MAPID"
                                src={feature.properties[field.key]}
                                style={{ maxHeight: "100px", height: "100px" }}
                              />
                            ) : (
                              <div
                                style={{ maxHeight: "100px", height: "100px" }}
                              >
                                {dict["No image"][language]}
                              </div>
                            );
                            break;
                          case "link":
                            content = (
                              <div>
                                <a
                                  href={`https://${
                                    feature.properties[field.key]
                                  }`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="badge_normal"
                                >
                                  {feature.properties[field.key]}
                                </a>
                              </div>
                            );
                            break;
                          default:
                            content = (
                              <div>{feature.properties[field.key]}</div>
                            );
                            break;
                        }
                        return <td key={idx}>{content}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );

    const list_content =
      table_mode === "surveyors" ? (
        <main>
          <table style={{ width: "100%" }} className="table_lite">
            <thead>
              <tr>
                <th style={{ textAlign: "left" }}>
                  {dict["Surveyor"][language]}
                </th>
                <th>{dict["Target"][language]}</th>
                <th>{dict["Received"][language]}</th>
                <th>{dict["Accepted"][language]}</th>
                <th>{dict["Under Revision"][language]}</th>
                <th>{dict["Pending"][language]}</th>
              </tr>
            </thead>
            <tbody>
              {surveyors.map((surveyor) => {
                const { _id, user, amount } = surveyor;
                const user_id = user?._id;
                const features_received = features.filter(
                  (feature) => feature.user._id === user_id
                );
                const features_accepted = features_received.filter((feature) =>
                  ["success", "paid_off"].includes(feature.formStatus.status)
                );
                const features_rejected = features_received.filter((feature) =>
                  ["rejected"].includes(feature.formStatus.status)
                );
                const features_pending = features_received.filter((feature) =>
                  ["pending"].includes(feature.formStatus.status)
                );
                const full_name = user?.full_name ? user.full_name : user.name;
                const profile_picture = user?.profile_picture?.url;
                return (
                  <tr key={_id}>
                    <td>
                      <div
                        style={{
                          width: "50px",
                          height: "50px",
                          background: `url(${profile_picture}) no-repeat center center/ cover`,
                          backgroundColor: "#cecece",
                          display: "inline-block",
                          borderRadius: "9px",
                        }}
                      />
                      {full_name}
                    </td>
                    <td style={{ textAlign: "center" }}>{amount}</td>
                    <td style={{ textAlign: "center" }}>
                      {features_received.length}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {features_accepted.length}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {features_rejected.length}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {features_pending.length}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </main>
      ) : (
        <main>
          <main>
            <section
              className="text_bold"
              style={{ textAlign: "center", marginBottom: "10px" }}
            >
              <p>
                Untuk meringankan web, hanya menampilkan 100 data pertama klik
                tombol dibawah untuk menampilkan semua data
              </p>
              <button className="button" onClick={this.toggle_full_data}>
                {is_full_data ? "Tampilkan 100 data" : "Tampilkan semua data"}
              </button>
            </section>
            <main
              style={{
                maxHeight: "calc(( 100vh - 300px ))",
                width: "100%",
                overflowX: "scroll",
              }}
            >
              <table className="table">
                <thead>
                  <tr style={{ textAlign: "left" }}>
                    <th style={{ textAlign: "left" }}>No</th>
                    {fields.map((field, idx) => {
                      return (
                        <th key={idx} style={{ minWidth: "55px" }}>
                          {field.name}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {features_final.map((feature, idx) => {
                    // let action_content = (
                    //   <div>
                    //     <div
                    //       onClick={this.on_form_validation.bind(
                    //         this,
                    //         feature.key
                    //       )}
                    //       className="button_small margin_1"
                    //       id="blue"
                    //     >
                    //       {dict["Accept"][language]}
                    //     </div>
                    //     <div
                    //       onClick={this.toggle_form_reject.bind(
                    //         this,
                    //         feature.key,
                    //         idx
                    //       )}
                    //       className="button_small margin_1"
                    //       id="red"
                    //     >
                    //       {dict["Revision"][language]}
                    //     </div>
                    //   </div>
                    // );
                    // if (
                    //   loadingProcess &&
                    //   itemLoading === "form_validation" &&
                    //   feature_key === feature.key
                    // ) {
                    //   action_content = (
                    //     <div className="button_standard_tight" id="blue">
                    //       {dict["Processing"][language]}...
                    //     </div>
                    //   );
                    // } else if (
                    //   ["success", "paid_off"].includes(
                    //     feature?.formStatus?.status
                    //   )
                    // ) {
                    //   action_content = (
                    //     <div className="button_standard_tight" id="green">
                    //       {dict["Valid"][language]}
                    //     </div>
                    //   );
                    // } else if (feature?.formStatus?.status === "rejected") {
                    //   action_content = (
                    //     <div className="button_standard_tight" id="grey">
                    //       {dict["Under revision"][language]}
                    //     </div>
                    //   );
                    // }
                    return (
                      <tr key={idx}>
                        <th style={{ textAlign: "left" }}>
                          {idx + 1}
                          <br />
                          <div>
                            Status:{" "}
                            <span
                              className="badge_small margin_bottom"
                              id={
                                feature?.formStatus?.status === "rejected"
                                  ? "red"
                                  : feature?.formStatus?.status === "success"
                                  ? "green"
                                  : "grey"
                              }
                            >
                              {feature?.formStatus?.status}
                            </span>
                          </div>
                          <section className="box" id="white">
                            <p className="text_inferior">Actions:</p>
                            <button
                              onClick={this.toggle_success.bind(this, feature)}
                              className="button_small margin_1"
                              id="blue"
                            >
                              {dict["Accept"][language]}
                            </button>
                            <br />
                            <button
                              onClick={this.toggle_form_reject.bind(
                                this,
                                feature,
                                idx
                              )}
                              className="button_small margin_1"
                              id="red"
                            >
                              {dict["Revision"][language]}
                            </button>
                          </section>
                        </th>
                        {fields.map((field, idx) => {
                          let content;
                          switch (field.type) {
                            case "text":
                              content = (
                                <div>{feature.properties[field.key]}</div>
                              );
                              break;
                            case "textarea":
                              content = (
                                <div
                                  className="badge_normal"
                                  style={{
                                    fontSize: "13px",
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  {feature.properties[field.key]}
                                </div>
                              );
                              break;
                            case "number":
                              content = (
                                <div style={{ textAlign: "right" }}>
                                  {new Intl.NumberFormat("id-ID", {
                                    style: "decimal",
                                  }).format(feature.properties[field.key])}
                                </div>
                              );
                              break;
                            case "slider":
                              content = (
                                <div style={{ textAlign: "right" }}>
                                  {new Intl.NumberFormat("id-ID", {
                                    style: "decimal",
                                  }).format(feature.properties[field.key])}
                                </div>
                              );
                              break;
                            case "currency":
                              content = (
                                <div style={{ textAlign: "right" }}>
                                  {new Intl.NumberFormat("id-ID", {
                                    style: "decimal",
                                  }).format(feature.properties[field.key])}
                                </div>
                              );
                              break;
                            case "year":
                              content = (
                                <div style={{ textAlign: "right" }}>
                                  {feature.properties[field.key]}
                                </div>
                              );
                              break;
                            case "date":
                              content = (
                                <div style={{ textAlign: "right" }}>
                                  {feature.properties[field.key]}
                                </div>
                              );
                              break;
                            case "time":
                              content = (
                                <div style={{ textAlign: "right" }}>
                                  {feature.properties[field.key]}
                                </div>
                              );
                              break;
                            case "image":
                              content = feature.properties[field.key] ? (
                                <img
                                  alt="GEO MAPID"
                                  style={{
                                    maxHeight: "100px",
                                    height: "100px",
                                  }}
                                  src={feature.properties[field.key]}
                                />
                              ) : (
                                <div
                                  style={{
                                    maxHeight: "100px",
                                    height: "100px",
                                  }}
                                >
                                  {dict["No image"][language]}
                                </div>
                              );
                              break;
                            case "link":
                              content = (
                                <div>
                                  <a
                                    href={`https://${
                                      feature.properties[field.key]
                                    }`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="badge_normal"
                                  >
                                    {feature.properties[field.key]}
                                  </a>
                                </div>
                              );
                              break;

                            default:
                              content = (
                                <div>{feature.properties[field.key]}</div>
                              );
                              break;
                          }
                          return <td key={idx}>{content}</td>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </main>
          </main>
        </main>
      );

    return (
      <main id="main_container">
        {modal_success_content}
        {modal_warn_full_data_content}
        {modalEditPreferenceContent}
        {modalSubmitPaymentContent}
        {modal_form_reject_content}

        {modal_rev_message_content}
        {modal_rev_all_content}
        {modal_full_content}

        {top_content}

        <main>
          <section
            className="text_bold"
            style={{ textAlign: "center", marginBottom: "10px" }}
          >
            <div onClick={this.toggle_full} className="button">
              {dict["Full Screen"][language]}
            </div>
          </section>
          <section style={{ textAlign: "center", marginBottom: "10px" }}>
            <div
              onClick={this.onClickTable.bind(this, "surveyors")}
              className="button"
              id={table_mode === "surveyors" ? "blue" : "blue_disable"}
            >
              {dict["Surveyors"][language]}
            </div>
            <div
              onClick={this.onClickTable.bind(this, "data")}
              className="button"
              id={table_mode === "data" ? "blue" : "blue_disable"}
              style={{ marginLeft: "10px" }}
            >
              {dict["Data"][language]}
            </div>
          </section>
        </main>
        {list_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  auth: state.auth,
  map: state.map,
});

export default connect(mapStateToProps, {
  createFormDoc,
  getBlogDetailForm,
  form_validation,
  push_revision_list_all,
  push_revision_list,
})(PaidFormDashboard);
