import React, { Component } from "react";
import { get_option_path } from "../../App/validation/nested_object";

export default class Path extends Component {
  render() {
    const { object, path, title } = this.props;

    return (
      <div className="w_full">
        <label>{title}:</label>
        <div className="flex w_full gap_5">
          <label
            type="text"
            className="w_80_percent"
            // value={}
            // onChange={() => {}}
            // placeholder="insert path"
          >
            {this.props.path.join(" > ")}
          </label>
          <button
            className="button_inactive bg_red rounded_5 padding_5 text_white h_40 w_100"
            onClick={(e) => {
              this.props.handle_delete_path(title.toLowerCase());
            }}
          >
            Delete
          </button>
          <select
            className="w_100"
            onChange={(e) => {
              if (e.target.value !== "") {
                this.props.handle_add_path(title.toLowerCase(), e.target.value);
              }
            }}
            value={path.at(-1)}
          >
            <option key={""} value={""}>
              Select
            </option>
            {Object.keys(object).length > 0 &&
              get_option_path(object, path).map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
          </select>
        </div>
      </div>
    );
  }
}
