import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";

const SERVER_URL = geoServerBaseUrl;

/*PROTOTYPE*/

export const delete_project_to_recovery = (body) => async (dispatch) => {
    try {
        const config = {
            headers: {
                accesstoken: localStorage.jwtTokenMapid,
            },
        };

        const { project_id } = body

        dispatch(setLoadingProcess("delete_project_to_recovery"));

        await axios.delete(SERVER_URL + `/projects/delete_project/${project_id}`, config);

        dispatch(clearLoading());

    } catch (e) {
        dispatch(clearLoading());
    }
};

export const get_layers_from_recovery = (body) => async (dispatch) => {
    try {
        const config = {
            headers: {
                accesstoken: localStorage.jwtTokenMapid,
            },
        };

        const { limit = 15, skip = 0, attribute = "trash.updated_at", sort_value = -1 } = body

        dispatch(setLoadingProcess("get_layers_from_recovery"));

        const result = await axios.get(SERVER_URL + `/layers_new/get_list_trash?limit=${limit}&skip=${skip}&sort_field=${attribute}&sort_value=${sort_value}`, config);

        dispatch(clearLoading());

        if (result?.status === 200) {
            return result?.data
        }


    } catch (e) {
        dispatch(clearLoading());
        return []
    }
};

export const delete_layer_permanent = (body) => async (dispatch) => {
    try {
        const config = {
            headers: {
                accesstoken: localStorage.jwtTokenMapid,
            },
        };

        dispatch(setLoadingProcess("get_layers_from_trash"));

        const result = await axios.post(SERVER_URL + `/layers_new/delete_permanent`, body, config);

        dispatch(clearLoading());

        if (result?.status === 200) {
            return result?.data
        }

    } catch (e) {
        dispatch(clearLoading());
        return []
    }
};

export const restore_layer = (body) => async (dispatch) => {
    try {
        const config = {
            headers: {
                accesstoken: localStorage.jwtTokenMapid,
            },
        };

        dispatch(setLoadingProcess("restore_layers"));

        const result = await axios.post(SERVER_URL + `/layers_new/restore_trash`, body, config);

        dispatch(clearLoading());

        if (result?.status === 200) {
            return result?.data
        }

    } catch (e) {
        dispatch(clearLoading());
    }
};


export const setLoadingProcess = (itemLoading) => {
    return {
        type: "SET_LOADING_PROCESS_LAYER",
        payload: itemLoading,
    };
};

export const clearLoading = () => {
    return {
        type: "CLEAR_LOADING_LAYER",
    };
};