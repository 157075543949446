//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import { StyledDataGrid } from "../../Style/StyledDataGrid";
import {
  convertFieldsToHeadersForSecondGenOrMore,
  convertPropertiesToDataList,
  packThePropertiesDataToUseApiEditNested,
} from "../../App/validation/convert_data";
import Modal from "../common_modal/Modal";
import EditField from "./EditField";

//Redux function
import { set_value } from "../../App/actions/dataActions";

//Picture Asset

//General Function
import is_empty from "../../App/validation/is-empty";

//Data
import dict from "../../Data/dict.json";
import PushField from "./PushField";
import {
  getChildrenFeaturesByColumnParentUuidAndRowParentUuid,
  getFeatureByKey,
  getGeoLayerById,
} from "../../App/reduxHelper/layer";
import { edit_child_cell } from "../../App/actions/crudNestedTable";
import CustomToolbarContainerNested from "./CustomToolbarContainerNested";
import NumberAndActionNested from "../custom_cell/NumberAndActionNested";

class ParentTableEditor extends Component {
  constructor(props) {
    super(props);
    this.component_id = props?.row?.key;
    this.field_parent = props?.column?.uuid;
    this.state = {
      layer_id: "",
      headers: [],
      data_list: [],
      fields: [],
      field: {},
      old_cell: {},
      modal_delete_field: false,
      modal_edit_field: false,
      modal_push_field: false,
      child_fields_shown: [],
    };
  }

  componentDidMount() {
    const { child_fields_shown } = this.props.layer;
    this.setState(
      {
        child_fields_shown,
      },
      this.on_render(child_fields_shown)
    );
  }

  componentDidUpdate = (prevProps) => {
    if (
      prevProps?.layer?.refresh_nested_table?.change_var !==
        this.props.layer?.refresh_nested_table?.change_var &&
      this.component_id === this.props.layer?.refresh_nested_table?.component_id
    ) {
      const { row, column } = this.props;

      const child_features = this.get_children_features(row, column);

      this.setState(
        {
          child_fields_shown: child_features,
        },
        () => {
          this.on_render(child_features);
        }
      );
    }
  };

  get_children_features = (row, column) => {
    const { is_from_first_gen_table, layer, layer_id } = this.props;

    const { selected_parent_table_row_key } = layer;

    const geo_layer = getGeoLayerById({ layer, layer_id });
    const feature = getFeatureByKey({
      geo_layer,
      feature_key: selected_parent_table_row_key || row?.key,
    });

    let row_parent_uuid = null;
    if (!is_from_first_gen_table) {
      if (row?.child_uuid) {
        row_parent_uuid = row?.child_uuid;
      } else if (row?.key) {
        row_parent_uuid = row?.key;
      }
    }

    let child_features = getChildrenFeaturesByColumnParentUuidAndRowParentUuid({
      feature,
      column_parent_uuid: column?.uuid,
      row_parent_uuid,
    });

    return child_features;
  };

  handlePreviewText = (value) => {
    this.setState(
      {
        text: value,
      },
      () => {
        this.toggleTextPreview();
      }
    );
  };

  toggle_push_field = () => {
    this.setState({ modal_push_field: !this.state.modal_push_field });
  };

  toggle_delete_field = (params) => {
    const field = this.state.fields.find(
      (field) => field?.key === params?.field
    );
    this.setState({
      modal_delete_field: !this.state.modal_delete_field,
      field: field || {},
    });
  };

  toggle_edit_field = (params) => {
    const field = this.state.fields.find(
      (field) => field?.key === params?.field
    );
    this.setState({
      modal_edit_field: !this.state.modal_edit_field,
      field: field || {},
    });
  };

  /**
   * Keterangan:
   *
   * Ketika cell diklik 2x maka akan menyimpan data sebelum ada perubahan data pada cell. Ini dilakukan dengan tujuan supaya ketika ada perubahan pada cell, aksi tersebut dapat diketahui dan akan langsung merequest API
   */
  onCellEditStart(params) {
    this.setState({ old_cell: params });
  }

  /**
   * Keterangan:
   *
   * Dijalankan ketika onChange dengan fitur bawaan package/library MUI Datagrid
   */
  onCellEditCommit = (event) => {
    if (event?.target?._wrapperState) {
      const cellElement = event.currentTarget;
      const properties_key = cellElement.getAttribute("data-field");
      const child_uuid =
        this.state.data_list?.[
          +cellElement.parentElement.getAttribute("data-id")
        ]?.key;

      const value = !isNaN(event.target?.value)
        ? +event.target?.value
        : event.target?.value;

      const old_value = !isNaN(this.state.old_cell.value)
        ? +this.state.old_cell.value
        : this.state.old_cell.value;

      if (!is_empty(this.state.old_cell) && old_value !== value) {
        let newParams = this.state.old_cell;
        newParams.value = value;
        newParams.row.value = value;
        const { layer_id, row, selected_parent_table_row_key } = this.props;
        const body = {
          geo_layer_id: layer_id,
          feature_key: selected_parent_table_row_key || row?.key,
          child_uuid,
          properties_key,
          properties_value: value,
        };
        this.props.edit_child_cell(body);
        this.setState({ old_cell: {} });
      }
    }
  };

  /**
   * Keterangan:
   *
   * Dijalankan ketika onChange dengan custom cell melalui library MUI Datagrid
   */
  handleEditCellChange(edit_properties_callback, layer_id, params) {
    const body = packThePropertiesDataToUseApiEditNested(layer_id, params);
    edit_properties_callback(body);
  }

  onCellKeyDown = (params, event) => {
    if (event?.key === "Enter" && params?.colDef.isImage) {
      document.getElementById(`${params.row.key}-${params.field}`).click();
    } else if (event?.key === " " && params?.colDef.isImage === false) {
      event.stopPropagation();
    } else if (event?.key === "Enter" && params?.colDef.isDoc) {
      document.getElementById(`${params.row.key}-${params.field}`).click();
    } else if (event?.key === "Enter") {
      this.onCellEditCommit(event);
    }
  };

  on_render = (child_fields_shown) => {
    const { geo_layer_list } = this.props.layer;

    const { selected_parent_table_row_key, row, layer_id } = this.props;

    const geo_layer = getGeoLayerById({ layer: this.props.layer, layer_id });

    const feature_parent = getFeatureByKey({
      geo_layer,
      feature_key: selected_parent_table_row_key,
    });

    const fields = geo_layer?.fields || [];

    const fields_child = fields.filter(
      (f) => f?.parent_uuid === this.field_parent
    );

    let child_array = child_fields_shown || [];

    const headers = convertFieldsToHeadersForSecondGenOrMore(
      {
        fields: fields_child,
        layer_id,
        sortedFeatures: child_array,
      },
      {
        handleEditCellChange: this.handleEditCellChange,
        toggleEditField: this.toggle_edit_field,
        toggleDeleteField: this.toggle_delete_field,
        editProperties: this.props.edit_child_cell,
      }
    );

    const id_pembanding = geo_layer?.layer_data_list?.[0]?._id;
    const layer_pembanding = geo_layer_list.find(
      (item) => item?.geo_layer?._id === id_pembanding
    )?.geo_layer;

    // const newColumn = {
    //   field: "add_column",
    //   headerName: "Add Column",
    //   width: 150,
    //   sortable: false,
    //   editable: false,
    //   renderHeader: (params) => {
    //     return (
    //       <button
    //         className="button bg_white text_black hover_bigger"
    //         onClick={() => {
    //           this.toggle_push_field();
    //         }}
    //       >
    //         New Column
    //       </button>
    //     );
    //   },
    // };

    const deleteColumn = {
      field: "no",
      headerName: "No.",
      width: 80,
      sortable: false,
      renderHeader: (params) => {
        return <>No.</>;
      },
      renderCell: (params) => {
        return (
          <NumberAndActionNested
            geo_layer_id={layer_id}
            feature_key={selected_parent_table_row_key || row?.key}
            child_uuid={params?.row?.key}
            params={params}
            component_id={this.component_id}
          />
        );
      },
    };

    headers.unshift(deleteColumn);

    const data_list = convertPropertiesToDataList({
      features: child_array,
      fields: fields_child,
      layer_pembanding,
      feature_parent,
      source: "Parent table editor",
      nested_attributes: {
        feature_key: selected_parent_table_row_key || row?.key,
      },
    });

    this.setState({
      layer_id: this.props.layer.layer_id,
      headers: headers,
      data_list: data_list,
      fields: fields,
    });
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { data_list = [], headers = [] } = this.state;

    const { layer_id, row, column, selected_parent_table_row_key } = this.props;

    const modal_edit_field = this.state.modal_edit_field && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={this.state.modal_edit_field}
        onClose={this.toggle_edit_field}
      >
        <div className="box-body">
          <EditField headers={headers} field={this.state.field} />
        </div>
      </Modal>
    );

    const modal_delete_field = this.state.modal_delete_field && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={this.state.modal_delete_field}
        onClose={(params) => this.toggle_delete_field(params)}
      >
        <main style={{ textAlign: "center", padding: 20 }}>
          <section className="text_medium">
            {dict["Are you sure to delete?"][language]}
          </section>
          <button
            className="buttonSimple2"
            id="deleteBright"
            style={{ marginTop: "1rem" }}
            onClick={this.handleDeleteField}
          >
            {dict["Delete"][language]}
          </button>
        </main>
      </Modal>
    );

    const modal_push_field = this.state.modal_push_field && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={this.state.modal_push_field}
        onClose={this.toggle_push_field}
      >
        <div className="box-body">
          <PushField
            headers={headers}
            from="nested_table"
            layer_id={this.state.layer_id}
          />
        </div>
      </Modal>
    );

    return (
      <main>
        <section
          style={{
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 5,
            paddingRight: 5,
            width: "100%",
            height: "500px",
          }}
          onClick={(event) => event.stopPropagation()}
        >
          <StyledDataGrid
            rows={data_list}
            columns={headers}
            onCellEditStart={(params) => this.onCellEditStart(params)}
            slotProps={{
              cell: {
                onBlur: (event) => {
                  this.onCellEditCommit(event);
                },
              },
            }}
            onCellKeyDown={(params, event) => this.onCellKeyDown(params, event)}
            getRowHeight={() => 45}
            componentsProps={{
              footer: {
                geo_layer_id: layer_id,
                feature_key: selected_parent_table_row_key || row?.key,
                parent_uuid: column?.uuid,
                parent_uuid_temp: row?.properties ? "" : row?.key,
                component_id: this.component_id,
              },
            }}
            components={{
              Footer: CustomToolbarContainerNested,
            }}
            rowsPerPageOptions={[
              25, 50, 100, 500, 1000, 5000, 10000, 50000, 100000,
            ]}
            disableColumnMenu
          />
        </section>
        {modal_edit_field}
        {modal_delete_field}
        {modal_push_field}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  map: state.map,
});

export default connect(mapStateToProps, {
  edit_child_cell,
  set_value,
})(ParentTableEditor);
