//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
//Personal Component
//Redux function
import { selectPicture } from "../../App/actions/blogAction";
//Picture Asset
//General Function
import dict from "../../Data/dict.json";
class ListPictures extends Component {
  onClickImage = (picture_url) => {
    this.props.selectPicture(picture_url);
  };
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { user } = this.props.auth;
    const uploaded_pictures = user?.uploaded_pictures
      ? user?.uploaded_pictures
      : [];
    const itemContent = (
      <div>
        {uploaded_pictures.map((picture, idx) => {
          const { url, name } = picture;
          return (
            <div
              key={idx}
              onClick={this.onClickImage.bind(this, url)}
              style={{
                display: "inline-block",
                textAlign: "center",
                marginBottom: "10px",
                width: "90px",
                height: "82px",
                overflow: "hidden",
                marginRight: "5px",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  width: "90px",
                  height: "65px",
                  borderRadius: "5px",
                  backgroundImage: `url(${url})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              />
              <div className="text_inferior">{name}</div>
            </div>
          );
        })}
      </div>
    );
    return (
      <div
        style={{
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        <div style={{ marginBottom: "10px" }}>
          {dict["or"][language]}
          <br />
          {dict["Select from your gallery"][language]}
        </div>
        {itemContent}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { selectPicture })(ListPictures);
