import React, { Component } from "react";
import { Route, Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";

import {
  setCurrentUser,
  getUserFromToken,
  setPathname,
  getTutorialList,
  get_quota_access,
} from "../App/actions/authActions";
import { getUserPaymentList } from "../App/actions/paymentActions";
import { get_admin_information_list } from "../App/actions/propertiesActions";

import history from "../App/actions/history";

import Navigation from "../Components/layout/Navigation";
import ModalTutorial from "../Components/layout/ModalTutorial";
import MODAL_SHARE_LINK from "../Components/map_reusable/MODAL_SHARE_LINK";

import { PrivateRoute } from "../App/validation";

import "../Style/App.css";
import "../Style/Rich.css";
import "../Style/SingleStyle.css";

import * as Page from "../Pages";

const jwtTokenMapid = localStorage.jwtTokenMapid;
if (jwtTokenMapid) {
  store.dispatch(setCurrentUser(JSON.parse(localStorage.getItem("mapidUser"))));
  store.dispatch(getUserFromToken(jwtTokenMapid));
  store.dispatch(getUserPaymentList());
  store.dispatch(get_quota_access());
}

class App extends Component {
  componentDidMount() {
    store.dispatch(getTutorialList());
    store.dispatch(get_admin_information_list());
    this.unlisten = history.listen((location, action) => {
      store.dispatch(setPathname(location.pathname));
    });
  }
  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <div className="mainReact">
            <ModalTutorial />
            <Navigation />
            <MODAL_SHARE_LINK />
            {/* Public */}
            <Route exact path="/" component={Page.DashboardPublic} />
            <Route exact path="/login" component={Page.LandingLogin} />
            <Route exact path="/register" component={Page.LandingRegister} />
            <Route
              exact
              path="/forget-password"
              component={Page.LandingForgetPassword}
            />
            <Route exact path="/play/:link" component={Page.ViewerPlay} />
            <Route exact path="/tutorial" component={Page.Tutorial} />
            {/* blog */}
            <Route exact path="/blog_read/:link" component={Page.BlogRead} />
            <Route exact path="/play_list" component={Page.PlayListPublic} />
            <Route
              exact
              path="/public/:mode"
              component={Page.DashboardPublic}
            />
            <Route exact path="/insight" component={Page.ParentInsight} />

            {/* PROJECT_DASHBOARD */}
            <PrivateRoute
              exact
              path="/dashboard"
              component={Page.PROJECT_DASHBOARD}
            />
            <PrivateRoute
              exact
              path="/editor/:geo_project_link"
              component={Page.ParentEditor}
            />
            <PrivateRoute
              exact
              path="/editor/:geo_project_link/:geo_layer_link/:mode"
              // geo_layer_link: layer link || no_layer
              // mode: view || chat || notif
              component={Page.ParentEditor}
            />
            <Route
              exact
              path="/view/:geo_project_link"
              component={Page.ParentViewer}
            />
            {/* Profile */}
            <PrivateRoute
              exact
              path="/user_profile"
              component={Page.UserDashboard}
            />
            <PrivateRoute
              exact
              path="/editprofile"
              component={Page.EditProfile}
            />
            <PrivateRoute
              exact
              path="/sini_access"
              component={Page.SiniAccess}
            />
            <PrivateRoute exact path="/map_access" component={Page.MapAccess} />
            <PrivateRoute
              exact
              path="/income_list"
              component={Page.IncomeList}
            />
            <PrivateRoute
              exact
              path="/purchase_list"
              component={Page.PaymentList}
            />
            <PrivateRoute
              exact
              path="/routing_access"
              component={Page.RoutingAccess}
            />
            {/* Blog */}
            <PrivateRoute exact path="/blog_list" component={Page.BlogList} />
            <PrivateRoute
              exact
              path="/blog_list/:blog_link"
              component={Page.BlogList}
            />
            <PrivateRoute
              exact
              path="/blog_editor/:blog_link"
              component={Page.BlogEditor}
            />
            {/* Form */}
            <PrivateRoute
              exact
              path="/form"
              component={Page.FormListDashboard}
            />
            <PrivateRoute
              exact
              path="/paid_form_list"
              component={Page.PaidFormListDashboard}
            />
            <PrivateRoute
              exact
              path="/form_editor/:geo_layer_link"
              component={Page.CreateForm}
            />
            <PrivateRoute
              exact
              path="/paid_form/:geo_layer_link"
              component={Page.ParentPaidForm}
            />
            {/* Group */}
            <PrivateRoute exact path="/user_group" component={Page.GroupList} />
            <PrivateRoute
              exact
              path="/group/:id"
              component={Page.GroupDetail}
            />

            <PrivateRoute
              exact
              path="/payment/:payment_id"
              component={Page.PaymentInstruction}
            />

            {/* Trash */}
            <PrivateRoute exact path="/recovery" component={Page.Recovery} />
            <style>
              {`
            #startingLoader{ display: none;}
            `}
            </style>
          </div>
        </Router>
      </Provider>
    );
  }
}
export default App;
