/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { io } from "socket.io-client";

/*PERSONAL COMPONENT*/
import EditorMapMain from "../Components/editor_map/EditorMapMain";

/*REDUX FUNCTION*/
import { getUserPaymentList } from "../App/actions/paymentActions";
import {
  getProjectDetailByLink,
  clearProject,
} from "../App/actions/projectActions";
import { clearLayer, realtime_update } from "../App/actions/layerActions";
import {
  finishGenangan,
  getDetailLayerById,
} from "../App/actions/layerNewActions";
import { getGroups, getGroupsProject } from "../App/actions/groupActions";
import { set_value_user } from "../App/actions/authActions";
import { set_value } from "../App/actions/dataActions";
import {
  set_value_properties,
  get_detail_many_layer,
} from "../App/actions/propertiesActions";

/*PICTURE ASSET*/
import icon_404 from "../Assets/svg/icon_404.svg";

/*GENERAL*/
import getStatusMember from "../App/validation/get-status-member";
import dict from "../Data/dict.json";
import decode_params_to_object from "../App/validation/decode_params_to_object";

class ParentEditor extends Component {
  constructor(props) {
    super(props);
    this.socket = io("wss://socket.mapid.io");
  }

  componentDidMount() {
    const geo_project_id = this?.props?.match?.params?.geo_project_link;
    this.activate_socket_by_project(geo_project_id);
    const geo_project_link = geo_project_id;
    this.props.getProjectDetailByLink(geo_project_link);
    this.props.getGroupsProject(geo_project_link);
    this.props.getUserPaymentList();
    this.props.getGroups();
    this.props.set_value_properties({
      key: "folder_list_active",
      value: [],
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.properties.get_layer_list_status !==
        prevProps.properties.get_layer_list_status &&
      this.props.properties.get_layer_list_status === "done"
    ) {
      const { geo_project } = this.props.project;
      const { geo_layer_list } = this.props.layer;

      const params = window?.location?.search?.substring(1);
      let object_params = {};
      let layer_id;
      let folder_id;
      let sidebar_right_status = false;

      let layer_list_load_first = geo_project?.layer_list_load_first || [];
      layer_list_load_first = layer_list_load_first.filter((item) => !!item);

      if (params) {
        object_params = decode_params_to_object(params);
        layer_id = object_params?.layer_id;
        folder_id = object_params?.folder_id;
        sidebar_right_status =
          object_params?.sidebar_right_status === "true" ? true : false;
      }

      if (layer_id) {
        const folder_id = geo_layer_list.find(
          (item) => item.geo_layer._id === layer_id
        )?.folder;
        const body = {
          geo_layer_id: layer_id,
          geo_project_id: geo_project._id,
          flyStatus: true,
          folder_id,
        };
        this.props.getDetailLayerById(body);
      }

      if (folder_id) {
        const folder = geo_project.folders.find(
          (item) => item._id === folder_id
        );
        if (folder) {
          this.props.set_value_properties({
            key: "sidebar_right_status",
            value: true,
          });
          this.props.set_value_properties({
            key: "folder_kai_timeseries",
            value: folder,
          });
          const layer_list = geo_layer_list
            .filter(
              (element) =>
                element?.folder === folder_id &&
                !element?.geo_layer?.geojson?.features?.length
            )
            .map((item) => item?.geo_layer?._id);
          if (layer_list.length > 0) {
            const params = {
              layer_list,
              geo_project_id: geo_project._id,
              folder_id: folder_id,
            };
            this.props.get_detail_many_layer(params);
          }
        }
      }

      if (sidebar_right_status === true) {
        this.props.set_value_properties({
          key: "sidebar_right_status",
          value: true,
        });
      }

      if (layer_list_load_first.length > 0) {
        const params = {
          layer_list: layer_list_load_first,
          geo_project_id: geo_project._id,
          folder_id: "",
        };
        this.props.get_detail_many_layer(params);
      }
    }
  }

  activate_socket_by_project = (geo_project_id) => {
    this.socket.on(geo_project_id, (data) => {
      this.props.realtime_update(data);
    });
  };

  activate_socket_by_id_layer = (layer_id) => {
    this.socket.on(layer_id, (data) => {
      this.props.realtime_update(data);
    });
  };

  disconnect_socket = () => {
    this.socket.disconnect();
    this.socket.close();
  };

  componentWillUnmount() {
    this.props.clearLayer();
    this.props.clearProject();
    this.props.finishGenangan();
    this.disconnect_socket();
  }

  toggle_login = () => {
    const { modal_login } = this.props.auth;
    this.props.set_value_user("modal_login", !modal_login);
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const geo_layer_link =
      this?.props?.match?.params?.geo_layer_link !== "no_layer"
        ? this?.props?.match?.params?.geo_layer_link
        : "";
    const mode = this?.props?.match?.params?.mode;

    //global props
    const { isAuthenticated } = this.props.auth;
    const { loadingProcess, itemLoading, chatStatus, realtimeStatus } =
      this.props.layer;
    const { geo_project, loadingDetail } = this.props.project;
    const { user } = this.props.auth;

    let userCurrentStatus = "viewer";
    if (geo_project._id) {
      userCurrentStatus = getStatusMember(geo_project.groups, user._id);
    }

    const geo_project_link = this?.props?.match?.params?.geo_project_link;
    let content;

    /*
    Kemungkinan-kemungkinan state:

    //1. Loading
    //2. white list
    //3. data publik --> hanya berlaku di viewer, tidak dieditor
    //4. lolos authorisasi
    //5. lainnya belum login
    //6. lainnya sudah login    
    */

    let api_status = "";
    if (loadingDetail) {
      //1. Loading
      api_status = "loading";
      content = (
        <main
          id="main_container"
          className="main_container"
          style={{ textAlign: "center" }}
        >
          <section>
            <img
              src={icon_404}
              alt="Viewer"
              style={{
                width: "200px",
                maxWidth: "70%",
                marginBottom: "20px",
              }}
            />
          </section>
          <section
            style={{
              color: "#52606e",
              marginTop: "10px",
              width: "500px",
              maxWidth: "100%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <p className="text_big">Loading...</p>
          </section>
        </main>
      );
    } else {
      if (
        //3. lolos authorisasi
        ["creator", "admin", "contributor"].includes(userCurrentStatus) ||
        (geo_project?.user?._id &&
          user?._id &&
          geo_project?.user?._id === user?._id)
      ) {
        //3. data publik --> hanya berlaku di viewer, tidak dieditor
        //4. lolos authorisasi
        api_status = "lolos lisensi, lolos autorisasi";
        content = (
          <EditorMapMain
            geo_project_link={geo_project_link}
            geo_layer_link={geo_layer_link}
            mode={mode}
          />
        );
      } else {
        if (!isAuthenticated) {
          //5. lainnya belum login
          api_status = "belum login";
          content = (
            <main
              id="main_container"
              className="main_container"
              style={{ textAlign: "center" }}
            >
              <section>
                <img
                  src={icon_404}
                  alt="Viewer"
                  style={{
                    width: "200px",
                    maxWidth: "70%",
                    marginBottom: "20px",
                  }}
                />
              </section>
              <section className="text_medium">
                {dict["Project with link"][language]} <b>{geo_project_link}</b>{" "}
                {dict["not found"][language]}.
              </section>
              {!isAuthenticated && (
                <section>
                  <br />
                  <p className="text_medium">
                    {
                      dict[
                        "Try logging in again if this data belongs to you or your collaboration group"
                      ][language]
                    }
                  </p>
                  <button className="button_big" onClick={this.toggle_login}>
                    Log in
                  </button>
                </section>
              )}
            </main>
          );
        } else {
          //6. lainnya sudah login
          api_status = "sudah login data private";
          content = (
            <main
              id="main_container"
              className="main_container"
              style={{ textAlign: "center" }}
            >
              <section>
                <img
                  src={icon_404}
                  alt="Viewer"
                  style={{
                    width: "200px",
                    maxWidth: "70%",
                    marginBottom: "20px",
                  }}
                />
              </section>
              <section style={{ fontSize: "15px" }}>
                {
                  dict[
                    "This is a private page, you are not a member or you are only"
                  ][language]
                }
                <b> {dict["viewer"][language]}</b>.
              </section>
            </main>
          );
        }
      }
    }

    geo_project.api_status = api_status;

    let textLoading = "Saving";
    switch (itemLoading) {
      case "createLayer":
        textLoading = dict["Creating layer"][language];
        break;
      case "importLayer":
        textLoading = dict["Importing layer"][language];
        break;
      case "uploadLayer":
        textLoading = dict["Importing layer"][language];
        break;
      case "edit_general":
        textLoading = dict["Saving changes"][language];
        break;
      case "editAllFields":
        textLoading = dict["Saving changes"][language];
        break;
      case "editLayerForm":
        textLoading = dict["Saving changes"][language];
        break;
      case "pushFeature":
        textLoading = dict["Adding feature"][language];
        break;
      case "push_project_attach":
        textLoading = dict["Attaching layer"][language];
        break;
      case "delete_project_attach":
        textLoading = dict["Deleting attached layer"][language];
        break;
      case "edit_geometry":
        textLoading = dict["Saving geometry changes"][language];
        break;
      case "edit_properties":
        textLoading = dict["Saving attribute value"][language];
        break;
      case "delete_feature":
        textLoading = dict["Deleting"][language];
        break;
      case "hide_feature":
        textLoading = dict["Hiding feature"][language];
        break;
      case "push_field":
        textLoading = dict["Adding column"][language];
        break;
      case "editField":
        textLoading = dict["Saving column"][language];
        break;
      case "deleteField":
        textLoading = dict["Deleting column"][language];
        break;
      case "delete_layer":
        textLoading = dict["Deleting layer"][language];
        break;
      case "get_detail":
        textLoading = dict["Getting GIS data"][language];
        break;
      case "genangan":
        textLoading = "Menjalankan algoritma genangan";
        break;
      case "deleteFieldForm":
        textLoading = "Menghapus kolom tabel";
        break;
      case "move_layers_into_any_folder":
        textLoading = dict["Moving layers"][language];
        break;
      case "delete_layers_bulk":
        textLoading = dict["Deleting layers"][language];
        break;
      default:
        textLoading = itemLoading;
        break;
    }
    return (
      <div>
        {!!textLoading && !chatStatus && !realtimeStatus && loadingProcess && (
          <div
            style={{
              zIndex: "20",
              backgroundColor: "#0ca5eb",
              color: "#fff",
              position: "fixed",
              top: "0px",
              right: "0px",
              width: "100vw",
              textAlign: "center",
              paddingTop: "5px",
              paddingBottom: "5px",
              boxShadow: "0px 0px 7px 7px rgba(0, 0, 0, 0.09)",
              fontSize: "15px",
            }}
          >
            {textLoading}
          </div>
        )}
        {content}
        <div id="main_container"></div>
        <div id="sidebar_home"></div>
        <div id="search_bar"></div>
        {/* jangan dihapus ya wan, ini aku butuh biar sidebar ga error */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  payment: state.payment,
  project: state.project,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  getUserPaymentList,
  getProjectDetailByLink,
  clearLayer,
  getGroups,
  getGroupsProject,
  finishGenangan,
  clearProject,
  set_value_user,
  getDetailLayerById,
  set_value,
  realtime_update,
  set_value_properties,
  get_detail_many_layer,
})(ParentEditor);
