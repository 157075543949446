/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_map } from "../../App/actions/mapActions";

/*PICTURE ASSET*/
import logoStreet from "../../Assets/img/icon_map_street.png";
import logoSatellite from "../../Assets/img/icon_map_satellite.png";
import logoLight from "../../Assets/img/icon_map_light.png";
import logoDark from "../../Assets/img/icon_map_dark.png";

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/
const array_basemap = [
  //https://api.maptiler.com/maps/streets/style.json?key=CVYOeJckfxqURN5wjKEB
  //mapbox://styles/mapbox/streets-v12
  {
    name: "street",
    api: "https://api.maptiler.com/maps/streets/style.json?key=CVYOeJckfxqURN5wjKEB",
  },
  {
    name: "light",
    api: "mapbox://styles/mapbox/light-v10",
  },
  {
    name: "dark",
    api: "mapbox://styles/mapbox/dark-v10",
  },
  {
    name: "satellite",
    api: "mapbox://styles/mapbox/satellite-streets-v11",
  },
];

class BASEMAP_MODE extends Component {
  state = {
    modal_basemap: false,
  };

  toggle_basemap = () => {
    this.setState({ modal_basemap: !this.state.modal_basemap });
  };

  on_set_basemap = (value) => {
    this.setState({ modal_basemap: false });
    this.props.set_value_map({
      key: "basemap_mode",
      value: value,
    });
    const basemap_api = array_basemap.find((item) => item.name === value)?.api;
    this.props.set_value_map({
      key: "basemap_api",
      value: basemap_api,
    });
  };

  render() {
    //local storage

    //local state
    const { modal_basemap } = this.state;

    //global props
    const { basemap_mode } = this.props.map;

    //content
    let logo_map;
    switch (basemap_mode) {
      case "street":
        logo_map = logoStreet;
        break;
      case "light":
        logo_map = logoLight;
        break;
      case "dark":
        logo_map = logoDark;
        break;
      default:
        logo_map = logoSatellite;
    }

    const content = (
      <div className="modal_left_side">
        <table
          style={{ textAlign: "center", width: 150, padding: 5, fontSize: 11 }}
        >
          <tbody>
            <tr>
              <td style={{ width: "50%" }}>
                <div>
                  <img
                    className={"img-rounded"}
                    src={logoStreet}
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                    alt={"Street mode"}
                    onClick={this.on_set_basemap.bind(this, "street")}
                  />
                </div>
                <div>Street</div>
              </td>
              <td>
                <div>
                  <img
                    className={"img-rounded"}
                    src={logoSatellite}
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                    alt={"Satellite mode"}
                    onClick={this.on_set_basemap.bind(this, "satellite")}
                  />
                </div>
                <div>
                  <span>Satellite</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <img
                    className={"img-rounded"}
                    src={logoDark}
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                    alt={"Dark mode"}
                    onClick={this.on_set_basemap.bind(this, "dark")}
                  />
                </div>
                <div>Dark</div>
              </td>
              <td>
                <div>
                  <img
                    className={"img-rounded"}
                    src={logoLight}
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                    alt={"Light mode"}
                    onClick={this.on_set_basemap.bind(this, "light")}
                  />
                </div>
                <div>Light</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );

    return (
      <main>
        <section
          style={{
            backgroundImage: `url(${logo_map})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            width: "25px",
            height: "25px",
          }}
          onClick={this.toggle_basemap}
          title="Change Style Map"
        ></section>
        {modal_basemap && <div>{content}</div>}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
});

export default connect(mapStateToProps, { set_value_map })(BASEMAP_MODE);
