//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

//Personal Component
import PaymentTable from "../Components/payment/PaymentTable";
import UploadPhotoProfile from "../Components/user/UploadPhotoProfile";
import Modal from "../Components/common_modal/Modal";
import SpinnerSimple from "../Components/common_spinner/SpinnerSimple";

//Redux function
import {
  logoutUser,
  getMapAccess,
  initApi,
  set_modal_quota_access,
} from "../App/actions/authActions";
import { clearProjectList } from "../App/actions/projectActions";
import { clearFormList } from "../App/actions/layerNewActions";
import { getUserPaymentList } from "../App/actions/paymentActions";
import { getGroups } from "../App/actions/groupActions";

//Picture Asset
import pic_static from "../Assets/svg/profile_pic_comment.svg";

//General Function
import * as Validation from "../App/validation";
import dict from "../Data/dict.json";
import domain_list from "../Data/domain_list";

const Paragrah = ({ title, value }) => {
  return (
    <div className="detail_profile_paragraf">
      <p>{title}</p>
      {value ? (
        <span
          style={{
            maxWidth: 200,
            wordBreak: "break-word",
          }}
        >
          {value}
        </span>
      ) : (
        <span>-</span>
      )}
    </div>
  );
};

class UserDashboard extends Component {
  state = {
    modal: false,
    modal_delete: false,
    modal_edit: false,
    modal_add_member: false,
    modal_device: false,
    modalBuyNow: false,
    modalTopUp: false,
    modalShowPricing: false,
    modal_token: false,
  };

  componentDidMount() {
    this.props.getUserPaymentList();
    this.props.getGroups();
    this.props.getMapAccess();
    if (this?.props?.match?.params?.status === "pricing") {
      this.setState({ modalShowPricing: true });
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
  }

  componentDidUpdate(prevProps) {
    //close modal
    const { groups } = this.props.auth;
    const groups_prev = prevProps.auth.groups;
    if (!Validation.isEqual(groups, groups_prev)) {
      this.setState({ modal: false, modal_edit: false });
    } else {
      return null;
    }
  }

  initApi = () => {
    const body = {};
    this.props.initApi(body);
  };

  toggle_token = () => {
    this.setState({ modal_token: !this.state.modal_token });
  };

  onLogoutClick() {
    this.props.logoutUser();
    this.props.clearProjectList();
    this.props.clearFormList();
  }

  togglePricing = () => {
    this.setState(
      {
        modalShowPricing: !this.state.modalShowPricing,
      },
      () => {
        if (this.state.modalShowPricing) {
          window.scrollTo({
            left: 0,
            top: document.body.scrollHeight,
            behavior: "smooth",
          });
        }
      }
    );
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  toggleDevice = () => {
    this.setState({
      modal_device: !this.state.modal_device,
    });
  };

  toggleBuyNow = () => {
    this.setState({ modalBuyNow: !this.state.modalBuyNow });
  };

  toggleTopUp = () => {
    this.setState({ modalTopUp: !this.state.modalTopUp });
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state

    //global props
    const { loadingDetail, groups_org, map_access, max_quota, domain } =
      this.props.auth;
    const {
      license,
      // payment_list
    } = this.props.payment;
    const { modalShowPricing, modal_token } = this.state;
    const { user, quota_access, modal_quota_access } = this.props.auth;
    const { admin_information_list } = this.props.properties;

    //content

    let key = "";
    if (domain === "geo.mapid.io") {
      key = "show_insight_prod";
    } else if (domain === "geo-beta.mapid.io") {
      key = "show_insight_beta";
    } else {
      key = "show_insight_alpha";
    }

    const show_insight_status = admin_information_list.find(
      (item) => item.key === key
    )?.value_string;

    const status_domain = domain_list?.[domain]?.status;
    let delta_access = max_quota - map_access;
    if (delta_access < 0) {
      delta_access = 0;
    }

    const api_keys = user?.api_keys ? user.api_keys : [];

    const url_pic =
      user && user.profile_picture && user.profile_picture.url
        ? user.profile_picture.url
        : pic_static;
    let itemContent;
    let word_expired;
    // let show_access_remaining = true;
    let team_license = {};
    let id_exp_button = "blue";
    if (!Validation.isEmpty(license)) {
      const { delta_day, date_finish } = license;
      if (delta_day < 0) {
        word_expired = `${dict["Has expired"][language]}`;
        id_exp_button = "red";
      } else if (delta_day === 0) {
        word_expired = `${dict["Expired today"][language]}`;
        id_exp_button = "red";
      } else if (delta_day > 30) {
        word_expired = `${dict["Exp at"][language]} ${Validation.formatDate(
          date_finish
        )}`;
        id_exp_button = "blue";
        // show_access_remaining = false;
      } else {
        word_expired = `${dict["Exp in"][language]} ${delta_day} ${dict["days"][language]}`;
        id_exp_button = "blue";
        // show_access_remaining = false;
      }
    } else {
      word_expired = dict["Upgrade"][language];
    }

    // const payment_list_bill = payment_list.filter(
    //   (e) => e.payment_type === "admin_bill"
    // );

    // const bill_total = payment_list_bill
    //   .map((payment) => {
    //     const total_price = payment.blogs_snapshot.map((blog) => {
    //       return blog.market_status.price;
    //     });
    //     return total_price;
    //   })
    //   .flat();

    // const total_price = bill_total.reduce((a, b) => a + b, 0);

    if (loadingDetail && Validation.isEmpty(user)) {
      itemContent = (
        <SpinnerSimple
          width={200}
          unik="loading_item_list"
          marginTop="0px"
          center={true}
          border={0.1}
        />
      );
    } else {
      const payment_type = license?.payment_type;
      let license_name = "FREE";
      if (Validation.isEmpty(license) && !Validation.isEmpty(groups_org)) {
        license_name = "TEAM";
        team_license = groups_org[0];
      } else {
        switch (payment_type) {
          case "license_0":
            license_name = dict["FREE"][language];
            break;
          case "license_1":
            license_name = `${dict["STUDENT"][language]}*`;
            break;
          case "license_2":
            license_name = `${dict["PERSONAL"][language]}*`;
            break;
          case "license_3":
            license_name = "ENTERPRISE";
            break;
          default:
            license_name = dict["FREE"][language];
            break;
        }
      }

      let content_array = [
        {
          key: "license",
          bubble: (
            <div className="button_very_small" id={id_exp_button}>
              {word_expired}
            </div>
          ),
          name: license_name,
          footer:
            !Validation.isEmpty(groups_org) && Validation.isEmpty(license)
              ? `${dict["Supported by"][language]} ${team_license?.members[0]?.user?.full_name}`
              : dict["Geo License"][language],
        },
        {
          key: "payment",
          bubble: (
            <Link to="/purchase_list" className="button_very_small" id="blue">
              {dict["History"][language]}
            </Link>
          ),
          name: dict["PAYMENT"][language],
          footer: dict["Your payment history"][language],
        },
        // {
        //   bubble: (
        //     <Link to="/income_list" className="button_very_small" id="blue">
        //       {dict["Explore"][language]}
        //     </Link>
        //   ),
        //   name: `Rp ${new Intl.NumberFormat("id-ID", {
        //     style: "decimal",
        //   }).format(total_price)}`,
        //   footer: dict["Data exchange income"][language],
        // },
        {
          key: "quota",
          bubble: (
            <button
              onClick={() =>
                this.props.set_modal_quota_access(!modal_quota_access)
              }
              className="button_very_small"
              id="blue"
            >
              Beli kuota
            </button>
          ),
          name: `${quota_access}`,
          footer: dict["Purchase access add-ons"][language],
        },
        // {
        //   bubble: (
        //     <div
        //       onClick={this.toggleDevice}
        //       className="button_very_small"
        //       id="blue"
        //     >
        //       {dict["Manage"][language]}
        //     </div>
        //   ),
        //   name: `${device_amount} ${dict["DEVICES"][language]}`,
        //   footer: dict["Web Notification"][language],
        // },
      ];

      if (show_insight_status !== "show") {
        content_array = content_array.filter((item) => item.key !== "quota");
      }

      const content_render = content_array.map((e, idx) => {
        return (
          <main
            key={idx}
            style={{
              backgroundColor: "#fff",
              color: "#556573ff",
              borderRadius: "10px",
              width: "160px",
              maxWidth: "90%",
              padding: "10px",
              display: "inline-block",
              marginRight: "10px",
              marginBottom: "10px",
            }}
          >
            <section style={{ textAlign: "right" }}>{e.bubble}</section>
            <section className="text_bold">{e.name}</section>
            <section className="text_inferior">{e.footer}</section>
          </main>
        );
      });
      const array_api = [
        // {
        //   name: dict["Monthly free map access"][language],
        //   number: delta_access,
        //   sub_name: `${delta_access}/${max_quota}`,
        //   percent_status: true,
        //   percent_number: percent_quota,
        //   show_status: show_access_remaining,
        //   button_status: false,
        //   button_text: "",
        //   button_link: "",
        // },
        // {
        //   name: dict["SINI Access"][language],
        //   number: user.sini_access_total ? user.sini_access_total : 0,
        //   sub_name: "SINI",
        //   percent_status: false,
        //   percent_number: 100,
        //   show_status: true,
        //   button_status: true,
        //   button_text: dict["Detail"][language],
        //   button_link: "/sini_access",
        // },
        // {
        //   name: "Map view API",
        //   number: user?.map_access_total ? user?.map_access_total : 0,
        //   sub_name: "MAP VIEW",
        //   percent_status: false,
        //   percent_number: 100,
        //   show_status: true,
        //   button_status: true,
        //   button_text: dict["Detail"][language],
        //   button_link: "/map_access",
        // },
        // {
        //   name: "Routing API",
        //   number: user?.routing_access_total ? user?.routing_access_total : 0,
        //   sub_name: "Routing",
        //   percent_status: false,
        //   percent_number: 100,
        //   show_status: true,
        //   button_status: true,
        //   button_text: dict["Detail"][language],
        //   button_link: "/routing_access",
        // },
        {
          name: "API Key Token",
          number: "",
          sub_name: "Secret token",
          percent_status: false,
          percent_number: 100,
          show_status: true,
          button_status: false,
          toggle_status: true,
          toggle: this.toggle_token,
          button_text: dict["Detail"][language],
        },
      ];

      let status_api = array_api.map((e) => {
        const {
          name,
          number,
          sub_name,
          percent_status,
          percent_number,
          button_status,
          button_text,
          button_link,
          toggle_status,
          toggle,
        } = e;
        return (
          <main
            key={name}
            style={{
              display: "inline-block",
              backgroundColor: "#fff",
              color: "#556573ff",
              borderRadius: "10px",
              width: "160px",
              maxWidth: "90%",
              padding: "10px",
              marginBottom: "10px",
              marginRight: "10px",
              minHeight: "75px",
              verticalAlign: "top",
            }}
          >
            <p className="text_inferior">{name}</p>
            <button className="flex_container" style={{ width: "100%" }}>
              <section className="flex_child" style={{ textAlign: "left" }}>
                <section
                  style={{
                    margin: "auto",
                    fontSize: "27px",
                    fontWeight: "500",
                  }}
                >
                  {number}
                </section>
              </section>
              <section className="flex_child" style={{ textAlign: "right" }}>
                <p className="text_inferior">{sub_name}</p>
              </section>
            </button>
            {button_status && (
              <Link to={button_link} className="button_very_small" id="green">
                {button_text}
              </Link>
            )}
            {toggle_status && (
              <button
                to={button_link}
                className="button_very_small"
                id="green"
                onClick={toggle}
              >
                {button_text}
              </button>
            )}
            {percent_status && (
              <div
                style={{
                  height: "10px",
                  backgroundColor: "#dedede",
                  borderRadius: "2rem",
                  padding: "3px",
                  margin: "auto",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: `${percent_number}%`,
                    backgroundColor: "#7cc735ff",
                    borderRadius: "2rem",
                  }}
                />
              </div>
            )}
          </main>
        );
      });
      itemContent = (
        <main>
          <section>
            <div className="detail_profile_wrapper">
              <div
                style={{
                  marginRight: "2rem",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    height: "77px",
                    width: "77px",
                    display: "block",
                    border: "5px solid #dedede",
                    borderRadius: "13px",
                    backgroundImage: `url(${url_pic})`,
                    backgroundSize: "cover",
                  }}
                />
                <UploadPhotoProfile />
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <h1
                    style={{
                      fontSize: "23px",
                      fontWeight: "470",
                      lineHeight: "110%",
                      marginRight: "1rem",
                    }}
                  >
                    {user.full_name ? user.full_name : user.name}
                  </h1>
                  <Link to={`/editprofile`} className="edit_button_profile">
                    {dict["Edit profile"][language]}
                  </Link>
                  <div
                    onClick={this.onLogoutClick.bind(this)}
                    className="logout_button_profile"
                  >
                    {dict["Sign out"][language]}
                  </div>
                </div>
                <p style={{ textAlign: "justify", padding: "0 0.5rem" }}>
                  {user.bio}
                </p>
                <div className="detail_profil_value">
                  <Paragrah
                    title={dict["Username"][language]}
                    value={user.name}
                  />
                  <Paragrah title="E-mail" value={user.email} />
                  <Paragrah
                    title={dict["phone number"][language]}
                    value={user.phone_number}
                  />
                  <Paragrah
                    title={dict["Birthday"][language]}
                    value={user.birthday}
                  />
                  <Paragrah
                    title={dict["industry"][language]}
                    value={user.industry}
                  />
                </div>
              </div>
            </div>
          </section>
          <br />
          {status_domain !== "premium" && (
            <main>
              {status_api}
              <br />
              {content_render}
              <section>
                <br />
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <div onClick={this.togglePricing} className="button_standard">
                    {dict["Open Pricing"][language]}
                  </div>
                </div>
                {((Validation.isEmpty(license) &&
                  Validation.isEmpty(groups_org)) ||
                  license?.delta_unix <= 0 ||
                  modalShowPricing) && <PaymentTable />}
              </section>
            </main>
          )}
        </main>
      );
    }

    const modal_token_content = modal_token && (
      <Modal
        modalSize="small"
        id="modal_token"
        isOpen={modal_token}
        onClose={this.toggle_token}
      >
        <div className="box-body">
          <h1 className="text_bold">API key token</h1>
          <br />
          {api_keys.length > 0 ? (
            <section>
              <p className="text_inferior">
                This is your API key, use it to unlock API access
              </p>
              <p className="text_bold">{api_keys[0]?.key}</p>
            </section>
          ) : (
            <section>
              <p className="text_inferior">You don't have an API key yet</p>
              <button className="button_standard" onClick={this.initApi}>
                Create a new API key
              </button>
            </section>
          )}
        </div>
      </Modal>
    );

    return (
      <div
        id="main_container"
        style={{ overflowX: "hidden", marginBottom: "20px" }}
      >
        {itemContent}
        {modal_token_content}
        <Helmet>
          <title>{dict["Profile"][language]}</title>
          <meta name="description" content={dict["Profile"][language]} />
        </Helmet>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  payment: state.payment,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  logoutUser,
  getUserPaymentList,
  getGroups,
  getMapAccess,
  clearProjectList,
  initApi,
  clearFormList,
  set_modal_quota_access,
})(UserDashboard);
