import React, { Component } from "react";
import { connect } from "react-redux";
import { addGroup } from "../../App/actions/groupActions";
import {
  invitations_group,
  invitations,
} from "../../App/actions/layerNewActions";
import formatDate from "../../App/validation/format-date";
import pic_static from "../../Assets/svg/profile_pic_comment.svg";

// pending
// pending_view
// viewer

class ModalAssignSurveyor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: "",
      new_group: false,
      group_id: "",
      massage: "",
      type_group: "",
      name: "",
      email: "",
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const domain = this.props.auth.domain;
    const { email, group_id } = this.state;
    const { layer_id } = this.props.layer;
    const feature_key = this.props.modal_data.key;

    const origin =
      domain === "localhost"
        ? "geo.mapid.io"
        : domain
        ? domain
        : "geo.mapid.io";

    let body = {
      origin,
      email,
      group_id,
      layer_id,
      feature_key,
    };

    this.props.invitations(body);
    this.props.toggleAssign();
    this.setState({
      email: "",
    });
  };

  onCreateGroup = (e) => {
    e.preventDefault();
    const { name } = this.state;
    const body = {
      name,
    };
    this.props.addGroup(body);
    this.setState({
      new_group: false,
      name: "",
    });
  };

  handleGroup = () => {
    this.setState({
      show: "group",
    });
  };

  handleInvite = () => {
    this.setState({
      show: "email",
    });
  };

  handleSelectGroup = (id, type) => {
    this.setState({
      type_group: type,
      show: "select invite",
      group_id: id,
    });
  };

  handleSelectAssign = async (data) => {
    const { key } = this.props.modal_data;
    const { layer_id } = this.props.layer;
    const domain = this.props.auth.domain;

    const fields = this.props.layer.geo_layer_list.find(
      (d) => d.geo_layer._id === layer_id
    ).geo_layer.fields;

    const fields_filter = fields.filter((d) => d.isHighlight === true);

    // const fields_danamas = fields.filter(
    //   (d) =>
    //     d.name === "Nama Borrower" ||
    //     d.name === "Kode Loan" ||
    //     d.name === "Jenis Objek" ||
    //     d.name === "Alamat" ||
    //     d.name === "No. Sertifikat" ||
    //     d.name === "Luas Tanah"
    // );

    // const fields_origin =
    //   domain === "localhost"
    //     ? fields_filter
    //     : domain === "danamas.mapid.io"
    //     ? fields_danamas
    //     : fields_filter;

    const origin =
      domain === "localhost"
        ? "geo.mapid.io"
        : domain
        ? domain
        : "geo.mapid.io";

    let obj = {};

    for (let index = 0; index < fields_filter.length; index++) {
      const element = fields_filter[index];
      obj[`${element.name}`] = this.props.modal_data[element.key];
    }

    try {
      const body = {
        origin: origin,
        layer_id: layer_id,
        key: key,
        user_id_surveyor: data.user._id,
        email_surveyor: data.user.email,
        name_surveyor: data.user.name,
        table_data: obj,
        data_user: data,
      };

      this.props.toggleAssign();
      this.props.invitations_group(body);
    } catch (error) {}
  };

  render() {
    //local storage

    //local state
    const {
      show,
      // name,
      email,
      group_id,
      massage,
      // new_group
    } = this.state;

    //global props
    const { groups } = this.props.auth;
    const { geo_project } = this.props.project;
    const { formStatus, user } = this.props.modal_data;
    const { layer_id, geo_layer_list } = this.props.layer;
    const { error_object } = this.props.properties;

    //content

    console.log("error_object=", error_object);

    const group = groups?.find((d) => d?._id === group_id);
    const members = group?.members;

    const key_fields = geo_layer_list
      ?.find((d) => d?.geo_layer?._id === layer_id)
      ?.geo_layer?.fields?.find((d) => d.name === "Nama PIC")?.key;
    const user_fields = this.props?.modal_data?.[key_fields];

    const ButtonBack = ({ type }) => (
      <button
        className="button"
        id="red"
        onClick={() => {
          this.setState({
            show: type,
          });
        }}
      >
        Kembali
      </button>
    );

    return (
      <section className="modal_assign">
        {show === "select invite" ? (
          <div className="modal_assign_row">
            <h1>Pilih Cara Invite</h1>
            <section className="modal_assign_button">
              <figure className="card_group">
                <div>
                  <h3>Dari Grup</h3>
                  <p>Invite user yang ada di dalam group</p>
                </div>
                <button className="button" onClick={() => this.handleGroup()}>
                  Select
                </button>
              </figure>
              <figure className="card_group">
                <div>
                  <h3>Undang Melalui Email</h3>
                  <p>Invite user yang belum terdaftar di GEO MAPID</p>
                </div>
                <button className="button" onClick={() => this.handleInvite()}>
                  Select
                </button>
              </figure>
            </section>
            <div className="assign_row_button">
              <ButtonBack type="" />
            </div>
          </div>
        ) : show === "group" ? (
          <div className="modal_assign_row">
            <h1>Daftar Anggota Grup</h1>
            <div className="assign_grup_row">
              {members?.map((d, index) => (
                <figure className="card_member_assign" key={index}>
                  <div className="card_member_img">
                    <img
                      src={
                        d?.user?.profile_picture?.url
                          ? d?.user?.profile_picture?.url
                          : pic_static
                      }
                      alt="profile member grup"
                    />
                    {/* <img src={pic_static} alt="profile member grup" /> */}
                  </div>
                  <p>{d?.user?.name ? d?.user?.name : "belum terdaftar"}</p>
                  <p>{d?.user?.email ? d?.user?.email : d?.user?.full_name}</p>
                  <p>{formatDate(d?.join_at)}</p>
                  <p>{d?.status}</p>
                  {d.user?.name === user_fields ? (
                    <button
                      className="button"
                      id="green"
                      onClick={() => this.handleSelectAssign(d)}
                    >
                      Resend
                    </button>
                  ) : d?.status === "pending" ||
                    d?.status === "pending_view" ? (
                    <button className="button" id="grey">
                      Pending
                    </button>
                  ) : (
                    <button
                      className="button"
                      onClick={() => this.handleSelectAssign(d)}
                    >
                      Assign
                    </button>
                  )}
                </figure>
              ))}
            </div>
            <div className="assign_row_button">
              <ButtonBack type="select invite" />
            </div>
          </div>
        ) : show === "email" ? (
          <section className="modal_invite_row">
            <h1>Undang Melalui Email</h1>
            <div className="register_group">
              <form onSubmit={this.onSubmit}>
                <label>Email</label>
                <div type="input_new">
                  <input
                    className="input_new"
                    type="email"
                    name="email"
                    value={email}
                    placeholder="masukan Email"
                    onChange={this.onChange}
                  />
                </div>
                <p className="massages_modal_invite">{massage}</p>
                <button className="button">Kirim invitasi</button>
              </form>
            </div>
            <div className="assign_row_button">
              <ButtonBack type="select invite" />
            </div>
          </section>
        ) : (
          <div className="modal_assign_row">
            <h1>Pilih Group</h1>
            <section className="box-body" style={{ width: "95%" }}>
              {geo_project.groups.length !== 0 ? (
                <div className="list_group">
                  {geo_project.groups.map((d, index) => (
                    <figure className="card_group" key={index}>
                      <div>
                        <h3>{d.name}</h3>
                        <p>{d.members.length} members</p>
                      </div>
                      <button
                        className="button"
                        onClick={() => this.handleSelectGroup(d._id, "old")}
                      >
                        open
                      </button>
                    </figure>
                  ))}
                </div>
              ) : (
                <main>
                  <p className="text_bold text_center">
                    Belum ada group di dalam proyek ini, silakan pilih group
                    terlebih dahulu pada dashboard list project
                  </p>
                  {/* <div className="list_group">
                    {groups.map((d, index) => (
                      <figure className="card_group" key={index}>
                        <div>
                          <h3>{d.name}</h3>
                          <p>{d.members.length} members</p>
                        </div>
                        <button
                          className="button"
                          onClick={() => this.handleSelectGroup(d._id, "new")}
                        >
                          Pilih
                        </button>
                      </figure>
                    ))}
                    {new_group ? (
                      <figure className="card_group">
                        <button
                          className="buton_close"
                          onClick={() => this.setState({ new_group: false })}
                        >
                          X
                        </button>
                        <form onSubmit={this.onCreateGroup}>
                          <input
                            type="text"
                            className="input_new"
                            name="name"
                            value={name}
                            placeholder="masukan nama group"
                            onChange={this.onChange}
                          />
                          <button className="button">Simpan</button>
                        </form>
                      </figure>
                    ) : (
                      <figure
                        className="card_group_add"
                        onClick={() => this.setState({ new_group: true })}
                      >
                        <button style={{ fontSize: "2rem" }}>+</button>
                      </figure>
                    )}
                  </div> */}
                </main>
              )}
            </section>
          </div>
        )}
        <p className="info_status">
          tabel yang dikirim ke email, yang sudah di Hightlight Attribute ||{" "}
          {formStatus?.status === "assign" && (
            <span>
              isian ini sudah di assign surveyor form{" "}
              {user?.fullname && `${user?.fullname} ||`}
              {user?.username && `${user?.username} ||`}
              {user?.email && `${user?.email} `}
            </span>
          )}
        </p>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  auth: state.auth,
  project: state.project,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  invitations_group,
  invitations,
  addGroup,
})(ModalAssignSurveyor);
