//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import PaidFormDashboard from "../Components/form_paid/PaidFormDashboard";

//Redux function
import { get_detail_by_link_form } from "../App/actions/layerActions";

//Picture Asset

//General Function

class ParentPaidForm extends Component {
  componentDidMount() {
    const { geo_layer_link } = this.props.match.params;
    const body = {
      geo_layer_link,
    };

    this.props.get_detail_by_link_form(body);
  }

  render() {
    return <PaidFormDashboard />;
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { get_detail_by_link_form })(
  ParentPaidForm
);
