//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function
import {
  set_poi_select,
  set_sini_all_active,
} from "../../App/actions/siniActions";
import DataPoi from "../../Data/Poi_List_Type_1.json";
import DataAllPoi from "../../Data/Poi_List_Type_2.json";
import dict from "../../Data/dict.json";

//Picture Asset
import icon_download from "../../Assets/svg/icon_download.svg";
import icon_view_blue from "../../Assets/svg/icon_view_blue.svg";
import icon_view_grey from "../../Assets/svg/icon_view_grey.svg";
import icon_view from "../../Assets/svg/icon_view.svg";
import icon_view_white from "../../Assets/svg/icon_view_white.svg";
import { IconOpen } from "./icon";

//General Function

class PoiDistribution extends Component {
  state = {
    view_active: true,
    poi_sini: false,
    hide: false,
  };

  // componentDidMount = () => {
  //   this.data_poi();
  // };

  data_poi = () => {
    const { sini_list, request_id } = this.props.layer;

    let poi = sini_list?.filter((e) => e.request_id === request_id)?.[0]?.poi;

    //eslint-disable-next-line
    let data_category = DataAllPoi?.map((data) => {
      const all_poi = poi?.features?.filter(
        (d) => d?.properties?.TIPE_1 === data?.name
      );

      const sub_poi = data?.sub_array?.map((sub) => {
        const data_poi = poi?.features?.filter(
          (d) => d?.properties?.TIPE_2 === sub.name
        );
        return {
          ...sub,
          data_poi,
        };
      });
      const column_ata = {
        ...data,
        all_poi,
        sub_array: sub_poi,
      };

      return column_ata;
    });
  };

  sini_poi = (key, value) => {
    const body = { key, value };
    this.setState({
      poi_sini: true,
    });
    this.props.set_poi_select(body);
  };

  sini_poi_active = (key, value) => {
    const body = { key, value };
    this.setState({
      poi_sini: false,
    });
    this.props.set_poi_select(body);
  };

  sini_all_active = () => {
    this.props.set_sini_all_active("active");
  };

  render() {
    // const { poi } = this.props;
    const { sini_list, request_id } = this.props.layer;
    const { hide } = this.state;
    const language = localStorage?.language ? localStorage?.language : "ina";

    let poi = sini_list?.filter((e) => e.request_id === request_id)?.[0]?.poi;

    const features = poi?.features || [];
    const tipe_1 = [...new Set(features.map((f) => f.properties.TIPE_1))].map(
      (e) => {
        const length = features.filter(
          (f) => f.properties.TIPE_1 === e
        )?.length;
        return { name: e, length };
      }
    );

    // const tipe_2 = [...new Set(features.map((f) => f.properties.TIPE_2))].map(
    //   (e) => {
    //     const length = features.filter(
    //       (f) => f.properties.TIPE_2 === e
    //     )?.length;
    //     return { name: e, length };
    //   }
    // );

    const content = (
      <section className="sini_card">
        <div className="sini_card_top">
          <div style={{ zIndex: "5" }}>
            <h2>{dict?.["Points of Interest"]?.[language]}</h2>
            <img
              src={
                this?.props?.layer?.poi_select?.length === 0
                  ? icon_view_grey
                  : icon_view_blue
              }
              title={
                this?.props?.layer?.poi_select?.length === 0
                  ? dict?.["Hide All"]?.[language]
                  : dict?.["View All"]?.[language]
              }
              className={hide ? "hide_icon" : "show_icon"}
              style={{ marginRight: "5px", cursor: "pointer" }}
              alt="Add "
              width={25}
              onClick={() => this.sini_all_active()}
            />
            <img
              src={icon_download}
              title="Download Data"
              alt="Add "
              width={25}
              style={{ cursor: "pointer" }}
              onClick={() => this.props.handleDownloadSini(request_id, "poi")}
            />
          </div>
          <IconOpen
            onClick={() => this.setState({ hide: !hide })}
            hide={hide}
          />
        </div>
        <main className={hide ? "sini_scroll" : "sini_scroll_hide"}>
          <figure
            className="sini_card_poi"
            style={{
              border: `2px solid  #000000`,
            }}
          >
            <div
              className="sini_card_poi_left"
              style={{
                backgroundColor: "#000000",
                width: "100px",
              }}
            >
              <p style={{ fontSize: "0.7rem" }}>
                {dict?.["Total POI"]?.[language]}
              </p>
              <h3 style={{ fontSize: "1.3rem" }}>{features?.length}</h3>
            </div>

            <div className="sini_card_poi_right">
              <p style={{ fontSize: "1rem" }}>{dict?.["Name"]?.[language]}</p>
            </div>
            {this?.props?.layer?.poi_select?.length === 0 ? (
              <div
                className="sini_card_poi_left"
                style={{
                  backgroundColor: "transparent",
                  borderRight: "2px solid #000",
                  cursor: "pointer",
                  width: "100px",
                }}
                onClick={() => this.sini_all_active()}
                title={dict?.["Hide All"]?.[language]}
              >
                <img src={icon_view} alt="Add " width={20} />
              </div>
            ) : (
              <div
                className="sini_card_poi_left"
                style={{
                  backgroundColor: "#000000",
                  borderRight: "2px solid #000",
                  cursor: "pointer",
                  width: "100px",
                }}
                onClick={() => this.sini_all_active()}
                title={dict?.["View All"]?.[language]}
              >
                <img src={icon_view_white} alt="Add " width={20} />
              </div>
            )}
          </figure>
          {tipe_1
            .sort((a, b) => (a.length < b.length ? 1 : -1))
            .map(({ name, length }, idx) => {
              const view_poi = this.props.layer.poi_select.includes(name);
              const color_poi = DataPoi?.find((d) => name === d?.name);

              return (
                <figure
                  className="sini_card_poi"
                  key={idx}
                  style={{
                    border: `2px solid ${
                      color_poi?.color ? color_poi?.color : "#000000"
                    }`,
                  }}
                >
                  <div
                    className="sini_card_poi_left"
                    style={{
                      backgroundColor: color_poi?.color
                        ? color_poi?.color
                        : "#000000",
                    }}
                  >
                    <p>{dict?.["Total"]?.[language]}</p>
                    <h3>{length}</h3>
                  </div>

                  <div className="sini_card_poi_right">
                    <p style={{ fontSize: "0.8rem" }}>
                      {
                        dict?.[
                          color_poi?.name !== undefined
                            ? color_poi?.name
                            : "null"
                        ]?.[language]
                      }
                    </p>
                  </div>
                  {view_poi ? (
                    <div
                      className="sini_card_poi_left "
                      style={{
                        backgroundColor: color_poi?.color
                          ? color_poi?.color
                          : "#000000",
                        borderRight: `2px solid ${
                          color_poi?.color ? color_poi?.color : "#000000"
                        }`,
                        cursor: "pointer",
                      }}
                      onClick={this.sini_poi_active.bind(this, name, false)}
                      title={dict?.["Hide"]?.[language]}
                    >
                      <img src={icon_view_white} alt="Add " width={20} />
                    </div>
                  ) : (
                    <div
                      className="sini_card_poi_left"
                      style={{
                        backgroundColor: "transparent",
                        borderRight: `2px solid ${
                          color_poi?.color ? color_poi?.color : "#000000"
                        }`,
                        cursor: "pointer",
                      }}
                      title={dict?.["View"]?.[language]}
                      onClick={this.sini_poi.bind(this, name, true)}
                    >
                      <img src={icon_view} alt="Add " width={20} />
                    </div>
                  )}
                </figure>
              );
            })}
        </main>
      </section>
    );

    const no_content = (
      <section className="sini_card">
        <div className="sini_card_top">
          <div>
            <h2>POI</h2>
            <p>
              {
                dict?.[
                  "There is no data at the point you selected, we will complete it soon."
                ]?.[language]
              }
            </p>
          </div>
        </div>
      </section>
    );

    return poi?.features?.length === 0 ? no_content : content;
  }
}
const mapStateToProps = (state) => ({
  layer: state.layer,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  set_poi_select,
  set_sini_all_active,
})(PoiDistribution);
