/*LIBRARY MODULE*/
import React, { Component } from "react";

/*PERSONAL COMPONENT*/
import Modal from "../reuseable/Modal";

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/
import dict from "../../Data/dict.json";
import ApiInput from "./ApiInput";

export default class ModalApiInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_api_input: false,
    };
  }

  toggle_modal_api_input = () => {
    const { modal_api_input } = this.state;
    this.setState({
      modal_api_input: !modal_api_input,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const { modal_api_input } = this.state;

    //global props

    //content

    const modal_api_input_content = modal_api_input && (
      <Modal
        title={dict["Connect to API"][language]}
        modalSize="normal"
        id="modal_api_input"
        isOpen={modal_api_input}
        onClose={this.toggle_modal_api_input}
      >
        <main className="box-body">
          <ApiInput />
        </main>
      </Modal>
    );

    return (
      <main>
        <button
          className="button rounded_25"
          onClick={this.toggle_modal_api_input}
        >
          {dict["Create Layer by API"][language]}
        </button>
        {modal_api_input_content}
      </main>
    );
  }
}
