import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";

const SERVER_URL = geoServerBaseUrl;

export const create_layer_custom_api = (body) => async (dispatch) => {
    try {
        dispatch(setLoading("createLayer"));
        const config = {
            headers: {
                accesstoken: localStorage.jwtTokenMapid,
            },
        };

        let final_body = { ...body };
        const res = await axios.post(
            SERVER_URL + "/open_api/create",
            final_body,
            config
        );
        let geo_layer = res.data;
        geo_layer.viewStatus = true;
        geo_layer.flyStatus = true;
        dispatch(clearLoading());
        dispatch({
            type: "CREATE_LAYER",
            payload: geo_layer,
        });
        if (body.is_show_and_fly) {
            const geo_layer_id = geo_layer?._id;
            dispatch(get_layer_detail_custom_api(geo_layer_id));
        }
        // dispatch({
        //     type: "status_action",
        // });
        // dispatch({
        //     type: "set_value",
        //     payload: {
        //         key: "current_progress_create_layer",
        //         value: 100,
        //     },
        // });
    } catch (e) {
        dispatch(clearLoading());
    }
};

// get layer
export const get_layer_detail_custom_api =
    (geo_layer_id) => async (dispatch) => {
        try {
            dispatch(setLoading("loading..."));
            const config = {
                headers: {
                    accesstoken: localStorage.jwtTokenMapid,
                },
            };

            const res = await axios.get(
                SERVER_URL +
                "/open_api/get_detail_layer_custom_endpoint/" +
                geo_layer_id,
                config
            );

            dispatch({
                type: "REPLACE_GEOJSON",
                payload: { geo_layer_id, geojson: res.data?.geojson },
            });


            dispatch({
                type: "push_field_bulk",
                payload: {
                    geo_layer_id,
                    fields: res.data?.fields || []
                }
            })



            dispatch({
                type: "set_layer_id",
                payload: geo_layer_id,
            });

            dispatch({
                type: "view_layer",
                payload: {
                    geo_layer_id,
                    value: true
                },
            });

            dispatch({
                type: "fly_action",
            });

            dispatch(clearLoading());
            // dispatch({
            //     type: "status_action",
            // });

            return res;
        } catch (error) {
            return error;
        }
    };

// testing endpoint client
export const request_by_client_api = (props) => async (dispatch) => {
    try {
        const { http_method, api_endpoint, headers, params, body } = props
        const request_body = { http_method, api_endpoint, headers, params, body };
        const config = {
            headers,
        };

        dispatch(setLoading("Loading..."));
        const res = await axios.post(SERVER_URL + "/open_api/get_custom_api", request_body, config);
        dispatch(clearLoading());
        return res;
    } catch (error) {
        dispatch(clearLoading());

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
            if (error.request?.status) {
                return {
                    status: 404,
                    data: {
                        message: "Server not found"
                    }
                }
            } else {
                return {
                    status: error.request?.status,
                    data: {
                        message: "Something error"
                    }
                }
            }
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }

        return error;
    }
};

export const setLoading = (itemLoading) => {
    return {
        type: "SET_LOADING_PROCESS_LAYER",
        payload: itemLoading,
    };
};

export const clearLoading = () => {
    return {
        type: "CLEAR_LOADING_LAYER",
    };
};