/*LIBRARY MODULE*/
import randomColor from "randomcolor";
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import {
  push_isochrone,
  delete_isochrone,
  view_isochrone,
  view_isochrone_id,
  non_view_isochrone_id,
} from "../../App/actions/siniActions";

/*PICTURE ASSET*/
import icon_delete from "../../Assets/svg/icon_delete_red_outline_white.svg";
import icon_download from "../../Assets/svg/icon_download.svg";
import icon_view_blue from "../../Assets/svg/icon_view_blue.svg";
import icon_add from "../../Assets/svg/icon_add.svg";
import { IconOpen } from "./icon";

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/
const languageGlobal = localStorage?.language ? localStorage?.language : "ina";

class SiniIsochrone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routing_profile: "",
      countour_type: "",
      message: "",
      interval: 0,
      information: false,
      hide: false,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = () => {
    const { routing_profile, countour_type, interval } = this.state;
    const { sini_list, request_id } = this.props.layer;

    const coordinates = sini_list.find((d) => d?.request_id === request_id);
    const lat = coordinates?.lat;
    const long = coordinates?.long;

    const body = {
      lat,
      long,
      routing_profile,
      countour_type,
      interval,
      request_id,
      color: randomColor(),
    };

    if (routing_profile === "") {
      this.setState({
        message: dict?.["Input Route Profile Data"]?.[languageGlobal],
      });
    } else if (countour_type === "") {
      this.setState({
        message: dict?.["Input the Isochron Method Data"]?.[languageGlobal],
      });
    } else if (interval === "") {
      this.setState({
        message: dict?.["Selected Time/Distance Data"]?.[languageGlobal],
      });
    } else {
      this.setState({
        information: true,
        message: "",
      });
      this.props.set_sini_mode("isochrone");
      this.props.push_isochrone(body);
    }
  };

  download_json = () => {
    const { isochrone } = this.props.layer;

    const body_json = JSON.stringify(isochrone);
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(body_json)
    );
    const file_name = `SINI-Isochrone.geojson`;
    element.setAttribute("download", file_name);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  handleReset = () => {
    this.setState({
      routing_profile: "",
      countour_type: "",
      interval: "",
      information: false,
    });
  };

  handle_delete = (data) => {
    const { request_id } = this.props.layer;

    const body = {
      data,
      request_id,
    };
    this.props.delete_isochrone(body);
  };

  set_view = (key) => {
    const { sini_list, request_id } = this.props.layer;

    const sini_active = sini_list.find((d) => d.request_id === request_id);
    const isochrone = sini_active.isochrone.find((d) => d.key === key);

    const body_false = {
      key,
      request_id,
      view: false,
    };
    const body_true = {
      key,
      request_id,
      view: true,
    };

    if (isochrone.view === false) {
      this.props.view_isochrone(body_true);
      this.props.view_isochrone_id(key);
    } else {
      this.props.view_isochrone(body_false);
      this.props.non_view_isochrone_id(key);
    }
  };

  render() {
    const { routing_profile, countour_type, interval, information, hide } =
      this.state;
    const { request_id } = this.props.layer;
    const data_sini = this?.props?.layer?.sini_list?.find(
      (d) => d?.request_id === this?.props?.layer?.request_id
    );
    const language = localStorage?.language ? localStorage?.language : "ina";

    return (
      <section className="sini_card">
        <div className="sini_card_top">
          <div style={{ zIndex: "5" }}>
            <h2> {dict?.["Isochrone"]?.[language]}</h2>
            <img
              src={icon_view_blue}
              className={hide ? "hide_icon" : "show_icon"}
              alt="Add "
              width={25}
              onClick={() => this.setState({ hide: !hide })}
              style={{ cursor: "pointer" }}
              title={
                this.props.layer.sini_mode.find((d) => d === "isochrone")
                  ? dict?.["Hide"]?.[language]
                  : dict?.["View"]?.[language]
              }
            />
            <img
              src={icon_download}
              title="Download Data"
              alt="Add "
              width={25}
              style={{ margin: "0 5px", cursor: "pointer" }}
              onClick={() =>
                this.props.handleDownloadSini(request_id, "isochrone")
              }
            />
            <img
              src={icon_add}
              style={{ marginRight: "5px", cursor: "pointer" }}
              className={hide && information ? "show_icon" : "hide_icon"}
              alt="Add "
              width={25}
              onClick={() => this.handleReset()}
            />
          </div>
          <IconOpen
            onClick={() => this.setState({ hide: !hide })}
            hide={hide}
          />
        </div>
        <main className={hide ? "sini_scroll" : "sini_scroll_hide"}>
          <div className="Isochrone_section">
            {information ? (
              <>
                {data_sini?.isochrone?.length !== 0 ? (
                  <table className="sini_table">
                    <thead>
                      <tr>
                        <th></th>
                        <th>{dict?.["Profile"]?.[language]}</th>
                        <th>{dict?.["Type"]?.[language]}</th>
                        <th>{dict?.["Methods"]?.[language]}</th>
                        <th>{dict?.["Action"]?.[language]}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data_sini?.isochrone?.map((d, index) => (
                        <tr key={index}>
                          <td>
                            <div
                              style={{
                                backgroundColor: d?.color,
                                width: "30px",
                                height: "30px",
                              }}
                            />
                          </td>
                          <td>
                            {d?.countour_type === "Time"
                              ? dict?.["Time"]?.[language]
                              : d?.countour_type === "Distance"
                              ? dict?.["Distance"]?.[language]
                              : d?.routing_profile}
                          </td>
                          <td>
                            {d?.routing_profile === "walking"
                              ? dict?.["Walking"]?.[language]
                              : d?.routing_profile === "cycling"
                              ? dict?.["Cycling"]?.[language]
                              : d?.routing_profile === "driving"
                              ? dict?.["Driving"]?.[language]
                              : d?.routing_profile}
                          </td>
                          <td>
                            {d?.countour_type === "Time"
                              ? `${d?.interval} Min`
                              : `${d?.interval} Km`}
                            {/* {d?.interval === "500"
                              ? "0.5"
                              : d?.interval === "1000"
                              ? "1"
                              : d?.interval === "5000"
                              ? "5"
                              : d?.interval === "10000"
                              ? "10"
                              : d?.interval === "15000"
                              ? "15"
                              : d?.interval === "20000"
                              ? "20"
                              : d?.interval} */}
                            {/* {d?.interval > 100 ? "Meters" : "Min"} */}
                          </td>
                          <td>
                            <div style={{ display: "flex", gap: "5px" }}>
                              <button
                                className={
                                  d.view
                                    ? "button_eyes_close"
                                    : "button_eyes_open"
                                }
                                title={
                                  d.view
                                    ? dict?.["Hide"]?.[language]
                                    : dict?.["View"]?.[language]
                                }
                                onClick={() => this.set_view(d.key)}
                              >
                                <div />
                                <div />
                                <div />
                              </button>
                              <button
                                onClick={() => this.handle_delete(d)}
                                title="Delete"
                              >
                                <img src={icon_delete} alt="delete" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : null}
              </>
            ) : (
              <>
                <div className="Isochrone_row">
                  <h3>{dict?.["Routing Profile"]?.[language]}</h3>
                  <div className="Isochrone_input">
                    <button
                      id={
                        routing_profile === "walking" ? "blue" : "bright_blue"
                      }
                      onClick={() =>
                        this.setState({ routing_profile: "walking" })
                      }
                    >
                      {dict?.["Walking"]?.[language]}
                    </button>
                    <button
                      id={
                        routing_profile === "cycling" ? "blue" : "bright_blue"
                      }
                      onClick={() =>
                        this.setState({ routing_profile: "cycling" })
                      }
                    >
                      {dict?.["Cycling"]?.[language]}
                    </button>
                    <button
                      id={
                        routing_profile === "driving" ? "blue" : "bright_blue"
                      }
                      onClick={() =>
                        this.setState({ routing_profile: "driving" })
                      }
                    >
                      {dict?.["Driving"]?.[language]}
                    </button>
                  </div>
                </div>
                <div className="Isochrone_row">
                  <h3> {dict?.["Isochrone Methods"]?.[language]}</h3>
                  <div className="Isochrone_input">
                    <button
                      id={countour_type === "Time" ? "blue" : "bright_blue"}
                      onClick={() => this.setState({ countour_type: "Time" })}
                    >
                      {dict?.["Time"]?.[language]}
                    </button>
                    <button
                      id={countour_type === "Distance" ? "blue" : "bright_blue"}
                      onClick={() =>
                        this.setState({ countour_type: "Distance" })
                      }
                    >
                      {dict?.["Distance"]?.[language]}
                    </button>
                  </div>
                </div>
                {countour_type === "Time" ? (
                  <div className="Isochrone_row">
                    <h3>{dict?.["Select Time"]?.[language]}</h3>
                    <div className="Isochrone_input">
                      <select
                        className="input_new"
                        onChange={(e) =>
                          this.setState({ interval: e.target.value })
                        }
                      >
                        <option hidden>{dict?.["Minutes"]?.[language]}</option>
                        <option value="10">
                          10 {dict?.["Minutes"]?.[language]}
                        </option>
                        <option value="20">
                          20 {dict?.["Minutes"]?.[language]}
                        </option>
                        <option value="30">
                          30 {dict?.["Minutes"]?.[language]}
                        </option>
                        <option value="40">
                          40 {dict?.["Minutes"]?.[language]}
                        </option>
                        <option value="50">
                          50 {dict?.["Minutes"]?.[language]}
                        </option>
                        <option value="60">
                          60 {dict?.["Minutes"]?.[language]}
                        </option>
                      </select>
                    </div>
                  </div>
                ) : countour_type === "Distance" ? (
                  <div className="Isochrone_row">
                    <h3>{dict?.["Select Distance"]?.[language]}</h3>
                    <div className="Isochrone_input">
                      {/* <select
                        className="input_new"
                        onChange={(e) =>
                          this.setState({ interval: e.target.value })
                        }
                      >
                        <option hidden>
                          {dict?.["Kilometers"]?.[language]}
                        </option>
                        <option value="500">
                          0.5 {dict?.["Kilometers"]?.[language]}
                        </option>
                        <option value="1000">
                          1 {dict?.["Kilometers"]?.[language]}
                        </option>
                        <option value="5000">
                          5 {dict?.["Kilometers"]?.[language]}
                        </option>
                        <option value="10000">
                          10 {dict?.["Kilometers"]?.[language]}
                        </option>
                        <option value="15000">
                          15 {dict?.["Kilometers"]?.[language]}
                        </option>
                        <option value="20000">
                          20 {dict?.["Kilometers"]?.[language]}
                        </option>
                      </select> */}
                      <p>1 Km</p>
                      <input
                        type="range"
                        min="1"
                        max="100"
                        step={1}
                        onChange={(e) =>
                          this.setState({ interval: e.target.value })
                        }
                        value={interval}
                      />
                      <p>100 Km</p>
                    </div>
                    <div>
                      <input
                        className="range_buffer"
                        type="number"
                        min="1000"
                        max="100000"
                        step={1}
                        onChange={(e) =>
                          this.setState({ interval: e.target.value })
                        }
                        value={interval}
                      />
                      <p>Kilometer</p>
                    </div>
                  </div>
                ) : (
                  <div className="Isochrone_row">
                    <h3>{dict?.["Select"]?.[language]}</h3>
                    <div className="Isochrone_input">
                      <select className="input_new">
                        <option hidden>
                          {dict?.["Select Methods Isochrone"]?.[language]}
                        </option>
                      </select>
                    </div>
                  </div>
                )}
                <h5 className="isochrone_message">{this.state.message}</h5>
                <section className="button_sini_row">
                  {data_sini?.isochrone?.length !== 0 ? (
                    <button
                      className="button"
                      id="blue"
                      onClick={() =>
                        this.setState({
                          information: true,
                          message: "",
                        })
                      }
                    >
                      {dict?.["Back"]?.[language]}
                    </button>
                  ) : null}
                  <button
                    className="button"
                    id={interval ? "green" : "grey"}
                    onClick={() => this.handleSubmit()}
                  >
                    {dict?.["Add"]?.[language]}
                  </button>
                </section>
              </>
            )}
          </div>
        </main>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  push_isochrone,
  delete_isochrone,
  view_isochrone,
  view_isochrone_id,
  non_view_isochrone_id,
})(SiniIsochrone);
