//Library modul
import { Component } from "react";
import { connect } from "react-redux";
import writeXlsxFile from "write-excel-file";

import dict from "../../Data/dict.json";
import is_not_empty from "./is_not_empty";

class DownloadExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  download_excel = async () => {
    const { layer_id, geo_layer_list } = this.props.layer;
    const geo_layer = geo_layer_list.find(
      (e) => e?.geo_layer?._id === layer_id
    );

    let fields_layer = geo_layer?.geo_layer?.fields
      ? geo_layer?.geo_layer?.fields
      : [];

    const features_filtered =
      geo_layer?.geo_layer?.geojson_filtered?.features || [];

    let fields_point = [
      ...fields_layer,
      {
        key: "latitude",
        name: "latitude",
        isHide: false,
      },
      {
        key: "longitude",
        name: "longitude",
        isHide: false,
      },
    ];

    let fields =
      features_filtered?.[0]?.geometry?.type === "Point"
        ? fields_point
        : fields_layer;

    try {
      let schema = fields
        .filter((f) => f.isHide === false)
        .map((d) => {
          return {
            column: d.name,
            type: String,
            value: (data) => data[d.key],
          };
        });

      const key = fields.map((d) => d.key);

      const newData = features_filtered.map((d) => {
        let obj = {};
        for (let index = 0; index < key.length; index++) {
          const element = key[index];

          if (d?.properties?.[element] !== undefined) {
            obj[element] = d?.properties?.[element];
          } else if (
            d?.child_array?.map((m) => m?.properties?.[element]) !== undefined
          ) {
            obj[element] = String(
              d?.child_array?.map((m) => m?.properties?.[element])
            );
          }

          let properties = "";
          if (is_not_empty(d?.properties?.[element])) {
            if (!isNaN) {
              properties = +d?.properties?.[element];
            } else {
              properties = d?.properties?.[element];
            }
          }

          let properties_child = "";
          if (is_not_empty(d?.child_array?.[0]?.properties?.[element])) {
            if (!isNaN) {
              properties = +d?.child_array?.[0]?.properties?.[element];
            } else {
              properties = d?.child_array?.[0]?.properties?.[element];
            }
          }

          if (d?.properties?.[element] !== undefined) {
            obj[element] = String(properties);
          } else if (d?.child_array?.[0]?.properties?.[element] !== undefined) {
            obj[element] = String(properties_child);
          }
        }

        const body = {
          ...obj,
          longitude: String(d.geometry.coordinates[0]),
          latitude: String(d.geometry.coordinates[1]),
        };
        return d.geometry.type === "Point" ? body : obj;
      });

      await writeXlsxFile(newData, {
        schema,
        fileName: geo_layer.geo_layer.name,
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";

    return (
      <button className="buttonSimple2" onClick={() => this.download_excel()}>
        {dict?.["Download Excel"]?.[language]}
      </button>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  auth: state.auth,
  layer: state.layer,
});

export default connect(mapStateToProps, {})(DownloadExcel);
