const generate_width_map = function (array_name, array_color, text) {
  let result = [],
    i,
    l = Math.min(array_name.length, array_color.length),
    circle_color;
  for (i = 0; i < l; i++) {
    result.push(array_name[i], array_color[i]);
  }
  if (array_color.length <= array_name.length) {
    array_color.push(1);
  }
  result.push(...array_name.slice(l), ...array_color.slice(l));
  circle_color = ["match", ["get", text]];
  circle_color = circle_color.concat(result);
  return circle_color;
};
export default generate_width_map;
