import React, { useState } from "react";
import dict from "../../Data/dict.json";

const IconPlus = ({ handleClick }) => {
  const [click, setClick] = useState(false);

  return (
    <button
      className={click ? "icon_plus_close" : "icon_plus_open"}
      onClick={() => {
        setClick(!click);
        handleClick();
      }}
    >
      <div />
      <div />
    </button>
  );
};

const IconArrowOpen = ({ onClick }) => {
  return (
    <div className="icon_arrow_open" onClick={onClick}>
      <div />
      <div />
    </div>
  );
};

const IconOpen = ({ onClick, hide }) => {
  const language = localStorage?.language ? localStorage?.language : "ina";

  return (
    <div
      onClick={onClick}
      style={{
        width: "97%",
        zIndex: "1",
        display: "flex",
        justifyContent: "flex-end",
        padding: "15px 5px",
        position: "absolute",
        cursor: "pointer",
      }}
      title={hide ? dict?.["Close"]?.[language] : dict?.["Open"]?.[language]}
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="caret-down"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
        style={
          hide
            ? {
                width: "1.7rem",
                display: "inline-block",
                height: "1.7rem",
                transition: "all 0.5s ease-in-out",
                transform: "rotate(180deg)",
                color: "#2f5e92",
              }
            : {
                width: "1.7rem",
                display: "inline-block",
                height: "1.7rem",
                transition: "all 0.5s ease-in-out",
                color: "#2f5e92",
              }
        }
      >
        <path
          fill="currentColor"
          d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
        ></path>
      </svg>
    </div>
  );
};

export { IconPlus, IconArrowOpen, IconOpen };
