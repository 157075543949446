import icon_plus from "../../Assets/svg/icon_add.svg";

import React, { Component } from "react";
import { connect } from "react-redux";
import dict from "../../Data/dict.json";

//Redux function
import { pushField } from "../../App/actions/layerActions";
import { push_features } from "../../App/actions/layerNewActions";
import { set_value } from "../../App/actions/dataActions";

import CheckBox from "../common_input/CheckBox";
import Modal from "../common_modal/Modal";
import AddRowsBulkMultipleFiles from "./AddRowsBulkMultipleFiles";
import AddRowBulkSingleFile from "./AddRowBulkSingleFile";

class AddRowBulk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      is_multiple: false,
      is_key_uuid: true,
      files_geojson: [],
    };
  }

  set_files_geojson = (value, callback) => {
    this.setState(
      {
        files_geojson: value,
      },
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  toggle_is_key_uuid = () => {
    const { is_key_uuid } = this.state;
    this.setState({
      is_key_uuid: !is_key_uuid,
    });
  };

  toggle_is_multiple = () => {
    const { is_multiple } = this.state;
    this.setState({
      is_multiple: !is_multiple,
    });
  };

  toggle_modal = () => {
    const { modal } = this.state;
    this.setState({
      modal: !modal,
    });
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { modal, is_multiple, is_key_uuid, files_geojson } = this.state;
    const { geo_layer } = this.props;
    const modal_content = modal && (
      <Modal
        modalSize="medium"
        id="modal_data_pembanding"
        isOpen={modal}
        onClose={this.toggle_modal}
      >
        <main className="box-body">
          {is_multiple ? (
            <>
              <div>
                <p className="font_20 text_center">
                  {dict["Add Rows from a multiple files into"][language]}
                </p>
                <p className="font_30 text_center">{geo_layer?.name}</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <p className="font_20 text_center">
                  {dict["Add Rows from a single file into"][language]}
                </p>
                <p className="font_30 text_center">{geo_layer?.name}</p>
              </div>
            </>
          )}
          <section className="paddingLeft_20">
            <div className="grid grid_3 w_250">
              <label htmlFor="multiple">Single</label>
              <div className="center_perfect">
                <CheckBox
                  text="multiple"
                  value={is_multiple}
                  handle={this.toggle_is_multiple}
                />
              </div>
              <label htmlFor="multiple">Multiple</label>
              <label>{`Key => Name`}</label>
              <div className="center_perfect">
                <CheckBox
                  value={is_key_uuid}
                  handle={this.toggle_is_key_uuid}
                />
              </div>
              <label>{`Key => UUID`}</label>
            </div>
          </section>
          {is_multiple ? (
            <AddRowsBulkMultipleFiles
              geo_layer={geo_layer}
              is_key_uuid={is_key_uuid}
              toggle_is_key_uuid={this.toggle_is_key_uuid}
              files_geojson={files_geojson}
              set_files_geojson={this.set_files_geojson}
            />
          ) : (
            <AddRowBulkSingleFile
              geo_layer={geo_layer}
              is_key_uuid={is_key_uuid}
              toggle_is_key_uuid={this.toggle_is_key_uuid}
            />
          )}
        </main>
      </Modal>
    );

    return (
      <>
        <div
          id="icon_add"
          className="w_20 h_20 pointer"
          onClick={this.toggle_modal}
        >
          <img
            src={icon_plus}
            alt={dict["Add Rows by file"][language]}
            title={dict["Add Rows by file"][language]}
            className="w_20 h_20"
          />
        </div>
        {modal_content}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  pushField,
  push_features,
  set_value,
})(AddRowBulk);
