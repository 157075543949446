/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

/*PERSONAL COMPONENT*/
import Modal from "../Components/common_modal/Modal";
import CreateProject from "../Components/project_dashboard/CreateProject";
import CREATE_FOLDER_PROJECT from "../Components/folder_project/CREATE_FOLDER_PROJECT";
import PROJECT_CONTROL_PANEL from "../Components/folder_project/PROJECT_CONTROL_PANEL";
import FOLDER_NAVIGATION from "../Components/folder_project/FOLDER_NAVIGATION";

import FOLDER_PROJECT_MODE_BOX from "../Components/folder_project/FOLDER_PROJECT_MODE_BOX";
import FOLDER_PROJECT_MODE_LIST from "../Components/folder_project/FOLDER_PROJECT_MODE_LIST";

import PROJECT_MODE_BOX from "../Components/project_dashboard/PROJECT_MODE_BOX";
import PROJECT_MODE_LIST from "../Components/project_dashboard/PROJECT_MODE_LIST";

/*REDUX FUNCTION*/
import { getUserPaymentList } from "../App/actions/paymentActions";
import { getGroups } from "../App/actions/groupActions";
import { openModal } from "../App/actions/authActions";
import { get_folder_project_list_by_user_id } from "../App/actions/folderProjectAction";
import { get_pinned_project_list } from "../App/actions/projectActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../Data/dict.json";

/*NON IMPORT*/

class ProjectListDashboard extends Component {
  state = {
    modal_create_project: false,
    modal_create_folder: false,
    mode_page: "projects",
    mode_view: "box",
  };

  componentDidMount() {
    this.props.getUserPaymentList();
    this.props.getGroups();
    this.props.get_folder_project_list_by_user_id();
    this.props.get_pinned_project_list();
  }

  toggle_create_folder = () => {
    this.setState({ modal_create_folder: !this.state.modal_create_folder });
  };

  toggle_create_project = () => {
    this.setState({
      modal_create_project: !this.state.modal_create_project,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";
    const mode_view = localStorage.getItem("mode_view") || "box";
    // const mode_page = localStorage.getItem("mode_page") || "all";

    //local state
    const { modal_create_project, modal_create_folder } = this.state;

    //global props

    //content

    const modal_create_project_content = modal_create_project && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_create_project}
        onClose={this.toggle_create_project}
      >
        <div className="box-body">
          <CreateProject toggle_create_project={this.toggle_create_project} />
        </div>
      </Modal>
    );

    const modal_create_folder_content = modal_create_folder && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_create_folder}
        onClose={this.toggle_create_folder}
      >
        <div className="box-body">
          <CREATE_FOLDER_PROJECT
            toggle_create_folder={this.toggle_create_folder}
          />
        </div>
      </Modal>
    );

    return (
      <div id="main_container">
        {modal_create_project_content}
        {modal_create_folder_content}
        <PROJECT_CONTROL_PANEL
          toggle_create_project={this.toggle_create_project}
          toggle_create_folder={this.toggle_create_folder}
        />

        {/* {mode_view === "box" ? (
          <PROJECT_MODE_BOX title="Pinned" />
        ) : (
          <PROJECT_MODE_LIST title="Pinned" />
        )} */}

        <FOLDER_NAVIGATION />
        {mode_view === "box" ? (
          <FOLDER_PROJECT_MODE_BOX />
        ) : (
          <FOLDER_PROJECT_MODE_LIST />
        )}
        {mode_view === "box" ? <PROJECT_MODE_BOX /> : <PROJECT_MODE_LIST />}
        <Helmet>
          <title>{dict?.["Dashboard"]?.[language]}</title>
          <meta name="description" content={`${dict["Dashboard"][language]}`} />
        </Helmet>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  payment: state.payment,
  auth: state.auth,
  project: state.project,
});

export default connect(mapStateToProps, {
  getUserPaymentList,
  getGroups,
  openModal,
  get_folder_project_list_by_user_id,
  get_pinned_project_list,
})(ProjectListDashboard);
