//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal component

//Redux function
import { searchLayersOnly } from "../../App/actions/layerActions";
import { pushLayer } from "../../App/actions/blogAction";

//Picture Asset

//General Function
import dict from "../../Data/dict.json";
import isEmpty from "../../App/validation/is-empty";
const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

class AttachLayer extends Component {
  state = {
    search_params: "",
  };
  componentDidMount() {
    this.timer = null;
  }
  handlePushLayer = (geo_layer) => {
    const { blog_personal } = this.props.blog;
    const layers = blog_personal.layers;
    const body = {
      blog_id: blog_personal._id,
      layer: geo_layer,
    };
    const isAnySameLayer = layers.find(
      (layer) => layer.geo_layer._id === geo_layer._id
    )
      ? true
      : false;

    if (!isAnySameLayer) {
      this.props.pushLayer(body);
    }
  };
  handleChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    clearTimeout(this.timer);
    this.setState({
      [name]: value,
    });
    this.timer = setTimeout(this.triggerChange.bind(this), WAIT_INTERVAL);
  }
  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.triggerChange.bind(this);
    }
  }
  triggerChange() {
    const { search_params } = this.state;
    if (search_params) {
      this.props.searchLayersOnly(search_params);
    }
  }
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { search_params } = this.state;
    const geo_layer_state = this.state.geo_layer;
    const { search_result_list, loadingProcess, itemLoading } =
      this.props.layer;
    const { geo_layer_list } = search_result_list;
    let loadingContent;
    if (loadingProcess && itemLoading === "search_layer") {
      loadingContent = dict["Searching"][language];
    } else {
      loadingContent = dict["Search Layer"][language];
    }
    let resultContent;
    if (loadingProcess && itemLoading === "search_layer") {
      resultContent = (
        <div className="container_grey">{dict["Searching"][language]}...</div>
      );
    } else if (isEmpty(geo_layer_list)) {
      resultContent = (
        <div className="container_grey">{dict["No result"][language]}</div>
      );
    } else {
      resultContent = (
        <div>
          {geo_layer_list.map((geo_layer) => {
            const { _id, name, geo_project } = geo_layer;
            const geo_project_name = geo_project?.name;
            let buttonContent;
            if (
              loadingProcess &&
              itemLoading === "push_project_attach" &&
              geo_layer_state._id === _id
            ) {
              buttonContent = (
                <div className="button_standard">
                  {dict["Attaching"][language]}...
                </div>
              );
            } else {
              buttonContent = (
                <div
                  onClick={this.handlePushLayer.bind(this, geo_layer)}
                  className="button_standard"
                >
                  {dict["Attach"][language]}
                </div>
              );
            }
            return (
              <div
                key={_id}
                className="container_grey"
                style={{ marginBottom: "10px" }}
              >
                <div className="two_container_auto">
                  <div style={{ textAlign: "left" }}>
                    <div
                      className="text_bold"
                      style={{ maxHeight: "38px", overflow: "hidden" }}
                    >
                      {name}
                    </div>
                    <div
                      className="text_inferior"
                      style={{ height: "15px", overflow: "hidden" }}
                    >
                      {dict["Project"][language]}: {geo_project_name}
                    </div>
                  </div>
                  <div style={{ textAlign: "right" }}>{buttonContent}</div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    const itemContent = (
      <div>
        <div className="text_bold">{loadingContent}</div>
        <div className="text_inferior" style={{ marginBottom: "10px" }}>
          {
            dict[
              "Search layer from all your project then import it to this document."
            ][language]
          }
        </div>
        <input
          type="text"
          placeholder={dict["Search"][language]}
          autoComplete="off"
          name="search_params"
          value={search_params}
          onChange={this.handleChange.bind(this)}
          onKeyDown={this.handleKeyDown.bind(this)}
        />
        {search_params && resultContent}
      </div>
    );
    return <div>{itemContent}</div>;
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  blog: state.blog,
});
export default connect(mapStateToProps, {
  searchLayersOnly,
  pushLayer,
})(AttachLayer);
