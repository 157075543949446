import React, { Component } from "react";
class IconCheck extends Component {
  render() {
    return (
      <svg
        viewBox="0 0 13.229166 15.275647"
        version="1.1"
        id="svg5"
        xmlns="http://www.w3.org/2000/svg"
        height="15px"
        width="15px"
      >
        <defs id="defs2" />
        <g id="layer1" transform="translate(-15.937285,-48.552281)">
          <path
            style={{
              fill: "#4d4d4d",
              fillRule: "evenodd",
              strokeWidth: 40,
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeDashoffset: 11.4111,
              paintOrder: "stroke markers fill",
            }}
            id="path53"
            d="M 221.41679,369.62093 60.235405,276.5628 221.4168,183.50469 Z"
            transform="matrix(-0.08207626,0,0,0.08207584,34.110348,33.49098)"
          />
        </g>
      </svg>
    );
  }
}

export default IconCheck;
