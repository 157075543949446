import React, { Component } from "react";
import { connect } from "react-redux";
import {
  // getSini,
  setRequestId,
} from "../../App/actions/siniActions";
import { circle } from "@turf/turf";
import { Source, Layer } from "@urbica/react-map-gl";

class SiniLayer extends Component {
  constructor(props) {
    super(props);
    this._map = React.createRef();
    this._draw = React.createRef();
    this.state = {
      // SINI
      mode: "draw_point",
      id_active: "",
      renderSini: [],
      pickCoord: [0, 0],
    };
  }
  componentDidMount() {
    this.SiniRender();
  }

  componentDidUpdate(prevProps) {
    // AFTER
    const sini_list_after = this.props.layer.sini_list;
    const request_id_after = this.props.layer.request_id;
    const sini_mode_after = this.props.layer.sini_mode;

    // BEFORE
    const sini_list_before = prevProps.layer.sini_list;
    const request_id_before = prevProps.layer.request_id;
    const sini_mode_before = prevProps.layer.sini_mode;

    if (
      sini_list_after?.length !== sini_list_before?.length ||
      request_id_after !== request_id_before ||
      sini_mode_after !== sini_mode_before
    ) {
      this.SiniRender();
    }
  }

  onSetId = (data) => {
    const map = this.props.map.map;
    this.props.setRequestId(data.request_id);
    this.setState({ id_active: data.request_id });
    map.flyTo({
      center: [data.long, data.lat],
      zoom: 12.5,
      speed: 1,
    });
  };

  SiniRender() {
    const { sini_list } = this.props.layer;

    const renderSini = sini_list?.map((data, index) => {
      const coordinates = [Number(data.long), Number(data.lat)];
      const body = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              color: "#56549A",
            },
            geometry: {
              coordinates: coordinates,
              type: "Point",
            },
          },
        ],
      };

      let center = coordinates;
      let options = {
        steps: 64,
        units: "kilometers",
        properties: {},
      };
      let radius = Number(data.radius) || 1;

      let srcSini = circle(center, radius, options);

      const colorPoint =
        data.request_id === this.props.layer.request_id ? "#1265ae" : "#61b930";

      return (
        <div key={index}>
          <DisplayRadiusSini
            data={data}
            srcSini={srcSini}
            active={data.request_id === this.props.layer.request_id}
          />
          <DisplaySini
            title={`titik-sini-${data.request_id}`}
            type="circle"
            data={body}
            color={{
              "circle-color": colorPoint,
              "circle-radius": 8,
              "circle-stroke-width": 3,
              "circle-stroke-color": "#ffffff",
            }}
            onClick={this.onSetId.bind(this, data)}
          />
          <section>
            <Source
              key={`${data.request_id} + ${index + 1}`}
              id={`${data.request_id} + ${index + 1}`}
              type="geojson"
              data={body}
            />
            <Layer
              id={`${data.request_id} + ${index + 1}`}
              type="symbol"
              source={`${data.request_id} + ${index + 1}`}
              layout={{
                "text-field": `${index + 1}`, // mengasih nama labelnya
                "text-variable-anchor": ["bottom"],
                "text-radial-offset": 1, // menentukan tata letak tulisan
                "text-justify": "auto",
              }}
              paint={{
                "text-halo-color": "#fff",
                "text-halo-width": 30,
                "text-halo-blur": 0,
                "text-color": "#2064A1",
              }}
            />
          </section>
        </div>
      );
    });
    this.setState({ renderSini: renderSini });
  }
  render() {
    return this.state.renderSini;
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  map: state.map,
});

export default connect(mapStateToProps, {
  setRequestId,
  // getSini,
})(SiniLayer);

export const DisplaySini = ({ title, data, color, type, onClick }) => {
  return (
    <section>
      <Source
        key={title}
        id={title}
        type="geojson"
        data={data}
        clusterMaxZoom={14} // Max zoom to cluster points on
        clusterRadius={1000}
      />
      <Layer
        id={title}
        type={type}
        source={title}
        paint={color}
        filter={["!", ["has", "point_count"]]}
        onClick={onClick}
      />
    </section>
  );
};

export const DisplayRadiusSini = ({ data, srcSini, active }) => {
  return (
    <>
      <Source
        id={"src-sini-radius-" + data.request_id}
        key={"src-sini-radius-" + data.request_id}
        type="geojson"
        data={srcSini}
      />
      <Layer
        id={"sini-radius-fill" + data.request_id}
        before={"unclustered-fill-" + data.request_id}
        key={"sini-radius-fill-" + data.request_id}
        type={"fill"}
        source={"src-sini-radius-" + data.request_id}
        layout={{
          visibility: "visible",
        }}
        paint={{
          // "line-color": active ? "#244aa1" : "#b8ffb8",
          // "line-width": 1.5,
          "fill-color": active ? "#b8ffb8" : "#244aa1",
          "fill-opacity": 0.15,
        }}
      />
      <Layer
        id={"sini-radius-line" + data.request_id}
        before={"unclustered-line-" + data.request_id}
        key={"sini-radius-line-" + data.request_id}
        type={"line"}
        source={"src-sini-radius-" + data.request_id}
        layout={{
          visibility: "visible",
        }}
        paint={{
          "line-color": active ? "#244aa1" : "#b8ffb8",
          "line-width": 1.5,
        }}
      />
    </>
  );
};
