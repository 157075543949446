/**
 * Function ini akan mengambil nilai object/array dalam object. 
 * Misalkan ada nested object, contoh:
 * 
 * ```
 * const obj = {
 *    lv_1 : {
 *        lv_2: {
 *          value: "hai"
 *       }
 *    } 
 * } 
 * ```
 * 
 * kita hanya perlu 2 parameter, object dan path dalam bentuk array seperti berikut 
 * 
 * ```
 * console.log( 
 *      get_nested_object( obj, ["lv_1","lv_2"] ) 
 * )
 * ```
 * 
 * output: { value : "hai"}
 * 
 */
export const get_nested_object = (object, array_path) => {
    const array_path_len = array_path.length;
    if (array_path_len > 0) {
        const first_path = array_path.slice(0, 1);
        const sub_data = object?.[first_path];
        return get_nested_object(sub_data, array_path.slice(1));
    } else {
        return object;
    }
};


/**
 * Function ini akan mengambil nilai object/array dalam object. 
 * Misalkan ada nested object, contoh:
 * 
 * ```
 * const obj = {
 *    lv_1 : {
 *       lv_2_a: {
 *          value: "hai"
 *       }
 *       lv_2_b: {
 *          value: "hello"
 *       }
 *    } 
 * } 
 * ```
 * 
 * kita hanya perlu 2 parameter, object dan path dalam bentuk array seperti berikut 
 * 
 * ```
 * console.log( get_nested_object( obj , ["lv_1"] ) )
 * ```
 *
 * // output: [ 'lv_2_a', 'lv_2_b' ]
 */
export const get_option_path = (object, array_path) => {
    const array_path_len = array_path.length;
    if (array_path_len > 0) {
        const first_path = array_path.slice(0, 1);
        const sub_data = object[first_path];
        return get_option_path(sub_data, array_path.slice(1));
    } else {
        const option_path = []
        for (const option in object) {
            option_path.push(option)
        }
        return option_path
    }
};