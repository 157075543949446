/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

/*PERSONAL COMPONENT*/
import * as Components from "../Components";

/*REDUX FUNCTION*/
import {
  getBlogListPublic,
  getBlogListPopular,
  changePrice,
  search_blog,
} from "../App/actions/blogAction";
import { getProjectListPublic } from "../App/actions/projectActions";
// import { get_tag_list } from "../../actions/playActions";
import { getBanner } from "../App/actions/authActions";

/*PICTURE ASSET*/
import * as Assets from "../Assets";

/*GENERAL*/
import history from "../App/actions/history";
import dict from "../Data/dict.json";
import domain_list from "../Data/domain_list";

const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

class DashboardPublic extends Component {
  state = {
    search_params: "",
    search_params_final: "",
    modal_search: false,
  };

  componentDidMount() {
    const { domain } = this.props.auth;
    const function_list = [
      this.props.getBlogListPublic(domain),
      this.props.getBlogListPopular(),
      this.props.getBanner(),
      // this.props.get_tag_list(),
      this.props.getProjectListPublic(domain),
    ];
    let array = function_list;
    const delay_c = 0;
    const delay = () => new Promise((res) => setTimeout(res, delay_c));
    const parent_function = () => {
      return array.reduce(
        (last_promise, object) =>
          last_promise.then((result_sum) =>
            child_function(object).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (object) => {
      return delay().then(() => {
        return object;
      });
    };
    parent_function().then(() => {});

    const premium_status = domain_list?.[domain]?.status;
    if (premium_status === "premium") {
      history.push("/dashboard");
    }
  }

  handleChange = (e) => {
    this.setState({ modal_search: true });
    const target = e.target;
    const value = target.value;
    const name = target.name;
    clearTimeout(this.timer);
    this.setState({
      [name]: value,
    });
    this.timer = setTimeout(
      this.triggerChange.bind(this, value),
      WAIT_INTERVAL
    );
  };

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      const target = e.target;
      const value = target.value;
      this.triggerChange.bind(this, value);
    }
  }

  handleOnMouseDown(e) {
    e.preventDefault();
  }

  triggerChange(value) {
    this.props.search_blog(value);

    // const { search_params } = this.state;
    // this.setState({ search_params_final: search_params });
  }

  toggleModal = () => {
    this.setState({ modal_search: true });
  };

  closeSearch = () => {
    this.setState({ modal_search: false });
  };

  toggleSearch = () => {
    this.setState({ modal_search: !this.state.modal_search });
  };

  render() {
    // let { tag_list } = this.props.play;
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { search_params, search_params_final, modal_search } = this.state;
    const mode = this?.props?.match?.params?.mode
      ? this?.props?.match?.params?.mode
      : "";

    const { geo_project_list_public } = this.props.project;
    const { blog_list } = this.props.blog;
    let blog_list_filtered = blog_list;
    // let blog_list_result = [];

    if (mode === "publication_free") {
      blog_list_filtered = blog_list.filter((blog) =>
        ["public", "free"].includes(blog?.market_status.status)
      );
    } else if (mode === "publication_paid") {
      blog_list_filtered = blog_list.filter((blog) =>
        ["paid"].includes(blog?.market_status.status)
      );
    } else {
      blog_list_filtered = blog_list;
    }
    // let project_list_result = [];
    // if (search_params_final) {
    //   blog_list_result = blog_list_filtered.filter((blog) =>
    //     blog.title.toLowerCase().includes(search_params_final.toLowerCase())
    //   );
    //   project_list_result = geo_project_list_public.filter((project) =>
    //     project.name.toLowerCase().includes(search_params_final.toLowerCase())
    //   );
    // }
    let item_content = (
      <main>
        <Components.Public.BlogListPublic blog_list={blog_list_filtered} />
        <Components.Public.ProjectListPublic />
      </main>
    );
    if (["publication_free", "publication_paid"].includes(mode)) {
      item_content = (
        <main>
          <Components.Public.BlogListPublic blog_list={blog_list_filtered} />
        </main>
      );
    } else if (mode === "publication_project") {
      item_content = (
        <main>
          <Components.Public.ProjectListPublic
            geo_project_list_public={geo_project_list_public}
          />
        </main>
      );
    } else {
      item_content = (
        <main>
          <Components.Public.BlogListPublic blog_list={blog_list_filtered} />
          <Components.Public.ProjectListPublic
            geo_project_list_public={geo_project_list_public}
          />
        </main>
      );
    }
    let result_search_content;
    const result_search_project = (
      <main>
        {this.props.projects.length > 0 ? (
          <main>
            <h3>{dict["Public Project"][language]}</h3>
            {this.props.projects.map((blog) => {
              const { _id, name, link } = blog;
              return (
                <main
                  className="container_notification"
                  onMouseDown={this.handleOnMouseDown}
                  key={_id}
                >
                  <section>{name}</section>
                  <section style={{ textAlign: "right" }}>
                    <Link
                      className="button_very_small"
                      id="blue"
                      to={`/analyzer/${link}`}
                      style={{ margin: "3px" }}
                    >
                      {dict["3D Map"][language]}
                    </Link>
                    <Link
                      className="button_very_small"
                      id="blue"
                      to={`/view/${link}`}
                      style={{ margin: "3px" }}
                    >
                      {dict["Data Insight"][language]}
                    </Link>
                  </section>
                </main>
              );
            })}
          </main>
        ) : !search_params_final ? (
          <div />
        ) : (
          <div className="container_notification">
            {dict["Project not found"][language]}
          </div>
        )}
      </main>
    );
    const result_search_blog = (
      <main>
        {this.props.blogs.length > 0 ? (
          <main>
            <h3>{dict["Publication"][language]}</h3>
            {this.props.blogs.map((blog) => {
              const { _id, title, title_en, link } = blog;
              return (
                <Link to={`/blog_read/${link}`} key={_id}>
                  <div
                    className="container_notification"
                    onMouseDown={this.handleOnMouseDown}
                  >
                    {language === "ina" ? title : title_en || title}
                  </div>
                </Link>
              );
            })}
          </main>
        ) : !search_params_final ? (
          <div />
        ) : (
          <div className="container_notification">
            {dict["Publication not found"][language]}
          </div>
        )}
      </main>
    );
    let placeholder = dict["Search all publication"][language];
    if (mode === "publication_free") {
      placeholder = dict["Search free publication"][language];
      result_search_content = <div>{result_search_blog}</div>;
    } else if (mode === "publication_paid") {
      placeholder = dict["Search data exchange"][language];
      result_search_content = <div>{result_search_blog}</div>;
    } else if (mode === "publication_project") {
      placeholder = dict["Search public project"][language];
      result_search_content = <div>{result_search_project}</div>;
    } else {
      placeholder = dict["Search all publication"][language];
      result_search_content = (
        <div>
          {result_search_blog}
          {result_search_project}
        </div>
      );
    }
    const search_content = (
      <div
        className="input_rounded"
        style={{
          position: "absolute",
          width: "500px",
          maxWidth: "50vw",
          margin: "auto",
          zIndex: "2",
        }}
      >
        <div className="icon-text">
          <div>
            <img
              src={Assets.icon.icon_search}
              alt="search"
              height="16px"
              style={{ marginBottom: "5px" }}
            />
          </div>
          <div>
            <input
              className="form-rounded"
              type="text"
              placeholder={placeholder}
              autoComplete="off"
              name="search_params"
              value={search_params}
              onChange={this.handleChange.bind(this)}
              onKeyDown={this.handleKeyDown.bind(this)}
              onFocus={this.toggleModal}
              onBlur={this.closeSearch}
              style={{
                overflow: "hidden",
                boxSizing: "border-box",
                border: "none",
                backgroundColor: "transparent",
                fontSize: "15px",
                color: "#5e5e5e",
                textAlign: "left",
              }}
            />
          </div>
        </div>
        {modal_search && (
          <section
            style={{
              height: "600px",
              maxHeight: "60vh",
              overflowY: "auto",
              paddingBottom: "10px",
              zIndex: 99,
              backgroundColor: "#ffffff",
            }}
          >
            {result_search_content}
          </section>
        )}
      </div>
    );
    const mode_array = [
      {
        name: dict["All"][language],
        link: "",
      },
      {
        name: dict["Public"][language],
        link: "publication_free",
      },
      {
        name: dict["Premium"][language],
        link: "publication_paid",
      },
      {
        name: dict["Data"][language],
        link: "publication_project",
      },
      // {
      //   name: "Play",
      //   link: "play_list",
      // },
    ];
    return (
      <main
        id="main_container"
        style={{
          marginBottom: "200px",
        }}
      >
        <section
          style={{
            textAlign: "center",
            zIndex: "2",
            width: "100%",
          }}
        >
          <Components.Public.Carousel />
          <div
            style={{
              paddingTop: "20px",
              paddingBottom: "60px",
              marginBottom: "-60px",
            }}
          >
            {mode_array.map((e, idx) => {
              let css_id = "transparent";
              if (mode === e.link || (!e.link && (!mode || mode === "/"))) {
                css_id = "white";
              }
              let link = `/public/${e.link}`;
              if (e.link === "") {
                link = "/";
              } else if (e.link === "play_list") {
                link = "/play_list";
              }
              return (
                <Link
                  key={idx}
                  to={link}
                  className="button_standard"
                  id={css_id}
                  style={{ margin: "5px" }}
                >
                  {e.name}
                </Link>
              );
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "50px",
              marginTop: "10px",
            }}
          >
            {search_content}
          </div>
        </section>
        {/* <section className="text_center">
          <section className="text_bold use_margin">Trending hashtag</section>
          {
            <main className="carousell">
              {tag_list.map((t, idx) => {
                let { text } = t;
                text = text.substring(1);
                return (
                  <Link
                    to={`/play_list?tag=${text}`}
                    key={idx}
                    style={{
                      backgroundColor: "#000",
                      color: "#fff",
                      margin: "5px 0px 5px 13px",
                      padding: "5px 10px 5px 10px",
                      borderRadius: "5px",
                    }}
                  >
                    {text}
                  </Link>
                );
              })}
            </main>
          }
        </section> */}
        <section style={{ marginTop: modal_search ? "50px" : "50px" }}>
          {item_content}
        </section>
        <Helmet>
          <title>
            {dict["Location Intelligence Platform Indonesia"][language]}
          </title>
          <meta
            name="description"
            content={dict["Location Intelligence Platform Indonesia"][language]}
          />
        </Helmet>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    blog: state.blog,
    project: state.project,
    play: state.play,
    blogs: state.blog.blog_list_search,
    projects: state.blog.project_list_search,
  };
};

export default connect(mapStateToProps, {
  getBlogListPublic,
  getProjectListPublic,
  getBlogListPopular,
  changePrice,
  // get_tag_list,
  getBanner,
  search_blog,
})(DashboardPublic);
