/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Path from "./Path";
// import Modal from "../reuseable/Modal";
import ModalLayerCustomApi from "./ModalLayerCustomApi";

/*REDUX FUNCTION*/
import {
  create_layer_custom_api,
  request_by_client_api,
} from "../../App/actions/openApiActions";

/*PICTURE ASSET*/

/*GENERAL*/
import dict from "../../Data/dict.json";
import ApiTable from "./ApiTable";
import uuid from "../../App/validation/uuid";
import { get_nested_object } from "../../App/validation/nested_object";
import { getGeoLayer } from "../../App/reduxHelper/layer";

/*VARIABLES*/
const http_methods = [
  {
    key: "post",
    name: "POST",
    color: "#c8a000",
  },
  {
    key: "get",
    name: "GET",
    color: "#297e21",
  },
];

const geometry_types = [
  {
    key: "point",
    name: "Point",
  },
  // {
  //   key: "polygon",
  //   name: "Polygon",
  // },
  // {
  //   key: "linstring",
  //   name: "LineString",
  // },
];

class ModalApiInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layer_name: "",
      description: "",
      folder: "",
      modal_layer_name: false,

      api_endpoint: "",
      http_method: "get",
      geometry_type: "Point",
      headers: [],
      params: [],
      body: [],
      page: "Test",
      response: {},
      latitude_path: [],
      longitude_path: [],
      properties_path: [],
      features_path: [],
    };
  }

  componentDidMount = () => {
    const layer = this.props.layer;
    const geo_layer = getGeoLayer(layer);

    const headers = this.createRows(geo_layer?.headers) || [];
    const params = this.createRows(geo_layer?.params) || [];
    const body = this.createRows(geo_layer?.body) || [];

    this.setState({
      headers: [...headers, this.createRow("Headers")],
      params: [...params, this.createRow("Params")],
      body: [...body, this.createRow("Body")],
      api_endpoint: geo_layer?.api_endpoint || "",
      http_method: geo_layer?.http_method || "get",
      geometry_type: geo_layer?.geometry_type || "Point",
      response: {},
      latitude_path: geo_layer?.latitude_path || [],
      longitude_path: geo_layer?.longitude_path || [],
      properties_path: geo_layer?.properties_path || [],
      features_path: geo_layer?.features_path || [],
    });
  };

  handle_next = () => {
    const { modal_layer_name } = this.state;
    this.setState({
      modal_layer_name: !modal_layer_name,
    });
  };

  handle_folder = (e) => {
    this.setState({
      folder: e?.target?.value,
    });
  };

  handle_save = () => {
    const {
      layer_name,
      geometry_type,
      description,
      folder,
      api_endpoint,
      http_method,
      headers,
      params,
      body,
      latitude_path,
      longitude_path,
      properties_path,
      features_path,
    } = this.state;
    const { geo_project } = this.props.project;
    const body_request = {
      name: layer_name,
      description,
      type: geometry_type,
      folder,
      user_id: this.props.auth.user._id,
      geo_project_id: geo_project._id,
      is_custom_endpoint: true,

      api_endpoint,
      params: this.convert_to_api_attributes(
        params.slice(0, params?.length - 1)
      ),
      headers: this.convert_to_api_attributes(
        headers.slice(0, headers?.length - 1)
      ),
      body: this.convert_to_api_attributes(body.slice(0, body?.length - 1)),
      http_method,
      status: "original",
      architecture: "api",

      latitude_path,
      longitude_path,
      properties_path,
      features_path,
      is_show_and_fly: true,
    };
    this.props.create_layer_custom_api(body_request);
  };

  params_merge = () => {
    const { params } = this.state;
    const result = params
      .slice(0, params?.length - 1)
      .map((param, index) => this.convert_to_url(param, index));
    return result.join("");
  };

  convert_to_url = (param, index) => {
    if (index === 0) {
      return `${param?.key ? `?${param?.key}` : ""}${
        param?.value ? `=${param?.value}` : ""
      }`;
    } else {
      return `${param?.key ? `&${param?.key}` : ""}${
        param?.value ? `=${param?.value}` : ""
      }`;
    }
  };

  convert_to_api_attributes = (data) => {
    let result = {};
    data.forEach((item) => {
      result[item?.key] = item?.value;
    });
    return result;
  };

  send_request = async () => {
    const { http_method, api_endpoint, geometry_type, headers, body } =
      this.state;

    const response = await this.props.request_by_client_api({
      http_method,
      api_endpoint,
      geometry_type,
      headers: this.convert_to_api_attributes(
        headers.slice(0, headers?.length - 1)
      ),
      params: this.params_merge(),
      body: this.convert_to_api_attributes(body.slice(0, body?.length - 1)),
    });

    console.log(response);

    this.setState({
      response,
    });
  };

  createRow = () => {
    return { id: uuid(), key: "", value: "" };
  };

  createRows = (rows_object) => {
    const result = [];
    for (const key in rows_object) {
      result.push({
        id: uuid(),
        key: key,
        value: rows_object[key],
      });
    }
    return result;
  };

  handle_layer_name = (e) => {
    this.setState({
      layer_name: e?.target?.value,
    });
  };

  handle_description = (e) => {
    this.setState({
      description: e?.target?.value,
    });
  };

  handle_api_endpoint = (e) => {
    this.setState({
      api_endpoint: e?.target?.value,
    });
  };

  get_http_color = (method) => {
    const color =
      http_methods.find((item) => item.key === method)?.color || "#000000";
    return color;
  };

  set_http_method = (method) => {
    this.setState({
      http_method: method,
    });
  };

  set_page = (page) => {
    this.setState({
      page,
    });
  };

  set_geometry_type = (geometry) => {
    this.setState({
      geometry_type: geometry,
    });
  };

  delete_row = (id, type) => {
    const data = this.state?.[`${type?.toLowerCase()}`]?.filter(
      (header) => header?.id !== id
    );
    if (type === "Headers") {
      this.setState({
        headers: data,
      });
    } else if (type === "Params") {
      this.setState({
        params: data,
      });
    } else if (type === "Body") {
      this.setState({
        body: data,
      });
    }
  };

  edit_cell = (type, e) => {
    if (type === "Headers") {
      let headers = structuredClone(this.state.headers);
      const index = headers.findIndex((header) => header?.id === e?.id);
      headers[index][e.field] = e?.value;

      const last_header = headers?.[headers?.length - 1];
      if (last_header?.key !== "" || last_header?.value !== "") {
        headers.push(this.createRow("Headers"));
      }

      this.setState({
        headers: headers,
      });
    } else if (type === "Params") {
      let params = structuredClone(this.state.params);
      const index = params.findIndex((param) => param?.id === e?.id);
      params[index][e.field] = e?.value;

      const last_header = params?.[params?.length - 1];
      if (last_header?.key !== "" || last_header?.value !== "") {
        params.push(this.createRow("Params"));
      }

      this.setState({
        params: params,
      });
    } else if (type === "Body") {
      let body = structuredClone(this.state.body);
      const index = body.findIndex((item) => item?.id === e?.id);
      body[index][e.field] = e?.value;

      const last_header = body?.[body?.length - 1];
      if (last_header?.key !== "" || last_header?.value !== "") {
        body.push(this.createRow("Body"));
      }

      this.setState({
        body: body,
      });
    }
  };

  handle_add_path = (path_type, value) => {
    if (path_type === "features") {
      const { features_path } = this.state;
      this.setState({
        features_path: [...features_path, value],
      });
    } else if (path_type === "properties") {
      const { properties_path } = this.state;
      this.setState({
        properties_path: [...properties_path, value],
      });
    } else if (path_type === "longitude") {
      const { longitude_path } = this.state;
      this.setState({
        longitude_path: [...longitude_path, value],
      });
    } else if (path_type === "latitude") {
      const { latitude_path } = this.state;
      this.setState({
        latitude_path: [...latitude_path, value],
      });
    }
  };

  handle_delete_path = (path_type) => {
    if (path_type === "features") {
      const { features_path } = this.state;
      this.setState({
        features_path: features_path.slice(0, features_path.length - 1),
      });
    } else if (path_type === "properties") {
      const { properties_path } = this.state;
      this.setState({
        properties_path: properties_path.slice(0, properties_path.length - 1),
      });
    } else if (path_type === "longitude") {
      const { longitude_path } = this.state;
      this.setState({
        longitude_path: longitude_path.slice(0, longitude_path.length - 1),
      });
    } else if (path_type === "latitude") {
      const { latitude_path } = this.state;
      this.setState({
        latitude_path: latitude_path.slice(0, latitude_path.length - 1),
      });
    }
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const {
      http_method,
      geometry_type,
      page,
      headers,
      params,
      body,
      api_endpoint,
      layer_name,
      description,
      response,
      latitude_path,
      longitude_path,
      properties_path,
      features_path,
      modal_layer_name,
      folder,
    } = this.state;

    //global props
    const folders = this.props.project?.geo_project?.folders;

    //content

    const selected_features_path =
      get_nested_object(response?.data || {}, features_path) || {};

    return (
      <main>
        {!modal_layer_name ? (
          <>
            <section className="flex gap_40"></section>
            <section className="flex gap_5">
              <select
                style={{
                  color: this.get_http_color(http_method),
                }}
                value={http_method}
                onChange={(e) => this.set_http_method(e.target.value)}
                className="w_100"
              >
                {http_methods.map((method) => (
                  <option
                    style={{ color: method.color }}
                    key={method.key}
                    value={method.key}
                  >
                    {method.name}
                  </option>
                ))}
              </select>
              <input
                type="text"
                value={api_endpoint}
                onChange={this.handle_api_endpoint}
                placeholder={dict["Enter URL"][language]}
              />
              <div
                onClick={() => {
                  this.send_request();
                }}
                className="pointer bg_blue text_white button_inactive padding_5 rounded_5 font_18 w_100 h_40"
              >
                {`Send >`}
              </div>
            </section>

            <section className="flex justify_between marginTop_10 marginBottom_20 h_30">
              <div className="flex gap_10">
                <div
                  className={`pointer ${page === "Headers" ? "underline" : ""}`}
                  onClick={() => this.set_page("Headers")}
                >
                  Headers
                </div>
                <div
                  className={`pointer ${page === "Params" ? "underline" : ""}`}
                  onClick={() => this.set_page("Params")}
                >
                  Params
                </div>
                <div
                  className={`pointer ${page === "Body" ? "underline" : ""}`}
                  onClick={() => this.set_page("Body")}
                >
                  Body
                </div>
                <div
                  className={`pointer ${page === "Test" ? "underline" : ""}`}
                  onClick={() => this.set_page("Test")}
                >
                  Request Test
                </div>
              </div>
            </section>

            {page === "Headers" && (
              <section className="marginBottom_10">
                <ApiTable
                  title="Headers"
                  data={headers}
                  edit_cell={this.edit_cell}
                  delete_row={this.delete_row}
                />
              </section>
            )}
            {page === "Params" && (
              <section className="marginBottom_10">
                <ApiTable
                  title="Params"
                  full_url={`${api_endpoint}${this.params_merge()}`}
                  data={params}
                  edit_cell={this.edit_cell}
                  delete_row={this.delete_row}
                />
              </section>
            )}
            {page === "Body" && (
              <section className="marginBottom_10">
                <ApiTable
                  title="Body"
                  data={body}
                  edit_cell={this.edit_cell}
                  delete_row={this.delete_row}
                />
              </section>
            )}

            {page === "Test" && (
              <>
                <section className="marginTop_20 marginBottom_10">
                  <div className="flex justify_between">
                    <label>Response</label>
                    <label>Status: {response.status}</label>
                  </div>
                  <div className="padding_10 pre h_300 border_grey rounded_5 scroll_y_auto">
                    {JSON.stringify(response.data, null, 2)}
                  </div>
                </section>

                <section className="flex gap_10 w_full">
                  <div className="w_80_percent">
                    {response?.status === 200 && (
                      <Path
                        handle_delete_path={this.handle_delete_path}
                        handle_add_path={this.handle_add_path}
                        path={features_path}
                        object={response.data}
                        title="Features"
                      />
                    )}
                  </div>

                  <div className="w_20_percent flex align_end gap_10">
                    <div className="flex justify_end align_center text_right h_50">
                      <label className="text_right">
                        {dict["Geometry Type"][language]}
                      </label>
                    </div>
                    <select
                      value={geometry_type}
                      onChange={(e) => this.set_geometry_type(e.target.value)}
                      className="w_100"
                    >
                      {geometry_types.map((method) => (
                        <option key={method.key} value={method.name}>
                          {method.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </section>

                {Array.isArray(selected_features_path) && (
                  <section className="marginBottom_10 min_h_75 max_h_200 scroll_y_auto border_grey padding_5 rounded_5">
                    <div className="">
                      <div className="flex">
                        <label className="w_20 center_perfect">{">"}</label>
                        <Path
                          handle_delete_path={this.handle_delete_path}
                          handle_add_path={this.handle_add_path}
                          path={properties_path}
                          object={selected_features_path?.[0] || {}}
                          title="Properties"
                        />
                      </div>
                      <div className="flex">
                        <label className="w_20 center_perfect">{">"}</label>
                        <Path
                          handle_delete_path={this.handle_delete_path}
                          handle_add_path={this.handle_add_path}
                          path={latitude_path}
                          object={selected_features_path?.[0] || {}}
                          title="Latitude"
                        />
                      </div>
                      <div className="flex">
                        <label className="w_20 center_perfect">{">"}</label>
                        <Path
                          handle_delete_path={this.handle_delete_path}
                          handle_add_path={this.handle_add_path}
                          path={longitude_path}
                          object={selected_features_path?.[0] || {}}
                          title="Longitude"
                        />
                      </div>
                    </div>
                  </section>
                )}
              </>
            )}
            {response?.status === 200 && (
              <div className="flex justify_end marginTop_10">
                <div
                  onClick={() => this.handle_next()}
                  className="pointer bg_blue text_white button_inactive padding_5 rounded_5 font_18"
                >
                  Next
                </div>
              </div>
            )}
          </>
        ) : (
          <ModalLayerCustomApi
            handle_folder={this.handle_folder}
            folder={folder}
            folders={folders}
            layer_name={layer_name}
            handle_layer_name={this.handle_layer_name}
            description={description}
            handle_description={this.handle_description}
            handle_back={this.handle_next}
            handle_save={this.handle_save}
          />
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  request_by_client_api,
  create_layer_custom_api,
})(ModalApiInput);
