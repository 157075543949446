/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import MapGL, {
  Image,
  GeolocateControl,
  NavigationControl,
  AttributionControl,
} from "@urbica/react-map-gl";
import { bbox, buffer } from "@turf/turf";
import Draw from "@urbica/react-map-gl-draw";
import randomcolor from "randomcolor";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";

/*PERSONAL COMPONENT*/
import { RenderPopup } from "./Popup";
import RenderPopupEditor from "./RenderPopupEditor";
import STATUS_GET_GENANGAN from "./STATUS_GET_GENANGAN";
import TOOLBOX_DRAWING from "../map_drawing/TOOLBOX_DRAWING";
import TOOLBOX_LAYER from "./TOOLBOX_LAYER";
import LayerBasic from "./LayerBasic";
import LayerSini from "./LayerSini";
import LayerPointSini from "./LayerPointSini";
import LayerInsight from "./LayerInsight";
import LAYER_GENANGAN from "./LAYER_GENANGAN";

/*REDUX FUNCTION*/
import {
  pushFeature,
  pushFeatureV2,
  deleteFeature,
  editGeometry,
  editGeometryV2,
} from "../../App/actions/layerActions";
import {
  setDrawMode,
  addToolboxFeature,
  pickStatus,
} from "../../App/actions/layerNewActions";
import { push_sini_list, setRequestId } from "../../App/actions/siniActions";
import { set_map } from "../../App/actions/mapActions";
import { getSDATelemetry } from "../../App/actions/iotActions";
import { set_value_properties } from "../../App/actions/propertiesActions";
import { status_action } from "../../App/actions/mapActions";

/*PICTURE ASSET*/
import * as Assets from "../../Assets";
import { icon_map } from "../../Assets";

/*GENERAL*/
import uuid from "../../App/validation/uuid";
import isEmpty from "../../App/validation/is-empty";
import { drawStyle } from "./DrawStyle";
import { gometry_to_area_or_length } from "../../App/validation/geometry_to_area_or_length";

/*NON IMPORT*/
let drawingshape;
const MAPID_COLOR1 = "#37A0F7";

class Map extends Component {
  constructor(props) {
    super(props);
    this._map = React.createRef();
    this._draw = React.createRef();
    this.geocoderContainerRef = React.createRef();
    this.state = {
      //map
      viewport: {
        latitude: -0.811123, // -0.435161, 118.009574 tengah
        longitude: 113.921327,
        zoom: 4,
      },
      drag: {
        cliked: false,
        screenX: 10,
        screenY: 10,
      },
      mapMode: "street",
      geojson: { type: "FeatureCollection", features: [] },

      selectedFeatures: [],

      //geojson draw
      data: {
        type: "FeatureCollection",
        features: [],
      },

      //sini
      mode: "simple_select",
      id_active: "",
      renderSini: [],
      pickCoord: [0, 0],
    };
    this.add_feature = this.add_feature.bind(this);
  }

  componentDidMount() {
    const map = this._map.current.getMap();
    const draw = this._draw.current;

    map.once("load", () => {
      let isDrawing = (md) => {
        return (
          md === "draw_polygon" ||
          md === "draw_line_string" ||
          md === "draw_point"
        );
      };
      /**
       * Handle event mode drawing berubah : Geser objek,nambah titik dsb
       * Direct select : Sudah milih
       * Simple select :
       * */
      map.on("draw.modechange", (event) => {
        if (
          event.mode === "direct_select" &&
          isEmpty(this.state.data.features)
        ) {
          draw.mode = "simple_select";
        }
        try {
          if (isDrawing(event.mode)) {
            let el =
              event.mode === "draw_point"
                ? document.getElementsByClassName(
                    "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_point active"
                  )
                : event.mode === "draw_line_string"
                ? document.getElementsByClassName(
                    "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_line active"
                  )
                : document.getElementsByClassName(
                    "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon active"
                  );
            el[0].style.backgroundColor = MAPID_COLOR1;
            drawingshape = event.mode;
          } else {
            let el =
              drawingshape === "draw_point"
                ? document.getElementsByClassName(
                    "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_point"
                  )
                : drawingshape === "draw_line_string"
                ? document.getElementsByClassName(
                    "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_line"
                  )
                : document.getElementsByClassName(
                    "mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon"
                  );
            el[0].style.backgroundColor = "transparent";
          }
        } catch (e) {}
      });
      /*Handle event delete objek di peta : Geser objek,nambah titik dsb */
      map.on("draw.update", (event) => {
        let feature = event.features[0];
        const body = {
          feature_key: feature.properties.key,
          geo_layer_id: this.props.layer.layer_id,
          geometry: feature.geometry,
        };
        const { layer_id, geo_layer_list } = this.props.layer;
        const layer = geo_layer_list.find((l) => l.geo_layer._id === layer_id);
        if (layer?.geo_layer?.architecture === "v2") {
          this.props.editGeometryV2(body);
        } else {
          this.props.editGeometry(body);
        }
      });
      /*Handle event delete objek di peta */
      map.on("draw.delete", (event) => {
        let feature = event.features[0];
        const body = {
          feature_key: feature.properties.key,
          geo_layer_id: this.props.layer.layer_id,
        };
        this.props.deleteFeature(body);
      });
      this.props.set_map(this._map.current.getMap());
    });
    const { pick_status, toolbox_mode } = this.props.layer;
    if (pick_status || toolbox_mode === "buffer_point") {
      this.setState({ mode: "draw_point" });
    } else if (toolbox_mode === "distance" || toolbox_mode === "elevation") {
      this.setState({ mode: "draw_line_string" });
    } else if (toolbox_mode === "area") {
      this.setState({ mode: "draw_polygon" });
    } else {
      this.setState({ mode: "simple_select" });
      this.props.setDrawMode("simple_select");
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { layer_id, geo_layer_list } = nextProps.layer;
    const layer = geo_layer_list?.find((l) => l.geo_layer?._id === layer_id);
    const typeLayer = layer?.geo_layer?.type;
    return {
      typeLayer,
    };
  }

  componentDidUpdate(prevProps) {
    const { pick_status, toolbox_mode } = this.props.layer;
    // Draw
    const geometryStatus_after = this.props.layer.geometryStatus;
    const geometryStatus_before = prevProps.layer.geometryStatus;
    // Sini
    const featueSiniBefore = this.props.layer.pick_status;
    const featueSiniAfter = prevProps.layer.pick_status;
    // Toolbox
    const toolbox_modeBefore = this.props.layer.toolbox_mode;
    const toolbox_modeAfter = prevProps.layer.toolbox_mode;
    // Drawmode
    const draw_mode_before = this.props.layer.draw_mode;
    const draw_mode_after = prevProps.layer.draw_mode;
    if (geometryStatus_after !== geometryStatus_before) {
      const { layer_id, geo_layer_list } = this.props.layer;
      const layer = geo_layer_list.find((l) => l.geo_layer._id === layer_id);
      const geojson = layer?.geo_layer?.geojson
        ? layer?.geo_layer?.geojson
        : { type: "FeatureCollection", features: [] };
      this.setState({ geojson });
    }
    if (
      featueSiniBefore !== featueSiniAfter ||
      toolbox_modeBefore !== toolbox_modeAfter ||
      geometryStatus_before !== geometryStatus_after ||
      draw_mode_before !== draw_mode_after
    ) {
      if (pick_status || toolbox_mode === "buffer_point") {
        this.setState({ mode: "draw_point" });
      } else if (toolbox_mode === "distance" || toolbox_mode === "elevation") {
        this.setState({ mode: "draw_line_string" });
      } else if (toolbox_mode === "area") {
        this.setState({ mode: "draw_polygon" });
      } else {
        this.setState({ mode: "simple_select" });
        this.props.setDrawMode("simple_select");
      }
    }
  }

  //Buat nampilin popup ketika salah satu point || line || polygon diklik
  layerOnClick = (event) => {
    const { geometryStatus } = this.props.layer;
    if (!geometryStatus) {
      this.props.set_value_properties({
        key: "popupInfo",
        value: null,
      });
      this.props.set_value_properties({
        key: "modal_pop_up_layer",
        value: true,
      });
      this.generatePopup(event);
    }
  };

  generatePopup = (event) => {
    const { geo_layer_list } = this.props.layer;
    const { from } = this.props;
    const { map } = this.props?.map;

    const layer_id_popup = event?.features?.[0]?.layer?.id;

    // mencari data layer dari id layer yang dipilih
    let layer_popup = geo_layer_list.find(
      (e) => e.geo_layer._id === layer_id_popup
    );

    // mengambil data id dari layer yang dipilih
    let dataPopUP = event?.features?.[0]?.properties;

    if (layer_popup !== undefined) {
      // menyisipkan geometry
      dataPopUP["_geometry"] = gometry_to_area_or_length(
        layer_popup.geo_layer?.geojson?.features?.find(
          (feature) => feature.properties.key === dataPopUP.key
        ).geometry
      );

      this.props.set_value_properties({
        key: "feature_key_selected",
        value: event?.features?.[0]?.properties?.key,
      });

      // mengambil data fields dari layer yang dipilih
      let fields = layer_popup.geo_layer.fields;
      const type_2 = layer_popup.geo_layer.type_2;
      const type = layer_popup.geo_layer.type;
      const features = layer_popup?.geo_layer?.geojson?.features || [];

      //handle untuk titik masukan long lat ke dalam properties supaya bisa diakses lewat pop up, karena long lat di event ketika klik peta bisa jadi beda dengan long lat milik feature (hanya untuk titik)

      let geometry = event?.features[0]?.geometry;
      if (type === "Point") {
        const feature = features.find((item) => item.key === dataPopUP.key);
        geometry = feature.geometry;
      }

      const genangan_cm =
        event?.features[0]?.properties?.Ketinggian_Genangan_cm;

      // Fly To
      const geojson = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: geometry,
          },
        ],
      };

      let [minLng, minLat, maxLng, maxLat] = bbox(geojson);
      const padding = { top: 150, bottom: 300, left: 400, right: 400 };

      if (map) {
        map?.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          {
            padding: padding,
            maxZoom: 18,
          }
        );
      }

      //menggunakan nilai center supaya ketika yang diklik poligon, pop upnya muncul di tengah poligon
      const center_lat = (maxLat + minLat) / 2;
      const center_long = (maxLng + minLng) / 2;

      // menerapkan popup pada mapbox untuk ditampilkan berdasarkan layer yang dipilih
      let popupInfo = (
        <RenderPopupEditor
          lat={center_lat}
          long={center_long}
          fields={fields}
          dataPopUP={dataPopUP}
          type_2={type_2}
          feature_key={dataPopUP.key}
          genangan_cm={genangan_cm}
          geo_layer={layer_popup?.geo_layer}
          is_editable={from === "editor"}
        />
      );
      this.props.set_value_properties({
        key: "popupInfo",
        value: popupInfo,
      });
    } else {
      let array_sini = [];
      for (const key in dataPopUP) {
        array_sini.push(key);
      }
      const popup_sini_value = array_sini.map((d, index) => (
        <div className="valueNewPopup" key={index}>
          <p>{d}</p>
          <h3>{dataPopUP[d]}</h3>
        </div>
      ));

      let latitude = event.lngLat["lat"];
      let longitude = event.lngLat["lng"];

      // menerapkan popup pada mapbox untuk ditampilkan berdasarkan layer yang dipilih
      let popupInfo = (
        <RenderPopup lat={latitude} long={longitude} value={popup_sini_value} />
      );
      this.props.set_value_properties({
        key: "popupInfo",
        value: popupInfo,
      });

      // Fly To
      const geojson = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              coordinates: [longitude, latitude],
              type: "Point",
            },
          },
        ],
      };
      let [minLng, minLat, maxLng, maxLat] = bbox(geojson);
      if (map) {
        map?.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          {
            padding: {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            },
            maxZoom: 18,
          }
        );
      }
    }

    this.props.status_action();
  };

  setOnChange(data) {
    try {
      this.setState({ data: data });
    } catch (e) {}
  }

  // Add Features sini & toolbox & draw
  async add_feature(data) {
    const { geometryStatus, pick_status, toolbox_mode, draw_mode } =
      this.props.layer;
    const { map } = this.props?.map;
    if (geometryStatus) {
      // DRAW
      const length = data.features.length - 1;
      const prop =
        this.state.typeLayer === "Point"
          ? {
              icon_image: "museum",
              text_field: "icon_image",
              circle_color: randomcolor(),
              circle_radius: 5,
              circle_stroke_width: 1,
              circle_stroke_color: "#fff",
            }
          : this.state.typeLayer === "Polygon" ||
            this.state.typeLayer === "MultiPolygon"
          ? {
              color: randomcolor(),
              opacity: 0.5,
              outline_color: "#fff",
            }
          : {
              color: randomcolor(),
              width: 2,
              line_width: 1.25,
              opacity: 0.5,
              line_gap_width: 0,
            };
      let feature = {
        key: uuid(),
        type: "Feature",
        properties: prop,
        geometry: data.features[length].geometry,
        isHide: false,
        formStatus: { status: "digitasi" },
      };
      const body = {
        geo_layer_id: this.props.layer.layer_id,
        feature,
      };
      this.setState({ mode: "simple_select" });
      const { layer_id, geo_layer_list } = this.props.layer;
      const layer = geo_layer_list.find((l) => l.geo_layer._id === layer_id);
      if (layer?.geo_layer?.architecture === "v2") {
        this.props.pushFeatureV2(body);
      } else {
        this.props.pushFeature(body);
      }
    } else {
      if (pick_status) {
        // SINI
        // mengirim data dari sini yang dicari
        const body = {
          request_id: uuid(),
          long: data.features[0].geometry.coordinates[0],
          lat: data.features[0].geometry.coordinates[1],
          km_rad: 1,
        };
        const feature = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {
                color: "#56549A",
              },
              geometry: {
                coordinates: [
                  data.features[0].geometry.coordinates[0],
                  data.features[0].geometry.coordinates[1],
                ],
                type: "Point",
              },
            },
          ],
        };
        const [minLng, minLat, maxLng, maxLat] = bbox(feature);
        if (map) {
          let padding = { top: 100, bottom: 100, left: 500, right: 500 };
          if (window.innerWidth < 1172) {
            padding = {
              top: 50,
              bottom: 400,
              left: 10,
              right: 10,
            };
          }
          map?.fitBounds(
            [
              [minLng, minLat],
              [maxLng, maxLat],
            ],
            {
              padding,
              maxZoom: 18,
            }
          );
        }
        this.props.push_sini_list(body);
        return;
      } else {
        if (toolbox_mode) {
          // TOOLBOX
          this.props.setDrawMode("simple_select");
          // mendapatkan long,lat dari radius buffer
          let src_buffer = buffer(data.features[0].geometry, 500, {
            units: "kilometers",
          });
          // data yang dikirim dari buffer yang dibuat
          const bodyBuffer = {
            buffer_point: 1,
            geometry: data.features[0].geometry,
            key: uuid(),
            type: "Feature",
            properties: {},
            isHide: false,
            polygon_buffer: src_buffer,
          };
          // data yang dikirim buat selain buffer yang dibuat
          const body = {
            geometry: data.features[0].geometry,
            key: uuid(),
            type: "Feature",
            properties: {},
            isHide: false,
          };
          if (draw_mode === "draw_point") {
            this.props.addToolboxFeature(toolbox_mode, bodyBuffer);
          } else {
            this.props.addToolboxFeature(toolbox_mode, body);
          }
          this.setState({ mode: "simple_select" });
        }
      }
    }
  }

  // Merender Icon
  generateMapIcon() {
    const listIcon = Object.keys(icon_map).map((key) => (
      <Image key={key} id={key} image={icon_map[key]} />
    ));
    return listIcon;
  }

  render() {
    //local storage

    //local state
    const { geojson, typeLayer } = this.state;

    //global props
    const { geometryStatus, pick_status, draw_mode } = this.props.layer;
    const { basemap_api } = this.props.map;
    const { popupInfo, modal_pop_up_layer } = this.props.properties;
    const { draw_toolbox_status } = this.props.toolbox;

    return (
      <main
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          height: "100vh",
          width: "100vw",
        }}
      >
        <MapGL
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
          mapStyle={basemap_api}
          accessToken={
            "pk.eyJ1IjoibWFwaWQiLCJhIjoiY2pqNWtnaW10MGNnMjNrcWg5MHQwY21nNiJ9.voWwMqU73TCDDua3mGCb8g"
          }
          latitude={this.state.viewport.latitude}
          longitude={this.state.viewport.longitude}
          zoom={this.state.viewport.zoom}
          maxZoom={20}
          minZoom={2}
          onViewportChange={(e) => {
            return;
          }}
          attributionControl={false}
          {...this.props}
          ref={this._map}
        >
          {modal_pop_up_layer && popupInfo}
          <Image id="cerah" image={Assets.icon.iconCerah} />
          <Image id="ringan" image={Assets.icon.iconRingan} />
          <Image id="sedang" image={Assets.icon.iconRingan} />
          <Image id="lebat" image={Assets.icon.iconLebat} />
          <Image id="sangatlebat" image={Assets.icon.iconLebat} />
          <Image id="normal" image={Assets.icon.iconCerah} />
          <Image id="Offline" image={Assets.icon.offline} />
          {/* {this.generateMapIcon()} */}
          {pick_status === true ||
          draw_mode !== "simple_select" ||
          geometryStatus === true ? (
            <Draw
              data={geometryStatus === true ? geojson : null}
              mode={this.state.mode}
              onDrawCreate={(data) => {
                this.add_feature(data);
              }}
              onChange={(e) => {
                this.setOnChange(e);
              }}
              onDrawModeChange={(event) => {}}
              combineFeaturesControl={false}
              uncombineFeaturesControl={false}
              pointControl={typeLayer === "Point" ? true : false}
              lineStringControl={typeLayer === "LineString" ? true : false}
              polygonControl={typeLayer === "Polygon" ? true : false}
              trashControl={geometryStatus === true ? true : false}
              styles={drawStyle}
            />
          ) : null}
          {draw_toolbox_status && <TOOLBOX_DRAWING />}

          <LAYER_GENANGAN layerOnClick={this.layerOnClick} />
          <LayerPointSini layerOnClick={this.layerOnClick} />
          <LayerSini layerOnClick={this.layerOnClick} />
          <LayerInsight layerOnClick={this.layerOnClick} />
          <TOOLBOX_LAYER />
          <LayerBasic layerOnClick={this.layerOnClick} />

          <NavigationControl showCompass showZoom position="top-right" />
          <GeolocateControl position="top-right" />
          <AttributionControl
            position="bottom-right"
            customAttribution={`Geomapid V ${this.props.auth.version} | <a href="https://mapid.io/">© MAPID</a> | © MapBox`}
          />
        </MapGL>
        <STATUS_GET_GENANGAN />
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  map: state.map,
  auth: state.auth,
  properties: state.properties,
  toolbox: state.toolbox,
});

export default connect(mapStateToProps, {
  setRequestId,
  set_map,
  getSDATelemetry,
  setDrawMode,
  addToolboxFeature,
  pushFeature,
  pushFeatureV2,
  editGeometry,
  editGeometryV2,
  deleteFeature,
  push_sini_list,
  pickStatus,
  set_value_properties,
  status_action,
})(Map);
