const get_groups_project_org = (groups_project, groups_org) => {
  const groups_org_id = groups_org.map((item) => item._id);
  groups_project = groups_project.map((item) => {
    let is_has_license = false;
    if (groups_org_id.includes(item._id)) is_has_license = true;
    item.is_has_license = is_has_license;
    return item;
  });
  const groups_project_org = groups_project.filter(
    (item) => item.is_has_license
  );
  return groups_project_org;
};
export default get_groups_project_org;
