/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class TOOLBOX_DETAIL_GROUPING extends Component {
  state = {};

  render() {
    //local storage

    //local state

    //global props

    //content
    return (
      <main className="container_light margin_bottom">
        <p className="text_bold">Grouping</p>
        <p className="text_small">
          Grouping an layer by nearest point in other layer
        </p>
        <section className="container_light background_white">
          <p className="text_small">Select layer center</p>
          <input />
          <br />
          <p className="text_small">Select layer to grouping</p>
          <input />
          <br />
          <br />
          <button className="button">Start grouping</button>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(TOOLBOX_DETAIL_GROUPING);
