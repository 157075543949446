import sort_array from "../validation/sort_array";

const initialState = {
  loadingProcess: false,
  loadingDetail: false,
  loadingList: false,
  loading_status: false,
  loading_item: "",

  payment_list: [],
  payment: {},

  license: {},
  license_list: [],
  license_list_active: [],

  bankList: [],
  specialist_avail: false,
  specialist_code: "",
  specialist_id: "",
  price_list: [],
};

export default function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case "get_price_2023":
      return {
        ...state,
        price_list: action.payload,
      };

    case "set_loading_payment":
      return {
        ...state,
        loading_status: true,
        loading_item: action.payload,
      };
    case "clear_loading":
      return {
        ...state,
        loading_status: false,
        loading_item: "",
      };
    case "SET_LOADING_PROCESS_PAYMENT":
      return {
        ...state,
        loadingProcess: true,
      };
    case "SET_LOADING_DETAIL_PAYMENT":
      return {
        ...state,
        loadingDetail: true,
      };
    case "SET_LOADING_LIST_PAYMENT":
      return {
        ...state,
        loadingList: true,
      };
    case "CLEAR_LOADING_PAYMENT":
      return {
        ...state,
        loadingProcess: false,
        loadingDetail: false,
        loadingList: false,
      };
    case "create_payment":
      return {
        ...state,
        payment_list: [action.payload, ...state.payment_list],
        license: get_last_license([action.payload, ...state.payment_list]),
      };
    case "ACTION_PAYMENT":
      return {
        ...state,
        payment_list: editFunction(state.payment_list, action.payload),
      };
    case "SURVEY_ACTION": //harus lewat geo layer
      return {
        ...state,
      };
    case "GET_USER_PAYMENT_LIST":
      return {
        ...state,
        payment_list: action.payload,
        license: get_last_license(action.payload),
        license_list: get_license_list(action.payload),
        license_list_active: get_license_list_active(action.payload),
      };
    case "GET_USER_PAYMENT_DETAIL":
      return {
        ...state,
        payment: action.payload,
      };
    case "ADD_BANK":
      return {
        ...state,
        bankList: [action.payload, ...state.bankList],
      };
    case "GET_USER_BANK_LIST":
      return {
        ...state,
        bankList: action.payload,
      };
    case "CHECK_SPECIALIST":
      return {
        ...state,
        specialist_avail: action.payload ? true : false,
        specialist_id: action.payload ? action.payload._id : "",
      };
    case "SET_SPECIALIST_CODE":
      return {
        ...state,
        specialist_code: action.payload,
        specialist_avail: true,
      };

    case "clear_payment_object": {
      return {
        ...state,
        payment: {},
      };
    }

    case "CLEAR_PAYMENT": {
      return {
        ...state,
        loadingProcess: false,
        loadingDetail: false,
        loadingList: false,
        payment_list: [],
        payment: {},
        license: {},
        bankList: [],
        specialist_avail: false,
        specialist_code: "",
      };
    }
    default:
      return state;
  }
}

const editFunction = (state, newItem) => {
  const newState = state.slice();
  //get index
  const index = newState.findIndex((element) => element._id === newItem._id);
  //replace item
  newState.splice(index, 1, newItem);
  return newState;
};

const get_last_license = (payment_list) => {
  payment_list = payment_list.filter((item) => item.date_user_action); //hanya mengambil payment yang memiliki date_user_action
  //get date convert to unix
  let payment_list_unix = payment_list.map((payment, idx) => {
    const date_user_action = payment?.date_user_action;
    const multiple_month = payment.multiple_month ? payment.multiple_month : 1;
    //86400 unix = 1 day
    //1 month = 30.5 day
    const month_unix = 86400 * 30.5;
    const day_unix = 86400;
    const date_finish_unix =
      parseInt((new Date(date_user_action).getTime() / 1000).toFixed(0)) +
      month_unix * multiple_month;
    const date_now_unix = parseInt(
      (new Date(Date.now()).getTime() / 1000).toFixed(0)
    );
    const delta_unix = parseInt(
      (date_finish_unix - date_now_unix) * multiple_month
    );
    const delta_day = parseInt(delta_unix / day_unix);
    const date_finish = new Date(date_finish_unix * 1000);
    let payment_edited = {
      ...payment,
      delta_unix,
      delta_day,
      date_finish,
    };
    return payment_edited;
  });

  payment_list_unix = sort_array(payment_list_unix, "delta_unix", true);

  //filter only license payment;
  const license_list = payment_list_unix.filter(
    (payment) =>
      // payment.payment_type === "license_1" ||
      // payment.payment_type === "license_2" || //sekarang hanya menerima license_3 enterprise, hanya satu pricing

      payment.payment_type === "license_3" &&
      // payment.status === "user_purchase" ||
      payment.status === "success"
  );
  const license = license_list[license_list?.length - 1] || {};

  return license;
};

const get_license_list = (payment_list) => {
  payment_list = payment_list.filter((item) => item.date_user_action);
  //get date convert to unix
  let payment_list_unix = payment_list.map((payment) => {
    const date_user_action = payment?.date_user_action;
    const multiple_month = payment?.multiple_month || 1;
    //86400 unix = 1 day
    //1 month = 30.5 day
    const month_unix = 86400 * 30.5;
    const day_unix = 86400;
    const date_finish_unix =
      parseInt((new Date(date_user_action).getTime() / 1000).toFixed(0)) +
      month_unix * multiple_month;
    const date_now_unix = parseInt(
      (new Date(Date.now()).getTime() / 1000).toFixed(0)
    );
    const delta_unix = (date_finish_unix - date_now_unix) * multiple_month;
    const delta_day = parseInt(delta_unix / day_unix);
    const date_finish = new Date(date_finish_unix * 1000);
    let payment_edited = {
      ...payment,
      delta_unix,
      delta_day,
      date_finish,
    };
    return payment_edited;
  });

  payment_list_unix = sort_array(payment_list_unix, "delta_unix", true);

  //filter only license payment;
  let license_list = payment_list_unix.filter(
    (payment) =>
      // payment.payment_type === "license_1" ||
      // payment.payment_type === "license_2" || //sekarang hanya menerima license_3 enterprise, hanya satu pricing
      payment.payment_type === "license_3" &&
      // payment.status === "user_purchase" ||
      payment.status === "success"
  );
  return license_list;
};

const get_license_list_active = (payment_list) => {
  payment_list = payment_list.filter((item) => item.date_user_action); //hanya mengambil payment yang memiliki
  //get date convert to unix
  let payment_list_unix = payment_list.map((payment) => {
    const date_user_action = payment?.date_user_action;
    const multiple_month = payment?.multiple_month || 1;
    //86400 unix = 1 day
    //1 month = 30.5 day
    const month_unix = 86400 * 30.5;
    const day_unix = 86400;
    const date_finish_unix =
      parseInt((new Date(date_user_action).getTime() / 1000).toFixed(0)) +
      month_unix * multiple_month;
    const date_now_unix = parseInt(
      (new Date(Date.now()).getTime() / 1000).toFixed(0)
    );
    const delta_unix = (date_finish_unix - date_now_unix) * multiple_month;
    const delta_day = parseInt(delta_unix / day_unix);
    const date_finish = new Date(date_finish_unix * 1000);
    let payment_edited = {
      ...payment,
      delta_unix,
      delta_day,
      date_finish,
    };
    return payment_edited;
  });

  payment_list_unix = sort_array(payment_list_unix, "delta_unix", true);
  //filter only license payment;
  let license_list = payment_list_unix.filter(
    (payment) =>
      // payment.payment_type === "license_1" ||
      // payment.payment_type === "license_2" || //sekarang hanya menerima license_3 enterprise, hanya satu pricing
      payment.payment_type === "license_3" &&
      // payment.status === "user_purchase" ||
      payment.status === "success" &&
      payment.delta_unix > 0
  );
  return license_list;
};
