/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
// import SelectDefaultLayersNavbarItem from "./SelectDefaultLayersNavbarItem";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { getProjectDetailByLink } from "../../App/actions/projectActions";
import { set_value_project } from "../../App/actions/projectActions";
// import SelectDefaultLayersList from "./SelectDefaultLayersList";

/*PICTURE ASSET*/
import icon_search from "../../Assets/svg/icon_search_new.svg";

/*GENERAL*/
// import dict from "../../Data/dict.json";

class SelectDefaultLayers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_layer: "All",
      keywords: "",
      filtered_geo_layer: [],
    };
  }

  componentDidMount = async () => {
    const { geo_project } = this.props.project;
    const geo_project_link = geo_project?.link;
    if (this.props.mode !== "editor") {
      await this.props.getProjectDetailByLink(geo_project_link);
    }
    this.setState({
      filtered_geo_layer: this.props.layer?.geo_layer_list || [],
    });
    const layer_list_load_first = geo_project?.layer_list_load_first?.filter(
      (item) => item !== ""
    );
    this.props.set_value_project({
      key: "layer_list_load_first",
      value: layer_list_load_first,
    });
  };

  toggle_layer = (geo_layer_id) => {
    let { layer_list_load_first } = this.props.project;
    if (layer_list_load_first.includes(geo_layer_id)) {
      //jika item sudah ada di dalam array, maka keluarkan dengan cara filter
      layer_list_load_first = layer_list_load_first.filter(
        (item) => item !== geo_layer_id
      );
    } else {
      //jika item belum ad di dalam array, maka masukkan dengan cara push
      layer_list_load_first.push(geo_layer_id);
    }
    this.props.set_value_project({
      key: "layer_list_load_first",
      value: layer_list_load_first,
    });
  };

  toggle_select_type = (value) => {
    this.setState({
      selected_layer: value,
    });
  };

  handle_layer_on_click = (new_layer_id) => {
    const { layer_id } = this.props;
    if (layer_id !== new_layer_id) {
      this.props.set_layer_id(new_layer_id);
    } else {
      this.props.clear_layer_id();
    }
  };

  on_change_keywords = (e) => {
    const keywords = e.target.value;
    this.filter_geo_layer(keywords);
    this.setState({
      keywords,
    });
  };

  filter_geo_layer = (keywords) => {
    const regex_string = `${keywords
      ?.toLowerCase()
      ?.split(" ")
      ?.map((word) => `(?=.*?${word})`)
      ?.join("")}.*`;
    const regex_pattern = new RegExp(regex_string);

    if (keywords) {
      const filtered_geo_layer = this.props.layer?.geo_layer_list?.filter(
        (item) => regex_pattern.test(item?.geo_layer?.name?.toLowerCase())
      );
      this.setState({
        filtered_geo_layer,
      });
    } else {
      this.setState({
        filtered_geo_layer: this.props.layer?.geo_layer_list,
      });
    }
  };

  render() {
    //local storage
    // const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const { keywords, filtered_geo_layer } = this.state;

    //global props
    const { geo_layer_list } = this.props.layer;
    const { layer_list_load_first } = this.props.project;

    //content

    return (
      <main className="max_height">
        <h1 className="text_header">Pilih layer</h1>

        <section className="border flex rounded_5 marginBottom_10">
          <div className="center_perfect">
            <img
              src={icon_search}
              alt="Search Filter"
              height="25px"
              width="25px"
              style={{ margin: "5px" }}
            />
          </div>
          <div className="w_full center_perfect">
            <input
              type="text"
              className="border_none"
              value={keywords}
              onChange={this.on_change_keywords}
            />
          </div>
        </section>

        <table style={{ tableLayout: "fixed" }} className="full_width">
          <tbody>
            <tr>
              <td style={{ verticalAlign: "top" }}>
                <p className="text_medium">Layer yang ada di dalam proyek</p>
                <table
                  style={{
                    maxHeight: "calc(( 85vh - 100px))",
                    overflowY: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  <tbody>
                    {filtered_geo_layer.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td
                            onClick={this.toggle_layer.bind(
                              this,
                              item?.geo_layer?._id
                            )}
                          >
                            <div
                              className={
                                layer_list_load_first.includes(
                                  item?.geo_layer?._id
                                )
                                  ? "checklist_active"
                                  : "checklist_not"
                              }
                            />
                          </td>
                          <td>{item?.geo_layer?.name}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </td>
              <td style={{ verticalAlign: "top" }}>
                <p className="text_medium">Layer terpilih</p>
                <table>
                  <tbody>
                    {layer_list_load_first.map((layer_id, idx) => {
                      return (
                        <tr key={idx}>
                          <td onClick={this.toggle_layer.bind(this, layer_id)}>
                            <div
                              className={
                                layer_list_load_first.includes(layer_id)
                                  ? "checklist_active"
                                  : "checklist_not"
                              }
                            />
                          </td>
                          <td>
                            {
                              geo_layer_list?.find(
                                (layer) => layer?.geo_layer?._id === layer_id
                              )?.geo_layer?.name
                            }
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>

        {/* <section className="scroll_y_auto h_300">
          <ul>
            {filtered_geo_layer.map((geo_layer) => {
              return (
                <div
                  key={geo_layer?.geo_layer?._id}
                  onClick={() =>
                    this.handle_layer_on_click(geo_layer?.geo_layer?._id)
                  }
                >
                  <SelectDefaultLayersList
                    geo_layer={geo_layer}
                    layer_id={layer_id}
                  />
                </div>
              );
            })}
          </ul>
        </section> */}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
});

export default connect(mapStateToProps, {
  getProjectDetailByLink,
  set_value_project,
})(SelectDefaultLayers);
