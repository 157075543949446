/*LIBRARY MODULE*/
import React, { Component } from "react";
import { Resizable } from "re-resizable";

/*PERSONAL COMPONENT*/
import LayerDetailStatistic from "./LayerDetailStatistic";

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/
import isEmpty from "../../App/validation/is-empty";
import dict from "../../Data/dict.json";

export default class RightSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableComponent: props.tableComponent || { height: 0 },
      statusBarComponent: {
        width: 0,
      },
    };
  }

  componentDidMount = () => {
    if (document.getElementById("statistic_resume_box")) {
      const width = document.getElementById("statistic_resume_box").offsetWidth;
      localStorage.setItem("rightbar_width", width);
    }
  };

  setStatusBarComponent = () => {
    const width = document.getElementById("statistic_resume_box").offsetWidth;
    this.setState({
      tableComponent: {
        ...this.state.statusBarComponent,
        width: width,
      },
    });
    localStorage.setItem("rightbar_width", width);
  };

  render() {
    //local storage
    const isMobile = window.innerWidth <= 500;

    //local state

    //global props

    //content
    return (
      <Resizable
        id="statistic_resume_box"
        onResizeStop={(e, direction, ref, d) => {
          this.setStatusBarComponent(d);
        }}
        style={
          isMobile
            ? {
                padding: "20px",
                zIndex: 1,
                backgroundColor: "white",
                display: "flex",
              }
            : {
                // padding: "1rem",
                zIndex: 1,
                position: "absolute",
                top: 0,
                right: 0,
                backgroundColor: "white",
                display: "flex",
              }
        }
        defaultSize={
          isMobile
            ? {
                height: "100%",
              }
            : {
                width: 420,
                height: "100%",
              }
        }
        maxHeight="100%"
        minHeight="100%"
        minWidth={isMobile ? 300 : 420}
        maxWidth="100vw"
      >
        <div
          className="statistic_resume_box"
          style={{
            padding: isMobile ? "0" : "1rem 2.2rem 1rem 1rem",
            overflowx: "none",
            height: "100%",
            width: "100%",
          }}
        >
          {this.props.loadingList ||
          this.props.loadingDetail ||
          (isEmpty(this.props.geo_project) &&
            this.props.geo_project_link !== "data") ? (
            <div className="button_standard">
              {dict["Loading GIS data"][this.props.language]} ...
            </div>
          ) : (
            <LayerDetailStatistic
              geocoderContainerRef={this.props.geocoderContainerRef || null}
              geo_project_link={this.props.geo_project_link}
            />
          )}
          <div
            style={{
              height: `${
                this.props.tableComponent.height + (this.props.extraHeight || 0)
              }px`,
            }}
          />
        </div>
      </Resizable>
    );
  }
}
