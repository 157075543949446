//Library modul
import React, { Component } from "react";
import { bbox, circle } from "@turf/turf";
import MapGL, { Source, Layer, Image } from "@urbica/react-map-gl";
//Library css
import "mapbox-gl/dist/mapbox-gl.css";
//Personal Component
//Redux function
import { connect } from "react-redux";

//Picture Asset
import pin_2 from "../../Assets/img/pin_blue_border.png";
//General Function
import isEqual from "../../App/validation/is-equal";
import isEmpty from "../../App/validation/is-empty";

//MAPID color for styling
const MAPID_COLOR1 = "#37A0F7";
const MAPID_COLOR2 = "#7EB532";
const MAPID_COLOR3 = "#1E5E96";

class Map extends Component {
  _map = React.createRef();
  state = {
    mapStyle:
      "https://api.maptiler.com/maps/streets/style.json?key=K156TFZjIQmhD3HPG1gE",
    viewport: {
      latitude: -0.811123, // -0.435161, 118.009574 tengah
      longitude: 113.921327,
      zoom: 2,
    },
    mapMode: "street",
    param_filter: [],
    mapFinish: false,
  };
  componentDidMount() {
    //Nyobain draw
    const map = this._map.current.getMap();
    const self = this;

    map.once("load", () => {
      self.setState({ mapFinish: true });
    });
  }
  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.index, this.props.index) &&
      this.props.screen !== "start"
    ) {
      const map = this._map.current.getMap();
      const { play_final } = this.props;
      const { index, screen } = this.props;

      const geojson = play_final.slide_list[0].layer.geojson;
      const fts = geojson.features;
      const selectFt = fts[index];
      try {
        const ftType = selectFt.geometry.type;
        let isPoint = (type) => {
          return (
            type === "Point" || ftType === "MultiPoint" || ftType === "IoT"
          );
        };
        let newGeojson = {};
        if (isPoint(ftType)) {
          let center = selectFt.geometry.coordinates;
          let options = { steps: 64, units: "kilometers", properties: {} };
          newGeojson = circle(center, 1, options);
        }

        let [minLng, minLat, maxLng, maxLat] = bbox(
          screen === "finish"
            ? geojson
            : isPoint(ftType)
            ? newGeojson
            : {
                type: "FeatureCollection",
                features: [selectFt],
              }
        );
        map.fitBounds([minLng, minLat, maxLng, maxLat], {
          padding: { top: 25, bottom: 350, left: 25, right: 25 },
          duration: play_final.delay * 1000 * 0.8,
        });
      } catch (e) {}
    }
  }
  generateLayer(i) {
    try {
      let layer = [];
      let visibility = "visible";
      const geo_layer = i;
      const { appliedColor } = i;
      const { param_filter } = this.state;
      let isUseLayerStyle = i.isGlobalStyle && i.properties.length > 0;
      let isAppliedExist = !isEmpty(appliedColor);
      let isUseApplyColor = isAppliedExist && i.link === geo_layer.link;
      let isUseFilterLayer =
        param_filter.length > 0 && i.link === geo_layer.link;
      let objProps = i.properties;
      let strExp;
      let filterParamExp = ["!=", "id", "-1"];

      /**Warna objek berdasarkan apply color di pie chart */
      if (isAppliedExist) {
        let inc;
        const len = appliedColor.dataset.length;
        strExp = ["match", ["get", appliedColor.key]];

        for (inc = 0; inc < len - 1; inc++) {
          strExp.push(appliedColor.dataset[inc].lbl);
          strExp.push(appliedColor.dataset[inc].color);
        }

        if (len > 1) {
          strExp.push(appliedColor.dataset[len - 1].color);
        } else {
          strExp.push(appliedColor.dataset[0].lbl);
          strExp.push(appliedColor.dataset[0].color);
          strExp.push(appliedColor.dataset[0].color);
        }
      }

      /**Filter berdasarkan pie chart */
      if (isUseFilterLayer) {
        filterParamExp = ["all"];
        param_filter.forEach((f) => {
          if (f?.value) {
            filterParamExp.push(["==", f.key, f?.value ? f?.value : null]);
          } else {
            filterParamExp.push(["!", ["has", f.key]]);
          }
        });
      }

      /**New styling */
      let globalStyleMode = i?.globalStyleMode;
      let selectedColumn;
      let interpoleParam;
      if (globalStyleMode === "by_value") {
        const range = i.valueStyleProps?.range || [];
        const color = i.valueStyleProps?.color || [];
        const classify_mode = i.valueStyleProps?.classify_mode || "";
        let isNumber = classify_mode === "number";
        selectedColumn = i.valueStyleProps?.selected_column;
        interpoleParam = isNumber
          ? ["interpolate", ["linear"], ["to-number", ["get", selectedColumn]]]
          : ["case"];

        range.forEach((r, idx) => {
          if (isNumber) {
            interpoleParam.push(r.min);
            interpoleParam.push(["to-color", color[idx]]);
          } else {
            if (idx <= range.length - 2) {
              interpoleParam.push(["==", ["get", selectedColumn], r]);
            }
            interpoleParam.push(color[idx]);
          }
        });
      }
      if (i.type === "Polygon" || i.type === "MultiPolygon") {
        layer = [
          <Layer
            id={"stroke-" + i._id}
            before={"stroke-" + i.folder + "-" + (i.layer_order + 1)}
            key={"Nilai: " + i.folder + "-" + i.layer_order}
            value={"Nilai: " + i.folder + "-" + i.layer_order}
            type={"line"}
            source={i._id}
            paint={
              isUseLayerStyle
                ? {
                    "line-color": objProps.find(
                      (obj) => obj.key === "outline_color"
                    ).defaultValue,
                    "line-width": objProps.find(
                      (obj) => obj.key === "outline_width"
                    )
                      ? parseInt(
                          objProps.find((obj) => obj.key === "outline_width")
                            .defaultValue
                        )
                      : 1,
                  }
                : {
                    "line-color": [
                      "case",
                      ["boolean", ["has", "color"], false],
                      ["get", "outline_color"],
                      "#000",
                    ],
                    "line-width": 1,
                    "line-opacity": 1,
                  }
            }
            filter={filterParamExp}
            layout={{ visibility: visibility, "line-join": "round" }}
          />,
          <Layer
            id={"layer-" + i._id}
            before={"stroke-" + i.folder + "-" + (i.layer_order + 1)}
            key={"Nilai: " + i.folder + "-" + i.layer_order + i._id}
            value={"Nilai: " + i.folder + "-" + i.layer_order}
            type={"fill"}
            source={i._id}
            paint={
              isUseApplyColor
                ? {
                    "fill-color": strExp,
                    "fill-opacity": 0.8,
                  }
                : isUseLayerStyle
                ? {
                    "fill-color":
                      globalStyleMode === "by_value"
                        ? interpoleParam
                        : objProps.find((obj) => obj.key === "color")
                        ? objProps.find((obj) => obj.key === "color")
                            .defaultValue
                        : objProps.find((obj) => obj.key === "fill_color")
                            .defaultValue,
                    "fill-opacity": parseFloat(
                      objProps.find((obj) => obj.key === "opacity").defaultValue
                    ),
                  }
                : {
                    "fill-color": [
                      "case",
                      ["boolean", ["has", "color"], false],
                      ["get", "color"],
                      ["boolean", ["has", "fill_color"], false],
                      ["get", "fill_color"],

                      "#ffffff",
                    ],
                    "fill-opacity": [
                      "case",
                      ["boolean", ["has", "opacity"], false],
                      ["get", "opacity"],
                      0.5,
                    ],
                    // "fill-outline-color" : ["case",["boolean",["has", "outline_color"],false],["get", "outline_color"],'#000000']
                  }
            }
            filter={filterParamExp}
            layout={{ visibility: visibility }}
          />,
        ];

        /** Untuk label polygon */
        if (
          isUseLayerStyle &&
          objProps.find((obj) => obj.key === "is_using_label").defaultValue
        ) {
          layer.push(
            <Layer
              id={"polygon-label-" + i._id}
              key={"polygon-label-" + i._id}
              type={"symbol"}
              source={i._id}
              filter={filterParamExp}
              layout={{
                "text-field": objProps.find(
                  (obj) => obj.key === "is_using_label"
                ).defaultValue
                  ? [
                      "get",
                      objProps.find((obj) => obj.key === "text_field")
                        .defaultValue,
                    ]
                  : "",
                "text-font": ["Droid Sans Regular"],
                "text-size": 12,
                "symbol-placement": "point",
                visibility: visibility,
              }}
              paint={{
                "text-color": [
                  "case",
                  ["boolean", ["feature-state", "hover"], false],
                  "rgba(255,0,0,0.75)",
                  "rgba(0,0,0,0.75)",
                ],
                "text-halo-color": [
                  "case",
                  ["boolean", ["feature-state", "hover"], false],
                  "rgba(255,255,0,0.75)",
                  "rgba(255,255,255,0.75)",
                ],
                "text-halo-width": 2,
                "text-halo-blur": 0,
              }}
            />
          );
        }
      }

      if (i.type === "Point" || i.type === "MultiPoint" || i.type === "IoT") {
        /** Status cilicis */
        let isIoT = i.type === "IoT";
        let iotTipe = i?.name?.toLowerCase().includes("curah hujan")
          ? "pch"
          : i?.name?.toLowerCase().includes("duga air")
          ? "pda"
          : "normal";

        let iotParam = [];

        if (iotTipe === "pch") {
          iotParam = [
            "case",
            ["==", ["get", "Status"], "sangatlebat"],
            "iconSangatLebat",
            ["==", ["get", "Status"], "lebat"],
            "iconLebat",
            ["==", ["get", "Status"], "sedang"],
            "iconLebat",
            ["==", ["get", "Status"], "ringan"],
            "iconRingan",
            "iconCerah",
          ];
        } else if (iotTipe === "pda") {
          iotParam = [
            "case",
            ["==", ["get", "Status"], "siaga1"],
            "#ED3833",
            ["==", ["get", "Status"], "siaga2"],
            "#FDF851",
            ["==", ["get", "Status"], "siaga3"],
            "#2046F5",
            ["==", ["get", "Status"], "-"],
            "#808080",
            "#39811D",
          ];
        }
        layer = i.isExpand
          ? [
              <Layer
                id={"unclustered-point-" + i._id}
                key={"unclustered-point-" + i._id}
                type={"circle"}
                source={i._id}
                filter={filterParamExp}
                layout={{
                  visibility,
                }}
                paint={
                  isUseApplyColor
                    ? {
                        "circle-color": strExp,
                        "circle-radius": [
                          "case",
                          ["boolean", ["has", "circle_radius"], false],
                          ["get", "circle_radius"],
                          5,
                        ],
                        "circle-stroke-width": [
                          "case",
                          ["boolean", ["has", "circle_stroke_width"], false],
                          ["get", "circle_stroke_width"],
                          1,
                        ],
                        "circle-stroke-color": "#000000",
                      }
                    : isUseLayerStyle
                    ? {
                        "circle-color":
                          isIoT && iotTipe === "pda"
                            ? iotParam
                            : globalStyleMode === "by_value"
                            ? interpoleParam
                            : objProps.find((obj) => obj.key === "circle_color")
                            ? objProps.find((obj) => obj.key === "circle_color")
                                .defaultValue
                            : objProps.find((obj) => obj.key === "fill_color")
                                .defaultValue,
                        "circle-radius": parseFloat(
                          objProps.find((obj) => obj.key === "circle_radius")
                            .defaultValue
                        ),
                        "circle-stroke-width": Number(
                          objProps.find(
                            (obj) => obj.key === "circle_stroke_width"
                          ).defaultValue
                        ),
                        "circle-stroke-color": objProps.find(
                          (obj) => obj.key === "circle_stroke_color"
                        ).defaultValue,
                      }
                    : {
                        "circle-color":
                          isIoT && iotTipe === "pda"
                            ? iotParam
                            : [
                                "case",
                                ["boolean", ["has", "circle_color"], false],
                                ["get", "circle_color"],
                                ["boolean", ["has", "fill_color"], false],
                                ["get", "fill_color"],
                                MAPID_COLOR3,
                              ],
                        "circle-radius": [
                          "case",
                          ["boolean", ["has", "circle_radius"], false],
                          ["get", "circle_radius"],
                          5,
                        ],
                        "circle-stroke-width": [
                          "case",
                          ["boolean", ["has", "circle_stroke_width"], false],
                          ["get", "circle_stroke_width"],
                          1,
                        ],
                        "circle-stroke-color": [
                          "case",
                          ["boolean", ["has", "circle_stroke_color"], false],
                          ["get", "circle_stroke_color"],
                          MAPID_COLOR1,
                        ],
                      }
                }
              />,
              <Layer
                id={"unclustered-symbol-" + i._id}
                key={"unclustered-symbol-" + i._id}
                type={"symbol"}
                source={i._id}
                filter={filterParamExp}
                layout={{
                  "icon-image": "pin",
                  "text-field":
                    isUseLayerStyle &&
                    objProps.find((obj) => obj.key === "is_using_label")
                      .defaultValue
                      ? [
                          "get",
                          objProps.find((obj) => obj.key === "text_field")
                            .defaultValue,
                        ]
                      : "",
                  "text-variable-anchor": ["bottom"],
                  "text-radial-offset": 0.5,
                  "text-justify": "auto",
                  visibility: visibility,
                  "icon-size": 0.25,
                  "icon-padding": 0,
                  "icon-anchor": "bottom",
                  "icon-allow-overlap": true,
                  "icon-rotate": ["get", "rotation"],
                }}
                paint={{
                  "icon-color": "#32a852",
                  "icon-opacity": 1,
                  "icon-translate": [0, -3.5],
                }}
              />,
            ]
          : [
              <Layer
                id={"point-" + i._id}
                before={"layer-" + i.folder + "-" + (i.layer_order + 1)}
                key={"layer-" + i.folder + "-" + (i.layer_order + 1) + i._id}
                value={"Nilai: " + i.folder + "-" + i.layer_order}
                type={"circle"}
                source={i._id}
                filter={
                  isUseFilterLayer
                    ? ["all", filterParamExp, ["has", "point_count"]]
                    : ["has", "point_count"]
                }
                layout={{
                  visibility: visibility,
                }}
                paint={{
                  "circle-color": [
                    "step",
                    ["get", "point_count"],
                    MAPID_COLOR3,
                    100,
                    MAPID_COLOR2,
                    750,
                    MAPID_COLOR1,
                  ],
                  "circle-radius": [
                    "step",
                    ["get", "point_count"],
                    20,
                    100,
                    30,
                    750,
                    40,
                  ],
                }}
                onLeave={this.onLeave}
                onClick={(e) => {
                  this.zoomOnCluster(
                    e.features,
                    // "layer-" + i.folder + "-" + i.layer_order
                    i._id
                  );
                }}
              />,
              <Layer
                id={"cluster-count-" + i._id}
                key={"cluster-count-" + i._id}
                type={"symbol"}
                source={i._id}
                filter={
                  isUseFilterLayer
                    ? ["all", filterParamExp, ["has", "point_count"]]
                    : ["has", "point_count"]
                }
                layout={{
                  visibility: visibility,
                  "text-field": "{point_count_abbreviated}",
                  "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                  "text-size": 12,
                }}
                paint={{
                  "text-color": "#fff",
                }}
              />,
              <Layer
                id={"unclustered-point-" + i._id}
                key={"unclustered-point-" + i._id}
                type={"circle"}
                source={i._id}
                filter={
                  isUseFilterLayer
                    ? ["all", filterParamExp, ["!has", "point_count"]]
                    : ["!has", "point_count"]
                }
                layout={{
                  visibility,
                }}
                paint={
                  isUseApplyColor
                    ? {
                        "circle-color": strExp,
                        "circle-radius": [
                          "case",
                          ["boolean", ["has", "circle_radius"], false],
                          ["get", "circle_radius"],
                          5,
                        ],
                        "circle-stroke-width": [
                          "case",
                          ["boolean", ["has", "circle_stroke_width"], false],
                          ["get", "circle_stroke_width"],
                          1,
                        ],
                        "circle-stroke-color": "#000000",
                      }
                    : isUseLayerStyle
                    ? {
                        "circle-color":
                          isIoT && iotTipe === "pda"
                            ? iotParam
                            : objProps.find((obj) => obj.key === "circle_color")
                            ? objProps.find((obj) => obj.key === "circle_color")
                                .defaultValue
                            : objProps.find((obj) => obj.key === "fill_color")
                                .defaultValue,
                        "circle-radius": parseFloat(
                          objProps.find((obj) => obj.key === "circle_radius")
                            .defaultValue
                        ),
                        "circle-stroke-width": Number(
                          objProps.find(
                            (obj) => obj.key === "circle_stroke_width"
                          ).defaultValue
                        ),
                        "circle-stroke-color": objProps.find(
                          (obj) => obj.key === "circle_stroke_color"
                        ).defaultValue,
                      }
                    : {
                        "circle-color":
                          isIoT && iotTipe === "pda"
                            ? iotParam
                            : [
                                "case",
                                ["boolean", ["has", "circle_color"], false],
                                ["get", "circle_color"],
                                ["boolean", ["has", "fill_color"], false],
                                ["get", "fill_color"],
                                MAPID_COLOR3,
                              ],
                        "circle-radius": [
                          "case",
                          ["boolean", ["has", "circle_radius"], false],
                          ["get", "circle_radius"],
                          5,
                        ],
                        "circle-stroke-width": [
                          "case",
                          ["boolean", ["has", "circle_stroke_width"], false],
                          ["get", "circle_stroke_width"],
                          1,
                        ],
                        "circle-stroke-color": [
                          "case",
                          ["boolean", ["has", "circle_stroke_color"], false],
                          ["get", "circle_stroke_color"],
                          MAPID_COLOR1,
                        ],
                      }
                }
              />,
              <Layer
                id={"unclustered-symbol-" + i._id}
                key={"unclustered-symbol-" + i._id}
                type={"symbol"}
                source={i._id}
                filter={
                  isUseFilterLayer
                    ? ["all", filterParamExp, ["has", "point_count"]]
                    : ["!has", "point_count"]
                }
                layout={{
                  "icon-image": "pin",
                  "text-field":
                    isUseLayerStyle &&
                    objProps.find((obj) => obj.key === "is_using_label")
                      .defaultValue
                      ? [
                          "get",
                          objProps.find((obj) => obj.key === "text_field")
                            .defaultValue,
                        ]
                      : "",
                  "text-variable-anchor": ["bottom"],
                  "text-radial-offset": 0.5,
                  "text-justify": "auto",
                  visibility: visibility,
                  "icon-size": 0.25,
                  "icon-padding": 0,
                  "icon-anchor": "bottom",
                  "icon-allow-overlap": true,
                  "icon-rotate": ["get", "rotation"],
                }}
                paint={{
                  "icon-color": "#32a852",
                  "icon-opacity": 1,
                  "icon-translate": [0, -3.5],
                }}
              />,
            ];
      }

      if (i.type === "LineString" || i.type === "MultiLineString") {
        layer = (
          <Layer
            id={"layer-line-" + i._id}
            before={"layer-" + i.folder + "-" + (i.layer_order + 1)}
            key={"Nilai: " + i.folder + "-" + i.layer_order}
            value={"Nilai: " + i.folder + "-" + i.layer_order}
            type={"line"}
            source={i._id}
            paint={
              isUseApplyColor
                ? {
                    "line-color": strExp,
                    "line-opacity": [
                      "case",
                      ["boolean", ["has", "opacity"], false],
                      ["get", "opacity"],
                      0.7,
                    ],
                    "line-gap-width": [
                      "case",
                      ["boolean", ["has", "line_gap_width"], false],
                      ["get", "line_gap_width"],
                      0,
                    ],
                  }
                : isUseLayerStyle
                ? {
                    "line-color":
                      globalStyleMode === "by_value"
                        ? interpoleParam
                        : objProps.find((obj) => obj.key === "color")
                        ? objProps.find((obj) => obj.key === "color")
                            .defaultValue
                        : objProps.find((obj) => obj.key === "fill_color")
                            .defaultValue,
                    "line-width": parseInt(
                      objProps.find((obj) => obj.key === "line_width")
                        .defaultValue
                    ),
                    "line-opacity": parseFloat(
                      objProps.find((obj) => obj.key === "opacity").defaultValue
                    ),
                    "line-gap-width": Number(
                      objProps.find((obj) => obj.key === "line_gap_width")
                        .defaultValue
                    ),
                  }
                : {
                    "line-color": [
                      "case",
                      ["boolean", ["has", "color"], false],
                      ["get", "color"],
                      ["boolean", ["has", "fill_color"], false],
                      ["get", "fill_color"],

                      "#000",
                    ],
                    "line-width": [
                      "case",
                      ["boolean", ["has", "line_width"], false],
                      ["get", "line_width"],
                      1.25,
                    ],
                    "line-opacity": [
                      "case",
                      ["boolean", ["has", "opacity"], false],
                      ["get", "opacity"],
                      0.7,
                    ],
                    "line-gap-width": [
                      "case",
                      ["boolean", ["has", "line_gap_width"], false],
                      ["get", "line_gap_width"],
                      0,
                    ],
                  }
            }
            filter={filterParamExp}
            layout={{ visibility: visibility }}
          />
        );
      }

      return layer;
    } catch (e) {
      return [];
    }
  }
  render() {
    const { play_final } = this.props;
    let mapStyle;
    switch (play_final.base_map) {
      case "street":
        mapStyle =
          "https://api.maptiler.com/maps/streets/style.json?key=CVYOeJckfxqURN5wjKEB";
        break;
      case "light":
        mapStyle = "mapbox://styles/mapbox/light-v10";
        break;
      case "dark":
        mapStyle = "mapbox://styles/mapbox/dark-v10";
        break;
      default:
        mapStyle = "mapbox://styles/mapbox/satellite-streets-v11";
    }
    return (
      <main className="play_map">
        <MapGL
          // style={{
          //   width: "100vw",
          //   height: "100vh",
          //   // position: "absolute",
          //   // bottom: 0,
          //   // left: 0,
          // }}
          style={{
            width: "100%",
            height: "100%",
          }}
          mapStyle={mapStyle}
          accessToken={
            "pk.eyJ1IjoibWFwaWQiLCJhIjoiY2pqNWtnaW10MGNnMjNrcWg5MHQwY21nNiJ9.voWwMqU73TCDDua3mGCb8g"
          }
          latitude={this.state.viewport.latitude}
          longitude={this.state.viewport.longitude}
          zoom={this.state.viewport.zoom}
          maxZoom={20}
          minZoom={2}
          onViewportChange={(viewport) => {
            this.setState({ viewport });
          }}
          attributionControl={false}
          ref={this._map}
        >
          <Image id="pin" image={pin_2} />
          {!isEmpty(play_final?.slide_list) &&
            play_final?.slide_list
              .sort((a, b) =>
                a.layer.type === "Point" || a.layer.type === "MultiPoint"
                  ? 1
                  : a.layer.type === "LineString" ||
                    a.layer.type === "MultiLineString"
                  ? 1
                  : -1
              )
              .map((l, i) => {
                const { layer } = l;
                return (
                  <div key={"source-" + layer._id}>
                    <Source
                      id={layer._id}
                      type="geojson"
                      data={
                        layer.hasOwnProperty("geojson")
                          ? layer.geojson
                          : {
                              type: "FeatureCollection",
                              features: [],
                            }
                      }
                      generateId={true}
                      cluster={false}
                    />
                    {this.generateLayer(layer)}
                  </div>
                );
              })}
        </MapGL>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({ play: state.play });
export default connect(mapStateToProps, {})(Map);
