/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

/*PERSONAL COMPONENT*/
import BlogViewer from "../Components/blog/BlogViewer";

/*REDUX FUNCTION*/
import {
  getBlogList,
  createEmpty,
  clearBlogList,
  setBlogPersonal,
} from "../App/actions/blogAction";
import { setPathname, openModal } from "../App/actions/authActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import isEmpty from "../App/validation/is-empty";
import dict from "../Data/dict.json";
import domain_list from "../Data/domain_list";

/*NON IMPORT*/

class BlogList extends Component {
  state = {
    sidebarStatus: true,
    blog_item: {},
    blog_link: "",
  };

  componentDidMount() {
    this.props.getBlogList();
    document.getElementById("container_document").style.width =
      "calc(( 100% - 400px ))";
    document.getElementById("container_document").style.marginLeft = "400px";
    const { blog_list_personal } = this.props.blog;
    const blog_link = this?.props?.match?.params?.blog_link
      ? this?.props?.match?.params?.blog_link
      : "";
    this.setState({ blog_link });
    if (!isEmpty(blog_list_personal)) {
      if (blog_link) {
        const blog_item = blog_list_personal.filter(
          (blog) => blog.link === blog_link
        )[0];
        this.props.setBlogPersonal(blog_item);
      } else {
        this.props.setBlogPersonal(blog_list_personal[0]);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.blog.blog_list_personal !== prevProps.blog.blog_list_personal
    ) {
      const { blog_list_personal } = this.props.blog;
      const blog_link = this?.props?.match?.params?.blog_link
        ? this?.props?.match?.params?.blog_link
        : "";
      this.setState({ blog_link });
      if (!isEmpty(blog_list_personal)) {
        if (blog_link) {
          const blog_item = blog_list_personal.filter(
            (blog) => blog.link === blog_link
          )[0];
          this.props.setBlogPersonal(blog_item);
        } else {
          this.props.setBlogPersonal(blog_list_personal[0]);
        }
      }
    }
  }

  onOpenModal = (name) => {
    this.props.openModal(name);
  };

  onClickPathname = (pathname) => {
    this.props.setPathname(pathname);
  };

  handleCreateEmptyBlog = () => {
    const { domain } = this.props.auth;
    const body = {
      domain,
    };
    this.props.createEmpty(body);
  };

  handleClickTitle = (blog_item) => {
    this.props.setBlogPersonal(blog_item);
  };

  toggleSideBar = () => {
    const { sidebarStatus } = this.state;
    this.setState(
      {
        sidebarStatus: !sidebarStatus,
      },
      () => {
        if (this.state.sidebarStatus) {
          this.openSidebar();
        } else {
          this.closeSidebar();
        }
      }
    );
  };

  closeSidebar = () => {
    document.getElementById("container_document").style.width = "100%";
    document.getElementById("container_document").style.marginLeft = "0px";
  };

  openSidebar = () => {
    document.getElementById("container_document").style.width =
      "calc(( 100% - 400px ))";
    document.getElementById("container_document").style.marginLeft = "400px";
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { domain } = this.props.auth;
    const { sidebarStatus } = this.state;
    const { blog_list_personal, blog_personal } = this.props.blog;

    let blog_list_personal_content;
    let blog_content;
    if (isEmpty(blog_personal) || blog_list_personal.length === 0) {
      blog_content = (
        <div
          className="text_medium"
          style={{ marginTop: "10px", marginBottom: "113px" }}
        >
          {dict["Loading"][language]}
        </div>
      );
    } else {
      blog_content = <BlogViewer blog_item={blog_personal} />;
    }
    if (blog_list_personal.length === 0) {
      blog_list_personal_content = (
        <div
          className="text_medium"
          style={{ marginTop: "10px", marginBottom: "113px" }}
        >
          {dict["Document"][language]}
        </div>
      );
    } else {
      blog_list_personal_content = (
        <div>
          {blog_list_personal.map((blog) => {
            const { _id, title, title_en, link } = blog;
            const id_content =
              link === blog_personal?.link ? "active" : "not_active";
            return (
              <Link to={`/blog_list/${link}`} key={_id}>
                <div
                  onClick={this.handleClickTitle.bind(this, blog)}
                  className="sidebar_button vertical_center"
                  id={id_content}
                  style={{ paddingLeft: "15px", overflow: "hidden" }}
                >
                  <div className="vertical_center_child">
                    {language === "ina" ? title : title_en || title}
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      );
    }
    const sidebarContent = (
      <div style={{ marginTop: "20px", marginBottom: "100px" }}>
        <div style={{ textAlign: "center" }}>
          <div style={{ marginBottom: "20px" }}>
            <Link
              to="/dashboard"
              onClick={this.onClickPathname.bind(this, "/dashboard")}
            >
              <img src={domain_list?.[domain]?.logo} height="43px" alt="Home" />
            </Link>
          </div>
          <div onClick={this.handleCreateEmptyBlog} className="button_pill">
            {dict["Write New"][language]}
          </div>
          <button
            className="button_info"
            id="blue"
            onClick={this.onOpenModal.bind(this, "Membuat Publikasi")}
          >
            i
          </button>
        </div>
        <div style={{ marginTop: "50px" }}>{blog_list_personal_content}</div>
      </div>
    );
    const documentContent = (
      <div
        id="container_document"
        style={{
          transition: "all 0.2s ease-in-out",
        }}
      >
        <div className="container_document">
          <div
            onClick={this.toggleSideBar}
            className="button_pill_tight"
            style={{ marginTop: "20px", fontSize: "11px" }}
            id="blue"
          >
            {sidebarStatus
              ? dict["Close Side Bar"][language]
              : dict["Open Side Bar"][language]}
          </div>
          {blog_content}
        </div>
      </div>
    );
    const itemContent = (
      <main>
        {sidebarStatus && (
          <section className="container_document_sidebar_viewer">
            {sidebarContent}
          </section>
        )}
        <section style={{ marginBottom: "500px" }}>{documentContent}</section>
      </main>
    );
    return (
      <div>
        {itemContent}
        <Helmet>
          <title>{dict["Blog List"][language]}</title>
          <meta name="description" content={dict["Blog List"][language]} />
        </Helmet>
        <style>
          {`
          body{
            padding-top: 0px;
            overflow-y: auto;
            background-color: #fff;
          }
          .navbarDefault{ visibility: hidden;}
          #top_nav{ visibility: hidden;}
          #sidebar_home{ visibility: hidden;}
          .nav_bottom{visibility: hidden;}      
          `}
        </style>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  blog: state.blog,
});
export default connect(mapStateToProps, {
  getBlogList,
  createEmpty,
  clearBlogList,
  setBlogPersonal,
  setPathname,
  openModal,
})(BlogList);
