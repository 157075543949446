//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import writeXlsxFile from "write-excel-file";
import { bbox } from "@turf/turf";

//Personal Component
import ModalNumber from "../common_modal/ModalNumber";
import Modal from "../common_modal/Modal";
import Login from "../auth/Login";
import Register from "../auth/Register";
import ErrorFree from "../user/ErrorFree";
import PoiDistribution from "./PoiDistribution";
import PeopleSpending from "./PeopleSpending";
import SiniIsochrone from "./Isochrone";
import Demografi from "./Demografi";
import Disaster from "./Disaster";
import LandValueZone from "./LandValueZone";
import Publication from "./Publication";

//Redux function
import { openModal } from "../../App/actions/authActions";
import { pickStatus } from "../../App/actions/layerNewActions";
import {
  remove_sini,
  setRequestId,
  set_sini_mode,
  del_sini_mode,
  push_change_radius_sini,
} from "../../App/actions/siniActions";
import { set_value_toolbox } from "../../App/actions/toolboxActions";

//Picture Asset
import illustration_sini from "../../Assets/svg/illustration_sini.svg";
import illustration_map from "../../Assets/svg/illustration_map.svg";
import icon_add from "../../Assets/svg/icon_add.svg";
import icon_delete from "../../Assets/svg/icon_delete_red.svg";
import logo_dukcapil from "../../Assets/img/logo_dukcapil.png";
import logo_atrbpn from "../../Assets/img/logo_atrbpn.png";
import logo_big from "../../Assets/img/logo_big.png";
import logo_bps from "../../Assets/img/logo_bps.png";
import logo_osm from "../../Assets/img/logo_osm.png";
import logo_pupr from "../../Assets/img/logo_pupr.png";
import logo_rumahcom from "../../Assets/img/logo_rumahcom.png";
import inarisk from "../../Assets/img/inarisk.png";

//General Function
import dict from "../../Data/dict.json";
import get_groups_project_org from "../../App/validation/get_groups_project_org";
import domain_list from "../../Data/domain_list";

class DataDashboard extends Component {
  state = {
    modal_number: false,
    hold_chart: false,
    modalLogin: false,
    loginStatus: true,
    modal_pricing: false,
    modal_invite: false,
    set_modal_download: false,
    set_modal_delete: false,
    data_downlad: [],
    type_download: "",
    sini_radius: "1",
    loading_change_radius: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {
      this.setState({ modalLogin: false });
    }
    if (this.props.auth.isRegistered !== prevProps.auth.isRegistered) {
      this.setState({ loginStatus: true });
    }
  }

  toggleLogin = () => {
    this.setState({ modalLogin: !this.state.modalLogin });
  };

  toggleLoginRegister = () => {
    this.setState({ loginStatus: !this.state.loginStatus });
  };

  pickStatus = () => {
    const { isAuthenticated, domain } = this.props.auth;
    const { license_list_active } = this.props.payment;
    const { geo_project } = this.props.project;

    let groups_project = geo_project?.groups || [];
    const groups_org = this.props.auth.groups_org || [];

    const groups_project_org = get_groups_project_org(
      groups_project,
      groups_org
    );

    const status_domain = domain_list?.[domain]?.status;

    if (!isAuthenticated) {
      this.toggleLogin();
    } else if (status_domain === "premium") {
      //mode white list domain
      this.props.set_value_toolbox({
        key: "draw_toolbox_status",
        value: false,
      });
      this.props.pickStatus(true);
    } else if (groups_project_org.length > 0) {
      //mode sharing lisensi
      this.props.set_value_toolbox({
        key: "draw_toolbox_status",
        value: false,
      });
      this.props.pickStatus(true);
    } else if (license_list_active?.length === 0) {
      this.toggle_pricing();
    } else {
      //mode personal lisensi
      this.props.set_value_toolbox({
        key: "draw_toolbox_status",
        value: false,
      });
      this.props.pickStatus(true);
    }
  };

  toggle_pricing = () => {
    this.setState({ modal_pricing: !this.state.modal_pricing });
  };

  toggle_invite = () => {
    this.setState({ modal_invite: !this.state.modal_invite });
  };

  toggleNumber = () => {
    this.setState({ modal_number: !this.state.modal_number });
  };

  onSetId = (request_id) => {
    const { sini_list } = this.props.layer;
    const layer = sini_list?.find((d) => d?.request_id === request_id);
    // flyto

    const body = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {
            color: "#56549A",
          },
          geometry: {
            coordinates: [layer.long, layer.lat],
            type: "Point",
          },
        },
      ],
    };

    const { map } = this.props?.map;
    let [minLng, minLat, maxLng, maxLat] = bbox(body);
    if (map) {
      let padding = { top: 100, bottom: 100, left: 500, right: 500 };
      if (window.innerWidth < 1172) {
        padding = {
          top: 50,
          bottom: 50,
          left: 10,
          right: 10,
        };
      }

      map?.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding,
          maxZoom: 13,
        }
      );
    }
    this.props.setRequestId(request_id);
    this.setState({ modal_number: false });
  };

  onOpenModal = (name) => {
    this.props.openModal(name);
  };

  handleDeleteSini = (id) => {
    this.setState({ loading_change_radius: false });
    this.props.remove_sini(id);
  };

  handleDownloadSini = (id, type) => {
    const { sini_list } = this.props.layer;

    const data_download = sini_list.filter((d) => d.request_id === id);
    const body = data_download.map((d) => d[type]);
    this.setState({
      set_modal_download: true,
      data_downlad: body,
      type_download: type,
    });
  };

  toggleDownloadSini = () => {
    this.setState({
      set_modal_download: !this.state.set_modal_download,
    });
  };

  download_json = () => {
    const { data_downlad, type_download } = this.state;
    let body_json = JSON.stringify(data_downlad[0]);
    if (type_download === "isochrone") {
      const raw = data_downlad?.[0] || [];
      const features = raw.map((item) => item.geometry.features[0]);
      const geojson = {
        type: "FeatureCollection",
        features,
      };
      body_json = JSON.stringify(geojson);
    }
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(body_json)
    );
    const file_name = `SINI-${type_download}.geojson`;
    element.setAttribute("download", file_name);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  download_excel = async () => {
    const { data_downlad, type_download } = this.state;

    let properties;
    if (type_download === "isochrone") {
      properties = data_downlad[0];
    } else {
      properties = data_downlad[0].features.map((d) => d.properties);
    }

    let array_key = [];

    for (const key in properties[0]) {
      array_key.push(key);
    }

    try {
      let schema = array_key.map((d) => {
        return {
          column: d,
          type: String,
          value: (data) => data[d],
        };
      });

      const newData = properties.map((d) => {
        let obj = {};
        for (let index = 0; index < array_key.length; index++) {
          const element = array_key[index];
          obj[element] = String(d[element]);
        }
        return obj;
      });

      await writeXlsxFile(newData, {
        schema,
        fileName: `SINI-${type_download}.xlsx`,
      });
    } catch (error) {}
  };

  sini_mode = (data) => {
    const { sini_mode } = this.props.layer;

    const find_data = sini_mode.find((d) => d === data);

    if (find_data === undefined) {
      this.props.set_sini_mode(data);
    } else if (find_data === "isochrone") {
      return;
    } else {
      this.props.del_sini_mode(data);
    }
  };

  get_change_radius_sini = () => {
    const { sini_radius } = this.state;
    const { request_id, sini_list } = this.props.layer;
    const coordinates = sini_list?.filter(
      (e) => e.request_id === request_id
    )[0];

    const body = {
      request_id: request_id,
      radius: sini_radius,
      lat: coordinates.lat,
      long: coordinates.long,
    };

    this.setState({ loading_change_radius: true });
    this.props.remove_sini(request_id);
    this.props.push_change_radius_sini(body);
    // this.setState({ loading_change_radius: false });
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const {
      modal_number,
      modalLogin,
      loginStatus,
      modal_pricing,
      set_modal_download,
      loading_change_radius,
    } = this.state;
    const { sini_list, request_id, pick_status } = this.props.layer;
    // const domain = this.props.auth.domain;
    const {
      isAuthenticated,
      isRegistered,
      // mode_balai
    } = this.props.auth;

    let content = (
      <main>
        {loading_change_radius ? (
          <div className="sini_loading">
            <h1>Loading ...</h1>
          </div>
        ) : (
          <section
            style={{
              textAlign: "center",
              marginTop: "70px",
            }}
          >
            <img
              className="ilustration_sini_max_width"
              src={illustration_sini}
              alt="Sini"
              // style={{ width: "60%", marginBottom: "15px" }}
            />
            <div className="text_medium" style={{ marginBottom: "10px" }}>
              {
                dict?.[
                  "Click the plus icon to start the data insights query process."
                ]?.[language]
              }
            </div>
            <button
              onClick={this.pickStatus}
              id="blue"
              className="button_circle"
              style={{ fontSize: "50px", marginBottom: "15px" }}
            >
              <div className="button_circle_inside">+</div>
            </button>
            <div className="text_medium" style={{ marginBottom: "10px" }}>
              {
                dict?.[
                  "Or view data on this project by click on eye or table icon."
                ]?.[language]
              }
            </div>
          </section>
        )}
      </main>
    );

    let nav_number = null;
    if (sini_list?.length > 0) {
      nav_number = (
        <section className="nav_number_sini">
          <div>
            {sini_list?.map((e, idx) => {
              let id = "outline_white";
              if (e.request_id === request_id) {
                id = "outline_blue";
              }
              return (
                <main key={idx} style={{ display: "inline-block" }}>
                  <button
                    onClick={this.onSetId.bind(this, e.request_id)}
                    id={id}
                    className="button_circle"
                    style={{
                      fontSize: "14px",
                      width: "30px",
                      height: "30px",
                      marginLeft: "3px",
                      marginRight: "3px",
                    }}
                  >
                    <div className="button_circle_inside">{idx + 1}</div>
                  </button>
                </main>
              );
            })}
          </div>
        </section>
      );
      if (sini_list?.length > 6) {
        const sini_cut = sini_list?.slice(0, 5);
        nav_number = (
          <section className="nav_number_sini">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                {sini_cut.map((e, idx) => {
                  let id = "outline_white";
                  if (e.request_id === request_id) {
                    id = "outline_blue";
                  }
                  return (
                    <main key={idx} style={{ display: "inline-block" }}>
                      <button
                        onClick={this.onSetId.bind(this, e.request_id)}
                        id={id}
                        className="button_circle"
                        style={{
                          fontSize: "14px",
                          width: "30px",
                          height: "30px",
                          marginLeft: "3px",
                          marginRight: "3px",
                        }}
                      >
                        <div className="button_circle_inside">{idx + 1}</div>
                      </button>
                    </main>
                  );
                })}
              </div>
              <main style={{ display: "inline-block" }}>
                <button
                  onClick={this.toggleNumber}
                  className="button_circle"
                  id="outline_white"
                  style={{
                    fontSize: "14px",
                    width: "30px",
                    height: "30px",
                    marginLeft: "3px",
                    marginRight: "3px",
                  }}
                >
                  <div className="button_circle_inside">{">"}</div>
                </button>
              </main>
            </div>
          </section>
        );
      }
    }
    if (!isAuthenticated) {
      content = (
        <main>
          <section style={{ textAlign: "center", marginTop: "70px" }}>
            <img
              className="ilustration_sini_max_width"
              src={illustration_sini}
              alt="Sini"
              // style={{ width: "60%", marginBottom: "15px" }}
            />
            <div className="text_medium" style={{ marginBottom: "10px" }}>
              {dict?.["Log in or create account to use SINI MAPID"]?.[language]}
            </div>
            <button
              onClick={this.toggleLogin}
              id="blue"
              className="button_standard"
            >
              {dict?.["Log in"]?.[language]}
            </button>
            <div className="text_medium" style={{ marginBottom: "10px" }}>
              {
                dict?.[
                  "Or view data on this project by click on eye or table icon."
                ]?.[language]
              }
            </div>
          </section>
        </main>
      );
    } else if (pick_status === true) {
      content = (
        <main>
          <section style={{ textAlign: "center", marginTop: "70px" }}>
            <img
              className="ilustration_sini_max_width"
              src={illustration_map}
              alt="Map "
              // style={{ width: "60%", marginBottom: "10px" }}
            />
            <div className="text_medium">
              {
                dict?.[
                  "Select the point on the map where you want to view the insight"
                ]?.[language]
              }
            </div>
          </section>
        </main>
      );
    } else if (sini_list?.length > 0) {
      const sini_clone = sini_list?.slice();
      const current_sini = sini_clone.filter(
        (e) => e.request_id === request_id
      );
      if (current_sini.length > 0) {
        const status = sini_list?.filter(
          (e) => e.request_id === request_id
        )?.[0]?.status;
        if (status === "outside") {
          content = (
            <main>
              <section style={{ textAlign: "center" }}>
                <div className="sini_demo" id="blue_light">
                  {dict?.["Demographics"]?.[language]}
                </div>
              </section>
              <div className="text_inferior">
                {
                  dict?.[
                    "Oops.. For now SINI MAPID only reaches Indonesia, look forward to when MAPID reaches the whole earth and beyond"
                  ]?.[language]
                }
              </div>
            </main>
          );
        } else {
          const logo_content = (
            <section>
              <main>
                <div
                  style={{ textAlign: "center", marginTop: "1rem" }}
                  className="text_inferior"
                >
                  {dict?.["Data Sources"]?.[language]}
                </div>
                <div style={{ textAlign: "center" }}>
                  <img
                    src={logo_atrbpn}
                    alt="ATRBPN"
                    style={{
                      height: "75px",
                      margin: "2px",
                      display: "inline-block",
                      verticalAllign: "bottom",
                    }}
                  />
                  <img
                    src={logo_pupr}
                    alt="pupr"
                    style={{
                      height: "75px",
                      margin: "2px",
                      display: "inline-block",
                      verticalAllign: "bottom",
                    }}
                  />
                  <img
                    src={logo_big}
                    alt="BIG"
                    style={{
                      height: "75px",
                      margin: "2px",
                      display: "inline-block",
                      verticalAllign: "bottom",
                    }}
                  />
                  <img
                    src={logo_bps}
                    alt="BPS"
                    style={{
                      height: "75px",
                      margin: "2px",
                      display: "inline-block",
                      verticalAllign: "bottom",
                    }}
                  />
                  <img
                    src={logo_osm}
                    alt="OSM"
                    style={{
                      height: "75px",
                      margin: "2px",
                      display: "inline-block",
                      verticalAllign: "bottom",
                    }}
                  />
                  <img
                    src={logo_dukcapil}
                    alt="Dukcapil"
                    style={{
                      height: "75px",
                      margin: "2px",
                      display: "inline-block",
                      verticalAllign: "bottom",
                    }}
                  />
                  <img
                    src={logo_rumahcom}
                    alt="Rumahcom"
                    style={{
                      height: "75px",
                      margin: "2px",
                      display: "inline-block",
                      verticalAllign: "bottom",
                    }}
                  />
                  <img
                    src={inarisk}
                    alt="inarisk"
                    style={{
                      height: "75px",
                      margin: "2px",
                      display: "inline-block",
                      verticalAllign: "bottom",
                    }}
                  />
                </div>
                <div></div>
              </main>
            </section>
          );

          const change_radius = (
            <section className="container_standard" id="grey_light">
              <div className="sini_change_radius">
                <h2>Change Radius SINI</h2>
                <select
                  className="input_new"
                  onChange={(e) =>
                    this.setState({ sini_radius: e.target.value })
                  }
                >
                  <option hidden>Select Radius</option>
                  <option value="0.5">500 Meters</option>
                  <option value="1">1 Kilo Meters</option>
                  <option value="2">2 Kilo Meters</option>
                </select>
                <button
                  className="button"
                  id="blue"
                  onClick={() => this.get_change_radius_sini()}
                >
                  Save Change
                </button>
              </div>
            </section>
          );

          content = (
            <>
              <main className="sini_row">
                {change_radius}
                <section>
                  <SiniIsochrone
                    set_sini_mode={(e) => this.sini_mode(e)}
                    sini_mode={this.sini_mode}
                    handleDownloadSini={this.handleDownloadSini}
                  />
                </section>
                <section>
                  <Demografi
                    sini_mode={this.sini_mode}
                    handleDownloadSini={this.handleDownloadSini}
                  />
                </section>
                <section>
                  <PoiDistribution
                    sini_mode={this.sini_mode}
                    handleDownloadSini={this.handleDownloadSini}
                  />
                </section>
                <section>
                  <Disaster
                    sini_mode={this.sini_mode}
                    handleDownloadSini={this.handleDownloadSini}
                  />
                </section>
                <section>
                  <LandValueZone
                    sini_mode={this.sini_mode}
                    handleDownloadSini={this.handleDownloadSini}
                  />
                </section>
                <section>
                  <PeopleSpending
                    sini_mode={this.sini_mode}
                    handleDownloadSini={this.handleDownloadSini}
                  />
                </section>
                <section>
                  <Publication />
                </section>
              </main>
              {logo_content}
              <div className="button_sini_row">
                <button
                  className="add_sini_button"
                  onClick={this.pickStatus}
                  style={{ width: "100%" }}
                >
                  <img src={icon_add} alt="Add " width={40} />
                </button>
              </div>
            </>
          );
        }
      }
    }

    const modal_number_content = modal_number && (
      <ModalNumber
        modalSize="small"
        id="modal"
        isOpen={modal_number}
        onClose={this.toggleNumber}
      >
        <div
          className="box-body"
          style={{ textAlign: "center", paddingBottom: "100px" }}
        >
          {sini_list?.map((e, idx) => {
            let id = "outline_white";
            if (e.request_id === request_id) {
              id = "outline_blue";
            }
            return (
              <main key={idx} style={{ display: "inline-block" }}>
                <button
                  onClick={this.onSetId.bind(this, e.request_id)}
                  id={id}
                  className="button_circle"
                  style={{
                    fontSize: "14px",
                    width: "30px",
                    height: "30px",
                    margin: "2px",
                  }}
                >
                  <div className="button_circle_inside">{idx + 1}</div>
                </button>
              </main>
            );
          })}
        </div>
      </ModalNumber>
    );

    const modalLoginContent = modalLogin && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modalLogin}
        onClose={this.toggleLogin}
      >
        <div
          className="box-body"
          style={{ marginTop: "30px", marginBottom: "30px" }}
        >
          {loginStatus || isRegistered ? <Login isNav={true} /> : <Register />}
          {loginStatus || isRegistered ? (
            <div style={{ display: "block", marginTop: "30px" }}>
              <div className="button_account">
                {dict?.["Dont have an account?"]?.[language]}
              </div>
              <div
                className="button_register"
                onClick={this.toggleLoginRegister}
              >
                {dict?.["Register here"]?.[language]}
              </div>
            </div>
          ) : (
            <div style={{ display: "block", marginTop: "30px" }}>
              <div className="button_account">
                {dict?.["Aready have an account?"]?.[language]}
              </div>
              <div
                className="button_register"
                onClick={this.toggleLoginRegister}
              >
                {dict?.["Login instead"]?.[language]}
              </div>
            </div>
          )}
        </div>
      </Modal>
    );

    const modal_pricing_content = modal_pricing && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_pricing}
        onClose={this.toggle_pricing}
      >
        <div className="box-body">
          <ErrorFree />
        </div>
      </Modal>
    );

    const modal_download = set_modal_download && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={set_modal_download}
        onClose={this.toggleDownloadSini}
      >
        <div className="modal_download_sini">
          <h1 className="text_header"> Download SINI & Data</h1>
          <p className="text_bold">
            {this.state.type_download === "demografi"
              ? dict?.["Demographics"]?.[language]
              : this.state.type_download === "poi"
              ? dict?.["POI Distribution"]?.[language]
              : this.state.type_download === "znt"
              ? dict?.["Land Value Zone"]?.[language]
              : this.state.type_download === "landuse"
              ? dict?.["Land Use"]?.[language]
              : this.state.type_download === "isochrone"
              ? dict?.["Isochrone"]?.[language]
              : this.state.type_download === "bencana_banjir"
              ? dict?.["Flood Vulnerability"]?.[language]
              : this.state.type_download === "bencana_longsor"
              ? dict?.["Landslide Hazard"]?.[language]
              : this.state.type_download === "bencana_tsunami"
              ? dict?.["Tsunami Hazard"]?.[language]
              : this.state.type_download}
          </p>
          <div className="button_sini_row">
            <button className="button" onClick={() => this.download_json()}>
              GEOJSON
            </button>
            <button className="button" onClick={() => this.download_excel()}>
              XLSX
            </button>
            {/* <button className="button">PDF</button> */}
          </div>
        </div>
      </Modal>
    );

    return (
      <main>
        <main className="title_sini">
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <h1 className="text_header" style={{ fontWeight: "700" }}>
              SINI & Data Viewer
            </h1>
            <span
              className="button_very_small"
              id="blue"
              onClick={this.onOpenModal.bind(this, "SINI")}
              style={{
                marginLeft: "5px",
                display: "inline-block",
                verticalAlign: "top",
              }}
            >
              i
            </span>
          </div>
          {this?.props?.layer?.geometryStatus ? null : (
            <>
              {this?.props?.layer?.pick_status ? (
                <button
                  className="icon_plus_close"
                  onClick={() => this.props.pickStatus(false)}
                >
                  <div />
                  <div />
                </button>
              ) : (
                <button className="icon_plus_open" onClick={this.pickStatus}>
                  <div />
                  <div />
                </button>
              )}
            </>
          )}
        </main>
        <div className="title_sini">
          <section
            className="text_bold paragrah_title_sini"
            style={{ fontWeight: "600" }}
          >
            {this?.props?.project?.geo_project?.name}
          </section>
          {this?.props?.layer?.pick_status === false && (
            <>
              {sini_list?.length !== 0 && (
                <button
                  onClick={() => this.handleDeleteSini(request_id)}
                  style={{ marginRight: "5px" }}
                >
                  <img src={icon_delete} alt="delete" width={30} height={30} />
                </button>
              )}
            </>
          )}
        </div>
        <div className="title_sini">
          <div className="text_inferior" style={{ marginBottom: "10px" }}>
            {dict?.["Data Insight"]?.[language]}
            <button
              className="button_very_small"
              id="blue"
              onClick={this.onOpenModal.bind(this, "Analyze Lite")}
              style={{ marginLeft: "10px" }}
            >
              i
            </button>
          </div>
        </div>
        {this.props.layer.geometryStatus ? (
          <p style={{ marginTop: "1rem" }}>
            Tidak bisa menggunakan toolbox selama pengeditan objek geometri dari
            layer
          </p>
        ) : (
          <>
            {content}
            {nav_number}
            {modal_number_content}
            {modalLoginContent}
            {modal_pricing_content}
            {modal_download}
          </>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  project: state.project,
  auth: state.auth,
  payment: state.payment,
  map: state.map,
});

export default connect(mapStateToProps, {
  openModal,
  setRequestId,
  pickStatus,
  remove_sini,
  set_sini_mode,
  del_sini_mode,
  push_change_radius_sini,
  set_value_toolbox,
})(DataDashboard);
