import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import editorReducer from "./editorReducer";
import projectReducer from "./projectReducer";
import layerReducer from "./layerReducer";
import analyzerInfoReducer from "./analyzerInfoReducer";
import paymentReducer from "./paymentReducer";
import ethnoReducer from "./globalReducer";
import blogReducer from "./blogReducer";
import iotReducer from "./iotReducer";
import playReducer from "./playReducer";
import mapReducer from "./mapReducer";
import propertiesReducer from "./propertiesReducer";
import siniReducer from "./siniReducer";
import toolboxReducer from "./toolboxReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  editor: editorReducer,
  project: projectReducer,
  layer: layerReducer,
  analyzerInfo: analyzerInfoReducer,
  payment: paymentReducer,
  ethno: ethnoReducer,
  blog: blogReducer,
  iot: iotReducer,
  play: playReducer,
  map: mapReducer,
  properties: propertiesReducer,
  sini: siniReducer,
  toolbox: toolboxReducer,
});
