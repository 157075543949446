//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import Modal from "../common_modal/Modal";
import TextFieldGroupTight from "../common_input/TextFieldGroupTight";

//Redux function
import {
  proposePaid,
  proposeFree,
  setPublic,
  setPrivate,
  changePrice,
} from "../../App/actions/blogAction";
import { openModal } from "../../App/actions/authActions";

//Picture Asset
import icon_blog_public from "../../Assets/svg/icon_blog_public.svg";
import icon_blog_private from "../../Assets/svg/icon_blog_private.svg";
import icon_blog_free from "../../Assets/svg/icon_blog_free.svg";
import icon_blog_paid from "../../Assets/svg/icon_blog_paid.svg";

//General Function
import dict from "../../Data/dict.json";

class StatusBlog extends Component {
  state = {
    modal_paid: false,
    modal_change_price: false,
    price: 10000,
  };
  onOpenModal = (name) => {
    this.props.openModal(name);
  };
  handleClick = (status) => {
    const { blog_personal } = this.props.blog;
    let body = {
      blog_id: blog_personal._id,
    };
    if (status === "private") {
      this.props.setPrivate(body);
    } else if (status === "public") {
      this.props.setPublic(body);
    } else if (status === "free") {
      this.props.proposeFree(body);
    } else {
      this.togglePaid();
    }
  };
  toggleChangePrice = (price) => {
    this.setState({ modal_change_price: !this.state.modal_change_price });
    if (price) {
      this.setState({ price });
    }
  };
  togglePaid = () => {
    this.setState({ modal_paid: !this.state.modal_paid });
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  submitPaid = () => {
    const { blog_personal } = this.props.blog;
    const { price } = this.state;
    const body = {
      blog_id: blog_personal._id,
      price,
    };
    this.props.proposePaid(body);
  };
  submitChangePrice = () => {
    const { blog_personal } = this.props.blog;
    const { price } = this.state;
    const body = {
      blog_id: blog_personal._id,
      price,
    };
    this.props.changePrice(body);
  };
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { modal_paid, modal_change_price, price } = this.state;
    const { blog_personal, loadingProcess, itemLoading } = this.props.blog;
    const status = blog_personal?.market_status?.status
      ? blog_personal.market_status.status
      : "private";
    const array_status = [
      {
        name: dict["Private"][language],
        type: "private",
        desc_1: dict["Private document"][language],
        desc_2: dict["Only you can see this document."][language],
        img: icon_blog_private,
      },
      {
        name: dict["Public"][language],
        type: "public",
        desc_1: dict["Public data"][language],
        desc_2:
          dict[
            "Anyone is allowed to import the data without requiring your prior permission."
          ][language],
        img: icon_blog_public,
      },
      {
        name: dict["Free"][language],
        type: "free",
        desc_1: dict["Free with permission"][language],
        desc_2:
          dict[
            "Anyone is allowed to import the data with your prior permission."
          ][language],
        img: icon_blog_free,
      },
      {
        name: dict["Paid"][language],
        type: "paid",
        desc_1: dict["Data exchange"][language],
        desc_2:
          dict[
            "Make the data in this publication as paid data, set the price of your data after clicking the button."
          ][language],
        desc_3: `Rp. ${new Intl.NumberFormat("id-ID", {
          style: "decimal",
        }).format(blog_personal?.market_status?.price)}`,
        img: icon_blog_paid,
      },
    ];
    const itemContent = (
      <main
        style={{
          display: "inline-block",
          verticalAlign: "top",
        }}
      >
        <h1 style={{ fontSize: "23px", padding: "10px", textAlign: "left" }}>
          {dict["Choose your publication status"][language]}:{" "}
          <button
            className="button_info"
            id="blue"
            onClick={this.onOpenModal.bind(this, "Data Exchange")}
          >
            i
          </button>
        </h1>
        {array_status.map(
          ({ name, type, desc_1, desc_2, desc_3, img }, idx) => {
            let id_css = "blog_not_active";
            let button_content = (
              <button
                onClick={this.handleClick.bind(this, type)}
                className="button_block"
                id="blue"
              >
                {dict["Choose"][language]}
              </button>
            );
            if (status === type) {
              if (status === "paid") {
                id_css = "blog_active";
                button_content = (
                  <div
                    onClick={this.toggleChangePrice.bind(
                      this,
                      blog_personal.market_status.price
                    )}
                    className="button_block"
                    id="white"
                  >
                    {dict["Change Price"][language]}
                  </div>
                );
              } else {
                id_css = "blog_active";
                button_content = (
                  <div className="button_block" id="white">
                    {dict["Activated"][language]}
                  </div>
                );
              }
            }
            return (
              <div className="container_blog_status" id={id_css} key={idx}>
                <section className="container_blog_status_img">
                  <img alt={name} src={img} width="100%" />
                </section>
                <section className="container_blog_paragraph">
                  <h1 style={{ margin: "0" }}>{name}</h1>
                  <p>{desc_1}</p>
                </section>
                <section>
                  <p>{desc_2}</p>
                </section>
                <section
                  style={{
                    whiteSpace: "pre-wrap",
                    fontWeight: "bold",
                    marginTop: "10px",
                  }}
                >
                  <p>{status === "paid" && desc_3}</p>
                </section>
                <section className="container_blog_footer">
                  {button_content}
                </section>
              </div>
            );
          }
        )}
      </main>
    );
    let button_submit_paid = (
      <button onClick={this.submitPaid} className="button_block" id="blue">
        {dict["Submit"][language]}
      </button>
    );
    if (price === 0) {
      button_submit_paid = (
        <button className="button_block" id="blue_disable">
          {dict["Submit"][language]}
        </button>
      );
    } else if (loadingProcess && itemLoading === "propose_paid") {
      button_submit_paid = (
        <button className="button_block" id="blue">
          {dict["Submiting"][language]}...
        </button>
      );
    }
    const modal_paid_content = modal_paid && (
      <Modal modalSize="small" isOpen={modal_paid} onClose={this.togglePaid}>
        <div className="box-body">
          <h3 className="text_bold">
            {dict["Set up data exchange"][language]}
          </h3>
          <TextFieldGroupTight
            type="number"
            placeholder="Price (Rp)"
            name="price"
            id="price"
            value={price}
            onChange={this.onChange}
          />
          <p className="text_inferior" style={{ whiteSpace: "pre-wrap" }}>
            {`${dict["For every data purchase, you will get what you specified"][language]} `}
            <b>
              ({`Rp `}
              {new Intl.NumberFormat("id-ID", {
                style: "decimal",
              }).format(price)}
              )
            </b>
            {`. ${dict["MAPID will add 30% of the price you specify for service fees, and 10% for Ppn"][language]} `}
            ({`Rp `}
            {new Intl.NumberFormat("id-ID", {
              style: "decimal",
            }).format(1.1 * 1.3 * price)}
            ).
          </p>
          <br />
          <p className="text_inferior" style={{ whiteSpace: "pre-wrap" }}>
            {`${dict["Term of service data exchange"][language]}:
${dict["1. Data source must be legal"][language]}
${dict["2. You have the right to access the data"][language]}`}
          </p>
          <br />
          {button_submit_paid}
        </div>
      </Modal>
    );
    let button_submit_change_price = (
      <button
        onClick={this.submitChangePrice}
        className="button_block"
        id="blue"
      >
        {dict["Change"][language]}
      </button>
    );
    if (price === 0) {
      button_submit_change_price = (
        <button className="button_block" id="blue_disable">
          {dict["Change"][language]}
        </button>
      );
    } else if (loadingProcess && itemLoading === "change_price") {
      button_submit_change_price = (
        <button className="button_block" id="blue">
          {dict["Submiting"][language]}...
        </button>
      );
    }
    const modal_change_price_content = modal_change_price && (
      <Modal
        modalSize="small"
        isOpen={modal_change_price}
        onClose={this.toggleChangePrice}
      >
        <div className="box-body">
          <h3 className="text_bold">{dict["Change price"][language]}</h3>
          <TextFieldGroupTight
            type="number"
            placeholder={dict["Price (Rp)"][language]}
            name="price"
            id="price"
            value={price}
            onChange={this.onChange}
          />
          <p className="text_inferior" style={{ whiteSpace: "pre-wrap" }}>
            {`${dict["For every data purchase, you will get what you specified"][language]} `}
            <b>
              ({`Rp `}
              {new Intl.NumberFormat("id-ID", {
                style: "decimal",
              }).format(price)}
              )
            </b>
            {`. ${dict["MAPID will add 30% of the price you specify for service fees, and 10% for Ppn"][language]} `}
            ({`Rp `}
            {new Intl.NumberFormat("id-ID", {
              style: "decimal",
            }).format(1.1 * 1.3 * price)}
            ).
          </p>
          <br />
          <p className="text_inferior" style={{ whiteSpace: "pre-wrap" }}>
            {`${dict["Term of service data exchange"][language]}:
${dict["1. Data source must be legal"][language]}
${dict["2. You have the right to access the data"][language]}`}
          </p>
          <br />
          {button_submit_change_price}
        </div>
      </Modal>
    );
    return (
      <div style={{ textAlign: "center" }}>
        {itemContent}
        {modal_paid_content}
        {modal_change_price_content}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  blog: state.blog,
});
export default connect(mapStateToProps, {
  proposePaid,
  proposeFree,
  setPublic,
  setPrivate,
  openModal,
  changePrice,
})(StatusBlog);
