import isEmpty from "../validation/is-empty";

const initialState = {
  arrLayerInfo: [],
  layerInfo: {},
  settings: {
    isPoint: false,
    viewBuffer: false,
    dataColumn: [],
    selectedMode: "number",
    inspectMode: false,
    indexActive: 0,
    indexActiveSum: 0,
    sumResult: 0,
    elevationScale: 1,
    isRadiusValue: false,
    radiusScale: 1,
    modalIsOpen: false,
    colorMode: "normal",
  },
  sidebarIsOpen: true,
};
export default function analyzerInfoReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_ARRAY_INFO_ANALYZER":
      return {
        ...state,
        arrLayerInfo: makeArray(action.payload),
      };
    case "CHANGE_ARRAY_INFO_ANALYZER":
      return {
        ...state,
        arrLayerInfo: action.payload,
      };
    case "PUSH_ITEM_INFO_ANALYZER":
      return {
        ...state,
        arrLayerInfo: [action.payload, ...state.arrLayerInfo],
      };
    case "SET_SELECTED_INFO_LAYER":
      return {
        ...state,
        layerInfo: isEmpty(action.payload)
          ? {}
          : setAnalyzerInfoLayer(state.arrLayerInfo, action.payload),
        settings: isEmpty(action.payload)
          ? state.settings
          : setSettingsInfoLayer(
              action.payload,
              state.arrLayerInfo,
              state.settings
            ),
      };
    case "ADD_ARRAY_CHECKED_ITEM":
      return {
        ...state,
        arrLayerInfo: addArrayCheckedItem(
          action.payload,
          state.arrLayerInfo,
          state.settings
        ),
        layerInfo: setLayerInfoAfterAddIndex(
          action.payload.arrChecked,
          state.layerInfo,
          state.settings
        ),
        settings: setSettingsAfterAddIndex(
          action.payload.arrChecked,
          state.layerInfo.geojson,
          state.layerInfo,
          state.settings
        ),
      };
    case "REMOVE_ARRAY_CHECKED_ITEM":
      return {
        ...state,
        arrLayerInfo: removeArrayCheckedItem(
          action.payload,
          state.arrLayerInfo,
          state.settings
        ),
        layerInfo: setLayerInfoAfterRemoveIndex(
          action.payload.arrChecked,
          state.layerInfo,
          state.settings
        ),
        settings: setSettingsAfterRemoveIndex(
          action.payload.arrChecked,
          state.layerInfo.geojson,
          state.layerInfo,
          state.settings
        ),
      };
    case "SET_INDEX_ACTIVE":
      return {
        ...state,
        settings: setActiveIndex(
          action.payload,
          state.settings,
          state.layerInfo
        ),
      };
    case "SET_INDEX_ACTIVE_SUM":
      return {
        ...state,
        settings: setActiveIndexSum(
          action.payload,
          state.settings,
          state.layerInfo
        ),
      };
    case "SET_INSPECT_MODE":
      return {
        ...state,
        settings: setInspectMode(state.settings),
      };
    case "CHANGE_2D_STATE":
      return {
        ...state,
        layerInfo: change2DStateLayer(state.layerInfo),
        arrLayerInfo: change2DStateArray(action.payload, state.arrLayerInfo),
      };
    case "SET_SELECTED_MODE":
      return {
        ...state,
        layerInfo: setSelectedModeLayer(action.payload.mode, state.layerInfo),
        arrLayerInfo: setSelectedModeArray(
          action.payload.id,
          action.payload.mode,
          state.arrLayerInfo
        ),
        settings: setSelectedModeSettings(
          action.payload.mode,
          state.settings,
          state.layerInfo
        ),
      };
    case "SET_RADIUS_VALUE":
      return {
        ...state,
        layerInfo: setRadiusValueLayer(action.payload.radius, state.layerInfo),
        arrLayerInfo: setRadiusValueArray(
          action.payload.id,
          action.payload.radius,
          state.arrLayerInfo
        ),
      };
    case "CHANGE_LAYER_VISIBILITY":
      return {
        ...state,
        layerInfo: changeVisibilityLayer(state.layerInfo),
        arrLayerInfo: changeVisibilityArray(action.payload, state.arrLayerInfo),
      };
    case "SET_SELECTED_COLUMN_BY_INDEX":
      return {
        ...state,
        layerInfo: changeSelectedColumnByIndexLayer(
          action.payload,
          state.layerInfo,
          state.settings
        ),
        arrLayerInfo: changeSelectedColumnByIndexArray(
          action.payload,
          state.layerInfo,
          state.arrLayerInfo,
          state.settings
        ),
      };
    case "SET_ANALYZER_INFO_GEOJSON":
      return {
        ...state,
        layerInfo: {
          ...state.layerInfo,
          geojson: action.payload.geojson,
          originalFeatures: action.payload.features,
        },
        arrLayerInfo: setGeojson(
          action.payload.id,
          action.payload.geojson,
          state.layerInfo,
          state.arrLayerInfo
        ),
        settings: setSettingsAfterGeojson(
          action.payload,
          state.layerInfo,
          state.settings
        ),
      };
    case "SET_SETTINGS_BY_INDEX_ACTIVE":
      return {
        ...state,
        settings: setSettingsByIndexActive(
          action.payload,
          state.layerInfo,
          state.settings
        ),
      };
    case "SET_COLOR_SETTINGS":
      return {
        ...state,
        settings: addColorProperties(action.payload, state.settings),
      };
    case "SET_FILTER_PARAM":
      return {
        ...state,
        arrLayerInfo: filterFeaturesArray(
          action.payload,
          state.layerInfo,
          state.arrLayerInfo
        ),
      };
    case "FILTER_FEATURES":
      return {
        ...state,
        arrLayerInfo: filterFeaturesArray(
          action.payload,
          state.layerInfo,
          state.arrLayerInfo
        ),
        layerInfo: filterFeaturesLayer(action.payload, state.layerInfo),
        settings: setSettingsAfterFilter(
          action.payload,
          state.layerInfo,
          state.settings
        ),
      };
    case "SET_ELEVATION_SCALE":
      return {
        ...state,
        settings: { ...state.settings, elevationScale: action.payload },
      };
    case "SET_RADIUS_AS_VALUE":
      return {
        ...state,
        settings: {
          ...state.settings,
          isRadiusValue: !state.settings.isRadiusValue,
        },
      };
    case "SET_RADIUS_SCALE":
      return {
        ...state,
        settings: { ...state.settings, radiusScale: action.payload },
      };
    case "SET_MODAL_STATUS":
      return {
        ...state,
        settings: { ...state.settings, radiusScale: action.payload },
      };
    case "SET_CLASS_NUMBER":
      return {
        ...state,
        arrLayerInfo: setClassNumber(
          state.layerInfo.id,
          state.arrLayerInfo,
          action.payload
        ),
        layerInfo: { ...state.layerInfo, classNumber: action.payload },
      };
    case "SET_LEGEND_COLOR":
      return {
        ...state,
        arrLayerInfo: setLegendColor(
          state.layerInfo.id,
          state.arrLayerInfo,
          action.payload.color
        ),
        layerInfo: {
          ...state.layerInfo,
          legendColor: action.payload.color,
          colorMode: action.payload.mode,
        },
        settings: { ...state.settings, colorMode: action.payload.mode },
      };
    case "CHANGE_SIDERBAR_STATUS":
      return {
        ...state,
        sidebarIsOpen: !state.sidebarIsOpen,
      };
    default:
      return state;
  }
}

const makeArray = (payload) => {
  const arrLayerInfo = payload.map((layer, index) => {
    const l = layer.geo_layer;
    return {
      _id: l._id,
      id: l.link,
      name: l.name,
      arrChecked: [],
      visibility: false,
      selectedColumn: "",
      selectedMode: "number",
      radius: 200,
      view2D: false,
      classNumber: l.hasOwnProperty("legend") ? l.legend.class_number : 6,
      filterParam: null,
      fields: l.fields,
      legendColor: "darkgreen,yellow,darkred",
      colorMode: "normal",
    };
  });

  return arrLayerInfo;
};

const addArrayCheckedItem = (payload, arrLayerInfo, settings) => {
  let newArr = arrLayerInfo.slice();
  let idx = arrLayerInfo.findIndex((l) => l.id === payload.id);
  let layerInfo = Object.assign({}, arrLayerInfo[idx]);
  newArr[idx] = payload;

  let indexActive = layerInfo.arrChecked.length < 1 ? 0 : settings.indexActive;

  newArr[idx].selectedColumn = payload.arrChecked[indexActive].key;

  return newArr;
};
const removeArrayCheckedItem = (payload, arrLayerInfo, settings) => {
  let newArr = arrLayerInfo.slice();
  let idx = arrLayerInfo.findIndex((l) => l.id === payload.id);

  newArr[idx] = payload;

  let isEmpty = payload.arrChecked.length < 1;
  let isOneItem = payload.arrChecked.length === 1;
  let isGreater = settings.indexActive > payload.arrChecked.length - 1;

  let indexActive =
    isEmpty || isOneItem
      ? 0
      : isGreater
      ? payload.arrChecked.length - 1
      : settings.indexActive;

  if (isEmpty) {
    newArr[idx].selectedColumn = "";
  } else {
    newArr[idx].selectedColumn = payload.arrChecked[indexActive].key;
  }
  return newArr;
};

const setAnalyzerInfoLayer = (arrLayerInfo, id) => {
  let idx = arrLayerInfo.findIndex((l) => l.id === id);
  return arrLayerInfo[idx];
};
const setSettingsInfoLayer = (id, arrLayerInfo, settings) => {
  const layerInfo = setAnalyzerInfoLayer(arrLayerInfo, id);
  const geojson = layerInfo.geojson;

  if (geojson) {
    const newSettings = setSettingsAfterGeojson(geojson, layerInfo, settings);
    return newSettings;
  } else {
    return settings;
  }
};
const setActiveIndex = (idx, settings, layerInfo) => {
  let newSettings = Object.assign({}, settings);
  newSettings.indexActive = idx;
  return newSettings;
};

const setActiveIndexSum = (idx, settings, layerInfo) => {
  let newSettings = Object.assign({}, settings);
  newSettings.indexActiveSum = idx;

  if (layerInfo.hasOwnProperty("geojson")) {
    newSettings.sumResult = getSummaryColumn(
      layerInfo.arrChecked[idx].key,
      layerInfo.geojson.features
    );
  } else {
    newSettings.sumResult = undefined;
  }
  /** */
  return newSettings;
};

const setInspectMode = (settings) => {
  let newSettings = Object.assign({}, settings);
  newSettings.inspectMode = !settings.inspectMode;

  return newSettings;
};

const change2DStateLayer = (layerInfo) => {
  let newLayerInfo = Object.assign({}, layerInfo);
  newLayerInfo.view2D = !layerInfo.view2D;
  return newLayerInfo;
};

const change2DStateArray = (id, arrLayerInfo) => {
  let newArrLayer = arrLayerInfo.slice();
  let idx = arrLayerInfo.findIndex((l) => l.id === id);
  newArrLayer[idx].view2D = !arrLayerInfo[idx].view2D;
  return newArrLayer;
};

const setSelectedModeLayer = (mode, layerInfo) => {
  let newLayerInfo = Object.assign({}, layerInfo);
  newLayerInfo.selectedMode = mode;
  return newLayerInfo;
};

const setSelectedModeArray = (id, mode, arrLayerInfo) => {
  let newArrLayer = arrLayerInfo.slice();
  let idx = arrLayerInfo.findIndex((l) => l.id === id);
  newArrLayer[idx].selectedMode = mode;
  return newArrLayer;
};

const setSelectedModeSettings = (mode, settings, layerInfo) => {
  let newSettings = Object.assign({}, settings);
  newSettings.selectedMode = mode;
  newSettings.dataColumn =
    layerInfo.arrChecked.length > 0
      ? layerInfo.geojson.features
          .map((d) => d.properties[layerInfo.selectedColumn])
          .sort()
          .filter((val, id, array) => {
            return array.indexOf(val) === id;
          })
      : [];
  return newSettings;
};
const setRadiusValueLayer = (radius, layerInfo) => {
  let newLayerInfo = Object.assign({}, layerInfo);
  newLayerInfo.radius = radius;
  return newLayerInfo;
};

const setRadiusValueArray = (id, radius, arrLayerInfo) => {
  let newArrLayer = arrLayerInfo.slice();
  let idx = arrLayerInfo.findIndex((l) => l.id === id);
  newArrLayer[idx].radius = radius;
  return newArrLayer;
};

const changeVisibilityLayer = (layerInfo) => {
  let newLayerInfo = Object.assign({}, layerInfo);
  newLayerInfo.visibility = !layerInfo.visibility;
  return newLayerInfo;
};

const changeVisibilityArray = (id, arrLayerInfo) => {
  let newArrLayer = arrLayerInfo.slice();
  let idx = arrLayerInfo.findIndex((l) => l.id === id);
  newArrLayer[idx].visibility = !arrLayerInfo[idx].visibility;
  return newArrLayer;
};

const changeSelectedColumnByIndexLayer = (
  { idx, isDifferent, color },
  layerInfo,
  settings
) => {
  const { colorMode } = settings;
  let newLayerInfo = Object.assign({}, layerInfo);
  let arrCheckedIsEmpty = layerInfo.arrChecked.length < 1;
  newLayerInfo.selectedColumn = arrCheckedIsEmpty
    ? ""
    : layerInfo.arrChecked[idx].key;

  if (isDifferent) {
    let newColor = color;

    newLayerInfo.legendColor =
      colorMode === "random" ? newColor : layerInfo.legendColor;
  }

  return newLayerInfo;
};

const changeSelectedColumnByIndexArray = (
  { idx, isDifferent, color },
  layerInfo,
  arrLayerInfo,
  settings
) => {
  const { colorMode } = settings;
  let newArrLayer = arrLayerInfo.slice();
  let idxArray = arrLayerInfo.findIndex((l) => l.id === layerInfo.id);
  let arrCheckedIsEmpty = arrLayerInfo[idxArray].arrChecked.length < 1;

  newArrLayer[idxArray].selectedColumn = arrCheckedIsEmpty
    ? ""
    : arrLayerInfo[idxArray].arrChecked[idx].key;

  if (isDifferent) {
    let newColor = color;
    newArrLayer[idxArray].legendColor =
      colorMode === "random" ? newColor : layerInfo.legendColor;
  }

  return newArrLayer;
};

const setGeojson = (id, geojson, layerInfo, arrLayerInfo) => {
  try {
    let newArrLayer = arrLayerInfo.slice();
    let idx = arrLayerInfo.findIndex((l) => l._id === id);
    newArrLayer[idx].geojson = geojson;
    newArrLayer[idx].originalFeatures = geojson.features;
    return newArrLayer;
  } catch (err) {
    return arrLayerInfo;
  }
};
const setLayerInfoAfterAddIndex = (arrChecked, layerInfo, settings) => {
  let newSettings = Object.assign({}, settings);
  let newLayerInfo = Object.assign({}, layerInfo);
  newSettings.indexActive =
    layerInfo.arrChecked.length < 1 ? 0 : settings.indexActive;

  newLayerInfo.arrChecked = arrChecked;
  newLayerInfo.selectedColumn = arrChecked[newSettings.indexActive].key;

  return newLayerInfo;
};

const setLayerInfoAfterRemoveIndex = (arrChecked, layerInfo, settings) => {
  let newSettings = Object.assign({}, settings);
  let newLayerInfo = Object.assign({}, layerInfo);

  let isEmpty = arrChecked.length < 1;
  let isOneItem = arrChecked.length === 1;
  let isGreater = settings.indexActive > arrChecked.length - 1;

  newLayerInfo.arrChecked = arrChecked;

  newSettings.indexActive =
    isEmpty || isOneItem
      ? 0
      : isGreater
      ? arrChecked.length - 1
      : settings.indexActive;

  if (isEmpty) {
    newLayerInfo.selectedColumn = "";
  } else {
    newLayerInfo.selectedColumn = arrChecked[newSettings.indexActive].key;
  }
  return newLayerInfo;
};
const setSettingsAfterGeojson = (geojson, layerInfo, settings) => {
  let max = getMaxValue(geojson, layerInfo.selectedColumn);
  let min = getMinValue(geojson, layerInfo.selectedColumn);
  const { arrChecked } = layerInfo;
  let newSettings = Object.assign({}, settings);

  if (layerInfo.hasOwnProperty("geojson") && arrChecked.length > 0) {
    newSettings.sumResult = getSummaryColumn(
      layerInfo.arrChecked[settings.indexActive].key,
      geojson.features
    );
  } else {
    newSettings.sumResult = undefined;
  }

  const dataColumn = layerInfo.geojson.features
    .map((d) => d.properties[layerInfo.selectedColumn])
    .sort()
    .filter((val, id, array) => {
      return array.indexOf(val) === id;
    });

  newSettings.maxValue = max;
  newSettings.minValue = min;
  newSettings.dataColumn = dataColumn;
  return newSettings;
};

const setSettingsAfterAddIndex = (arrChecked, geojson, layerInfo, settings) => {
  let newSettings = Object.assign({}, settings);
  let newLayerInfo = Object.assign({}, layerInfo);
  newSettings.indexActive =
    layerInfo.arrChecked.length < 1 ? 0 : settings.indexActive;
  newSettings.indexActiveSum =
    layerInfo.arrChecked.length < 1 ? 0 : settings.indexActiveSum;
  newLayerInfo.arrChecked = arrChecked;

  newSettings.maxValue = getMaxValue(
    geojson,
    arrChecked[newSettings.indexActive].key
  );
  newSettings.minValue = getMinValue(
    geojson,
    arrChecked[newSettings.indexActive].key
  );
  const { sumResult } = setActiveIndexSum(
    newSettings.indexActiveSum,
    settings,
    newLayerInfo
  );
  newSettings.sumResult = sumResult;
  return newSettings;
};

const setSettingsAfterRemoveIndex = (
  arrChecked,
  geojson,
  layerInfo,
  settings
) => {
  let newSettings = Object.assign({}, settings);
  let newLayerInfo = Object.assign({}, layerInfo);

  let isEmpty = arrChecked.length < 1;
  let isOneItem = arrChecked.length === 1;
  let isGreater = settings.indexActive > arrChecked.length - 1;
  let isGreaterSum = settings.indexActiveSum > arrChecked.length - 1;

  newLayerInfo.arrChecked = arrChecked;

  newSettings.indexActive =
    isEmpty || isOneItem
      ? 0
      : isGreater
      ? arrChecked.length - 1
      : settings.indexActive;
  newSettings.indexActiveSum =
    isEmpty || isOneItem
      ? 0
      : isGreaterSum
      ? arrChecked.length - 1
      : settings.indexActiveSum;

  if (isEmpty) {
    newSettings.maxValue = null;
    newSettings.minValue = null;
    newSettings.sumResult = undefined;
  } else {
    newSettings.maxValue = getMaxValue(
      geojson,
      arrChecked[newSettings.indexActive].key
    );
    newSettings.minValue = getMinValue(
      geojson,
      arrChecked[newSettings.indexActive].key
    );
    const { sumResult } = setActiveIndexSum(
      newSettings.indexActiveSum,
      settings,
      newLayerInfo
    );
    newSettings.sumResult = sumResult;
  }
  return newSettings;
};

const getMinValue = (geojson, column) => {
  return Math.min.apply(
    Math,
    geojson.features.map(function (o) {
      return o.properties[column];
    })
  );
};

const getMaxValue = (geojson, column) => {
  return Math.max.apply(
    Math,
    geojson.features.map(function (o) {
      return o.properties[column];
    })
  );
};

const setSettingsByIndexActive = (idx, layerInfo, settings) => {
  let newSettings = Object.assign({}, settings);
  let arrCheckedIsEmpty = layerInfo.arrChecked.length < 1;
  let column = arrCheckedIsEmpty ? "" : layerInfo.arrChecked[idx].key;
  let max = getMaxValue(layerInfo.geojson, column);
  let min = getMinValue(layerInfo.geojson, column);
  const dataColumn = layerInfo.geojson.features
    .map((d) => d.properties[column])
    .sort()
    .filter((val, id, array) => {
      return array.indexOf(val) === id;
    });

  newSettings.maxValue = max;
  newSettings.minValue = min;
  newSettings.dataColumn = dataColumn;
  return newSettings;
};

const addColorProperties = (color, settings) => {
  let newSettings = Object.assign({}, settings);
  newSettings.colorArray = color;
  return newSettings;
};

// const filterFeaturesLayer = (filterParam) => {
//     if(filterParam){
//         getFilteredFeatures
//     }else{
//         return originalFeatures;
//     }
// }

const filterFeaturesLayer = (filterParam, layerInfo) => {
  const newLayerInfo = Object.assign({}, layerInfo);

  const features = layerInfo.originalFeatures.slice();
  let newGeojson;
  layerInfo.geojson.features = features;
  if (filterParam) {
    const { op, col, param } = filterParam;
    newGeojson = Object.assign({}, newLayerInfo.geojson);
    newGeojson.features = getFilteredFeatures(
      newLayerInfo.geojson.features,
      op,
      col,
      param
    );
    newLayerInfo.geojson = newGeojson;
  } else {
    newGeojson = Object.assign({}, newLayerInfo.geojson);
    newGeojson.features = features;
    newLayerInfo.geojson = newGeojson;
  }
  newLayerInfo.filterParam = filterParam;
  return newLayerInfo;
};

const filterFeaturesArray = (filterParam, layerInfo, arrLayerInfo) => {
  const newArrLayer = arrLayerInfo.slice();
  const idx = arrLayerInfo.findIndex((l) => l.id === layerInfo.id);
  const features = newArrLayer[idx].originalFeatures.slice();
  let newGeojson;
  newArrLayer[idx].geojson.features = features;

  if (filterParam) {
    const { op, col, param } = filterParam;
    newGeojson = Object.assign({}, newArrLayer[idx].geojson);
    newGeojson.features = getFilteredFeatures(
      newArrLayer[idx].geojson.features,
      op,
      col,
      param
    );
    newArrLayer[idx].geojson = newGeojson;
  } else {
    newGeojson = Object.assign({}, newArrLayer[idx].geojson);
    newGeojson.features = features;
    newArrLayer[idx].geojson = newGeojson;
  }
  newArrLayer[idx].filterParam = filterParam;
  return newArrLayer;
};

const setSettingsAfterFilter = (filterParam, layerInfo, settings) => {
  const features = layerInfo.originalFeatures.slice();
  let newFeatures;
  let newSettings = Object.assign({}, settings);
  if (filterParam) {
    const { op, col, param } = filterParam;
    newFeatures = getFilteredFeatures(features, op, col, param);
  } else {
    newFeatures = features.slice();
  }

  if (layerInfo.arrChecked.length > 0) {
    newSettings.sumResult = getSummaryColumn(
      layerInfo.arrChecked[settings.indexActiveSum].key,
      newFeatures
    );
  }
  return newSettings;
};
const getFilteredFeatures = (ft, op, col, pr) => {
  if (op === ">=") {
    ft = ft.filter((f) => Number(f.properties[col]) >= Number(pr));
  } else if (op === "<=") {
    ft = ft.filter((f) => Number(f.properties[col]) <= Number(pr));
  } else if (op === "!=") {
    ft = ft.filter((f) => String(f.properties[col]) !== pr);
  } else if (op === ">") {
    ft = ft.filter((f) => Number(f.properties[col]) > Number(pr));
  } else if (op === "<") {
    ft = ft.filter((f) => Number(f.properties[col]) < Number(pr));
  } else if (op === "=") {
    ft = ft.filter(
      (f) => String(f.properties[col]).toUpperCase() === pr.toUpperCase()
    );
  } else if (op === "has") {
    ft = ft.filter((f) =>
      String(f.properties[col]).toUpperCase().startsWith(pr.toUpperCase())
    );
  }

  return ft;
};

const getSummaryColumn = (colName, features) => {
  let sum;
  let arr = features.map((x) => x.properties[colName]);
  sum = arr.reduce((a, b) => Number(a) + Number(b), 0);
  return sum;
};

const setClassNumber = (id, arrLayerInfo, classNumber) => {
  let newArrLayer = arrLayerInfo.slice();
  let idx = arrLayerInfo.findIndex((l) => l.id === id);
  newArrLayer[idx].classNumber = classNumber;
  return newArrLayer;
};

const setLegendColor = (id, arrLayerInfo, legendColor) => {
  let newArrLayer = arrLayerInfo.slice();
  let idx = arrLayerInfo.findIndex((l) => l.id === id);
  newArrLayer[idx].legendColor = legendColor;
  return newArrLayer;
};
