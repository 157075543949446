import React from "react";
import { GridToolbarContainer } from "@mui/x-data-grid";

function ApiFooterTable({ add_function, data }) {
  return (
    <GridToolbarContainer>
      {/* <div
        style={{
          padding: "5px",
          width: "100%",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div
          onClick={() => {
            if (add_function) {
              add_function(data);
            }
          }}
          className="button_inactive bg_blue w_30 h_30 rounded_5 center_perfect text_white"
        >
          +
        </div>
      </div> */}
    </GridToolbarContainer>
  );
}

export default ApiFooterTable;
