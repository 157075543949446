//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Bar } from "react-chartjs-2";
//Personal Component
//Redux function
import { openModal } from "../App/actions/authActions";
//Picture Asset
//General Function
class MapAccess extends Component {
  onOpenModal = (name) => {
    this.props.openModal(name);
  };
  render() {
    const map_access_monthly = this.props.auth?.user?.map_access_monthly
      ? this.props.auth?.user?.map_access_monthly
      : [];
    let data_time_series = map_access_monthly.map((e) => {
      return {
        x: new Date(e.month_id),
        y: e.access,
      };
    });
    if (data_time_series.length < 10) {
      //86400 unix = 1 day
      //1 month = 30.5 day
      const month_unix = 86400 * 30.5 * 1000;
      const date_latest = map_access_monthly?.[map_access_monthly.length - 1]
        ?.month_id
        ? map_access_monthly?.[map_access_monthly.length - 1]?.month_id
        : Date.now();
      const date_latest_unix = parseInt(
        (new Date(date_latest).getTime() / 1000).toFixed(0)
      );
      const date_first = map_access_monthly?.[0]?.month_id
        ? map_access_monthly?.[0]?.month_id
        : Date.now();
      const date_first_unix = parseInt(
        (new Date(date_first).getTime() / 1000).toFixed(0)
      );
      const data_add_before = [
        {
          x: new Date(date_first_unix * 1000 - 1 * month_unix),
          y: 0,
        },
      ];
      const data_add_after = [
        {
          x: new Date(date_latest_unix * 1000 + 1 * month_unix),
          y: 0,
        },
        {
          x: new Date(date_latest_unix * 1000 + 2 * month_unix),
          y: 0,
        },
        {
          x: new Date(date_latest_unix * 1000 + 3 * month_unix),
          y: 0,
        },
        {
          x: new Date(date_latest_unix * 1000 + 4 * month_unix),
          y: 0,
        },
        {
          x: new Date(date_latest_unix * 1000 + 5 * month_unix),
          y: 0,
        },
        {
          x: new Date(date_latest_unix * 1000 + 6 * month_unix),
          y: 0,
        },
        {
          x: new Date(date_latest_unix * 1000 + 7 * month_unix),
          y: 0,
        },
        {
          x: new Date(date_latest_unix * 1000 + 8 * month_unix),
          y: 0,
        },
        {
          x: new Date(date_latest_unix * 1000 + 9 * month_unix),
          y: 0,
        },
        {
          x: new Date(date_latest_unix * 1000 + 10 * month_unix),
          y: 0,
        },
      ];
      data_time_series = data_time_series.concat(data_add_after);
      data_time_series = data_add_before.concat(data_time_series);
    }
    const data = {
      datasets: [
        {
          backgroundColor: "#0ca5eb",
          data: data_time_series,
        },
      ],
    };
    const options = {
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            id: "month_scale",
            display: true,
            type: "time",
            time: {
              unit: "month",
              displayFormats: {
                month: "MMM",
              },
            },
            distribution: "linear",
            ticks: {
              beginAtZero: true,
            },
          },
          {
            id: "year_scale",
            scaleLabel: {
              display: true,
              labelString: "Time",
            },
            display: true,
            type: "time",
            time: {
              unit: "year",
              displayFormats: {
                year: "YYYY",
              },
            },
            distribution: "linear",
            ticks: {
              beginAtZero: true,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            scaleLabel: {
              display: true,
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };
    return (
      <div id="main_container">
        <div className="header_medium" style={{ marginBottom: "50px" }}>
          Map View API
          <button
            className="button_info"
            id="blue"
            onClick={this.onOpenModal.bind(this, "SINI")}
            style={{ marginLeft: "10px" }}
          >
            i
          </button>
        </div>
        <Bar data={data} width={100} height={40} options={options} />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { openModal })(MapAccess);
