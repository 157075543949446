/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Map from "./Map";

/*REDUX FUNCTION*/
import { edit_location } from "../../App/actions/blogAction";
import { generate_city_object } from "../../App/actions/siniActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class EditLocation extends Component {
  state = {
    long: 109,
    lat: -7,
    PROVINSI: "",
    KAB_KOT: "",
    KECAMATAN: "",
    DESA: "",
  };

  componentDidMount() {
    const { blog_personal } = this.props.blog;
    if (blog_personal?.address?.DESA) {
      const { PROVINSI, KAB_KOT, KECAMATAN, DESA } = blog_personal?.address;
      const long = blog_personal?.location?.coordinates?.[0];
      const lat = blog_personal?.location?.coordinates?.[1];
      this.setState({ PROVINSI, KAB_KOT, KECAMATAN, DESA, long, lat });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this?.props?.properties?.city_object?.kelurahan !==
      prevProps?.properties?.city_object?.kelurahan
    ) {
      const { kelurahan, kecamatan, kota, provinsi } =
        this.props.properties.city_object;
      this.setState({
        PROVINSI: provinsi,
        KAB_KOT: kota,
        KECAMATAN: kecamatan,
        DESA: kelurahan,
      });
    }
  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  on_get_geocode = (prm) => {
    const { long, lat } = prm;
    const body = { long, lat };
    this.props.generate_city_object(body);
  };

  on_submit_geocode = () => {
    const { long, lat, PROVINSI, KAB_KOT, KECAMATAN, DESA } = this.state;
    const body = {
      blog_id: this?.props?.blog?.blog_personal?._id,
      long,
      lat,
      PROVINSI,
      KAB_KOT,
      KECAMATAN,
      DESA,
    };
    this.props.edit_location(body);
    this.props.toggle_edit_location();
  };

  render() {
    //local storage

    //local state
    const { long, lat, PROVINSI, KAB_KOT, KECAMATAN, DESA } = this.state;

    //global props

    //content

    return (
      <div>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "350px",
            zIndex: 2001,
          }}
        >
          <Map
            lat={lat}
            lng={long}
            handleChange={this.handleChange}
            on_get_geocode={this.on_get_geocode}
          />
        </div>
        <br />
        long
        <input
          type="number"
          name="long"
          id="long"
          value={long}
          onChange={this.handleChange}
        />
        <br />
        lat
        <input
          type="number"
          name="lat"
          id="lat"
          value={lat}
          onChange={this.handleChange}
        />
        <br />
        PROVINSI
        <input
          type="text"
          name="PROVINSI"
          id="PROVINSI"
          value={PROVINSI}
          onChange={this.handleChange}
        />
        <br />
        KAB_KOT
        <input
          type="text"
          name="KAB_KOT"
          id="KAB_KOT"
          value={KAB_KOT}
          onChange={this.handleChange}
        />
        <br />
        KECAMATAN
        <input
          type="text"
          name="KECAMATAN"
          id="KECAMATAN"
          value={KECAMATAN}
          onChange={this.handleChange}
        />
        <br />
        DESA
        <input
          type="text"
          name="DESA"
          id="DESA"
          value={DESA}
          onChange={this.handleChange}
        />
        <br />
        <button
          className="button_standard use_margin"
          onClick={() =>
            this.on_get_geocode({
              lat,
              long,
            })
          }
        >
          Generate wilayah
        </button>
        <br />
        {DESA && (
          <button
            className="button_standard use_margin"
            onClick={this.on_submit_geocode}
          >
            Submit lokasi
          </button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  blog: state.blog,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  edit_location,
  generate_city_object,
})(EditLocation);
