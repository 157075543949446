//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function
import dict from "../../Data/dict.json";

//Picture Asset
import icon_download from "../../Assets/svg/icon_download.svg";
import icon_view_blue from "../../Assets/svg/icon_view_blue.svg";
import icon_view_grey from "../../Assets/svg/icon_view_grey.svg";
import { IconOpen } from "./icon";

//General Function

class PeopleSpending extends Component {
  state = {
    hide: false,
  };

  render() {
    const { hide } = this.state;
    const { request_id, sini_list } = this.props.layer;
    const language = localStorage?.language ? localStorage?.language : "ina";

    const people_spending = sini_list?.filter(
      (e) => e.request_id === request_id
    )[0].people_spending;

    const features = people_spending?.features;
    const feature = features?.[0];
    let properties = feature?.properties;

    let content;

    if (properties) {
      const {
        MAKANAN, //1
        NON_MKN, //2
        MKN_MNM_JD, //3
        ROTI_TAWAR, //4
        RTI_MNS_DL, //5
        K_KRG_BSKT, //6
        KUE_BASAH, //7
        MKN_GRGN, //8
        B_KCNG_IJO, //9
        GDO_PCL_DL, //10
        NASI_CMPR, //11
        NASI_GRNG, //12
        NASI_PTH, //13
        LTNG_SYR, //14
        STO_GLE_DL, //15
        SYR_MTNG, //16
        SATE_TSNG, //17
        MIE_DL, //18
        MIE_INSTN, //19
        MK_ANK2_DL, //20
        IKAN_MTNG, //21
        AYAM_DGNG, //22
        DGNG_OLHN, //23
        BBR_AYAM, //24
        SIOMAY_BTG, //25
        MKN_JD_DL, //26
        AIR_KMSN, //27
        AIRKMSN_GL, //28
        AIRTH_KMSN, //29
        MNMN_KSHTN, //30
        MNMN_JADI, //31
        ES_KRIM, //32
        ES_LAIN, //33
        MNMN_KRS, //34
      } = properties;

      // change: "test",

      const array = [
        {
          name_origin: "MAKANAN",
          name: "MAKANAN",
          value: MAKANAN,
          change: "Makanan",
        },
        {
          name_origin: "NON_MKN",
          name: "NON_MKN",
          value: NON_MKN,
          change: "Non Makanan",
        },
      ];

      const array_per_minggu = [
        {
          name_origin: "MKN_MNM_JD",
          name: "MKN_MNM_JD",
          value: MKN_MNM_JD,
          change: "Makanan dan Minuman Jadi",
        },
        {
          name_origin: "ROTI_TAWAR",
          name: "ROTI_TAWAR",
          value: ROTI_TAWAR,
          change: "Roti Tawar",
        },
        {
          name_origin: "RTI_MNS_DL",
          name: "RTI_MNS_DL",
          value: RTI_MNS_DL,
          change: "Roti Manis dan lainnya",
        },
        {
          name_origin: "K_KRG_BSKT",
          name: "K_KRG_BSKT",
          value: K_KRG_BSKT,
          change: "Kue Kering, Biskuit, dan Semprong",
        },
        {
          name_origin: "KUE_BASAH",
          name: "KUE_BASAH",
          value: KUE_BASAH,
          change: "Kue Basah (Kue Lapis, Bika Ambon, Lemper, dan Semacamnya)",
        },
        {
          name_origin: "MKN_GRGN",
          name: "MKN_GRGN",
          value: MKN_GRGN,
          change: "Makanan Gorengan",
        },
        {
          name_origin: "B_KCNG_IJO",
          name: "B_KCNG_IJO",
          value: B_KCNG_IJO,
          change: "Bubur Kacang Hijau",
        },
        {
          name_origin: "GDO_PCL_DL",
          name: "GDO_PCL_DL",
          value: GDO_PCL_DL,
          change: "Gado-gado, Ketoprak, dan Pecel",
        },
        {
          name_origin: "NASI_CMPR",
          name: "NASI_CMPR",
          value: NASI_CMPR,
          change: " Nasi Campur/Rames",
        },
        {
          name_origin: "NASI_GRNG",
          name: "NASI_GRNG",
          value: NASI_GRNG,
          change: "Nasi Goreng",
        },
        {
          name_origin: "NASI_PTH",
          name: "NASI_PTH",
          value: NASI_PTH,
          change: "Nasi Putih",
        },
        {
          name_origin: "LTNG_SYR",
          name: "LTNG_SYR",
          value: LTNG_SYR,
          change: "Lontong/Ketupat Sayur",
        },
        {
          name_origin: "STO_GLE_DL",
          name: "STO_GLE_DL",
          value: STO_GLE_DL,
          change: "Soto, Gule, Sop, Rawon, dan Cincang",
        },
        {
          name_origin: "SYR_MTNG",
          name: "SYR_MTNG",
          value: SYR_MTNG,
          change: "Sayur Matang (Ditumis, Disantan, dan Semacamnya)",
        },
        {
          name_origin: "SATE_TSNG",
          name: "SATE_TSNG",
          value: SATE_TSNG,
          change: "Sate dan Tongseng",
        },
        {
          name_origin: "MIE_DL",
          name: "MIE_DL",
          value: MIE_DL,
          change: "Mie Bakso, Mie Rebus, dan Mie Goreng",
        },
        {
          name_origin: "MIE_INSTN",
          name: "MIE_INSTN",
          value: MIE_INSTN,
          change: "Mie Instan",
        },
        {
          name_origin: "MK_ANK2_DL",
          name: "MK_ANK2_DL",
          value: MK_ANK2_DL,
          change: "Makanan Ringan Anak-anak dan Krupuk/Kripik",
        },
        {
          name_origin: "IKAN_MTNG",
          name: "IKAN_MTNG",
          value: IKAN_MTNG,
          change: "Ikan Matang",
        },
        {
          name_origin: "AYAM_DGNG",
          name: "AYAM_DGNG",
          value: AYAM_DGNG,
          change: "Ayam/Daging Matang (Ayam Goreng, Rendang, dan Semacamnya)",
        },
        {
          name_origin: "DGNG_OLHN",
          name: "DGNG_OLHN",
          value: DGNG_OLHN,
          change:
            "Daging Olahan (Sosis, Nugget, Daging Asap, dan Semacamnya) Matang",
        },
        {
          name_origin: "BBR_AYAM",
          name: "BBR_AYAM",
          value: BBR_AYAM,
          change: "Bubur Ayam",
        },
        {
          name_origin: "SIOMAY_BTG",
          name: "SIOMAY_BTG",
          value: SIOMAY_BTG,
          change: "Siomay dan Batagor",
        },
        {
          name_origin: "MKN_JD_DL",
          name: "MKN_JD_DL",
          value: MKN_JD_DL,
          change: "Makanan Jadi Lainnya",
        },
        {
          name_origin: "AIR_KMSN",
          name: "AIR_KMSN",
          value: AIR_KMSN,
          change: "Air Kemasan ",
        },
        {
          name_origin: "AIRKMSN_GL",
          name: "AIRKMSN_GL",
          value: AIRKMSN_GL,
          change: " Air Kemasan Galon",
        },
        {
          name_origin: "AIRTH_KMSN",
          name: "AIRTH_KMSN",
          value: AIRTH_KMSN,
          change: "Air Teh Kemasan dan Minuman Bersoda/Mengandung CO2",
        },
        {
          name_origin: "MNMN_KSHTN",
          name: "MNMN_KSHTN",
          value: MNMN_KSHTN,
          change: "Sari Buah Kemasan, Minuman Kesehatan, dan Minuman Berenergi",
        },
        {
          name_origin: "MNMN_JADI",
          name: "MNMN_JADI",
          value: MNMN_JADI,
          change:
            "Minuman Jadi (Kopi, Kopi Susu, Teh, Susu Coklat, dan Semacamnya)",
        },
        {
          name_origin: "ES_KRIM",
          name: "ES_KRIM",
          value: ES_KRIM,
          change: "Es Krim",
        },
        {
          name_origin: "ES_LAIN",
          name: "ES_LAIN",
          value: ES_LAIN,
          change: "Es Lainnya",
        },
        {
          name_origin: "MNMN_KRS",
          name: "MNMN_KRS",
          value: MNMN_KRS,
          change: "Minuman Keras",
        },
      ];

      content = (
        <section className="sini_card">
          <div className="sini_card_top">
            <div style={{ zIndex: "5" }}>
              <h2>{dict?.["People Spending"]?.[language]}</h2>

              <img
                src={
                  this.props.layer.sini_mode.find((d) => d === "demografi")
                    ? icon_view_blue
                    : icon_view_grey
                }
                title={
                  this.props.layer.sini_mode.find((d) => d === "demografi")
                    ? dict?.["Hide"]?.[language]
                    : dict?.["View"]?.[language]
                }
                style={{ marginRight: "5px", cursor: "pointer" }}
                alt="Add "
                width={25}
                onClick={() => this.props.sini_mode("demografi")}
              />
              <img
                src={icon_download}
                title="Download Data"
                alt="Add "
                style={{ cursor: "pointer" }}
                width={25}
                onClick={() =>
                  this.props.handleDownloadSini(request_id, "demografi")
                }
              />
            </div>
            <IconOpen
              onClick={() => this.setState({ hide: !hide })}
              hide={hide}
            />
          </div>
          <main className={hide ? "sini_scroll" : "sini_scroll_hide"}>
            <p className="text_bold">
              {dict?.["Monthly capita expenditure"]?.[language]}
            </p>

            <table className="sini_table" style={{ width: "100%" }}>
              <tbody>
                {array.map(({ name, value, change }, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="text_left">
                        {dict?.[change ? change : name]?.[language]}
                      </td>
                      <td
                        className="text_bold text_right"
                        style={{ width: "120px" }}
                      >
                        Rp.{" "}
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(value)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <br />

            <p className="text_bold">
              {dict?.["Spending capita week"]?.[language]}
            </p>

            <table className="sini_table" style={{ width: "100%" }}>
              <tbody>
                {array_per_minggu.map(({ name, value, change }, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="text_left">
                        {dict?.[change ? change : name]?.[language]}
                      </td>
                      <td
                        className="text_bold text_right"
                        style={{ width: "100px" }}
                      >
                        Rp.{" "}
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(value)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </main>
        </section>
      );
      return content;
    } else {
      let no_content = (
        <section className="sini_card">
          <div className="sini_card_top">
            <div style={{ zIndex: "5" }}>
              <h2>{dict?.["People Spending"]?.[language]}</h2>
              <p>
                {
                  dict?.[
                    "There is no data at the point you selected, we will complete it soon."
                  ]?.[language]
                }
              </p>
            </div>
          </div>
        </section>
      );

      return no_content;
    }
  }
}
const mapStateToProps = (state) => ({
  layer: state.layer,
  auth: state.auth,
});
export default connect(mapStateToProps, {})(PeopleSpending);
