/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value } from "../../App/actions/dataActions";
import {
  filter_isi_layer,
  reset_filter,
  undo_filter_isi_layer,
} from "../../App/actions/dataActions";

/*PICTURE ASSET*/
import close from "../../Assets/svg/close.svg";

/*GENERAL*/

class Legend extends Component {
  state = {};

  toggle_legend = () => {
    const { legend_status } = this.props.map;
    this.props.set_value("legend_status", !legend_status);
  };

  on_filter_isi_layer = (field_key, value) => {
    const { layer_id } = this.props.layer;

    const body = {
      geo_layer_id: layer_id,
      field_key,
      value,
    };

    this.props.filter_isi_layer(body);
  };

  on_reset_filter = () => {
    const { layer_id } = this.props.layer;
    const body = { geo_layer_id: layer_id };
    this.props.reset_filter(body);
  };

  render() {
    //local storage

    //local state

    //global props
    const { legend_status } = this.props.map;
    const { geo_layer_list, layer_id } = this.props.layer;

    //legend_content
    const layer = geo_layer_list.find(
      (item) => item?.geo_layer?._id === layer_id
    )?.geo_layer;

    let legend_content;

    /*
    selected_column
    range
    */

    if (legend_status) {
      if (
        layer?.globalStyleMode === "by_value" &&
        layer?.valueStyleProps?.classify_mode === "number"
      ) {
        const range_value = layer?.valueStyleProps?.range || [];
        let color_array = layer?.valueStyleProps?.color || [];
        color_array = color_array.filter((item) => item !== "#000");
        legend_content = (
          <main>
            <p className="text_bold margin_bottom">Legenda angka:</p>
            <table>
              <tbody>
                {color_array.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <div
                          style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: item,
                          }}
                        />
                      </td>
                      <td className="text_inferior">
                        {range_value?.[idx]?.min} - {range_value?.[idx]?.max}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </main>
        );
      } else if (
        layer?.globalStyleMode === "by_value" &&
        layer?.valueStyleProps?.classify_mode === "text"
      ) {
        const selected_column = layer?.valueStyleProps?.selected_column;
        const range_value = layer?.valueStyleProps?.range || [];
        let color_array = layer?.valueStyleProps?.color || [];
        color_array = color_array.filter((item) => item !== "#000");
        legend_content = (
          <main>
            <table className="full_width">
              <tbody>
                <tr>
                  <td>
                    <p className="text_bold margin_bottom">Legenda teks:</p>
                  </td>
                  <td className="text_right">
                    <img
                      className="cursor_pointer"
                      alt="close"
                      src={close}
                      style={{ height: "20px" }}
                      onClick={this.on_reset_filter}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <table>
              <tbody>
                {color_array.map((item, idx) => {
                  return (
                    <tr
                      key={idx}
                      onClick={this.on_filter_isi_layer.bind(
                        this,
                        selected_column,
                        range_value?.[idx]
                      )}
                    >
                      <td>
                        <div
                          style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: item,
                          }}
                        />
                      </td>
                      <td className="text_inferior">{range_value?.[idx]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </main>
        );
      }
    }

    const button_content = (
      <main className="text_right" onClick={this.toggle_legend}>
        <div
          className="button text_center"
          id="blue_outline"
          style={{ height: "20px", width: "20px", boxSizing: "border-box" }}
        >
          {legend_status ? (
            <div className="arrow_right" />
          ) : (
            <div className="arrow_left" />
          )}
        </div>
      </main>
    );

    const final_content = (
      <main className="legend_close" id="outline_grey">
        {legend_content}
        {button_content}
      </main>
    );

    return final_content;
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  layer: state.layer,
});

export default connect(mapStateToProps, {
  set_value,
  filter_isi_layer,
  reset_filter,
  undo_filter_isi_layer,
})(Legend);
