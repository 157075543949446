import React, { Component } from "react";
class KERETA_API_INDONESIA_ICON extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="20"
        viewBox="0 0 31.862 9.768"
        style={{
          cursor: "pointer",
        }}
      >
        <g transform="translate(-46.863 -101.87)">
          <rect
            width="31.862"
            height="9.768"
            x="46.863"
            y="101.87"
            fill="#f38026"
            fillRule="evenodd"
            paintOrder="markers fill stroke"
            rx="4.884"
            ry="4.884"
          ></rect>
          <text
            x="50.273"
            y="108.841"
            style={{ lineHeight: 0 }}
            fill="none"
            stroke="#000"
            strokeWidth="0.265"
            fontFamily="Univers"
            fontSize="5.825"
            letterSpacing="0"
            wordSpacing="0"
          >
            <tspan
              x="50.273"
              y="108.841"
              fill="#fff"
              stroke="none"
              fontFamily="Inter"
              fontWeight="bold"
            >
              RESUME
            </tspan>
          </text>
        </g>
      </svg>
    );
  }
}

export default KERETA_API_INDONESIA_ICON;
