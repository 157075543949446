//Library modul
import { Component } from "react";
import { connect } from "react-redux";

import dict from "../../Data/dict.json";

class downloadgeojson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feature_filter: [],
    };
  }

  on_dowload_json = () => {
    const { layer_id, geo_layer_list } = this.props.layer;

    let geo_layer = geo_layer_list.find((e) => e.geo_layer._id === layer_id);

    const fields = geo_layer?.geo_layer?.fields
      ? geo_layer?.geo_layer?.fields
      : [];

    let geo_filter = this.state.feature_filter;
    geo_layer = geo_layer?.geo_layer;
    let geojson = geo_layer?.geojson ? geo_layer.geojson_filtered : {};
    const geoJsonFilter = {
      type: "FeatureCollection",
      features: geo_filter,
    };
    let dataJson =
      geo_filter?.length === 1 || geo_filter?.length >= 1
        ? geoJsonFilter
        : geojson;

    const features = dataJson?.features?.map((item) => {
      let properties = {};

      fields
        .filter((data) => data?.isStyle !== true || data?.isStyle === undefined)
        .forEach((data) => {
          properties[data.name] = item.properties[data.key];
        });

      return {
        type: item.type,
        geometry: item.geometry,
        properties: properties,
      };
    });

    const body = {
      type: "FeatureCollection",
      features: features,
    };

    const geojson_text = JSON.stringify(body);
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(geojson_text)
    );
    const file_name = `${geo_layer.name}.geojson`;
    element.setAttribute("download", file_name);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";

    return (
      <div className="buttonSimple2" onClick={() => this.on_dowload_json()}>
        {dict?.["Download GEOJSON"]?.[language]}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  auth: state.auth,
  layer: state.layer,
});

export default connect(mapStateToProps, {})(downloadgeojson);
